export class LRUCache<T> {
  private capacity: number;
  private cache: Map<string, T>;

  constructor(capacity: number) {
    this.capacity = capacity;
    this.cache = new Map<string, T>();
  }

  get(key: string): T | undefined {
    if (!this.cache.has(key)) {
      return undefined;
    }
    const value = this.cache.get(key);
    if (!value) return undefined;
    this.cache.delete(key);
    this.cache.set(key, value);
    return value;
  }

  put(key: string, value: T): void {
    if (!this.cache.has(key)) {
      const keys = Array.from(this.cache.keys());
      if (keys.length >= this.capacity) {
        // Remove the least recently used item
        const oldestKey = keys.shift();
        if (oldestKey !== undefined) {
          this.cache.delete(oldestKey);
        }
      }
    }
    this.cache.delete(key);
    this.cache.set(key, value);
  }

  serialize(): string {
    return JSON.stringify(Array.from(this.cache.entries()));
  }

  deserialize(serializedData: string): void {
    const data = new Map<string, T>(JSON.parse(serializedData));
    for (const key of Array.from(data.keys())) {
      this.put(key, data.get(key)!);
    }
  }
}
