import { IconButton, LinearProgress } from "@mui/material";
import { Paperclip, Image } from "lucide-react";

export default ({
  uploadsInProgress,
  uploadPercentage,
  onChange,
  imageIcon = false,
}: {
  uploadsInProgress: boolean;
  uploadPercentage: number;
  onChange: (f: FileList | null) => void;
  imageIcon?: boolean;
}) => {
  return (
    <label>
      <IconButton
        component="div"
        disabled={uploadsInProgress}
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          color: "#8E9598",
        }}
      >
        {!imageIcon ? <Paperclip size={20} /> : <Image size={20} />}
      </IconButton>
      {uploadPercentage > 0 && (
        <LinearProgress
          variant="determinate"
          value={uploadPercentage}
          sx={{
            bottom: "4px",
            width: "30px",
            left: "2px",
          }}
        />
      )}
      <input
        multiple
        disabled={uploadsInProgress}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => onChange(e.target.files)}
      />
    </label>
  );
};
