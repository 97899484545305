import { Box } from "@mui/material";

export default ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        marginBottom: "16px",
        marginTop: "16px",
      }}
    >
      <div
        style={{
          height: "1px",
          flex: "1 0 0",
          background: "#EAECF0",
        }}
      />
      <div
        style={{
          color: "#475467",
          textAlign: "center",
          userSelect: "none",
        }}
      >
        {text}
      </div>
      <div
        style={{
          height: "1px",
          flex: "1 0 0",
          background: "#EAECF0",
        }}
      />
    </Box>
  );
};
