import { useFetchAutocompleteEntries } from "services/email";
import { useEffect } from "react";
import { AutocompleteEntry } from "protogen/email_service_pb";

interface Props {
  familyRef?: string;
}
type Result = {
  autocompleteEntries: AutocompleteEntry[];
  loading: boolean;
};
export default ({ familyRef }: Props): Result => {
  const { request, data, loading } = useFetchAutocompleteEntries();
  useEffect(() => {
    request({ familyRef: familyRef || "" });
  }, [familyRef]);
  return {
    autocompleteEntries: data?.autocompleteEntries || [],
    loading,
  };
};
