import { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useDeleteCalendarEvent, useGetCalendarEvent } from "services/calendar";
import { CalendarEvent, EventNotice } from "protogen/calendar_pb";
import Breadcrumbs from "../common/Breadcrumbs";
import {
  subtitle,
  recurringSubtitle,
  getAttendeeStatusLabel,
  getAttendeeStatusLabelColor,
  getAttendeeStatusLabelBackgroundColor,
} from "./utils";
import DocumentViewer from "../editor/DocumentViewer";
import useIsMobile from "../hooks/useIsMobile";
interface Props {
  eventRef: string;
  onClose: (refresh?: boolean) => void;
  primaryAction: (eventRef: string) => void;
  onDelete?: () => void;
}

const Header = (event: CalendarEvent, notice: EventNotice) => {
  return (
    <Box>
      <Breadcrumbs breadcrumbs={[{ name: "Event" }]} />
      <Typography variant="title">{event.title}</Typography>
      <Typography variant="bodyHeavy" sx={{ color: "#6B6E7B" }}>
        {subtitle(notice)}
      </Typography>
    </Box>
  );
};

const Details = (event: CalendarEvent) => {
  const content = event?.details?.payload
    ? JSON.parse(event?.details?.payload)
    : event?.details?.textContent;
  return (
    <Box>
      <Typography sx={{ marginBottom: "12px" }} variant="h4">
        Details
      </Typography>
      <DocumentViewer
        content={content}
        attachments={event?.details?.attachments!}
        minHeight="20px"
      />
    </Box>
  );
};

const Attendees = (event: CalendarEvent, isMobile: boolean) => {
  if (event.attendees.length === 0) return <></>;
  return (
    <Box>
      <Typography sx={{ marginBottom: "12px" }} variant="h4">
        Guests
      </Typography>
      {event.attendees.map((a) => {
        return (
          <Box
            key={a.email}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "7px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                {a.firstName && a.lastName
                  ? `${a.firstName} ${a.lastName}`
                  : a.email}
              </Typography>
              {a.rsvpStatus === 1 || a.rsvpStatus === 2 ? (
                <Chip
                  label={getAttendeeStatusLabel(a.rsvpStatus)}
                  sx={{
                    height: isMobile ? "28px" : "35px",
                    width: isMobile ? "64px" : "87px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: getAttendeeStatusLabelColor(a.rsvpStatus),
                    backgroundColor: getAttendeeStatusLabelBackgroundColor(
                      a.rsvpStatus,
                    ),
                  }}
                />
              ) : (
                <Typography color="text.secondary">
                  {getAttendeeStatusLabel(a.rsvpStatus)}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const Settings = (notice: EventNotice) => {
  return (
    <Box>
      <Typography sx={{ marginBottom: "12px" }} variant="h4">
        Settings
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body">Repeats</Typography>
          <Typography color="text.secondary" fontSize="14px" fontWeight="500">
            {" "}
            {notice.recurringUnit !== 0
              ? recurringSubtitle(notice)
              : "Does not repeat"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const EditEventButton = (
  event: CalendarEvent,
  handleClick: (eventRef: string) => void,
) => {
  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => {
          handleClick(event.ref!);
        }}
      >
        Edit event
      </Button>
    </Box>
  );
};

const DeleteEventButton = (
  event: CalendarEvent,
  disabled: boolean,
  handleClick: (eventRef: string) => void,
) => {
  return (
    <Box>
      <Button
        variant="text"
        disabled={disabled}
        sx={(theme) => ({
          color: theme.palette.secondary.dark,
        })}
        onClick={() => {
          handleClick(event.ref!);
        }}
      >
        Delete event
      </Button>
    </Box>
  );
};
export default ({ eventRef, onClose, primaryAction, onDelete }: Props) => {
  const isMobile = useIsMobile();
  const { request, data, loading: eventLoading } = useGetCalendarEvent();
  const { request: deleteRequest, loading: deleteLoading } =
    useDeleteCalendarEvent();
  const loading = eventLoading || deleteLoading;

  const deleteEvent = async (eventRef: string) => {
    await deleteRequest({ eventRef: eventRef });
    onDelete && onDelete();
    onClose(true);
  };

  useEffect(() => {
    request({ eventRef: eventRef });
  }, [open]);

  if (!data) {
    return <></>;
  }
  return (
    <Box sx={{ width: "100%" }}>
      {(loading || !data) && <></>}
      {Header(data?.event!, data?.notice!)}
      {isMobile && (
        <Box
          sx={{ marginTop: "29px" }}
          display="flex"
          flexDirection="row"
          gap="8px"
        >
          {EditEventButton(data?.event!, primaryAction)}
          {DeleteEventButton(data?.event!, deleteLoading, deleteEvent)}
        </Box>
      )}
      <Divider sx={{ marginTop: "22px", marginBottom: "22px" }}></Divider>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : undefined,
          gap: isMobile ? undefined : "64px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{Details(data?.event!)}</Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minWidth: "270px",
          }}
        >
          {Attendees(data?.event!, isMobile)}
          {Settings(data?.notice!)}
        </Box>
      </Box>
      {!isMobile && (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {EditEventButton(data?.event!, primaryAction)}
          {DeleteEventButton(data?.event!, deleteLoading, deleteEvent)}
        </Box>
      )}
      {isMobile && (
        <Box sx={{ marginTop: "24px" }}>
          <Button
            sx={{ paddingLeft: "0px" }}
            startIcon={
              <ArrowLeftIcon height="20px" width="20px" color="##198282" />
            }
            variant="text"
            onClick={() => onClose()}
          >
            Back
          </Button>
        </Box>
      )}
    </Box>
  );
};
