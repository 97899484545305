import { ReactNode, useCallback } from "react";
import { Editor } from "@tiptap/react";
import {
  Bold,
  Heading,
  Italic,
  Link,
  List,
  ListOrdered,
  ListChecks,
  Strikethrough,
  Table,
  Undo,
  Redo,
  Underline,
} from "lucide-react";
import EditIcon from "./EditIcon";
import TextColorAction from "./TextColorAction";
import { Divider } from "@mui/material";

export default ({
  editor,
  extraActions = [],
  withDividers,
}: {
  editor: Editor;
  extraActions?: ReactNode[];
  withDividers?: boolean;
}) => {
  const setLink = useCallback(() => {
    if (!editor) return;
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl || "https://");

    // cancelled
    if (url === null) return;

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  return (
    <>
      <EditIcon
        IconComponent={Heading}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        isActive={editor.isActive("heading")}
        className={editor.isActive("heading") ? "is-active" : ""}
      />
      <EditIcon
        IconComponent={Bold}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        className={editor.isActive("bold") ? "is-active" : ""}
      />
      <EditIcon
        IconComponent={Italic}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        className={editor.isActive("italic") ? "is-active" : ""}
      />
      <EditIcon
        IconComponent={Underline}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        isActive={editor.isActive("underline")}
        className={editor.isActive("underline") ? "is-active" : ""}
      />
      <EditIcon
        IconComponent={Strikethrough}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isActive={editor.isActive("strike")}
        className={editor.isActive("strike") ? "is-active" : ""}
      />
      <TextColorAction editor={editor} />
      {withDividers && (
        <Divider
          orientation="vertical"
          sx={{ height: "20px", margin: "6px 3px" }}
        />
      )}
      <EditIcon
        IconComponent={Link}
        onClick={setLink}
        className={editor.isActive("link") ? "is-active" : ""}
        isActive={editor.isActive("link")}
      />
      <EditIcon
        IconComponent={List}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
        isActive={editor.isActive("bulletList")}
      />
      <EditIcon
        IconComponent={ListOrdered}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
        isActive={editor.isActive("orderedList")}
      />
      <EditIcon
        IconComponent={ListChecks}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        isActive={editor.isActive("taskList")}
        className={editor.isActive("taskList") ? "is-active" : ""}
      />
      <EditIcon
        IconComponent={Table}
        disabled={editor.isActive("table")}
        onClick={() =>
          editor
            .chain()
            .focus()
            .setHardBreak()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
        isActive={editor.isActive("table")}
        className={editor.isActive("table") ? "is-active" : ""}
      />
      {extraActions.map((action, index) => (
        <EditIcon key={index}>{action}</EditIcon>
      ))}
      {withDividers && (
        <Divider
          orientation="vertical"
          sx={{ height: "20px", margin: "6px 3px" }}
        />
      )}
      <EditIcon
        IconComponent={Undo}
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().undo()}
      />
      <EditIcon
        IconComponent={Redo}
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().redo()}
      />
    </>
  );
};
