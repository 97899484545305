import { Box, Button, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAcceptAdvisorTerms } from "../../..//services/nux";
import auth from "components/nux/auth";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import { TOS_VERSION } from "components/context/CheckTerms";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "components/nux/constants";

export default () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { request, loading } = useAcceptAdvisorTerms((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.ADVISOR);
      navigate(path);
    }
  });
  const onContinue = () => {
    request({
      token: auth.getAuthData(),
      termsOfServiceVersion: TOS_VERSION,
    });
  };
  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="title"
          >
            Our Advisor agreement
          </Typography>
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="body"
          >
            Please read and accept our&nbsp;
            <Link
              sx={{ textDecoration: "none" }}
              target="_blank"
              href={"https://findfaye.com/advisor-terms"}
            >
              Advisor Terms of Service
            </Link>
            . If you need to refer back to it, you can always find it in the
            Advisor platform.
          </Typography>
          <Box
            sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}
          >
            <Button
              disabled={loading}
              sx={{ width: "100%" }}
              onClick={onContinue}
            >
              Accept terms
            </Button>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
