import { Button, ListItemAvatar } from "@mui/material";
import { useCreateComment } from "services/forum";
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ForumComment } from "protogen/forum_service_pb";
import ListItem from "@mui/material/ListItem";
import { UserAvatar } from "../common/CurrentUserAvatar";
import { CurrentUserContext } from "../context/RequireAuth";
import { RichContent } from "../editor/utils";
import SimpleRichEditor, { Handle } from "../editor/SimpleRichEditor";

type Props = {
  postRef: string;
  parentRef?: string | null;
  onCreate: (c: ForumComment) => void;
};

export type CommentHandle = {
  focus: () => void;
};

export default forwardRef<CommentHandle, Props>(
  ({ postRef, parentRef = null, onCreate }: Props, ref) => {
    const editorRef = useRef<Handle>(null);
    const currentUser = useContext(CurrentUserContext);
    const [content, setContent] = useState<RichContent | null>(null);
    const [commentKey, setCommentKey] = useState("");
    const [uploadsInProgress, setUploadsInProgress] = useState(false);
    const { request, loading } = useCreateComment((r) => {
      setContent(null);
      onCreate(r.comment!);
      setCommentKey(r.comment!.ref);
    });
    useImperativeHandle(ref, () => ({
      focus: () => {
        editorRef?.current?.focus();
      },
    }));

    const handleComment = () => {
      if (!content) return;
      request({
        postRef,
        parentRef: parentRef || "",
        body: {
          contentType: "json",
          payload: content.json,
          textContent: content.text,
          attachments: content.attachments,
        },
      });
    };
    return (
      <ListItem
        sx={{
          marginTop: "16px",
          marginBottom: "16px",
          padding: "8px 0",
        }}
      >
        <ListItemAvatar>
          <UserAvatar user={currentUser} size={40} />
        </ListItemAvatar>
        <SimpleRichEditor
          ref={editorRef}
          key={commentKey}
          disabled={loading}
          setContent={setContent}
          placeholder="Reply..."
          setUploadsInProgress={setUploadsInProgress}
          primaryAction={
            <Button
              size="small"
              sx={{
                height: "unset",
                padding: "8px 18px",
              }}
              onClick={handleComment}
              disabled={loading || !content || uploadsInProgress}
            >
              Reply
            </Button>
          }
        />
      </ListItem>
    );
  },
);
