import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { SecretFormHandle, SecretFormProps } from "../types";
import DragNDrop from "../../common/DragNDrop";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { useUploader } from "../../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { UploadAttachment } from "protogen/common_pb";
import AttachmentList from "../../editor/AttachmentList";
import UploadDocumentsButton from "../UploadDocumentsButton";
import { Typography } from "@mui/material";

export default forwardRef<SecretFormHandle, SecretFormProps>(
  ({ secret, updateFormData, errors }: SecretFormProps, ref) => {
    const [dragState, setDragState] = React.useState(false);
    const {
      onUpload,
      fileUploads,
      uploadPercentage,
      removeUpload,
      uploadsInProgress,
      withAttachments,
    } = useUploader({
      initialAttachments: secret.attachments || [],
      sseRef: "client-secrets",
    });
    useEffect(() => {
      withAttachments(secret.attachments || []);
    }, [secret.attachments]);

    useImperativeHandle(ref, () => ({
      isValid: () => {
        const notUploading = !uploadsInProgress || uploadPercentage === 100;
        const hasName = secret.name !== "";

        return notUploading && hasName;
      },
    }));

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        // Note - there could be a race condition here if the user is uploading a file AND updating text fields.
        updateFormData({ ...secret, uploads: attachments });
      },
      [secret, fileUploads],
    );

    return (
      <DragNDrop
        setHover={setDragState}
        onUpload={(files) => {
          onUpload(files, undefined, setAttachments);
        }}
      >
        <Box
          sx={{
            ...(dragState ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={secret.name}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) =>
              updateFormData({ ...secret, name: e.target.value })
            }
          />
          <TextField
            rows={5}
            multiline
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            value={secret.value || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) =>
              updateFormData({ ...secret, value: e.target.value })
            }
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Typography variant="bodyHeavy">Documents</Typography>
            <AttachmentList
              attachments={fileUploads}
              onDelete={(filename) => removeUpload(filename, setAttachments)}
            />
            <UploadDocumentsButton
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={(files) => onUpload(files, undefined, setAttachments)}
            />
          </Box>
        </Box>
      </DragNDrop>
    );
  },
);
