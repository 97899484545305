import { useCallback, useEffect, useState } from "react";

type ReturnType = {
  isHidden: boolean;
  isVisible: boolean;
};

type Props = {
  onRefocus?: (blurSecs: number) => void;
};
export default ({ onRefocus }: Props): ReturnType => {
  const [isHidden, setIsHidden] = useState(document.hidden);
  const [lastChanged, setLastChanged] = useState(Date.now());

  const handleVisibilityChange = useCallback(() => {
    const ts = Date.now();
    const duration = (ts - lastChanged) / 1000;
    setIsHidden(document.hidden);
    setLastChanged(ts);
    if (!document.hidden && onRefocus) {
      onRefocus(duration);
    }
  }, [lastChanged, isHidden, onRefocus]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [lastChanged, isHidden, onRefocus]);
  return { isHidden, isVisible: !isHidden };
};
