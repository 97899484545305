import { FeedEntry } from "protogen/advisors_service_pb";
import { protoInt64 } from "@bufbuild/protobuf";

const dedupeFeedEntries = (entries: FeedEntry[]) => {
  const result: FeedEntry[] = [];
  const indexMap = new Map<string, number>();
  for (const item of entries) {
    // Create a unique string representation of the keys
    const key = `${item.ref}-${item.medium}`;

    // Maybe overwrite?
    if (!indexMap.has(key)) {
      indexMap.set(key, result.length);
      result.push(item);
    } else {
      // Special case for attachments where we DON'T Want the latest value as that will force the browser to refetch.
      // I'm not quite sure what the best solution is here. We should use the latest entry in case there's been
      // an update to a statue or something, but we don't want to refetch the attachments. Longer term perhaps we have
      // an attachment endpoint that generates the signed URLs and redirects and just use that instead of returning the
      // signed URL with the feed.
      const seen = indexMap.get(key)!;
      const newEntry = result[seen];
      if (newEntry.textMessage?.attachments && item.textMessage?.attachments) {
        newEntry.textMessage.attachments = item.textMessage.attachments;
      }
    }
  }
  return result;
};

const sortEntryTimestamps = (a: FeedEntry, b: FeedEntry) => {
  const aTs = protoInt64.parse(a.timestampSec!);
  const bTs = protoInt64.parse(b.timestampSec!);
  return aTs < bTs ? 1 : aTs > bTs ? -1 : 0;
};

export { dedupeFeedEntries, sortEntryTimestamps };
