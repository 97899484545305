// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/forum_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ContentUpdates, NoteContent } from "./notes_pb.ts";
import { AccountStub } from "./common_pb.ts";

/**
 * @generated from enum protogen.ForumPostType
 */
export enum ForumPostType {
  /**
   * @generated from enum value: POST_TYPE_UNSPECIFIED = 0;
   */
  POST_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: POST_DISCUSSION = 1;
   */
  POST_DISCUSSION = 1,

  /**
   * @generated from enum value: POST_RECOMMENDATION = 2;
   */
  POST_RECOMMENDATION = 2,

  /**
   * @generated from enum value: POST_RESOURCE = 3;
   */
  POST_RESOURCE = 3,

  /**
   * @generated from enum value: POST_ASKFAS = 4;
   */
  POST_ASKFAS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ForumPostType)
proto3.util.setEnumType(ForumPostType, "protogen.ForumPostType", [
  { no: 0, name: "POST_TYPE_UNSPECIFIED" },
  { no: 1, name: "POST_DISCUSSION" },
  { no: 2, name: "POST_RECOMMENDATION" },
  { no: 3, name: "POST_RESOURCE" },
  { no: 4, name: "POST_ASKFAS" },
]);

/**
 * @generated from message protogen.LikeHint
 */
export class LikeHint extends Message<LikeHint> {
  /**
   * @generated from field: string displayName = 1;
   */
  displayName = "";

  /**
   * @generated from field: string userRef = 2;
   */
  userRef = "";

  constructor(data?: PartialMessage<LikeHint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LikeHint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "userRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LikeHint {
    return new LikeHint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LikeHint {
    return new LikeHint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LikeHint {
    return new LikeHint().fromJsonString(jsonString, options);
  }

  static equals(a: LikeHint | PlainMessage<LikeHint> | undefined, b: LikeHint | PlainMessage<LikeHint> | undefined): boolean {
    return proto3.util.equals(LikeHint, a, b);
  }
}

/**
 * @generated from message protogen.ForumPost
 */
export class ForumPost extends Message<ForumPost> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: int64 pinnedSec = 3;
   */
  pinnedSec = protoInt64.zero;

  /**
   * @generated from field: protogen.NoteContent body = 4;
   */
  body?: NoteContent;

  /**
   * @generated from field: protogen.AccountStub author = 5;
   */
  author?: AccountStub;

  /**
   * @generated from field: int64 createdSec = 6;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastActivitySec = 7;
   */
  lastActivitySec = protoInt64.zero;

  /**
   * @generated from field: int32 commentCount = 8;
   */
  commentCount = 0;

  /**
   * @generated from field: int64 lastEditSec = 9;
   */
  lastEditSec = protoInt64.zero;

  /**
   * @generated from field: bool isLikedByUser = 10;
   */
  isLikedByUser = false;

  /**
   * @generated from field: int32 totalLikes = 11;
   */
  totalLikes = 0;

  /**
   * @generated from field: protogen.ForumPostType postType = 12;
   */
  postType = ForumPostType.POST_TYPE_UNSPECIFIED;

  /**
   * @generated from field: repeated string topics = 13;
   */
  topics: string[] = [];

  /**
   * Only present on single post view.
   *
   * @generated from field: repeated protogen.LikeHint likerHints = 14;
   */
  likerHints: LikeHint[] = [];

  /**
   * Second that was used for sorting purposes.
   *
   * @generated from field: int64 sortSec = 15;
   */
  sortSec = protoInt64.zero;

  constructor(data?: PartialMessage<ForumPost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumPost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pinnedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "body", kind: "message", T: NoteContent },
    { no: 5, name: "author", kind: "message", T: AccountStub },
    { no: 6, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "lastActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "commentCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "lastEditSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "isLikedByUser", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "totalLikes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "postType", kind: "enum", T: proto3.getEnumType(ForumPostType) },
    { no: 13, name: "topics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "likerHints", kind: "message", T: LikeHint, repeated: true },
    { no: 15, name: "sortSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumPost {
    return new ForumPost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumPost {
    return new ForumPost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumPost {
    return new ForumPost().fromJsonString(jsonString, options);
  }

  static equals(a: ForumPost | PlainMessage<ForumPost> | undefined, b: ForumPost | PlainMessage<ForumPost> | undefined): boolean {
    return proto3.util.equals(ForumPost, a, b);
  }
}

/**
 * @generated from message protogen.ForumComment
 */
export class ForumComment extends Message<ForumComment> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string postRef = 2;
   */
  postRef = "";

  /**
   * @generated from field: string parentRef = 3;
   */
  parentRef = "";

  /**
   * @generated from field: int32 depth = 4;
   */
  depth = 0;

  /**
   * @generated from field: protogen.NoteContent body = 5;
   */
  body?: NoteContent;

  /**
   * @generated from field: protogen.AccountStub author = 6;
   */
  author?: AccountStub;

  /**
   * @generated from field: int64 createdSec = 7;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastEditSec = 8;
   */
  lastEditSec = protoInt64.zero;

  /**
   * @generated from field: bool isLikedByUser = 9;
   */
  isLikedByUser = false;

  /**
   * @generated from field: int32 totalLikes = 10;
   */
  totalLikes = 0;

  /**
   * Only present on single post view.
   *
   * @generated from field: repeated protogen.LikeHint likerHints = 11;
   */
  likerHints: LikeHint[] = [];

  constructor(data?: PartialMessage<ForumComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "body", kind: "message", T: NoteContent },
    { no: 6, name: "author", kind: "message", T: AccountStub },
    { no: 7, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "lastEditSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "isLikedByUser", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "totalLikes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "likerHints", kind: "message", T: LikeHint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumComment {
    return new ForumComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumComment {
    return new ForumComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumComment {
    return new ForumComment().fromJsonString(jsonString, options);
  }

  static equals(a: ForumComment | PlainMessage<ForumComment> | undefined, b: ForumComment | PlainMessage<ForumComment> | undefined): boolean {
    return proto3.util.equals(ForumComment, a, b);
  }
}

/**
 * @generated from message protogen.CreatePostRequest
 */
export class CreatePostRequest extends Message<CreatePostRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 2;
   */
  body?: ContentUpdates;

  /**
   * @generated from field: protogen.ForumPostType postType = 3;
   */
  postType = ForumPostType.POST_TYPE_UNSPECIFIED;

  /**
   * @generated from field: repeated string topics = 4;
   */
  topics: string[] = [];

  constructor(data?: PartialMessage<CreatePostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreatePostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "message", T: ContentUpdates },
    { no: 3, name: "postType", kind: "enum", T: proto3.getEnumType(ForumPostType) },
    { no: 4, name: "topics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined, b: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined): boolean {
    return proto3.util.equals(CreatePostRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreatePostResponse
 */
export class CreatePostResponse extends Message<CreatePostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  constructor(data?: PartialMessage<CreatePostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreatePostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined, b: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined): boolean {
    return proto3.util.equals(CreatePostResponse, a, b);
  }
}

/**
 * @generated from message protogen.BusinessInfoLocation
 */
export class BusinessInfoLocation extends Message<BusinessInfoLocation> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * @generated from field: string state = 3;
   */
  state = "";

  /**
   * @generated from field: string zip = 4;
   */
  zip = "";

  /**
   * @generated from field: string formattedAddress = 5;
   */
  formattedAddress = "";

  /**
   * @generated from field: float latitude = 6;
   */
  latitude = 0;

  /**
   * @generated from field: float longitude = 7;
   */
  longitude = 0;

  /**
   * @generated from field: string unit = 8;
   */
  unit = "";

  constructor(data?: PartialMessage<BusinessInfoLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.BusinessInfoLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "formattedAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "latitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "longitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BusinessInfoLocation {
    return new BusinessInfoLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BusinessInfoLocation {
    return new BusinessInfoLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BusinessInfoLocation {
    return new BusinessInfoLocation().fromJsonString(jsonString, options);
  }

  static equals(a: BusinessInfoLocation | PlainMessage<BusinessInfoLocation> | undefined, b: BusinessInfoLocation | PlainMessage<BusinessInfoLocation> | undefined): boolean {
    return proto3.util.equals(BusinessInfoLocation, a, b);
  }
}

/**
 * @generated from message protogen.BusinessInfo
 */
export class BusinessInfo extends Message<BusinessInfo> {
  /**
   * Indicates we have dismissed business info entirely.
   *
   * @generated from field: bool dismissed = 1;
   */
  dismissed = false;

  /**
   * @generated from field: bool empty = 2;
   */
  empty = false;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string website = 5;
   */
  website = "";

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * @generated from field: bool national = 7;
   */
  national = false;

  /**
   * @generated from field: protogen.BusinessInfoLocation location = 8;
   */
  location?: BusinessInfoLocation;

  constructor(data?: PartialMessage<BusinessInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.BusinessInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dismissed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "empty", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "national", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "location", kind: "message", T: BusinessInfoLocation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BusinessInfo {
    return new BusinessInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BusinessInfo {
    return new BusinessInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BusinessInfo {
    return new BusinessInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BusinessInfo | PlainMessage<BusinessInfo> | undefined, b: BusinessInfo | PlainMessage<BusinessInfo> | undefined): boolean {
    return proto3.util.equals(BusinessInfo, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBusinessInfoRequest
 */
export class UpdateBusinessInfoRequest extends Message<UpdateBusinessInfoRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: protogen.BusinessInfo businessInfo = 5;
   */
  businessInfo?: BusinessInfo;

  constructor(data?: PartialMessage<UpdateBusinessInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBusinessInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "businessInfo", kind: "message", T: BusinessInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBusinessInfoRequest {
    return new UpdateBusinessInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBusinessInfoRequest {
    return new UpdateBusinessInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBusinessInfoRequest {
    return new UpdateBusinessInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBusinessInfoRequest | PlainMessage<UpdateBusinessInfoRequest> | undefined, b: UpdateBusinessInfoRequest | PlainMessage<UpdateBusinessInfoRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBusinessInfoRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBusinessInfoResponse
 */
export class UpdateBusinessInfoResponse extends Message<UpdateBusinessInfoResponse> {
  /**
   * @generated from field: protogen.BusinessInfo businessInfo = 1;
   */
  businessInfo?: BusinessInfo;

  constructor(data?: PartialMessage<UpdateBusinessInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBusinessInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "businessInfo", kind: "message", T: BusinessInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBusinessInfoResponse {
    return new UpdateBusinessInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBusinessInfoResponse {
    return new UpdateBusinessInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBusinessInfoResponse {
    return new UpdateBusinessInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBusinessInfoResponse | PlainMessage<UpdateBusinessInfoResponse> | undefined, b: UpdateBusinessInfoResponse | PlainMessage<UpdateBusinessInfoResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBusinessInfoResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditPostRequest
 */
export class EditPostRequest extends Message<EditPostRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 3;
   */
  body?: ContentUpdates;

  /**
   * @generated from field: repeated string topics = 4;
   */
  topics: string[] = [];

  constructor(data?: PartialMessage<EditPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "message", T: ContentUpdates },
    { no: 4, name: "topics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPostRequest {
    return new EditPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPostRequest {
    return new EditPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPostRequest {
    return new EditPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditPostRequest | PlainMessage<EditPostRequest> | undefined, b: EditPostRequest | PlainMessage<EditPostRequest> | undefined): boolean {
    return proto3.util.equals(EditPostRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditPostResponse
 */
export class EditPostResponse extends Message<EditPostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  constructor(data?: PartialMessage<EditPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPostResponse {
    return new EditPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPostResponse {
    return new EditPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPostResponse {
    return new EditPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditPostResponse | PlainMessage<EditPostResponse> | undefined, b: EditPostResponse | PlainMessage<EditPostResponse> | undefined): boolean {
    return proto3.util.equals(EditPostResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeletePostRequest
 */
export class DeletePostRequest extends Message<DeletePostRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  constructor(data?: PartialMessage<DeletePostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeletePostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePostRequest | PlainMessage<DeletePostRequest> | undefined, b: DeletePostRequest | PlainMessage<DeletePostRequest> | undefined): boolean {
    return proto3.util.equals(DeletePostRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeletePostResponse
 */
export class DeletePostResponse extends Message<DeletePostResponse> {
  constructor(data?: PartialMessage<DeletePostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeletePostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePostResponse | PlainMessage<DeletePostResponse> | undefined, b: DeletePostResponse | PlainMessage<DeletePostResponse> | undefined): boolean {
    return proto3.util.equals(DeletePostResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateCommentRequest
 */
export class CreateCommentRequest extends Message<CreateCommentRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: string parentRef = 2;
   */
  parentRef = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 3;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<CreateCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined, b: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined): boolean {
    return proto3.util.equals(CreateCommentRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateCommentResponse
 */
export class CreateCommentResponse extends Message<CreateCommentResponse> {
  /**
   * @generated from field: protogen.ForumComment comment = 1;
   */
  comment?: ForumComment;

  constructor(data?: PartialMessage<CreateCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comment", kind: "message", T: ForumComment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommentResponse | PlainMessage<CreateCommentResponse> | undefined, b: CreateCommentResponse | PlainMessage<CreateCommentResponse> | undefined): boolean {
    return proto3.util.equals(CreateCommentResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentRequest
 */
export class EditCommentRequest extends Message<EditCommentRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 2;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<EditCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentRequest | PlainMessage<EditCommentRequest> | undefined, b: EditCommentRequest | PlainMessage<EditCommentRequest> | undefined): boolean {
    return proto3.util.equals(EditCommentRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentResponse
 */
export class EditCommentResponse extends Message<EditCommentResponse> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.ForumComment comment = 2;
   */
  comment?: ForumComment;

  constructor(data?: PartialMessage<EditCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment", kind: "message", T: ForumComment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentResponse | PlainMessage<EditCommentResponse> | undefined, b: EditCommentResponse | PlainMessage<EditCommentResponse> | undefined): boolean {
    return proto3.util.equals(EditCommentResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteCommentRequest
 */
export class DeleteCommentRequest extends Message<DeleteCommentRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  constructor(data?: PartialMessage<DeleteCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCommentRequest {
    return new DeleteCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCommentRequest {
    return new DeleteCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCommentRequest {
    return new DeleteCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCommentRequest | PlainMessage<DeleteCommentRequest> | undefined, b: DeleteCommentRequest | PlainMessage<DeleteCommentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCommentRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteCommentResponse
 */
export class DeleteCommentResponse extends Message<DeleteCommentResponse> {
  constructor(data?: PartialMessage<DeleteCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCommentResponse {
    return new DeleteCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCommentResponse {
    return new DeleteCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCommentResponse {
    return new DeleteCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCommentResponse | PlainMessage<DeleteCommentResponse> | undefined, b: DeleteCommentResponse | PlainMessage<DeleteCommentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteCommentResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentLikeRequest
 */
export class EditCommentLikeRequest extends Message<EditCommentLikeRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string postRef = 2;
   */
  postRef = "";

  /**
   * @generated from field: bool isLiked = 3;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditCommentLikeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentLikeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentLikeRequest | PlainMessage<EditCommentLikeRequest> | undefined, b: EditCommentLikeRequest | PlainMessage<EditCommentLikeRequest> | undefined): boolean {
    return proto3.util.equals(EditCommentLikeRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentLikeResponse
 */
export class EditCommentLikeResponse extends Message<EditCommentLikeResponse> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: bool isLiked = 2;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditCommentLikeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentLikeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentLikeResponse | PlainMessage<EditCommentLikeResponse> | undefined, b: EditCommentLikeResponse | PlainMessage<EditCommentLikeResponse> | undefined): boolean {
    return proto3.util.equals(EditCommentLikeResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPostRequest
 */
export class FetchForumPostRequest extends Message<FetchForumPostRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: bool markAsRead = 2;
   */
  markAsRead = false;

  constructor(data?: PartialMessage<FetchForumPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markAsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPostRequest | PlainMessage<FetchForumPostRequest> | undefined, b: FetchForumPostRequest | PlainMessage<FetchForumPostRequest> | undefined): boolean {
    return proto3.util.equals(FetchForumPostRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPostResponse
 */
export class FetchForumPostResponse extends Message<FetchForumPostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  /**
   * @generated from field: repeated protogen.ForumComment comments = 2;
   */
  comments: ForumComment[] = [];

  /**
   * @generated from field: protogen.BusinessInfo businessInfo = 3;
   */
  businessInfo?: BusinessInfo;

  constructor(data?: PartialMessage<FetchForumPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
    { no: 2, name: "comments", kind: "message", T: ForumComment, repeated: true },
    { no: 3, name: "businessInfo", kind: "message", T: BusinessInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPostResponse | PlainMessage<FetchForumPostResponse> | undefined, b: FetchForumPostResponse | PlainMessage<FetchForumPostResponse> | undefined): boolean {
    return proto3.util.equals(FetchForumPostResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPageRequest
 */
export class FetchForumPageRequest extends Message<FetchForumPageRequest> {
  /**
   * @generated from field: int32 pageSize = 1;
   */
  pageSize = 0;

  /**
   * @generated from field: string cursor = 2;
   */
  cursor = "";

  /**
   * Matches any of these topics.
   *
   * @generated from field: repeated protogen.ForumPostType anyPostTypes = 3;
   */
  anyPostTypes: ForumPostType[] = [];

  /**
   * @generated from field: repeated string anyTopics = 4;
   */
  anyTopics: string[] = [];

  /**
   * @generated from field: bool respectPinned = 5;
   */
  respectPinned = false;

  constructor(data?: PartialMessage<FetchForumPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pageSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "anyPostTypes", kind: "enum", T: proto3.getEnumType(ForumPostType), repeated: true },
    { no: 4, name: "anyTopics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "respectPinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPageRequest | PlainMessage<FetchForumPageRequest> | undefined, b: FetchForumPageRequest | PlainMessage<FetchForumPageRequest> | undefined): boolean {
    return proto3.util.equals(FetchForumPageRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPageResponse
 */
export class FetchForumPageResponse extends Message<FetchForumPageResponse> {
  /**
   * @generated from field: repeated protogen.ForumPost posts = 1;
   */
  posts: ForumPost[] = [];

  /**
   * @generated from field: string nextCursor = 2;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchForumPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: ForumPost, repeated: true },
    { no: 2, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPageResponse | PlainMessage<FetchForumPageResponse> | undefined, b: FetchForumPageResponse | PlainMessage<FetchForumPageResponse> | undefined): boolean {
    return proto3.util.equals(FetchForumPageResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditForumPostLikeRequest
 */
export class EditForumPostLikeRequest extends Message<EditForumPostLikeRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: bool isLiked = 2;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditForumPostLikeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditForumPostLikeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditForumPostLikeRequest {
    return new EditForumPostLikeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditForumPostLikeRequest {
    return new EditForumPostLikeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditForumPostLikeRequest {
    return new EditForumPostLikeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditForumPostLikeRequest | PlainMessage<EditForumPostLikeRequest> | undefined, b: EditForumPostLikeRequest | PlainMessage<EditForumPostLikeRequest> | undefined): boolean {
    return proto3.util.equals(EditForumPostLikeRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditForumPostLikeResponse
 */
export class EditForumPostLikeResponse extends Message<EditForumPostLikeResponse> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: bool isLiked = 2;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditForumPostLikeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditForumPostLikeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditForumPostLikeResponse {
    return new EditForumPostLikeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditForumPostLikeResponse {
    return new EditForumPostLikeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditForumPostLikeResponse {
    return new EditForumPostLikeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditForumPostLikeResponse | PlainMessage<EditForumPostLikeResponse> | undefined, b: EditForumPostLikeResponse | PlainMessage<EditForumPostLikeResponse> | undefined): boolean {
    return proto3.util.equals(EditForumPostLikeResponse, a, b);
  }
}

/**
 * @generated from message protogen.PinForumPostRequest
 */
export class PinForumPostRequest extends Message<PinForumPostRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: bool unpin = 2;
   */
  unpin = false;

  constructor(data?: PartialMessage<PinForumPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.PinForumPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unpin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinForumPostRequest {
    return new PinForumPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinForumPostRequest {
    return new PinForumPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinForumPostRequest {
    return new PinForumPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PinForumPostRequest | PlainMessage<PinForumPostRequest> | undefined, b: PinForumPostRequest | PlainMessage<PinForumPostRequest> | undefined): boolean {
    return proto3.util.equals(PinForumPostRequest, a, b);
  }
}

/**
 * @generated from message protogen.PinForumPostResponse
 */
export class PinForumPostResponse extends Message<PinForumPostResponse> {
  constructor(data?: PartialMessage<PinForumPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.PinForumPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinForumPostResponse {
    return new PinForumPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinForumPostResponse {
    return new PinForumPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinForumPostResponse {
    return new PinForumPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PinForumPostResponse | PlainMessage<PinForumPostResponse> | undefined, b: PinForumPostResponse | PlainMessage<PinForumPostResponse> | undefined): boolean {
    return proto3.util.equals(PinForumPostResponse, a, b);
  }
}

