import { useRef, useState, useEffect } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { TrashIcon } from "@heroicons/react/24/outline";
import { EphemeralFactSection, FactFormHandle } from "./types";
import useIsMobile from "../hooks/useIsMobile";
import FactSectionForm from "./forms/FactSectionForm";

export default ({
  factSection,
  isEdit,
  updateFormData,
  onSave,
  onCancel,
  onDelete,
}: {
  factSection: EphemeralFactSection;
  isEdit: boolean;
  updateFormData: (f: EphemeralFactSection) => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  const isMobile = useIsMobile();
  const [isValid, setIsValid] = useState(false);
  const viewRef = useRef<FactFormHandle | null>(null);

  useEffect(() => {
    setIsValid(viewRef.current?.isValid() ?? false);
  }, [viewRef.current]);

  return (
    <Box
      sx={{
        ...(!isMobile && {
          padding: "24px",
          paddingTop: "8px",
          paddingLeft: "42px",
          paddingRight: "42px",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Typography variant="title">
          {isEdit ? "Edit section" : "Add section"}
        </Typography>
        {isEdit && (
          <IconButton onClick={onDelete}>
            <TrashIcon height="24px" width="24px" stroke="#E57171" />
          </IconButton>
        )}
      </Box>
      <Box sx={{ marginBottom: "24px" }}>
        {
          <FactSectionForm
            ref={viewRef}
            factSection={factSection}
            updateFormData={updateFormData}
            errors={null}
          ></FactSectionForm>
        }
      </Box>
      <Box>
        <Button
          sx={{ marginBottom: "12px" }}
          fullWidth
          disabled={!isValid}
          onClick={onSave}
        >
          Save
        </Button>
        <Button fullWidth variant="outlinedSecondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
