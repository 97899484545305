// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/nux_state.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.NuxDetails
 */
export class NuxDetails extends Message<NuxDetails> {
  /**
   * @generated from field: string stepName = 1;
   */
  stepName = "";

  /**
   * @generated from field: string state = 2;
   */
  state = "";

  constructor(data?: PartialMessage<NuxDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.NuxDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stepName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NuxDetails {
    return new NuxDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NuxDetails {
    return new NuxDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NuxDetails {
    return new NuxDetails().fromJsonString(jsonString, options);
  }

  static equals(a: NuxDetails | PlainMessage<NuxDetails> | undefined, b: NuxDetails | PlainMessage<NuxDetails> | undefined): boolean {
    return proto3.util.equals(NuxDetails, a, b);
  }
}

