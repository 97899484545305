import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Family,
  FeedEntry,
  GetFamilyActivityResponse,
  Medium,
  Member,
} from "protogen/advisors_service_pb";
import CompositionSection from "./CompositionSection";
import { CurrentUserContext } from "../context/RequireAuth";
import { FeedFocusState } from "../../types/feed";
import { ScrollContainerHandle } from "../common/ScrollContainer";
import AttachmentDialog from "../common/AttachmentDialog";
import { useGetFamilyActivity, useGetTextMessageEntry } from "services/advisor";
import useIsVisible from "../hooks/useIsVisible";
import ContactSection from "./ContactSection";
import SuggestContactCard from "./SuggestContactCard";
import ScrollableActivityPanel from "../details-page/ScrollableActivityPanel";
import {
  Attachment,
  AccountStub,
  UserPermission,
  Advisor,
} from "protogen/common_pb";
import FeedEntryComponent from "./FeedEntryComponent";
import { dedupeFeedEntries, sortEntryTimestamps } from "./utils";
import { insertTaskSuggestion, SuggestionActionsType } from "./Suggestions";
import CenteredEntryFooter from "./CenteredEntryFooter";
import { usePushContext } from "../context/PushContextProvider";
import BookmarkToTaskDialog, {
  fromFeedEntry,
} from "../tasks/BookmarkToTaskDialog";
import { useRetrySendTextMessage } from "services/textMessage";

const determineMostRecentMemberContacted = (
  members: Member[],
  entries: FeedEntry[],
): [Member | null, boolean] => {
  const memberRefs = new Set(members.map((m) => m.ref));
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    let memberContacted: Member | null = null;
    members.forEach((m) => {
      if (entry.senderRef === m.ref || entry.recipientRefs.includes(m.ref)) {
        memberContacted = m;
      }
    });
    if (memberContacted) {
      return [
        memberContacted,
        entry.recipientRefs.length > 1 &&
          entry.recipientRefs.every((r) => memberRefs.has(r)),
      ];
    }
  }
  return [null, false];
};

const useSMSRetry = () => {
  const { request } = useRetrySendTextMessage();
  const handleRetry = async (messageRef: string) => {
    const response = await request({ messageRef });
    if (!response) {
      return null;
    }
    const message = response.textMessage;
    return new FeedEntry({
      ref: message.ref,
      senderRef: message.senderRef,
      timestampSec: message.timestampSec,
      medium: Medium.SMS,
      textMessage: message,
      recipientRefs: [],
    });
  };
  return { handleRetry };
};

type Props = {
  family: Family;
  showMessages: boolean;
  suggestionActions: SuggestionActionsType;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
};

export default ({
  family,
  suggestionActions,
  primaryAdvisor,
  advisorPermissions,
}: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activityRef, setActivityRef] = useState<string | null>(
    searchParams.get("activityRef"),
  );
  const [isCenteredEntry, setIsCenteredEntry] = useState(activityRef !== null);
  const currentUser = useContext(CurrentUserContext);
  const { registerHandler, unregisterHandler } = usePushContext();
  const hasLoadedOnce = useRef(false);
  const [feedFocusState, setFeedFocusState] = useState<FeedFocusState>({
    selectedContact: family.familyMembers[0],
    selectedMedium: null,
    selectedEntryKey: null,
    replyToEmail: null,
    editEmailDraft: null,
    isDirty: false,
    groupText: false,
  });
  const { handleRetry } = useSMSRetry();
  const scrollRef = useRef<ScrollContainerHandle | null>(null);
  const [localEntries, setLocalEntries] = useState<FeedEntry[]>([]);
  const [feedCursor, setFeedCursor] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [entries, setEntries] = useState<FeedEntry[]>([]);
  const [accountMap, setAccountMap] = useState<Record<string, AccountStub>>({
    [currentUser.ref]: currentUser,
    ...family.familyMembers.reduce((acc, m) => ({ ...acc, [m.ref]: m }), {}),
  });
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const [bookmarkEntry, setBookmarkEntry] = useState<FeedEntry | null>(null);
  const updateEntries = (r: GetFamilyActivityResponse) => {
    hasLoadedOnce.current = true;
    setEntries((pastEntries) => {
      return dedupeFeedEntries(
        [...r.entries, ...pastEntries].sort(sortEntryTimestamps),
      );
    });
    setAccountMap((pastMap) => {
      return {
        ...pastMap,
        ...r.accounts.reduce(
          (acc: Record<string, AccountStub>, account: AccountStub) => {
            acc[account.ref] = account;
            return acc;
          },
          {},
        ),
      };
    });
    if (!r.hasMore) {
      // End of the line, currently you can't get out of this state
      setHasMore(false);
    }
    // Don't blow away a good cursor on a refresh If the prev request used a cursor update the next one OR if
    // we haven't set one yet.
    if ((r.startCursor || !feedCursor) && feedCursor !== r.nextCursor) {
      setFeedCursor(() => r.nextCursor);
    }
  };
  const { request } = useGetFamilyActivity();
  const { request: fetchMessageEntry } = useGetTextMessageEntry(async (r) => {
    const response = await request({
      familyRef: family.ref,
      startCursor: `timestampSec=${BigInt(r.entry?.timestampSec ?? 0)}`,
      markConversationsRead: false,
      pageSize: 50,
      direction: "asc",
    });
    if (response) {
      updateEntries(response);
    }
    setHasMore(true);
    const resp = await request({
      familyRef: family.ref,
      startCursor: `timestampSec=${
        BigInt(r.entry?.textMessage?.timestampSec ?? 0) + BigInt(1)
      }`,
      markConversationsRead: false,
      pageSize: 10,
      direction: "desc",
    });
    if (resp) {
      updateEntries(resp);
    }
  });

  const refresh = async () => {
    const resp = await request({
      familyRef: family.ref,
      startCursor: "",
      // Only mark conversations as read if the feed is visible.
      markConversationsRead: isVisible,
      pageSize: 10,
      direction: "desc",
    });
    if (resp) {
      updateEntries(resp);
    }
  };
  const { isVisible } = useIsVisible({
    onRefocus: async (blurSecs) => {
      if (blurSecs > 10) {
        await refresh();
      }
    },
  });
  useEffect(() => {
    if (!hasLoadedOnce.current) {
      // Something is wrong here - this is loading 2x more than it should because "feedCursor" is triggering itself.
      refresh();
    }
    const intervalId = setInterval(async () => {
      if (isVisible) {
        await refresh();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [family.ref, isVisible, feedCursor]);

  useEffect(() => {
    // This handles the case where a family is updated and we may need to change the default
    // contact information.
    const updatedMembers = new Map(family.familyMembers.map((m) => [m.ref, m]));
    const selectedContact = updatedMembers.get(
      feedFocusState.selectedContact.ref,
    );
    if (selectedContact) {
      setFeedFocusState((prevState) => ({
        ...prevState,
        selectedContact: selectedContact,
      }));
    }

    // Until a user has made an explicit change (sent message, changed contact) lets update the selected contact form
    // to the latest contact who's been chatted with. This should help us avoid looking at a message from user A
    // but sending a response to user B.
    const [latestMember, isGroupMessage] = determineMostRecentMemberContacted(
      family.familyMembers,
      entries,
    );
    if (latestMember) {
      // To make sure we have the latest state, use a callback to decide if we should update selectedContact.
      setFeedFocusState((prevState) => ({
        ...prevState,
        ...(!prevState.isDirty
          ? {
              selectedContact: latestMember,
              groupText: isGroupMessage,
            }
          : {}),
      }));
    }
  }, [entries, family]);

  useEffect(() => {
    const initialActivityRef = searchParams.get("activityRef");
    if (initialActivityRef == null) return;
    if (hasLoadedOnce.current && initialActivityRef !== null) {
      // Activity feed assumes certain state on load.  If we are on the family page and want to center
      // an entry, reload the page/component.  Not ideal, but not a huge hit.
      window.location.reload();
    }
    setActivityRef(initialActivityRef);
    setIsCenteredEntry(initialActivityRef !== null);
    // remove the activityRef so on refresh the user is removed from the "centered entry mode".
    searchParams.delete("activityRef");
    navigate("." + searchParams.toString(), { replace: true });
  }, [searchParams]);

  useEffect(() => {
    if (isCenteredEntry) {
      fetchMessageEntry({
        messageRef: activityRef || "",
      });
    }
  }, []);

  useEffect(() => {
    const pushHandler = () => {
      if (isVisible) {
        refresh();
      }
    };
    registerHandler(pushHandler);

    return () => {
      unregisterHandler(pushHandler);
    };
  }, [isVisible, registerHandler, unregisterHandler]);

  // Dedupe.
  const allEntries = dedupeFeedEntries(
    [...entries, ...localEntries].sort(sortEntryTimestamps),
  );
  const suggestContactCard =
    hasLoadedOnce.current &&
    allEntries.every((entry) => entry.medium !== Medium.SMS);

  const addFeedEntry = (entry: FeedEntry) => {
    setFeedFocusState((prevState) => ({
      ...prevState,
      isDirty: true,
      selectedMedium: Medium.SMS,
      selectedEntryKey: `${entry.medium}-${entry.ref}`,
    }));
    setLocalEntries([...localEntries, entry]);
    scrollRef.current?.setSticky();
    refresh();
  };
  const fetchMoreData = async () => {
    const resp = await request({
      familyRef: family.ref,
      startCursor: feedCursor,
      markConversationsRead: false,
      pageSize: 10,
      direction: "desc",
    });
    if (resp) {
      updateEntries(resp);
    }
  };

  const createFeedEntryComponent = (e: FeedEntry, i: number): JSX.Element => {
    const isSearchEntry = activityRef === e.ref;
    return (
      <FeedEntryComponent
        key={`${e.medium}-${e.ref}`}
        suggestFact={suggestionActions.suggestFact}
        entry={e}
        feedFocusState={feedFocusState}
        setFeedFocusState={setFeedFocusState}
        accountMap={accountMap}
        openAttachment={(attachment) => {
          setOpenAttachment(attachment);
        }}
        // Be confused! We sort these in reverse from our CSS rendering which makes life more confusing.
        nextEntry={allEntries[i - 1]}
        prevEntry={allEntries[i + 1]}
        isCenteredEntry={isSearchEntry}
        onBookmark={() => setBookmarkEntry(e)}
        noAvatars={true}
        familyRef={family.ref}
        handleRetry={
          e.medium !== Medium.SMS
            ? undefined
            : async () => {
                const entry = await handleRetry(e.ref);
                if (entry) {
                  addFeedEntry(entry);
                }
              }
        }
      />
    );
  };

  const scrollEntries = useMemo(() => {
    const allEntries = dedupeFeedEntries(
      [...entries, ...localEntries].sort(sortEntryTimestamps),
    );
    const entryEls = insertTaskSuggestion(
      allEntries,
      suggestionActions,
      createFeedEntryComponent,
      {},
      family.ref,
    );
    if (isCenteredEntry) {
      entryEls.unshift(<CenteredEntryFooter key="centered-entry-footer" />);
    }
    return entryEls;
  }, [entries, localEntries]);

  if (suggestContactCard) {
    scrollEntries.push(
      <SuggestContactCard
        key={"suggest-contact-card"}
        family={family}
        recipient={feedFocusState.selectedContact}
        onShared={addFeedEntry}
      />,
    );
  }
  return (
    <>
      <ScrollableActivityPanel
        headerPanel={
          <ContactSection
            family={family}
            feedFocusState={feedFocusState}
            setFeedFocusState={(s) => {
              setFeedFocusState(s);
            }}
          />
        }
        scrollEntries={scrollEntries}
        fetchScrollEntries={fetchMoreData}
        hasMoreScrollEntries={hasMore}
        scrollRef={scrollRef}
        footerPanel={
          <CompositionSection
            addFeedEntry={addFeedEntry}
            removeFeedEntry={(ref, med) => {
              const clean = (l: FeedEntry[]) =>
                l.filter((x) => !(x.ref === ref && x.medium === med));
              setLocalEntries(clean);
              setEntries(clean);
            }}
            family={family}
            feedFocusState={feedFocusState}
            setFeedFocusState={setFeedFocusState}
            primaryAdvisor={primaryAdvisor}
            advisorPermissions={advisorPermissions}
          />
        }
      />
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
      <BookmarkToTaskDialog
        familyRef={family.ref}
        open={!!bookmarkEntry}
        onClose={() => {
          setBookmarkEntry(null);
          refresh();
        }}
        entry={bookmarkEntry ? fromFeedEntry(bookmarkEntry) : null}
      />
    </>
  );
};
