import { useState } from "react";
import * as React from "react";
import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { Check } from "lucide-react";

export interface SnackbarMessage {
  message: string;
  key: number | string;
  // Overrides the default alertSeverity.
  alertSeverity?: AlertColor;
  saveNotification?: boolean;
}

type SnackpackProps = {
  snackPack: readonly SnackbarMessage[];
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
  alertSeverity?: AlertColor;
  sx?: SxProps;
};

export default ({
  snackPack,
  autoHideDuration = 6000,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  alertSeverity = "success",
  sx = {},
}: SnackpackProps) => {
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const localSnacks = snackPack.slice(offset);
    if (localSnacks.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...localSnacks[0] });
      setOffset((prev) => prev + 1);
      setOpen(true);
    } else if (localSnacks.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const saveNotification = messageInfo && messageInfo.saveNotification;
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      sx={{ ...sx }}
    >
      {!saveNotification ? (
        <Alert
          onClose={handleClose}
          severity={(messageInfo && messageInfo.alertSeverity) || alertSeverity}
          sx={{
            width: "100%",
            borderRadius: "8px",
          }}
        >
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "200px",
            borderRadius: "8px",
            padding: "14px",
            border: "1px solid #C6E3C3",
            background: "#EEF8ED",
            alignItems: "center",
          }}
        >
          <Check color="#468E3E" />
          <Typography variant="bodySmall" color="#468E3E">
            {messageInfo?.message}
          </Typography>
        </Box>
      )}
    </Snackbar>
  );
};
