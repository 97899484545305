import Link, { LinkOptions } from "@tiptap/extension-link";

// Extend the LinkOptions interface to include the new emailUrl option
interface CustomLinkOptions extends LinkOptions {
  emailUrl?: null | ((email: string) => string);
  // phoneUrl?: (phone: string) => string;
}

export default Link.extend<CustomLinkOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      emailUrl: null,
      // phoneUrl: (email: string) => `/email?email=${encodeURIComponent(email)}`, // Default email URL format
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-k": () => {
        const previousUrl = this.editor.getAttributes("link").href;
        const url = window.prompt("URL", previousUrl || "https://");

        // cancelled
        if (url === null) return this.editor.chain().focus().run();

        // empty
        if (url === "") {
          return this.editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .unsetLink()
            .run();
        }

        // update link
        return this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    const href = HTMLAttributes.href;

    // Check if the link is an email
    if (this.options.emailUrl && href && href.startsWith("mailto:")) {
      // Modify the href to use the provided emailUrl option
      HTMLAttributes.href = this.options.emailUrl(href.replace("mailto:", ""));
    }

    return ["a", HTMLAttributes, 0];
  },
});
