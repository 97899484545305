import React, { useEffect, useState } from "react";

export default (ref: React.RefObject<HTMLElement>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [lastSelectionTime, setLastSelectionTime] = useState<number>(0);

  useEffect(() => {
    const handleMouseUp = () => {
      const hasSelection = window.getSelection()?.toString() !== "";
      if (hasSelection) {
        setLastSelectionTime(Date.now());
      }
    };

    const handleClick = (event: MouseEvent) => {
      const now = Date.now();
      if (now - lastSelectionTime <= 250) {
        return;
      }

      setIsFocused(
        !!(ref.current && ref.current.contains(event.target as Node)),
      );
    };
    document.addEventListener("click", handleClick);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ref, lastSelectionTime]);

  return {
    isFocused,
  };
};
