import useProtoMethod from "./useProtoMethod";
import {
  CreateCalendarEventRequest,
  CreateCalendarEventResponse,
  DeleteCalendarEventResponse,
  ListCalendarEventsRequest,
  ListCalendarEventsResponse,
  UpdateCalendarEventRequest,
  UpdateCalendarEventResponse,
  UpdateAttendeeStatusRequest,
  UpdateAttendeeStatusResponse,
  GetCalendarEventRequest,
  GetCalendarEventResponse,
  DeleteCalendarEventRequest,
  GetCalendarIntegrationsResponse,
  GetCalendarIntegrationsRequest,
  CreateCalendarIntegrationResponse,
  CreateCalendarIntegrationRequest,
  UpdateCalendarIntegrationResponse,
  UpdateCalendarIntegrationRequest,
  GenerateICSUrlRequest,
  GenerateICSUrlResponse,
} from "protogen/calendar_service_pb";

const useCreateCalendarEvent = (
  callback?: (r: CreateCalendarEventResponse) => void,
) => {
  return useProtoMethod<
    CreateCalendarEventRequest,
    CreateCalendarEventResponse
  >("CreateCalendarEvent", CreateCalendarEventResponse, { callback });
};

const useListCalendarEvents = (
  callback?: (r: ListCalendarEventsResponse) => void,
) => {
  return useProtoMethod<ListCalendarEventsRequest, ListCalendarEventsResponse>(
    "ListCalendarEvents",
    ListCalendarEventsResponse,
    { callback },
  );
};

const useUpdateCalendarEvent = (
  callback?: (r: UpdateCalendarEventResponse) => void,
) => {
  return useProtoMethod<
    UpdateCalendarEventRequest,
    UpdateCalendarEventResponse
  >("UpdateCalendarEvent", UpdateCalendarEventResponse, { callback });
};

const useDeleteCalendarEvent = (
  callback?: (r: DeleteCalendarEventResponse) => void,
) => {
  return useProtoMethod<
    DeleteCalendarEventRequest,
    DeleteCalendarEventResponse
  >("DeleteCalendarEvent", DeleteCalendarEventResponse, { callback });
};

const useHandleCalendarEvent = (
  callback?: (r: UpdateAttendeeStatusResponse) => void,
) => {
  return useProtoMethod<
    UpdateAttendeeStatusRequest,
    UpdateAttendeeStatusResponse
  >("UpdateAttendeeStatusResponse", UpdateAttendeeStatusResponse, { callback });
};

const useGetCalendarEvent = (
  callback?: (r: GetCalendarEventResponse) => void,
) => {
  return useProtoMethod<GetCalendarEventRequest, GetCalendarEventResponse>(
    "GetCalendarEvent",
    GetCalendarEventResponse,
    { callback },
  );
};

const useGetCalendarIntegrations = (
  callback?: (r: GetCalendarIntegrationsResponse) => void,
) => {
  return useProtoMethod<
    GetCalendarIntegrationsRequest,
    GetCalendarIntegrationsResponse
  >("GetCalendarIntegrations", GetCalendarIntegrationsResponse, { callback });
};

const useCreateCalendarIntegration = (
  callback?: (r: CreateCalendarIntegrationResponse) => void,
) => {
  return useProtoMethod<
    CreateCalendarIntegrationRequest,
    CreateCalendarIntegrationResponse
  >("CreateCalendarIntegration", CreateCalendarIntegrationResponse, {
    callback,
  });
};

const useUpdateCalendarIntegration = (
  callback?: (r: UpdateCalendarIntegrationResponse) => void,
) => {
  return useProtoMethod<
    UpdateCalendarIntegrationRequest,
    UpdateCalendarIntegrationResponse
  >("UpdateCalendarIntegration", UpdateCalendarIntegrationResponse, {
    callback,
  });
};

const useGenerateICSUrl = (callback?: (r: GenerateICSUrlResponse) => void) => {
  return useProtoMethod<GenerateICSUrlRequest, GenerateICSUrlResponse>(
    "GenerateICSUrl",
    GenerateICSUrlResponse,
    {
      callback,
    },
  );
};

export {
  useCreateCalendarEvent,
  useListCalendarEvents,
  useUpdateCalendarEvent,
  useDeleteCalendarEvent,
  useHandleCalendarEvent,
  useGetCalendarEvent,
  useGetCalendarIntegrations,
  useCreateCalendarIntegration,
  useUpdateCalendarIntegration,
  useGenerateICSUrl,
};
