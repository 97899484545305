import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { Box } from "@mui/system";
import { Button, Checkbox, TextareaAutosize, Typography } from "@mui/material";
import { Circle, CircleCheck, ExternalLink } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Collapse from "@mui/material/Collapse";
import { isStandaloneWebapp, isTouchDevice } from "../../../common/utils";
import LinkRouter from "../../navigation/LinkRouter";

type Detail = {
  description: string;
  value: string;
  isNew?: boolean;
  entityUrl?: string;
};

// interface DetailsAttributes extends TipTapNodeAttr {
//   details: Detail[];
// }

const Description = ({
  detail,
  entityUrlsEnabled,
}: {
  detail: Detail;
  entityUrlsEnabled: boolean;
}) => {
  const isApp = isStandaloneWebapp();
  if (entityUrlsEnabled && detail.entityUrl && detail.value) {
    return (
      <LinkRouter targetNew={!isApp} to={detail.entityUrl} sx={{ padding: 0 }}>
        {detail.description}
        <ExternalLink size={12} style={{ marginLeft: "4px" }} />
      </LinkRouter>
    );
  } else {
    return <Box sx={{ cursor: "default" }}>{detail.description}</Box>;
  }
};

const ListEntry = ({
  detail,
  onChanges,
  addDetail,
  deleteDetail,
  entityUrlsEnabled,
}: {
  detail: Detail;
  onChanges: (d: Detail) => void;
  deleteDetail: () => void;
  addDetail?: () => void;
  entityUrlsEnabled: boolean;
}) => {
  const isTouch = isTouchDevice();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [preventNextChange, setPreventNextChange] = useState(false);
  const [value, setValue] = useState(detail.value);
  const [open, setOpen] = useState(!!detail.value || !detail.description);
  const handleTypingStop = useCallback(
    (d: Detail) =>
      onChanges({
        ...d,
        isNew: false,
        // Clear references if value is cleared.
        ...(!d.value ? { entityUrl: "" } : {}),
      }),
    [detail.entityUrl, detail.value, detail.description],
  );

  // Create a debounced version of the function
  const debouncedHandleTypingStop = useCallback(
    _.debounce(handleTypingStop, 200),
    [],
  );
  useEffect(() => {
    if (detail.isNew) {
      inputRef.current?.focus();
    }
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{
        width: "100%",
        alignItems: "start",
        gap: "8px",
      }}
    >
      <Checkbox
        sx={{ padding: 0 }}
        icon={<Circle color={"#F2F2F2"} fill={"#F2F2F2"} />}
        checkedIcon={<CircleCheck color={"#468E3E"} />}
        checked={!!detail.value}
        disabled={true}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          paddingTop: detail.description ? "3px" : undefined,
          gap: "6px",
          width: "100%",
        }}
      >
        <label>
          <Typography
            variant="body"
            style={{
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(true);
              inputRef.current?.focus();
            }}
          >
            <Description
              detail={detail}
              entityUrlsEnabled={entityUrlsEnabled}
            />
          </Typography>
          <Collapse in={open} sx={{ marginBottom: "-6px" }}>
            <TextareaAutosize
              defaultValue={value}
              minRows={1}
              style={{
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "12px 12px 0 12px",
                border: "none",
                outline: "none",
                resize: "none",
                fontFamily: "AlbertSans",
                fontSize: "16px",
                fontWeight: "500",
                fontStyle: "normal",
                color: "#3D3D3D",
              }}
              ref={inputRef}
              onBlur={() => {
                setOpen(!!value || !detail.description);
                if (!detail.description && !value) {
                  // Self destruct
                  deleteDetail();
                }
              }}
              onChange={(e) => {
                if (preventNextChange) {
                  setPreventNextChange(false);
                  return;
                }
                setValue(e.target.value);
                debouncedHandleTypingStop({
                  description: detail.description,
                  value: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                // make TS happy.
                // @ts-ignore
                if (addDetail && deleteDetail && isTouch && e) {
                  return;
                }
                // const textarea: HTMLTextAreaElement =
                //   e.target as HTMLTextAreaElement;
                // const isAtEnd = textarea.selectionEnd === textarea.value.length;
                // // if backspace is pressed and the value is empty, delete the detail
                // if (e.key === "Backspace" && !textarea.value) {
                //   deleteDetail();
                //   return true;
                // }
                // if (
                //   addDetail &&
                //   isAtEnd &&
                //   textarea.value?.trim() &&
                //   e.key === "Enter" &&
                //   !e.shiftKey &&
                //   !isTouch
                // ) {
                //   addDetail();
                //   e.stopPropagation();
                //   setPreventNextChange(true);
                //   return false;
                // }
              }}
            />
          </Collapse>
        </label>
      </Box>
    </Box>
  );
};

const Component: React.FC<NodeViewProps> = ({
  node,
  updateAttributes,
  extension,
}) => {
  const entityUrlsEnabled = extension?.options?.entityUrlsEnabled ?? false;
  const [details, setDetails] = useState<Detail[]>(node.attrs.details);
  const deleteDetail = (idx: number) => {
    const newDetails = [...details];
    newDetails.splice(idx, 1);
    setDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(idx, 1);
      return newDetails;
    });
  };
  const handleUpdates = (d: Detail, idx: number) => {
    setDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[idx] = {
        ...newDetails[idx],
        ...d,
      };
      return newDetails;
    });
  };
  const addDetail = () => {
    setDetails((prevDetails) => {
      return [
        ...prevDetails,
        {
          description: "",
          value: "",
          isNew: true,
        },
      ];
    });
  };
  useEffect(() => {
    updateAttributes({ details: details });
  }, [details]);
  return (
    <NodeViewWrapper className="tiptap-details-list">
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        alignItems={"start"}
      >
        {details.map((v, i) => (
          <ListEntry
            key={i}
            detail={v}
            onChanges={(d) => handleUpdates(d, i)}
            deleteDetail={() => deleteDetail(i)}
            addDetail={i == details.length - 1 ? addDetail : undefined}
            entityUrlsEnabled={entityUrlsEnabled}
          />
        ))}
        <Button variant={"text"} sx={{ height: "unset" }} onClick={addDetail}>
          Add new
        </Button>
      </Box>
    </NodeViewWrapper>
  );
};

export interface ExtOptions {
  entityUrlsEnabled: boolean;
}

export default Node.create<ExtOptions>({
  name: "detailsList",
  group: "block",
  atom: true,
  draggable: true,
  selectable: true,

  addOptions() {
    return {
      entityUrlsEnabled: false,
    };
  },

  addAttributes() {
    return {
      details: {
        default: [],
        parseHTML: (element) => {
          const data = element.getAttribute("data-details");
          try {
            return (data && JSON.parse(data)) || [];
          } catch (e) {
            return [];
          }
        },
        renderHTML: (attributes) => {
          return {
            "data-details": JSON.stringify(attributes.details),
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "tiptap-details-list",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["tiptap-details-list", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
