import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { SxProps, Theme } from "@mui/system";

type AppBarSxContextType = {
  sx: SxProps<Theme>;
  setSx: (newSx: SxProps<Theme>) => void;
};

const defaultSx: SxProps<Theme> = {};

const AppBarSxContext = createContext<AppBarSxContextType>({
  sx: defaultSx,
  setSx: () => {},
});

export const useAppBarSx = () => useContext(AppBarSxContext);

export const AppBarSxProvider: React.FC<{ children: React.ReactNode }> =
  React.memo(({ children }) => {
    const [sx, setSxState] = useState<SxProps<Theme>>(defaultSx);

    const setSx = useCallback((newSx: SxProps<Theme>) => {
      setSxState((prevSx: SxProps<Theme>) => {
        if (JSON.stringify(prevSx) !== JSON.stringify(newSx)) {
          return newSx;
        }
        return prevSx;
      });
    }, []);

    const value = useMemo(() => ({ sx, setSx }), [sx, setSx]);

    return (
      <AppBarSxContext.Provider value={value}>
        {children}
      </AppBarSxContext.Provider>
    );
  });
