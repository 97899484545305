import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

export const CommonTileProps = {
  style: { color: "#198282", strokeWidth: "1.75" },
  size: 32,
};

export default ({
  title,
  content,
}: {
  title: string | ReactNode;
  content: string | ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginBottom: "32px",
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body">{content}</Typography>
    </Box>
  );
};
