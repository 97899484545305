import GridPage from "components/layout/GridPage";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useIsMobile from "components/hooks/useIsMobile";
import { useGenerateReport, useListReports } from "services/reports";
import {
  GenerateReportResponse,
  Report,
  ReportColumn,
  ReportParameter,
} from "protogen/reports_service_pb";
import { DatePicker } from "@mui/x-date-pickers";
import FamilySelect from "components/creation/FamilySelect";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ReportChart } from "components/common/reporting/ReportCharts";
import {
  ChartBar,
  ChartLine,
  ChartPie,
  FileSpreadsheet,
  Maximize2,
} from "lucide-react";

const parseMarkdownLink = (markdownLink: string): [string, string] => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/;
  const match = markdownLink.match(regex);

  if (match && match.length === 3) {
    return [match[1], match[2]];
  }

  return ["", ""];
};

const getFormattedDate = (reportName: string): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yy = String(today.getFullYear()).slice(-2);
  return `${reportName.replaceAll(/\s/g, "")}.${yy}${mm}${dd}.csv`;
};

const convertToCSV = (response: GenerateReportResponse) => {
  const escapeValue = (value: string): string => {
    if (
      value.includes(",") ||
      value.includes('"') ||
      value.includes("\n") ||
      value.includes("\r")
    ) {
      value = value.replace(/"/g, '""');
      return `"${value}"`;
    }
    return value;
  };
  const escapeCell = (value: string, col: ReportColumn): string => {
    if (col.type === "url") {
      const [label] = parseMarkdownLink(value);
      return escapeValue(label);
    } else {
      return escapeValue(value);
    }
  };

  const rows = [
    response.columns.map((c) => escapeValue(c.name)).join(","),
    ...response.rows.map((row) =>
      row.values
        .map((c, idx) => escapeCell(c, response.columns[idx]))
        .join(","),
    ),
  ];
  return rows.join("\r\n");
};

const downloadCSV = (report: Report, response: GenerateReportResponse) => {
  const csvData = convertToCSV(response);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", getFormattedDate(report.name));
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

type ReportsSelectProps = {
  value: string;
  loading: boolean;
  error: boolean;
  setValue: (value: string) => void;
  options: Record<string, { label: string; value: string; type?: string }>;
};
const ReportsSelect = ({
  value,
  loading,
  error,
  setValue,
  options,
}: ReportsSelectProps) => {
  const optionsArray = Object.values(options).sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  const selectedOption = options[value] || null;

  // Function to determine the icon based on report type
  const getReportIcon = (type: string | undefined) => {
    switch (type) {
      case "bar":
        return <ChartBar />;
      case "line":
        return <ChartLine />;
      case "pie":
        return <ChartPie />;
      default:
        return <FileSpreadsheet />;
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <Autocomplete
          sx={{ width: "100%", cursor: "pointer" }}
          options={optionsArray}
          getOptionLabel={(option) => option.label}
          loading={loading}
          disabled={loading}
          value={selectedOption}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue.value);
            } else {
              setValue("");
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {getReportIcon(option.type)}
              <Box sx={{ ml: 1 }}>{option.label}</Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reports"
              error={error}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: selectedOption ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {getReportIcon(selectedOption?.type)}
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

const ReportData = ({ report }: { report: GenerateReportResponse }) => {
  const columns: GridColDef[] = report.columns.map((column, idx) => {
    const field = `field${idx}`;
    let type: GridColDef["type"] = "string";
    let valueFormatter: GridColDef["valueFormatter"];
    let renderCell: GridColDef["renderCell"];

    if (column.type === "number") {
      type = "number";
      valueFormatter = (val) => {
        const value = parseFloat(val);
        if (isNaN(value)) {
          return "";
        }
        return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
      };
    } else if (column.type === "percentage") {
      type = "number";
      valueFormatter = (val) => {
        const value = parseFloat(val);
        if (isNaN(value)) {
          return "";
        }
        return `${(100 * value).toFixed(1)}%`;
      };
    } else if (column.type === "currency") {
      type = "number";
      valueFormatter = (val) => {
        const value = parseFloat(val);
        if (isNaN(value)) {
          return "";
        }
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      };
    } else if (column.type === "date") {
      type = "date";
      valueFormatter = (val) => {
        const date = new Date(val);
        return isNaN(date.getTime()) ? "" : date.toLocaleDateString("en-US");
      };
    } else if (column.type === "url") {
      type = "string";
      renderCell = (params: GridRenderCellParams<any>) => {
        const [label, url] = parseMarkdownLink(params.value);
        return (
          <Link href={url} target="_blank" rel="noopener">
            {label}
          </Link>
        );
      };
    }

    return {
      field,
      headerName: column.name,
      type,
      sortable: true,
      valueFormatter,
      renderCell,
      editable: false,
      flex: 1, // Add flex to make columns expand
    };
  });

  const rows = report.rows.map((row, rowIndex) => {
    const rowData: { id: number } & { [key: string]: any } = { id: rowIndex };
    row.values.forEach((value, colIndex) => {
      rowData[`field${colIndex}`] = value;
    });
    return rowData;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        sortingOrder={["asc", "desc"]}
        disableRowSelectionOnClick
        sx={{
          "& .MuiDataGrid-cell": {
            userSelect: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            top: 0,
            backgroundColor: "background.paper",
            zIndex: 1,
          },
        }}
      />
    </Box>
  );
};

const DateSelect = ({
  label,
  disabled,
  value,
  update,
  error,
  format,
}: {
  label: string;
  disabled: boolean;
  value?: Date;
  update: (d: Date | null) => void;
  error?: string | null;
  format?: string | null;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <FormControl sx={{ width: "100%" }}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        sx={{ width: "100%", ".MuiButtonBase-root": { marginRight: "unset" } }}
        onChange={update}
        format={format ? format : "MM/dd/yyyy"}
        open={dateOpen}
        onClose={() => setDateOpen(false)}
        slotProps={{
          textField: {
            error: !!error,
            helperText: error,
            onClick: () => setDateOpen(true),
          },
        }}
      />
    </FormControl>
  );
};

const GenerationParameter = ({
  paramDefinition,
  parameters,
  loading,
  setValue,
}: {
  paramDefinition: ReportParameter;
  parameters: Record<string, any>;
  loading: boolean;
  setValue: (v: Record<string, any>) => void;
}) => {
  if (paramDefinition.type === "date") {
    return (
      <DateSelect
        label={paramDefinition.label}
        value={parameters[paramDefinition.name] || undefined}
        disabled={loading}
        update={(d) => {
          setValue({ [paramDefinition.name]: d });
        }}
      />
    );
  }
  if (paramDefinition.type === "family") {
    return (
      <FamilySelect
        adminMode={true}
        disabled={loading}
        selectedFamily={parameters[paramDefinition.name] || null}
        onChange={(v) => {
          setValue({ [paramDefinition.name]: v });
        }}
      />
    );
  }
  return null;
};

const updateParametersForReport = (
  report: Report,
  parameters: Record<string, any>,
): Record<string, any> => {
  const keepKeys = new Set(report.explicitParameters.map((p) => p.name));
  const newParams = Object.keys(parameters)
    .filter((k: string) => keepKeys.has(k))
    .map((k) => [k, parameters[k]])
    .reduce(
      (obj, [key, value]) => {
        obj[key] = value;
        return obj;
      },
      {} as Record<string, any>,
    );
  for (const paramDefinition of report.explicitParameters) {
    if (paramDefinition.defaultValue && !newParams[paramDefinition.name]) {
      if (paramDefinition.type === "date") {
        const now = new Date();
        const [part, transform] = paramDefinition.defaultValue.split(":");
        if (part === "day") {
          now.setTime(
            now.getTime() + 1000 * 60 * 60 * 24 * parseInt(transform),
          );
        } else if (part === "month") {
          now.setMonth(now.getMonth() + parseInt(transform));
        } else if (part === "year") {
          now.setFullYear(now.getFullYear() + parseInt(transform));
        }
        newParams[paramDefinition.name] = now;
      }
    }
  }
  return newParams;
};

export default () => {
  const isMobile = useIsMobile();
  let params = useParams();
  const navigate = useNavigate();
  const { request: generate, loading: generating, error } = useGenerateReport();
  const [report, setReport] = useState<Report | null>(null);
  const [parameters, setParameters] = useState<Record<string, any>>({});
  const [reportData, setReportData] = useState<GenerateReportResponse | null>(
    null,
  );
  // State variable to control the expandable area
  const [dataExpanded, setDataExpanded] = useState(false);
  const {
    request: listReports,
    data: reports,
    loading: listing,
  } = useListReports((r) => {
    if (r.reports.length > 0) {
      const initialReport = r.reports.find(
        (report) => report.ref === params.reportName,
      );
      if (initialReport && !report) {
        setReport(initialReport);
      }
    }
  });

  useEffect(() => {
    listReports();
  }, []);

  const isValid = () => {
    if (report === null) return false;
    for (const param of report.explicitParameters) {
      if (parameters[param.name] === undefined) return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (isValid()) {
      const resp = await generate({
        reportRef: report?.ref || "",
        parameters,
      });
      setReportData(resp);
    }
  };

  const loading = generating || listing;
  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        alignItems: "start",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Generate reports
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          gap: 2,
          minWidth: "300px",
          marginTop: "30px",
          width: "100%",
        }}
      >
        <ReportsSelect
          loading={loading}
          error={false}
          value={report?.ref || ""}
          setValue={(value) => {
            const newReport =
              (reports?.reports || []).find((report) => report.ref === value) ||
              null;
            if (newReport && report?.ref !== newReport.ref) {
              setParameters((r) => updateParametersForReport(newReport, r));
            } else if (newReport) {
              setParameters({});
            }
            setReport(newReport);
            setReportData(null);
            setDataExpanded(false);
            navigate("/tools/reports/" + (newReport?.ref || ""), {
              replace: true,
            });
          }}
          options={(reports?.reports || []).reduce(
            (
              acc: Record<
                string,
                { label: string; value: string; type?: string }
              >,
              report,
            ) => {
              acc[report.ref] = {
                label: report.name,
                value: report.ref,
                type: report?.chartDefinition?.type,
              };
              return acc;
            },
            {},
          )}
        />
        {report && (
          <>
            <Alert severity="info" variant="outlined">
              <AlertTitle>{report.name}</AlertTitle>
              {report.description}
            </Alert>
            {report.explicitParameters.map((paramDefinition) => (
              <GenerationParameter
                key={paramDefinition.name}
                paramDefinition={paramDefinition}
                parameters={parameters}
                loading={loading}
                setValue={(v) => {
                  setParameters((p) => ({ ...p, ...v }));
                }}
              />
            ))}
          </>
        )}
        {error && !generating && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was an error generating your report. Please try again.
          </Alert>
        )}
        <Button onClick={onSubmit} disabled={loading || !isValid()}>
          Generate Report
        </Button>
      </Box>
      {report && reportData && !generating && (
        <Box
          sx={{
            marginTop: "36px",
            overflowX: "scroll",
            width: "100%",
            ...(!isMobile ? { minWidth: "600px" } : {}),
          }}
        >
          {report.chartDefinition?.type ? (
            <>
              <ReportChart report={report} reportData={reportData} />
              {!dataExpanded && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px 25px",
                    marginTop: "25px",
                    borderColor: "rgb(224, 224, 224)",
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderRadius: "4px",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => setDataExpanded(true)}
                    startIcon={<Maximize2 />}
                  >
                    Show Data
                  </Button>
                </Box>
              )}
            </>
          ) : null}
          {(dataExpanded || !report.chartDefinition?.type) && (
            <>
              <ReportData report={reportData} />
              <Box
                justifyContent="start"
                flexDirection="row"
                sx={{
                  display: reportData.rows.length === 0 ? "none" : "flex",
                  marginTop: "24px",
                  width: "100%",
                }}
                gap={"16px"}
              >
                <Button onClick={() => downloadCSV(report, reportData)}>
                  Download
                </Button>
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(convertToCSV(reportData))
                  }
                  variant="outlined"
                >
                  Copy to Clipboard
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </GridPage>
  );
};
