// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/extraction_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { SuggestionEntityType, Suggestions } from "./suggestions_pb.ts";

/**
 * @generated from message protogen.ExtractionFunction
 */
export class ExtractionFunction extends Message<ExtractionFunction> {
  /**
   * @generated from field: string functionName = 1;
   */
  functionName = "";

  /**
   * @generated from field: string functionTitle = 2;
   */
  functionTitle = "";

  constructor(data?: PartialMessage<ExtractionFunction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ExtractionFunction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "functionName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "functionTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExtractionFunction {
    return new ExtractionFunction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExtractionFunction {
    return new ExtractionFunction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExtractionFunction {
    return new ExtractionFunction().fromJsonString(jsonString, options);
  }

  static equals(a: ExtractionFunction | PlainMessage<ExtractionFunction> | undefined, b: ExtractionFunction | PlainMessage<ExtractionFunction> | undefined): boolean {
    return proto3.util.equals(ExtractionFunction, a, b);
  }
}

/**
 * @generated from message protogen.ExtractionResult
 */
export class ExtractionResult extends Message<ExtractionResult> {
  /**
   * @generated from field: string functionName = 1;
   */
  functionName = "";

  /**
   * @generated from field: string payload = 2;
   */
  payload = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string subtitle = 4;
   */
  subtitle = "";

  constructor(data?: PartialMessage<ExtractionResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ExtractionResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "functionName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subtitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExtractionResult {
    return new ExtractionResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExtractionResult {
    return new ExtractionResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExtractionResult {
    return new ExtractionResult().fromJsonString(jsonString, options);
  }

  static equals(a: ExtractionResult | PlainMessage<ExtractionResult> | undefined, b: ExtractionResult | PlainMessage<ExtractionResult> | undefined): boolean {
    return proto3.util.equals(ExtractionResult, a, b);
  }
}

/**
 * @generated from message protogen.GPTExtractionFromSegmentsRequest
 */
export class GPTExtractionFromSegmentsRequest extends Message<GPTExtractionFromSegmentsRequest> {
  /**
   * @generated from field: string functionName = 1;
   */
  functionName = "";

  /**
   * @generated from field: string callRef = 2;
   */
  callRef = "";

  /**
   * @generated from field: string cursor = 3;
   */
  cursor = "";

  constructor(data?: PartialMessage<GPTExtractionFromSegmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GPTExtractionFromSegmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "functionName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GPTExtractionFromSegmentsRequest {
    return new GPTExtractionFromSegmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsRequest {
    return new GPTExtractionFromSegmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsRequest {
    return new GPTExtractionFromSegmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GPTExtractionFromSegmentsRequest | PlainMessage<GPTExtractionFromSegmentsRequest> | undefined, b: GPTExtractionFromSegmentsRequest | PlainMessage<GPTExtractionFromSegmentsRequest> | undefined): boolean {
    return proto3.util.equals(GPTExtractionFromSegmentsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GPTExtractionFromSegmentsResponse
 */
export class GPTExtractionFromSegmentsResponse extends Message<GPTExtractionFromSegmentsResponse> {
  /**
   * @generated from field: repeated protogen.ExtractionResult results = 1;
   */
  results: ExtractionResult[] = [];

  /**
   * @generated from field: string cursor = 2;
   */
  cursor = "";

  constructor(data?: PartialMessage<GPTExtractionFromSegmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GPTExtractionFromSegmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: ExtractionResult, repeated: true },
    { no: 2, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GPTExtractionFromSegmentsResponse {
    return new GPTExtractionFromSegmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsResponse {
    return new GPTExtractionFromSegmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsResponse {
    return new GPTExtractionFromSegmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GPTExtractionFromSegmentsResponse | PlainMessage<GPTExtractionFromSegmentsResponse> | undefined, b: GPTExtractionFromSegmentsResponse | PlainMessage<GPTExtractionFromSegmentsResponse> | undefined): boolean {
    return proto3.util.equals(GPTExtractionFromSegmentsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GPTExtractionFromSegmentsPromptRequest
 */
export class GPTExtractionFromSegmentsPromptRequest extends Message<GPTExtractionFromSegmentsPromptRequest> {
  /**
   * @generated from field: string promptName = 1;
   */
  promptName = "";

  /**
   * @generated from field: string callRef = 2;
   */
  callRef = "";

  constructor(data?: PartialMessage<GPTExtractionFromSegmentsPromptRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GPTExtractionFromSegmentsPromptRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promptName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GPTExtractionFromSegmentsPromptRequest {
    return new GPTExtractionFromSegmentsPromptRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsPromptRequest {
    return new GPTExtractionFromSegmentsPromptRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsPromptRequest {
    return new GPTExtractionFromSegmentsPromptRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GPTExtractionFromSegmentsPromptRequest | PlainMessage<GPTExtractionFromSegmentsPromptRequest> | undefined, b: GPTExtractionFromSegmentsPromptRequest | PlainMessage<GPTExtractionFromSegmentsPromptRequest> | undefined): boolean {
    return proto3.util.equals(GPTExtractionFromSegmentsPromptRequest, a, b);
  }
}

/**
 * @generated from message protogen.GPTExtractionFromSegmentsPromptResponse
 */
export class GPTExtractionFromSegmentsPromptResponse extends Message<GPTExtractionFromSegmentsPromptResponse> {
  /**
   * @generated from field: string result = 1;
   */
  result = "";

  constructor(data?: PartialMessage<GPTExtractionFromSegmentsPromptResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GPTExtractionFromSegmentsPromptResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GPTExtractionFromSegmentsPromptResponse {
    return new GPTExtractionFromSegmentsPromptResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsPromptResponse {
    return new GPTExtractionFromSegmentsPromptResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GPTExtractionFromSegmentsPromptResponse {
    return new GPTExtractionFromSegmentsPromptResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GPTExtractionFromSegmentsPromptResponse | PlainMessage<GPTExtractionFromSegmentsPromptResponse> | undefined, b: GPTExtractionFromSegmentsPromptResponse | PlainMessage<GPTExtractionFromSegmentsPromptResponse> | undefined): boolean {
    return proto3.util.equals(GPTExtractionFromSegmentsPromptResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListExtractionFunctionsRequest
 */
export class ListExtractionFunctionsRequest extends Message<ListExtractionFunctionsRequest> {
  constructor(data?: PartialMessage<ListExtractionFunctionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListExtractionFunctionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListExtractionFunctionsRequest {
    return new ListExtractionFunctionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListExtractionFunctionsRequest {
    return new ListExtractionFunctionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListExtractionFunctionsRequest {
    return new ListExtractionFunctionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListExtractionFunctionsRequest | PlainMessage<ListExtractionFunctionsRequest> | undefined, b: ListExtractionFunctionsRequest | PlainMessage<ListExtractionFunctionsRequest> | undefined): boolean {
    return proto3.util.equals(ListExtractionFunctionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListExtractionFunctionsResponse
 */
export class ListExtractionFunctionsResponse extends Message<ListExtractionFunctionsResponse> {
  /**
   * @generated from field: repeated protogen.ExtractionFunction functions = 1;
   */
  functions: ExtractionFunction[] = [];

  constructor(data?: PartialMessage<ListExtractionFunctionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListExtractionFunctionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "functions", kind: "message", T: ExtractionFunction, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListExtractionFunctionsResponse {
    return new ListExtractionFunctionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListExtractionFunctionsResponse {
    return new ListExtractionFunctionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListExtractionFunctionsResponse {
    return new ListExtractionFunctionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListExtractionFunctionsResponse | PlainMessage<ListExtractionFunctionsResponse> | undefined, b: ListExtractionFunctionsResponse | PlainMessage<ListExtractionFunctionsResponse> | undefined): boolean {
    return proto3.util.equals(ListExtractionFunctionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GenerateSuggestionsRequest
 */
export class GenerateSuggestionsRequest extends Message<GenerateSuggestionsRequest> {
  /**
   * @generated from field: protogen.SuggestionEntityType entityType = 1;
   */
  entityType = SuggestionEntityType.UNKNOWN;

  /**
   * @generated from field: string entityRef = 2;
   */
  entityRef = "";

  /**
   * Optional E.g. facts/tasks/etc
   *
   * @generated from field: string suggestionType = 3;
   */
  suggestionType = "";

  constructor(data?: PartialMessage<GenerateSuggestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateSuggestionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entityType", kind: "enum", T: proto3.getEnumType(SuggestionEntityType) },
    { no: 2, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "suggestionType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateSuggestionsRequest {
    return new GenerateSuggestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateSuggestionsRequest {
    return new GenerateSuggestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateSuggestionsRequest {
    return new GenerateSuggestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateSuggestionsRequest | PlainMessage<GenerateSuggestionsRequest> | undefined, b: GenerateSuggestionsRequest | PlainMessage<GenerateSuggestionsRequest> | undefined): boolean {
    return proto3.util.equals(GenerateSuggestionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GenerateSuggestionsResponse
 */
export class GenerateSuggestionsResponse extends Message<GenerateSuggestionsResponse> {
  /**
   * @generated from field: protogen.Suggestions suggestions = 1;
   */
  suggestions?: Suggestions;

  constructor(data?: PartialMessage<GenerateSuggestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateSuggestionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestions", kind: "message", T: Suggestions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateSuggestionsResponse {
    return new GenerateSuggestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateSuggestionsResponse {
    return new GenerateSuggestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateSuggestionsResponse {
    return new GenerateSuggestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateSuggestionsResponse | PlainMessage<GenerateSuggestionsResponse> | undefined, b: GenerateSuggestionsResponse | PlainMessage<GenerateSuggestionsResponse> | undefined): boolean {
    return proto3.util.equals(GenerateSuggestionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.RunWorkflowRequest
 */
export class RunWorkflowRequest extends Message<RunWorkflowRequest> {
  /**
   * @generated from field: string workflowName = 1;
   */
  workflowName = "";

  /**
   * @generated from field: repeated protogen.RunWorkflowRequest.WorkflowArgument arguments = 2;
   */
  arguments: RunWorkflowRequest_WorkflowArgument[] = [];

  /**
   * @generated from field: protogen.SuggestionEntityType entityType = 3;
   */
  entityType = SuggestionEntityType.UNKNOWN;

  /**
   * @generated from field: string entityRef = 4;
   */
  entityRef = "";

  /**
   * @generated from field: bool runAsync = 5;
   */
  runAsync = false;

  constructor(data?: PartialMessage<RunWorkflowRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RunWorkflowRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workflowName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "arguments", kind: "message", T: RunWorkflowRequest_WorkflowArgument, repeated: true },
    { no: 3, name: "entityType", kind: "enum", T: proto3.getEnumType(SuggestionEntityType) },
    { no: 4, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "runAsync", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunWorkflowRequest {
    return new RunWorkflowRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunWorkflowRequest {
    return new RunWorkflowRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunWorkflowRequest {
    return new RunWorkflowRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunWorkflowRequest | PlainMessage<RunWorkflowRequest> | undefined, b: RunWorkflowRequest | PlainMessage<RunWorkflowRequest> | undefined): boolean {
    return proto3.util.equals(RunWorkflowRequest, a, b);
  }
}

/**
 * @generated from message protogen.RunWorkflowRequest.WorkflowArgument
 */
export class RunWorkflowRequest_WorkflowArgument extends Message<RunWorkflowRequest_WorkflowArgument> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<RunWorkflowRequest_WorkflowArgument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RunWorkflowRequest.WorkflowArgument";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunWorkflowRequest_WorkflowArgument {
    return new RunWorkflowRequest_WorkflowArgument().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunWorkflowRequest_WorkflowArgument {
    return new RunWorkflowRequest_WorkflowArgument().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunWorkflowRequest_WorkflowArgument {
    return new RunWorkflowRequest_WorkflowArgument().fromJsonString(jsonString, options);
  }

  static equals(a: RunWorkflowRequest_WorkflowArgument | PlainMessage<RunWorkflowRequest_WorkflowArgument> | undefined, b: RunWorkflowRequest_WorkflowArgument | PlainMessage<RunWorkflowRequest_WorkflowArgument> | undefined): boolean {
    return proto3.util.equals(RunWorkflowRequest_WorkflowArgument, a, b);
  }
}

/**
 * @generated from message protogen.RunWorkflowResponse
 */
export class RunWorkflowResponse extends Message<RunWorkflowResponse> {
  /**
   * @generated from field: string result = 1;
   */
  result = "";

  constructor(data?: PartialMessage<RunWorkflowResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RunWorkflowResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunWorkflowResponse {
    return new RunWorkflowResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunWorkflowResponse {
    return new RunWorkflowResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunWorkflowResponse {
    return new RunWorkflowResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RunWorkflowResponse | PlainMessage<RunWorkflowResponse> | undefined, b: RunWorkflowResponse | PlainMessage<RunWorkflowResponse> | undefined): boolean {
    return proto3.util.equals(RunWorkflowResponse, a, b);
  }
}

