import FormStep from "./FormStep";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { EphemeralAdvisor, FormErrors } from "../types";
import { ContactCard, LoadingContactCard } from "../../common/ContactCardEntry";
import { useGenerateAdvisorContacts } from "services/admin";

type Props = {
  formData: EphemeralAdvisor;
  updateFormData: (f: EphemeralAdvisor) => void;
  errors: FormErrors | null;
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
  index?: number;
};
export default ({
  formData,
  updateFormData,
  errors,
  handleNext,
  handleBack,
  activeStep,
  ...otherProps
}: Props) => {
  const { request, loading } = useGenerateAdvisorContacts((r) => {
    updateFormData({
      username: r.advisorEmail,
      advisorPhone: r.advisorPhone,
    });
  });
  const isActive = activeStep === otherProps?.index;
  useEffect(() => {
    if (isActive) {
      if (!formData.username || !formData.advisorPhone) {
        request({
          firstName: formData.firstName || "",
          lastName: formData.lastName || "",
          areaCode: formData.personalPhone
            ? formData.personalPhone?.slice(2, 5)
            : "",
        });
      }
    }
  }, [isActive]);
  return (
    <FormStep
      title="Advisor Contact"
      handleNext={handleNext}
      handleBack={handleBack}
      {...otherProps}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent={"center"}>
          {loading ? (
            <LoadingContactCard />
          ) : (
            <ContactCard
              fullName={`${formData.firstName} ${formData.lastName}`}
              tel={formData.advisorPhone || ""}
              email={formData.username || ""}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={!!errors?.username}
            helperText={errors?.username}
            label="Faye Email"
            fullWidth
            autoComplete="off"
            size="small"
            disabled={true}
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={formData.username || ""}
            // onChange={(e) => updateFormData({ username: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={!!errors?.advisorPhone}
            helperText={errors?.advisorPhone}
            label="Faye Phone"
            fullWidth
            autoComplete="off"
            size="small"
            disabled={true}
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={formData.advisorPhone || ""}
            // onChange={(e) => updateFormData({ username: e.target.value })}
          />
        </Grid>
      </Grid>
    </FormStep>
  );
};
