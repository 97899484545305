// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/billing.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.BillingProduct
 */
export class BillingProduct extends Message<BillingProduct> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: int32 amountCents = 4;
   */
  amountCents = 0;

  constructor(data?: PartialMessage<BillingProduct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.BillingProduct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amountCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BillingProduct {
    return new BillingProduct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BillingProduct {
    return new BillingProduct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BillingProduct {
    return new BillingProduct().fromJsonString(jsonString, options);
  }

  static equals(a: BillingProduct | PlainMessage<BillingProduct> | undefined, b: BillingProduct | PlainMessage<BillingProduct> | undefined): boolean {
    return proto3.util.equals(BillingProduct, a, b);
  }
}

/**
 * @generated from message protogen.BillingCoupon
 */
export class BillingCoupon extends Message<BillingCoupon> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 amountOffCents = 3;
   */
  amountOffCents = 0;

  /**
   * @generated from field: float percentOff = 4;
   */
  percentOff = 0;

  /**
   * @generated from field: string duration = 5;
   */
  duration = "";

  constructor(data?: PartialMessage<BillingCoupon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.BillingCoupon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amountOffCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "percentOff", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "duration", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BillingCoupon {
    return new BillingCoupon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BillingCoupon {
    return new BillingCoupon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BillingCoupon {
    return new BillingCoupon().fromJsonString(jsonString, options);
  }

  static equals(a: BillingCoupon | PlainMessage<BillingCoupon> | undefined, b: BillingCoupon | PlainMessage<BillingCoupon> | undefined): boolean {
    return proto3.util.equals(BillingCoupon, a, b);
  }
}

