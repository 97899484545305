import { useContext, useState, useRef } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Ellipsis, Heart } from "lucide-react";
import { ForumComment, ForumPost } from "protogen/forum_service_pb";
import DateDisplay from "../common/DateDisplay";
import { UserAvatar } from "../common/CurrentUserAvatar";
import DocumentViewer from "../editor/DocumentViewer";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdmin } from "../../common/userUtils";
import { RichContent } from "../editor/utils";
import {
  useDeleteComment,
  useEditComment,
  useEditCommentLike,
} from "services/forum";
import SimpleEditor from "../editor/SimpleEditor";
import LikerTooltip from "./LikerTooltip";
import AddComment from "components/forum/AddComment";
import PopperMenu, { createMenuOption } from "../common/PopperMenu";

type EditAreaProps = {
  comment: ForumComment;
  cancelEdit: () => void;
  onUpdate: (c: ForumComment) => void;
};
const EditArea = ({ comment, cancelEdit, onUpdate }: EditAreaProps) => {
  const _initialContent = comment.body.payload
    ? JSON.parse(comment.body.payload)
    : null;
  const [content, setContent] = useState<RichContent | null>(null);
  const { request, loading } = useEditComment((r) => {
    onUpdate(r.comment!);
  });
  const handleSave = () => {
    if (content) {
      request({
        ref: comment.ref,
        body: {
          contentType: "json",
          payload: content.json,
          textContent: content.text,
          attachments: content.attachments,
        },
      });
    }
  };
  return (
    <Box sx={{ padding: "8px 16px", width: "100%" }}>
      <SimpleEditor
        disabled={loading}
        defaultInlineAttachments={true}
        setContent={setContent}
        attachmentsEnabled={true}
        initialContent={_initialContent}
        initialAttachments={comment.body.attachments}
        onKeyDown={(_, e: KeyboardEvent) => {
          if (e.key === "Enter" && e.metaKey) {
            handleSave();
          }
        }}
        secondaryAction={
          <Button
            sx={{ marginBottom: "4px" }}
            onClick={() => cancelEdit()}
            variant="outlined"
          >
            Cancel
          </Button>
        }
        primaryAction={
          <Button
            sx={{ marginBottom: "4px" }}
            onClick={handleSave}
            disabled={loading || !content}
          >
            Save
          </Button>
        }
      />
    </Box>
  );
};

const ParentGuideline = () => {
  return (
    <Box
      sx={{
        alignSelf: "flex-start",
        marginLeft: "20px",
        flexGrow: 1,
        borderLeft: "3px solid #F2F2F2",
        marginBottom: "-32px",
      }}
    />
  );
};

const ChildGuideline = ({
  depth,
  threadGuidelines,
}: {
  threadGuidelines: boolean[];
  depth: number;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: `${depth * 52}px`,
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          marginLeft: "20px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {Array.from({ length: depth }).map((_, index) => (
          <Box
            key={index}
            sx={{
              borderLeft: threadGuidelines[index] ? "3px solid #F2F2F2" : "",
              marginBottom: "-36px",
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: "26px",
          height: "20px",
          borderBottom: "3px solid #F2F2F2",
          borderLeft: "3px solid #F2F2F2",
          borderBottomLeftRadius: "8px",
          marginRight: "6px",
          marginLeft: "-3px",
        }}
      />
    </Box>
  );
};

type Props = {
  comment: ForumComment;
  post: ForumPost;
  highlighted: boolean;
  hasParent: boolean;
  hasChildren: boolean;
  threadGuidelines: boolean[];
  onCommentCreation: (c: ForumComment) => void;
};

export default ({
  comment,
  highlighted,
  post,
  onCommentCreation,
  hasParent,
  hasChildren,
  threadGuidelines,
}: Props) => {
  const MAX_DEPTH = 2;
  const currentUser = useContext(CurrentUserContext);
  const [isDeleted, setIsDeleted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [open, setOpen] = useState(false);
  const canEdit =
    currentUser.ref === comment.author.ref || isUserAdmin(currentUser);
  const [updatedComment, setUpdatedComment] = useState<ForumComment | null>(
    null,
  );
  const [isLiked, setLiked] = useState(comment.isLikedByUser || false);
  const { request: deleteRequest, loading: deleteLoading } = useDeleteComment();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUpdated = (c: ForumComment) => {
    setUpdatedComment(c);
    setEditMode(false);
    setLiked(c.isLikedByUser);
  };

  const { request } = useEditCommentLike((r) => {
    if (r.isLiked === undefined) return;
    setLiked(r.isLiked);
    comment.totalLikes = r.isLiked
      ? comment.totalLikes + 1
      : comment.totalLikes - 1;
    setUpdatedComment(comment);
  });
  const handleEditCommentLike = () => {
    request({
      ref: comment.ref,
      postRef: comment.postRef,
      isLiked: !isLiked,
    });
  };

  const deleteComment = async () => {
    if (!deleteLoading) {
      await deleteRequest({ ref: comment.ref });
      setIsDeleted(true);
    }
  };

  const useComment = updatedComment || comment;
  const bodyContent = useComment.body?.payload
    ? JSON.parse(useComment.body?.payload)
    : useComment.body?.textContent;

  const options = [];
  if (canEdit) {
    options.push(
      createMenuOption(
        "Edit",
        () => {
          setEditMode((e) => !e);
          handleClose();
        },
        "pencil",
      ),
    );
  }
  if (canEdit && !hasChildren) {
    options.push(
      createMenuOption(
        "Delete",
        () => {
          if (!deleteLoading) {
            deleteComment();
          }
          handleClose();
        },
        "trash",
      ),
    );
  }
  // one off case where we've just deleted the comment
  if (isDeleted) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {hasParent && (
        <ChildGuideline
          threadGuidelines={threadGuidelines}
          depth={comment.depth}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <UserAvatar user={useComment.author!} size={40} />
          {hasChildren && <ParentGuideline />}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FAF9FA",
            flexGrow: 1,
            borderRadius: "16px",
            padding: "16px 24px",
            gap: "8px",
            ...(highlighted ? { border: "3px solid #C8DBD7" } : {}),
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ fontWeight: "700" }}>
                {useComment.author?.displayName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "4px" }}>
              <DateDisplay
                date={new Date(Number(useComment.createdSec) * 1000)}
                sx={{ fontSize: "14px", color: "#6B6E7B" }}
              />
              <Typography
                component="span"
                variant="caption"
                sx={{ fontSize: "14px", color: "#6B6E7B" }}
              >
                {useComment.createdSec !== useComment.lastEditSec && (
                  <span>{" (edited)"}</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            {editMode ? (
              <EditArea
                comment={useComment}
                cancelEdit={() => setEditMode(false)}
                onUpdate={onUpdated}
              />
            ) : (
              <DocumentViewer
                key={`${useComment.lastEditSec}`}
                content={bodyContent}
                attachments={useComment.body?.attachments}
                minHeight="20px"
              />
            )}
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "stretch", gap: "12px" }}>
              <LikerTooltip
                likers={comment.likerHints}
                total={useComment.totalLikes}
              >
                <IconButton
                  sx={{
                    justifyContent: "flex-start",
                    width: "fit-content",
                    padding: 0,
                  }}
                  aria-label="toggle like"
                  onClick={handleEditCommentLike}
                >
                  {isLiked && (
                    <Heart size="24px" fill="#EF7B77" stroke="#EF7B77" />
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      height: "24px",
                      alignItems: "center",
                    }}
                  >
                    {isLiked && (
                      <Typography
                        variant="bodySmallHeavy"
                        sx={{
                          marginLeft: "4px",
                          color: "#616161",
                        }}
                      >
                        {useComment.totalLikes}{" "}
                      </Typography>
                    )}

                    <Typography
                      variant="bodySmallHeavy"
                      sx={{
                        color: isLiked ? "#EF7B77" : "#616161",
                      }}
                    >
                      Like
                    </Typography>
                  </Box>
                </IconButton>
              </LikerTooltip>
              {comment.depth < MAX_DEPTH && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="bodySmallHeavy"
                    sx={{
                      color: "#616161",
                      cursor: "pointer",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setReplyMode((e) => !e)}
                  >
                    Reply
                  </Typography>
                </Box>
              )}
              {canEdit && (
                <Box>
                  <IconButton ref={anchorRef} onClick={handleToggle}>
                    <Ellipsis size={20} />
                  </IconButton>
                  <PopperMenu
                    open={open}
                    anchorRef={anchorRef}
                    handleClose={handleClose}
                  >
                    {options}
                  </PopperMenu>
                </Box>
              )}
            </Box>
            {replyMode && (
              <AddComment
                postRef={post.ref}
                parentRef={comment.ref}
                onCreate={(c) => {
                  onCommentCreation(c);
                  setReplyMode(false);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
