// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/auth.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.AuthToken
 */
export class AuthToken extends Message<AuthToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: int64 validUntil = 3;
   */
  validUntil = protoInt64.zero;

  constructor(data?: PartialMessage<AuthToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AuthToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "validUntil", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthToken {
    return new AuthToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthToken {
    return new AuthToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthToken {
    return new AuthToken().fromJsonString(jsonString, options);
  }

  static equals(a: AuthToken | PlainMessage<AuthToken> | undefined, b: AuthToken | PlainMessage<AuthToken> | undefined): boolean {
    return proto3.util.equals(AuthToken, a, b);
  }
}

/**
 * @generated from message protogen.CurrentUser
 */
export class CurrentUser extends Message<CurrentUser> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: string avatarUrl = 5;
   */
  avatarUrl = "";

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * No need to leak PII out anywhere.
   *
   * @generated from field: string phoneTeaser = 7;
   */
  phoneTeaser = "";

  /**
   * @generated from field: string emailTeaser = 8;
   */
  emailTeaser = "";

  /**
   * @generated from field: repeated string roles = 9;
   */
  roles: string[] = [];

  /**
   * @generated from field: bool hasAcceptedTermsOfService = 10;
   */
  hasAcceptedTermsOfService = false;

  /**
   * @generated from field: string timezone = 11;
   */
  timezone = "";

  /**
   * @generated from field: bool isTemporarySession = 12;
   */
  isTemporarySession = false;

  constructor(data?: PartialMessage<CurrentUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CurrentUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phoneTeaser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "emailTeaser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "hasAcceptedTermsOfService", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "isTemporarySession", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CurrentUser {
    return new CurrentUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CurrentUser {
    return new CurrentUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CurrentUser {
    return new CurrentUser().fromJsonString(jsonString, options);
  }

  static equals(a: CurrentUser | PlainMessage<CurrentUser> | undefined, b: CurrentUser | PlainMessage<CurrentUser> | undefined): boolean {
    return proto3.util.equals(CurrentUser, a, b);
  }
}

