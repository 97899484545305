import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Box,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { FactSectionStub } from "protogen/facts_pb";

interface Props {
  selectedSectionRef: string | undefined;
  sections: FactSectionStub[];
  onChange: (sectionRef: string) => void;
  error?: string | null;
}

export default ({ selectedSectionRef, sections, onChange, error }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const sectionRef = sections.find((s) => s.name === selectedValue)?.ref;
    if (sectionRef) {
      onChange(sectionRef);
    }
  };

  const refToName = (ref: string): string => {
    return sections.find((s) => s.ref === ref)?.name ?? "";
  };

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <InputLabel>Section</InputLabel>
      <Select
        sx={{
          ".MuiSelect-select": {
            ...(selectedSectionRef
              ? {
                  paddingTop: "13px",
                  paddingBottom: "13px",
                }
              : null),
          },
        }}
        error={!!error}
        input={<OutlinedInput label="Section" />}
        value={refToName(selectedSectionRef || "")}
        renderValue={(value) => {
          const section = sections.find((s) => s.name === value);
          return section ? <Box>{section.name}</Box> : null;
        }}
        onChange={handleChange}
      >
        {sections.map((s) => (
          <MenuItem sx={{ margin: "0px" }} key={s.ref} value={s.name}>
            <ListItem component="div" disablePadding>
              {s.name}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
