import React, { useEffect, useState } from "react";
import { ForumPost } from "protogen/forum_service_pb";
import { useEditPostLike } from "services/forum";
import { Box, Typography } from "@mui/material";
import { Heart, MessageSquare } from "lucide-react";
import LikerTooltip from "./LikerTooltip";
import { pluralize } from "../../common/utils";
import AnimatedIcon from "../common/AnimatedIcon";

export default ({
  post,
  onCommentClick,
}: {
  post: ForumPost;
  onCommentClick?: () => void;
}) => {
  const [postLikes, setPostLikes] = useState<number>(0);
  const [isLikedByUser, setIsLikedByUser] = useState<boolean>(false);

  const { request } = useEditPostLike((r) => {
    if (r.isLiked === undefined) return;
    setPostLikes((prev) => (r.isLiked ? prev + 1 : prev - 1));
    setIsLikedByUser(r.isLiked);
  });
  const handleEditCommentLike = () => {
    // Do this optimisitally to cut network latency.
    setIsLikedByUser((prev) => !prev);
    request({
      postRef: post.ref,
      isLiked: !isLikedByUser,
    });
  };

  useEffect(() => {
    setPostLikes(post.totalLikes);
    setIsLikedByUser(post.isLikedByUser);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="12px"
      width={{ xs: "100%", md: "auto" }}
    >
      <LikerTooltip likers={post.likerHints} total={postLikes}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditCommentLike();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#6B6E7B",
            cursor: "pointer",
          }}
        >
          <AnimatedIcon
            icon={Heart}
            isActive={isLikedByUser}
            aria-label="Like"
            size={24}
            activeColor={"#EF7B77"}
            inactiveColor={"#8E9598"}
          />
          <Typography
            variant="body"
            sx={{
              marginLeft: "4px",
              color: "#616161",
            }}
          >
            {postLikes} {pluralize("like", postLikes)}
          </Typography>
        </Box>
      </LikerTooltip>
      <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCommentClick?.();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#6B6E7B",
            cursor: onCommentClick ? "pointer" : undefined,
          }}
        >
          <MessageSquare size="24px" stroke="#8E9598" />
        </Box>
        <Typography
          variant="body"
          sx={{
            marginLeft: "4px",
            color: "#616161",
          }}
        >
          {post.commentCount} {post.commentCount == 1 ? "reply" : "replies"}
        </Typography>
      </Box>
    </Box>
  );
};
