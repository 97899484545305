import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import AuthService from "services/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SWRegistrationContext } from "components/context/AddServiceWorker";
import { InitiatePasswordResetRequest_Flow } from "protogen/auth_service_pb";

type Props = {
  isAccountSetup?: boolean;
};

const ExpiredError = ({ username }: { username: string }) => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const onclick = (event: React.MouseEvent<HTMLElement>) => {
    if (loading) return;
    event.preventDefault();
    setLoading(true);
    AuthService.initiatePasswordReset(
      username,
      InitiatePasswordResetRequest_Flow.SETUP_ACCOUNT,
    ).then(
      () => {
        setSent(true);
        setLoading(false);
        setError(null);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(resMessage);
      },
    );
  };
  return (
    <Alert severity="error" style={{ marginTop: 16 }}>
      {!sent && (
        <>
          Your link has expired. Click <Link onClick={onclick}>here</Link> to
          send a new email.
        </>
      )}
      {sent && <>Check your email for a new setup link.</>}
      {error && <>{error}</>}
    </Alert>
  );
};

export default ({ isAccountSetup }: Props) => {
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenVal = queryParams.get("token") || token || "";
  const navigate = useNavigate();
  const { subscription } = useContext(SWRegistrationContext);
  const username = queryParams.get("username") || "";

  let from = location.state?.from?.pathname || "/";
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    // If this device is already subscribed to notifications, we should send that along.
    const subscriptionString = subscription
      ? JSON.stringify(subscription.toJSON())
      : null;
    AuthService.resetPassword(
      tokenVal,
      username,
      password,
      subscriptionString,
    ).then(
      () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
        setLoading(false);
        setError(null);
        // navigate(0); // Note - this still is stored ephemerally rather than persisted in the URL.
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          error?.response?.data || error.message || error.toString();
        setLoading(false);
        setError(resMessage);
      },
    );
  };

  const expiredToken = error?.toLowerCase().includes("expired token");
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ padding: "2rem", maxWidth: "500px" }}>
            <Typography variant="display">Account Setup</Typography>
            <form onSubmit={handleSubmit}>
              {expiredToken && <ExpiredError username={username} />}
              {error && !expiredToken && (
                <Alert severity="error" style={{ marginTop: 16 }}>
                  {error}
                </Alert>
              )}
              <FormControl
                fullWidth
                style={{ marginTop: 16 }}
                disabled={loading}
              >
                <InputLabel htmlFor="username-input">Username</InputLabel>
                <Input
                  error={!!error}
                  id="username-input"
                  name="username"
                  autoComplete="off"
                  disabled={true}
                  value={username}
                />
              </FormControl>
              <FormControl
                fullWidth
                style={{ marginTop: 16 }}
                disabled={loading}
              >
                <InputLabel htmlFor="password-input">Password</InputLabel>
                <Input
                  error={!!error}
                  id="password-input"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                style={{ marginTop: 16 }}
              >
                {isAccountSetup ? "Set Password" : "Reset Password"}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
