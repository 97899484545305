import { Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";

export default ({
  uploadsInProgress,
  uploadPercentage,
  onChange,
}: {
  uploadsInProgress: boolean;
  uploadPercentage: number;
  onChange: (f: FileList | null) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <label htmlFor="upload-documents-button">
      <Button
        disabled={uploadsInProgress}
        fullWidth
        variant="text"
        onClick={() => {
          fileInputRef.current && fileInputRef.current.click();
        }}
        startIcon={
          uploadsInProgress && uploadPercentage > 0 ? (
            <CircularProgress
              variant="determinate"
              value={uploadPercentage}
              sx={{
                bottom: "4px",
                width: "30px",
                left: "2px",
              }}
            />
          ) : (
            <AddIcon />
          )
        }
        sx={{
          justifyContent: "flex-start",
          paddingLeft: "0px",
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        New document
      </Button>
      <input
        ref={fileInputRef}
        multiple
        disabled={uploadsInProgress}
        type="file"
        id="upload-documents-button"
        style={{ display: "none" }}
        onChange={(e) => onChange(e.target.files)}
      />
    </label>
  );
};
