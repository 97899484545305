import { Tooltip, Typography } from "@mui/material";
import { absoluteTime } from "../../common/utils";

type Props = {
  lastUpdatedSec: number | null;
  saving?: boolean;
  hasError?: boolean;
};
export default ({
  lastUpdatedSec,
  saving = false,
  hasError = false,
}: Props) => {
  const updateTime = lastUpdatedSec
    ? absoluteTime(new Date(lastUpdatedSec * 1000))
    : null;

  const getContent = () => {
    return (
      <div
        style={{
          fontSize: "12px",
          fontStyle: "italic",
        }}
      >
        {!saving && !hasError && updateTime && "Saved"}
        {saving && "Saving..."}
        {!saving && hasError && (
          <Typography variant="bodySmall" color="error">
            Error saving
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {updateTime ? (
        <Tooltip
          title={`Last updated ${updateTime}`}
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          {getContent()}
        </Tooltip>
      ) : (
        getContent()
      )}
    </div>
  );
};
