import { Box, Typography } from "@mui/material";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default () => {
  const footerText =
    "It looks like you were searching for a message.  If you need to see more messages, please refresh the page and scroll up on the activity feed. ";
  return (
    <Box>
      <Typography variant="bodySmall" color="textSecondary">
        <ExclamationTriangleIcon
          height="20px"
          width="20px"
          style={{ verticalAlign: "middle", display: "inline" }}
        />
        {footerText}
        <Typography
          onClick={() => {
            window.location.reload();
          }}
          sx={{
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
          }}
          variant="bodySmall"
          color="textSecondary"
        >
          Click to refresh
        </Typography>
      </Typography>
    </Box>
  );
};
