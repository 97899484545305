import { forwardRef, useImperativeHandle } from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { FactFormHandle, FactSectionFormProps } from "../types";

export default forwardRef<FactFormHandle, FactSectionFormProps>(
  ({ factSection, updateFormData, errors }: FactSectionFormProps, ref) => {
    useImperativeHandle(ref, () => ({
      isValid: () => {
        const hasName = factSection.name !== "";

        return hasName;
      },
    }));

    return (
      <Box>
        <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={factSection.name}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) =>
            updateFormData({ ...factSection, name: e.target.value })
          }
        />
      </Box>
    );
  },
);
