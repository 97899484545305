import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  NodeViewProps,
} from "@tiptap/react";
import { Box } from "@mui/system";
import { Button, IconButton, Typography } from "@mui/material";
import { X } from "lucide-react";
import { ReactComponent as DoubleBubbleIcon } from "../../../icons/Menu/DoubleBubble.svg";

const Component: React.FC<NodeViewProps> = ({
  node,
  // updateAttributes,
  ...otherProps
}) => {
  // Keep for reference of how to update attributes.
  // const increase = () => {
  //   updateAttributes({
  //     count: node.attrs.count + 1,
  //   });
  // };
  return (
    <NodeViewWrapper className="react-component-recommendation">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          gap: "12px",
          padding: "8px 12px",
          borderRadius: "4px",
          border: "1px solid #E2E3E4",
          marginTop: "8px",
          cursor: "pointer",
        }}
        // Enable dragging for this node.
        // https://github.com/ueberdosis/tiptap/issues/2597
        draggable="true"
        data-drag-handle=""
        onClick={() => window.open(node.attrs.url, "_blank")}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="4px"
          sx={{
            justifyContent: "center",
            padding: "0px 5px",
          }}
        >
          <DoubleBubbleIcon height="20px" width="20px" />
        </Box>
        <Box display="flex" flexDirection="column" gap="4px" flexGrow={1}>
          <Typography variant="bodySmallHeavy">{node.attrs.title}</Typography>
          <Typography variant="bodySmall">{node.attrs.subtitle}</Typography>
        </Box>
        <Box>
          {node.attrs.phone && (
            <Button variant="text" sx={{ height: "auto", fontSize: "14px" }}>
              Call ({node.attrs.phone})
            </Button>
          )}
          {node.attrs.website && (
            <Button variant="text" sx={{ height: "auto", fontSize: "14px" }}>
              Website ({node.attrs.website})
            </Button>
          )}
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              otherProps.deleteNode();
            }}
          >
            <X size={24} />
          </IconButton>
        </Box>
      </Box>
      {/*<span className="label">React Component</span>*/}

      {/*<div className="content">*/}
      {/*  <button onClick={increase}>*/}
      {/*    This button has been clicked {node.attrs.count} props.node.attrs.count*/}
      {/*    times.*/}
      {/*  </button>*/}
      {/*</div>*/}
    </NodeViewWrapper>
  );
};

export interface ExtOptions {
  // inline: boolean;
}

// Great example: https://github.com/ueberdosis/tiptap/blob/main/packages/extension-youtube/src/youtube.ts
export default Node.create<ExtOptions>({
  name: "recommendationExtension",
  group: "block",
  atom: true,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      identifier: {
        default: null,
      },
      title: {
        default: null,
      },
      subtitle: {
        default: null,
      },
      url: {
        default: null,
      },
      phone: {
        default: null,
      },
      website: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "react-component-recommendation",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["react-component-recommendation", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
  // Keeping for reference.
  // addKeyboardShortcuts() {
  //   return {
  //     "Mod-Enter": () => {
  //       return this.editor
  //         .chain()
  //         .insertContentAt(this.editor.state.selection.head, {
  //           type: this.type.name,
  //           attrs: {
  //             title: "Kip",
  //             subtitle: "Kaehler",
  //             phone: "1",
  //             website: "2",
  //           },
  //         })
  //         .focus()
  //         .run();
  //     },
  //   };
  // },
});
