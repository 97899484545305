import axios, { AxiosProgressEvent } from "axios";
import { SignedUrlHeader } from "protogen/files_service_pb";

export default async (
  signedUrl: string,
  file: File,
  validationKey: string,
  progress: (n: number) => void,
  headers: SignedUrlHeader[],
) => {
  const options = {
    headers: {
      "Content-Type": file.type,
      "x-amz-meta-validation-key": validationKey,
      // When using SSE-KMS (double encryption), the following headers are required and passed from the server.
      // "x-amz-server-side-encryption", "x-amz-server-side-encryption-aws-kms-key-id"
      ...headers.reduce(
        (obj, item) => {
          obj[item.key] = item.value;
          return obj;
        },
        {} as Record<string, string>,
      ),
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const percentage = progressEvent.total
        ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
        : 0;
      progress(percentage);
    },
  };

  await axios.put(signedUrl, file, options);
  progress(0);
};
