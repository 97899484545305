import { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, TextField, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PlainMessage } from "@bufbuild/protobuf";
import { useAddPublicProfile } from "services/nux";
import auth from "components/nux/auth";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import DocumentEditor from "components/editor/DocumentEditor";
import { RichContent } from "components/editor/utils";
import { ExternalLink } from "components/advisor/types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useIsMobile from "components/hooks/useIsMobile";
import { useFetchAdvisorProfile, useUpdateAdvisor } from "services/advisor";
import { Advisor } from "protogen/common_pb";
import AuthService from "services/auth";
import CurrentUserAvatar, {
  AvatarBadgeType,
} from "components/common/CurrentUserAvatar";
import { useUploader } from "components/creation/FileUploader";
import { UploadAttachment } from "protogen/common_pb";
import { Camera } from "lucide-react";
import { USER_NUX_TYPE } from "components/nux/constants";
import { validateExternalLinks } from "components/advisor/utils";
import { FormErrors } from "components/advisor/types";

const SpecialtyChip = ({
  specialty,
  onDelete,
}: {
  specialty: string;
  onDelete: () => void;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        display: "flex",
        height: "48px",
        padding: "8px 24px",
        alignItems: "center",
        gap: "8px",
        color: "text.secondary",
        border: "none",
        cursor: "pointer",
        borderRadius: "100px",
        width: "fit-content",
      }}
    >
      <Typography variant="bodyHeavy" color="#616161" sx={{ fontWeight: 600 }}>
        {specialty}
      </Typography>
      <XMarkIcon
        color="#616161"
        onClick={onDelete}
        style={{ marginLeft: "12px", height: "20px", width: "20px" }}
      />
    </Box>
  );
};

export default () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const currentUser = AuthService.getCurrentUser();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [advisor, setAdvisor] = useState<Advisor | undefined>(undefined);
  const [newSpeciality, setNewSpeciality] = useState<string>("");
  const [content, setContent] = useState<RichContent | null>(null);
  const [specialties, setSpecialties] = useState<string[]>([]);
  const [errors, setFormErrors] = useState<FormErrors>({});
  const [profileImage, setProfileImage] = useState<
    PlainMessage<UploadAttachment> | null | undefined
  >(null);
  const [externalLinkData, setExternalLinkData] = useState<ExternalLink[]>([]);
  const { request, loading } = useAddPublicProfile((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.ADVISOR);
      navigate(path);
    }
  });
  const { onUpload } = useUploader({
    maxUploadBytes: 4900000,
  });
  const onSkip = () => {
    request({
      token: auth.getAuthData(),
    });
  };
  const onContinue = async () => {
    await handleSave();
    request({
      token: auth.getAuthData(),
    });
  };
  const handleSave = async () => {
    const errors = validateExternalLinks(externalLinkData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // TODO: @kegami move to "types" for reuse
    await updateRequest({
      advisorRef: currentUser?.ref || "",
      updatedFirstName: advisor?.firstName || "",
      updatedLastName: advisor?.lastName || "",
      updatedPersonalEmail: advisor?.personalEmail || "",
      updatedPersonalPhone: advisor?.personalPhone || "",
      body: {
        contentType: "html",
        payload: content?.html || advisor?.blurbContent || "",
        textContent: content?.text || advisor?.blurbText || "",
        attachments: [],
      },
      profileImage: profileImage || undefined,
      isProfilePublic: false,
      shouldUpdateProfileImage: !!profileImage,
      externalLinks: externalLinkData || [],
      updatedTimezone: "",
      updatedSpecialties: specialties || [],
      updatedStatus: null,
      updatedAddress: advisor?.address || "",
      shouldUpdateEmailNotifications: false,
      emailNotificationsEnabled: false,
    });
  };

  const { request: fetchRequest, loading: fetchLoading } =
    useFetchAdvisorProfile((r) => {
      setAdvisor(r.advisor);
      setExternalLinkData(r?.advisor?.externalLinks || []);
    });

  const { request: updateRequest, loading: updateLoading } = useUpdateAdvisor();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchRequest({ advisorRef: currentUser.ref });
  }, []);
  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="title"
          >
            Your public profile
          </Typography>
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="body"
          >
            Your public profile tells your Faye colleagues, clients, and leads a
            little bit about yourself. Need a little more time to think about
            it? You can skip this now and come back to it later.
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "48px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Typography variant="h3Serif">Profile photo</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
                <Box
                  sx={{
                    aspectRatio: "1/1",
                    flexGrow: 1,
                    maxWidth: isMobile ? "88px" : "88px",
                    width: "100%",
                  }}
                >
                  <CurrentUserAvatar
                    user={advisor}
                    size={88}
                    autoscale={true}
                    badgeType={AvatarBadgeType.NONE}
                    clickable={false}
                    placeholder={<Camera size={49} stroke="#8E9598" />}
                  />
                </Box>
                <input
                  ref={inputFileRef}
                  type="file"
                  style={{ display: "none", height: "100%", width: "100%" }}
                  onChange={(e) => {
                    if (!onUpload) return;
                    const files = e.target.files;
                    if (!files) return;
                    const imageUrl = URL.createObjectURL(files[0]);
                    setAdvisor((prevState) => {
                      if (!prevState) return;
                      const newState = prevState.clone();
                      newState.avatarUrl = imageUrl;
                      return newState;
                    });
                    onUpload(files, undefined, (u) => {
                      setProfileImage(u.pop());
                    });
                  }}
                  id="input-file-upload"
                />
                <label htmlFor="input-file-upload">
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        inputFileRef.current && inputFileRef.current.click()
                      }
                    >
                      Upload an image
                    </Button>
                  </Box>
                </label>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Typography variant="h3Serif">Social media</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    flexWrap: "wrap",
                  }}
                >
                  {externalLinkData.map((link) => (
                    <FormControl key={link.name} sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        value={link.url}
                        error={!!errors?.[`externalLinks${link.name}`]}
                        helperText={errors?.[`externalLinks${link.name}`]}
                        onChange={(e) => {
                          const newData: ExternalLink[] = [
                            ...externalLinkData,
                          ].map((l) => {
                            if (l.name !== link.name) return l;
                            return { ...link, url: e.target.value };
                          });
                          setExternalLinkData(newData);
                        }}
                        label={link.name}
                        margin="normal"
                        size="small"
                        sx={{ marginTop: 0 }}
                      />
                    </FormControl>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Typography variant="h3Serif">About me</Typography>
              <DocumentEditor
                sx={{
                  backgroundColor: "#FFFFFF",
                  padding: "24px",
                  border: "2px solid #F1F1F1",
                  borderRadius: "8px",
                  marginTop: "0px",
                }}
                initialContent={advisor?.blurbContent || ""}
                disabled={fetchLoading}
                placeholder="Tell families a little about yourself..."
                attachmentsEnabled={false}
                setContent={setContent}
                secondaryAction={
                  updateLoading && (
                    <Box
                      sx={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      Saving...
                    </Box>
                  )
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Typography variant="h3Serif">Specialties</Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <Typography variant="bodySmallHeavy" sx={{ fontWeight: 600 }}>
                  What’s something you’re great at?
                </Typography>
                <Typography variant="bodySmall" color="text.secondary">
                  Include as many as you’d like, separated by the ‘enter’ key
                </Typography>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#3D3D3D",
                      marginTop: "0px",
                    },
                  }}
                  fullWidth
                  value={newSpeciality}
                  placeholder="ex. travel plans, credit card points, etc."
                  autoComplete="off"
                  onChange={(e) => {
                    setNewSpeciality(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && advisor) {
                      setNewSpeciality("");
                      setSpecialties([...specialties, newSpeciality]);
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                {specialties.map((specialty, i) => (
                  <SpecialtyChip
                    key={i}
                    specialty={specialty}
                    onDelete={() => {
                      setSpecialties(
                        specialties.filter((s) => s !== specialty),
                      );
                    }}
                  />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "16px",
              }}
            >
              <Button
                onClick={onSkip}
                variant={isMobile ? "outlined" : "text"}
                sx={{ flexGrow: isMobile ? 1 : 0 }}
              >
                Skip
              </Button>
              <Button
                disabled={loading}
                onClick={async () => {
                  await onContinue();
                }}
                sx={{ flexGrow: isMobile ? 1 : 0 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
