import Link, { LinkProps } from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React from "react";

let LOAD_TS = new Date();
const ONE_HOUR = 1000 * 60 * 60;

interface Props extends LinkProps {
  to: string | null | undefined;
  disabled?: boolean;
  targetNew?: boolean;
  inline?: boolean;
}

export default (props: Props) => {
  const navigate = useNavigate();
  const { to, disabled, onClick, targetNew, sx, inline, ...otherProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent default Link behavior
    event.preventDefault();
    if (disabled) {
      return;
    }
    if (to === null || to === undefined) {
      // Cleanup or other specific onClick behavior
      onClick && onClick(event);
      return;
    }
    // Note: Let's NOT fire onClick if the user is trying to open in a new tab. This could be controversial,
    // but doing this audit it seems like uses of onclick are for closing UIs which we don't want to do if
    // the user is trying to open in a new tab.
    if (event.ctrlKey || event.metaKey || targetNew) {
      // Open in a new tab for control-click or command-click
      window.open(to, "_blank");
      return;
    }
    if (new Date().getTime() - LOAD_TS.getTime() > ONE_HOUR) {
      // If the page has been open for more than an hour, let's force a reload on the next page.
      // While a rare occurence, this will still usually hit the cache and be fast. However, if there
      // has been a frontend release this will pickup the new code.
      location.href = to;
    } else {
      // Use React Router's navigate for normal clicks
      navigate(to);
    }
    // Cleanup or other specific onClick behavior
    onClick && onClick(event);
  };
  return (
    <Link
      variant="inherit"
      sx={{
        textDecoration: "none",
        color: "inherit",
        cursor: "pointer",
        display: !inline ? "flex" : "inline",
        padding: "4px 0",
        ...sx,
      }}
      to={to}
      onClick={handleClick}
      {...otherProps}
      component={RouterLink as any}
    />
  );
};
