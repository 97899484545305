import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIsMobile from "components/hooks/useIsMobile";
import useIsDesktop from "components/hooks/useIsDesktop";
import FlexPage from "components/layout/FlexPage";
import { Plus } from "lucide-react";
import { createTaskHelper } from "components/tasks/utils";
import { useGetMemberDetails } from "services/member";

import {
  useListAllTaskInspirations,
  useListAllMemberTaskInspirations,
  useListAllTaskInspirationCategories,
  useListAllMemberTaskDiscoveryInspirationCategories,
} from "services/tasks";
import { TaskInspiration, TaskInspirationCategory } from "protogen/tasks_pb";
import { LazyIcon } from "../common/iconUtils";

const TaskCategoryQuickLink = ({
  title,
  onClick,
  itemsPerRow = 5,
}: {
  title: string;
  onClick: () => void;
  itemsPerRow?: number;
}) => {
  const isMobile = useIsMobile();
  return (
    <Typography
      variant="bodyHeavy"
      sx={{
        display: "flex",
        padding: isMobile ? "12px" : "16px",
        border: "1px solid #D4D4D4",
        borderRadius: "12px",
        alignItems: "center",
        textWrap: isMobile ? "nowrap" : "wrap",

        width: isMobile
          ? "auto"
          : `calc(100% * (1/${itemsPerRow}) - 12px + 2px)`,
      }}
      onClick={onClick}
    >
      {title}
    </Typography>
  );
};

const TaskCategoryQuickLinks = ({
  categories,
  onClick,
  maxItems = 10,
}: {
  categories: TaskInspirationCategory[];
  onClick: (ref: string) => void;
  maxItems?: number;
}) => {
  const isMobile = useIsMobile();
  // On mobile, we just always show all the quick links.
  const [showMore, setShowMore] = useState(isMobile);
  const excludedQuickLinks = ["Popular", "Seasonal"];
  const filteredItems = categories.filter((c) => {
    return !excludedQuickLinks.includes(c.title);
  });
  const includeShowMore = !showMore && filteredItems.length > maxItems;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: isMobile ? "nowrap" : "wrap",
          gap: "12px",
          marginBottom: "8px",
          whiteSpace: "nowrap",
          overflowX: "auto",

          // Full width scrolling
          paddingLeft: isMobile ? "20px" : "",
          paddingRight: isMobile ? "20px" : "",
          marginLeft: isMobile ? "-20px" : "",
          marginRight: isMobile ? "-20px" : "",
          msOverflowStyle: "none" /* Hide scrollbar */,
          scrollbarWidth: "none" /* Hide scrollbars */,
          cursor: "pointer",
        }}
      >
        {filteredItems.map((category, i) => {
          if (includeShowMore && i >= maxItems) return null;
          return (
            <TaskCategoryQuickLink
              key={category.ref}
              title={category.title}
              onClick={() => onClick(category.ref)}
            />
          );
        })}
      </Box>
      {includeShowMore && (
        <Button
          variant="text"
          onClick={() => setShowMore(true)}
          sx={{ height: "unset", alignSelf: "start" }}
        >
          Show all categories
        </Button>
      )}
    </Box>
  );
};

const TaskCategorySection = ({
  category,
  taskInspirations,
  onTaskClick,
}: {
  category: TaskInspirationCategory;
  taskInspirations: TaskInspiration[];
  onTaskClick: (task: TaskInspiration) => void;
}) => {
  const filteredTaskInspirations = taskInspirations.filter((task) => {
    return task.categoryRef === category.ref && task.status === "active";
  });
  const isDesktop = useIsDesktop();
  const Icon = LazyIcon(category.iconName);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
      }}
      key={category.ref}
    >
      {/* Example tasks container */}
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
          gridGap: "12px",
        }}
      >
        {/* Category name */}
        <Box
          sx={{
            display: "flex",
            background: "linear-gradient(343deg, #E8F8F8, #EDF8F6)",
            padding: "24px 20px",
            borderRadius: "12px",
            gridRow: "1 / span 2",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Icon width="32" height="32" stroke="#198282" />
          <Typography variant="h3">{category.title}</Typography>
        </Box>
        {filteredTaskInspirations.map((task) => {
          return (
            /* Example task */
            <Box
              sx={{
                border: "1px solid #D4D4D4",
                padding: "12px 20px",
                borderRadius: "8px",
                alignContent: "center",
                display: "flex",
                gap: "8px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={task.ref}
            >
              <Typography variant="body" sx={{ width: "100%" }}>
                {task.title}
              </Typography>
              <Box
                sx={{ display: "flex" }}
                onClick={() => {
                  onTaskClick(task);
                }}
              >
                <Plus width="24" color="#198282" cursor="pointer" />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ({
  accountType = "advisor",
}: {
  accountType?: "advisor" | "member";
}) => {
  const memberPageDescription =
    "Here are our most popular tasks to help you get the most out of Faye.";
  const advisorPageDescription =
    "Here are our most popular tasks to help your families get the most out of Faye.";
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const sectionRefs = useRef<{
    [key: string]: React.RefObject<HTMLDivElement>;
  }>({});

  const taskInspirationRequestFn =
    accountType === "advisor"
      ? useListAllTaskInspirations
      : useListAllMemberTaskInspirations;

  const { data, request: listAllTaskInspirationRequest } =
    taskInspirationRequestFn(() => {});

  const taskInspirationCategoriesRequestFn =
    accountType === "advisor"
      ? useListAllTaskInspirationCategories
      : useListAllMemberTaskDiscoveryInspirationCategories;

  const {
    data: inspirationCategories,
    request: listAllTaskInspirationCategoriesRequest,
  } = taskInspirationCategoriesRequestFn(() => {});
  const { request: memberDetailsRequest, data: memberDetails } =
    useGetMemberDetails(() => {});
  useEffect(() => {
    listAllTaskInspirationCategoriesRequest();
    listAllTaskInspirationRequest();
  }, []);

  inspirationCategories?.taskInspirationCategories.forEach((category) => {
    if (!sectionRefs.current[category.ref]) {
      sectionRefs.current[category.ref] = React.createRef();
    }
  });

  const handleQuickLinkClick = (ref: string) => {
    sectionRefs.current[ref]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (accountType === "advisor") return;
    memberDetailsRequest();
  }, []);

  const { createTask } = createTaskHelper({
    referral: "task-inspiration",
    creatorType: "member",
    assigneeRef: memberDetails?.primaryAdvisor?.ref || "",
  });

  const handleTaskClick = (task: TaskInspiration) => {
    if (accountType === "advisor") {
      navigate("/tasks/add", { state: { inspiredTask: task } });
    } else if (accountType === "member") {
      createTask({
        familyRef: memberDetails?.family?.ref || "",
        title: task.title,
        sourceEntityType: "task_inspiration",
        sourceEntityRef: task.ref,
      });
    }
  };
  const onAddTaskClick = () => {
    navigate("/tasks/add", { state: { family: memberDetails?.family } });
  };
  return (
    <FlexPage
      leftAligned
      sx={{
        margin:
          accountType === "advisor" && isMobile
            ? "0px"
            : accountType === "advisor"
              ? "64px min(7%, 100px)"
              : "auto",
        padding:
          accountType === "advisor" && isMobile
            ? "32px 24px"
            : isMobile
              ? "32px 24px"
              : accountType !== "advisor" && !isMobile
                ? "64px 32px"
                : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: isMobile ? "20px" : "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography variant="display">Task discovery</Typography>
            <Typography variant="body">
              {accountType === "advisor"
                ? advisorPageDescription
                : memberPageDescription}
            </Typography>
          </Box>
          {!isMobile && (
            <Button sx={{ minWidth: "120px" }} onClick={onAddTaskClick}>
              New task
            </Button>
          )}
        </Box>

        {/* Category quick links */}
        <TaskCategoryQuickLinks
          categories={inspirationCategories?.taskInspirationCategories || []}
          onClick={handleQuickLinkClick}
        />
        {/* Category page sections */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: isMobile ? "48px" : "64px",
          }}
        >
          {inspirationCategories?.taskInspirationCategories.map((category) => {
            return (
              <Box
                key={category.ref}
                ref={sectionRefs.current[category.ref]}
                sx={{
                  scrollMarginTop: "64px",
                }}
              >
                <TaskCategorySection
                  category={category}
                  taskInspirations={data?.taskInspirations || []}
                  onTaskClick={handleTaskClick}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </FlexPage>
  );
};
