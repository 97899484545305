// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/url_shortener_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.CreateShortUrlRequest
 */
export class CreateShortUrlRequest extends Message<CreateShortUrlRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<CreateShortUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateShortUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateShortUrlRequest {
    return new CreateShortUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateShortUrlRequest {
    return new CreateShortUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateShortUrlRequest {
    return new CreateShortUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateShortUrlRequest | PlainMessage<CreateShortUrlRequest> | undefined, b: CreateShortUrlRequest | PlainMessage<CreateShortUrlRequest> | undefined): boolean {
    return proto3.util.equals(CreateShortUrlRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateShortUrlResponse
 */
export class CreateShortUrlResponse extends Message<CreateShortUrlResponse> {
  /**
   * @generated from field: string shortUrl = 1;
   */
  shortUrl = "";

  constructor(data?: PartialMessage<CreateShortUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateShortUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shortUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateShortUrlResponse {
    return new CreateShortUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateShortUrlResponse {
    return new CreateShortUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateShortUrlResponse {
    return new CreateShortUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateShortUrlResponse | PlainMessage<CreateShortUrlResponse> | undefined, b: CreateShortUrlResponse | PlainMessage<CreateShortUrlResponse> | undefined): boolean {
    return proto3.util.equals(CreateShortUrlResponse, a, b);
  }
}

