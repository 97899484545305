import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Loading from "components/common/Loading";
import { FamilyStatus } from "protogen/advisors_service_pb";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useFetchAdminEngagementReports } from "services/engagement";
import {
  sortFamiliesByFields,
  ColumnHeader,
  FamilyReportRow,
} from "components/engagement/ReportTable";

export default () => {
  const isMobile = useIsMobile();
  // const [filteredFamilies, setFilteredFamilies] = useState<Family[] | null>(
  //   null,
  // );
  const [sorts, setSorts] = useState<{ field: string; asc: boolean }[]>([
    // Note the order is how they will be applied, so the first entry is the primary sort.
    { field: "state", asc: true },
    { field: "familyName", asc: true },
  ]);
  const { request, data, loading } = useFetchAdminEngagementReports();
  useEffect(() => {
    request();
  }, []);
  const allReports = sortFamiliesByFields(
    (data?.reports || [])
      // .sort((a, b) => a.family.name.localeCompare(b.family.name))
      .filter((r) => r.family?.status === FamilyStatus.ACTIVE),
    sorts,
  );
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Family reports</Typography>
          </Box>
        </Box>
      </Box>
      {/*<FamilyFilters*/}
      {/*  families={data?.families || []}*/}
      {/*  advisors={distinctAdvisors}*/}
      {/*  onFiltered={(f) => setFilteredFamilies(f)}*/}
      {/*  extra={*/}
      {/*    <Button*/}
      {/*      onClick={() => mappableFamilies.length && setMapOpen(true)}*/}
      {/*      sx={{*/}
      {/*        borderRadius: "100px",*/}
      {/*        border: "2px solid #EAEBEC",*/}
      {/*        color: "#616161",*/}
      {/*        backgroundColor: "unset",*/}
      {/*        fontWeight: 500,*/}
      {/*        "&:hover": {*/}
      {/*          backgroundColor: "#EAEBEC",*/}
      {/*        },*/}
      {/*      }}*/}
      {/*      startIcon={<MapIcon size={20} stroke="#8E9598" />}*/}
      {/*    >*/}
      {/*      Mapped*/}
      {/*    </Button>*/}
      {/*  }*/}
      {/*/>*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={"0 24px"}
          mr="34px"
        >
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Family"
            sortKey="familyName"
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Advisor"
            sortKey="advisorName"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Last activity"
            sortKey="lastActivity"
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Utilization"
            sortKey="utilization"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Renewal"
            sortKey="renewal"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Start date"
            sortKey="startDate"
            hideMobile
          />
        </Box>
        {loading && <Loading />}
        {allReports.map((report) => (
          <FamilyReportRow key={report.family.ref} report={report} />
        ))}
      </Box>
    </Box>
  );
};
