import {
  Checkbox,
  CheckboxProps,
  FormControl,
  ListSubheader,
  ListSubheaderProps,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { ReactComponent as CheckboxIcon } from "../../icons/Checkbox.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../icons/CheckboxSelected.svg";
import { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";

// Move somewhere more general
const StyledCheckbox = ({ ...otherProps }: CheckboxProps) => {
  return (
    <Checkbox
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxSelectedIcon />}
      sx={{}}
      {...otherProps}
    />
  );
};

// See note here: https://mui.com/material-ui/react-select/#grouping
const MyListSubheader = (
  props: ListSubheaderProps & { muiSkipListHighlight: boolean },
) => {
  const { ...other } = props;
  return <ListSubheader {...other} />;
};

export type Option = {
  label: string;
  value: string;
  defaultChecked?: boolean;
};

type GroupArgs = {
  label: string;
  options: Option[];
  onChange: (s: Option[]) => void;
};

type Props = {
  groups: GroupArgs[];
  displayValue?: string;
  disabled?: boolean;
};

export default ({ groups, displayValue, disabled = false }: Props) => {
  const [values, setValues] = useState<string[]>(
    groups
      .map((g) => g.options)
      .flat()
      .filter((s) => s.defaultChecked)
      .map((s) => s.value),
  );
  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    const updatedValues = typeof value === "string" ? value.split(",") : value;
    setValues(updatedValues);
    const groupValues = new Map<string, string[]>();
    for (const v of updatedValues) {
      const [groupLabel, val] = v.split("|", 2);
      groupValues.set(
        groupLabel,
        (groupValues.get(groupLabel) || []).concat(val),
      );
    }
    for (const group of groups) {
      group.onChange(
        (groupValues.get(group.label) || []).map(
          (v) => group.options.find((o) => o.value === v) as Option,
        ),
      );
    }
  };

  const isMobile = useIsMobile();

  return (
    <FormControl>
      <Select
        disabled={disabled}
        value={values}
        multiple
        displayEmpty
        renderValue={() => (
          <Typography component="span" variant="body" fontWeight="500">
            {displayValue}
          </Typography>
        )}
        label={displayValue}
        onChange={handleChange}
        sx={{
          minHeight: "48px",
          fieldset: {
            borderRadius: "100px",
            border: "2px solid var(--Button-Colors-Gray-gray-3, #EAEBEC)",
          },
          ".MuiInputBase-input": {
            marginTop: "0px",
            fontWeight: "500",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "8px",
              borderRadius: "12px",
              border: "2px solid var(--Button-Colors-Gray-gray-3, #EAEBEC)",
              background: "var(--White, #FFF)",
              display: "flex",
              padding: isMobile ? "20px" : "19px 48px 19px 24px",
              flexDirection: "column",
              alignItems: "flex-start",
              boxShadow: "none",
              ul: {
                padding: 0,
                margin: 0,
                li: {
                  paddingLeft: "0px",
                  minWidth: "150px",
                  "&.Mui-selected, &:hover": {
                    backgroundColor: "transparent",
                  },
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                },
              },
            },
          },
        }}
        IconComponent={() => {
          return (
            <div
              style={{
                right: "20px",
                top: "16px",
                pointerEvents: "none",
                position: "absolute",
              }}
            >
              <ChevronDownIcon
                height="16px"
                width="16px"
                strokeWidth="2.5px"
                color="#8E9598"
              />
            </div>
          );
        }}
      >
        {groups.map((g, i) => {
          return [
            <MyListSubheader
              muiSkipListHighlight
              key={g.label}
              sx={{
                marginBottom: "4px",
                ...(i !== 0
                  ? {
                      borderTop: "1px solid #D4D4D4",
                      paddingTop: "24px",
                    }
                  : {
                      paddingBottom: "15px",
                    }),
              }}
            >
              <Typography variant="bodyHeavy">{g.label}</Typography>
            </MyListSubheader>,
            ...g.options.map((o) => (
              <MenuItem
                key={o.value}
                value={`${g.label}|${o.value}`}
                disableRipple={true}
                disableTouchRipple={true}
                sx={{
                  padding: 0,
                  "&:hover": { backgroundColor: "transparent" },
                  "&.Mui-selected:hover": { backgroundColor: "transparent" },
                }}
              >
                <StyledCheckbox
                  checked={values.indexOf(`${g.label}|${o.value}`) > -1}
                />
                <ListItemText primary={o.label} />
              </MenuItem>
            )),
          ];
        })}
      </Select>
    </FormControl>
  );
};
