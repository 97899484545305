import useProtoMethod from "./useProtoMethod";
import {
  FetchEngagementReportsRequest,
  FetchEngagementReportsResponse,
} from "protogen/engagement_service_pb";

const useFetchAdminEngagementReports = (
  callback?: (r: FetchEngagementReportsResponse) => void,
) => {
  return useProtoMethod<
    FetchEngagementReportsRequest,
    FetchEngagementReportsResponse
  >("FetchAdminEngagementReports", FetchEngagementReportsResponse, {
    callback,
  });
};
const useFetchAdvisorEngagementReports = (
  callback?: (r: FetchEngagementReportsResponse) => void,
) => {
  return useProtoMethod<
    FetchEngagementReportsRequest,
    FetchEngagementReportsResponse
  >("FetchAdvisorEngagementReports", FetchEngagementReportsResponse, {
    callback,
  });
};

export { useFetchAdminEngagementReports, useFetchAdvisorEngagementReports };
