import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { Box } from "@mui/material";
import { StyledTab, StyledTabs } from "./Tab";
import { useNavigate, useLocation } from "react-router-dom";
import TabPanel from "../helpers/TabPanel";

interface PanelProps {
  title: string;
}

interface TabbedDetailsPanelProps {
  panels: React.ReactElement<PanelProps>[];
  defaultTab?: number;
  sx?: CSSProperties;
}

export default ({ panels, defaultTab = 0, sx }: TabbedDetailsPanelProps) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(defaultTab);
  const { hash } = useLocation();
  const parentContainer = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const tab = hash ? parseInt(hash.slice(5)) : defaultTab;
    setTabValue(tab);
  }, [hash]);

  useEffect(() => {
    scrollAreaRef.current?.focus?.();
  }, []);

  return (
    <Box
      ref={parentContainer}
      sx={{
        minWidth: "100px",
        gap: "24px",
        alignSelf: "stretch",
        padding: "0px 20px 0 20px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...sx,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={tabValue}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
            navigate(`#tab-${newValue}`, { replace: true });
          }}
        >
          {panels.map((panel, i) => (
            <StyledTab key={i} label={panel.props.title} id={`tab-${i}`} />
          ))}
        </StyledTabs>
      </Box>
      <Box
        ref={scrollAreaRef}
        sx={{
          flex: "1 1 100px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          outline: "none",
        }}
        tabIndex={0}
      >
        {panels.map((panel, idx) => {
          return (
            <TabPanel children={panel} index={idx} key={idx} value={tabValue} />
          );
        })}
      </Box>
    </Box>
  );
};
