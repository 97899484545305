import { useState, useCallback, useRef } from "react";

type Props = {
  onLongPress: () => void;
  delay?: number;
};

type ReturnType = {
  onTouchStart: () => void;
  onTouchEnd: () => void;
  onTouchMove: () => void;
};

export default ({ onLongPress, delay = 500 }: Props): ReturnType => {
  const [, setLongPressTriggered] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>();

  const start = useCallback(() => {
    setLongPressTriggered(false);
    timerRef.current = setTimeout(() => {
      setLongPressTriggered(true);
      onLongPress();
    }, delay);
  }, [onLongPress, delay]);

  const stop = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setLongPressTriggered(false);
  }, []);

  const handlers = {
    onTouchStart: start,
    onTouchEnd: stop,
    onTouchMove: stop,
  };

  return handlers;
};
