import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ExternalLink } from "lucide-react";
import LinkRouter from "components/navigation/LinkRouter";
import Loading from "components/common/Loading";
import { useListInvoices, useUpdateInvoice } from "services/billing";
import {
  ListInvoicesRequest,
  Invoice,
  UpdateInvoiceRequest,
} from "protogen/billing_service_pb";
import { feeSchedule } from "components/family/utils";
import InvoiceFilters, {
  InvoiceFilter,
} from "components/payments/InvoiceFilters";
import InvoiceStatusSelect from "components/payments/InvoiceStatusSelect";
import Collapse from "@mui/material/Collapse";
import WithDividers from "components/helpers/WithDividers";
import { PartialMessage } from "@bufbuild/protobuf";

const formatPrice = (amountCents: number, currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amountCents / 100);
};

const formatDateRange = (startSeconds: bigint, endSeconds: bigint) => {
  const startDate = new Date(Number(startSeconds) * 1000);
  const endDate = new Date(Number(endSeconds) * 1000);
  // Get the year only from the end date, assuming both dates are in the same year
  const year = endDate.getFullYear();
  let options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  const startFormatted = startDate.toLocaleDateString("en-US", options);
  const endFormatted = endDate.toLocaleDateString("en-US", options);

  return `${startFormatted} - ${endFormatted}, ${year}`;
};

const shortDate = (dateString: string) => {
  const [month, day, year] = dateString.split("/");
  const date = new Date(`${year}-${month}-${day}`);
  let options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const EditInvoice = ({
  invoice,
  onClose,
  update,
  loading,
}: {
  invoice: Invoice;
  onClose: () => void;
  update: (data: PartialMessage<UpdateInvoiceRequest>) => void;
  loading: boolean;
}) => {
  // Initialize the state using normal units (percent and dollars)
  // const [feePercent, setFeePercent] = useState(invoice.feePercent * 100 ?? 0); // Convert 0.05 to 5%
  const [paymentDollars, setPaymentDollars] = useState(
    invoice.paymentCents / 100 ?? 0,
  ); // Convert cents to dollars
  const [changeNotes, setChangeNotes] = useState("");

  const onSave = () => {
    // if (feePercent < 0 || feePercent > 100) return;
    // Convert back to original units when saving
    update({
      // feePercent: feePercent / 100, // Convert 5% back to 0.05
      paymentCents: Math.round(paymentDollars * 100), // Convert dollars back to cents
      changeNotes,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px" padding="16px">
      {/*<TextField*/}
      {/*  label="Fee Percent"*/}
      {/*  type="number"*/}
      {/*  value={feePercent}*/}
      {/*  onChange={(e) => setFeePercent(parseFloat(e.target.value))}*/}
      {/*  inputProps={{ min: 0, max: 100, step: 1 }} // Increment by whole percentage points*/}
      {/*  fullWidth*/}
      {/*  InputProps={{*/}
      {/*    endAdornment: <InputAdornment position="end">%</InputAdornment>,*/}
      {/*  }}*/}
      {/*/>*/}
      <TextField
        label="Payment"
        type="number"
        value={paymentDollars}
        onChange={(e) => setPaymentDollars(parseFloat(e.target.value))}
        inputProps={{ min: 0, step: 0.01 }} // Represent in dollars
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Change Notes"
        multiline
        rows={4}
        value={changeNotes}
        onChange={(e) => setChangeNotes(e.target.value)}
        fullWidth
      />
      <Box justifyContent="end" display="flex" flexDirection="row" gap="8px">
        <Button onClick={onClose} variant="text" disabled={loading}>
          Close
        </Button>
        <Button onClick={onSave} variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

const InvoiceHistory = ({
  invoice,
  onClose,
}: {
  invoice: Invoice;
  onClose: () => void;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box display="flex" flexDirection="column" gap="8px">
        <Box display="flex" flexDirection="row">
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Date</Typography>
          </Box>
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Change Notes</Typography>
          </Box>
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Changes</Typography>
          </Box>
        </Box>
        <WithDividers>
          {invoice.changeEvents.map((changeEvent, i) => {
            const eventData = JSON.parse(changeEvent.eventJson);
            const changedKeys: string[] = Array.from(
              Object.keys(eventData),
            ).filter(
              (key) =>
                typeof eventData[key] !== "string" &&
                eventData[key]?.length === 2,
            );
            return (
              <Box key={i} display="flex" flexDirection="row">
                <Box flex={"1"}>
                  <Typography sx={{ color: "text.secondary" }}>
                    {eventData["timestamp"]}
                  </Typography>
                </Box>
                <Box flex={"1"}>
                  <Typography sx={{ color: "text.secondary" }}>
                    {eventData["change_notes"] || "N/A"}
                  </Typography>
                </Box>
                <Box flex={"1"}>
                  {changedKeys.map((k) => (
                    <Box key={k}>
                      <Typography variant="bodyHeavy" sx={{ fontWeight: 500 }}>
                        {k}
                      </Typography>
                      <Typography variant="body">
                        From: "{eventData[k][0]}", to: "{eventData[k][1]}"
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
        </WithDividers>
      </Box>
      <Box justifyContent="end" display="flex" flexDirection="row">
        <Button onClick={onClose} variant="text">
          Close
        </Button>
      </Box>
    </Box>
  );
};

const InvoiceCard = ({
  invoice,
  refresh,
}: {
  invoice: Invoice;
  refresh: () => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [viewMode, setViewMode] = useState<"edit" | "history">("edit");
  const isMobile = useIsMobile();
  const { request, loading } = useUpdateInvoice();
  const update = async (data: PartialMessage<UpdateInvoiceRequest>) => {
    if (loading) return;
    await request(
      new UpdateInvoiceRequest({
        invoiceRef: invoice.ref,
        ...data,
      }),
    );
    refresh();
  };

  const byline = (
    <LinkRouter
      targetNew={true}
      to={`https://dashboard.stripe.com/invoices/${invoice.stripeId}`}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography variant="body" sx={{ fontWeight: 500, marginRight: "4px" }}>
          {formatPrice(invoice.invoiceAmountCents, invoice.invoiceCurrency)}
        </Typography>
        <Typography variant="body">
          (
          {invoice.periodStartSec
            ? formatDateRange(invoice.periodStartSec, invoice.periodEndSec)
            : " - "}
          )
        </Typography>
        <ExternalLink size={16} style={{ marginLeft: "8px" }} />
      </Box>
    </LinkRouter>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
        gap: "8px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
      >
        <Box flex="2">
          <Box display="flex" flexDirection={"column"} gap="4px">
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              gap="4px"
              alignItems={"center"}
            >
              <Typography
                variant="bodyHeavy"
                color="#262626"
                sx={{
                  textWrap: "nowrap",
                }}
              >
                <LinkRouter to={`/advisor/${invoice.advisor?.ref}`}>
                  {invoice.advisor?.firstName} {invoice.advisor?.lastName}
                </LinkRouter>
              </Typography>
              {!isMobile && <> &middot; </>}
              <Typography
                sx={{
                  ...(!isMobile && {
                    textWrap: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                  }),
                }}
                color="text.secondary"
              >
                <LinkRouter to={`/families/${invoice.family?.ref}`}>
                  {invoice.family?.name}
                </LinkRouter>
              </Typography>
            </Box>
            <Typography
              variant="body"
              color="text.secondary"
              sx={{
                textWrap: "none",
                textOverflow: "ellipses",
                overflow: "hidden",
              }}
            >
              {byline}
            </Typography>
          </Box>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body" color="text.secondary">
            {invoice.serviceMonth}
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            {invoice.family?.startDate &&
              `Started ${shortDate(invoice.family?.startDate)}`}
          </Typography>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body" color="text.secondary">
            {invoice.feePercent * 100}%
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            {feeSchedule(invoice.family?.platformFeeSchedule)}
          </Typography>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body"
            color="#262626"
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              fontWeight: 500,
            }}
          >
            {formatPrice(invoice.paymentCents, "USD")}
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            ({formatPrice(invoice.invoiceAmountCents, invoice.invoiceCurrency)}{" "}
            - {invoice.feePercent * 100}%)
          </Typography>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <InvoiceStatusSelect
            status={invoice.status}
            setStatus={(s) => update({ status: s })}
          />
          <Box
            style={{
              marginTop: "6px",
            }}
          >
            <span
              onClick={() => {
                setExpanded(true);
                setViewMode("edit");
              }}
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "14px",
                margin: "0 6px",
              }}
            >
              overrides
            </span>
            &middot;
            <span
              onClick={() => {
                setExpanded(true);
                setViewMode("history");
              }}
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "14px",
                margin: "0 6px",
              }}
            >
              history
            </span>
          </Box>
          {/*{getStatusBadge(invoice.status) || (*/}
          {/*  <Button*/}
          {/*    onClick={queuePayment}*/}
          {/*    endIcon={<Receipt />}*/}
          {/*    sx={{*/}
          {/*      borderRadius: "4px",*/}
          {/*      color: "#FFF",*/}
          {/*      backgroundColor: "#2196f3",*/}
          {/*      borderColor: "#FFF",*/}
          {/*      "&:hover": {*/}
          {/*        backgroundColor: "#1769aa",*/}
          {/*      },*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Pay*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Box>
      </Box>
      <Collapse in={expanded}>
        {viewMode === "edit" && (
          <EditInvoice
            invoice={invoice}
            onClose={() => setExpanded(false)}
            update={update}
            loading={loading}
          />
        )}
        {viewMode === "history" && (
          <InvoiceHistory
            invoice={invoice}
            onClose={() => setExpanded(false)}
          />
        )}
      </Collapse>
    </Box>
  );
};

type Props = {};
export default ({}: Props) => {
  const hasLoadedOnce = useRef(false);
  // const currentUser = useContext(CurrentUserContext);
  // let params = useParams();
  const isMobile = useIsMobile();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const { request, loading, data } = useListInvoices();
  const [filter, setFilter] = useState<InvoiceFilter>({
    statuses: null,
    createdSinceSeconds: null,
    advisorRef: null,
    familyRef: null,
  });
  const fetchInvoices = async () => {
    await request(
      new ListInvoicesRequest({
        ...(filter.statuses && { statuses: filter.statuses }),
        ...(filter.createdSinceSeconds && {
          createdSecStart: BigInt(filter.createdSinceSeconds),
        }),
        ...(filter.advisorRef && { advisorRef: filter.advisorRef }),
        ...(filter.familyRef && { familyRef: filter.familyRef }),
      }),
    );
    hasLoadedOnce.current = true;
  };
  useEffect(() => {
    fetchInvoices();
  }, [filter]);

  const refresh = () => {
    fetchInvoices();
  };
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Advisor Payments</Typography>
          </Box>
        </Box>
      </Box>
      <InvoiceFilters setFilter={setFilter} loading={loading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flex="2">
              <Typography variant="bodySmallHeavy" color="#262626">
                Invoice
              </Typography>
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="bodySmallHeavy" color="#262626">
                Service Month
              </Typography>
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="bodySmallHeavy" color="#262626">
                Fee %
              </Typography>
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="bodySmallHeavy" color="#262626">
                Payment
              </Typography>
            </Box>
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="bodySmallHeavy" color="#262626">
                Action
              </Typography>
            </Box>
          </Box>
        )}
        {loading && !hasLoadedOnce.current && <Loading />}
        {data?.invoices.map((invoice) => (
          <InvoiceCard key={invoice.ref} invoice={invoice} refresh={refresh} />
        ))}
        {hasLoadedOnce.current && data?.invoices.length === 0 && (
          <Typography variant="body" color="text.tertiary">
            No invoices found
          </Typography>
        )}
      </Box>
    </Box>
  );
};
