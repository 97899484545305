import { styled } from "@mui/system";
import { Tab, TabProps, Tabs, TabsProps } from "@mui/material";

const StyledTab = styled((props: TabProps) => <Tab {...props} />)(() => ({
  color: "#667085",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  padding: "14px 4px",
  textTransform: "none",
  "&.MuiButtonBase-root": {
    padding: 0,
    minWidth: 0,
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#000",
  },
  "&.Mui-selected": {
    color: "#000",
    fontWeight: "700",
  },
}));
const StyledTabs = styled((props: TabsProps) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-flexContainer": {
    gap: "16px",
  },
  ".MuiTabs-indicator": {
    backgroundColor: "#EF7B77",
  },
}));
export { StyledTab, StyledTabs };
