import { Typography, Tooltip } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import { relativeTime, absoluteTime } from "../../common/utils";

export default ({
  date,
  variant = "bodySmall",
  color = "textSecondary",
  tooltipEnabled = true,
  sx,
}: {
  date: Date;
  variant?: TypographyProps["variant"];
  color?: string;
  tooltipEnabled?: boolean;
  sx?: React.CSSProperties;
}) => {
  const absTime = absoluteTime(date);

  const content = (
    <Typography variant={variant} color={color} sx={{ ...sx }}>
      {relativeTime(date)}
    </Typography>
  );

  return tooltipEnabled ? (
    <Tooltip
      title={absTime}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};
