import api from "./api";
import TokenStore from "./TokenStore";
import {
  InitiatePasswordResetResponse,
  LoginResponse,
  ResetPasswordResponse,
  InitiateMemberLoginResponse,
  MemberLoginResponse,
  AdvisorNuxLoginResponse,
  InitiatePasswordResetRequest_Flow,
  AcceptTermsOfServiceResponse,
  AcceptTermsOfServiceRequest,
  TokenLoginResponse,
} from "protogen/auth_service_pb";

import { AuthToken, CurrentUser } from "protogen/auth_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import useProtoMethod from "./useProtoMethod";

const protoMethod = (method: string, payload: any) => {
  return api.post(`/proto?method=${method}`, {
    method,
    payload,
  });
};

// Don't use hook pattern because we want to handle setting the token here.
const login = (
  username: string,
  password: string,
  subscription: string | null,
) => {
  return protoMethod("Login", {
    username,
    password,
    subscription: subscription || "",
  }).then((response: { data: PlainMessage<LoginResponse> }) => {
    if (response.data.accessToken) {
      // TODO: Set user as well.
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const initiateMemberLogin = (
  email: string,
  phone: string,
  redirect?: string | null,
) => {
  return protoMethod("InitiateMemberLogin", {
    email,
    phone,
    redirectUrl: redirect || "",
  }).then((response: { data: PlainMessage<InitiateMemberLoginResponse> }) => {
    return response.data;
  });
};

const memberLogin = (token: string, subscription: string | null) => {
  return protoMethod("MemberLogin", {
    token,
    subscription: subscription || "",
  }).then((response: { data: PlainMessage<MemberLoginResponse> }) => {
    if (response.data.accessToken) {
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const advisorNuxLogin = (token: string) => {
  return protoMethod("AdvisorNuxLogin", {
    token,
  }).then((response: { data: PlainMessage<AdvisorNuxLoginResponse> }) => {
    if (response.data.accessToken) {
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const memberNuxLogin = (token: string) => {
  return protoMethod("MemberNuxLogin", {
    token,
  }).then((response: { data: PlainMessage<AdvisorNuxLoginResponse> }) => {
    if (response.data.accessToken) {
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const tokenLogin = (token: string) => {
  return protoMethod("TokenLogin", {
    token,
  }).then((response: { data: PlainMessage<TokenLoginResponse> }) => {
    if (response.data.accessToken) {
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const initiatePasswordReset = (
  email: string,
  flow?: InitiatePasswordResetRequest_Flow,
) => {
  return protoMethod("InitiatePasswordReset", {
    email,
    flow: flow || InitiatePasswordResetRequest_Flow.UNSPECIFIED,
  }).then((response: { data: PlainMessage<InitiatePasswordResetResponse> }) => {
    return response.data;
  });
};

const resetPassword = (
  token: string,
  username: string,
  password: string,
  subscription: string | null,
) => {
  return protoMethod("ResetPassword", {
    token,
    username,
    password,
    subscription: subscription || "",
  }).then((response: { data: PlainMessage<ResetPasswordResponse> }) => {
    if (response.data.accessToken) {
      TokenStore.setAuthData({
        accessToken: response.data.accessToken,
        // Make typescript happy for a nullable type.
        refreshToken: response.data.refreshToken || new AuthToken(),
        currentUser: new CurrentUser(response.data.currentUser),
      });
    }
    return response.data;
  });
};

const logout = () => {
  // Fire/forget the logout request. Note that we want this to be an authenticated request so we
  // need to clear the data after it has been made.
  return protoMethod("Logout", {})
    .then(() => {
      TokenStore.clearAuthData();
    })
    .catch(() => {
      TokenStore.clearAuthData();
    });
};

const getCurrentUser = (): CurrentUser | undefined => {
  return TokenStore.getLocalCurrentUser();
};

const useAcceptTermsOfService = (
  callback?: (r: AcceptTermsOfServiceResponse) => void,
) => {
  return useProtoMethod<
    AcceptTermsOfServiceRequest,
    AcceptTermsOfServiceResponse
  >("AcceptTermsOfService", AcceptTermsOfServiceResponse, { callback });
};

const AuthService = {
  login,
  logout,
  resetPassword,
  initiateMemberLogin,
  initiatePasswordReset,
  getCurrentUser,
  memberLogin,
  advisorNuxLogin,
  memberNuxLogin,
  useAcceptTermsOfService,
  tokenLogin,
};

export default AuthService;
