import { ReactNode } from "react";
import { SwipeableDrawer } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer/Drawer";

interface MobileDrawerProps extends DrawerProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  drawerWidth: number;
  children: ReactNode;
}

export default ({
  open,
  setOpen,
  drawerWidth,
  children,
  ...otherProps
}: MobileDrawerProps) => {
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <SwipeableDrawer
      {...otherProps}
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          boxShadow: "none",
          // We want a left border, but start it from below the app bar
          "&::before": {
            content: '""',
            position: "absolute",
            top: "50px",
            bottom: 0,
            left: 0,
            borderLeft: "1px solid #E8E8E8",
          },
        },
      }}
      // variant="persistent"
      anchor="right"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      // See perf concerns:
      // https://mui.com/material-ui/react-drawer/#swipeable
      disableBackdropTransition={!iOS}
      // We should disable this when it's on the left.
      disableDiscovery={false}
      // Removes the default backdrop
      componentsProps={{
        backdrop: { invisible: true },
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};
