import { Checkbox, CheckboxProps } from "@mui/material";
import { ReactComponent as RadioIcon } from "../../icons/Radio.svg";
import { ReactComponent as RadioSelectedIcon } from "../../icons/RadioSelected.svg";

export default ({ ...otherProps }: CheckboxProps) => {
  return (
    <Checkbox
      icon={<RadioIcon />}
      checkedIcon={<RadioSelectedIcon />}
      sx={{}}
      {...otherProps}
    />
  );
};
