import { useEffect, useState } from "react";
import { PhoneState } from "../../types/phone";
import { Device } from "@twilio/voice-sdk";
import { useGetTwilioToken } from "services/phone";
import useIsVisible from "../hooks/useIsVisible";

const CACHE_KEY = "twilio-device-session-token";
const CACHE_TTL = 25 * 60 * 1000; // token is good for 30 minutes.

const storeInSessionStorage = (token: string): void => {
  const now = new Date().getTime();
  sessionStorage.setItem(
    CACHE_KEY,
    JSON.stringify({
      token,
      timestamp: now,
    }),
  );
};

const getFromSessionStorage = (): string | null => {
  const item = sessionStorage.getItem(CACHE_KEY);
  if (!item) {
    return null;
  }
  const { token, timestamp } = JSON.parse(item);
  const now = new Date().getTime();

  // Check if TTL has passed
  if (now - timestamp > CACHE_TTL) {
    sessionStorage.removeItem(CACHE_KEY); // Optionally clear the expired item
    return null;
  }

  return token;
};

export type DeviceManagerReturnType = {
  phoneState: PhoneState;
  isMuted: boolean;
  setAcceptNextCall?: (initiated: boolean) => void;
  acceptCall?: () => void;
  denyCall?: () => void;
  endCall?: () => void;
  muteCall?: () => void;
  sendDigits?: (d: string) => void;
  // Active Call
  // End call
};

const useToken = (): { token: string | null } => {
  const { request } = useGetTwilioToken((resp) => {
    storeInSessionStorage(resp.token);
  });
  const { isVisible } = useIsVisible({});
  // Use cache, this gets called all the time.
  useEffect(() => {
    if (isVisible && !getFromSessionStorage()) {
      request();
      const intervalId = setInterval(
        () => {
          request();
        },
        1000 * 60 * 25,
      ); // token is good for 30 minutes.
      return () => clearInterval(intervalId);
    }
  }, [isVisible]);
  return {
    token: getFromSessionStorage(),
  };
};

export default (): DeviceManagerReturnType => {
  const [phoneState, setPhoneState] = useState<PhoneState>(
    PhoneState.NOT_CONFIGURED,
  );
  const { token } = useToken();
  const [connection, setConnection] = useState<any>(null);
  const [acceptNextCall, setAcceptNextCall] = useState(false);
  const [device, setDevice] = useState<Device | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  // I'm not sure this click handling is strictly necessary, it does prevent
  // a warning about "e AudioContext was not allowed to start. I" though.
  const startDeviceOnFirstClick = async () => {
    if (phoneState === PhoneState.NOT_CONFIGURED && token) {
      // Trigger microphone permissions for the first time.
      // TODO(Kip): Rethink this chaos.
      // const constraints = { audio: true };
      // await navigator.mediaDevices.getUserMedia(constraints)
      setPhoneState(PhoneState.CREATED);
      // Useful in dev, but noisy if we aren't actively debugging.
      // setDevice(new Device(token, { logLevel: "DEBUG" }));
      setDevice(new Device(token));
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    if (phoneState === PhoneState.NOT_CONFIGURED) {
      // See which one triggers first.
      timeoutId = setTimeout(startDeviceOnFirstClick, 2000);
      document.addEventListener("click", startDeviceOnFirstClick);
    }
    return () => {
      document.removeEventListener("click", startDeviceOnFirstClick);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [phoneState, token]);

  useEffect(() => {
    const setupDevice = async (device_: Device) => {
      device_.audio?.incoming(false);
      device_.audio?.outgoing(false);
      device_.audio?.disconnect(false);
      // Works
      device_.on("incoming", (incomingCall) => {
        console.log("Incoming connection from " + incomingCall);
        // This is dirty! I lack better ideas though.
        incomingCall._pstream.on("hangup", () => {
          setPhoneState(PhoneState.REGISTERED);
        });
        if (acceptNextCall) {
          incomingCall.accept();
          setPhoneState(PhoneState.ACTIVE);
          setAcceptNextCall(false);
        } else {
          setPhoneState(PhoneState.RINGING);
        }
        setConnection(incomingCall);
      });
      device_.on("registered", () => {
        // setStatus(connection.status());
      });

      // Unclear...
      device_.on("cancel", () => {
        console.log("cancel");
        // setStatus(connection_.status());
      });
      device_.on("offline", () => {
        console.log("offline");
        // setStatus(connection_.status());
      });

      device_.on("error", (error) => {
        console.log("Device on error:", error.originalError.message);
      });

      device_.on("ready", () => {
        console.log("ready");
        // setStatus("device ready");
        // setReady(true);
      });

      device_.on("connect", (connection_) => {
        console.log("connect", connection_);
        // setStatus(connection_.status());
      });

      device_.on("disconnect", (connection_) => {
        console.log("disconnect", connection_);
        setConnection(null);
        // setStatus(connection_.status());
      });
      device_.on("deviceChange", () => {
        console.log("deviceChange");
      });

      try {
        await device_.register();
        setPhoneState(PhoneState.REGISTERED);
      } catch (error) {
        console.log("Error registering device: ", error);
        setPhoneState(PhoneState.FAILED);
      }
    };
    if (device) {
      setupDevice(device);
    }
    // Cleanup on unmount
    return () => {
      if (device) {
        device.destroy();
        setDevice(null);
      }
    };
  }, [device]);

  const acceptCall = () => {
    if (!connection) return;
    connection.accept();
    setPhoneState(PhoneState.ACTIVE);
    setAcceptNextCall(false);
  };
  const denyCall = () => {
    if (!connection) return;
    connection.reject();
    setPhoneState(PhoneState.REGISTERED);
    setAcceptNextCall(false);
  };

  const sendDigits = (digits: string) => {
    if (!connection) return;
    connection.sendDigits(digits);
  };

  const endCall = () => {
    if (!connection) return;
    connection.disconnect();
    setPhoneState(PhoneState.REGISTERED);
    setAcceptNextCall(false);
  };

  const muteCall = () => {
    if (!connection) return;
    const shouldMute = !isMuted;
    connection.mute(shouldMute);
    setIsMuted(shouldMute);
  };

  return {
    phoneState,
    isMuted,
    acceptCall,
    denyCall,
    endCall,
    muteCall,
    setAcceptNextCall,
    sendDigits,
  };
};
