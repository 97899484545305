import {
  Autocomplete,
  InputBase,
  ListItem,
  Paper,
  Popper,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useIsMobile from "../hooks/useIsMobile";
import { checkEmail } from "../../common/userUtils";

export type AutocompleteEntry = {
  name: string;
  email: string;
};

interface Props {
  autocompleteEntries: AutocompleteEntry[];
  addAddresses: (address: string[]) => void;
  usedAddresses?: Set<string>;
  placeholder?: string;
  anchorID: string;
  fullWidthInput?: boolean;
  autofocus?: boolean;
}

export type Handle = {
  setFocus: () => void;
};

export default forwardRef<Handle, Props>(
  (
    {
      autocompleteEntries,
      addAddresses,
      usedAddresses,
      placeholder,
      anchorID,
      fullWidthInput = false,
      autofocus = false,
    }: Props,
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [val, setVal] = useState("");
    const [validationError, setError] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const handlePaste = (e: React.ClipboardEvent) => {
      // On paste, split out as many emails as possible
      // "kip@findfaye.com, kip.kaehler@gmail.com, kip@gmail.com"
      const pastedText = e.clipboardData.getData("text");
      const emails = pastedText
        .split(/[\s,;]+/)
        .map((e) => e.trim())
        .filter((e) => checkEmail(e));
      // If there's only one email, just run normal paste behavior.
      if (emails.length <= 1) return true;
      addAddresses(emails);
      setVal("");
      setError(false);
      e.preventDefault();
      return false;
    };
    const addEntry = (withVal?: string) => {
      const email = withVal || val.trim();
      if (email.length == 0) return;
      if (!checkEmail(email)) {
        setError(true);
        return;
      }
      addAddresses([email]);
      setVal("");
      setError(false);
    };

    useImperativeHandle(ref, () => ({
      setFocus: () => {
        inputRef.current?.querySelector("input")?.focus();
      },
    }));

    let filteredOptions: AutocompleteEntry[];
    if (!autocompleteEntries || val == "") {
      filteredOptions = [];
    } else {
      filteredOptions = autocompleteEntries.filter(
        (option) =>
          (!usedAddresses || !usedAddresses.has(option.email)) &&
          (option.email.toLowerCase().includes(val.toLowerCase()) ||
            option.name.toLowerCase().includes(val.toLowerCase())),
      );
    }

    const MyPopper = useCallback((props) => {
      const anchorEl = document.getElementById(anchorID);
      return (
        <Popper
          {...props}
          anchorEl={anchorEl}
          style={{
            width: anchorEl?.clientWidth,
          }}
          placement="bottom-start"
        />
      );
    }, []);

    return (
      <Autocomplete
        id="email-input-autocomplete"
        sx={{
          padding: "4px",
          minWidth: "100px",
          ...(fullWidthInput ? { flexBasis: "100%" } : { flex: 1 }),
        }}
        freeSolo
        // Not sure what this does, but it removes a warning message.
        isOptionEqualToValue={(option, value) => option.email === value.email}
        inputValue={val}
        fullWidth={val.length > (isMobile ? 1 : 10)}
        open={filteredOptions.length > 0}
        getOptionLabel={(option: AutocompleteEntry | string) =>
          typeof option === "string" ? option : option.email
        }
        PopperComponent={MyPopper}
        renderOption={(props, option: AutocompleteEntry) => (
          <ListItem {...props} key={option.email}>
            {option.name ? (
              <ListItemText primary={option.name} secondary={option.email} />
            ) : (
              <ListItemText primary={option.email} />
            )}
          </ListItem>
        )}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              width: "100%",
            }}
          >
            {children}
          </Paper>
        )}
        onChange={(_, newInputValue) => {
          if (!newInputValue) return;
          if (typeof newInputValue === "string") return;
          addEntry(newInputValue.email);
        }}
        options={filteredOptions}
        renderInput={(params) => (
          <InputBase
            autoFocus={autofocus}
            ref={inputRef}
            sx={{
              ...(validationError ? { color: "#d32f2f" } : {}),
              width: "100%",
            }}
            inputProps={{
              ...params.inputProps,
            }}
            inputRef={params.InputProps.ref}
            onBlur={() => addEntry()}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" ||
                e.key === "Tab" ||
                e.key === " " ||
                e.key === ","
              ) {
                addEntry();
                e.preventDefault();
                return false;
              }
            }}
            onPaste={handlePaste}
            onChange={(e) => {
              setError(false);
              setVal(e.target.value);
            }}
            placeholder={placeholder}
          />
        )}
      />
    );
  },
);
