import React, { ReactNode, useContext } from "react";
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_ADVISOR,
  ROLE_SUPERADMIN,
} from "../../common/userUtils";
import { CurrentUserContext } from "components/context/RequireAuth";
import { Box, Grid, Typography } from "@mui/material";
import LinkRouter from "components/navigation/LinkRouter";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { CurrentUser } from "protogen/auth_pb";
import {
  Bell,
  BookHeart,
  BookUser,
  CalendarHeart,
  ContactRound,
  FlaskConical,
  FolderKanban,
  MailQuestion,
  UserCog,
  VenetianMask,
  Videotape,
  ScissorsLineDashed,
  SquareActivity,
  Lightbulb,
  Landmark,
} from "lucide-react";
import {
  FEATURE_FLAGS,
  isFeatureEnabled,
} from "components/helpers/GatedComponent";

// You can replace these types with the actual types you are using.
type Tool = {
  path: string;
  name: string;
  acceptableRoles: string[];
  icon: ReactNode;
  description?: string;
  badged?: boolean;
};

const tools: Tool[] = [
  // Should be dynamic at some point.
  // Advisor tools
  {
    path: "advisor-directory",
    name: "Advisor directory",
    acceptableRoles: [ROLE_ADVISOR],
    icon: <ContactRound size={32} color="#198282" />,
    description:
      "Discover and meet other FAs in your area with different specialties.",
  },
  {
    path: "feature-switches",
    name: "Faye labs",
    acceptableRoles: [ROLE_ADVISOR],
    icon: <FlaskConical size={32} color="#198282" />,
    description: "Test out new features before they’re published for everyone.",
  },
  {
    path: "reports",
    name: "Reports",
    acceptableRoles: [ROLE_ADVISOR],
    icon: <FolderKanban size={32} color="#198282" />,
    description:
      "See statistics on hours spent on tasks, task counts, and more.",
  },
  {
    path: "family-notifications",
    name: "Family Notifications",
    acceptableRoles: [ROLE_ADVISOR],
    icon: <Bell size={32} color="#198282" />,
    description: "Customize which families you receive notifications for.",
  },
  ...(isFeatureEnabled(FEATURE_FLAGS.CALENDAR_URLS.value)
    ? [
        {
          path: "export-calendars",
          name: "Export Calendars",
          acceptableRoles: [ROLE_ADVISOR],
          icon: <CalendarHeart size={32} color="#198282" />,
          description: "Pull family calendars into your own calendar system.",
        },
      ]
    : []),
  {
    path: "url-shortener",
    name: "Url Shortener",
    acceptableRoles: [ROLE_ADVISOR],
    icon: <ScissorsLineDashed size={32} color="#198282" />,
    description: "Create shortened Faye branded urls.",
  },
  // Admin tools
  {
    path: "audit-log",
    name: "Audit Log",
    acceptableRoles: [ROLE_ADMIN],
    icon: <Videotape size={32} color="#198282" />,
  },
  {
    path: "advisor-administration",
    name: "Manage advisors",
    acceptableRoles: [ROLE_ADMIN],
    icon: <UserCog size={32} color="#198282" />,
  },
  {
    path: "family-directory",
    name: "Family directory",
    acceptableRoles: [ROLE_ADMIN],
    icon: <BookHeart size={32} color="#198282" />,
  },
  {
    path: "family-engagement",
    name: "Family engagement",
    acceptableRoles: [ROLE_ADMIN],
    icon: <SquareActivity size={32} color="#198282" />,
  },
  {
    path: "impersonate-user",
    name: "Impersonate user",
    acceptableRoles: [ROLE_ADMIN],
    icon: <VenetianMask size={32} color="#198282" />,
  },
  {
    path: "manage-roles",
    name: "Manage roles",
    acceptableRoles: [ROLE_SUPERADMIN],
    icon: <BookUser size={32} color="#198282" />,
  },
  {
    path: "unassigned-emails",
    name: "Unassigned emails",
    acceptableRoles: [ROLE_ADMIN],
    icon: <MailQuestion size={32} color="#198282" />,
  },
  {
    path: "task-inspiration",
    name: "Task inspiration",
    acceptableRoles: [ROLE_ADMIN],
    icon: <Lightbulb size={32} color="#198282" />,
  },
  {
    path: "advisor-payments",
    name: "Advisor Payments",
    acceptableRoles: [ROLE_ADMIN], // This should be ROLE_PAYMENTS
    icon: <Landmark size={32} color="#198282" />,
  },
  // add more tools here
];

const ToolTile = ({
  tool,
  currentUser,
}: {
  tool: Tool;
  currentUser: CurrentUser;
}) => {
  const disabled = !hasOneOfRoles(currentUser, tool.acceptableRoles);
  return (
    /* each item takes up 1/2 (6/12ths) of the row */
    <Grid item xs={12} md={6}>
      <LinkRouter
        sx={{ display: "block", position: "relative" }}
        key={tool.path}
        to={`/tools/${tool.path}`}
        disabled={disabled}
      >
        {tool.badged && (
          <span
            style={{
              position: "absolute",
              top: -3,
              left: -6,
              backgroundColor: "rgb(211, 47, 47)",
              minWidth: "12px",
              height: "12px",
              borderRadius: "12px",
            }}
          ></span>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          sx={(theme) => ({
            display: "flex",
            border: `1px solid ${theme.palette.border}`,
            padding: "24px 32px",
            justifyContent: "center",
            cursor: "pointer",
            alignSelf: "stretch",
            borderRadius: "8px",
            background: "#FFF",
            overflow: "hidden",
            ...(disabled && { opacity: 0.5 }),
          })}
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="12px"
            alignItems="center"
          >
            {tool.icon}
            <Typography variant="h4">{tool.name}</Typography>
          </Box>
          {tool.description && (
            <Typography
              variant="body1"
              sx={{
                height: "46px",
                overflow: "hidden",
              }}
            >
              {tool.description}
            </Typography>
          )}
        </Box>
      </LinkRouter>
    </Grid>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const currentUser = useContext(CurrentUserContext);

  const advisorTools = tools
    .filter((tool) => tool.acceptableRoles.includes(ROLE_ADVISOR))
    .sort((a, b) => a.name.localeCompare(b.name));
  const adminTools = tools
    .filter((tool) => !tool.acceptableRoles.includes(ROLE_ADVISOR))
    .sort((a, b) => a.name.localeCompare(b.name));
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Tools</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "16px",
              alignSelf: "stretch",
              flexDirection: "column",
              marginBottom: "25px",
              marginTop: "38px",
            }}
          >
            {/* spacing for gaps between items. MUI's spacing scale is based on a 8px unit, so 2 would result in a 16px gap */}
            <Grid container spacing={isMobile ? 1 : 3}>
              {advisorTools.map((tool, idx) => (
                <ToolTile key={idx} tool={tool} currentUser={currentUser} />
              ))}
            </Grid>
          </Box>
          {hasOneOfRoles(currentUser, [ROLE_ADMIN, ROLE_SUPERADMIN]) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "16px",
                alignSelf: "stretch",
                flexDirection: "column",
                marginBottom: "25px",
                marginTop: "38px",
              }}
            >
              <Typography variant="h3">Admin only</Typography>
              {/* spacing for gaps between items. MUI's spacing scale is based on a 8px unit, so 2 would result in a 16px gap */}
              <Grid container spacing={isMobile ? 1 : 3}>
                {adminTools.map((tool, idx) => (
                  <ToolTile key={idx} tool={tool} currentUser={currentUser} />
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
