import {
  EventNotice,
  RecurringUnit,
  EventNotice_NoticeType,
  CalendarEvent,
} from "protogen/calendar_pb";
import { recurringUnitString } from "../../types/calendars";
import { numberToWord, pluralize } from "../../common/utils";
import { TimezoneAbbreviations } from "../common/Timezones";

const formatRecurringInterval = (
  unit: RecurringUnit,
  interval: number,
): string => {
  let unitStr = recurringUnitString(unit);
  // Pluralize the unit if interval is greater than 1
  let pluralUnit = interval > 1 ? pluralize(unitStr) : unitStr;
  // Format the interval (convert numbers to words for 1 and 2)
  let formattedInterval =
    interval === 1 ? "every" : `every ${numberToWord(interval)}`;
  return `${formattedInterval} ${pluralUnit}`;
};

export const recurringSubtitle = (event: EventNotice) => {
  let startStr: string;
  if (event.allDay) {
    startStr = `All day`;
  } else {
    const start = new Date(Number(event.startSec) * 1000);
    startStr = start.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }
  return `${startStr} (${formatRecurringInterval(
    event.recurringUnit,
    event.recurringInterval,
  )})`;
};

export const subtitle = (event: EventNotice) => {
  if (event.noticeType === EventNotice_NoticeType.NoticeType_UPCOMING_TASK) {
    return "Task due";
  } else if (
    event.noticeType === EventNotice_NoticeType.NoticeType_OVERDUE_TASK
  ) {
    return "Task (overdue)";
  } else if (
    event.noticeType === EventNotice_NoticeType.NoticeType_RECURRING_EVENT
  ) {
    return recurringSubtitle(event);
  } else {
    return eventSubtitle(event);
  }
};

export const eventSubtitle = (event: EventNotice | CalendarEvent) => {
  const displayTimezone = event.displayTimezone;
  const start = new Date(Number(event.startSec) * 1000);
  const end = new Date(Number(event.endSec) * 1000);

  const localOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };

  const displayOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZone: displayTimezone || undefined,
  };

  const localStartStr = start.toLocaleTimeString("en-US", localOptions);
  const localEndStr = end.toLocaleTimeString("en-US", localOptions);

  const displayStartStr = displayTimezone
    ? start.toLocaleTimeString("en-US", displayOptions)
    : "";

  let timezoneStr = "";
  if (displayTimezone) {
    timezoneStr = ` ${TimezoneAbbreviations[displayTimezone]}`;
  }

  if (event.allDay) {
    return `All day${timezoneStr}`;
  } else if (event.endSec) {
    return displayTimezone
      ? `${localStartStr} - ${localEndStr} (${displayStartStr} ${timezoneStr})`
      : `${localStartStr} - ${localEndStr}`;
  } else {
    return displayTimezone
      ? `${localStartStr} (${displayStartStr}${timezoneStr})`
      : `${localStartStr}`;
  }
};

interface AttendeeStatus {
  status: number;
  label: string;
  labelColor: string | null;
  labelBackgroundColor: string | null;
}
const ATTENDEE_STATUS: AttendeeStatus[] = [
  {
    status: 1,
    label: "Accepted",
    labelColor: "primary.main",
    labelBackgroundColor: "#ECF4EC",
  },
  {
    status: 2,
    label: "Declined",
    labelColor: "secondary.dark",
    labelBackgroundColor: "secondary.light",
  },
  {
    status: 3,
    label: "Maybe",
    labelColor: null,
    labelBackgroundColor: null,
  },
  {
    status: 4,
    label: "Pending",
    labelColor: null,
    labelBackgroundColor: null,
  },
];
export const getAttendeeStatusLabel = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.label : "Pending";
};
export const getAttendeeStatusLabelColor = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.labelColor : null;
};
export const getAttendeeStatusLabelBackgroundColor = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.labelBackgroundColor : null;
};

export const getMonthName = (date: Date) => {
  const monthIndex = date.getMonth();
  const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return MONTH_NAMES[monthIndex];
};

export const getMonthBounds = (date: Date): [bigint, bigint] => {
  // First moment of the month
  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0,
  );

  // Last moment of the month
  const lastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999,
  );

  // Convert to seconds since the Unix Epoch
  const firstMomentInSeconds = BigInt(
    Math.floor(firstDayOfMonth.getTime() / 1000),
  );
  const lastMomentInSeconds = BigInt(
    Math.floor(lastDayOfMonth.getTime() / 1000),
  );

  return [firstMomentInSeconds, lastMomentInSeconds];
};

export const addOneMonth = (date: Date): Date => {
  let newYear = date.getFullYear();
  let newMonth = date.getMonth() + 1;
  let newDay = date.getDate();

  if (newMonth > 11) {
    newYear++;
    newMonth = 0;
  }
  let result = new Date(newYear, newMonth, newDay);

  if (result.getMonth() !== newMonth) {
    result = new Date(newYear, newMonth + 1, 0);
  }
  return result;
};

export const subtractOneMonth = (date: Date): Date => {
  let newYear = date.getFullYear();
  let newMonth = date.getMonth() - 1;
  let newDay = date.getDate();

  if (newMonth < 0) {
    newYear--;
    newMonth = 11;
  }

  let result = new Date(newYear, newMonth, newDay);
  if (result.getMonth() !== newMonth) {
    result = new Date(newYear, newMonth + 1, 0);
  }
  return result;
};
