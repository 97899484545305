import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  SelectChangeEvent,
  OutlinedInput,
  Box,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { SECRET_TYPES } from "./Constants";

export const getTypeIcon = (category: string) => {
  const categoryObj = SECRET_TYPES.find((cat) => cat.value === category);
  return categoryObj ? categoryObj.icon : null;
};

interface Props {
  selectedCategory: string | null;
  onChange: (category: string) => void;
  error?: string | null;
  disabled?: boolean;
}

export default ({ selectedCategory, onChange, error, disabled }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <InputLabel>Category</InputLabel>
      <Select
        sx={{
          ".MuiSelect-select": {
            ...(selectedCategory
              ? {
                  paddingTop: "13px",
                  paddingBottom: "13px",
                }
              : null),
          },
        }}
        disabled={disabled}
        error={!!error}
        input={<OutlinedInput label="Category" />}
        value={selectedCategory || ""}
        renderValue={(value) => {
          const obj = SECRET_TYPES.find((cat) => cat.value === value);
          return obj ? (
            <Box gap="6px" display="flex" alignItems="center">
              {obj.icon} {obj.label}
            </Box>
          ) : null;
        }}
        onChange={handleChange}
      >
        {SECRET_TYPES.map((cat) => (
          <MenuItem sx={{ margin: "0px" }} key={cat.value} value={cat.value}>
            <ListItem component="div" disablePadding>
              <ListItemIcon>{cat.icon}</ListItemIcon>
              {cat.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
