import React from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { capitalize } from "../../common/utils";

type Props = {
  topics: string[];
};

export default ({ topics }: Props) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="row" gap="8px">
      {topics.map((topic) => (
        <Box
          key={topic}
          sx={{
            display: "flex",
            minHeight: "26px",
            padding: "8px 12px",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            borderRadius: "100px",
            background: "#E9F3F3",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/community/topics/${encodeURIComponent(topic)}`);
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 600,
            })}
            variant={"bodySmall"}
          >
            {capitalize(topic)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
