import useProtoMethod from "./useProtoMethod";
import {
  CreateCommentRequest,
  CreateCommentResponse,
  CreatePostRequest,
  CreatePostResponse,
  EditCommentRequest,
  EditCommentResponse,
  EditPostRequest,
  EditPostResponse,
  EditCommentLikeRequest,
  EditCommentLikeResponse,
  FetchForumPageRequest,
  FetchForumPageResponse,
  FetchForumPostRequest,
  FetchForumPostResponse,
  EditForumPostLikeRequest,
  EditForumPostLikeResponse,
  DeletePostResponse,
  DeletePostRequest,
  DeleteCommentResponse,
  DeleteCommentRequest,
  PinForumPostResponse,
  PinForumPostRequest,
  UpdateBusinessInfoResponse,
  UpdateBusinessInfoRequest,
} from "protogen/forum_service_pb";

const useCreatePost = (callback?: (r: CreatePostResponse) => void) => {
  return useProtoMethod<CreatePostRequest, CreatePostResponse>(
    "CreatePost",
    CreatePostResponse,
    { callback },
  );
};

const useEditPost = (callback?: (r: EditPostResponse) => void) => {
  return useProtoMethod<EditPostRequest, EditPostResponse>(
    "EditPost",
    EditPostResponse,
    { callback },
  );
};

const useEditPostLike = (callback?: (r: EditForumPostLikeResponse) => void) => {
  return useProtoMethod<EditForumPostLikeRequest, EditForumPostLikeResponse>(
    "EditForumPostLike",
    EditForumPostLikeResponse,
    { callback },
  );
};

const useCreateComment = (callback?: (r: CreateCommentResponse) => void) => {
  return useProtoMethod<CreateCommentRequest, CreateCommentResponse>(
    "CreateComment",
    CreateCommentResponse,
    { callback },
  );
};

const useEditComment = (callback?: (r: EditCommentResponse) => void) => {
  return useProtoMethod<EditCommentRequest, EditCommentResponse>(
    "EditComment",
    EditCommentResponse,
    { callback },
  );
};

const useEditCommentLike = (
  callback?: (r: EditCommentLikeResponse) => void,
) => {
  return useProtoMethod<EditCommentLikeRequest, EditCommentLikeResponse>(
    "EditCommentLike",
    EditCommentLikeResponse,
    { callback },
  );
};

const useFetchPost = (callback?: (r: FetchForumPostResponse) => void) => {
  return useProtoMethod<FetchForumPostRequest, FetchForumPostResponse>(
    "FetchForumPost",
    FetchForumPostResponse,
    { callback },
  );
};

const useFetchForumPage = (callback?: (r: FetchForumPageResponse) => void) => {
  return useProtoMethod<FetchForumPageRequest, FetchForumPageResponse>(
    "FetchForumPage",
    FetchForumPageResponse,
    { callback },
  );
};

const useDeletePost = (callback?: (r: DeletePostResponse) => void) => {
  return useProtoMethod<DeletePostRequest, DeletePostResponse>(
    "DeletePost",
    DeletePostResponse,
    { callback },
  );
};

const useDeleteComment = (callback?: (r: DeleteCommentResponse) => void) => {
  return useProtoMethod<DeleteCommentRequest, DeleteCommentResponse>(
    "DeleteComment",
    DeleteCommentResponse,
    { callback },
  );
};

const usePinForumPost = (callback?: (r: PinForumPostResponse) => void) => {
  return useProtoMethod<PinForumPostRequest, PinForumPostResponse>(
    "PinForumPost",
    PinForumPostResponse,
    { callback },
  );
};

const useUpdateBusinessInfo = (
  callback?: (r: UpdateBusinessInfoResponse) => void,
) => {
  return useProtoMethod<UpdateBusinessInfoRequest, UpdateBusinessInfoResponse>(
    "UpdateBusinessInfo",
    UpdateBusinessInfoResponse,
    { callback },
  );
};

export {
  useCreatePost,
  useCreateComment,
  useFetchPost,
  useFetchForumPage,
  useEditPost,
  useEditComment,
  useEditCommentLike,
  useEditPostLike,
  useDeletePost,
  useDeleteComment,
  usePinForumPost,
  useUpdateBusinessInfo,
};
