import NavItem from "./NavItem";
import { Box, CircularProgress } from "@mui/material";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";
import { ReactNode, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import useIsMobile from "components/hooks/useIsMobile";
import useLocalStorageState from "../hooks/useLocalStorageState";

export const ExpendableEntryItem = ({
  content,
  selected,
  unread = false,
  dense = false,
}: {
  content: string | ReactNode | undefined;
  selected: boolean;
  unread?: boolean;
  dense?: boolean;
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: selected ? "700" : "500",
        gap: "6px",
        color: "#3D3D3D",
        fontSize: "15px",
        padding: dense ? "5px 20px" : "10px 20px",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        marginRight: "15px",
        width: "100%",
        ...(selected
          ? {
              background: "#E2EEEC",
            }
          : {}),
      }}
    >
      {content}
      {unread && <EllipseIcon height={8} width={8} style={{}} />}
    </div>
  );
};

type Props = {
  children: ReactNode[];
  selected: boolean;
  open: boolean;
  onClick: () => void;
  icon: ReactNode;
  content: string;
  url: string;
  stateName: string;
  emptyContent?: string;
  loading?: boolean;
  showCount?: [number, number];
};

export const ExpendableEntryContainer = ({
  children,
  selected,
  open,
  onClick,
  icon,
  content,
  url,
  emptyContent,
  stateName,
  loading = false,
  showCount = [3, 5],
}: Props) => {
  const isMobile = useIsMobile();
  const SHOW_COUNT = isMobile ? showCount[0] : showCount[1];
  const [showAll, setShowAll] = useState(false);
  const [expanded, setExpanded] = useLocalStorageState(stateName, true);
  const hasMore = !showAll && children.length > SHOW_COUNT;
  const truncChildren = showAll ? children : children.slice(0, SHOW_COUNT);
  let secondaryIcon: ReactNode;
  if (loading) {
    secondaryIcon = (
      <CircularProgress
        size={16}
        sx={{
          color: "#756F6C",
          marginTop: "4px",
          cursor: "pointer",
        }}
      />
    );
  } else {
    const ExpandIcon = expanded ? ChevronUpIcon : ChevronDownIcon;
    secondaryIcon = (
      <ExpandIcon
        width={20}
        height={20}
        color="#756F6C"
        strokeWidth={2}
        style={{
          marginTop: "4px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setExpanded((o: boolean) => !o);
        }}
      />
    );
  }
  const empty = !loading && children.length === 0;
  return (
    <NavItem
      selected={selected}
      open={open}
      onClick={onClick}
      icon={icon}
      text={content}
      url={url}
      secondaryIcon={
        children.length || emptyContent ? secondaryIcon : undefined
      }
    >
      <div
        style={{
          maxHeight: expanded
            ? `${50.5 * (truncChildren.length + (empty || hasMore ? 1 : 0))}px`
            : 0, // len * XXpx
          overflow: "hidden",
          transition: "max-height 0.2s ease-in-out",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            marginLeft: "39px",
            borderLeftStyle: "solid",
            borderLeftWidth: "2px",
            borderLeftColor: "var(--Border-stroke, #D4D4D4)",
          }}
        >
          {truncChildren}
        </Box>
        {hasMore && (
          <Box sx={{ marginLeft: "39px" }}>
            <div
              onClick={() => setShowAll(true)}
              style={{
                cursor: "pointer",
                fontWeight: "500",
                gap: "6px",
                color: "#6B6E7B",
                fontSize: "15px",
                padding: "10px 0",
              }}
            >
              Show all
            </div>
          </Box>
        )}
        {empty && emptyContent && (
          <Box sx={{ marginLeft: "39px" }}>
            <div
              style={{
                fontWeight: "400",
                gap: "6px",
                fontStyle: "italic",
                color: "#6B6E7B",
                fontSize: "15px",
                padding: "10px 0",
              }}
            >
              {emptyContent}
            </div>
          </Box>
        )}
      </div>
    </NavItem>
  );
};
