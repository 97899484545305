import { CSSProperties, ReactNode } from "react";
import { Box } from "@mui/material";
export default ({
  icon,
  sx,
  isLast,
}: {
  icon: ReactNode;
  sx?: CSSProperties;
  isLast?: boolean;
}) => {
  return (
    <Box
      sx={{
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        ...sx,
      }}
    >
      <Box>{icon}</Box>
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          borderLeft: `1px solid ${theme.palette.border}`,
          borderBottom: isLast ? `1px solid ${theme.palette.border}` : "none",
          paddingRight: isLast ? "20px" : "0",
          marginRight: isLast ? "-20px" : "0",
          marginBottom: isLast ? "20px" : "0",
          borderBottomLeftRadius: "8px",
        })}
      ></Box>
    </Box>
  );
};
