import { useState } from "react";

const getBoundsZoomLevel = (
  bounds: any,
  mapDim: { height: number; width: number },
) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 13;

  const latRad = (lat: number) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  const zoom = (mapPx: number, worldPx: number, fraction: number) => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  };

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

type Address = {
  address: string;
  lat: number;
  lng: number;
};
type GeocodeResult = {
  locations: Address[];
  zoom: number;
  center: { lat: number; lng: number };
};

type GeocoderReturnType = {
  geocoding: boolean;
  results?: GeocodeResult;
  geocode: (
    addresses: {
      address: string;
      lat?: number;
      lng?: number;
      bounding?: boolean;
    }[],
  ) => Promise<GeocodeResult>;
};

export default ({}): GeocoderReturnType => {
  const [locations, setLocations] = useState<Address[]>([]);

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(3);
  const [geocoding, setGeocoding] = useState(false);
  let geocoder: google.maps.Geocoder | null = null;
  const getGeocoder = () => {
    if (!geocoder) {
      geocoder = new window.google.maps.Geocoder();
    }
    return geocoder;
  };
  const geocode = async (
    addresses: {
      address: string;
      lat?: number;
      lng?: number;
      bounding?: boolean;
    }[],
  ) => {
    const boundingAddresses = new Set(
      addresses
        .filter((address) => address.bounding !== false)
        .map((address) => address.address),
    );

    // Map of address to lat/lng
    const results = new Map<
      string,
      { address: string; lat: number; lng: number }
    >(
      addresses
        .filter((address) => address.lat && address.lng)
        .map((address) => [
          address.address,
          { lat: address.lat!, lng: address.lng!, address: address.address },
        ]),
    );

    const missingAddresses = addresses
      .filter((address) => !results.has(address.address))
      .map((address) => address.address);
    const geocodePromises = missingAddresses.map(
      (address) =>
        new Promise<Address | null>((resolve) => {
          getGeocoder().geocode({ address: address }, (results, status) => {
            if (status === "OK" && results && results[0]) {
              const { lat, lng } = results[0].geometry.location;
              resolve({ address: address, lat: lat(), lng: lng() });
            } else {
              console.warn(`Geocoding failed for address: ${address}`);
              resolve(null);
            }
          });
        }),
    );
    let geocodedAddresses;
    try {
      geocodedAddresses = await Promise.all(geocodePromises);
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error;
    } finally {
      setGeocoding(false);
    }
    let zoom = undefined;
    let center = undefined;
    geocodedAddresses.forEach((result) => {
      if (result) {
        results.set(result.address, {
          address: result.address,
          lat: result.lat,
          lng: result.lng,
        });
      }
    });
    const geoResults = Array.from(results.values());

    if (geoResults.length > 0) {
      // Calculate the bounds
      const bounds = new window.google.maps.LatLngBounds();
      geoResults
        .filter((l) => boundingAddresses.has(l.address))
        .forEach((location) => {
          bounds.extend(
            new window.google.maps.LatLng(location.lat, location.lng),
          );
        });

      // Get the center of the bounds
      const mapCenter = bounds.getCenter();

      // Fit the bounds to the map to calculate the appropriate zoom level
      const map = new window.google.maps.Map(document.createElement("div"));
      map.fitBounds(bounds);
      zoom = getBoundsZoomLevel(bounds, { height: 400, width: 400 });
      center = { lat: mapCenter.lat(), lng: mapCenter.lng() };
      setMapZoom(zoom);
      setMapCenter(center);
    }
    setLocations(geoResults);
    return {
      locations: geoResults,
      zoom: zoom || mapZoom,
      center: center || mapCenter,
    };
  };
  return {
    geocoding,
    geocode,
    results: { locations, zoom: mapZoom, center: mapCenter },
  };
};
