import { checkEmail, checkPhone } from "../../common/userUtils";
import { EphemeralMember, FormErrors } from "./types";

export const validateLocation = (location: string | undefined): FormErrors => {
  const errors: FormErrors = {};
  if (location === "" || location === "null" || !location) {
    errors["location"] = "Address required.";
  }
  return errors;
};

const validateContact = (member: EphemeralMember): FormErrors => {
  const errors: FormErrors = {};
  if (!member.primaryPhone) {
    errors["primaryPhone"] = "Required";
  }
  if (!member.primaryEmail) {
    errors["primaryEmail"] = "Required";
  }
  if (!checkPhone(member.primaryPhone || "")) {
    errors["primaryPhone"] = "Invalid phone number";
  }
  if (!checkEmail(member.primaryEmail || "")) {
    errors["primaryEmail"] = "Invalid email address";
  }
  return errors;
};

export { validateContact };
