import { LucideIcon } from "lucide-react";
import { useEffect, useRef } from "react";

interface AnimatedIconProps {
  icon: LucideIcon;
  isActive: boolean;
  setActive?: (active: boolean) => void;
  activeColor?: string;
  inactiveColor?: string;
  size?: number;
  className?: string;
  disabled?: boolean;
  "aria-label"?: string;
}

export default ({
  icon: Icon,
  isActive,
  setActive,
  activeColor = "#ef4444",
  inactiveColor = "#9ca3af",
  size = 24,
  className = "",
  disabled = false,
  "aria-label": ariaLabel,
}: AnimatedIconProps) => {
  // Feels wrong - but we need the first change AFTER the first render.
  const isFirstRender = useRef(true);
  const isFirstChange = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } else if (isFirstChange.current) {
      isFirstChange.current = false;
      return;
    }
  }, [isActive]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!disabled) {
      setActive?.(!isActive);
    }
  };
  return (
    <button
      onClick={handleClick}
      className={[
        "animated-icon",
        isActive ? "active" : "",
        isFirstChange.current ? "initial-state" : "",
        className,
      ]
        .filter((s) => !!s)
        .join(" ")}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-pressed={isActive}
      type="button"
    >
      <Icon
        size={size}
        className="icon"
        fill={isActive ? "currentColor" : "none"}
      />
      <style>
        {`
          .animated-icon {
            background: none;
            border: none;
            cursor: ${disabled ? "not-allowed" : "pointer"};
            padding: 0;
            transition: transform 0.2s ease;
            outline: none;
            opacity: ${disabled ? "0.5" : "1"};
          }

          .animated-icon:hover:not(:disabled) {
            transform: scale(1.1);
          }

          .animated-icon:active:not(:disabled) {
            transform: scale(0.95);
          }

          .icon {
            color: ${inactiveColor};
            transition: color 0.2s ease;
          }

          .active .icon {
            color: ${activeColor};
          }

          .active:not(.initial-state) .icon {
            color: ${activeColor};
            animation: iconPop 0.3s ease-in-out;
          }

          @keyframes iconPop {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </button>
  );
};
