import { Box, Stepper } from "@mui/material";
import React from "react";
import { EphemeralAdvisor, FormErrors } from "./types";
import ContactStep from "./steps/ContactStep";
import PersonalDetailsStep from "./steps/PersonalDetailsStep";
import AdvisorContactStep from "./steps/AdvisorContactStep";
import SubmissionStep from "./steps/SubmissionStep";

interface Props {
  formData: EphemeralAdvisor;
  updateFormData: (f: EphemeralAdvisor) => void;
  errors: FormErrors | null;
  onSubmit: () => void;
  loading: boolean;
  editMode?: boolean;
  actionName?: string;
  disabled?: boolean;
}

export default ({
  loading,
  errors,
  formData,
  updateFormData,
  onSubmit,
  editMode = false,
  disabled = false,
  actionName = "Create Account",
}: Props) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        <ContactStep
          formData={formData}
          updateFormData={updateFormData}
          errors={errors}
          handleNext={handleNext}
        />
        <PersonalDetailsStep
          formData={formData}
          updateFormData={updateFormData}
          errors={errors}
          handleNext={handleNext}
          handleBack={handleBack}
        />
        {!editMode && (
          <AdvisorContactStep
            activeStep={activeStep}
            formData={formData}
            updateFormData={updateFormData}
            errors={errors}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        <SubmissionStep
          formData={formData}
          errors={errors}
          updateFormData={updateFormData}
          actionName={actionName}
          handleBack={handleBack}
          onSubmit={onSubmit}
          loading={loading}
          editMode={editMode}
          disabled={disabled}
        />
      </Stepper>
    </Box>
  );
};
