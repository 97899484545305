import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ExternalLink, ChevronDown, ChevronUp } from "lucide-react";
import { Box, Button, Typography, Collapse } from "@mui/material";
import BlockGutterMap from "./BlockGutterMap";
import { BlockHandle, BlockProps } from "./utils";
import BlockContainer from "./BlockContainer";
import DateDisplay from "../../common/DateDisplay";
import { Attachment as AttachmentProto } from "protogen/common_pb";
import { useGetTextMessageEntry } from "services/advisor";
import { useUpdateBlockMetadata } from "services/tasks";
import AttachmentComp from "../../common/AttachmentComp";
import AttachmentDialog from "../../common/AttachmentDialog";
import { useGetEmailThread } from "services/email";

const formatTitleForBookmark = (blockContent: any): string => {
  const sender = blockContent.sender.firstName;
  switch (blockContent.conversationMedium) {
    case "sms":
      return `Saved text from ${sender}`;
    case "email":
      return `Saved email from ${sender}`;
    case "phone":
      return `Saved phone call from ${sender}`;
    default:
      return "Message";
  }
};

const formatTitleForCreation = (blockContent: any): string => {
  const sender = blockContent.sender.firstName;
  return `Task created from conversation with ${sender}`;
};

const formatTitle = (blockContent: any): string => {
  if (blockContent.bookmarkType === "task-creation") {
    return formatTitleForCreation(blockContent);
  } else {
    return formatTitleForBookmark(blockContent);
  }
};

const linkToConversation = (blockContent: any, familyRef: string): string => {
  switch (blockContent.conversationMedium) {
    case "sms":
      return `/families/${familyRef}?activityRef=${blockContent.messageRef}`;
    case "email":
      return `/inbox/email/${encodeURIComponent(blockContent.messageRef)}`;
    case "phone":
      return `/calls/${encodeURIComponent(blockContent.messageRef)}`;
    default:
      return "";
  }
};

export default forwardRef<BlockHandle, BlockProps>(
  ({ block, task, accountType }: BlockProps, _ref) => {
    const [parsedMetadata, setParsedMetadata] = useState<any>();
    const [openAttachment, setOpenAttachment] =
      useState<AttachmentProto | null>(null);
    const [attachments, setAttachments] = useState<AttachmentProto[]>([]);
    const navigate = useNavigate();
    const blockData = JSON.parse(block.content);

    const { request: fetchMessageEntry } = useGetTextMessageEntry();
    const { request: fetchEmailEntry } = useGetEmailThread();

    const { request: updateMetadataRequest } = useUpdateBlockMetadata((r) => {
      if (!r.block?.metadata) return;
      setParsedMetadata(JSON.parse(r.block?.metadata));
    });

    const handleToggleCollapsed = () => {
      updateMetadataRequest({
        taskBlockRef: block.ref,
        key: "isCollapsed",
        value: (!parsedMetadata.isCollapsed).toString(),
      });
    };

    useEffect(() => {
      if (block.metadata) {
        setParsedMetadata(JSON.parse(block.metadata));
      }
    }, [block]);

    useEffect(() => {
      const getSourceData = async () => {
        const blockData = JSON.parse(block.content);
        if (blockData.conversationMedium === "sms") {
          const resp = await fetchMessageEntry({
            messageRef: blockData.messageRef,
          });
          if (resp) {
            setAttachments(resp.entry?.textMessage?.attachments || []);
          }
        } else if (blockData.conversationMedium === "email") {
          const resp = await fetchEmailEntry({
            emailRef: blockData.messageRef,
            markAsRead: false,
          });
          if (resp) {
            setAttachments(
              resp.emails.find((e) => e.ref === blockData.messageRef)
                ?.attachments || [],
            );
          }
        }
      };
      if (accountType === "advisor") {
        getSourceData();
      }
    }, [block]);
    const imageAttachments = attachments.filter((a) =>
      a.contentType.startsWith("image"),
    );
    const fileAttachments = attachments.filter(
      (a) => !a.contentType.startsWith("image"),
    );
    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.message.icon({ user: task.createdBy })}
        isLast={false}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignSelf: "stretch",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                alignSelf: "stretch",
                flexDirection: "column",
              }}
            >
              <Typography variant="bodyHeavy">
                {formatTitle(blockData)}
              </Typography>
              <DateDisplay date={new Date(blockData.timestamp * 1000)} />
            </Box>
            <Box onClick={handleToggleCollapsed} sx={{ cursor: "pointer" }}>
              {parsedMetadata?.isCollapsed ? (
                <ChevronDown size={24} stroke="#8E9598" />
              ) : (
                <ChevronUp size={24} stroke="#8E9598" />
              )}
            </Box>
          </Box>
          <Collapse in={!parsedMetadata?.isCollapsed} sx={{ width: "100%" }}>
            {blockData.message && (
              <Typography variant="body" sx={{ fontStyle: "italic" }}>
                "{blockData.message}"
              </Typography>
            )}
            {imageAttachments.length > 0 && (
              <Box
                sx={{
                  gap: "8px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "8px",
                  flexWrap: "wrap",
                }}
              >
                {imageAttachments.map((attachment, i) => (
                  <Box
                    key={i}
                    sx={{
                      flex: "1 0 calc(33.333% - 8px)",
                      maxWidth: "calc(33.333% - 8px)",
                    }}
                  >
                    <AttachmentComp
                      attachment={attachment}
                      openAttachment={() => setOpenAttachment(attachment)}
                    />
                  </Box>
                ))}
              </Box>
            )}
            {fileAttachments.length > 0 && (
              <Box
                sx={{
                  gap: "8px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                  flexWrap: "wrap",
                }}
              >
                {fileAttachments.map((attachment, i) => (
                  <AttachmentComp
                    key={i}
                    attachment={attachment}
                    openAttachment={() => setOpenAttachment(attachment)}
                  />
                ))}
              </Box>
            )}
            {accountType === "advisor" && (
              <Button
                variant={"text"}
                sx={{ height: "unset" }}
                onClick={() =>
                  navigate(linkToConversation(blockData, task.familyRef))
                }
                endIcon={<ExternalLink />}
              >
                See conversation
              </Button>
            )}
          </Collapse>
        </Box>
        <AttachmentDialog
          attachment={openAttachment}
          onClose={() => setOpenAttachment(null)}
        />
      </BlockContainer>
    );
  },
);
