// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/phone_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { PhoneCall } from "./conversation_pb.ts";
import { AccountStub } from "./common_pb.ts";
import { Note } from "./notes_pb.ts";
import { Suggestions } from "./suggestions_pb.ts";

/**
 * @generated from enum protogen.Device
 */
export enum Device {
  /**
   * @generated from enum value: DEVICE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEVICE_CLIENT = 1;
   */
  CLIENT = 1,

  /**
   *  DEVICE_SID = 1;
   *
   * @generated from enum value: DEVICE_PHONE = 2;
   */
  PHONE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Device)
proto3.util.setEnumType(Device, "protogen.Device", [
  { no: 0, name: "DEVICE_UNSPECIFIED" },
  { no: 1, name: "DEVICE_CLIENT" },
  { no: 2, name: "DEVICE_PHONE" },
]);

/**
 * @generated from message protogen.GetTwilioTokenRequest
 */
export class GetTwilioTokenRequest extends Message<GetTwilioTokenRequest> {
  constructor(data?: PartialMessage<GetTwilioTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTwilioTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTwilioTokenRequest {
    return new GetTwilioTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTwilioTokenRequest {
    return new GetTwilioTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTwilioTokenRequest {
    return new GetTwilioTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTwilioTokenRequest | PlainMessage<GetTwilioTokenRequest> | undefined, b: GetTwilioTokenRequest | PlainMessage<GetTwilioTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetTwilioTokenRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetTwilioTokenResponse
 */
export class GetTwilioTokenResponse extends Message<GetTwilioTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetTwilioTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTwilioTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTwilioTokenResponse {
    return new GetTwilioTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTwilioTokenResponse {
    return new GetTwilioTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTwilioTokenResponse {
    return new GetTwilioTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTwilioTokenResponse | PlainMessage<GetTwilioTokenResponse> | undefined, b: GetTwilioTokenResponse | PlainMessage<GetTwilioTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetTwilioTokenResponse, a, b);
  }
}

/**
 * @generated from message protogen.InitiateCallRequest
 */
export class InitiateCallRequest extends Message<InitiateCallRequest> {
  /**
   * @generated from field: repeated string toMemberRefs = 1;
   */
  toMemberRefs: string[] = [];

  /**
   * @generated from field: protogen.Device advisorDevice = 2;
   */
  advisorDevice = Device.UNSPECIFIED;

  /**
   * @generated from field: bool transcriptionEnabled = 3;
   */
  transcriptionEnabled = false;

  constructor(data?: PartialMessage<InitiateCallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateCallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "toMemberRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "advisorDevice", kind: "enum", T: proto3.getEnumType(Device) },
    { no: 3, name: "transcriptionEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateCallRequest {
    return new InitiateCallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateCallRequest {
    return new InitiateCallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateCallRequest {
    return new InitiateCallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateCallRequest | PlainMessage<InitiateCallRequest> | undefined, b: InitiateCallRequest | PlainMessage<InitiateCallRequest> | undefined): boolean {
    return proto3.util.equals(InitiateCallRequest, a, b);
  }
}

/**
 * @generated from message protogen.InitiateCallResponse
 */
export class InitiateCallResponse extends Message<InitiateCallResponse> {
  /**
   * @generated from field: protogen.PhoneCall phoneCall = 1;
   */
  phoneCall?: PhoneCall;

  constructor(data?: PartialMessage<InitiateCallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateCallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phoneCall", kind: "message", T: PhoneCall },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateCallResponse {
    return new InitiateCallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateCallResponse {
    return new InitiateCallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateCallResponse {
    return new InitiateCallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateCallResponse | PlainMessage<InitiateCallResponse> | undefined, b: InitiateCallResponse | PlainMessage<InitiateCallResponse> | undefined): boolean {
    return proto3.util.equals(InitiateCallResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetPhoneCallRequest
 */
export class GetPhoneCallRequest extends Message<GetPhoneCallRequest> {
  /**
   * @generated from field: string callRef = 1;
   */
  callRef = "";

  constructor(data?: PartialMessage<GetPhoneCallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetPhoneCallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneCallRequest {
    return new GetPhoneCallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneCallRequest {
    return new GetPhoneCallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneCallRequest {
    return new GetPhoneCallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneCallRequest | PlainMessage<GetPhoneCallRequest> | undefined, b: GetPhoneCallRequest | PlainMessage<GetPhoneCallRequest> | undefined): boolean {
    return proto3.util.equals(GetPhoneCallRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetPhoneCallResponse
 */
export class GetPhoneCallResponse extends Message<GetPhoneCallResponse> {
  /**
   * @generated from field: protogen.PhoneCall phoneCall = 1;
   */
  phoneCall?: PhoneCall;

  /**
   * @generated from field: protogen.AccountStub caller = 2;
   */
  caller?: AccountStub;

  /**
   * @generated from field: repeated protogen.AccountStub recipients = 3;
   */
  recipients: AccountStub[] = [];

  /**
   * @generated from field: protogen.Note callNotes = 4;
   */
  callNotes?: Note;

  /**
   * @generated from field: string familyRef = 5;
   */
  familyRef = "";

  /**
   * @generated from field: protogen.Suggestions suggestions = 6;
   */
  suggestions?: Suggestions;

  constructor(data?: PartialMessage<GetPhoneCallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetPhoneCallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phoneCall", kind: "message", T: PhoneCall },
    { no: 2, name: "caller", kind: "message", T: AccountStub },
    { no: 3, name: "recipients", kind: "message", T: AccountStub, repeated: true },
    { no: 4, name: "callNotes", kind: "message", T: Note },
    { no: 5, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "suggestions", kind: "message", T: Suggestions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneCallResponse {
    return new GetPhoneCallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneCallResponse {
    return new GetPhoneCallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneCallResponse {
    return new GetPhoneCallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneCallResponse | PlainMessage<GetPhoneCallResponse> | undefined, b: GetPhoneCallResponse | PlainMessage<GetPhoneCallResponse> | undefined): boolean {
    return proto3.util.equals(GetPhoneCallResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeletePhoneCallRequest
 */
export class DeletePhoneCallRequest extends Message<DeletePhoneCallRequest> {
  /**
   * @generated from field: string callRef = 1;
   */
  callRef = "";

  constructor(data?: PartialMessage<DeletePhoneCallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeletePhoneCallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePhoneCallRequest {
    return new DeletePhoneCallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePhoneCallRequest {
    return new DeletePhoneCallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePhoneCallRequest {
    return new DeletePhoneCallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePhoneCallRequest | PlainMessage<DeletePhoneCallRequest> | undefined, b: DeletePhoneCallRequest | PlainMessage<DeletePhoneCallRequest> | undefined): boolean {
    return proto3.util.equals(DeletePhoneCallRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeletePhoneCallResponse
 */
export class DeletePhoneCallResponse extends Message<DeletePhoneCallResponse> {
  constructor(data?: PartialMessage<DeletePhoneCallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeletePhoneCallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePhoneCallResponse {
    return new DeletePhoneCallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePhoneCallResponse {
    return new DeletePhoneCallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePhoneCallResponse {
    return new DeletePhoneCallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePhoneCallResponse | PlainMessage<DeletePhoneCallResponse> | undefined, b: DeletePhoneCallResponse | PlainMessage<DeletePhoneCallResponse> | undefined): boolean {
    return proto3.util.equals(DeletePhoneCallResponse, a, b);
  }
}

/**
 * @generated from message protogen.TransferCallDeviceRequest
 */
export class TransferCallDeviceRequest extends Message<TransferCallDeviceRequest> {
  /**
   * @generated from field: string callId = 1;
   */
  callId = "";

  /**
   * @generated from field: protogen.Device toDevice = 2;
   */
  toDevice = Device.UNSPECIFIED;

  constructor(data?: PartialMessage<TransferCallDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TransferCallDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "toDevice", kind: "enum", T: proto3.getEnumType(Device) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransferCallDeviceRequest {
    return new TransferCallDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransferCallDeviceRequest {
    return new TransferCallDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransferCallDeviceRequest {
    return new TransferCallDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TransferCallDeviceRequest | PlainMessage<TransferCallDeviceRequest> | undefined, b: TransferCallDeviceRequest | PlainMessage<TransferCallDeviceRequest> | undefined): boolean {
    return proto3.util.equals(TransferCallDeviceRequest, a, b);
  }
}

/**
 * @generated from message protogen.TransferCallDeviceResponse
 */
export class TransferCallDeviceResponse extends Message<TransferCallDeviceResponse> {
  constructor(data?: PartialMessage<TransferCallDeviceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TransferCallDeviceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransferCallDeviceResponse {
    return new TransferCallDeviceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransferCallDeviceResponse {
    return new TransferCallDeviceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransferCallDeviceResponse {
    return new TransferCallDeviceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TransferCallDeviceResponse | PlainMessage<TransferCallDeviceResponse> | undefined, b: TransferCallDeviceResponse | PlainMessage<TransferCallDeviceResponse> | undefined): boolean {
    return proto3.util.equals(TransferCallDeviceResponse, a, b);
  }
}

/**
 * @generated from message protogen.TranscriptionSegment
 */
export class TranscriptionSegment extends Message<TranscriptionSegment> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: float startSec = 3;
   */
  startSec = 0;

  /**
   * @generated from field: float endSec = 4;
   */
  endSec = 0;

  /**
   * @generated from field: bool stable = 5;
   */
  stable = false;

  /**
   * @generated from field: float temperature = 6;
   */
  temperature = 0;

  /**
   * @generated from field: repeated int32 tokens = 7;
   */
  tokens: number[] = [];

  /**
   * @generated from field: string track = 8;
   */
  track = "";

  constructor(data?: PartialMessage<TranscriptionSegment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TranscriptionSegment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "endSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "stable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "temperature", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "tokens", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "track", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscriptionSegment {
    return new TranscriptionSegment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscriptionSegment {
    return new TranscriptionSegment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscriptionSegment {
    return new TranscriptionSegment().fromJsonString(jsonString, options);
  }

  static equals(a: TranscriptionSegment | PlainMessage<TranscriptionSegment> | undefined, b: TranscriptionSegment | PlainMessage<TranscriptionSegment> | undefined): boolean {
    return proto3.util.equals(TranscriptionSegment, a, b);
  }
}

/**
 * @generated from message protogen.LiveTranscriptionsRequest
 */
export class LiveTranscriptionsRequest extends Message<LiveTranscriptionsRequest> {
  /**
   * @generated from field: string callRef = 1;
   */
  callRef = "";

  /**
   * @generated from field: string track = 2;
   */
  track = "";

  /**
   * @generated from field: int32 maxTimestampProcessed = 3;
   */
  maxTimestampProcessed = 0;

  constructor(data?: PartialMessage<LiveTranscriptionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LiveTranscriptionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "track", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "maxTimestampProcessed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LiveTranscriptionsRequest {
    return new LiveTranscriptionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LiveTranscriptionsRequest {
    return new LiveTranscriptionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LiveTranscriptionsRequest {
    return new LiveTranscriptionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LiveTranscriptionsRequest | PlainMessage<LiveTranscriptionsRequest> | undefined, b: LiveTranscriptionsRequest | PlainMessage<LiveTranscriptionsRequest> | undefined): boolean {
    return proto3.util.equals(LiveTranscriptionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.LiveTranscriptionsResponse
 */
export class LiveTranscriptionsResponse extends Message<LiveTranscriptionsResponse> {
  /**
   * @generated from field: repeated protogen.TranscriptionSegment segments = 1;
   */
  segments: TranscriptionSegment[] = [];

  /**
   * @generated from field: int32 cursorMs = 2;
   */
  cursorMs = 0;

  /**
   * @generated from field: int32 maxTimestampProcessed = 3;
   */
  maxTimestampProcessed = 0;

  /**
   * @generated from field: bool complete = 4;
   */
  complete = false;

  constructor(data?: PartialMessage<LiveTranscriptionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LiveTranscriptionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segments", kind: "message", T: TranscriptionSegment, repeated: true },
    { no: 2, name: "cursorMs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "maxTimestampProcessed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LiveTranscriptionsResponse {
    return new LiveTranscriptionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LiveTranscriptionsResponse {
    return new LiveTranscriptionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LiveTranscriptionsResponse {
    return new LiveTranscriptionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LiveTranscriptionsResponse | PlainMessage<LiveTranscriptionsResponse> | undefined, b: LiveTranscriptionsResponse | PlainMessage<LiveTranscriptionsResponse> | undefined): boolean {
    return proto3.util.equals(LiveTranscriptionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.StartTranscriptionRequest
 */
export class StartTranscriptionRequest extends Message<StartTranscriptionRequest> {
  /**
   * @generated from field: string callRef = 1;
   */
  callRef = "";

  constructor(data?: PartialMessage<StartTranscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartTranscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartTranscriptionRequest {
    return new StartTranscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartTranscriptionRequest {
    return new StartTranscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartTranscriptionRequest {
    return new StartTranscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartTranscriptionRequest | PlainMessage<StartTranscriptionRequest> | undefined, b: StartTranscriptionRequest | PlainMessage<StartTranscriptionRequest> | undefined): boolean {
    return proto3.util.equals(StartTranscriptionRequest, a, b);
  }
}

/**
 * @generated from message protogen.StartTranscriptionResponse
 */
export class StartTranscriptionResponse extends Message<StartTranscriptionResponse> {
  constructor(data?: PartialMessage<StartTranscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartTranscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartTranscriptionResponse {
    return new StartTranscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartTranscriptionResponse {
    return new StartTranscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartTranscriptionResponse {
    return new StartTranscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartTranscriptionResponse | PlainMessage<StartTranscriptionResponse> | undefined, b: StartTranscriptionResponse | PlainMessage<StartTranscriptionResponse> | undefined): boolean {
    return proto3.util.equals(StartTranscriptionResponse, a, b);
  }
}

