import { RecurringUnit } from "protogen/calendar_pb";
import {
  CreateCalendarEventRequest,
  UpdateCalendarEventRequest,
} from "protogen/calendar_service_pb";
import { RichContent } from "components/editor/utils";
import { CalendarEvent, EventReminder } from "protogen/calendar_pb";
import { attachmentsToUploads } from "components/creation/FileUploader";
import { Attachment } from "protogen/common_pb";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

const reminderOptions: string[] = [
  "None",
  "At time of event",
  "5 minutes before",
  // "10 minutes before",
  // "15 minutes before",
  // "30 minutes before",
  "1 hour before",
  // "2 hours before",
  "1 day before",
  // "2 days before",
  "1 week before",
];

type EphemeralEvent = {
  title?: string;
  description?: RichContent;
  allDay?: boolean;
  startDate?: Date;
  endDate?: Date;
  invitees?: string[];
  recurringInterval?: number;
  recurringUnit?: RecurringUnit;
  recurringUntil?: Date;
  // Only matters if family selector is present.
  familyRef?: string;
  // Only needed for form control
  isRecurring?: boolean;
  initialAttachments?: Attachment[];
  initialDetailsText?: string;
  // Used for associating a task with where it was made.
  sourceEntityType?: string;
  sourceEntityRef?: string;
  sourceEntityIndex?: number;
  reminders?: EventReminder[];
  displayTimezone?: string;
};

const DEFAULT_EVENT_DURATION_MS = 60 * 60 * 1000; // 1 hour

const createDefaultEphemeralEvent = (): EphemeralEvent => ({
  title: "",
  description: {
    text: "",
    json: "",
    attachments: [],
    html: "",
  },
  allDay: false,
  startDate: getNextHourDate(),
  endDate: new Date(getNextHourDate().getTime() + DEFAULT_EVENT_DURATION_MS),
  invitees: [],
  recurringInterval: 0,
  recurringUnit: RecurringUnit.RecurringUnit_UNSPECIFIED,
  recurringUntil: new Date(),
  familyRef: "",
  isRecurring: false,
  initialAttachments: [],
  reminders: undefined,
  displayTimezone: "",
});

const getNextHourDate = (): Date => {
  const now = new Date();
  if (now.getMinutes() > 0) {
    now.setHours(now.getHours() + 1);
  }
  now.setMinutes(0, 0, 0);
  return now;
};

type FormErrors = { [key: string]: string };

const validateEvent = (event: EphemeralEvent): FormErrors => {
  const errors: FormErrors = {};
  // if (!event.familyRef) {
  //   errors["familyRef"] = "Required";
  // }
  if (!event.title) {
    errors["title"] = "Required";
  }
  if (!event.startDate) {
    errors["startDate"] = "Required";
  }

  if (event.startDate && event.endDate && event.startDate > event.endDate) {
    errors["startDate"] = "Start date must be before end date";
    errors["endDate"] = "End date must be after start date";
  }

  if (event.isRecurring) {
    if (!event.recurringInterval) {
      errors["recurringInterval"] = "Required";
    }
    if (!event.recurringUnit) {
      errors["recurringUnit"] = "Required";
    }
  }

  if (event.allDay && event.displayTimezone) {
    errors["displayTimezone"] = "All day events cannot specify a timezone";
  }

  if (
    event.reminders &&
    !event.reminders.every((reminder) =>
      reminderOptions.includes(reminder.title),
    )
  ) {
    errors["reminder"] = "Invalid reminder option";
  }

  return errors;
};

const formattedDate = (date: Date) =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const toDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
};

const buildRequest = (event: EphemeralEvent): CreateCalendarEventRequest => {
  let startDate = event.startDate;
  let endDate = event.endDate;
  let startDisplayDate = "";
  let endDisplayDate = "";
  if (event.allDay) {
    startDisplayDate = formattedDate(startDate!);
    startDate!.setUTCHours(0, 0, 0, 0);
    if (endDate) {
      endDisplayDate = formattedDate(endDate!);
      endDate.setUTCHours(23, 59, 59, 999);
    }
  }

  if (event.displayTimezone) {
    if (startDate) {
      startDate = zonedTimeToUtc(startDate, event.displayTimezone);
    }
    if (endDate) {
      endDate = zonedTimeToUtc(endDate, event.displayTimezone);
    }
  }
  return new CreateCalendarEventRequest({
    title: event.title || "",
    description: {
      payload: event?.description?.json || "",
      contentType: "json",
      textContent: event?.description?.text || "",
      attachments: event?.description?.attachments || [],
    },
    allDay: !!event.allDay,
    startSecUTC: BigInt(Math.ceil((startDate?.getTime() || 0) / 1000)),
    endSecUTC: BigInt(Math.ceil((endDate?.getTime() || 0) / 1000)),
    startDisplayDate: startDisplayDate,
    endDisplayDate: endDisplayDate,
    recurringInterval: event.isRecurring ? event.recurringInterval || 0 : 0,
    recurringUnit: event.isRecurring
      ? event.recurringUnit
      : RecurringUnit.RecurringUnit_UNSPECIFIED,
    // Feature not being used
    recurringUntilSecUTC: undefined,
    invitees: event.invitees || [],
    familyRef: event.familyRef || "",
    sourceEntityType: event.sourceEntityType || "",
    sourceEntityRef: event.sourceEntityRef || "",
    sourceEntityIndex: event.sourceEntityIndex || 0,
    reminders: event.reminders,
    displayTimezone: event.displayTimezone || "",
  });
};

const buildUpdateRequest = (
  eventRef: string,
  event: CalendarEvent,
  formData: EphemeralEvent,
): UpdateCalendarEventRequest => {
  const startDate = formData.startDate;
  const endDate = formData.endDate;
  let startDisplayDate = "";
  let endDisplayDate = "";
  if (formData.allDay) {
    startDisplayDate = formattedDate(startDate!);
    startDate!.setUTCHours(0, 0, 0, 0);
    if (endDate) {
      endDisplayDate = formattedDate(endDate!);
      endDate.setUTCHours(23, 59, 59, 999);
    }
  }

  const hasRepeatChanged =
    event.recurringInterval !== formData.recurringInterval ||
    event.recurringUnit !== formData.recurringUnit;

  const startDateUTC = formData.displayTimezone
    ? zonedTimeToUtc(formData.startDate!, event.displayTimezone)
    : formData.startDate;

  const endDateUTC = formData.displayTimezone
    ? zonedTimeToUtc(formData.endDate!, event.displayTimezone)
    : formData.endDate;
  return new UpdateCalendarEventRequest({
    eventRef: eventRef,
    updatedTitle: event.title === formData.title ? "" : formData.title,
    updatedDescription: {
      payload:
        event?.details?.payload === formData.description?.json
          ? ""
          : formData.description?.json || "",
      contentType: "json",
      textContent:
        event?.details?.textContent === formData.description?.text
          ? ""
          : formData.description?.text || "",
      attachments:
        event?.details?.attachments == formData.description?.attachments
          ? undefined
          : formData.description?.attachments,
    },
    updatedAllDay:
      event.allDay === formData.allDay ? undefined : formData.allDay,
    updatedStartSecUTC:
      Number(event.startSec) === (startDateUTC?.getTime() || 0) / 1000
        ? undefined
        : BigInt(Math.ceil((startDateUTC?.getTime() || 0) / 1000)),
    updatedEndSecUTC:
      Number(event.endSec) === (endDateUTC?.getTime() || 0) / 1000
        ? undefined
        : BigInt(Math.ceil((endDateUTC?.getTime() || 0) / 1000)),
    updatedStartDisplayDate: startDisplayDate,
    updatedEndDisplayDate: endDisplayDate,
    updatedRecurringInterval: !hasRepeatChanged
      ? undefined
      : formData.recurringInterval,
    updatedRecurringUnit: !hasRepeatChanged
      ? undefined
      : formData.recurringUnit || RecurringUnit.RecurringUnit_UNSPECIFIED,
    // Feature not being used
    updatedRecurringUntilSecUTC: undefined,
    updatedInvitees:
      event.attendees
        .map((a) => {
          return a.email;
        })
        .sort() === formData.invitees?.sort()
        ? undefined
        : formData.invitees,
    updatedReminders: formData.reminders,
    updatedDisplayTimezone:
      event.displayTimezone === formData.displayTimezone
        ? ""
        : formData.displayTimezone,
    shouldUpdateDisplayTimezone:
      event.displayTimezone !== formData.displayTimezone,
  });
};

const buildEphemeralEventFromCalendarEvent = (
  ce: CalendarEvent,
): EphemeralEvent => {
  const isAllDay = ce.allDay;
  const isRecurring = ce.recurringInterval > 0;
  return {
    title: ce.title,
    description: {
      text: ce?.details?.textContent || "",
      json: ce?.details?.payload || "",
      attachments: attachmentsToUploads(ce?.details?.attachments || []),
      html: "",
    },
    allDay: isAllDay,
    startDate: isAllDay
      ? toDate(ce.startDisplayDate)
      : ce.displayTimezone
        ? utcToZonedTime(
            new Date(Number(ce.startSec) * 1000),
            ce.displayTimezone,
          )
        : new Date(Number(ce.startSec) * 1000),
    endDate: isAllDay
      ? toDate(ce.endDisplayDate)
      : ce.displayTimezone
        ? utcToZonedTime(new Date(Number(ce.endSec) * 1000), ce.displayTimezone)
        : new Date(Number(ce.endSec) * 1000),
    invitees: ce.attendees.map((attendee) => attendee.email),
    recurringInterval: ce.recurringInterval,
    recurringUnit: ce.recurringUnit,
    recurringUntil: isRecurring
      ? new Date(Number(ce.recurringUntilSec) * 1000)
      : undefined,
    familyRef: ce.familyRef,
    isRecurring: isRecurring,
    initialAttachments: ce.details?.attachments || [],
    reminders: ce.reminders,
    displayTimezone: ce.displayTimezone,
  };
};

const recurringUnitString = (unit: RecurringUnit) => {
  switch (unit) {
    // case RecurringUnit.RecurringUnit_DAY:
    case RecurringUnit.RecurringUnit_WEEK:
      return "week";
    case RecurringUnit.RecurringUnit_MONTH:
      return "month";
    case RecurringUnit.RecurringUnit_YEAR:
      return "year";
    default:
      return "";
  }
};

type CalendarParameters = {
  allFamiliesSelected: boolean;
  familyRefs: string[] | null;
  ignoreTasks: boolean;
  ignoreEvents: boolean;
  includeOverdueTasks: boolean;
  startSec: bigint;
  endSec: bigint | null;
};

const todaySec = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return BigInt(Math.ceil((today?.getTime() || 0) / 1000));
};

const nowSec = () => {
  const today = new Date();
  return BigInt(Math.ceil((today?.getTime() || 0) / 1000));
};

const defaultParameters = (): CalendarParameters => {
  return {
    allFamiliesSelected: true,
    familyRefs: null,
    startSec: todaySec(),
    endSec: null,
    ignoreTasks: false,
    ignoreEvents: false,
    includeOverdueTasks: true,
  };
};

export type { EphemeralEvent, FormErrors, CalendarParameters };

export {
  validateEvent,
  buildRequest,
  recurringUnitString,
  defaultParameters,
  todaySec,
  buildEphemeralEventFromCalendarEvent,
  buildUpdateRequest,
  createDefaultEphemeralEvent,
  nowSec,
  reminderOptions,
  DEFAULT_EVENT_DURATION_MS,
};
