import ReactiveDialog from "../common/ReactiveDialog";
import EventView from "./EventView";
import useIsMobile from "../hooks/useIsMobile";

interface Props {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  onDelete?: () => void;
  primaryAction: (eventRef: string) => void;
  eventRef: string;
}

export default ({
  open,
  onClose,
  eventRef,
  primaryAction,
  onDelete,
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      noActionsFullScreen={true}
      style={{
        width: "776px",
        maxWidth: "776px",
        padding: !isMobile ? "20px 18px" : undefined,
      }}
    >
      <EventView
        eventRef={eventRef}
        onClose={onClose}
        primaryAction={primaryAction}
        onDelete={onDelete}
      />
    </ReactiveDialog>
  );
};
