import { Box, Button } from "@mui/material";

import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DocumentEditor, {
  Handle as DocumentHandle,
} from "../editor/DocumentEditor";
import { useEditNote } from "services/notes";
import { Note } from "protogen/notes_pb";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import useAutoSave from "../editor/useAutoSave";
import { InsertableSuggestion } from "../editor/extensions/utils";

const _toMaybeNumber = (n: BigInt | number | null) => {
  if (!n) return null;
  if (typeof n === "number") return n;
  return Number(n);
};

interface Props {
  title?: string; // Only needed for tabbed panels where this is used to infer tab name.g
  note: Note;
  initialContent?: string | null;
  action?: React.ReactNode;
  style?: CSSProperties;
  summarizer?: () => void;
  onUpdate?: (n: Note) => void;
}
type Handle = {
  insertSuggestion: (s: InsertableSuggestion) => void;
};
export default forwardRef<Handle, Props>(
  (
    { initialContent = null, action, style, note, summarizer, onUpdate }: Props,
    ref,
  ) => {
    const _initialContent = note.content?.payload
      ? JSON.parse(note.content?.payload)
      : initialContent;
    const [hover, setHover] = useState(false);
    const documentEditorRef = useRef<DocumentHandle | null>(null);

    const { request, error } = useEditNote((r) => {
      onUpdate && onUpdate(r.note);
      if (documentEditorRef.current) {
        documentEditorRef.current.updateAttachments(
          r.note?.content?.attachments || [],
        );
      }
    });

    const { setEditorContent, savingIndicator } = useAutoSave(
      note.content?.payload || null,
      _toMaybeNumber(note?.lastEditSec),
      async (c) => {
        await request({
          entityType: note.entityType,
          entityRef: note.entityRef,
          prevHash: note.hash,
          content: {
            contentType: "json",
            payload: c.json,
            textContent: c.text,
            attachments: c.attachments,
          },
        });
      },
      !!error,
    );

    // Note(Kip): I can't figure out how to make this work without passing the method ref here.
    useImperativeHandle(ref, () => ({
      insertSuggestion: (s: InsertableSuggestion) =>
        documentEditorRef.current?.insertSuggestion(s),
    }));

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            gap: "20px",
            borderRadius: "12px",
            padding: "24px",
            border: "1px solid #EAECF0",
            background: "#FFF",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            width: "100%",
            ...(hover ? { backgroundColor: "#e8f4f8" } : {}),
            ...style,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
                alignSelf: "stretch",
              }}
            ></Box>
            <Box sx={{}}>{action}</Box>
          </Box>
          {(_initialContent || !summarizer) && (
            <DocumentEditor
              ref={documentEditorRef}
              setContent={setEditorContent}
              passiveEditor={true}
              placeholder={"Write a note..."}
              initialContent={_initialContent}
              primaryAction={savingIndicator}
              attachmentsEnabled={true}
              initialAttachments={note.content?.attachments}
              setDragState={(d) => setHover(d)}
            />
          )}
          {!_initialContent && summarizer && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "130px",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<SmartToyIcon />}
                  sx={{
                    maxWidth: "200px",
                    textTransform: "none",
                  }}
                  onClick={summarizer}
                >
                  Generate Summary
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);
