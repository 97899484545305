export type DMADefinition = {
  dma_code: number;
  dma_name: string;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
};

const DMA_LIST: DMADefinition[] = [
  {
    dma_code: 657,
    dma_name: "Sherman, TX - Ada, OK",
    latitude: 34.774531,
    longitude: -96.6783449,
    city: "Ada",
    state: "OK",
  },
  {
    dma_code: 510,
    dma_name: "Cleveland",
    latitude: 41.0814447,
    longitude: -81.5190053,
    city: "Akron",
    state: "OH",
  },
  {
    dma_code: 525,
    dma_name: "Albany, GA",
    latitude: 31.5785074,
    longitude: -84.155741,
    city: "Albany",
    state: "GA",
  },
  {
    dma_code: 644,
    dma_name: "Alexandria, LA",
    latitude: 31.3112936,
    longitude: -92.4451371,
    city: "Alexandria",
    state: "LA",
  },
  {
    dma_code: 583,
    dma_name: "Alpena",
    latitude: 45.0616794,
    longitude: -83.4327528,
    city: "Alpena",
    state: "MI",
  },
  {
    dma_code: 574,
    dma_name: "Johnstown - Altoona",
    latitude: 40.5186809,
    longitude: -78.3947359,
    city: "Altoona",
    state: "PA",
  },
  {
    dma_code: 634,
    dma_name: "Amarillo",
    latitude: 35.2219971,
    longitude: -101.8312969,
    city: "Amarillo",
    state: "TX",
  },
  {
    dma_code: 679,
    dma_name: "Des Moines - Ames",
    latitude: 42.02335,
    longitude: -93.625622,
    city: "Ames",
    state: "IA",
  },
  {
    dma_code: 743,
    dma_name: "Anchorage",
    latitude: 61.2180556,
    longitude: -149.9002778,
    city: "Anchorage",
    state: "AK",
  },
  {
    dma_code: 567,
    dma_name: "Greenville - Spartansburg - Asheville - Anderson",
    latitude: 34.5034394,
    longitude: -82.6501332,
    city: "Anderson",
    state: "SC",
  },
  {
    dma_code: 658,
    dma_name: "Green Bay - Appleton",
    latitude: 44.2619309,
    longitude: -88.4153847,
    city: "Appleton",
    state: "WI",
  },
  {
    dma_code: 524,
    dma_name: "Atlanta",
    latitude: 33.7489954,
    longitude: -84.3879824,
    city: "Atlanta",
    state: "GA",
  },
  {
    dma_code: 500,
    dma_name: "Portland - Auburn",
    latitude: 44.0978509,
    longitude: -70.2311655,
    city: "Auburn",
    state: "ME",
  },
  {
    dma_code: 520,
    dma_name: "Augusta",
    latitude: 33.47,
    longitude: -81.975,
    city: "Augusta",
    state: "GA",
  },
  {
    dma_code: 611,
    dma_name: "Rochester - Mason City - Austin",
    latitude: 43.6666296,
    longitude: -92.9746367,
    city: "Austin",
    state: "MN",
  },
  {
    dma_code: 635,
    dma_name: "Austin, TX",
    latitude: 30.267153,
    longitude: -97.7430608,
    city: "Austin",
    state: "TX",
  },
  {
    dma_code: 800,
    dma_name: "Bakersfield",
    latitude: 35.3732921,
    longitude: -119.0187125,
    city: "Bakersfield",
    state: "CA",
  },
  {
    dma_code: 512,
    dma_name: "Baltimore",
    latitude: 39.2903848,
    longitude: -76.6121893,
    city: "Baltimore",
    state: "MD",
  },
  {
    dma_code: 537,
    dma_name: "Bangor",
    latitude: 44.8011821,
    longitude: -68.7778138,
    city: "Bangor",
    state: "ME",
  },
  {
    dma_code: 716,
    dma_name: "Baton Rouge",
    latitude: 30.4507462,
    longitude: -91.154551,
    city: "Baton Rouge",
    state: "LA",
  },
  {
    dma_code: 563,
    dma_name: "Grand Rapids - Kalamazoo - Battle Creek",
    latitude: 42.3211522,
    longitude: -85.1797142,
    city: "Battle Creek",
    state: "MI",
  },
  {
    dma_code: 513,
    dma_name: "Flint - Saginaw - Bay City",
    latitude: 43.5944677,
    longitude: -83.8888647,
    city: "Bay City",
    state: "MI",
  },
  {
    dma_code: 821,
    dma_name: "Bend, OR",
    latitude: 44.0581728,
    longitude: -121.3153096,
    city: "Bend",
    state: "OR",
  },
  {
    dma_code: 756,
    dma_name: "Billings",
    latitude: 45.7832856,
    longitude: -108.5006904,
    city: "Billings",
    state: "MT",
  },
  {
    dma_code: 502,
    dma_name: "Binghamton",
    latitude: 42.0986867,
    longitude: -75.9179738,
    city: "Binghamton",
    state: "NY",
  },
  {
    dma_code: 630,
    dma_name: "Birmingham (Anniston and Tuscaloosa)",
    latitude: 33.5206608,
    longitude: -86.80249,
    city: "Birmingham",
    state: "AL",
  },
  {
    dma_code: 675,
    dma_name: "Peoria - Bloomington",
    latitude: 40.4842027,
    longitude: -88.9936873,
    city: "Bloomington",
    state: "IL",
  },
  {
    dma_code: 757,
    dma_name: "Boise",
    latitude: 43.613739,
    longitude: -116.237651,
    city: "Boise",
    state: "ID",
  },
  {
    dma_code: 736,
    dma_name: "Bowling Green",
    latitude: 36.9903199,
    longitude: -86.4436018,
    city: "Bowling Green",
    state: "KY",
  },
  {
    dma_code: 754,
    dma_name: "Butte - Bozeman",
    latitude: 45.68346,
    longitude: -111.050499,
    city: "Bozeman",
    state: "MT",
  },
  {
    dma_code: 625,
    dma_name: "Waco - Temple - Bryan",
    latitude: 30.6743643,
    longitude: -96.3699632,
    city: "Bryan",
    state: "TX",
  },
  {
    dma_code: 514,
    dma_name: "Buffalo",
    latitude: 42.8864468,
    longitude: -78.8783689,
    city: "Buffalo",
    state: "NY",
  },
  {
    dma_code: 540,
    dma_name: "Traverse City - Cadillac",
    latitude: 44.2519526,
    longitude: -85.4011619,
    city: "Cadillac",
    state: "MI",
  },
  {
    dma_code: 510,
    dma_name: "Cleveland",
    latitude: 40.7989473,
    longitude: -81.378447,
    city: "Canton",
    state: "OH",
  },
  {
    dma_code: 648,
    dma_name: "Champaign & Springfield - Decatur",
    latitude: 39.8403147,
    longitude: -88.9548001,
    city: "Champaign",
    state: "IL",
  },
  {
    dma_code: 519,
    dma_name: "Charleston, SC",
    latitude: 32.7765656,
    longitude: -79.9309216,
    city: "Charleston",
    state: "SC",
  },
  {
    dma_code: 517,
    dma_name: "Charlotte",
    latitude: 35.2270869,
    longitude: -80.8431267,
    city: "Charlotte",
    state: "NC",
  },
  {
    dma_code: 584,
    dma_name: "Charlottesville",
    latitude: 38.0293059,
    longitude: -78.4766781,
    city: "Charlottesville",
    state: "VA",
  },
  {
    dma_code: 575,
    dma_name: "Chattanooga",
    latitude: 35.0456297,
    longitude: -85.3096801,
    city: "Chattanooga",
    state: "TN",
  },
  {
    dma_code: 602,
    dma_name: "Chicago",
    latitude: 41.850033,
    longitude: -87.6500523,
    city: "Chicago",
    state: "IL",
  },
  {
    dma_code: 515,
    dma_name: "Cincinnati",
    latitude: 39.1361111,
    longitude: -84.5030556,
    city: "Cincinnati",
    state: "OH",
  },
  {
    dma_code: 531,
    dma_name: "Tri-Cities, TN-VA",
    latitude: 36.472723,
    longitude: -82.4106862,
    city: "Cities",
    state: "TN-VA",
  },
  {
    dma_code: 546,
    dma_name: "Columbia, SC",
    latitude: 34.0007104,
    longitude: -81.0348144,
    city: "Columbia",
    state: "SC",
  },
  {
    dma_code: 522,
    dma_name: "Columbus, GA",
    latitude: 32.4609764,
    longitude: -84.9877094,
    city: "Columbus",
    state: "GA",
  },
  {
    dma_code: 535,
    dma_name: "Columbus, OH",
    latitude: 39.9611755,
    longitude: -82.9987942,
    city: "Columbus",
    state: "OH",
  },
  {
    dma_code: 600,
    dma_name: "Corpus Christi",
    latitude: 27.8005828,
    longitude: -97.396381,
    city: "Corpus Christi",
    state: "TX",
  },
  {
    dma_code: 542,
    dma_name: "Dayton",
    latitude: 39.7589478,
    longitude: -84.1916069,
    city: "Dayton",
    state: "OH",
  },
  {
    dma_code: 691,
    dma_name: "Huntsville - Decatur (Florence)",
    latitude: 34.6059253,
    longitude: -86.9833417,
    city: "Decatur",
    state: "AL",
  },
  {
    dma_code: 648,
    dma_name: "Champaign & Springfield - Decatur",
    latitude: 39.8403147,
    longitude: -88.9548001,
    city: "Decatur;",
    state: "IL",
  },
  {
    dma_code: 751,
    dma_name: "Denver",
    latitude: 39.7391536,
    longitude: -104.9847034,
    city: "Denver",
    state: "CO",
  },
  {
    dma_code: 505,
    dma_name: "Detroit",
    latitude: 42.331427,
    longitude: -83.0457538,
    city: "Detroit",
    state: "MI",
  },
  {
    dma_code: 687,
    dma_name: "Minot - Bismarck - Dickinson",
    latitude: 46.8791756,
    longitude: -102.7896242,
    city: "Dickinson",
    state: "ND",
  },
  {
    dma_code: 606,
    dma_name: "Dothan",
    latitude: 31.2232313,
    longitude: -85.3904888,
    city: "Dothan",
    state: "AL",
  },
  {
    dma_code: 560,
    dma_name: "Raleigh - Durham (Fayetteville)",
    latitude: 35.9940329,
    longitude: -78.898619,
    city: "Durham",
    state: "NC",
  },
  {
    dma_code: 702,
    dma_name: "La Crosse - Eau Claire",
    latitude: 44.811349,
    longitude: -91.4984941,
    city: "Eau Claire",
    state: "WI",
  },
  {
    dma_code: 771,
    dma_name: "Yuma - El Centro",
    latitude: 32.792,
    longitude: -115.5630514,
    city: "El Centro",
    state: "CA",
  },
  {
    dma_code: 628,
    dma_name: "Monroe - El Dorado",
    latitude: 33.210973,
    longitude: -92.665901,
    city: "El Dorado",
    state: "AR",
  },
  {
    dma_code: 765,
    dma_name: "El Paso",
    latitude: 31.7587198,
    longitude: -106.4869314,
    city: "El Paso",
    state: "TX",
  },
  {
    dma_code: 588,
    dma_name: "South Bend - Elkhart",
    latitude: 41.6819935,
    longitude: -85.9766671,
    city: "Elkhart",
    state: "IN",
  },
  {
    dma_code: 565,
    dma_name: "Elmira",
    latitude: 42.0897965,
    longitude: -76.8077338,
    city: "Elmira",
    state: "NY",
  },
  {
    dma_code: 516,
    dma_name: "Erie",
    latitude: 42.1292241,
    longitude: -80.085059,
    city: "Erie",
    state: "PA",
  },
  {
    dma_code: 801,
    dma_name: "Eugene",
    latitude: 44.0520691,
    longitude: -123.0867536,
    city: "Eugene",
    state: "OR",
  },
  {
    dma_code: 802,
    dma_name: "Eureka",
    latitude: 40.8020712,
    longitude: -124.1636729,
    city: "Eureka",
    state: "CA",
  },
  {
    dma_code: 649,
    dma_name: "Evansville",
    latitude: 37.9747644,
    longitude: -87.5558482,
    city: "Evansville",
    state: "IN",
  },
  {
    dma_code: 745,
    dma_name: "Fairbanks",
    latitude: 64.8377778,
    longitude: -147.7163889,
    city: "Fairbanks",
    state: "AK",
  },
  {
    dma_code: 560,
    dma_name: "Raleigh - Durham (Fayetteville)",
    latitude: 35.0526641,
    longitude: -78.8783585,
    city: "Fayetteville",
    state: "NC",
  },
  {
    dma_code: 691,
    dma_name: "Huntsville - Decatur (Florence)",
    latitude: 34.79981,
    longitude: -87.677251,
    city: "Florence",
    state: "AL",
  },
  {
    dma_code: 528,
    dma_name: "Miami - Fort Lauderdale",
    latitude: 26.1223084,
    longitude: -80.1433786,
    city: "Ft. Lauderdale",
    state: "FL",
  },
  {
    dma_code: 548,
    dma_name: "West Palm Beach - Fort Pierce",
    latitude: 27.4467056,
    longitude: -80.3256056,
    city: "Ft. Pierce",
    state: "FL",
  },
  {
    dma_code: 686,
    dma_name: "Mobile - Pensacola (Fort Walton Beach)",
    latitude: 30.4057552,
    longitude: -86.618842,
    city: "Ft. Walton Beach",
    state: "FL",
  },
  {
    dma_code: 509,
    dma_name: "Fort Wayne",
    latitude: 41.1306041,
    longitude: -85.1288597,
    city: "Ft. Wayne",
    state: "IN",
  },
  {
    dma_code: 623,
    dma_name: "Dallas - Fort Worth",
    latitude: 32.725409,
    longitude: -97.3208496,
    city: "Ft. Worth",
    state: "TX",
  },
  {
    dma_code: 592,
    dma_name: "Gainesville",
    latitude: 29.6516344,
    longitude: -82.3248262,
    city: "Gainesville",
    state: "FL",
  },
  {
    dma_code: 798,
    dma_name: "Glendive",
    latitude: 47.108491,
    longitude: -104.710419,
    city: "Glendive",
    state: "MT",
  },
  {
    dma_code: 755,
    dma_name: "Great Falls",
    latitude: 47.5002354,
    longitude: -111.3008083,
    city: "Great Falls",
    state: "MT",
  },
  {
    dma_code: 647,
    dma_name: "Greenwood - Greenville",
    latitude: 33.4101161,
    longitude: -91.0617735,
    city: "Greenville",
    state: "MS",
  },
  {
    dma_code: 746,
    dma_name: "Biloxi - Gulfport",
    latitude: 30.3674198,
    longitude: -89.0928155,
    city: "Gulfport",
    state: "MS",
  },
  {
    dma_code: 511,
    dma_name: "Washington DC (Hagerstown)",
    latitude: 39.6417629,
    longitude: -77.7199932,
    city: "Hagerstown",
    state: "MD",
  },
  {
    dma_code: 569,
    dma_name: "Harrisonburg",
    latitude: 38.4495688,
    longitude: -78.8689155,
    city: "Harrisonburg",
    state: "VA",
  },
  {
    dma_code: 533,
    dma_name: "Hartford & New Haven",
    latitude: 41.7637111,
    longitude: -72.6850932,
    city: "Hartford",
    state: "CT",
  },
  {
    dma_code: 766,
    dma_name: "Helena",
    latitude: 46.595805,
    longitude: -112.027031,
    city: "Helena",
    state: "MT",
  },
  {
    dma_code: 543,
    dma_name: "Springfield - Holyoke",
    latitude: 42.2042586,
    longitude: -72.6162009,
    city: "Holyoke",
    state: "MA",
  },
  {
    dma_code: 744,
    dma_name: "Honolulu",
    latitude: 21.3069444,
    longitude: -157.8583333,
    city: "Honolulu",
    state: "HI",
  },
  {
    dma_code: 618,
    dma_name: "Houston",
    latitude: 29.7628844,
    longitude: -95.3830615,
    city: "Houston",
    state: "TX",
  },
  {
    dma_code: 564,
    dma_name: "Charleston-Huntington",
    latitude: 38.4192496,
    longitude: -82.445154,
    city: "Huntington",
    state: "WV",
  },
  {
    dma_code: 678,
    dma_name: "Wichita - Hutchinson",
    latitude: 38.0608445,
    longitude: -97.9297743,
    city: "Hutchinson",
    state: "KS",
  },
  {
    dma_code: 527,
    dma_name: "Indianapolis",
    latitude: 39.7683765,
    longitude: -86.1580423,
    city: "Indianapolis",
    state: "IN",
  },
  {
    dma_code: 637,
    dma_name: "Cedar Rapids - Waterloo & Dubuque",
    latitude: 41.677204,
    longitude: -91.5162792,
    city: "Iowa City",
    state: "IA",
  },
  {
    dma_code: 639,
    dma_name: "Jackson, TN",
    latitude: 35.6145169,
    longitude: -88.8139469,
    city: "Jackson",
    state: "TN",
  },
  {
    dma_code: 718,
    dma_name: "Jackson, MS",
    latitude: 32.2987573,
    longitude: -90.1848103,
    city: "Jackson",
    state: "MS",
  },
  {
    dma_code: 561,
    dma_name: "Jacksonville, Brunswick",
    latitude: 30.3321838,
    longitude: -81.655651,
    city: "Jacksonville",
    state: "FL",
  },
  {
    dma_code: 604,
    dma_name: "Columbia - Jefferson City",
    latitude: 38.5767017,
    longitude: -92.1735164,
    city: "Jefferson City",
    state: "MO",
  },
  {
    dma_code: 734,
    dma_name: "Jonesboro",
    latitude: 35.8422967,
    longitude: -90.704279,
    city: "Jonesboro",
    state: "AR",
  },
  {
    dma_code: 747,
    dma_name: "Juneau",
    latitude: 58.3019444,
    longitude: -134.4197222,
    city: "Juneau",
    state: "AK",
  },
  {
    dma_code: 616,
    dma_name: "Kansas City",
    latitude: 39.0997265,
    longitude: -94.5785667,
    city: "Kansas City",
    state: "MO",
  },
  {
    dma_code: 722,
    dma_name: "Lincoln & Hastings - Kearney",
    latitude: 40.699959,
    longitude: -99.083107,
    city: "Kearney",
    state: "NE",
  },
  {
    dma_code: 810,
    dma_name: "Yakima - Pasco - Richland - Kennewick",
    latitude: 46.2112458,
    longitude: -119.1372338,
    city: "Kennewick",
    state: "WA",
  },
  {
    dma_code: 717,
    dma_name: "Quincy - Hannibal - Keokuk",
    latitude: 40.402525,
    longitude: -91.394372,
    city: "Keokuk",
    state: "IA",
  },
  {
    dma_code: 631,
    dma_name: "Ottumwa - Kirksville",
    latitude: 40.1947539,
    longitude: -92.5832496,
    city: "Kirksville",
    state: "MO",
  },
  {
    dma_code: 813,
    dma_name: "Medford - Klamath Falls",
    latitude: 42.224867,
    longitude: -121.7816704,
    city: "Klamath Falls",
    state: "OR",
  },
  {
    dma_code: 557,
    dma_name: "Knoxville",
    latitude: 35.9606384,
    longitude: -83.9207392,
    city: "Knoxville",
    state: "TN",
  },
  {
    dma_code: 582,
    dma_name: "Lafayette, IN",
    latitude: 40.4167022,
    longitude: -86.8752869,
    city: "Lafayette",
    state: "IN",
  },
  {
    dma_code: 642,
    dma_name: "Lafayette, LA",
    latitude: 30.2240897,
    longitude: -92.0198427,
    city: "Lafayette",
    state: "LA",
  },
  {
    dma_code: 643,
    dma_name: "Lake Charles",
    latitude: 30.2265949,
    longitude: -93.2173758,
    city: "Lake Charles",
    state: "LA",
  },
  {
    dma_code: 551,
    dma_name: "Lansing",
    latitude: 42.732535,
    longitude: -84.5555347,
    city: "Lansing",
    state: "MI",
  },
  {
    dma_code: 749,
    dma_name: "Laredo",
    latitude: 27.506407,
    longitude: -99.5075421,
    city: "Laredo",
    state: "TX",
  },
  {
    dma_code: 839,
    dma_name: "Las Vegas",
    latitude: 36.114646,
    longitude: -115.172816,
    city: "Las Vegas",
    state: "NV",
  },
  {
    dma_code: 710,
    dma_name: "Hattiesburg - Laurel",
    latitude: 31.6940509,
    longitude: -89.1306124,
    city: "Laurel",
    state: "MS",
  },
  {
    dma_code: 627,
    dma_name: "Wichita Falls & Lawton",
    latitude: 34.6086854,
    longitude: -98.3903305,
    city: "Lawton",
    state: "OK",
  },
  {
    dma_code: 541,
    dma_name: "Lexington",
    latitude: 38.0317136,
    longitude: -84.4951359,
    city: "Lexington",
    state: "KY",
  },
  {
    dma_code: 558,
    dma_name: "Lima",
    latitude: 40.742551,
    longitude: -84.1052256,
    city: "Lima",
    state: "OH",
  },
  {
    dma_code: 709,
    dma_name: "Tyler - Longview (Lufkin & Nacogdoches)",
    latitude: 32.5007037,
    longitude: -94.7404891,
    city: "Longview",
    state: "TX",
  },
  {
    dma_code: 803,
    dma_name: "Los Angeles",
    latitude: 34.0522342,
    longitude: -118.2436849,
    city: "Los Angeles",
    state: "CA",
  },
  {
    dma_code: 529,
    dma_name: "Louisville",
    latitude: 38.2542376,
    longitude: -85.759407,
    city: "Louisville",
    state: "KY",
  },
  {
    dma_code: 651,
    dma_name: "Lubbock",
    latitude: 33.5778631,
    longitude: -101.8551665,
    city: "Lubbock",
    state: "TX",
  },
  {
    dma_code: 709,
    dma_name: "Tyler - Longview (Lufkin & Nacogdoches)",
    latitude: 31.3382406,
    longitude: -94.729097,
    city: "Lufkin",
    state: "TX",
  },
  {
    dma_code: 573,
    dma_name: "Roanoke - Lynchburg",
    latitude: 37.4137536,
    longitude: -79.1422464,
    city: "Lynchburg",
    state: "VA",
  },
  {
    dma_code: 503,
    dma_name: "Macon",
    latitude: 32.8406946,
    longitude: -83.6324022,
    city: "Macon",
    state: "GA",
  },
  {
    dma_code: 669,
    dma_name: "Madison",
    latitude: 43.0730517,
    longitude: -89.4012302,
    city: "Madison",
    state: "WI",
  },
  {
    dma_code: 506,
    dma_name: "Boston (Manchester)",
    latitude: 42.9956397,
    longitude: -71.4547891,
    city: "Manchester",
    state: "NH",
  },
  {
    dma_code: 737,
    dma_name: "Mankato",
    latitude: 44.1635775,
    longitude: -93.9993996,
    city: "Mankato",
    state: "MN",
  },
  {
    dma_code: 553,
    dma_name: "Marquette",
    latitude: 46.5435442,
    longitude: -87.395417,
    city: "Marquette",
    state: "MI",
  },
  {
    dma_code: 636,
    dma_name: "Harlingen - Weslaco - Brownsville - McAllen",
    latitude: 26.2034071,
    longitude: -98.2300124,
    city: "McAllen",
    state: "TX",
  },
  {
    dma_code: 534,
    dma_name: "Orlando - Daytona Beach - Melbourne",
    latitude: 28.0836269,
    longitude: -80.6081089,
    city: "Melbourne",
    state: "FL",
  },
  {
    dma_code: 640,
    dma_name: "Memphis",
    latitude: 35.1495343,
    longitude: -90.0489801,
    city: "Memphis",
    state: "TN",
  },
  {
    dma_code: 711,
    dma_name: "Meridian",
    latitude: 32.3643098,
    longitude: -88.703656,
    city: "Meridian",
    state: "MS",
  },
  {
    dma_code: 633,
    dma_name: "Odessa - Midland",
    latitude: 31.9973456,
    longitude: -102.0779146,
    city: "Midland",
    state: "TX",
  },
  {
    dma_code: 617,
    dma_name: "Milwaukee",
    latitude: 43.0389025,
    longitude: -87.9064736,
    city: "Milwaukee",
    state: "WI",
  },
  {
    dma_code: 762,
    dma_name: "Missoula",
    latitude: 46.872146,
    longitude: -113.9939982,
    city: "Missoula",
    state: "MT",
  },
  {
    dma_code: 725,
    dma_name: "Sioux Falls (Mitchell)",
    latitude: 43.7094283,
    longitude: -98.0297992,
    city: "Mitchell",
    state: "SD",
  },
  {
    dma_code: 862,
    dma_name: "Sacramento - Stockton - Modesto",
    latitude: 37.6390972,
    longitude: -120.9968782,
    city: "Modesto",
    state: "CA",
  },
  {
    dma_code: 682,
    dma_name: "Davenport - Rock Island - Moline",
    latitude: 41.5067003,
    longitude: -90.5151342,
    city: "Moline",
    state: "IL",
  },
  {
    dma_code: 698,
    dma_name: "Montgomery (Selma)",
    latitude: 32.3668052,
    longitude: -86.2999689,
    city: "Montgomery",
    state: "AL",
  },
  {
    dma_code: 773,
    dma_name: "Grand Junction - Montrose",
    latitude: 38.4783198,
    longitude: -107.8761738,
    city: "Montrose",
    state: "CO",
  },
  {
    dma_code: 632,
    dma_name: "Paducah - Cape Girardeau - Harrisburg - Mt Vernon",
    latitude: 38.3172714,
    longitude: -88.9031201,
    city: "Mount Vernon",
    state: "IL",
  },
  {
    dma_code: 570,
    dma_name: "Florence - Myrtle Beach",
    latitude: 33.6890603,
    longitude: -78.8866943,
    city: "Myrtle Beach",
    state: "SC",
  },
  {
    dma_code: 709,
    dma_name: "Tyler - Longview (Lufkin & Nacogdoches)",
    latitude: 31.6035129,
    longitude: -94.6554874,
    city: "Nacogdoches",
    state: "TX",
  },
  {
    dma_code: 571,
    dma_name: "Fort Myers - Naples",
    latitude: 26.1420358,
    longitude: -81.7948103,
    city: "Naples",
    state: "FL",
  },
  {
    dma_code: 659,
    dma_name: "Nashville",
    latitude: 36.1658899,
    longitude: -86.7844432,
    city: "Nashville",
    state: "TN",
  },
  {
    dma_code: 521,
    dma_name: "Providence - New Bedford",
    latitude: 41.6362152,
    longitude: -70.934205,
    city: "New Bedford",
    state: "MA",
  },
  {
    dma_code: 533,
    dma_name: "Hartford & New Haven",
    latitude: 41.3081527,
    longitude: -72.9281577,
    city: "New Haven",
    state: "CT",
  },
  {
    dma_code: 622,
    dma_name: "New Orleans",
    latitude: 29.9647222,
    longitude: -90.0705556,
    city: "New Orleans",
    state: "LA",
  },
  {
    dma_code: 501,
    dma_name: "New York",
    latitude: 40.7142691,
    longitude: -74.0059729,
    city: "New York",
    state: "NY",
  },
  {
    dma_code: 544,
    dma_name: "Norfolk - Portsmouth - Newport News",
    latitude: 36.9787588,
    longitude: -76.428003,
    city: "Newport News",
    state: "VA",
  },
  {
    dma_code: 740,
    dma_name: "North Platte",
    latitude: 41.1238873,
    longitude: -100.7654232,
    city: "North Platte",
    state: "NE",
  },
  {
    dma_code: 559,
    dma_name: "Bluefield - Beckley - Oak Hill",
    latitude: 37.9723339,
    longitude: -81.1487135,
    city: "Oak Hill",
    state: "WV",
  },
  {
    dma_code: 650,
    dma_name: "Oklahoma City",
    latitude: 35.4675602,
    longitude: -97.5164276,
    city: "Oklahoma City",
    state: "OK",
  },
  {
    dma_code: 652,
    dma_name: "Omaha",
    latitude: 41.254006,
    longitude: -95.999258,
    city: "Omaha",
    state: "NE",
  },
  {
    dma_code: 804,
    dma_name: "Palm Springs",
    latitude: 33.8302961,
    longitude: -116.5452921,
    city: "Palm Springs",
    state: "CA",
  },
  {
    dma_code: 656,
    dma_name: "Panama City",
    latitude: 30.1588129,
    longitude: -85.6602058,
    city: "Panama City",
    state: "FL",
  },
  {
    dma_code: 597,
    dma_name: "Parkersburg",
    latitude: 39.2667418,
    longitude: -81.5615135,
    city: "Parkersburg",
    state: "WV",
  },
  {
    dma_code: 686,
    dma_name: "Mobile - Pensacola (Fort Walton Beach)",
    latitude: 30.421309,
    longitude: -87.2169149,
    city: "Pensacola ",
    state: "FL",
  },
  {
    dma_code: 686,
    dma_name: "Mobile - Pensacola (Fort Walton Beach)",
    latitude: 30.421309,
    longitude: -87.2169149,
    city: "Pensacola",
    state: "FL",
  },
  {
    dma_code: 556,
    dma_name: "Richmond - Petersburg",
    latitude: 37.2279279,
    longitude: -77.4019267,
    city: "Petersburg",
    state: "VA",
  },
  {
    dma_code: 504,
    dma_name: "Philadelphia",
    latitude: 39.952335,
    longitude: -75.163789,
    city: "Philadelphia",
    state: "PA",
  },
  {
    dma_code: 753,
    dma_name: "Phoenix",
    latitude: 33.4483771,
    longitude: -112.0740373,
    city: "Phoenix",
    state: "AZ",
  },
  {
    dma_code: 693,
    dma_name: "Little Rock - Pine Bluff",
    latitude: 34.2284312,
    longitude: -92.0031955,
    city: "Pine Bluff",
    state: "AR",
  },
  {
    dma_code: 603,
    dma_name: "Joplin - Pittsburg",
    latitude: 37.410884,
    longitude: -94.70496,
    city: "Pittsburg",
    state: "KS",
  },
  {
    dma_code: 508,
    dma_name: "Pittsburgh",
    latitude: 40.4406248,
    longitude: -79.9958864,
    city: "Pittsburgh",
    state: "PA",
  },
  {
    dma_code: 523,
    dma_name: "Burlington - Plattsburgh",
    latitude: 44.6994873,
    longitude: -73.4529124,
    city: "Plattsburgh",
    state: "NY",
  },
  {
    dma_code: 758,
    dma_name: "Idaho Falls - Pocatello",
    latitude: 42.8713032,
    longitude: -112.4455344,
    city: "Pocatello",
    state: "ID",
  },
  {
    dma_code: 692,
    dma_name: "Beaumont - Port Arthur",
    latitude: 29.8849504,
    longitude: -93.939947,
    city: "Port Arthur",
    state: "TX",
  },
  {
    dma_code: 820,
    dma_name: "Portland, OR",
    latitude: 45.5234515,
    longitude: -122.6762071,
    city: "Portland",
    state: "OR",
  },
  {
    dma_code: 552,
    dma_name: "Presque Isle",
    latitude: 46.681153,
    longitude: -68.0158615,
    city: "Presque Isle",
    state: "ME",
  },
  {
    dma_code: 752,
    dma_name: "Colorado Springs - Pueblo",
    latitude: 38.2544472,
    longitude: -104.6091409,
    city: "Pueblo",
    state: "CO",
  },
  {
    dma_code: 764,
    dma_name: "Rapid City",
    latitude: 44.0805434,
    longitude: -103.2310149,
    city: "Rapid City",
    state: "SD",
  },
  {
    dma_code: 868,
    dma_name: "Chico - Redding",
    latitude: 40.5865396,
    longitude: -122.3916754,
    city: "Redding",
    state: "CA",
  },
  {
    dma_code: 811,
    dma_name: "Reno",
    latitude: 39.5296329,
    longitude: -119.8138027,
    city: "Reno",
    state: "NV",
  },
  {
    dma_code: 705,
    dma_name: "Wausau - Rhinelander",
    latitude: 45.6366228,
    longitude: -89.4120753,
    city: "Rhinelander",
    state: "WI",
  },
  {
    dma_code: 767,
    dma_name: "Casper - Riverton",
    latitude: 43.0249592,
    longitude: -108.3801036,
    city: "Riverton",
    state: "WY",
  },
  {
    dma_code: 538,
    dma_name: "Rochester, NY",
    latitude: 43.1547845,
    longitude: -77.6155567,
    city: "Rochester",
    state: "NY",
  },
  {
    dma_code: 610,
    dma_name: "Rockford",
    latitude: 42.2711311,
    longitude: -89.0939952,
    city: "Rockford",
    state: "IL",
  },
  {
    dma_code: 670,
    dma_name: "Fort Smith - Fayetteville - Springdale - Rogers",
    latitude: 36.3320196,
    longitude: -94.1185366,
    city: "Rogers",
    state: "AR",
  },
  {
    dma_code: 828,
    dma_name: "Monterey - Salinas",
    latitude: 36.6777372,
    longitude: -121.6555013,
    city: "Salinas",
    state: "CA",
  },
  {
    dma_code: 576,
    dma_name: "Salisbury",
    latitude: 38.3606736,
    longitude: -75.5993692,
    city: "Salisbury",
    state: "MD",
  },
  {
    dma_code: 770,
    dma_name: "Salt Lake City",
    latitude: 40.7607793,
    longitude: -111.8910474,
    city: "Salt Lake City",
    state: "UT",
  },
  {
    dma_code: 661,
    dma_name: "San Angelo",
    latitude: 31.4637723,
    longitude: -100.4370375,
    city: "San Angelo",
    state: "TX",
  },
  {
    dma_code: 641,
    dma_name: "San Antonio",
    latitude: 29.4241219,
    longitude: -98.4936282,
    city: "San Antonio",
    state: "TX",
  },
  {
    dma_code: 825,
    dma_name: "San Diego",
    latitude: 32.7153292,
    longitude: -117.1572551,
    city: "San Diego",
    state: "CA",
  },
  {
    dma_code: 807,
    dma_name: "San Francisco - Oakland - San Jose",
    latitude: 37.3393857,
    longitude: -121.8949555,
    city: "San Jose",
    state: "CA",
  },
  {
    dma_code: 855,
    dma_name: "Santa Barbara - Santa Maria - San Luis Obispo",
    latitude: 35.2827524,
    longitude: -120.6596156,
    city: "San Luis Obispo",
    state: "CA",
  },
  {
    dma_code: 790,
    dma_name: "Albuquerque - Santa Fe",
    latitude: 35.6869752,
    longitude: -105.937799,
    city: "Santa Fe",
    state: "NM",
  },
  {
    dma_code: 539,
    dma_name: "Tampa - Saint Petersburg (Sarasota)",
    latitude: 27.3364347,
    longitude: -82.5306527,
    city: "Sarasota",
    state: "FL",
  },
  {
    dma_code: 507,
    dma_name: "Savannah",
    latitude: 32.0835407,
    longitude: -81.0998342,
    city: "Savannah",
    state: "GA",
  },
  {
    dma_code: 759,
    dma_name: "Cheyenne - Scottsbluff",
    latitude: 41.86714,
    longitude: -103.660709,
    city: "Scottsbluff",
    state: "NE",
  },
  {
    dma_code: 577,
    dma_name: "Wilkes Barre - Scranton",
    latitude: 41.408969,
    longitude: -75.6624122,
    city: "Scranton",
    state: "PA",
  },
  {
    dma_code: 698,
    dma_name: "Montgomery (Selma)",
    latitude: 32.4073589,
    longitude: -87.0211007,
    city: "Selma",
    state: "AL",
  },
  {
    dma_code: 612,
    dma_name: "Shreveport",
    latitude: 32.5251516,
    longitude: -93.7501789,
    city: "Shreveport",
    state: "LA",
  },
  {
    dma_code: 789,
    dma_name: "Tucson (Sierra Vista)",
    latitude: 31.5455001,
    longitude: -110.2772856,
    city: "Sierra Vista",
    state: "AZ",
  },
  {
    dma_code: 624,
    dma_name: "Sioux City",
    latitude: 42.4999942,
    longitude: -96.4003069,
    city: "Sioux City",
    state: "IA",
  },
  {
    dma_code: 725,
    dma_name: "Sioux Falls (Mitchell)",
    latitude: 43.5499749,
    longitude: -96.700327,
    city: "Sioux Falls",
    state: "SD",
  },
  {
    dma_code: 881,
    dma_name: "Spokane",
    latitude: 47.6587802,
    longitude: -117.4260466,
    city: "Spokane",
    state: "WA",
  },
  {
    dma_code: 619,
    dma_name: "Springfield, MO",
    latitude: 37.215326,
    longitude: -93.2982436,
    city: "Springfield",
    state: "MO",
  },
  {
    dma_code: 648,
    dma_name: "Champaign & Springfield - Decatur",
    latitude: 39.8403147,
    longitude: -88.9548001,
    city: "Springfield",
    state: "IL",
  },
  {
    dma_code: 638,
    dma_name: "Saint Joseph",
    latitude: 39.7577778,
    longitude: -94.8363889,
    city: "St. Joseph",
    state: "MO",
  },
  {
    dma_code: 609,
    dma_name: "Saint Louis",
    latitude: 38.646991,
    longitude: -90.224967,
    city: "St. Louis",
    state: "MO",
  },
  {
    dma_code: 613,
    dma_name: "Minneapolis - Saint Paul",
    latitude: 44.944167,
    longitude: -93.086075,
    city: "St. Paul",
    state: "MN",
  },
  {
    dma_code: 539,
    dma_name: "Tampa - Saint Petersburg (Sarasota)",
    latitude: 27.782254,
    longitude: -82.667619,
    city: "St. Petersburg",
    state: "FL",
  },
  {
    dma_code: 554,
    dma_name: "Wheeling - Steubenville",
    latitude: 40.3697905,
    longitude: -80.6339638,
    city: "Steubenville",
    state: "OH",
  },
  {
    dma_code: 676,
    dma_name: "Duluth - Superior",
    latitude: 46.7207737,
    longitude: -92.1040796,
    city: "Superior",
    state: "WI",
  },
  {
    dma_code: 662,
    dma_name: "Abilene - Sweetwater",
    latitude: 32.4709519,
    longitude: -100.4059384,
    city: "Sweetwater",
    state: "TX",
  },
  {
    dma_code: 555,
    dma_name: "Syracuse",
    latitude: 43.0481221,
    longitude: -76.1474244,
    city: "Syracuse",
    state: "NY",
  },
  {
    dma_code: 819,
    dma_name: "Seattle - Tacoma",
    latitude: 47.2528768,
    longitude: -122.4442906,
    city: "Tacoma",
    state: "WA",
  },
  {
    dma_code: 581,
    dma_name: "Terre Haute",
    latitude: 39.4667034,
    longitude: -87.4139092,
    city: "Terre Haute",
    state: "IN",
  },
  {
    dma_code: 530,
    dma_name: "Tallahassee - Thomasville",
    latitude: 30.8365815,
    longitude: -83.9787808,
    city: "Thomasville",
    state: "GA",
  },
  {
    dma_code: 547,
    dma_name: "Toledo",
    latitude: 41.6639383,
    longitude: -83.555212,
    city: "Toledo",
    state: "OH",
  },
  {
    dma_code: 605,
    dma_name: "Topeka",
    latitude: 39.0483336,
    longitude: -95.6780371,
    city: "Topeka",
    state: "KS",
  },
  {
    dma_code: 532,
    dma_name: "Albany - Schenectady - Troy",
    latitude: 42.7284117,
    longitude: -73.6917851,
    city: "Troy",
    state: "NY",
  },
  {
    dma_code: 789,
    dma_name: "Tucson (Sierra Vista)",
    latitude: 32.2217429,
    longitude: -110.926479,
    city: "Tucson",
    state: "AZ",
  },
  {
    dma_code: 671,
    dma_name: "Tulsa",
    latitude: 36.1539816,
    longitude: -95.992775,
    city: "Tulsa",
    state: "OK",
  },
  {
    dma_code: 760,
    dma_name: "Twin Falls",
    latitude: 42.5629668,
    longitude: -114.4608711,
    city: "Twin Falls",
    state: "ID",
  },
  {
    dma_code: 526,
    dma_name: "Utica",
    latitude: 43.100903,
    longitude: -75.232664,
    city: "Utica",
    state: "NY",
  },
  {
    dma_code: 724,
    dma_name: "Fargo - Valley City",
    latitude: 46.9233129,
    longitude: -98.0031547,
    city: "Valley City",
    state: "ND",
  },
  {
    dma_code: 626,
    dma_name: "Victoria",
    latitude: 28.8052674,
    longitude: -97.0035982,
    city: "Victoria",
    state: "TX",
  },
  {
    dma_code: 866,
    dma_name: "Fresno - Visalia",
    latitude: 36.3302284,
    longitude: -119.2920585,
    city: "Visalia",
    state: "CA",
  },
  {
    dma_code: 511,
    dma_name: "Washington DC (Hagerstown)",
    latitude: 38.8951118,
    longitude: -77.0363658,
    city: "Washington DC ",
    state: "MD",
  },
  {
    dma_code: 545,
    dma_name: "Greenville - New Bern - Washington",
    latitude: 35.5465517,
    longitude: -77.0521742,
    city: "Washington",
    state: "NC",
  },
  {
    dma_code: 549,
    dma_name: "Watertown",
    latitude: 43.9747838,
    longitude: -75.9107565,
    city: "Watertown",
    state: "NY",
  },
  {
    dma_code: 673,
    dma_name: "Columbus - Tupelo - West Point",
    latitude: 33.6076186,
    longitude: -88.6503254,
    city: "West Point",
    state: "MS",
  },
  {
    dma_code: 598,
    dma_name: "Clarksburg - Weston",
    latitude: 39.0384274,
    longitude: -80.467313,
    city: "Weston",
    state: "WV",
  },
  {
    dma_code: 627,
    dma_name: "Wichita Falls & Lawton",
    latitude: 33.6953791,
    longitude: -98.3088441,
    city: "Wichita Falls",
    state: "OK",
  },
  {
    dma_code: 687,
    dma_name: "Minot - Bismarck - Dickinson",
    latitude: 48.1469683,
    longitude: -103.6179745,
    city: "Williston",
    state: "ND",
  },
  {
    dma_code: 550,
    dma_name: "Wilmington",
    latitude: 34.2257255,
    longitude: -77.9447102,
    city: "Wilmington",
    state: "NC",
  },
  {
    dma_code: 518,
    dma_name: "Greensboro - High Point - Winston-Salem",
    latitude: 36.0998596,
    longitude: -80.244216,
    city: "Winston Salem",
    state: "NC",
  },
  {
    dma_code: 566,
    dma_name: "Harrisburg - Lancaster - Lebanon - York",
    latitude: 39.9625984,
    longitude: -76.727745,
    city: "York",
    state: "PA",
  },
  {
    dma_code: 536,
    dma_name: "Youngstown",
    latitude: 41.0997803,
    longitude: -80.6495194,
    city: "Youngstown",
    state: "OH",
  },
  {
    dma_code: 596,
    dma_name: "Zanesville",
    latitude: 39.9403453,
    longitude: -82.0131924,
    city: "Zanesville",
    state: "OH",
  },
];

export default DMA_LIST;
