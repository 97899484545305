import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HttpErrorProvider from "./components/context/HttpErrorProvider";
import MemberNavigationLayout from "./components/layout/MemberNavigationLayout";
import MemberLogin from "./pages/MemberLogin";
import MemberLoginSetup from "./pages/MemberLoginSetup";
import MemberHome from "./pages/MemberHome";
import MemberTasks from "./pages/MemberTasks";
import MemberTask from "./pages/MemberTask";
import MemberTasksUrlQuickAdd from "./pages/MemberTaskUrlQuickAdd";
import FamilyInfo from "./pages/FamilyInfo";
import FamilyAddress from "./pages/FamilyAddress";
import SecretWrapper from "./pages/SecretWrapper";
import MemberSettings from "./pages/MemberSettings";
import CalendarEventRedirect from "./pages/CalendarEventRedirect";
import TokenRedirect from "./components/common/TokenRedirect";
import AdvisorPublicProfile from "./pages/AdvisorPublicProfile";
import NuxContextProvider from "./components/context/AdvisorNuxContext";
import NuxWrapper from "./pages/nux/NuxWrapper";
import Landing from "./pages/nux/member/Landing";
import Error from "./pages/nux/member/Error";
import PersonalDetails from "./pages/nux/member/MemberDetails";
import PlanDetails from "./pages/nux/member/MemberPlan";
import MemberContacts from "./pages/nux/member/MemberContacts";
import NuxMemberTasks from "./pages/nux/member/MemberTasks";
import Finish from "./pages/nux/member/Finish";
import { USER_NUX_TYPE } from "./components/nux/constants";
import CalendarIntegrations from "./pages/CalendarIntegrations";
const NUX_ROUTES = [
  { path: "/welcome/landing", component: Landing },
  { path: "/welcome/personal-details", component: PersonalDetails },
  { path: "/welcome/plan-details", component: PlanDetails },
  { path: "/welcome/contacts", component: MemberContacts },
  { path: "/welcome/tasks", component: NuxMemberTasks },
  { path: "/welcome/finish", component: Finish },
  { path: "/welcome/error", component: Error },
];
import { AppBarSxProvider } from "./components/context/AppBarContext";
import MemberAdvisorProfile from "./pages/MemberAdvisorProfile";
import Referrals from "./pages/Referrals";
import TaskDiscovery from "./pages/TaskDiscovery";

const NoMatch = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
    </div>
  );
};

const MEMBER_SITE_TITLE = "Member";

export default () => {
  const location = useLocation();
  useEffect(() => {
    document.title = MEMBER_SITE_TITLE;
  }, []);

  return (
    <Routes>
      <Route path={"/member-login"} element={<MemberLogin />} />
      <Route
        path={"/member-login-setup/:token?"}
        element={<MemberLoginSetup />}
      />
      <Route path={"/token-login/:token?"} element={<TokenRedirect />} />
      <Route
        path={"/advisor/:profilePath"}
        element={
          <HttpErrorProvider>
            <AdvisorPublicProfile />
          </HttpErrorProvider>
        }
      />
      <Route
        path={"/datebook/event"}
        element={
          <HttpErrorProvider>
            <CalendarEventRedirect />
          </HttpErrorProvider>
        }
      />
      <Route
        path={"/welcome"}
        element={
          <HttpErrorProvider>
            <NuxContextProvider userType={USER_NUX_TYPE.MEMBER}>
              <NuxWrapper userType={USER_NUX_TYPE.MEMBER} />
            </NuxContextProvider>
          </HttpErrorProvider>
        }
      >
        {NUX_ROUTES.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        path="/"
        element={
          <AppBarSxProvider>
            <MemberNavigationLayout />
          </AppBarSxProvider>
        }
      >
        <Route
          index
          element={<Navigate to={"home"} state={{ ...location.state }} />}
        />
        <Route path={"/home"} element={<MemberHome />} />
        <Route path={"/tasks"} element={<MemberTasks />} />
        <Route path={"/tasks/add"} element={<MemberTask addTask={true} />} />
        <Route
          path={"/tasks/add/inspired/:taskRef"}
          element={<MemberTasksUrlQuickAdd />}
        />
        <Route path={"/tasks/:taskRef"} element={<MemberTask />} />
        <Route path={"/family-info"} element={<FamilyInfo />} />
        <Route path={"/family-info/address"} element={<FamilyAddress />} />
        <Route path={"/invite"} element={<Referrals />} />
        <Route
          path={"/task-discovery"}
          element={<TaskDiscovery accountType="member" />}
        />
        <Route path={"/secret/:secretRef"} element={<SecretWrapper />} />
        <Route path={"/secret/"} element={<SecretWrapper />} />
        <Route path={"/settings/"} element={<MemberSettings />} />
        <Route
          path={"/profile/:profileRef"}
          element={<MemberAdvisorProfile />}
        />
        <Route
          path={"/settings/integrations"}
          element={<CalendarIntegrations />}
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
