import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import Duration from "../common/Duration";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as PhoneIcon } from "../../icons/PhoneIcon.svg";
import FeedEntryBox from "../common/FeedEntryBox";
import { getFormattedDuration } from "../../common/utils";
import LinkRouter from "../navigation/LinkRouter";

type CardConf = {
  color: string;
  title: string;
  duration: string;
};

const StyleForCard = (call: PhoneCall): CardConf => {
  const common = {
    duration: getFormattedDuration(
      Number(call.lastUpdatedSec),
      Number(call.creationSec),
    ),
  };
  switch (call.state) {
    case PhoneCall_State.ACTIVE:
      return {
        ...common,
        title: "Active Call",
        color: "#dfd",
      };
    case PhoneCall_State.COMPLETED:
      return {
        ...common,
        title: "Call Completed",
        color: "#4242425c",
      };
    case PhoneCall_State.MISSED:
      return {
        ...common,
        title: "Missed Call",
        color: "#fdeded",
      };
    case PhoneCall_State.FAILED:
    default:
      return {
        ...common,
        title: "Call Failed",
        color: "#fff4e5",
      };
  }
};

type CallPreviewProps = {
  call: PhoneCall;
};

export default ({ call }: CallPreviewProps) => {
  const style = StyleForCard(call);
  const creationDate = new Date(Number(call.creationSec) * 1000);
  return (
    <LinkRouter to={`/calls/${encodeURIComponent(call.ref)}`}>
      <FeedEntryBox
        header={style.title}
        subheader={
          call.state === PhoneCall_State.ACTIVE ? (
            <Duration startTime={creationDate} />
          ) : (
            style.duration
          )
        }
        icon={
          <SvgIcon sx={{ fontSize: 40 }}>
            <PhoneIcon />
          </SvgIcon>
        }
      />
    </LinkRouter>
  );
};
