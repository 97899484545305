import React, { ReactNode } from "react";
import {
  KeyIcon,
  CreditCardIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import CreditCardForm from "./forms/CreditCardForm";
import DefaultForm from "./forms/DefaultForm";
import LoginForm from "./forms/LoginForm";
import { SecretFormProps } from "./types";

interface SecretType {
  value: string;
  label: string;
  icon: ReactNode;
  view: React.FC<SecretFormProps>;
}

export const DEFAULT_SECRET_TYPE = {
  value: "secret",
  label: "Other",
  icon: <DocumentTextIcon height="24px" width="24px" />,
  view: DefaultForm,
};

export const SECRET_TYPES: SecretType[] = [
  {
    value: "credit_card",
    label: "Credit Card",
    icon: <CreditCardIcon height="24px" width="24px" />,
    view: CreditCardForm,
  },
  {
    value: "login",
    label: "Password",
    icon: <KeyIcon height="24px" width="24px" />,
    view: LoginForm,
  },
  DEFAULT_SECRET_TYPE,
];

export const getSecretLabel = (valueType: string) => {
  const obj = SECRET_TYPES.find((cat) => cat.value === valueType);
  return obj ? obj.label : "";
};
