import useProtoMethod from "./useProtoMethod";
import {
  CreateFamilySecretRequest,
  CreateFamilySecretResponse,
  CreateMemberSecretRequest,
  CreateMemberSecretResponse,
  DeleteSecretRequest,
  DeleteSecretResponse,
  RevealSecretRequest,
  RevealSecretResponse,
  EditSecretRequest,
  EditSecretResponse,
} from "protogen/secrets_service_pb";

const useCreateFamilySecret = (
  callback?: (r: CreateFamilySecretResponse) => void,
) => {
  return useProtoMethod<CreateFamilySecretRequest, CreateFamilySecretResponse>(
    "CreateFamilySecret",
    CreateFamilySecretResponse,
    { callback },
  );
};

const useDeleteSecret = (callback?: (r: DeleteSecretResponse) => void) => {
  return useProtoMethod<DeleteSecretRequest, DeleteSecretResponse>(
    "DeleteSecret",
    DeleteSecretResponse,
    { callback },
  );
};

const useDeleteMemberSecret = (
  callback?: (r: DeleteSecretResponse) => void,
) => {
  return useProtoMethod<DeleteSecretRequest, DeleteSecretResponse>(
    "DeleteMemberSecret",
    DeleteSecretResponse,
    { callback },
  );
};

const useRevealSecret = (callback?: (r: RevealSecretResponse) => void) => {
  return useProtoMethod<RevealSecretRequest, RevealSecretResponse>(
    "RevealSecret",
    RevealSecretResponse,
    { callback },
  );
};

const useRevealSecretMember = (
  callback?: (r: RevealSecretResponse) => void,
) => {
  return useProtoMethod<RevealSecretRequest, RevealSecretResponse>(
    "RevealSecretMember",
    RevealSecretResponse,
    { callback },
  );
};

const useCreateMemberSecret = (
  callback?: (r: CreateMemberSecretResponse) => void,
) => {
  return useProtoMethod<CreateMemberSecretRequest, CreateMemberSecretResponse>(
    "CreateMemberSecret",
    CreateMemberSecretResponse,
    { callback },
  );
};

const useEditSecret = (callback?: (r: EditSecretResponse) => void) => {
  return useProtoMethod<EditSecretRequest, EditSecretResponse>(
    "EditSecret",
    EditSecretResponse,
    { callback },
  );
};

const useEditMemberSecret = (callback?: (r: EditSecretResponse) => void) => {
  return useProtoMethod<EditSecretRequest, EditSecretResponse>(
    "EditMemberSecret",
    EditSecretResponse,
    { callback },
  );
};

export {
  useCreateFamilySecret,
  useDeleteSecret,
  useRevealSecret,
  useRevealSecretMember,
  useCreateMemberSecret,
  useEditSecret,
  useEditMemberSecret,
  useDeleteMemberSecret,
};
