import { lazy, ComponentType } from "react";
import { LucideProps } from "lucide-react";
type IconComponent = ComponentType<LucideProps>;

const EmptyIcon: React.FC = () => <div />;

const loadIcon = (iconName: string): Promise<{ default: IconComponent }> => {
  return import("lucide-react").then((icons) => {
    const Icon = icons[iconName];
    if (Icon) {
      return { default: Icon as IconComponent };
    } else {
      return { default: EmptyIcon as IconComponent };
    }
  });
};

const createLazyIcon = (iconName: string) => {
  return lazy(() => loadIcon(iconName));
};

const memoizedIcons: { [key: string]: ReturnType<typeof createLazyIcon> } = {};

export const LazyIcon = (iconName: string) => {
  if (!memoizedIcons[iconName]) {
    memoizedIcons[iconName] = createLazyIcon(iconName);
  }
  return memoizedIcons[iconName];
};
