import { useEffect, useState } from "react";
import {
  Typography,
  Menu,
  MenuItem,
  Box,
  Divider,
  MenuItemProps,
} from "@mui/material";
import {
  ReactNodeViewRenderer,
  NodeViewContent,
  NodeViewWrapper,
} from "@tiptap/react";
import TiptapTable, { TableOptions } from "@tiptap/extension-table";
import { addColumn, addRow } from "@tiptap/pm/tables";
import { Plus, Trash2 } from "lucide-react";
import { selectedRect } from "prosemirror-tables";

const CustomMenuItem = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & MenuItemProps) => {
  return (
    <MenuItem sx={{ padding: "0px" }} {...props}>
      {children}
    </MenuItem>
  );
};

export const TableWrapper = TiptapTable.extend<TableOptions>({
  addNodeView() {
    return ReactNodeViewRenderer(({ node, getPos, editor }: any) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const [tooltipPosition, setTooltipPosition] = useState<{
        x: number;
        y: number;
      } | null>(null);

      const isSelected = () => {
        const { selection } = editor.state;
        const currentPos = getPos();

        // Check if selection is within this table's position range
        return (
          currentPos <= selection.from &&
          selection.to <= currentPos + node.nodeSize
        );
      };

      // You can use this in your UI
      const [isActive, setIsActive] = useState(isSelected());
      useEffect(() => {
        if (!editor) return;

        const onSelectionUpdate = () => {
          setIsActive(isSelected());
        };

        // This event fires on all cursor movements and selection changes
        editor.on("selectionUpdate", onSelectionUpdate);

        return () => {
          editor.off("selectionUpdate", onSelectionUpdate);
        };
      }, [editor]);

      const addColumnToEnd = () => {
        const { state, view } = editor;
        const table = selectedRect(state);

        if (table) {
          const numberOfColumns = table.map.width;
          const { tr } = state;
          const newTr = addColumn(tr, table, numberOfColumns);

          if (newTr) {
            view.dispatch(newTr);
          }
        }
      };
      const addRowToBottom = () => {
        const { state, view } = editor;
        const table = selectedRect(state);

        if (table) {
          const numberOfRows = table.map.height;
          const { tr } = state;
          const newTr = addRow(tr, table, numberOfRows);

          if (newTr) {
            view.dispatch(newTr);
          }
        }
      };
      const handleRightClick = (e: React.MouseEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const cell = e.target as HTMLElement;
        const rect = cell.getBoundingClientRect();
        setTooltipPosition({ x: rect.left, y: rect.top - 40 });
        setAnchorEl(cell);
        editor.chain().focus().run();
      };

      const runEditorAction = (action: () => void) => {
        action();
        setAnchorEl(null);
      };

      return (
        <NodeViewWrapper
          className="custom-table-wrapper"
          onContextMenu={handleRightClick}
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <NodeViewContent
                as="table"
                className="custom-table-content"
                style={{
                  width: "auto",
                }}
              ></NodeViewContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                // Use mouseDown instead of click to prevent the editor from losing focus.
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  isActive && addColumnToEnd();
                }}
              >
                {
                  <Plus
                    style={{
                      cursor: isActive ? "pointer" : undefined,
                      visibility: !isActive ? "hidden" : undefined,
                    }}
                    size={16}
                  />
                }
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginRight: "16px", // to offset the right icon
              }}
              // Use mouseDown instead of click to prevent the editor from losing focus.
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
                isActive && addRowToBottom();
              }}
            >
              <Plus
                style={{
                  cursor: isActive ? "pointer" : undefined,
                  visibility: !isActive ? "hidden" : undefined,
                }}
                size={16}
              />
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorPosition={
              tooltipPosition
                ? { top: tooltipPosition.y, left: tooltipPosition.x }
                : undefined
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "12px 20px",
                gap: "20px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography variant="bodySmallHeavy" color="#6B6E7B">
                  Column
                </Typography>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addColumnBefore().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add column left
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addColumnAfter().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add column right
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().deleteColumn().run(),
                    )
                  }
                >
                  <Trash2
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Trash2>
                  <Typography variant="body" color="#3D3D3D">
                    Delete column
                  </Typography>
                </CustomMenuItem>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography variant="bodySmallHeavy" color="#6B6E7B">
                  Row
                </Typography>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addRowBefore().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add row above
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addRowAfter().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add row below
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().deleteRow().run(),
                    )
                  }
                >
                  <Trash2
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Trash2>
                  <Typography variant="body" color="#3D3D3D">
                    Delete row
                  </Typography>
                </CustomMenuItem>
              </Box>
              <Divider sx={{ marginLeft: "-20px", marginRight: "-20px" }} />
              <CustomMenuItem
                onClick={() =>
                  runEditorAction(() =>
                    editor.chain().focus().deleteTable().run(),
                  )
                }
              >
                <Trash2
                  size={20}
                  style={{ paddingLeft: "0px", marginRight: "8px" }}
                  stroke="#C14743"
                ></Trash2>
                <Typography variant="body" color="secondary.dark">
                  Delete table
                </Typography>
              </CustomMenuItem>
            </Box>
          </Menu>
        </NodeViewWrapper>
      );
    });
  },
});
