import React, { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Box, Button, TextareaAutosize } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { createTaskHelper } from "./utils";
import { Family } from "../family/types";
import RoundedDropDown, { Option } from "../common/RoundedDropDown";
import { ArrowRight, Plus } from "lucide-react";

const FamilyPicker = ({
  selectedFamilyRef,
  families,
  onSelect,
}: {
  selectedFamilyRef: string | null;
  families: Family[];
  onSelect: (s: string) => void;
}) => {
  return (
    <RoundedDropDown
      noBorder={true}
      small={true}
      initial={selectedFamilyRef}
      onChange={(s) => s?.value && onSelect(s.value)}
      options={
        families.map((f) => ({
          label: f.name,
          value: f.ref,
        })) as Option[]
      }
      displayValue={
        families.find((f) => f.ref === selectedFamilyRef)?.name || "Family"
      }
    />
  );
};

interface Props {
  // One of these two must be true.
  familyRef?: string;
  assigneeRef?: string;
  creatorType?: "advisor" | "member";
  selectFamilies?: Family[] | null;
  backgroundColor?: string;
  buttonPlacement?: "collapse" | "visible";
}

export default ({
  familyRef,
  assigneeRef,
  creatorType = "advisor",
  selectFamilies = null,
  backgroundColor = "#FFF",
  buttonPlacement = "collapse",
}: Props) => {
  const [selectedFamilyRef, setSelectedFamilyRef] = useState<string | null>(
    null,
  );
  const isMobile = useIsMobile();
  const [taskTitle, setTaskTitle] = useState<string>("");
  const { createTask, loading } = createTaskHelper({
    referral: "family-taskbar",
    creatorType,
    assigneeRef,
  });
  const fref = selectFamilies !== null ? selectedFamilyRef : familyRef;

  const handleCreateTask = () => {
    if (fref && taskTitle) {
      createTask({
        familyRef: fref,
        title: taskTitle,
      });
    }
  };
  return (
    <Box display="flex" flexDirection={"row"} alignItems="center" gap="16px">
      <div
        style={{
          borderRadius: "30px",
          border: "1px solid #D4D4D4",
          background: "#FFF",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          padding: "11px 0px 12px 11px",
          backgroundColor: backgroundColor,
        }}
      >
        <TextareaAutosize
          minRows={1}
          defaultValue={taskTitle}
          onChange={(event) => setTaskTitle(event.target.value)}
          style={{
            boxSizing: "border-box",
            border: "none",
            outline: "none",
            resize: "none",
            fontFamily: "AlbertSans",
            fontSize: "16px",
            fontWeight: "500",
            fontStyle: "normal",
            color: "#3D3D3D",
            marginLeft: "10px",
            marginRight: "10px",
            flex: 1,
            transition: "width 0.5s",
            backgroundColor: backgroundColor,
          }}
          placeholder="Add a new task"
          onKeyDown={(event) => {
            // Allow shift for newlines.
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleCreateTask();
            }
          }}
        />
        {selectFamilies !== null && (
          <FamilyPicker
            families={selectFamilies}
            onSelect={(s) => setSelectedFamilyRef(s)}
            selectedFamilyRef={selectedFamilyRef}
          />
        )}
        {buttonPlacement === "visible" && (
          <Button
            disabled={loading || !taskTitle}
            onClick={handleCreateTask}
            sx={{
              padding: "0px",
              height: "32px",
              width: "32px",
              minWidth: "32px",
              marginRight: "12px",
            }}
          >
            <ArrowRight />
          </Button>
        )}
      </div>
      {buttonPlacement === "collapse" && (
        <Collapse orientation={"horizontal"} in={!!taskTitle}>
          <Button
            disabled={
              loading || (selectedFamilyRef === null && !!selectFamilies)
            }
            onClick={handleCreateTask}
            sx={{
              textWrap: "nowrap",
              padding: isMobile ? "0px" : "8px 24px",
              overflow: "hidden",
            }}
          >
            {isMobile ? <Plus /> : "Create task"}
          </Button>
        </Collapse>
      )}
    </Box>
  );
};
