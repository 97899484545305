// Inspired from:
// https://github.com/mui/material-ui/issues/16037
import * as React from "react";
import { Grid } from "@mui/material";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from "./StripeTextFields";

export default (): JSX.Element => {
  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange =
    (field: string, errorField: string) =>
    ({
      complete,
      error = { message: null },
    }: {
      complete: boolean;
      error?: { message: null | string };
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };

  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={onElementChange("cardNumberComplete", "cardNumberError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={onElementChange("expiredComplete", "expiredError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={onElementChange("cvcComplete", "cvcError")}
        />
      </Grid>
    </Grid>
  );
};
