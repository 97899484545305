const AUTH_DATA_KEY = "nux-auth";

const getAuthData = (): string => {
  return localStorage.getItem(AUTH_DATA_KEY) || "";
};

const setAuthData = (data: string) => {
  localStorage.setItem(AUTH_DATA_KEY, data);
};

const clearAuthData = () => {
  localStorage.removeItem(AUTH_DATA_KEY);
};

export default {
  getAuthData,
  setAuthData,
  clearAuthData,
};
