import { Alert, AlertTitle } from "@mui/material";
import GridPage from "./layout/GridPage";

export default ({ title }: { title?: string }) => {
  return (
    <GridPage>
      <Alert severity="warning">
        <AlertTitle>{title || "Page not found"}</AlertTitle>
        Requested page was not found.
      </Alert>
    </GridPage>
  );
};
