import { useEffect, useState } from "react";

export default ({ startTime }: { startTime: Date }) => {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    // calculate initial duration in seconds
    const initialDuration = Math.floor(
      (new Date().getTime() - startTime.getTime()) / 1000,
    );
    setDuration(initialDuration);

    // update duration every second
    const interval = setInterval(() => {
      setDuration((prevDuration) => prevDuration + 1);
    }, 1000);

    // clear interval on unmount
    return () => clearInterval(interval);
  }, [startTime]);

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = (duration % 3600) % 60;

  let timeStr;
  if (hours > 0) {
    timeStr = `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    timeStr = `${minutes}m ${seconds}s`;
  } else {
    timeStr = `${seconds}s`;
  }
  return <span>{timeStr}</span>;
};
