import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Recipients } from "./types";
import { AutocompleteEntry } from "./AddressCompletion";
import AddressInputList from "./AddressInputList";

type Props = {
  recipients: Recipients;
  setRecipients: (r: Recipients) => void;
  autocompleteEntries?: AutocompleteEntry[];
  autofocusTo?: boolean;
};

export default ({
  recipients,
  setRecipients,
  autocompleteEntries,
  autofocusTo = false,
}: Props) => {
  const [open, setOpen] = useState({
    cc: recipients.cc.length > 0,
    bcc: recipients.bcc.length > 0,
  });
  useEffect(() => {
    if (
      (recipients.cc.length > 0 && !open.cc) ||
      (recipients.bcc.length > 0 && !open.bcc)
    ) {
      setOpen({ cc: recipients.cc.length > 0, bcc: recipients.bcc.length > 0 });
    }
  }, [recipients]);
  const buttonStyle = {
    width: "20px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "8px",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <AddressInputList
          autofocus={autofocusTo}
          title="to"
          recipients={recipients.to}
          setRecipients={(r) => setRecipients({ ...recipients, to: r })}
          autocompleteEntries={autocompleteEntries}
        />
        {open.cc && (
          <AddressInputList
            title="cc"
            recipients={recipients.cc}
            setRecipients={(r) => setRecipients({ ...recipients, cc: r })}
            autocompleteEntries={autocompleteEntries}
          />
        )}
        {open.bcc && (
          <AddressInputList
            title="bcc"
            recipients={recipients.bcc}
            setRecipients={(r) => setRecipients({ ...recipients, bcc: r })}
            autocompleteEntries={autocompleteEntries}
          />
        )}
      </Box>
      {(!open.cc || !open.bcc) && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
          {!open.cc && (
            <Typography
              variant="caption"
              sx={buttonStyle}
              onClick={() => setOpen({ ...open, cc: true })}
            >
              Cc
            </Typography>
          )}
          {!open.bcc && (
            <Typography
              variant="caption"
              sx={buttonStyle}
              onClick={() => setOpen({ ...open, bcc: true })}
            >
              Bcc
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
