import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { EventSuggestion } from "protogen/suggestions_pb";
import { PlusIcon, CheckIcon } from "@heroicons/react/24/outline";
import { EphemeralEvent } from "../../types/calendars";
import SuggestionItemContainer from "./SuggestionItemContainer";
import { EventReminder } from "protogen/calendar_pb";

const EventSuggestionItem = ({
  entityRef,
  entityType,
  suggestion,
  suggestEvent,
  familyRef,
  index,
  disabled = false,
}: {
  entityRef: string;
  entityType: string;
  suggestion: EventSuggestion;
  suggestEvent: (e: EphemeralEvent) => void;
  familyRef?: string;
  index: number;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const eventCreated = suggestion.eventRef;
  return (
    <Box sx={{}}>
      <Button
        disabled={disabled}
        size="small"
        sx={{
          textAlign: "left",
          height: "auto",
        }}
        variant="text"
        onClick={() => {
          if (eventCreated) {
            navigate(`/datebook/${encodeURIComponent(suggestion.eventRef)}`);
          } else {
            suggestEvent({
              title: suggestion.name,
              initialDetailsText: suggestion.details,
              allDay: !!(suggestion.dateStr && !suggestion.timeStr),
              recurringUnit: suggestion.recurringUnit,
              recurringInterval: suggestion.recurringInterval,
              startDate: new Date(
                `${suggestion.dateStr} ${suggestion.timeStr || "00:00:00"}`,
              ),
              sourceEntityType: entityType,
              sourceEntityRef: entityRef,
              sourceEntityIndex: index,
              familyRef: familyRef,
              reminders:
                suggestion?.reminder && suggestion?.reminder !== "None"
                  ? [
                      new EventReminder({
                        title: suggestion.reminder,
                      }),
                    ]
                  : undefined,
            });
          }
        }}
        startIcon={
          eventCreated ? (
            <CheckIcon height="20px" width="20px" />
          ) : (
            <PlusIcon height="20px" width="20px" />
          )
        }
      >
        {suggestion.name}
      </Button>
    </Box>
  );
};

export default ({
  entityRef,
  entityType,
  suggestions,
  suggestEvent,
  familyRef,
  isOpen,
  disabled = false,
}: {
  entityRef: string;
  entityType: string;
  suggestions: EventSuggestion[];
  suggestEvent: (e: EphemeralEvent) => void;
  familyRef?: string;
  isOpen: boolean;
  disabled?: boolean;
}) => {
  return (
    <SuggestionItemContainer
      open={isOpen}
      title={`Add event${suggestions.length === 1 ? "" : "s"}`}
    >
      {suggestions.map((suggestion: EventSuggestion, i: number) => {
        return (
          <EventSuggestionItem
            key={`suggestion-${suggestion.name}-${i}`}
            entityRef={entityRef}
            entityType={entityType}
            suggestion={suggestion}
            suggestEvent={suggestEvent}
            index={i}
            familyRef={familyRef}
            disabled={disabled}
          />
        );
      })}
    </SuggestionItemContainer>
  );
};
