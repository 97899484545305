import React, { useEffect, useState } from "react";
import {
  useCreateCalendarIntegration,
  useGetCalendarIntegrations,
  useUpdateCalendarIntegration,
} from "services/calendar";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useIsMobile from "components/hooks/useIsMobile";
import Loading from "components/common/Loading";
import { CalendarIntegration } from "protogen/calendar_service_pb";
import DateDisplay from "components/common/DateDisplay";
import ReactiveDialog from "components/common/ReactiveDialog";
import { useLocation } from "react-router-dom";
import Checkbox from "components/common/Checkbox";
import { useNavigate } from "react-router";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CAL_CLIENT_ID!;
const REDIRECT_URI = `https://${window.location.host}/settings/integrations`;

type GoogleScope = {
  name: string;
  path: string;
  helperText: string;
};

const SCOPES: GoogleScope[] = [
  {
    name: "Read only",
    path: "https://www.googleapis.com/auth/calendar.readonly",
    helperText: "Allows read-only access to your calendars and events.",
  },
  {
    name: "Limited read",
    path: "https://www.googleapis.com/auth/calendar.freebusy",
    helperText: "Allows access to your calendar's free/busy information only.",
  },
  {
    name: "Full access",
    path: "https://www.googleapis.com/auth/calendar",
    helperText:
      "Allows full access to manage your calendars and events, including creating, editing, and deleting events.",
  },
];

const SelectIntegration = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [scope, setScope] = useState<GoogleScope>(SCOPES[0]);
  const handleAuthorization = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20${scope.path}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&prompt=consent`;
    window.location.href = authUrl;
  };
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={`Add Calendar`}
      fullWidthSize="sm"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ padding: "20px", height: "100%" }}
      >
        <Box display="flex">
          <FormControl sx={{ m: 1, width: "100%" }}>
            <InputLabel>Access Level</InputLabel>
            <Select
              defaultValue={0}
              onChange={(e) => setScope(SCOPES[e.target.value as number])}
            >
              {SCOPES.map((scope, ind) => (
                <MenuItem key={scope.path} value={ind}>
                  {scope.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography sx={{ marginLeft: "8px" }} variant="body">
          {scope.helperText}
        </Typography>
        <Box display="flex">
          <Button
            fullWidth
            color="success"
            onClick={handleAuthorization}
            style={{ marginTop: "25px" }}
          >
            Connect
          </Button>
        </Box>
        <FormHelperText sx={{ marginTop: "10px" }}>
          Faye's use and transfer to any other app of information received from
          Google APIs will adhere to{" "}
          <Link
            target="_new"
            href={
              "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            }
          >
            Google API Services User Data Policy
          </Link>
          , including the Limited Use requirements.
        </FormHelperText>
      </Box>
    </ReactiveDialog>
  );
};

const AuthorizationCard = ({
  integration,
  onUpdate,
}: {
  integration: CalendarIntegration;
  onUpdate: () => void;
}) => {
  const { request } = useUpdateCalendarIntegration();
  const onIntegrationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const response = await request({
      integrationRef: integration.ref,
      deactivate: !event.target.checked,
      activate: event.target.checked,
      enableCalendarId: "",
      disableCalendarId: "",
    });
    if (response) {
      onUpdate();
    }
  };
  const onCalendarChange = async (calendarId: string, enabled: boolean) => {
    const response = await request({
      integrationRef: integration.ref,
      deactivate: false,
      activate: false,
      enableCalendarId: enabled ? calendarId : "",
      disableCalendarId: !enabled ? calendarId : "",
    });
    if (response) {
      onUpdate();
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="12px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
      }}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection={"column"} gap="4px">
          <Typography
            variant="bodyHeavy"
            color="#262626"
          >{`${integration.name}`}</Typography>
          <Typography
            variant="bodySmall"
            color="text.secondary"
            sx={{
              textWrap: "none",
              textOverflow: "ellipses",
              overflow: "hidden",
            }}
          >
            Last accessed{" "}
            <DateDisplay
              date={new Date(Number(integration.lastAccessedSec) * 1000)}
            />
          </Typography>
        </Box>
        <Box display="flex" gap="32px" justifyContent="end">
          <Switch
            edge="end"
            onChange={onIntegrationChange}
            defaultChecked={integration.status === "active"}
            inputProps={{
              "aria-labelledby": "switch-label-family-",
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="4px">
        <Box display="flex" flexDirection="column" gap="18px" padding="8px 8px">
          {integration.calendars.map((cal) => {
            return (
              <FormControlLabel
                key={cal.id}
                label={
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="bodyHeavy"
                      color="text.primary"
                      sx={{
                        textWrap: "none",
                        textOverflow: "ellipses",
                        overflow: "hidden",
                      }}
                    >
                      {cal.summary}
                    </Typography>
                    <Typography
                      variant="body"
                      color="text.secondary"
                      sx={{
                        textWrap: "none",
                        textOverflow: "ellipses",
                        overflow: "hidden",
                      }}
                    >
                      {cal.id}
                    </Typography>
                  </Box>
                }
                control={
                  <Checkbox
                    disabled={integration.status !== "active"}
                    checked={cal.enabled}
                    onChange={(e) => onCalendarChange(cal.id, e.target.checked)}
                  />
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

type IntegrationResponse = {
  code?: string;
  scope?: string;
  redirectUri?: string;
  calendarUrl?: string;
};

export default () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const integrationSuccess = searchParams.get("calendarAdded") === "success";
  const [dialogOpen, setDialogOpen] = useState(false);
  const [integrationResponse, setIntegrationResponse] =
    useState<IntegrationResponse | null>(null);
  const { request, loading, data } = useGetCalendarIntegrations();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const {
    request: createRequest,
    error: createError,
    loading: createLoading,
  } = useCreateCalendarIntegration();
  useEffect(() => {
    const code = searchParams.get("code");
    const scope = searchParams.get("scope");
    if (code && scope) {
      setIntegrationResponse({ code, scope, redirectUri: REDIRECT_URI });
    }
    request();
  }, []);

  const doToast = (key: number) => {
    setSnackPack((p) => [
      ...p,
      {
        message: "Changes saved!",
        saveNotification: true,
        key: `${key}`,
      },
    ]);
  };

  useEffect(() => {
    const create = async (ir: IntegrationResponse) => {
      await createRequest({
        accessCode: ir.code || "",
        accessScope: ir.scope || "",
        accessRedirectUri: ir.redirectUri || "",
        calendarUrl: ir.calendarUrl || "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?calendarAdded=success`,
      });
      request();
    };
    if (integrationResponse) {
      create(integrationResponse);
    }
  }, [integrationResponse]);

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Calendars", link: "/settings/integration" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Calendar Integrations</Typography>
            <Button onClick={() => setDialogOpen(true)}>Add Calendar</Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!createLoading && integrationSuccess && (
          <Alert>
            <AlertTitle>New Calendar Added</AlertTitle>
            New calendar added!
          </Alert>
        )}
        {integrationResponse && createError && (
          <Alert severity="error">
            {/* Error? */}
            <AlertTitle>Error Adding Calendar</AlertTitle>
            {createError}
          </Alert>
        )}
        {(data?.integrations || []).map((i) => (
          <AuthorizationCard
            key={i.ref}
            integration={i}
            onUpdate={() => {
              doToast(new Date().getTime());
              request();
            }}
          />
        ))}
        {!loading && data?.integrations.length === 0 && (
          <Typography variant="h4Serif">No calendars connected</Typography>
        )}
        {integrationResponse && createLoading && <Loading />}
        {!createLoading && loading && !data && <Loading />}
      </Box>
      <SelectIntegration
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <SnackPack
        autoHideDuration={2000}
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};
