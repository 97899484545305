import { Content, Editor } from "@tiptap/react";
import {
  RecommendationsSuggestions_Recommendation,
  WebResult,
} from "protogen/suggestions_pb";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { NodeViewProps } from "@tiptap/react";

export interface TipTapNodeAttr extends Attr {}

export interface TipTapComponentNodeProps<T extends TipTapNodeAttr>
  extends ProseMirrorNode {
  attrs: T;
}

export interface TipTapComponentProps<T extends TipTapNodeAttr>
  extends NodeViewProps {
  node: TipTapComponentNodeProps<T>;
}

export type InsertableSuggestion = {
  product?: WebResult;
  webLink?: WebResult;
  recommendation?: RecommendationsSuggestions_Recommendation;
};

const appendContent = (editor: Editor, content: Content) => {
  const endOfDoc = editor.state.doc.content.size;
  editor
    ?.chain()
    .insertContentAt(endOfDoc, content)
    .insertContentAt(endOfDoc + 1, {
      type: "paragraph",
    })
    .run();
};

const insertRecommendation = (
  editor: Editor,
  recommendation: RecommendationsSuggestions_Recommendation,
) => {
  appendContent(editor, {
    type: "recommendationExtension",
    attrs: {
      identifier: recommendation.postRef,
      title: recommendation.title,
      subtitle: recommendation.textContent,
      url: `/community/${encodeURIComponent(recommendation.postRef)}`,
    },
  });
};

const insertProduct = (editor: Editor, product: WebResult) => {
  appendContent(editor, {
    type: "productLinkExtension",
    attrs: {
      identifier: product.url,
      title: product.title,
      description: product.description,
      url: product.url,
      thumbnailUrl: product.thumbnailUrl,
      phone: product.phone,
      address: product.address,
    },
  });
};

export const insertSuggestion = (
  editor: Editor,
  suggestion: InsertableSuggestion,
) => {
  if (suggestion.product) {
    insertProduct(editor, suggestion.product);
  } else if (suggestion.webLink) {
    insertProduct(editor, suggestion.webLink);
  } else if (suggestion.recommendation) {
    insertRecommendation(editor, suggestion.recommendation);
  }
};
