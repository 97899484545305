import { CurrentUser } from "protogen/auth_pb";
import { EmailMessage } from "protogen/conversation_pb";
import { LocalDraft } from "./types";
import { attachmentsToUploads } from "../creation/FileUploader";

const _defaultRecipientsForReply = (
  currentUser: CurrentUser,
  inReplyTo: EmailMessage,
) => {
  if (inReplyTo.senderRef === currentUser.ref) {
    return {
      to: inReplyTo.toAddresses,
      cc: inReplyTo.ccAddresses,
      bcc: inReplyTo.bccAddresses,
    };
  }
  // Reply all by removing us from "to" and adding the sender
  const to = inReplyTo.toAddresses
    .filter((r) => r !== currentUser.email)
    .concat([inReplyTo.fromAddress]);
  return {
    to,
    cc: inReplyTo.ccAddresses.filter((r) => r !== currentUser.email),
    bcc: inReplyTo.bccAddresses.filter((r) => r !== currentUser.email),
  };
};

const initialDraftFromDraft = (draft: EmailMessage): LocalDraft => {
  return {
    ref: draft.ref,
    isReply: draft.isReply,
    to: draft.toAddresses,
    cc: draft.ccAddresses,
    bcc: draft.bccAddresses,
    subject: draft.subject,
    textContent: draft.text,
    htmlContent: draft.html,
    attachments: draft.attachments || [],
    uploadAttachments: attachmentsToUploads(draft.attachments || []),
    isForwarded: draft.isForwarded,
  };
};

const initialDraftFromReply = (
  inReplyTo: EmailMessage,
  currentUser: CurrentUser,
): LocalDraft => {
  return {
    ..._defaultRecipientsForReply(currentUser, inReplyTo),
    replyToRef: inReplyTo.ref,
    isReply: true,
    subject: inReplyTo.subject.toLowerCase().startsWith("re:")
      ? inReplyTo.subject
      : `RE: ${inReplyTo.subject}`,
    textContent: "",
    htmlContent: "",
    attachments: [],
    uploadAttachments: [],
    isForwarded: false,
  };
};

const initialDraftForwardFromReply = (inReplyTo: EmailMessage): LocalDraft => {
  return {
    to: [],
    cc: [],
    bcc: [],
    replyToRef: inReplyTo.ref,
    isReply: true,
    subject: `FWD: ${inReplyTo.subject}`,
    textContent: "",
    htmlContent: "",
    attachments: [],
    uploadAttachments: [],
    isForwarded: true,
  };
};

const initialLocalDraft = (
  draft: EmailMessage | null,
  inReplyTo: EmailMessage | null,
  currentUser: CurrentUser,
  isForwarded: boolean,
  initialTo: string[] | null | undefined = [],
  initialCC: string[] | null | undefined = [],
): LocalDraft => {
  if (draft && inReplyTo) {
    throw new Error("Cannot have both draft and inReplyTo");
  }
  if (draft) return initialDraftFromDraft(draft);
  if (inReplyTo && isForwarded) return initialDraftForwardFromReply(inReplyTo);
  if (inReplyTo) return initialDraftFromReply(inReplyTo, currentUser);
  return {
    to: initialTo || [],
    cc: initialCC || [],
    bcc: [],
    subject: "",
    textContent: "",
    htmlContent: "",
    attachments: [],
    uploadAttachments: [],
    isReply: false,
    isForwarded: false,
  };
};

const isDraftSendable = (draft: LocalDraft) => {
  if (draft.to.length == 0) return false;
  // if (!draft.isReply && draft.subject.length == 0) return false;
  // if (draft.textContent.length == 0) return false;
  // NOTE(Kip): For now, let's be pretty loose on email requirements.
  if (
    draft.subject.length == 0 &&
    draft.textContent.length === 0 &&
    draft.attachments.length === 0
  ) {
    return false;
  }
  return true;
};

const isDraftSavable = (draft: LocalDraft) => {
  if (draft.isReply && !(draft.textContent.length > 0)) return false;
  if (draft.to.length > 0 || draft.cc.length > 0 || draft.bcc.length > 0)
    return true;
  if (!draft.isReply && draft.subject.length > 0) return true;
  if (draft.textContent.length > 0) return true;
  return false;
};

export {
  initialDraftFromDraft,
  initialLocalDraft,
  isDraftSendable,
  isDraftSavable,
};
