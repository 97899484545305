import TileSection from "./TileSection";
import {
  Baby,
  Car,
  Carrot,
  Drill,
  PartyPopper,
  Plane,
  Stethoscope,
  SunSnow,
} from "lucide-react";
import React from "react";
import { CommonTileProps } from "./Panel";

export default () => {
  return (
    <TileSection
      tiles={[
        {
          title: "Home services",
          icon: <Drill {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("home services")}`,
        },
        {
          title: "Parties",
          icon: <PartyPopper {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("parties")}`,
        },
        {
          title: "Kids activities",
          icon: <Baby {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("kids activities")}`,
        },
        {
          title: "Travel",
          icon: <Plane {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("travel")}`,
        },
        {
          title: "Seasonal",
          icon: <SunSnow {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("seasonal")}`,
        },
        {
          title: "Food",
          icon: <Carrot {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("food")}`,
        },
        {
          title: "Wellness",
          icon: <Stethoscope {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent("health")}`,
        },
        {
          title: "Automotive",
          icon: <Car {...CommonTileProps} />,
          path: `/community/topics/${encodeURIComponent(
            "automotive services",
          )}`,
        },
      ]}
    />
  );
};
