import { FC, ReactNode } from "react";

// Define a type for the wrapper components
export type ContextComponent = FC<{ children: ReactNode }>;

interface Props {
  contexts?: ContextComponent[];
  children: ReactNode;
}

export default ({ contexts, children }: Props) => {
  if (!contexts || !contexts.length) return <>{children}</>;
  // Recursively nest the children inside each wrapper
  const renderNestedContext = (
    contexts: ContextComponent[],
    content: ReactNode,
  ): ReactNode => {
    if (!contexts.length) return content;
    const [CurrentContext, ...remainingContexts] = contexts;
    return (
      <CurrentContext>
        {renderNestedContext(remainingContexts, content)}
      </CurrentContext>
    );
  };

  return <>{renderNestedContext(contexts, children)}</>;
};
