import { useRef, useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { SECRET_TYPES, DEFAULT_SECRET_TYPE } from "./Constants";
import { EphemeralSecret, SecretFormHandle } from "./types";
import useIsMobile from "../hooks/useIsMobile";
import { Family } from "protogen/advisors_service_pb";

export const getTypeView = (secret: EphemeralSecret) => {
  const isNewSecret = secret.name === "" || secret.value === "";
  let isValidJSON = checkIsValidJson(secret.value);
  const categoryObj = SECRET_TYPES.find(
    (cat) => cat.value === secret.valueType,
  );

  return categoryObj && (isNewSecret || isValidJSON)
    ? categoryObj
    : DEFAULT_SECRET_TYPE;
};

function checkIsValidJson(jsonString: string): boolean {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
  return true;
}

export default ({
  secret,
  isEdit,
  updateFormData,
  onSave,
  onDelete,
  disabled,
  children,
  family,
}: {
  secret: EphemeralSecret;
  isEdit: boolean;
  updateFormData: (f: EphemeralSecret) => void;
  onSave: () => void;
  onDelete: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  family?: Family;
}) => {
  const isMobile = useIsMobile();
  const [isValid, setIsValid] = useState(false);
  const viewRef = useRef<SecretFormHandle | null>(null);
  const secretType = getTypeView(secret);

  let secretLabel = secretType.label.toLowerCase();
  secretLabel = secretLabel == "other" ? "secret" : secretLabel;

  const view = (
    <secretType.view
      ref={viewRef}
      secret={secret}
      updateFormData={updateFormData}
      errors={null}
      family={family}
    />
  );

  useEffect(() => {
    setIsValid(viewRef.current?.isValid() ?? false);
  }, [viewRef.current]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        paddingTop: "8px",
        paddingLeft: "42px",
        paddingRight: "42px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Typography variant="title">
          {isEdit ? `Edit ${secretLabel}` : `Add a ${secretLabel}`}
        </Typography>
      </Box>
      <Box>{children}</Box>
      <Box sx={{ marginBottom: "24px" }}>{view}</Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {isEdit ? (
          <Button
            onClick={onDelete}
            variant={isMobile ? "text" : "outlined"}
            sx={{ color: isMobile ? "secondary.dark" : "primary.main" }}
          >
            Delete
          </Button>
        ) : (
          <Box></Box>
        )}
        <Button
          sx={{ marginBottom: "12px" }}
          disabled={!isValid || disabled}
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
