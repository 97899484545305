import useProtoMethod from "./useProtoMethod";
import {
  RetrySendTextMessageRequest,
  RetrySendTextMessageResponse,
  SendTextMessageRequest,
  SendTextMessageResponse,
} from "protogen/text_message_service_pb";

const useSendTextMessage = (
  callback?: (r: SendTextMessageResponse) => void,
) => {
  return useProtoMethod<SendTextMessageRequest, SendTextMessageResponse>(
    "SendTextMessage",
    SendTextMessageResponse,
    { callback },
  );
};

const useRetrySendTextMessage = (
  callback?: (r: RetrySendTextMessageResponse) => void,
) => {
  return useProtoMethod<
    RetrySendTextMessageRequest,
    RetrySendTextMessageResponse
  >("RetrySendTextMessage", RetrySendTextMessageResponse, { callback });
};

export { useSendTextMessage, useRetrySendTextMessage };
