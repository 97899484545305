// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/facts_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { UploadAttachment } from "./common_pb.ts";
import { FactSectionStub, FactStub } from "./facts_pb.ts";

/**
 * @generated from message protogen.CreateFactRequest
 */
export class CreateFactRequest extends Message<CreateFactRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string valueType = 3;
   */
  valueType = "";

  /**
   * @generated from field: string value = 4;
   */
  value = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 5;
   */
  attachments: UploadAttachment[] = [];

  /**
   * Track where a fact came from.
   *
   * @generated from field: string sourceEntityType = 6;
   */
  sourceEntityType = "";

  /**
   * @generated from field: string sourceEntityRef = 7;
   */
  sourceEntityRef = "";

  /**
   * @generated from field: int32 sourceEntityIndex = 8;
   */
  sourceEntityIndex = 0;

  /**
   * @generated from field: string sectionRef = 9;
   */
  sectionRef = "";

  constructor(data?: PartialMessage<CreateFactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "valueType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
    { no: 6, name: "sourceEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sourceEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "sourceEntityIndex", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "sectionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFactRequest {
    return new CreateFactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFactRequest {
    return new CreateFactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFactRequest {
    return new CreateFactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFactRequest | PlainMessage<CreateFactRequest> | undefined, b: CreateFactRequest | PlainMessage<CreateFactRequest> | undefined): boolean {
    return proto3.util.equals(CreateFactRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFactResponse
 */
export class CreateFactResponse extends Message<CreateFactResponse> {
  /**
   * @generated from field: protogen.FactStub fact = 1;
   */
  fact?: FactStub;

  constructor(data?: PartialMessage<CreateFactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact", kind: "message", T: FactStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFactResponse {
    return new CreateFactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFactResponse {
    return new CreateFactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFactResponse {
    return new CreateFactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFactResponse | PlainMessage<CreateFactResponse> | undefined, b: CreateFactResponse | PlainMessage<CreateFactResponse> | undefined): boolean {
    return proto3.util.equals(CreateFactResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteFactRequest
 */
export class DeleteFactRequest extends Message<DeleteFactRequest> {
  /**
   * @generated from field: string factRef = 1;
   */
  factRef = "";

  constructor(data?: PartialMessage<DeleteFactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteFactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFactRequest {
    return new DeleteFactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFactRequest {
    return new DeleteFactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFactRequest {
    return new DeleteFactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFactRequest | PlainMessage<DeleteFactRequest> | undefined, b: DeleteFactRequest | PlainMessage<DeleteFactRequest> | undefined): boolean {
    return proto3.util.equals(DeleteFactRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteFactResponse
 */
export class DeleteFactResponse extends Message<DeleteFactResponse> {
  constructor(data?: PartialMessage<DeleteFactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteFactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFactResponse {
    return new DeleteFactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFactResponse {
    return new DeleteFactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFactResponse {
    return new DeleteFactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFactResponse | PlainMessage<DeleteFactResponse> | undefined, b: DeleteFactResponse | PlainMessage<DeleteFactResponse> | undefined): boolean {
    return proto3.util.equals(DeleteFactResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditFactRequest
 */
export class EditFactRequest extends Message<EditFactRequest> {
  /**
   * @generated from field: string factRef = 1;
   */
  factRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 4;
   */
  attachments: UploadAttachment[] = [];

  /**
   * @generated from field: float sortOrder = 5;
   */
  sortOrder = 0;

  /**
   * @generated from field: string sectionRef = 6;
   */
  sectionRef = "";

  constructor(data?: PartialMessage<EditFactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditFactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
    { no: 5, name: "sortOrder", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "sectionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditFactRequest {
    return new EditFactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditFactRequest {
    return new EditFactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditFactRequest {
    return new EditFactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditFactRequest | PlainMessage<EditFactRequest> | undefined, b: EditFactRequest | PlainMessage<EditFactRequest> | undefined): boolean {
    return proto3.util.equals(EditFactRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditFactResponse
 */
export class EditFactResponse extends Message<EditFactResponse> {
  /**
   * @generated from field: protogen.FactStub fact = 1;
   */
  fact?: FactStub;

  constructor(data?: PartialMessage<EditFactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditFactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact", kind: "message", T: FactStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditFactResponse {
    return new EditFactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditFactResponse {
    return new EditFactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditFactResponse {
    return new EditFactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditFactResponse | PlainMessage<EditFactResponse> | undefined, b: EditFactResponse | PlainMessage<EditFactResponse> | undefined): boolean {
    return proto3.util.equals(EditFactResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateFactSectionRequest
 */
export class CreateFactSectionRequest extends Message<CreateFactSectionRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<CreateFactSectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFactSectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFactSectionRequest {
    return new CreateFactSectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFactSectionRequest {
    return new CreateFactSectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFactSectionRequest {
    return new CreateFactSectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFactSectionRequest | PlainMessage<CreateFactSectionRequest> | undefined, b: CreateFactSectionRequest | PlainMessage<CreateFactSectionRequest> | undefined): boolean {
    return proto3.util.equals(CreateFactSectionRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFactSectionResponse
 */
export class CreateFactSectionResponse extends Message<CreateFactSectionResponse> {
  /**
   * @generated from field: protogen.FactSectionStub factSection = 1;
   */
  factSection?: FactSectionStub;

  constructor(data?: PartialMessage<CreateFactSectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFactSectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factSection", kind: "message", T: FactSectionStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFactSectionResponse {
    return new CreateFactSectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFactSectionResponse {
    return new CreateFactSectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFactSectionResponse {
    return new CreateFactSectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFactSectionResponse | PlainMessage<CreateFactSectionResponse> | undefined, b: CreateFactSectionResponse | PlainMessage<CreateFactSectionResponse> | undefined): boolean {
    return proto3.util.equals(CreateFactSectionResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditFactSectionRequest
 */
export class EditFactSectionRequest extends Message<EditFactSectionRequest> {
  /**
   * @generated from field: string factSectionRef = 1;
   */
  factSectionRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: float sortOrder = 3;
   */
  sortOrder = 0;

  constructor(data?: PartialMessage<EditFactSectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditFactSectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factSectionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sortOrder", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditFactSectionRequest {
    return new EditFactSectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditFactSectionRequest {
    return new EditFactSectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditFactSectionRequest {
    return new EditFactSectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditFactSectionRequest | PlainMessage<EditFactSectionRequest> | undefined, b: EditFactSectionRequest | PlainMessage<EditFactSectionRequest> | undefined): boolean {
    return proto3.util.equals(EditFactSectionRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditFactSectionResponse
 */
export class EditFactSectionResponse extends Message<EditFactSectionResponse> {
  /**
   * @generated from field: protogen.FactSectionStub factSection = 1;
   */
  factSection?: FactSectionStub;

  constructor(data?: PartialMessage<EditFactSectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditFactSectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factSection", kind: "message", T: FactSectionStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditFactSectionResponse {
    return new EditFactSectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditFactSectionResponse {
    return new EditFactSectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditFactSectionResponse {
    return new EditFactSectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditFactSectionResponse | PlainMessage<EditFactSectionResponse> | undefined, b: EditFactSectionResponse | PlainMessage<EditFactSectionResponse> | undefined): boolean {
    return proto3.util.equals(EditFactSectionResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteFactSectionRequest
 */
export class DeleteFactSectionRequest extends Message<DeleteFactSectionRequest> {
  /**
   * @generated from field: string factSectionRef = 1;
   */
  factSectionRef = "";

  constructor(data?: PartialMessage<DeleteFactSectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteFactSectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factSectionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFactSectionRequest {
    return new DeleteFactSectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFactSectionRequest {
    return new DeleteFactSectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFactSectionRequest {
    return new DeleteFactSectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFactSectionRequest | PlainMessage<DeleteFactSectionRequest> | undefined, b: DeleteFactSectionRequest | PlainMessage<DeleteFactSectionRequest> | undefined): boolean {
    return proto3.util.equals(DeleteFactSectionRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteFactSectionResponse
 */
export class DeleteFactSectionResponse extends Message<DeleteFactSectionResponse> {
  constructor(data?: PartialMessage<DeleteFactSectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteFactSectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFactSectionResponse {
    return new DeleteFactSectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFactSectionResponse {
    return new DeleteFactSectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFactSectionResponse {
    return new DeleteFactSectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFactSectionResponse | PlainMessage<DeleteFactSectionResponse> | undefined, b: DeleteFactSectionResponse | PlainMessage<DeleteFactSectionResponse> | undefined): boolean {
    return proto3.util.equals(DeleteFactSectionResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyFactSectionsRequest
 */
export class GetFamilyFactSectionsRequest extends Message<GetFamilyFactSectionsRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<GetFamilyFactSectionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyFactSectionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyFactSectionsRequest {
    return new GetFamilyFactSectionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyFactSectionsRequest {
    return new GetFamilyFactSectionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyFactSectionsRequest {
    return new GetFamilyFactSectionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyFactSectionsRequest | PlainMessage<GetFamilyFactSectionsRequest> | undefined, b: GetFamilyFactSectionsRequest | PlainMessage<GetFamilyFactSectionsRequest> | undefined): boolean {
    return proto3.util.equals(GetFamilyFactSectionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyFactSectionsResponse
 */
export class GetFamilyFactSectionsResponse extends Message<GetFamilyFactSectionsResponse> {
  /**
   * @generated from field: repeated protogen.FactSectionStub factSections = 1;
   */
  factSections: FactSectionStub[] = [];

  constructor(data?: PartialMessage<GetFamilyFactSectionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyFactSectionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factSections", kind: "message", T: FactSectionStub, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyFactSectionsResponse {
    return new GetFamilyFactSectionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyFactSectionsResponse {
    return new GetFamilyFactSectionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyFactSectionsResponse {
    return new GetFamilyFactSectionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyFactSectionsResponse | PlainMessage<GetFamilyFactSectionsResponse> | undefined, b: GetFamilyFactSectionsResponse | PlainMessage<GetFamilyFactSectionsResponse> | undefined): boolean {
    return proto3.util.equals(GetFamilyFactSectionsResponse, a, b);
  }
}

