import { ReactNode } from "react";
import useIsMobile from "../hooks/useIsMobile";
import MobileDrawer from "./MobileDrawer";
import DesktopDrawer from "./DesktopDrawer";

interface ResponsiveDrawerProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  drawerWidth: number;
  children: ReactNode;
}

export default ({
  open,
  setOpen,
  drawerWidth,
  children,
}: ResponsiveDrawerProps) => {
  const isMobileView = useIsMobile();

  if (isMobileView) {
    return (
      <MobileDrawer open={open} setOpen={setOpen} drawerWidth={drawerWidth}>
        {children}
      </MobileDrawer>
    );
  }
  return (
    <DesktopDrawer open={open} setOpen={setOpen} drawerWidth={drawerWidth}>
      {children}
    </DesktopDrawer>
  );
};
