import useIsMobile from "../hooks/useIsMobile";
import useIsDesktop from "../hooks/useIsDesktop";
import { Box, Typography } from "@mui/material";
import { Video as VideoIcon } from "lucide-react";

export default () => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  type ResourceLink = {
    name: string;
    href: string;
    icon?: boolean;
  };

  const ResourceSection = ({
    title,
    links,
  }: {
    title: string;
    links: ResourceLink[];
  }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: isMobile ? "0px" : "8px",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {links.map((link) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            {link.icon && <VideoIcon width="20px" color="#198282" />}
            <a
              style={{
                padding: isMobile ? "8px 0px" : "0px",
                fontWeight: "600",
                textDecoration: "none",
                color: "#198282",
                letterSpacing: "-0.15px",
              }}
              href={link.href}
            >
              {link.name}
            </a>
          </Box>
        ))}
      </Box>
    );
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "32px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isDesktop ? "row" : "column",
        gap: "32px",
        marginBottom: "32px",
      }}
    >
      {/* Left column */}
      <Box sx={columnStyle}>
        <ResourceSection
          title="Platform basics"
          links={[
            {
              name: "Setting up your profile",
              href: "https://www.loom.com/share/a8d54251da5c44079227e541466374fe?sid=c7acc7f6-cf3c-471e-8efa-9554fca14d8e",
              icon: true,
            },
            {
              name: "Family details",
              href: "https://www.loom.com/share/81bb8749ae6f4f50a86a1c9f637df2b2?sid=90b40de8-b33c-496b-8e42-d7be4274fac9",
              icon: true,
            },
            {
              name: "Inbox and messaging",
              href: "https://www.loom.com/share/8a28e6ca7f5f4d63a05fecbd75c28592?sid=93e4f666-7274-4d38-8b2e-33aa25d51fa5",
              icon: true,
            },
            {
              name: "Using the Faye community",
              href: "https://www.loom.com/share/0c9b687474d24bddaff1d277de1c20bf?sid=c5e4439d-1c9e-41d4-be84-22af83eaad1c",
              icon: true,
            },
            {
              name: "Adding a family",
              href: "https://www.loom.com/share/37d0aa8968d5467ca2480308499e1fb3?sid=090366f6-56bb-42a7-bbdb-83f5cca29366",
              icon: true,
            },
            {
              name: "Running reports",
              href: "https://www.loom.com/share/a0cc241ec37443eeab95f3b327313a18?sid=dd4d1b9c-87e8-4d18-b638-413b2ea6893c",
              icon: true,
            },
            {
              name: "Adding Faye to your phone's home screen",
              href: "https://advisor.findfaye.com/community/AAAAAAAABe8%3D",
            },
            {
              name: "Client view: Invitations",
              href: "https://advisor.findfaye.com/community/AAAAAAAAC7w%3D",
            },
          ]}
        />

        <ResourceSection
          title="General operations"
          links={[
            {
              name: "Working with Faye",
              href: "https://advisor.findfaye.com/community/AAAAAAAAE%2Fw%3D",
            },
            {
              name: "Code of conduct",
              href: "https://advisor.findfaye.com/community/AAAAAAAAE%2F0%3D",
            },
          ]}
        />
      </Box>

      {/* Right column */}
      <Box sx={columnStyle}>
        <ResourceSection
          title="Working with clients"
          links={[
            {
              name: "Best practices",
              href: "https://advisor.findfaye.com/community/AAAAAAAAE%2F4%3D",
            },
            {
              name: "Common questions",
              href: "https://advisor.findfaye.com/community/AAAAAAAAE5k%3D",
            },
            {
              name: "Handling new client leads",
              href: "https://advisor.findfaye.com/community/AAAAAAAAEKI%3D",
            },
            {
              name: "Onboarding questions for new clients",
              href: "https://advisor.findfaye.com/community/AAAAAAAAE%2F8%3D",
            },
            {
              name: '14 days to "wow"',
              href: "https://advisor.findfaye.com/community/AAAAAAAAEEA%3D",
            },
            {
              name: "Client calendars",
              href: "https://advisor.findfaye.com/community/AAAAAAAAFes%3D",
            },
          ]}
        />

        <ResourceSection
          title="Task ideas"
          links={[
            {
              name: "Delegation directory",
              href: "https://docs.google.com/spreadsheets/d/1VOaUsZcG6qDhiWF_lxSoHZfH7WjsL6Ya0nrtFD8aXvY/edit?gid=89708944#gid=89708944",
            },
            {
              name: "Common tasks to ask quiet clients",
              href: "https://advisor.findfaye.com/community/AAAAAAAAEnA%3D",
            },
          ]}
        />

        <ResourceSection
          title="Growing your business"
          links={[
            {
              name: "Growing your clientele",
              href: "https://advisor.findfaye.com/community/AAAAAAAAFMM%3D",
            },
            {
              name: "Marketing templates",
              href: "https://advisor.findfaye.com/community/AAAAAAAACMc%3D",
            },
            {
              name: "Faye brand guidelines",
              href: "https://advisor.findfaye.com/community/AAAAAAAAFMI%3D",
            },
            {
              name: "Handling sales objections",
              href: "https://advisor.findfaye.com/community/AAAAAAAAFAA%3D",
            },
          ]}
        />
      </Box>
    </Box>
  );
};
