import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import ScrollContainer, {
  ScrollContainerHandle,
} from "../common/ScrollContainer";
import useIsMobile from "../hooks/useIsMobile";

interface Props {
  headerPanel?: ReactNode;
  // scroll references
  scrollEntries: React.ReactNode[];
  fetchScrollEntries?: () => void;
  scrollRef?: React.RefObject<ScrollContainerHandle>;
  hasMoreScrollEntries?: boolean;
  scrollToLatestOnChange?: boolean;
  aboveFooterPanel?: ReactNode;
  footerPanel?: ReactNode;
  setScrollPercentage?: (percentage: number) => void;
  sx?: React.CSSProperties;
  hasHeaderBorder?: boolean;
}

export default ({
  headerPanel,
  scrollEntries,
  fetchScrollEntries,
  scrollRef,
  hasMoreScrollEntries = false,
  scrollToLatestOnChange = false,
  aboveFooterPanel,
  footerPanel,
  setScrollPercentage,
  hasHeaderBorder = true,
  sx,
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        flexGrow: 1,
        "&::-webkit-scrollbar": {
          display: "none",
        },
        ...sx,
      }}
    >
      {headerPanel && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            background: "white",
            paddingBottom: "16px",
            borderBottom: hasHeaderBorder ? "1px solid #EAECF0" : "none",
            ...(!isMobile && { backgroundColor: "#fff" }),
          }}
        >
          {/* Fixes a match with the left panel. */}
          <Box sx={{ flexGrow: 1, minHeight: "46px" }}>{headerPanel}</Box>
        </Box>
      )}
      <ScrollContainer
        ref={scrollRef}
        scrollToLatestOnChange={scrollToLatestOnChange}
        sx={{
          minHeight: "50vh",
          padding: "0 16px 8px 24px",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // '.infinite-scroll-component': {
          //   gap: '16px',
          // }
        }}
        fetchMoreData={fetchScrollEntries ? fetchScrollEntries : () => {}}
        hasMore={hasMoreScrollEntries}
        setScrollPercentage={setScrollPercentage}
      >
        {/* Scrollable area */}
        {scrollEntries}
      </ScrollContainer>
      {aboveFooterPanel}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {/* Input area */}
        {footerPanel}
      </Box>
    </Box>
  );
};
