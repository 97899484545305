import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  buildUpdateRequest,
  EphemeralEvent,
  FormErrors,
  validateEvent,
  createDefaultEphemeralEvent,
} from "../../types/calendars";
import ReactiveDialog from "../common/ReactiveDialog";
import useIsMobile from "../hooks/useIsMobile";
import { useUpdateCalendarEvent, useGetCalendarEvent } from "services/calendar";
import EventForm from "./EventForm";
import { EventNotice } from "protogen/calendar_pb";
import { buildEphemeralEventFromCalendarEvent } from "../../types/calendars";
import useGetEmailAutocompleteEntries from "../email/useGetEmailAutocompleteEntries";

interface Props {
  eventRef: string;
  open: boolean;
  onClose: () => void;
  onEdited?: (t: EventNotice) => void;
}

export default ({ eventRef, open, onClose, onEdited }: Props) => {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralEvent>(
    createDefaultEphemeralEvent(),
  );
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { autocompleteEntries } = useGetEmailAutocompleteEntries({
    familyRef: "",
  });
  const { request, loading } = useUpdateCalendarEvent((r) => {
    if (onEdited) {
      onEdited(r.notice!);
    } else {
      navigate(`/datebook/${encodeURIComponent(r?.event?.ref!)}`);
    }
  });

  const { request: getEventRequest, data: getEventData } = useGetCalendarEvent(
    (r) => {
      if (r.event !== undefined) {
        const event: EphemeralEvent = buildEphemeralEventFromCalendarEvent(
          r.event,
        );
        setFormData(event);
      }
    },
  );

  useEffect(() => {
    if (open && eventRef !== "") {
      getEventRequest({ eventRef });
    }
  }, [open, eventRef]);

  const updateFormData = (t: EphemeralEvent) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...t,
    }));
  };

  const _validate = () => {
    const errors = validateEvent(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleEditEvent = () => {
    if (_validate()) {
      if (getEventData?.event) {
        const d = buildUpdateRequest(eventRef, getEventData?.event, formData);
        request(d);
      }
    }
  };

  if (!getEventData) {
    return <></>;
  }
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={"Edit Event"}
      primaryAction={handleEditEvent}
      primaryActionName={"Save"}
      noActionsFullScreen={true}
      unpad
      style={{
        width: "950px",
        maxWidth: "950px",
        padding: !isMobile ? "20px" : undefined,
      }}
    >
      <EventForm
        loading={loading}
        formData={formData}
        updateFormData={updateFormData}
        errors={formErrors}
        hideAction={isMobile}
        onSubmit={handleEditEvent}
        onClose={onClose}
        attendeeAutocomplete={autocompleteEntries}
        actionName="Save"
      />
    </ReactiveDialog>
  );
};
