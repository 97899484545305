import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Checkbox from "./Checkbox";
import { SxProps } from "@mui/system";
import Radio from "./Radio";

export type Option = {
  label: string;
  value: string;
  defaultChecked?: boolean;
  // When checked, all other selections will be cleared.
  // If checked and another check is selected, it will be cleared.
  mutuallyExclusive?: boolean;
};

type Props = {
  options: Option[];
  initial?: string[];
  onChange: (s: Option[]) => void;
  displayValue?: string;
  disabled?: boolean;
  maxDropdownHeight?: string;
  sx?: SxProps;
  radio?: boolean;
  noDecoration?: boolean;
};

export default ({
  options,
  initial,
  onChange,
  displayValue,
  maxDropdownHeight,
  disabled = false,
  radio = false,
  noDecoration = false,
  sx,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<string[]>(
    initial || options.filter((s) => s.defaultChecked).map((s) => s.value),
  );
  const optionsMap = new Map(options.map((o) => [o.value, o]));
  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    const updatedValues = typeof value === "string" ? value.split(",") : value;
    const newEntries = updatedValues.filter((v) => values.indexOf(v) < 0);
    let newValues: string[];
    if (
      newEntries.length === 1 &&
      optionsMap.get(newEntries[0])?.mutuallyExclusive
    ) {
      // Case where new value is an "all" case.
      newValues = newEntries;
    } else if (newEntries.length > 0) {
      // Case where we want to uncheck "all"
      newValues = updatedValues.filter(
        (v) => !optionsMap.get(v)?.mutuallyExclusive,
      );
    } else {
      // Case where we are unchecking something or otherwise don't care.
      newValues = updatedValues;
    }
    setValues(newValues);
    onChange(
      newValues.map((v) => optionsMap.get(v)).filter((v) => !!v) as Option[],
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ cursor: "pointer", ...sx }}>
      <Select
        disabled={disabled}
        value={values}
        multiple
        displayEmpty
        open={open}
        onClick={(e) => {
          // Check if the click is exactly on the element with the event listener
          if (e.target === e.currentTarget || radio) {
            setOpen(!open);
          }
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={(selected) => {
          let val =
            displayValue ||
            selected.map((s) => optionsMap.get(s)?.label || "").join(", ");
          return (
            <Typography component="span" variant="body">
              {val}
            </Typography>
          );
        }}
        label={displayValue}
        onChange={handleChange}
        sx={{
          cursor: "pointer",
          minHeight: "48px",
          fieldset: {
            borderRadius: "100px",
            border: "2px solid #EAEBEC !important",
          },
          ".MuiInputBase-input": {
            marginTop: "0px",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "8px",
              borderRadius: "12px",
              border: "2px solid #EAEBEC",
              background: "#FFF",
              display: "flex",
              padding: "19px 48px 19px 24px",
              flexDirection: "column",
              alignItems: "flex-start",
              boxShadow: "none",
              maxHeight: maxDropdownHeight || undefined,
              ul: {
                padding: 0,
                margin: 0,
                li: {
                  padding: 0,
                  marginBottom: "20px",
                  "&.Mui-selected, &:hover": {
                    backgroundColor: "transparent",
                  },
                  "&.Mui-selected, &:focus": {
                    backgroundColor: "transparent",
                  },
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                },
              },
            },
          },
        }}
        IconComponent={() => {
          return (
            <div
              style={{
                right: "20px",
                top: "16px",
                pointerEvents: "none",
                position: "absolute",
              }}
            >
              <ChevronDownIcon
                height="16px"
                width="16px"
                strokeWidth="3px"
                color="#8E9598"
              />
            </div>
          );
        }}
      >
        {options.map((o) => (
          <MenuItem
            key={o.value}
            value={o.value}
            disableRipple={true}
            disableTouchRipple={true}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              "&.Mui-selected:hover": { backgroundColor: "transparent" },
            }}
          >
            {!noDecoration &&
              (radio ? (
                <Radio checked={values.indexOf(o.value) > -1} />
              ) : (
                <Checkbox checked={values.indexOf(o.value) > -1} />
              ))}
            <ListItemText primary={o.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
