import useIsMobile from "../hooks/useIsMobile";
import LinkRouter from "../navigation/LinkRouter";
import TileSection from "./TileSection";
import {
  BookOpenCheck,
  HeartHandshake,
  MessageCircleQuestion,
  MessageSquare,
} from "lucide-react";
import React from "react";
import Panel, { CommonTileProps } from "./Panel";

export default () => {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <Panel
          title="We want your feedback!"
          content={
            <>
              If you have any thoughts, suggestions or wild ideas about Faye
              please share! Start a discussion with the community here or send
              the team a{" "}
              <LinkRouter
                to="/inbox/messages?recipientRefs=AAAAAAAAAAE="
                inline
              >
                <span
                  style={{
                    color: "#198282",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  direct message
                </span>
              </LinkRouter>
              .
            </>
          }
        />
      )}
      <TileSection
        tiles={[
          {
            title: "Ask FAs",
            icon: <MessageCircleQuestion {...CommonTileProps} />,
            path: "/community/ask-fas",
          },
          {
            title: "Recommendations",
            icon: <HeartHandshake {...CommonTileProps} />,
            path: "/community/recommendations",
          },
          {
            title: "Resources",
            icon: <BookOpenCheck {...CommonTileProps} />,
            path: "/community/resources",
          },
          {
            title: "Chatter",
            icon: <MessageSquare {...CommonTileProps} />,
            path: "/community/chatter",
          },
        ]}
      />
    </>
  );
};
