import { TaskState } from "protogen/tasks_pb";
import { ReactNode } from "react";
import { ReactComponent as CheckmarkIcon } from "../../icons/Member/Checkmark.svg";
import { ReactComponent as LoadingIcon } from "../../icons/Member/Loading.svg";
import { ClockIcon } from "@heroicons/react/24/outline";

interface State {
  value: TaskState;
  label: string;
  icon: ReactNode;
}

export const TASK_STATES: State[] = [
  {
    value: TaskState.OPEN,
    label: "Upcoming",
    icon: <ClockIcon height="24px" width="24px" stroke="#6092A8" />,
  },
  {
    value: TaskState.INPROGRESS,
    label: "In progress",
    icon: <LoadingIcon height="24px" width="24px" stroke="#6092A8" />,
  },
  {
    value: TaskState.COMPLETED,
    label: "Done",
    icon: <CheckmarkIcon height="24px" width="24px" />,
  },
];

export const getStateIcon = (state: TaskState) => {
  const stateObj = TASK_STATES.find((s) => s.value === state);
  return stateObj ? stateObj.icon : null;
};

export const getStateLabel = (state: TaskState) => {
  const stateObj = TASK_STATES.find((s) => s.value === state);
  return stateObj ? stateObj.label : null;
};
