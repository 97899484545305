import { ReactNode } from "react";
import { Drawer } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer/Drawer";
import { CSSObject, Theme, useTheme } from "@mui/material/styles";

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "1px solid #E8E8E8",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `83px`,
  borderRight: "1px solid #E8E8E8",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

interface DesktopDrawerProps extends DrawerProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  drawerWidth: number;
  children: ReactNode;
}

export default ({
  open,
  drawerWidth,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setOpen,
  ...otherProps
}: DesktopDrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer
      {...otherProps}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(theme, drawerWidth),
          "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
        }),
        ...(!open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
        // Hide the drawer on print.
        "@media print": {
          display: "none",
        },
      }}
      variant="permanent"
      anchor="left"
      open={open}
    >
      {children}
    </Drawer>
  );
};
