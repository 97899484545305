import { Box, Typography } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default ({
  specialty,
  onDelete,
}: {
  specialty: string;
  onDelete?: () => void;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        display: "flex",
        height: "48px",
        padding: "8px 24px",
        alignItems: "center",
        gap: "8px",
        color: "text.secondary",
        border: "none",
        cursor: onDelete ? "pointer" : "default",
        borderRadius: "100px",
        width: "fit-content",
      }}
    >
      <Typography variant="bodyHeavy" color="#616161" sx={{ fontWeight: 600 }}>
        {specialty}
      </Typography>
      {onDelete && (
        <XMarkIcon
          color="#616161"
          onClick={onDelete}
          style={{ marginLeft: "12px", height: "20px", width: "20px" }}
        />
      )}
    </Box>
  );
};
