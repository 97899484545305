import { EphemeralAdvisor, FormErrors, ExternalLink } from "./types";
import { checkEmail, checkPhone } from "../../common/userUtils";
import { AdvisorStatus } from "protogen/common_pb";

const defaultAdvisor = (): EphemeralAdvisor => ({
  sendIntroEmail: true,
  status: AdvisorStatus.ACTIVE,
});

const validateContact = (formData: EphemeralAdvisor): FormErrors => {
  const errors: FormErrors = {};
  if (!formData.personalPhone) {
    errors["personalPhone"] = "Required";
  }
  if (!checkPhone(formData.personalPhone || "")) {
    errors["personalPhone"] = "Invalid phone number";
  }
  if (!formData.personalEmail) {
    errors["personalEmail"] = "Required";
  }
  if (!checkEmail(formData.personalEmail || "")) {
    errors["personalEmail"] = "Invalid email address";
  }
  return errors;
};

const validateDetails = (formData: EphemeralAdvisor): FormErrors => {
  const errors: FormErrors = {};
  if (!formData.firstName) {
    errors["firstName"] = "Required";
  }
  if (!formData.lastName) {
    errors["lastName"] = "Required";
  }
  if (!formData.timezone) {
    errors["timezone"] = "Required";
  }
  return errors;
};

const validateAddress = (address: string): FormErrors => {
  const errors: FormErrors = {};
  if (!address) {
    errors["location"] = "Required";
  }
  return errors;
};

const validateForm = (formData: EphemeralAdvisor): FormErrors => {
  const errors: FormErrors = {
    ...validateContact(formData),
    ...validateDetails(formData),
  };
  return errors;
};

const validateExternalLinks = (links: ExternalLink[]): FormErrors => {
  const errors: FormErrors = {};
  links.forEach((link) => {
    if (link.name === "Instagram" && link.url) {
      if (!/^https:\/\/www\.instagram\.com\/[\w\.]+/.test(link.url)) {
        errors["externalLinksInstagram"] = "Invalid Instagram URL";
      }
    } else if (link.name === "LinkedIn" && link.url) {
      if (!/^https:\/\/www\.linkedin\.com\/in\/[\w\.]+/.test(link.url)) {
        errors["externalLinksLinkedIn"] = "Invalid LinkedIn URL";
      }
    }
  });
  return errors;
};

const validatePassword = (
  password: string,
  minLen: number = 8,
  maxLen: number = 20,
): FormErrors => {
  const errors: FormErrors = {};
  if (password.length < minLen || password.length > maxLen) {
    errors["password"] =
      `Password must be between ${minLen} and ${maxLen} characters.`;
  }

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors["password"] = "Password must contain at least one uppercase letter.";
  }

  // Check for at least one digit
  if (!/[0-9]/.test(password)) {
    errors["password"] = "Password must contain at least one digit.";
  }

  // Check for at least one special character (e.g., !@#$%^&*()_+-=[]{}|;:'",.<>/?)
  if (!/[!@#$%^&*()_+\-=\[\]{}|;:'",.<>\/?]/.test(password)) {
    errors["password"] =
      "Password must contain at least one special character.";
  }
  return errors;
};

export {
  defaultAdvisor,
  validateContact,
  validateDetails,
  validatePassword,
  validateForm,
  validateExternalLinks,
  validateAddress,
};
