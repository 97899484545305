import { Alert, AlertTitle } from "@mui/material";
import GridPage from "./layout/GridPage";

export default ({ name }: { name: string }) => {
  return (
    <GridPage>
      <Alert severity="warning">
        <AlertTitle>
          <i>{name}</i> Not Implemented
        </AlertTitle>
        Hold your horses, the engineer is busy right now.
      </Alert>
    </GridPage>
  );
};
