enum USER_NUX_TYPE {
  ADVISOR = "advisor",
  MEMBER = "member",
}

const ADVISOR_STEP_NAME_INDEX_MAPPING: { [key: string]: number } = {
  advisor_landing: 0,
  advisor_personal_details: 0,
  advisor_agreement: 1,
  advisor_contact_card: 2,
  advisor_public_profile: 3,
  advisor_finish: 4,
};

const ADVISOR_STEP_NAME_PATH_MAPPING: { [key: string]: string } = {
  advisor_start: "/welcome/landing",
  advisor_landing: "/welcome/landing",
  advisor_personal_details: "/welcome/personal-details",
  advisor_agreement: "/welcome/advisor-agreement",
  advisor_contact_card: "/welcome/contact-card",
  advisor_public_profile: "/welcome/public-profile",
  advisor_finish: "/welcome/finish",
};

const MEMBER_STEP_NAME_INDEX_MAPPING: { [key: string]: number } = {
  member_landing: 0,
  member_personal_details: 0,
  member_plan_details: 1,
  member_contacts: 2,
  member_tasks: 3,
  member_finish: 4,
};

const MEMBER_STEP_NAME_PATH_MAPPING: { [key: string]: string } = {
  member_landing: "/welcome/landing",
  member_personal_details: "/welcome/personal-details",
  member_plan_details: "/welcome/plan-details",
  member_contacts: "/welcome/contacts",
  member_tasks: "/welcome/tasks",
  member_finish: "/welcome/finish",
};

const ADVISOR_NUX_STEPS = [
  {
    label: "Personal contact info",
  },
  {
    label: "Advisor agreement",
  },
  {
    label: "Faye contact info",
  },
  {
    label: "Public profile",
  },
  {
    label: "Finished!",
  },
];

const MEMBER_NUX_STEPS = [
  {
    label: "Your info",
  },
  {
    label: "Plan details",
  },
  {
    label: "Contacts",
  },
  {
    label: "Tasks",
  },
  {
    label: "Finished!",
  },
];

export {
  USER_NUX_TYPE,
  ADVISOR_NUX_STEPS,
  MEMBER_NUX_STEPS,
  ADVISOR_STEP_NAME_INDEX_MAPPING,
  ADVISOR_STEP_NAME_PATH_MAPPING,
  MEMBER_STEP_NAME_INDEX_MAPPING,
  MEMBER_STEP_NAME_PATH_MAPPING,
};
