import useProtoMethod from "./useProtoMethod";
import { EditNoteRequest, EditNoteResponse } from "protogen/notes_service_pb";

const useEditNote = (callback?: (r: EditNoteResponse) => void) => {
  return useProtoMethod<EditNoteRequest, EditNoteResponse>(
    "EditNote",
    EditNoteResponse,
    { callback },
  );
};

export { useEditNote };
