import { CurrentUser } from "protogen/auth_pb";
import parsePhoneNumberFromString from "libphonenumber-js";

export const ROLE_ADVISOR = "advisor";

export const ROLE_ADMIN = "admin";
export const ROLE_SUPERADMIN = "superadmin";

const checkEmail = (email: string) => {
  return /^[A-Za-z0-9._%+-]+(?:[A-Za-z0-9-._]*[A-Za-z0-9])?@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(
    email,
  );
};

const cleanPhone = (phone: string) => {
  if (phone === "+" || phone === "+1") {
    return "";
  }
  return phone;
};

const checkPhone = (phone: string) => /^\+1\d{10}$/i.test(phone);

const checkInternationalPhone = (phoneNumber: string) => {
  if (!phoneNumber) {
    return false;
  }

  try {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    return phoneNumberObj?.isValid() ?? false;
  } catch (error) {
    return false;
  }
};

const hasOneOfRoles = (user: CurrentUser, roles: string[]) =>
  user.roles.filter((r) => roles.includes(r)).length > 0;

const hasRole = (user: CurrentUser, role: string) => user.roles.includes(role);

const isUserAdmin = (user: CurrentUser) => hasRole(user, ROLE_ADMIN);
const isUserAdvisor = (user: CurrentUser) => hasRole(user, ROLE_ADVISOR);
const isUserSuperadmin = (user: CurrentUser) => hasRole(user, ROLE_SUPERADMIN);

export {
  checkEmail,
  cleanPhone,
  checkPhone,
  checkInternationalPhone,
  isUserAdmin,
  isUserSuperadmin,
  isUserAdvisor,
  hasRole,
  hasOneOfRoles,
};
