import { useEffect, useState } from "react";
import {
  useCreateTaskInspirationCategory,
  useEditTaskInspirationCategory,
} from "services/tasks";

import {
  EphemeralTaskInspirationCategory,
  TaskFormErrors,
} from "../../types/tasks";
import { TaskInspirationCategory } from "protogen/tasks_pb";
import TaskInspirationCategoryForm from "./TaskInspirationCategoryForm";
import ReactiveDialog from "../common/ReactiveDialog";

type Props = {
  open: boolean;
  taskInspirationCategory: TaskInspirationCategory | null;
  taskInspirationCategories: TaskInspirationCategory[];
  onClose: () => void;
};

const defaultFormData = (): EphemeralTaskInspirationCategory => ({
  title: "",
  description: "",
  iconName: "",
});

const validateTaskInspirationCategory = (
  t: EphemeralTaskInspirationCategory,
  taskInspirationCategories: TaskInspirationCategory[],
): TaskFormErrors => {
  const errors: TaskFormErrors = {};
  if (!t.title) {
    errors.title = "Title is required";
  }

  const categoryExists = taskInspirationCategories.find((c) => {
    return c.title === t.title;
  });
  if (categoryExists) {
    errors.title = "Category title already exists";
  }

  return errors;
};

export default ({
  open,
  taskInspirationCategory,
  taskInspirationCategories,
  onClose,
}: Props) => {
  const [formErrors, setFormErrors] = useState<TaskFormErrors | null>(null);
  const [formData, setFormData] =
    useState<EphemeralTaskInspirationCategory>(defaultFormData());

  const { request: createCategoryRequest } = useCreateTaskInspirationCategory();
  const { request: editCategoryRequest } = useEditTaskInspirationCategory();

  useEffect(() => {
    if (!open) {
      setFormData(defaultFormData());
    } else if (taskInspirationCategory !== null) {
      setFormData({
        ref: taskInspirationCategory.ref,
        title: taskInspirationCategory.title,
        description: taskInspirationCategory.description,
        iconName: taskInspirationCategory.iconName,
      });
    }
  }, [open]);

  const updateFormData = (s: EphemeralTaskInspirationCategory) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const errors = validateTaskInspirationCategory(
      formData,
      taskInspirationCategories,
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (_validate()) {
      if (taskInspirationCategory !== null) {
        await editCategoryRequest({
          categoryRef: formData.ref!,
          title: formData.title || "",
          description: formData.description || "",
          iconName: formData.iconName || "",
        });
      } else {
        createCategoryRequest({
          title: formData.title || "",
          description: formData.description || "",
          iconName: formData.iconName || "",
        });
      }
      onClose();
    }
  };
  return (
    <ReactiveDialog
      title={
        taskInspirationCategory !== null
          ? "Edit task inspiration category"
          : "Add task inspiration category"
      }
      fullWidthSize="sm"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <TaskInspirationCategoryForm
        formData={formData}
        updateFormData={updateFormData}
        onSave={onSave}
        errors={formErrors}
      ></TaskInspirationCategoryForm>
    </ReactiveDialog>
  );
};
