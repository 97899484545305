import React, { useState } from "react";
import { Autocomplete, Box, Chip } from "@mui/material";
import TextField from "@mui/material/TextField";

type Props = {
  title: string;
  values: string[];
  setValues: (r: string[]) => void;
  disabled?: boolean;
  validator?: (v: string) => boolean;
};
export default ({
  title,
  values,
  setValues,
  disabled = false,
  validator,
}: Props) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [activeValue, setActiveValue] = useState("");

  const update = () => {
    if (activeValue.trim().length > 0) {
      setValues([...values, activeValue]);
      setActiveValue("");
    }
  };
  return (
    <Box
      ref={divRef}
      onClick={(e) => {
        if (e.target === divRef.current && inputRef.current) {
          inputRef.current.focus();
        }
      }}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
        cursor: "text",
      }}
    >
      <Autocomplete
        disabled={disabled}
        multiple
        clearIcon={false}
        fullWidth
        freeSolo
        options={[]} // Replace with your actual options
        value={values}
        inputValue={activeValue}
        onChange={(_, newValue) => setValues(newValue)}
        onInputChange={(_, newInputValue) => setActiveValue(newInputValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              {...(validator && !validator(option)
                ? {
                    color: "error",
                    variant: "outlined",
                  }
                : {})}
              onDelete={() => setValues(values.filter((_, i) => i !== index))}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={title}
            onBlur={() => {
              update();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent default Enter key behavior
                update();
              }
            }}
          />
        )}
      />
    </Box>
  );
};
