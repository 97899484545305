import { FileUpload } from "../creation/FileUploader";
import { Box, IconButton } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AttachmentDialog, {
  isSupportedAttachment,
} from "../common/AttachmentDialog";
import { useState } from "react";
import { Attachment } from "protogen/common_pb";
import { FileDown } from "lucide-react";
import { humanFileSize } from "../../common/utils";

export const downloadAttachment = async (attachment: Attachment) => {
  const response = await fetch(attachment.url!);
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = attachment.filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl); // Clean up
};

export default ({
  attachments,
  onDelete,
}: {
  attachments: FileUpload[];
  onDelete: (s: string) => void;
}) => {
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const onAttachmentClick = async (attachment: FileUpload) => {
    if (!attachment.attachment) return;
    if (isSupportedAttachment(attachment.attachment)) {
      // Handle document...
      setOpenAttachment(attachment.attachment);
    } else {
      await downloadAttachment(attachment.attachment);
    }
  };
  return (
    <>
      <Box display="flex" flexDirection="column" gap="8px">
        {attachments.map((attachment, i) => (
          <Box
            key={i}
            display="flex"
            flexDirection="row"
            onClick={async () => onAttachmentClick(attachment)}
            sx={(theme) => ({
              height: "54px",
              padding: "18px 35px 18px 20px",
              borderRadius: "8px",
              gap: "8px",
              border: `1px solid ${theme.palette.border}`,
              position: "relative",
              cursor: "pointer",
            })}
          >
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!attachment.attachment) return;
                await downloadAttachment(attachment.attachment!);
              }}
            >
              <FileDown height="24px" width="24px" />
            </IconButton>
            <div
              style={{
                fontSize: "14px",
                height: "24px",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                ...{ textWrap: "nowrap" },
              }}
            >
              <span style={{ fontWeight: 600 }}>{attachment.filename}</span>
              <span style={{ marginLeft: "5px", fontStyle: "italic" }}>
                ({humanFileSize(attachment.size)})
              </span>
            </div>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(attachment.filename);
              }}
              sx={{
                position: "absolute",
                right: "25px",
                top: "15px",
              }}
            >
              <XMarkIcon height="24px" width="24px" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
    </>
  );
};
