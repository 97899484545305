// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/engagement_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Family } from "./advisors_service_pb.ts";
import { Advisor, PlanUtilization } from "./common_pb.ts";

/**
 * @generated from message protogen.EngagementReport
 */
export class EngagementReport extends Message<EngagementReport> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: protogen.Advisor advisor = 2;
   */
  advisor?: Advisor;

  /**
   * @generated from field: protogen.EngagementReport.EngagementStats stats = 3;
   */
  stats?: EngagementReport_EngagementStats;

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 4;
   */
  planUtilization?: PlanUtilization;

  constructor(data?: PartialMessage<EngagementReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EngagementReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "advisor", kind: "message", T: Advisor },
    { no: 3, name: "stats", kind: "message", T: EngagementReport_EngagementStats },
    { no: 4, name: "planUtilization", kind: "message", T: PlanUtilization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EngagementReport {
    return new EngagementReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EngagementReport {
    return new EngagementReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EngagementReport {
    return new EngagementReport().fromJsonString(jsonString, options);
  }

  static equals(a: EngagementReport | PlainMessage<EngagementReport> | undefined, b: EngagementReport | PlainMessage<EngagementReport> | undefined): boolean {
    return proto3.util.equals(EngagementReport, a, b);
  }
}

/**
 * @generated from message protogen.EngagementReport.EngagementStats
 */
export class EngagementReport_EngagementStats extends Message<EngagementReport_EngagementStats> {
  /**
   * @generated from field: int32 tasksOpen = 1;
   */
  tasksOpen = 0;

  /**
   * @generated from field: int32 tasksCompleted = 2;
   */
  tasksCompleted = 0;

  /**
   * @generated from field: int64 tasksOldestOpenSec = 3;
   */
  tasksOldestOpenSec = protoInt64.zero;

  /**
   * @generated from field: int64 tasksAverageTimeToCloseSec = 4;
   */
  tasksAverageTimeToCloseSec = protoInt64.zero;

  /**
   * @generated from field: int64 tasksLatestActivitySec = 5;
   */
  tasksLatestActivitySec = protoInt64.zero;

  /**
   * @generated from field: int64 activityLatestCommunicationSec = 11;
   */
  activityLatestCommunicationSec = protoInt64.zero;

  /**
   * @generated from field: int32 activitySevenDayCount = 12;
   */
  activitySevenDayCount = 0;

  /**
   * @generated from field: int32 activityUnreadCount = 13;
   */
  activityUnreadCount = 0;

  /**
   * @generated from field: int32 activityUnreadEmailCount = 14;
   */
  activityUnreadEmailCount = 0;

  /**
   * @generated from field: int32 activityUnreadMessageCount = 15;
   */
  activityUnreadMessageCount = 0;

  /**
   * @generated from field: int32 activityTaskMessageCount = 16;
   */
  activityTaskMessageCount = 0;

  /**
   * @generated from field: float averageMonthlyHours = 21;
   */
  averageMonthlyHours = 0;

  constructor(data?: PartialMessage<EngagementReport_EngagementStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EngagementReport.EngagementStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasksOpen", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tasksCompleted", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tasksOldestOpenSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "tasksAverageTimeToCloseSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "tasksLatestActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "activityLatestCommunicationSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "activitySevenDayCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "activityUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "activityUnreadEmailCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "activityUnreadMessageCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "activityTaskMessageCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "averageMonthlyHours", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromJsonString(jsonString, options);
  }

  static equals(a: EngagementReport_EngagementStats | PlainMessage<EngagementReport_EngagementStats> | undefined, b: EngagementReport_EngagementStats | PlainMessage<EngagementReport_EngagementStats> | undefined): boolean {
    return proto3.util.equals(EngagementReport_EngagementStats, a, b);
  }
}

/**
 * @generated from message protogen.FetchEngagementReportsRequest
 */
export class FetchEngagementReportsRequest extends Message<FetchEngagementReportsRequest> {
  constructor(data?: PartialMessage<FetchEngagementReportsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEngagementReportsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEngagementReportsRequest | PlainMessage<FetchEngagementReportsRequest> | undefined, b: FetchEngagementReportsRequest | PlainMessage<FetchEngagementReportsRequest> | undefined): boolean {
    return proto3.util.equals(FetchEngagementReportsRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchEngagementReportsResponse
 */
export class FetchEngagementReportsResponse extends Message<FetchEngagementReportsResponse> {
  /**
   * @generated from field: repeated protogen.EngagementReport reports = 1;
   */
  reports: EngagementReport[] = [];

  constructor(data?: PartialMessage<FetchEngagementReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEngagementReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reports", kind: "message", T: EngagementReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEngagementReportsResponse | PlainMessage<FetchEngagementReportsResponse> | undefined, b: FetchEngagementReportsResponse | PlainMessage<FetchEngagementReportsResponse> | undefined): boolean {
    return proto3.util.equals(FetchEngagementReportsResponse, a, b);
  }
}

