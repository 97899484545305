import { AccountStub } from "protogen/common_pb";
import { Family } from "protogen/advisors_service_pb";
import useIsMobile from "../hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import { useAdminListAllAdvisors, useListAllFamilies } from "services/admin";
import { Box } from "@mui/material";
import CheckedDropDown from "../common/CheckedDropDown";
import { TIME_RANGES } from "../creation/TimeRangeSelect";

const statusOptions = () => {
  return [
    {
      label: "Any status",
      value: "",
      mutuallyExclusive: true,
    },
    {
      label: "Unpaid",
      value: "unpaid",
      mutuallyExclusive: true,
    },
    {
      label: "Pending",
      value: "pending",
      mutuallyExclusive: true,
    },
    {
      label: "Paid",
      value: "paid",
      mutuallyExclusive: true,
    },
  ];
};

const timeRangeToSecondsAgo = (timeRange: string) => {
  const daysAgo = (days: number) =>
    Math.floor(Date.now() / 1000) - days * 24 * 60 * 60;
  const monthsAgo = (months: number) =>
    Math.floor(Date.now() / 1000) - months * 30 * 24 * 60 * 60;

  // Parse the timeRange string into a tuple
  const parseTimeRange = (range: string): [string, number] | null => {
    const match = range.match(/(\w+)\(([-\d]+)\)/);
    return match ? [match[1], parseInt(match[2], 10)] : null;
  };

  const parsed = parseTimeRange(timeRange);

  if (!parsed) return null;

  const [unit, value] = parsed;

  switch (unit) {
    case "days":
      return daysAgo(-value);
    case "months":
      return monthsAgo(-value);
    default:
      return null;
  }
};

const advisorOptions = (advisors: AccountStub[]) => {
  const options = advisors
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((advisor: AccountStub) => ({
      label: advisor.displayName,
      value: advisor.ref,
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any advisor",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

const familyOptions = (families: Family[]) => {
  const options = families
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((family: Family) => ({
      label: family.name,
      value: family.ref || "",
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any family",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

export type InvoiceFilter = {
  statuses: string[] | null;
  createdSinceSeconds: number | null;
  familyRef: string | null;
  advisorRef: string | null;
};

export default ({
  loading,
  setFilter,
}: {
  loading: boolean;
  setFilter: (f: InvoiceFilter) => void;
}) => {
  const isMobile = useIsMobile();
  const [advisor, setAdvisor] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [timeRange, setTimeRange] = useState<string>("");
  const {
    request: advisorsRequest,
    data: advisorsData,
    loading: advisorLoading,
  } = useAdminListAllAdvisors();
  const {
    request: familiesRequest,
    data: familiesData,
    loading: familyLoading,
  } = useListAllFamilies();
  useEffect(() => {
    advisorsRequest();
    familiesRequest();
  }, []);
  useEffect(() => {
    setFilter({
      statuses: status ? [status] : null,
      createdSinceSeconds: timeRange ? timeRangeToSecondsAgo(timeRange) : null,
      familyRef: family || null,
      advisorRef: advisor || null,
    });
  }, [status, timeRange, family, advisor]);
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      sx={{ overflowX: "scroll" }}
      justifyContent={!isMobile ? "end" : undefined}
    >
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent="end"
        gap="10px"
        mb={isMobile ? "20px" : undefined}
      >
        <CheckedDropDown
          disabled={loading || advisorLoading}
          radio={true}
          options={advisorOptions(advisorsData?.advisors || [])}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (advisor &&
              advisorsData?.advisors.find((a) => a.ref === advisor)
                ?.displayName) ||
            "Advisor"
          }
          onChange={(f) => f.length === 1 && setAdvisor(f[0].value)}
        />
        <CheckedDropDown
          disabled={loading || familyLoading}
          radio={true}
          options={familyOptions(familiesData?.families || [])}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (family &&
              familiesData?.families.find((a) => a.ref === family)?.name) ||
            "Family"
          }
          onChange={(f) => f.length === 1 && setFamily(f[0].value)}
        />
        <CheckedDropDown
          disabled={loading}
          radio={true}
          options={Object.values(TIME_RANGES).map((pt) => ({
            label: pt.label,
            value: pt.value || "",
            mutuallyExclusive: true,
          }))}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          displayValue={
            Object.values(TIME_RANGES).find((t) => t.value === timeRange)
              ?.label || "Date"
          }
          onChange={(r) => r.length === 1 && setTimeRange(r[0].value || "")}
        />
        <CheckedDropDown
          disabled={loading}
          radio={true}
          options={statusOptions()}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (status &&
              statusOptions().find((s) => s.value === status)?.label) ||
            "Any Status"
          }
          onChange={(f) => f.length === 1 && setStatus(f[0].value)}
        />
      </Box>
    </Box>
  );
};
