import React, { useMemo } from "react";
import { Attachment } from "protogen/common_pb";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withImageProps } from "./SignedImageCache";
import DocViewer from "@cyntler/react-doc-viewer";
import useIsMobile from "../hooks/useIsMobile";

interface DocumentProp {
  uri: string;
  fileName: string;
  fileType: string;
}

interface MemoizedDocViewerProps {
  document: DocumentProp;
}

// DocViewer does not handle re-renders well when props change
// renders blank page.
const MemoizedDocViewer = React.memo(({ document }: MemoizedDocViewerProps) => {
  return <DocViewer prefetchMethod="GET" documents={[document]} />;
});

const isImage = (attachment: Attachment) => {
  return attachment.contentType.startsWith("image/");
};

const isDocument = (attachment: Attachment) => {
  const documentTypes = new Set([
    // Note: Pain to get their image renderer to work correctly.
    // "image/bmp",
    // "image/gif",
    // "image/jpg",
    // "image/jpeg",
    // "image/png",
    // "image/tiff",
    "application/vnd.oasis.opendocument.text",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/htm",
    "text/html",
    "text/plain",
    "video/mp4",
  ]);
  return documentTypes.has(attachment.contentType.toLowerCase());
};

export const isSupportedAttachment = (attachment: Attachment) => {
  return isImage(attachment) || isDocument(attachment);
};

const DocAttachment = ({ attachment }: { attachment: Attachment }) => {
  // Handle memoization and attachment parsing.
  const memoizedDocument = useMemo(() => {
    if (!attachment) return null;
    return {
      uri: attachment.url,
      fileName: attachment.filename,
      fileType: attachment.contentType,
    };
  }, [attachment]);
  if (!memoizedDocument) return null;
  return <MemoizedDocViewer document={memoizedDocument} />;
};

const ImageAttachment = ({
  attachment,
  isMobile,
}: {
  attachment: Attachment;
  isMobile: boolean;
}) => {
  const imageProps = withImageProps({ url: attachment.url! });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "transparent",
        padding: isMobile ? "0" : "20px",
      }}
    >
      <img
        {...imageProps}
        alt={attachment.filename}
        style={{
          maxWidth: isMobile ? "100%" : "80vw",
          maxHeight: isMobile ? "100%" : "80vh",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default ({
  attachment,
  onClose,
}: {
  attachment: Attachment | null;
  onClose: () => void;
}) => {
  // Hooks are always called at the top level of the component, even if no attachment is provided.
  const isMobile = useIsMobile();
  // Return null early if the attachment is invalid, but the hooks have already been called
  if (!attachment || !isSupportedAttachment(attachment)) {
    return null;
  }
  return (
    <Dialog
      open={!!attachment}
      onClose={onClose}
      fullScreen={isMobile} // Full screen on mobile
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : isImage(attachment) ? "auto" : "60vw",
          height: isMobile ? "100%" : isImage(attachment) ? "auto" : "80vh",
          maxWidth: "100%",
          maxHeight: "100%",
          position: "relative",
          borderRadius: isMobile ? 0 : "16px",
          padding: isMobile ? "10vh 10vw" : "35px",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {/* Close Button */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: "4px",
          backgroundColor: "#e5e5e5",
          zIndex: 10,
          color: "#000",
          "&:hover": {
            backgroundColor: "#e5e5e5",
          },
          ...(isMobile
            ? {
                top: 24,
                right: 24,
                color: "#000",
              }
            : {}),
        }}
      >
        <CloseIcon style={{ color: "black" }} />
      </IconButton>

      {/* Render Document */}
      {isDocument(attachment) && <DocAttachment attachment={attachment} />}
      {/* Render Image */}
      {isImage(attachment) && (
        <ImageAttachment attachment={attachment} isMobile={isMobile} />
      )}
    </Dialog>
  );
};
