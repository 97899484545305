import useProtoMethod from "./useProtoMethod";
import {
  CreateFactRequest,
  CreateFactResponse,
  DeleteFactRequest,
  DeleteFactResponse,
  EditFactRequest,
  EditFactResponse,
  CreateFactSectionRequest,
  CreateFactSectionResponse,
  EditFactSectionRequest,
  EditFactSectionResponse,
  DeleteFactSectionRequest,
  DeleteFactSectionResponse,
  GetFamilyFactSectionsRequest,
  GetFamilyFactSectionsResponse,
} from "protogen/facts_service_pb";

const useCreateFact = (callback?: (r: CreateFactResponse) => void) => {
  return useProtoMethod<CreateFactRequest, CreateFactResponse>(
    "CreateFact",
    CreateFactResponse,
    { callback },
  );
};

const useDeleteFact = (callback?: (r: DeleteFactResponse) => void) => {
  return useProtoMethod<DeleteFactRequest, DeleteFactResponse>(
    "DeleteFact",
    DeleteFactResponse,
    { callback },
  );
};

const useEditFact = (callback?: (r: EditFactResponse) => void) => {
  return useProtoMethod<EditFactRequest, EditFactResponse>(
    "EditFact",
    EditFactResponse,
    { callback },
  );
};

const useCreateFactSection = (
  callback?: (r: CreateFactSectionResponse) => void,
) => {
  return useProtoMethod<CreateFactSectionRequest, CreateFactSectionResponse>(
    "CreateFactSection",
    CreateFactSectionResponse,
    { callback },
  );
};

const useEditFactSection = (
  callback?: (r: EditFactSectionResponse) => void,
) => {
  return useProtoMethod<EditFactSectionRequest, EditFactSectionResponse>(
    "EditFactSection",
    EditFactSectionResponse,
    { callback },
  );
};

const useDeleteFactSection = (
  callback?: (r: DeleteFactSectionResponse) => void,
) => {
  return useProtoMethod<DeleteFactSectionRequest, DeleteFactSectionResponse>(
    "DeleteFactSection",
    DeleteFactSectionResponse,
    { callback },
  );
};

const useGetFamilyFactSections = (
  callback?: (r: GetFamilyFactSectionsResponse) => void,
) => {
  return useProtoMethod<
    GetFamilyFactSectionsRequest,
    GetFamilyFactSectionsResponse
  >("GetFamilyFactSections", GetFamilyFactSectionsResponse, { callback });
};

export {
  useCreateFact,
  useDeleteFact,
  useEditFact,
  useCreateFactSection,
  useEditFactSection,
  useDeleteFactSection,
  useGetFamilyFactSections,
};
