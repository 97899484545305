import ReactiveDialog from "../common/ReactiveDialog";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import PhoneInput from "./PhoneInput";
import { cleanPhone } from "../../common/utils";
import { checkInternationalPhone } from "../../common/userUtils";
import { useNavigate } from "react-router-dom";
import { useStartExternalConversation } from "services/advisor";
import { ShellCompositionSection } from "../activity/TextMessageCompose";
import { PlusIcon } from "@heroicons/react/24/outline";
import useIsMobile from "../hooks/useIsMobile";

type ConversationStarterProps = {
  onCompletion?: () => void;
  setEnabled?: (enabled: boolean) => void;
  initialPhoneNumber?: string | null;
  initialPhoneDisplayName?: string | null;
  initialPhoneFirstName?: string | null;
  initialPhoneLastName?: string | null;
};
type ConversationStarterHandle = {
  startConversation: () => void;
};

export const ConversationStarter = forwardRef<
  ConversationStarterHandle,
  ConversationStarterProps
>(
  (
    {
      onCompletion,
      setEnabled,
      initialPhoneNumber,
      ...props
    }: ConversationStarterProps,
    ref,
  ) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const { request, loading, error } = useStartExternalConversation();
    const [phoneNumber, setPhoneNumber] = useState<string | null>(
      initialPhoneNumber || null,
    );
    const onStart = async () => {
      if (loading || !phoneNumber) {
        return false;
      }
      const resp = await request({
        phoneNumber: phoneNumber,
        displayName: props.initialPhoneDisplayName || "",
        firstName: props.initialPhoneFirstName || "",
        lastName: props.initialPhoneLastName || "",
      });
      if (resp?.conversationRef) {
        onCompletion && onCompletion();
        navigate(`/inbox/phone/${encodeURIComponent(resp.conversationRef)}`);
        return true;
      }
      return false;
    };

    useImperativeHandle(ref, () => ({
      startConversation: onStart,
    }));

    const enabled =
      !loading && !!(phoneNumber && checkInternationalPhone(phoneNumber));
    useEffect(() => {
      setEnabled && setEnabled(enabled);
    }, [enabled]);
    useEffect(() => {
      // Handle /inbox/phone/create?phone=+15431234567
      if (phoneNumber) {
        onStart();
      }
    }, []);
    return (
      <Box
        display="flex"
        flexDirection="column"
        paddingTop="20px"
        gap="20px"
        height="100%"
        justifyContent={"space-between"}
      >
        <Box display="flex" flexDirection="column" gap={"20px"}>
          <Box display={"flex"} flexDirection={"row"} gap={"12px"}>
            <PhoneInput
              autoFocus={true}
              disableDropdown={false}
              value={phoneNumber}
              onChange={(phone) =>
                setPhoneNumber(
                  cleanPhone(
                    typeof phone === "string" ? phone : phone.target.value,
                  ),
                )
              }
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  phoneNumber &&
                  checkInternationalPhone(phoneNumber)
                ) {
                  onStart();
                }
              }}
              onBlur={() => {
                if (phoneNumber && checkInternationalPhone(phoneNumber)) {
                  onStart();
                }
              }}
              label="Phone number"
              fullWidth
              size="small"
              sx={{ marginTop: "8px", marginBottom: "4px" }}
              autoComplete="off"
              disabled={loading}
            />
            {/* if desktop, create action! */}
            {!isMobile && (
              <Button
                disabled={
                  !(phoneNumber && checkInternationalPhone(phoneNumber))
                }
                sx={{ minWidth: "150px", alignSelf: "center" }}
                onClick={() => {
                  onStart();
                }}
              >
                Start thread
              </Button>
            )}
          </Box>
          {error && (
            <Box>
              <Alert severity="error">
                <AlertTitle>Error starting conversation</AlertTitle>
                If the phone number is already associated with a family start
                the conversation from the family page.
              </Alert>
            </Box>
          )}
        </Box>
        <ShellCompositionSection
          onFocus={onStart}
          placeholder={"Send message"}
          disabled={!enabled}
        />
      </Box>
    );
  },
);

interface Props {
  open: boolean;
  onClose: () => void;
}

export default ({ open, onClose }: Props) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const ref = useRef<ConversationStarterHandle>(null);
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title="New Conversation"
      unpad={true}
      primaryActionEnabled={enabled}
      primaryAction={() => ref.current?.startConversation()}
      primaryActionName={<PlusIcon height={24} width={24} stroke="white" />}
    >
      <ConversationStarter
        ref={ref}
        onCompletion={onClose}
        setEnabled={setEnabled}
      />
    </ReactiveDialog>
  );
};
