import { DragEvent, ReactNode, useState } from "react";

type Props = {
  onUpload: (files: FileList) => void;
  hoverColor?: string | null;
  setHover?: (hover: boolean) => void;
  children: ReactNode;
  enabled?: boolean;
};

export default ({
  onUpload,
  children,
  setHover,
  hoverColor = "#e8f4f8",
  enabled = true, // ...otherProps
}: Props) => {
  const [dragActive, setDragActive] = useState(false);
  const setDrag = (drag: boolean) => {
    setHover && setHover(drag);
    setDragActive(drag);
  };

  // handle drag events
  const handleDrag = (e: DragEvent) => {
    if (!e.dataTransfer.types.includes("Files")) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrag(true);
    } else if (e.type === "dragleave") {
      setDrag(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent) => {
    if (!e.dataTransfer.types.includes("Files")) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
      onUpload(e.dataTransfer.files);
    }
  };
  if (!enabled) {
    return <div>{children}</div>;
  }

  return (
    <div
      onDragEnter={handleDrag}
      style={{
        width: "100%",
        position: "relative",
        ...(dragActive && hoverColor
          ? {
              backgroundColor: hoverColor,
            }
          : {}),
      }}
    >
      {children}
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "1rem",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          }}
        ></div>
      )}
    </div>
  );
};
