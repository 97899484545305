import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdvisorLogin from "./pages/AdvisorLogin";
import AdvisorNavigationLayout from "./components/layout/AdvisorNavigationLayout";
import Families from "./pages/Families";
import NotImplemented from "./components/NotImplemented";
import Family from "./pages/Family";
import FamilyInvitation from "./pages/FamilyInvitation";
import Settings from "./pages/Settings";
import Tools from "./pages/tools/Tools";
import UnassignedEmails from "./pages/tools/UnassignedEmails";
import PhoneCall from "./pages/PhoneCall";
import StatMeThat from "./pages/StatMeThat";
import Tasks from "./pages/Tasks";
import Task from "./pages/Task";
import AdvisorAdminDirectory from "./pages/tools/AdvisorAdministration";
import FamilyDirectory from "./pages/tools/FamilyDirectory";
import ManageRoles from "./pages/tools/ManageRoles";
import SetupPassword from "./pages/SetupPassword";
import ResetPassword from "./pages/ResetPassword";
import AuditLog from "./pages/tools/AuditLog";
import ForumPost from "./pages/ForumPost";
import Inbox from "./pages/Inbox";
import Calendar from "./pages/Calendar";
import FeatureSwitches from "./pages/tools/FeatureSwitches";
import Impersonate from "./pages/tools/Impersonate";
import TokenRedirect from "./components/common/TokenRedirect";
import Reports from "./pages/tools/Reports";
import FamilyNotifications from "./pages/tools/FamilyNotifications";
import AdvisorInternalProfile from "./pages/AdvisorInternalProfile";
import ForumTopic from "./pages/ForumPage";
import NotFound from "./components/NotFound";
import { TopicFeed } from "./components/forum/constants";
import Search from "./pages/Search";
import HttpErrorProvider from "./components/context/HttpErrorProvider";
import NuxContextProvider from "./components/context/AdvisorNuxContext";
import Landing from "./pages/nux/advisor/Landing";
import PersonalDetails from "./pages/nux/advisor/PersonalDetails";
import AdvisorAgreement from "./pages/nux/advisor/AdvisorAgreement";
import ContactCard from "./pages/nux/advisor/ContactCard";
import PublicProfile from "./pages/nux/advisor/PublicProfile";
import Finish from "./pages/nux/advisor/Finish";
import NuxWrapper from "./pages/nux/NuxWrapper";
import AdvisorDirectory from "./pages/tools/AdvisorDirectory";
import { USER_NUX_TYPE } from "./components/nux/constants";
import AdvisorPayments from "./pages/tools/AdvisorPayments";
import CalendarURLs from "./pages/tools/CalendarURLs";
import UrlShortener from "./pages/tools/UrlShortener";
import TimeUtilization from "./pages/tools/TimeUtilization";
import FamilyEngagement from "./pages/tools/FamilyEngagement";
import TaskInspiration from "./pages/tools/TaskInspiration";
import TaskDiscovery from "./pages/TaskDiscovery";

const ADVISOR_SITE_TITLE = "Advisor";

const NUX_ROUTES = [
  { path: "/welcome/landing", component: Landing },
  { path: "/welcome/personal-details", component: PersonalDetails },
  { path: "/welcome/advisor-agreement", component: AdvisorAgreement },
  { path: "/welcome/contact-card", component: ContactCard },
  { path: "/welcome/public-profile", component: PublicProfile },
  { path: "/welcome/finish", component: Finish },
];

export default () => {
  useEffect(() => {
    document.title = ADVISOR_SITE_TITLE;
  }, []);
  return (
    <Routes>
      <Route path={"/login"} element={<AdvisorLogin />} />
      <Route path={"/forgot-password"} element={<ResetPassword />} />
      <Route path={"/token-login/:token?"} element={<TokenRedirect />} />
      <Route
        path={"/setup-account/:token?"}
        element={<SetupPassword isAccountSetup={true} />}
      />
      <Route
        path={"/reset-password/:token?"}
        element={<SetupPassword isAccountSetup={false} />}
      />
      <Route
        path={"/welcome"}
        element={
          <HttpErrorProvider>
            <NuxContextProvider userType={USER_NUX_TYPE.ADVISOR}>
              <NuxWrapper userType={USER_NUX_TYPE.ADVISOR} />
            </NuxContextProvider>
          </HttpErrorProvider>
        }
      >
        {NUX_ROUTES.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route path="/" element={<AdvisorNavigationLayout />}>
        {/* Do we want this? */}
        <Route index element={<ForumTopic treatment={TopicFeed.Home} />} />
        <Route
          path={"/forum"}
          element={<ForumTopic treatment={TopicFeed.Home} />}
        />
        <Route path={"/forum/:postRef"} element={<ForumPost />} />
        <Route
          path={"/community"}
          element={<ForumTopic treatment={TopicFeed.Home} />}
        />
        {(
          [
            ["ask-fas", TopicFeed.AskFAs],
            ["resources", TopicFeed.Resources],
            ["chatter", TopicFeed.Chatter],
            ["recommendations", TopicFeed.Recommendations],
          ] as [string, TopicFeed][]
        ).map((f) => (
          <Route
            key={f[0]}
            path={`/community/${f[0]}`}
            element={<ForumTopic treatment={f[1]} />}
          />
        ))}
        <Route
          path={"/community/topics/:topicQuery"}
          element={<ForumTopic />}
        />
        <Route path={"/community/:postRef"} element={<ForumPost />} />
        <Route path={"/search"} element={<Search />} />
        <Route path={"/families"} element={<Families />} />
        <Route path={"/families/:familyRef"} element={<Family />} />
        <Route
          path={"/families-invitation/:familyRef"}
          element={<FamilyInvitation />}
        />
        <Route path={"/calls/:callRef"} element={<PhoneCall />} />
        <Route path={"/tasks"} element={<Tasks />} />
        <Route path={"/tasks/add"} element={<Task addTask={true} />} />
        <Route path={"/tasks/:taskRef"} element={<Task />} />
        <Route
          path={"/task-discovery"}
          element={<TaskDiscovery accountType="advisor" />}
        />
        <Route path={"/datebook/:eventRef?"} element={<Calendar />} />
        <Route
          path={"/inbox/:inboxType?/create"}
          element={<Inbox create={true} />}
        />
        <Route path={"/inbox/:inboxType?/:ref?"} element={<Inbox />} />
        <Route
          path={"/playbooks"}
          element={<NotImplemented name={"Playbooks"} />}
        />
        <Route path={"/tools"} element={<Tools />} />
        <Route
          path={"/tools/advisor-administration"}
          element={<AdvisorAdminDirectory />}
        />
        <Route
          path={"/tools/advisor-directory"}
          element={<AdvisorDirectory />}
        />
        <Route path={"/tools/audit-log"} element={<AuditLog />} />
        <Route path={"/tools/family-directory"} element={<FamilyDirectory />} />
        <Route path={"/tools/impersonate-user"} element={<Impersonate />} />
        <Route path={"/tools/manage-roles"} element={<ManageRoles />} />
        <Route
          path={"/tools/family-engagement"}
          element={<FamilyEngagement />}
        />
        <Route path={"/tools/feature-switches"} element={<FeatureSwitches />} />
        <Route path={"/tools/url-shortener"} element={<UrlShortener />} />
        <Route
          path={"/tools/unassigned-emails"}
          element={<UnassignedEmails />}
        />
        <Route path={"/tools/time-utilization"} element={<TimeUtilization />} />
        <Route path={"/tools/task-inspiration"} element={<TaskInspiration />} />
        <Route
          path={"/tools/family-notifications"}
          element={<FamilyNotifications />}
        />
        <Route path={"/tools/reports/:reportName?"} element={<Reports />} />
        <Route path={"/tools/advisor-payments"} element={<AdvisorPayments />} />
        <Route path={"/tools/export-calendars"} element={<CalendarURLs />} />
        <Route path={"/settings"} element={<Settings />} />
        <Route
          path={"/advisor/:advisorRef"}
          element={<AdvisorInternalProfile />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path={"/statmethat"} element={<StatMeThat />} />
    </Routes>
  );
};
