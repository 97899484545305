// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/email_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { EmailMessage } from "./conversation_pb.ts";
import { FamilyStub, UploadAttachment } from "./common_pb.ts";
import { CalendarEvent } from "./calendar_pb.ts";

/**
 * @generated from message protogen.AutocompleteEntry
 */
export class AutocompleteEntry extends Message<AutocompleteEntry> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string entityType = 3;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 4;
   */
  entityRef = "";

  constructor(data?: PartialMessage<AutocompleteEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AutocompleteEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteEntry {
    return new AutocompleteEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteEntry {
    return new AutocompleteEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteEntry {
    return new AutocompleteEntry().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteEntry | PlainMessage<AutocompleteEntry> | undefined, b: AutocompleteEntry | PlainMessage<AutocompleteEntry> | undefined): boolean {
    return proto3.util.equals(AutocompleteEntry, a, b);
  }
}

/**
 * @generated from message protogen.FetchUnassignedEmailsRequest
 */
export class FetchUnassignedEmailsRequest extends Message<FetchUnassignedEmailsRequest> {
  /**
   * @generated from field: string cursor = 1;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchUnassignedEmailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchUnassignedEmailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchUnassignedEmailsRequest {
    return new FetchUnassignedEmailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsRequest {
    return new FetchUnassignedEmailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsRequest {
    return new FetchUnassignedEmailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchUnassignedEmailsRequest | PlainMessage<FetchUnassignedEmailsRequest> | undefined, b: FetchUnassignedEmailsRequest | PlainMessage<FetchUnassignedEmailsRequest> | undefined): boolean {
    return proto3.util.equals(FetchUnassignedEmailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchUnassignedEmailsResponse
 */
export class FetchUnassignedEmailsResponse extends Message<FetchUnassignedEmailsResponse> {
  /**
   * @generated from field: repeated protogen.FetchUnassignedEmailsResponse.UnassignedEmail emails = 1;
   */
  emails: FetchUnassignedEmailsResponse_UnassignedEmail[] = [];

  /**
   * @generated from field: string cursor = 2;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchUnassignedEmailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchUnassignedEmailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emails", kind: "message", T: FetchUnassignedEmailsResponse_UnassignedEmail, repeated: true },
    { no: 2, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchUnassignedEmailsResponse {
    return new FetchUnassignedEmailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsResponse {
    return new FetchUnassignedEmailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsResponse {
    return new FetchUnassignedEmailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchUnassignedEmailsResponse | PlainMessage<FetchUnassignedEmailsResponse> | undefined, b: FetchUnassignedEmailsResponse | PlainMessage<FetchUnassignedEmailsResponse> | undefined): boolean {
    return proto3.util.equals(FetchUnassignedEmailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchUnassignedEmailsResponse.UnassignedEmail
 */
export class FetchUnassignedEmailsResponse_UnassignedEmail extends Message<FetchUnassignedEmailsResponse_UnassignedEmail> {
  /**
   * @generated from field: protogen.EmailMessage email = 1;
   */
  email?: EmailMessage;

  /**
   * @generated from field: string fromAddress = 2;
   */
  fromAddress = "";

  /**
   * @generated from field: string toAddress = 3;
   */
  toAddress = "";

  /**
   * @generated from field: string familyRef = 4;
   */
  familyRef = "";

  constructor(data?: PartialMessage<FetchUnassignedEmailsResponse_UnassignedEmail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchUnassignedEmailsResponse.UnassignedEmail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "message", T: EmailMessage },
    { no: 2, name: "fromAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "toAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchUnassignedEmailsResponse_UnassignedEmail {
    return new FetchUnassignedEmailsResponse_UnassignedEmail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsResponse_UnassignedEmail {
    return new FetchUnassignedEmailsResponse_UnassignedEmail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchUnassignedEmailsResponse_UnassignedEmail {
    return new FetchUnassignedEmailsResponse_UnassignedEmail().fromJsonString(jsonString, options);
  }

  static equals(a: FetchUnassignedEmailsResponse_UnassignedEmail | PlainMessage<FetchUnassignedEmailsResponse_UnassignedEmail> | undefined, b: FetchUnassignedEmailsResponse_UnassignedEmail | PlainMessage<FetchUnassignedEmailsResponse_UnassignedEmail> | undefined): boolean {
    return proto3.util.equals(FetchUnassignedEmailsResponse_UnassignedEmail, a, b);
  }
}

/**
 * @generated from message protogen.AssignEmailToInboxRequest
 */
export class AssignEmailToInboxRequest extends Message<AssignEmailToInboxRequest> {
  /**
   * @generated from field: string emailRef = 1;
   */
  emailRef = "";

  /**
   * @generated from field: string inboxType = 2;
   */
  inboxType = "";

  /**
   * @generated from field: string inboxEntityRef = 3;
   */
  inboxEntityRef = "";

  constructor(data?: PartialMessage<AssignEmailToInboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AssignEmailToInboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emailRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "inboxType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "inboxEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssignEmailToInboxRequest {
    return new AssignEmailToInboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssignEmailToInboxRequest {
    return new AssignEmailToInboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssignEmailToInboxRequest {
    return new AssignEmailToInboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssignEmailToInboxRequest | PlainMessage<AssignEmailToInboxRequest> | undefined, b: AssignEmailToInboxRequest | PlainMessage<AssignEmailToInboxRequest> | undefined): boolean {
    return proto3.util.equals(AssignEmailToInboxRequest, a, b);
  }
}

/**
 * @generated from message protogen.AssignEmailToInboxResponse
 */
export class AssignEmailToInboxResponse extends Message<AssignEmailToInboxResponse> {
  /**
   * @generated from field: protogen.EmailMessage email = 1;
   */
  email?: EmailMessage;

  constructor(data?: PartialMessage<AssignEmailToInboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AssignEmailToInboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "message", T: EmailMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssignEmailToInboxResponse {
    return new AssignEmailToInboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssignEmailToInboxResponse {
    return new AssignEmailToInboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssignEmailToInboxResponse {
    return new AssignEmailToInboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssignEmailToInboxResponse | PlainMessage<AssignEmailToInboxResponse> | undefined, b: AssignEmailToInboxResponse | PlainMessage<AssignEmailToInboxResponse> | undefined): boolean {
    return proto3.util.equals(AssignEmailToInboxResponse, a, b);
  }
}

/**
 * @generated from message protogen.EmailDraft
 */
export class EmailDraft extends Message<EmailDraft> {
  /**
   * One of these two must be true.
   *
   * @generated from field: string replyToRef = 1;
   */
  replyToRef = "";

  /**
   * @generated from field: string subject = 2;
   */
  subject = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: string htmlContent = 4;
   */
  htmlContent = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 5;
   */
  attachments: UploadAttachment[] = [];

  /**
   * @generated from field: repeated string toAddresses = 6;
   */
  toAddresses: string[] = [];

  /**
   * @generated from field: repeated string ccAddresses = 7;
   */
  ccAddresses: string[] = [];

  /**
   * @generated from field: repeated string bccAddresses = 8;
   */
  bccAddresses: string[] = [];

  /**
   * @generated from field: bool isForwarded = 9;
   */
  isForwarded = false;

  constructor(data?: PartialMessage<EmailDraft>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EmailDraft";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "replyToRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "htmlContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
    { no: 6, name: "toAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "ccAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "bccAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "isForwarded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmailDraft {
    return new EmailDraft().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmailDraft {
    return new EmailDraft().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmailDraft {
    return new EmailDraft().fromJsonString(jsonString, options);
  }

  static equals(a: EmailDraft | PlainMessage<EmailDraft> | undefined, b: EmailDraft | PlainMessage<EmailDraft> | undefined): boolean {
    return proto3.util.equals(EmailDraft, a, b);
  }
}

/**
 * @generated from message protogen.GetEmailDraftRequest
 */
export class GetEmailDraftRequest extends Message<GetEmailDraftRequest> {
  /**
   * @generated from field: string draftRef = 1;
   */
  draftRef = "";

  constructor(data?: PartialMessage<GetEmailDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetEmailDraftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEmailDraftRequest {
    return new GetEmailDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEmailDraftRequest {
    return new GetEmailDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEmailDraftRequest {
    return new GetEmailDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEmailDraftRequest | PlainMessage<GetEmailDraftRequest> | undefined, b: GetEmailDraftRequest | PlainMessage<GetEmailDraftRequest> | undefined): boolean {
    return proto3.util.equals(GetEmailDraftRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetEmailDraftResponse
 */
export class GetEmailDraftResponse extends Message<GetEmailDraftResponse> {
  /**
   * @generated from field: protogen.EmailMessage email = 2;
   */
  email?: EmailMessage;

  constructor(data?: PartialMessage<GetEmailDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetEmailDraftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "email", kind: "message", T: EmailMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEmailDraftResponse {
    return new GetEmailDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEmailDraftResponse {
    return new GetEmailDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEmailDraftResponse {
    return new GetEmailDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEmailDraftResponse | PlainMessage<GetEmailDraftResponse> | undefined, b: GetEmailDraftResponse | PlainMessage<GetEmailDraftResponse> | undefined): boolean {
    return proto3.util.equals(GetEmailDraftResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateOrUpdateEmailDraftRequest
 */
export class CreateOrUpdateEmailDraftRequest extends Message<CreateOrUpdateEmailDraftRequest> {
  /**
   * @generated from field: string draftRef = 1;
   */
  draftRef = "";

  /**
   * @generated from field: protogen.EmailDraft draft = 2;
   */
  draft?: EmailDraft;

  constructor(data?: PartialMessage<CreateOrUpdateEmailDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateOrUpdateEmailDraftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "draft", kind: "message", T: EmailDraft },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrUpdateEmailDraftRequest {
    return new CreateOrUpdateEmailDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrUpdateEmailDraftRequest {
    return new CreateOrUpdateEmailDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrUpdateEmailDraftRequest {
    return new CreateOrUpdateEmailDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrUpdateEmailDraftRequest | PlainMessage<CreateOrUpdateEmailDraftRequest> | undefined, b: CreateOrUpdateEmailDraftRequest | PlainMessage<CreateOrUpdateEmailDraftRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrUpdateEmailDraftRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateOrUpdateEmailDraftResponse
 */
export class CreateOrUpdateEmailDraftResponse extends Message<CreateOrUpdateEmailDraftResponse> {
  /**
   * @generated from field: string draftRef = 1;
   */
  draftRef = "";

  /**
   * @generated from field: protogen.EmailMessage email = 2;
   */
  email?: EmailMessage;

  constructor(data?: PartialMessage<CreateOrUpdateEmailDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateOrUpdateEmailDraftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "message", T: EmailMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrUpdateEmailDraftResponse {
    return new CreateOrUpdateEmailDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrUpdateEmailDraftResponse {
    return new CreateOrUpdateEmailDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrUpdateEmailDraftResponse {
    return new CreateOrUpdateEmailDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrUpdateEmailDraftResponse | PlainMessage<CreateOrUpdateEmailDraftResponse> | undefined, b: CreateOrUpdateEmailDraftResponse | PlainMessage<CreateOrUpdateEmailDraftResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrUpdateEmailDraftResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteEmailDraftRequest
 */
export class DeleteEmailDraftRequest extends Message<DeleteEmailDraftRequest> {
  /**
   * @generated from field: string draftRef = 1;
   */
  draftRef = "";

  constructor(data?: PartialMessage<DeleteEmailDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteEmailDraftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEmailDraftRequest {
    return new DeleteEmailDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEmailDraftRequest {
    return new DeleteEmailDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEmailDraftRequest {
    return new DeleteEmailDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEmailDraftRequest | PlainMessage<DeleteEmailDraftRequest> | undefined, b: DeleteEmailDraftRequest | PlainMessage<DeleteEmailDraftRequest> | undefined): boolean {
    return proto3.util.equals(DeleteEmailDraftRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteEmailDraftResponse
 */
export class DeleteEmailDraftResponse extends Message<DeleteEmailDraftResponse> {
  constructor(data?: PartialMessage<DeleteEmailDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteEmailDraftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEmailDraftResponse {
    return new DeleteEmailDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEmailDraftResponse {
    return new DeleteEmailDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEmailDraftResponse {
    return new DeleteEmailDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEmailDraftResponse | PlainMessage<DeleteEmailDraftResponse> | undefined, b: DeleteEmailDraftResponse | PlainMessage<DeleteEmailDraftResponse> | undefined): boolean {
    return proto3.util.equals(DeleteEmailDraftResponse, a, b);
  }
}

/**
 * @generated from message protogen.SendEmailDraftRequest
 */
export class SendEmailDraftRequest extends Message<SendEmailDraftRequest> {
  /**
   * @generated from field: string draftRef = 1;
   */
  draftRef = "";

  constructor(data?: PartialMessage<SendEmailDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendEmailDraftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendEmailDraftRequest {
    return new SendEmailDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendEmailDraftRequest {
    return new SendEmailDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendEmailDraftRequest {
    return new SendEmailDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendEmailDraftRequest | PlainMessage<SendEmailDraftRequest> | undefined, b: SendEmailDraftRequest | PlainMessage<SendEmailDraftRequest> | undefined): boolean {
    return proto3.util.equals(SendEmailDraftRequest, a, b);
  }
}

/**
 * @generated from message protogen.SendEmailDraftResponse
 */
export class SendEmailDraftResponse extends Message<SendEmailDraftResponse> {
  /**
   * @generated from field: protogen.EmailMessage email = 1;
   */
  email?: EmailMessage;

  constructor(data?: PartialMessage<SendEmailDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendEmailDraftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "message", T: EmailMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendEmailDraftResponse {
    return new SendEmailDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendEmailDraftResponse {
    return new SendEmailDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendEmailDraftResponse {
    return new SendEmailDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendEmailDraftResponse | PlainMessage<SendEmailDraftResponse> | undefined, b: SendEmailDraftResponse | PlainMessage<SendEmailDraftResponse> | undefined): boolean {
    return proto3.util.equals(SendEmailDraftResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetEmailThreadRequest
 */
export class GetEmailThreadRequest extends Message<GetEmailThreadRequest> {
  /**
   * @generated from field: string emailRef = 1;
   */
  emailRef = "";

  /**
   * @generated from field: bool markAsRead = 2;
   */
  markAsRead = false;

  constructor(data?: PartialMessage<GetEmailThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetEmailThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emailRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markAsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEmailThreadRequest {
    return new GetEmailThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEmailThreadRequest {
    return new GetEmailThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEmailThreadRequest {
    return new GetEmailThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEmailThreadRequest | PlainMessage<GetEmailThreadRequest> | undefined, b: GetEmailThreadRequest | PlainMessage<GetEmailThreadRequest> | undefined): boolean {
    return proto3.util.equals(GetEmailThreadRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetEmailThreadResponse
 */
export class GetEmailThreadResponse extends Message<GetEmailThreadResponse> {
  /**
   * @generated from field: repeated protogen.EmailMessage emails = 1;
   */
  emails: EmailMessage[] = [];

  /**
   * @generated from field: repeated protogen.CalendarEvent calendarEvents = 2;
   */
  calendarEvents: CalendarEvent[] = [];

  /**
   * @generated from field: string familyRef = 3;
   */
  familyRef = "";

  constructor(data?: PartialMessage<GetEmailThreadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetEmailThreadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emails", kind: "message", T: EmailMessage, repeated: true },
    { no: 2, name: "calendarEvents", kind: "message", T: CalendarEvent, repeated: true },
    { no: 3, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEmailThreadResponse {
    return new GetEmailThreadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEmailThreadResponse {
    return new GetEmailThreadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEmailThreadResponse {
    return new GetEmailThreadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEmailThreadResponse | PlainMessage<GetEmailThreadResponse> | undefined, b: GetEmailThreadResponse | PlainMessage<GetEmailThreadResponse> | undefined): boolean {
    return proto3.util.equals(GetEmailThreadResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchEmailInboxRequest
 */
export class FetchEmailInboxRequest extends Message<FetchEmailInboxRequest> {
  /**
   * @generated from field: string cursor = 1;
   */
  cursor = "";

  /**
   * @generated from field: string status = 2;
   */
  status = "";

  constructor(data?: PartialMessage<FetchEmailInboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEmailInboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEmailInboxRequest {
    return new FetchEmailInboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEmailInboxRequest {
    return new FetchEmailInboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEmailInboxRequest {
    return new FetchEmailInboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEmailInboxRequest | PlainMessage<FetchEmailInboxRequest> | undefined, b: FetchEmailInboxRequest | PlainMessage<FetchEmailInboxRequest> | undefined): boolean {
    return proto3.util.equals(FetchEmailInboxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchEmailInboxResponse
 */
export class FetchEmailInboxResponse extends Message<FetchEmailInboxResponse> {
  /**
   * @generated from field: repeated protogen.FetchEmailInboxResponse.EmailMessageThread threads = 1;
   */
  threads: FetchEmailInboxResponse_EmailMessageThread[] = [];

  /**
   * @generated from field: string nextCursor = 2;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchEmailInboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEmailInboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "threads", kind: "message", T: FetchEmailInboxResponse_EmailMessageThread, repeated: true },
    { no: 2, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEmailInboxResponse {
    return new FetchEmailInboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEmailInboxResponse {
    return new FetchEmailInboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEmailInboxResponse {
    return new FetchEmailInboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEmailInboxResponse | PlainMessage<FetchEmailInboxResponse> | undefined, b: FetchEmailInboxResponse | PlainMessage<FetchEmailInboxResponse> | undefined): boolean {
    return proto3.util.equals(FetchEmailInboxResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchEmailInboxResponse.EmailMessageThread
 */
export class FetchEmailInboxResponse_EmailMessageThread extends Message<FetchEmailInboxResponse_EmailMessageThread> {
  /**
   * @generated from field: string latestEmailRef = 1;
   */
  latestEmailRef = "";

  /**
   * @generated from field: bool unread = 3;
   */
  unread = false;

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0;

  /**
   * @generated from field: string sender = 5;
   */
  sender = "";

  /**
   * @generated from field: string subject = 6;
   */
  subject = "";

  /**
   * @generated from field: string teaserText = 7;
   */
  teaserText = "";

  /**
   * @generated from field: int64 lastMessageSec = 8;
   */
  lastMessageSec = protoInt64.zero;

  /**
   * Only present if the thread is associated with a family.
   *
   * @generated from field: protogen.FamilyStub family = 9;
   */
  family?: FamilyStub;

  /**
   * @generated from field: bool latestIsDraft = 10;
   */
  latestIsDraft = false;

  /**
   * @generated from field: bool hasDraft = 11;
   */
  hasDraft = false;

  constructor(data?: PartialMessage<FetchEmailInboxResponse_EmailMessageThread>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEmailInboxResponse.EmailMessageThread";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "latestEmailRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unread", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "sender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "teaserText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "lastMessageSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "family", kind: "message", T: FamilyStub },
    { no: 10, name: "latestIsDraft", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "hasDraft", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEmailInboxResponse_EmailMessageThread {
    return new FetchEmailInboxResponse_EmailMessageThread().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEmailInboxResponse_EmailMessageThread {
    return new FetchEmailInboxResponse_EmailMessageThread().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEmailInboxResponse_EmailMessageThread {
    return new FetchEmailInboxResponse_EmailMessageThread().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEmailInboxResponse_EmailMessageThread | PlainMessage<FetchEmailInboxResponse_EmailMessageThread> | undefined, b: FetchEmailInboxResponse_EmailMessageThread | PlainMessage<FetchEmailInboxResponse_EmailMessageThread> | undefined): boolean {
    return proto3.util.equals(FetchEmailInboxResponse_EmailMessageThread, a, b);
  }
}

/**
 * @generated from message protogen.MarkEmailsUnreadStatusRequest
 */
export class MarkEmailsUnreadStatusRequest extends Message<MarkEmailsUnreadStatusRequest> {
  /**
   * @generated from field: repeated string emailRefs = 1;
   */
  emailRefs: string[] = [];

  /**
   * @generated from field: bool markAsRead = 2;
   */
  markAsRead = false;

  constructor(data?: PartialMessage<MarkEmailsUnreadStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MarkEmailsUnreadStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emailRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "markAsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkEmailsUnreadStatusRequest {
    return new MarkEmailsUnreadStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkEmailsUnreadStatusRequest {
    return new MarkEmailsUnreadStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkEmailsUnreadStatusRequest {
    return new MarkEmailsUnreadStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MarkEmailsUnreadStatusRequest | PlainMessage<MarkEmailsUnreadStatusRequest> | undefined, b: MarkEmailsUnreadStatusRequest | PlainMessage<MarkEmailsUnreadStatusRequest> | undefined): boolean {
    return proto3.util.equals(MarkEmailsUnreadStatusRequest, a, b);
  }
}

/**
 * @generated from message protogen.MarkEmailsUnreadStatusResponse
 */
export class MarkEmailsUnreadStatusResponse extends Message<MarkEmailsUnreadStatusResponse> {
  constructor(data?: PartialMessage<MarkEmailsUnreadStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MarkEmailsUnreadStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkEmailsUnreadStatusResponse {
    return new MarkEmailsUnreadStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkEmailsUnreadStatusResponse {
    return new MarkEmailsUnreadStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkEmailsUnreadStatusResponse {
    return new MarkEmailsUnreadStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MarkEmailsUnreadStatusResponse | PlainMessage<MarkEmailsUnreadStatusResponse> | undefined, b: MarkEmailsUnreadStatusResponse | PlainMessage<MarkEmailsUnreadStatusResponse> | undefined): boolean {
    return proto3.util.equals(MarkEmailsUnreadStatusResponse, a, b);
  }
}

/**
 * @generated from message protogen.ArchiveEmailsRequest
 */
export class ArchiveEmailsRequest extends Message<ArchiveEmailsRequest> {
  /**
   * @generated from field: repeated string emailRefs = 1;
   */
  emailRefs: string[] = [];

  constructor(data?: PartialMessage<ArchiveEmailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ArchiveEmailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emailRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveEmailsRequest {
    return new ArchiveEmailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveEmailsRequest {
    return new ArchiveEmailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArchiveEmailsRequest {
    return new ArchiveEmailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ArchiveEmailsRequest | PlainMessage<ArchiveEmailsRequest> | undefined, b: ArchiveEmailsRequest | PlainMessage<ArchiveEmailsRequest> | undefined): boolean {
    return proto3.util.equals(ArchiveEmailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ArchiveEmailsResponse
 */
export class ArchiveEmailsResponse extends Message<ArchiveEmailsResponse> {
  constructor(data?: PartialMessage<ArchiveEmailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ArchiveEmailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveEmailsResponse {
    return new ArchiveEmailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveEmailsResponse {
    return new ArchiveEmailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArchiveEmailsResponse {
    return new ArchiveEmailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ArchiveEmailsResponse | PlainMessage<ArchiveEmailsResponse> | undefined, b: ArchiveEmailsResponse | PlainMessage<ArchiveEmailsResponse> | undefined): boolean {
    return proto3.util.equals(ArchiveEmailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAutocompleteEntriesRequest
 */
export class FetchAutocompleteEntriesRequest extends Message<FetchAutocompleteEntriesRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<FetchAutocompleteEntriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAutocompleteEntriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAutocompleteEntriesRequest {
    return new FetchAutocompleteEntriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAutocompleteEntriesRequest {
    return new FetchAutocompleteEntriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAutocompleteEntriesRequest {
    return new FetchAutocompleteEntriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAutocompleteEntriesRequest | PlainMessage<FetchAutocompleteEntriesRequest> | undefined, b: FetchAutocompleteEntriesRequest | PlainMessage<FetchAutocompleteEntriesRequest> | undefined): boolean {
    return proto3.util.equals(FetchAutocompleteEntriesRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAutocompleteEntriesResponse
 */
export class FetchAutocompleteEntriesResponse extends Message<FetchAutocompleteEntriesResponse> {
  /**
   * @generated from field: repeated protogen.AutocompleteEntry autocompleteEntries = 1;
   */
  autocompleteEntries: AutocompleteEntry[] = [];

  constructor(data?: PartialMessage<FetchAutocompleteEntriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAutocompleteEntriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "autocompleteEntries", kind: "message", T: AutocompleteEntry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAutocompleteEntriesResponse {
    return new FetchAutocompleteEntriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAutocompleteEntriesResponse {
    return new FetchAutocompleteEntriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAutocompleteEntriesResponse {
    return new FetchAutocompleteEntriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAutocompleteEntriesResponse | PlainMessage<FetchAutocompleteEntriesResponse> | undefined, b: FetchAutocompleteEntriesResponse | PlainMessage<FetchAutocompleteEntriesResponse> | undefined): boolean {
    return proto3.util.equals(FetchAutocompleteEntriesResponse, a, b);
  }
}

