import { useState } from "react";
import { useGetSignedUploadUrls } from "services/files";
import s3upload from "services/s3upload";
import { Attachment, UploadAttachment } from "protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";

const _toUploadAttachments = (
  uploads_: FileUpload[],
): PlainMessage<UploadAttachment>[] => {
  return uploads_.map((u) => {
    return {
      filename: u.filename,
      contentType: u.contentType,
      fileSize: u.size,
      // Will be blank if these are just references to existing attachments.
      s3Key: u.s3Key || "",
      validationKey: u.validationKey || "",
      inlineReference: u.inlineReference || "",
    };
  });
};

export const fromAttachment = (attachment: Attachment): FileUpload => ({
  filename: attachment.filename,
  contentType: attachment.contentType,
  size: attachment.fileSize,
  attachment: attachment,
  progress: 100,
  // Need to persist this on the server.
  inlineReference: attachment.inlineReference,
});

export const attachmentsToUploads = (
  attachments: Attachment[],
): PlainMessage<UploadAttachment>[] => {
  return _toUploadAttachments(attachments.map(fromAttachment));
};

const mergeUploads = (uploads: FileUpload[], newUploads: FileUpload[]) => {
  const updatesMap = new Map<string, FileUpload>();
  const used = new Set<string>();
  for (const upl of newUploads) {
    updatesMap.set(upl.filename, upl);
  }
  const mergedList: FileUpload[] = [];
  for (const upl of uploads) {
    if (updatesMap.has(upl.filename)) {
      mergedList.push(updatesMap.get(upl.filename)!);
      used.add(upl.filename);
    } else {
      mergedList.push(upl);
    }
  }
  for (const upl of newUploads) {
    if (!used.has(upl.filename)) {
      mergedList.push(upl);
    }
  }
  return mergedList;
};

export interface FileUpload {
  filename: string;
  contentType: string;
  size: number;
  progress: number;
  inlineReference: string | null;
  // Present if it has already been uploaded.
  attachment?: Attachment;
  // True if it is ephemeral.
  file?: File;
  url?: string;
  validationKey?: string;
  s3Key?: string;
}

interface UseUploaderProps {
  initialAttachments?: Attachment[];
  maxUploadBytes?: number;
  sseRef?: string;
}

type Uploader = {
  onUpload: (
    files: FileList | File[] | null,
    inlineReferences?: (string | null)[],
    callback?: (u: PlainMessage<UploadAttachment>[]) => void,
  ) => void;
  fileUploads: FileUpload[];
  fileOnlyUploads: FileUpload[];
  inlineUploads: FileUpload[];
  clearUploads: () => void;
  removeUpload: (
    filename: string,
    callback?: (u: PlainMessage<UploadAttachment>[]) => void,
  ) => void;
  getCompleteAttachments: () => PlainMessage<UploadAttachment>[];
  withAttachments: (attachments: Attachment[]) => void;
  uploadPercentage: number;
  uploadsInProgress: boolean;
  updateFileUpload: (updates: FileUpload[]) => void;
};

export const useUploader = ({
  initialAttachments = [],
  maxUploadBytes = 30000000,
  sseRef,
}: UseUploaderProps): Uploader => {
  const [fileUploads, setFileUploads] = useState<FileUpload[]>(
    initialAttachments.map(fromAttachment),
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadsInProgress, setUploadsInProgress] = useState<boolean>(false);

  const { request: signedUrlRequest } = useGetSignedUploadUrls();

  const withAttachments = (attachments: Attachment[]) => {
    setFileUploads(attachments.map(fromAttachment));
  };

  const updateFileUpload = (updates: FileUpload[]) => {
    setFileUploads((prevUploads) => mergeUploads(prevUploads, updates));
  };

  const onUpload = async (
    files: FileList | File[] | null,
    inlineReferences?: (string | null)[],
    callback?: (u: PlainMessage<UploadAttachment>[]) => void,
  ) => {
    if (!files) return;
    if (uploadsInProgress) {
      console.error("Upload already in progress");
      return;
    }
    if (inlineReferences && inlineReferences.length !== files.length) {
      console.error("File names must match files");
      return;
    }
    setUploadsInProgress && setUploadsInProgress(true);
    const uploads: FileUpload[] = [];
    let totalSize = fileUploads.reduce((sum, upl) => sum + upl.size, 0);
    const usedNames = new Set<string>(fileUploads.map((u) => u.filename));
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      totalSize += file.size;
      if (totalSize > maxUploadBytes) {
        alert(
          `Total attachment size must not exceed than ${
            maxUploadBytes / 1000000
          }MB`,
        );
        setUploadsInProgress && setUploadsInProgress(false);
        return;
      }
      let filename = file.name;
      if (usedNames.has(filename)) {
        let j = 1;
        while (usedNames.has(filename)) {
          filename = `${file.name} (${j})`;
          j++;
        }
      }
      usedNames.add(filename);
      uploads.push({
        filename: filename,
        contentType: file.type,
        size: file.size,
        file: file,
        progress: 0,
        inlineReference: inlineReferences ? inlineReferences[i] : null,
      });
    }
    const response = await signedUrlRequest({
      // with KMS?
      uploads: uploads.map((u) => {
        return {
          filename: u.filename,
          contentType: u.contentType,
          size: u.size,
        };
      }),
      sseRef: sseRef || "",
    });
    if (!response) {
      // error!
      setUploadsInProgress && setUploadsInProgress(false);
      return;
    }
    updateFileUpload(uploads);
    for (let i = 0; i < uploads.length; i++) {
      const upload = uploads[i];
      const signedUrl = response.signedUrls[i];
      upload.url = signedUrl.url;
      upload.validationKey = response.validationKey;
      upload.s3Key = signedUrl.key;
      const progress = (percentage: number) => {
        upload.progress = percentage;
        updateFileUpload(uploads);
        const totalProgress = uploads.reduce(
          (acc, u) => acc + (u.progress / 100) * u.size,
          0,
        );
        const totalSize = uploads.reduce((acc, u) => acc + u.size, 0);
        setUploadPercentage((totalProgress / totalSize) * 100);
      };
      if (signedUrl.filename === upload.filename) {
        await s3upload(
          signedUrl.url,
          upload.file!,
          response.validationKey,
          progress,
          signedUrl.headers,
        );
        upload.progress = 100; // Otherwise is updates to zero at the end.
      }
    }
    updateFileUpload(uploads);
    setUploadsInProgress && setUploadsInProgress(false);
    setUploadPercentage(0);
    if (callback) {
      callback(_toUploadAttachments(mergeUploads(fileUploads, uploads)));
    }
  };
  const removeUpload = (
    filename: string,
    callback?: (u: PlainMessage<UploadAttachment>[]) => void,
  ) => {
    const filtered = fileUploads.filter((u) => u.filename !== filename);
    setFileUploads(filtered);
    if (callback) {
      callback(_toUploadAttachments(filtered));
    }
  };

  return {
    onUpload,
    fileUploads,
    fileOnlyUploads: fileUploads.filter((u) => !u.inlineReference),
    inlineUploads: fileUploads.filter((u) => u.inlineReference),
    clearUploads: () => setFileUploads([]),
    removeUpload,
    getCompleteAttachments: () => _toUploadAttachments(fileUploads),
    withAttachments,
    uploadPercentage,
    uploadsInProgress,
    updateFileUpload,
  };
};
