// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/text_message_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { UploadAttachment } from "./common_pb.ts";
import { TextMessage } from "./conversation_pb.ts";

/**
 * @generated from message protogen.SendTextMessageRequest
 */
export class SendTextMessageRequest extends Message<SendTextMessageRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: repeated string memberRefs = 2;
   */
  memberRefs: string[] = [];

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * Attachments
   *
   * @generated from field: bool includeContactCard = 4;
   */
  includeContactCard = false;

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 5;
   */
  attachments: UploadAttachment[] = [];

  constructor(data?: PartialMessage<SendTextMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendTextMessageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "memberRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "includeContactCard", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendTextMessageRequest {
    return new SendTextMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendTextMessageRequest {
    return new SendTextMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendTextMessageRequest {
    return new SendTextMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendTextMessageRequest | PlainMessage<SendTextMessageRequest> | undefined, b: SendTextMessageRequest | PlainMessage<SendTextMessageRequest> | undefined): boolean {
    return proto3.util.equals(SendTextMessageRequest, a, b);
  }
}

/**
 * @generated from message protogen.SendTextMessageResponse
 */
export class SendTextMessageResponse extends Message<SendTextMessageResponse> {
  /**
   * @generated from field: protogen.TextMessage textMessage = 1;
   */
  textMessage?: TextMessage;

  constructor(data?: PartialMessage<SendTextMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendTextMessageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "textMessage", kind: "message", T: TextMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendTextMessageResponse {
    return new SendTextMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendTextMessageResponse {
    return new SendTextMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendTextMessageResponse {
    return new SendTextMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendTextMessageResponse | PlainMessage<SendTextMessageResponse> | undefined, b: SendTextMessageResponse | PlainMessage<SendTextMessageResponse> | undefined): boolean {
    return proto3.util.equals(SendTextMessageResponse, a, b);
  }
}

/**
 * @generated from message protogen.RetrySendTextMessageRequest
 */
export class RetrySendTextMessageRequest extends Message<RetrySendTextMessageRequest> {
  /**
   * @generated from field: string messageRef = 1;
   */
  messageRef = "";

  constructor(data?: PartialMessage<RetrySendTextMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RetrySendTextMessageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messageRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrySendTextMessageRequest {
    return new RetrySendTextMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrySendTextMessageRequest {
    return new RetrySendTextMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrySendTextMessageRequest {
    return new RetrySendTextMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RetrySendTextMessageRequest | PlainMessage<RetrySendTextMessageRequest> | undefined, b: RetrySendTextMessageRequest | PlainMessage<RetrySendTextMessageRequest> | undefined): boolean {
    return proto3.util.equals(RetrySendTextMessageRequest, a, b);
  }
}

/**
 * @generated from message protogen.RetrySendTextMessageResponse
 */
export class RetrySendTextMessageResponse extends Message<RetrySendTextMessageResponse> {
  /**
   * @generated from field: protogen.TextMessage textMessage = 1;
   */
  textMessage?: TextMessage;

  constructor(data?: PartialMessage<RetrySendTextMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RetrySendTextMessageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "textMessage", kind: "message", T: TextMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrySendTextMessageResponse {
    return new RetrySendTextMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrySendTextMessageResponse {
    return new RetrySendTextMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrySendTextMessageResponse {
    return new RetrySendTextMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RetrySendTextMessageResponse | PlainMessage<RetrySendTextMessageResponse> | undefined, b: RetrySendTextMessageResponse | PlainMessage<RetrySendTextMessageResponse> | undefined): boolean {
    return proto3.util.equals(RetrySendTextMessageResponse, a, b);
  }
}

