import { forwardRef, useRef, useState, useEffect } from "react";
import {
  Box,
  Divider,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Collapse,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Calendar,
  Pencil,
  Plus,
  Trash2,
  X,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { format } from "date-fns";
import BlockGutterMap from "./BlockGutterMap";
import { BlockHandle, BlockProps } from "./utils";
import BlockContainer from "./BlockContainer";
import useIsMobile from "components/hooks/useIsMobile";
import { useUpdateBlockContent, useUpdateBlockMetadata } from "services/tasks";
import { minutesToDisplay } from "../utils";
import { TaskTimer } from "protogen/tasks_pb";

interface TimeEntry {
  hoursReported?: number;
  minutesReported?: number;
  description?: string;
  date?: number;
}

const getTotalFromTimeEntries = (timeEntries: TimeEntry[]): number => {
  return timeEntries.reduce((acc, entry) => {
    return acc + (entry.hoursReported || 0) * 60 + (entry.minutesReported || 0);
  }, 0);
};

const defaultTimeEntry = (): TimeEntry => {
  const entry: TimeEntry = {
    hoursReported: 0,
    minutesReported: 0,
    description: "",
    date: Date.now(),
  };
  return entry;
};

const TotalTimeDisplay = ({ time }: { time: number }) => {
  const totalTimeStr = minutesToDisplay(time);
  return (
    <Box sx={{ display: "flex", gap: "12px" }}>
      <Typography variant="body">Total time</Typography>
      <Typography variant="bodyHeavy">{totalTimeStr}</Typography>
    </Box>
  );
};

const TimeTrackerForm = ({
  title,
  updateFormData,
  formData,
  totalInMinutes,
  onSave,
  onCancel,
  onDelete,
  isSaving,
}: {
  title: string;
  updateFormData: (updates: TimeEntry) => void;
  formData: TimeEntry;
  totalInMinutes: number;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
  isSaving: boolean;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  const isMobile = useIsMobile();
  const hoursInputRef = useRef<HTMLDivElement>(null);
  const minutesInputRef = useRef<HTMLDivElement>(null);
  const preventDecimals = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent entering '.', '-', '+', 'e', etc.
    if (
      e.key === "." ||
      e.key === "-" ||
      e.key === "+" ||
      e.key.toLowerCase() === "e"
    ) {
      e.preventDefault();
    }
  };
  const noSpinButtons = {
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        WebkitAppearance: "none",
        margin: 0,
      },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "12px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography variant="bodyHeavy">{title}</Typography>
        {isMobile && <Trash2 size={16} color="#C14743" onClick={onDelete} />}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? "12px" : "0px",
        }}
      >
        <Box sx={{ display: "flex", gap: "8px" }}>
          <TextField
            ref={hoursInputRef}
            defaultValue={formData.hoursReported}
            type="number"
            onKeyDown={preventDecimals}
            onChange={(e) =>
              updateFormData({ hoursReported: Number(e.target.value) })
            }
            sx={{
              width: isMobile ? "100%" : "130px",
              height: "48px",
              div: { minHeight: "48px" },
              input: {
                marginTop: "0",
                paddingLeft: "16px",
                paddingRight: "8px",
              },
              ...noSpinButtons,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{ paddingTop: "0px !important" }}
                  position="end"
                  onClick={() =>
                    hoursInputRef.current?.querySelector("input")?.focus()
                  }
                >
                  hours
                </InputAdornment>
              ),
            }}
          />
          <TextField
            ref={minutesInputRef}
            defaultValue={formData.minutesReported}
            type="number"
            onKeyDown={preventDecimals}
            onChange={(e) =>
              updateFormData({ minutesReported: Number(e.target.value) })
            }
            sx={{
              width: isMobile ? "100%" : "150px",
              div: { minHeight: "48px" },
              input: {
                marginTop: "0",
                paddingLeft: "16px",
                paddingRight: "8px",
              },
              ...noSpinButtons,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{ paddingTop: "0px !important" }}
                  onClick={() =>
                    minutesInputRef.current?.querySelector("input")?.focus()
                  }
                  position="end"
                >
                  minutes
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <DatePicker
            value={new Date(formData.date || Date.now())}
            onChange={(date: Date | null) => {
              if (date === null) return;
              updateFormData({ date: date.getTime() });
            }}
            format="MMM dd"
            open={dateOpen}
            onClose={() => setDateOpen(false)}
            sx={{ maxWidth: "150px" }}
            slotProps={{
              textField: {
                onClick: () => setDateOpen(true),
                InputProps: {
                  sx: { minHeight: "48px", input: { marginTop: "0px" } },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ cursor: "pointer", paddingTop: "0px !important" }}
                    >
                      <Calendar />
                    </InputAdornment>
                  ),
                  endAdornment: null,
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box>
        <TextField
          fullWidth
          placeholder="What did you do?"
          value={formData.description}
          type="text"
          onChange={(e) => updateFormData({ description: e.target.value })}
          sx={{
            div: { minHeight: "48px" },
            input: {
              marginTop: "0",
              paddingLeft: "16px",
              paddingRight: "8px",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button disabled={isSaving} onClick={onSave}>
            Save
          </Button>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
        {!isMobile && (
          <TotalTimeDisplay time={totalInMinutes}></TotalTimeDisplay>
        )}
      </Box>
    </Box>
  );
};

const TimeEntryDisplay = ({
  entries,
  onEditEntry,
  onDeleteEntry,
}: {
  entries: TimeEntry[];
  onEditEntry: (index: number) => void;
  onDeleteEntry: (index: number) => void;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ width: "100%" }}>
      {entries.map((entry, i) => {
        const totalMinutes =
          (entry.hoursReported || 0) * 60 + (entry.minutesReported || 0);
        return (
          <Box key={i}>
            {isMobile ? (
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => onEditEntry(i)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Typography variant="bodyHeavy">
                      {minutesToDisplay(totalMinutes)}
                    </Typography>
                  </Box>
                  {entry.date && (
                    <Typography variant="bodySmall" color="#6B6E7B">
                      {format(new Date(entry.date), "MMM dd")}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography variant="body">{entry.description}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  "&:hover .edit-icon": {
                    visibility: "visible",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <Typography variant="bodyHeavy" sx={{ textWrap: "nowrap" }}>
                    {minutesToDisplay(totalMinutes)}
                  </Typography>
                  <Typography variant="body">{entry.description}</Typography>
                  {entry.date && (
                    <Typography variant="bodySmall" color="#6B6E7B">
                      {format(new Date(entry.date), "MMM dd")}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{ visibility: "hidden", cursor: "pointer" }}
                  className="edit-icon"
                >
                  <Pencil
                    onClick={() => onEditEntry(i)}
                    size={20}
                    color="#8E9598"
                  />
                  <X
                    onClick={() => onDeleteEntry(i)}
                    size={20}
                    color="#8E9598"
                  />
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default forwardRef<BlockHandle, BlockProps>(
  ({ block, task, updateTask }: BlockProps, _ref) => {
    const [parsedMetadata, setParsedMetadata] = useState<any>();
    const isMobile = useIsMobile();
    const blockData = JSON.parse(block.content);
    const [timeEntries, setTimeEntries] = useState<TimeEntry[]>(
      blockData.timeEntries || [],
    );
    const [formData, setFormData] = useState<TimeEntry>(defaultTimeEntry());
    const [formVisibility, setFormVisibility] = useState<
      "add" | "edit" | "closed"
    >("closed");
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [totalInMinutes, setTotalInMinutes] = useState(
      getTotalFromTimeEntries(timeEntries),
    );

    const [isSaving, setIsSaving] = useState(false);

    const { request } = useUpdateBlockContent((r) => {
      const blockData = JSON.parse(r?.block?.content || "{}");
      setTimeEntries(blockData.timeEntries || []);
      setFormData(defaultTimeEntry());
      setEditingIndex(null);
      setFormVisibility("closed");
      const totalTime = getTotalFromTimeEntries(blockData.timeEntries || []);
      setTotalInMinutes(totalTime);
      const updatedTask = task.clone();
      updatedTask.timing = new TaskTimer({
        minutesReported: totalTime,
        hoursReported: 0, // We are only updating local state
      });
      updateTask && updateTask(updatedTask);
      setIsSaving(false);
    });

    const { request: updateMetadataRequest } = useUpdateBlockMetadata((r) => {
      if (!r.block?.metadata) return;
      setParsedMetadata(JSON.parse(r.block?.metadata));
    });

    const updateTimeEntries = (timeEntries: TimeEntry[]) => {
      if (isSaving) return;
      setIsSaving(true);
      const updatedContent = {
        ...blockData,
        timeEntries: timeEntries,
      };
      request({
        taskBlockRef: block.ref,
        content: JSON.stringify(updatedContent),
        contentType: "json",
        textContent: "", // not used but required in proto
        attachments: [], // not used but required in proto
      });
    };

    const onSave = () => {
      if (editingIndex !== null) {
        // An edit
        const entries = timeEntries.map((entry, i) =>
          i === editingIndex ? formData : entry,
        );
        updateTimeEntries(entries);
      } else {
        // An add
        updateTimeEntries([...timeEntries, formData]);
      }
    };

    const onEditEntry = (index: number) => {
      setEditingIndex(index);
      setFormData(timeEntries[index]);
      setFormVisibility("edit");
    };

    const onDeleteEntry = (index: number) => {
      const newEntries = [...timeEntries];
      newEntries.splice(index, 1);
      updateTimeEntries(newEntries);
    };

    const handleToggleCollapsed = () => {
      updateMetadataRequest({
        taskBlockRef: block.ref,
        key: "isCollapsed",
        value: (!parsedMetadata.isCollapsed).toString(),
      });
    };

    useEffect(() => {
      if (block.metadata) {
        setParsedMetadata(JSON.parse(block.metadata));
      }
    }, [block]);

    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.timeTracking.icon}
        isLast={false}
        advisorViewOnly={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              padding: isMobile ? "20px" : "16px 24px",
              gap: parsedMetadata?.isCollapsed ? "0" : "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="bodyHeavy">Time tracker</Typography>
              </Box>
              <Box onClick={handleToggleCollapsed} sx={{ cursor: "pointer" }}>
                {parsedMetadata?.isCollapsed ? (
                  <ChevronDown size={24} stroke="#8E9598" />
                ) : (
                  <ChevronUp size={24} stroke="#8E9598" />
                )}
              </Box>
            </Box>

            <Collapse in={!parsedMetadata?.isCollapsed} sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Divider sx={{ width: "100%" }} />
                {timeEntries.length === 0 && formVisibility === "closed" && (
                  <Typography variant="body" color="#6B6E7B">
                    Incrementally record time spent doing activities for this
                    task.
                  </Typography>
                )}
                {timeEntries.length > 0 && formVisibility !== "edit" && (
                  <TimeEntryDisplay
                    entries={timeEntries}
                    onEditEntry={onEditEntry}
                    onDeleteEntry={onDeleteEntry}
                  />
                )}
                {(formVisibility === "add" || formVisibility === "edit") && (
                  <TimeTrackerForm
                    title={
                      formVisibility === "add"
                        ? "New time record"
                        : "Edit time record"
                    }
                    updateFormData={(updates: TimeEntry) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        ...updates,
                      }));
                    }}
                    formData={formData}
                    totalInMinutes={totalInMinutes}
                    onSave={onSave}
                    onCancel={() => {
                      setFormVisibility("closed");
                    }}
                    isSaving={isSaving}
                    onDelete={() => {
                      if (editingIndex === null) return;
                      onDeleteEntry(editingIndex);
                    }}
                  />
                )}
                {formVisibility === "closed" && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        setFormVisibility("add");
                      }}
                      startIcon={<Plus size={20} />}
                      sx={{ height: "20px" }}
                    >
                      Add time
                    </Button>
                    {timeEntries.length > 0 && (
                      <TotalTimeDisplay
                        time={totalInMinutes}
                      ></TotalTimeDisplay>
                    )}
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        </Box>
      </BlockContainer>
    );
  },
);
