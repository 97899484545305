import {
  AdvancedMarker,
  AdvancedMarkerRef,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { ReactComponent as Pin } from "../../../icons/maps/Pin.svg";

export enum Treatment {
  Pin,
  PersistentDot,
  //   house?
}

type FMarkerProps = {
  lat: number;
  lng: number;
  active: boolean;
  onClick: (marker: AdvancedMarkerRef) => void;
  title?: string;
  treatment?: Treatment;
  color?: string;
};

export default ({
  lat,
  lng,
  title,
  active,
  onClick,
  treatment = Treatment.Pin,
  color,
}: FMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const getTreatment = () => {
    switch (treatment) {
      case Treatment.Pin:
        return (
          <Pin
            height="50px"
            width="35px"
            fill={color || "#198282"}
            stroke={color || "#198282"}
            style={{ opacity: active ? "1" : "0.6" }}
          />
        );
      case Treatment.PersistentDot:
        return (
          <div
            style={{
              width: 16,
              height: 16,
              position: "absolute",
              top: 0,
              left: 0,
              background: color || "#1dbe80",
              border: `2px solid #0e6443`,
              borderRadius: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        );
    }
  };
  return (
    <AdvancedMarker
      ref={markerRef}
      onClick={() => onClick(marker)}
      position={{ lat, lng }}
      title={title}
    >
      {getTreatment()}
    </AdvancedMarker>
  );
};
