import { forwardRef, useImperativeHandle, useState } from "react";
import { EphemeralTask } from "../../types/tasks";
import { EphemeralFact } from "../facts/types";
import { EphemeralEvent } from "../../types/calendars";
import FactDialog from "../facts/FactDialog";
import AddEventDialog from "../calendar/AddEventDialog";
import { createTaskHelper } from "../tasks/utils";

export type SuggestionActionsHandle = {
  suggestTask: (t: EphemeralTask) => void;
  suggestFact: (f: EphemeralFact) => void;
  suggestEvent: (e: EphemeralEvent) => void;
};

type Props = {
  familyRef: string;
  onTaskSuggested?: () => void;
  onFactSuggested?: () => void;
  onEventSuggested?: () => void;
};

export default forwardRef<SuggestionActionsHandle, Props>(
  ({ familyRef, onFactSuggested, onEventSuggested }: Props, ref) => {
    const { createTask } = createTaskHelper({
      referral: "suggestion-actions",
    });

    const suggestTask = async (t: EphemeralTask) => {
      await createTask({
        familyRef,
        ...t,
      });
    };
    useImperativeHandle(ref, () => ({
      suggestTask: suggestTask,
      suggestFact: (f: EphemeralFact) => setFactSuggestion(f),
      suggestEvent: (e: EphemeralEvent) => setEventSuggestion(e),
    }));
    const [factSuggestion, setFactSuggestion] = useState<EphemeralFact | null>(
      null,
    );
    const [eventSuggestion, setEventSuggestion] =
      useState<EphemeralEvent | null>(null);
    return (
      <>
        {factSuggestion && (
          <FactDialog
            open={!!factSuggestion}
            familyRef={familyRef}
            category={factSuggestion.valueType!}
            onClose={() => setFactSuggestion(null)}
            onCreate={() => {
              // Update facts in details section?
              setFactSuggestion(null);
              onFactSuggested?.();
            }}
            onDelete={() => {
              // Update facts in details section?
              setFactSuggestion(null);
            }}
            onEdit={() => {
              // Update facts in details section?
              setFactSuggestion(null);
            }}
            isEdit={false}
            fact={factSuggestion}
          />
        )}
        {eventSuggestion && (
          <AddEventDialog
            familyRef={familyRef}
            open={!!eventSuggestion}
            initialFormData={eventSuggestion || undefined}
            onClose={() => setEventSuggestion(null)}
            onCreated={() => {
              setEventSuggestion(null);
              onEventSuggested?.();
            }}
          />
        )}
      </>
    );
  },
);
