import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import useIsVisible from "../hooks/useIsVisible";
import { useEffect, useRef, useState } from "react";
import { useLiveTranscriptions } from "services/transcription";
import {
  LiveTranscriptionsResponse,
  TranscriptionSegment,
} from "protogen/phone_service_pb";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

type TranscriptionState = {
  cursorMs: number;
  maxTimestampProcessed: number;
  transcriptionComplete: boolean;
  segments: Array<TranscriptionSegment>;
  callCount: number;
  streamExists: boolean;
  transcriptionFailed: boolean;
};

const _defaultState = () => {
  return {
    cursorMs: 0,
    maxTimestampProcessed: 0,
    transcriptionComplete: false,
    segments: [],
    callCount: 0,
    streamExists: false,
    transcriptionFailed: false,
  };
};

type Output = {
  segments: TranscriptionSegment[];
  complete: boolean;
  streamStarted: boolean;
  transcriptionFailed: boolean;
};

export default (call: PhoneCall, waitForBothTracks: boolean = true): Output => {
  const { request: inboundRequest } = useLiveTranscriptions();
  const { request: outboundRequest } = useLiveTranscriptions();
  const { isVisible } = useIsVisible({});

  const [inboundState, setInboundState] =
    useState<TranscriptionState>(_defaultState());
  const [outboundState, setOutboundState] =
    useState<TranscriptionState>(_defaultState());

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  useEffect(() => {
    let effectStale = false; // Don't forget ; on the line before self-invoking functions
    const looper = async (track: string) => {
      let state: TranscriptionState;
      let setState: (s: TranscriptionState) => void;
      let request;
      const nowSec = () => Math.floor(Date.now() / 1000);
      let nullCounter: number | null = nowSec();
      if (track === "outbound") {
        state = outboundState;
        setState = setOutboundState;
        request = outboundRequest;
      } else {
        // if (window.location.hostname === 'localhost') {
        // Sleep here so the calls are offset (just a favor to our server)
        await sleep(1000);
        // }
        state = inboundState;
        setState = setInboundState;
        request = inboundRequest;
      }
      while (!state.transcriptionComplete && !state.transcriptionFailed) {
        let resultData: LiveTranscriptionsResponse | null = null;
        if (isVisible) {
          resultData = await request({
            callRef: call.ref,
            track: track,
            maxTimestampProcessed: state.maxTimestampProcessed,
          });
        }
        /* Component has been unmounted. Stop to avoid
           "Warning: Can't perform a React state update on an unmounted component." */
        if (unmountedRef.current) return;

        /* Component has re-rendered with different someId value
           Stop to avoid updating state with stale response */
        if (effectStale) return;

        // ... update component state
        if (resultData) {
          nullCounter = null; // unset
          state = {
            cursorMs: resultData.cursorMs,
            maxTimestampProcessed: resultData.maxTimestampProcessed,
            transcriptionComplete: resultData.complete,
            segments: resultData.segments,
            callCount: state.callCount + 1,
            streamExists: true,
            transcriptionFailed: false,
          };
          setState(state);
        } else if (call.state === PhoneCall_State.FAILED) {
          setState({ ...state, transcriptionFailed: true });
          return;
        } else if (nullCounter && nowSec() - nullCounter > 60) {
          // If we didn't get any data for a minute, stop counting.
          setState({ ...state, transcriptionFailed: true });
          return;
        }
        await sleep(5000);
      }
    };
    if (call.transcriptionEnabled) {
      looper("outbound");
      looper("inbound");
    }
    return () => {
      effectStale = true;
    };
  }, [call.ref, call.transcriptionEnabled, isVisible]);

  const segments = [...inboundState.segments, ...outboundState.segments].sort(
    (a, b) => {
      const aTs = a.startSec;
      const bTs = b.startSec;
      return aTs < bTs ? -1 : aTs > bTs ? 1 : 0;
    },
  );
  return {
    segments:
      !waitForBothTracks || (inboundState.callCount && outboundState.callCount)
        ? segments
        : [],
    complete:
      inboundState.transcriptionComplete && outboundState.transcriptionComplete,
    streamStarted: inboundState.streamExists && outboundState.streamExists,
    transcriptionFailed:
      inboundState.transcriptionFailed && outboundState.transcriptionFailed,
  };
};
