enum PhoneState {
  NOT_CONFIGURED = 0,
  CREATED = 1,
  REGISTERED = 2,
  FAILED = 3,
  ACTIVE = 4,
  RINGING = 5,
}

export { PhoneState };
