import { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import SecretView from "./SecretView";
import { EphemeralSecret } from "./types";
import Loading from "../common/Loading";
import {
  useRevealSecretMember,
  useCreateMemberSecret,
  useEditMemberSecret,
  useDeleteMemberSecret,
} from "services/secrets";
import { SECRET_TYPES } from "./Constants";
import { attachmentsToUploads } from "../creation/FileUploader";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import { Family } from "protogen/advisors_service_pb";

const defaultFormData = (): EphemeralSecret => ({
  name: "",
  value: "",
  valueType: "",
  attachments: [],
  uploads: [],
});

const SecretSelector = ({
  updateFormData,
  onBackHandler,
}: {
  updateFormData: (s: EphemeralSecret) => void;
  onBackHandler: () => void;
}) => {
  return (
    <Box
      sx={{
        padding: "24px",
        paddingTop: "8px",
        paddingLeft: "42px",
        paddingRight: "42px",
      }}
    >
      <Box sx={{ marginBottom: "24px" }}>
        <Typography variant="title">
          What kind of secret would you like to add?
        </Typography>
      </Box>
      {SECRET_TYPES.map((secretType) => {
        return (
          <MenuItem
            disableRipple
            sx={{
              height: "70px",
              border: "1px solid #D4D4D4",
              borderRadius: "8px",
              marginBottom: "16px",
              padding: "0px 24px",
              gap: "8px",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
            onClick={() => {
              updateFormData({
                ...defaultFormData(),
                valueType: secretType.value,
              });
            }}
            key={secretType.value}
            value={secretType.value}
          >
            <ListItemIcon>{secretType.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: "body" }}
              primary={secretType.label}
            />
          </MenuItem>
        );
      })}
      <Button
        fullWidth
        variant="outlinedSecondary"
        onClick={() => {
          onBackHandler();
        }}
      >
        Back
      </Button>
    </Box>
  );
};

interface SecretProps {
  secretRef: string;
  onSaveHandler: () => void;
  onCancelHandler: () => void;
  family?: Family;
}

export default ({
  secretRef,
  onSaveHandler,
  onCancelHandler,
  family,
}: SecretProps) => {
  const confirmState = useConfirmationDialog();
  const { request, loading } = useRevealSecretMember((r) => {
    const secret: EphemeralSecret = {
      ref: r?.secret?.ref,
      name: r?.secret?.name || "",
      valueType: r?.secret?.valueType || "",
      value: r.secretValue,
      attachments: r?.attachments || [],
      uploads: attachmentsToUploads(r?.attachments || []),
    };
    setFormData(secret);
  });
  const { request: createRequest, loading: createLoading } =
    useCreateMemberSecret();
  const { request: editRequest, loading: editLoading } = useEditMemberSecret();
  const { request: deleteRequest, loading: deleteLoading } =
    useDeleteMemberSecret();
  const mutationLoading = createLoading || editLoading || deleteLoading;
  const [formData, setFormData] = useState<EphemeralSecret>(defaultFormData());
  const [isEdit, setIsEdit] = useState(false);
  const updateFormData = (s: EphemeralSecret) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const onSave = async () => {
    if (loading) return;
    if (isEdit) {
      await editRequest({
        secretRef: formData.ref!,
        name: formData.name,
        value: formData.value,
        attachments: formData.uploads,
      });
    } else {
      await createRequest({
        name: formData.name,
        value: formData.value,
        valueType: formData.valueType,
        attachments: formData.uploads,
      });
    }
    onSaveHandler();
  };
  const onDelete = async () => {
    confirmState.openDialog(() => {
      deleteRequest({
        secretRef: formData.ref!,
      });
      onSaveHandler();
    });
  };
  useEffect(() => {
    if (!secretRef) {
      setFormData(defaultFormData());
      setIsEdit(false);
      return;
    }
    request({
      secretRef: secretRef!,
    });
    setIsEdit(true);
  }, [secretRef]);
  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      {formData.valueType !== "" ? (
        <SecretView
          secret={formData}
          updateFormData={updateFormData}
          onSave={onSave}
          onDelete={onDelete}
          isEdit={isEdit}
          disabled={mutationLoading}
          family={family}
        />
      ) : (
        <SecretSelector
          updateFormData={(s) => {
            updateFormData(s);
            setIsEdit(false);
          }}
          onBackHandler={onCancelHandler}
        />
      )}
      <ConfirmationDialog
        content="Are you sure you want to remove this secret"
        {...confirmState.dialogProps}
      />
    </Box>
  );
};
