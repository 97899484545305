import MuiPhoneNumber, { MuiPhoneNumberProps } from "mui-phone-number";

type MyProps = MuiPhoneNumberProps & {
  onChange: (value: string) => void;
  disableDropdown?: boolean;
};
export default ({
  onChange,
  disableDropdown = true,
  sx,
  ...otherProps
}: MyProps) => {
  return (
    <MuiPhoneNumber
      {...otherProps}
      sx={{
        ...sx,
        ".MuiIconButton-root": {
          margin: "8px !important",
          minWidth: "22px !important",
          height: "22px !important",
        },
        ".MuiOutlinedInput-input": {
          padding: disableDropdown ? undefined : 0,
        },
      }}
      variant="outlined"
      disableDropdown={disableDropdown}
      defaultCountry="us"
      preferredCountries={["us"]}
      autoFormat={true}
      disableAreaCodes={true}
      onChange={(e) => {
        onChange(typeof e === "string" ? e : e.target.value);
      }}
      placeholder="Enter phone number"
    />
  );
};
