import FamilyForm from "./FamilyForm";
import MemberForm from "./MemberForm";
import { Box, Button } from "@mui/material";
import { defaultMember } from "./utils";
import { Family, FormErrors, Member } from "./types";
import { Advisor } from "protogen/common_pb";
import FamilyPermissions from "./FamilyPermissions";

type Props = {
  familyData: Family;
  members: Member[];
  setFamilyData: (familyData: Family) => void;
  setMembers: (members: Member[]) => void;
  familyErrors: FormErrors | null;
  memberErrors: FormErrors[] | null;
  handleSubmit: () => void;
  familyOnly?: boolean;
  contactOnly?: string | null;
  primaryAdvisor?: Advisor;
  selectAdvisor?: boolean;
  error?: string | null | undefined;
};

export default ({
  familyData,
  members,
  setFamilyData,
  setMembers,
  handleSubmit,
  familyErrors,
  memberErrors,
  familyOnly = false,
  contactOnly = null,
  primaryAdvisor,
  selectAdvisor = false,
  error = null,
}: Props) => {
  const handleAddMember = () => {
    setMembers([...members, defaultMember()]);
  };

  return (
    <form onSubmit={handleSubmit}>
      {!contactOnly && (
        <FamilyForm
          errors={familyErrors}
          familyData={familyData}
          setFamilyData={setFamilyData}
          selectAdvisor={selectAdvisor}
        />
      )}
      {!contactOnly && primaryAdvisor && (
        <FamilyPermissions
          primaryAdvisor={primaryAdvisor}
          errors={familyErrors}
          familyData={familyData}
          setFamilyData={setFamilyData}
        />
      )}
      {members.map((member, index) => {
        if (contactOnly && contactOnly !== member.ref) return;
        if (familyOnly && !member.isEphemeral) return;
        return (
          <MemberForm
            key={index}
            noDivider={contactOnly === member.ref}
            errors={memberErrors?.[index] || null}
            title={`Account ${index + 1}`}
            member={member}
            onChange={(updatedMember) => {
              const updatedMembers = [...members];
              updatedMembers[index] = updatedMember;
              setMembers(updatedMembers);
            }}
            onRemove={() => {
              const updatedMembers = [...members];
              updatedMembers.splice(index, 1);
              setMembers(updatedMembers);
            }}
          />
        );
      })}
      {!contactOnly && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginBottom: "8px",
          }}
        >
          <Button
            variant="text"
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "0px",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={handleAddMember}
          >
            Add family account
          </Button>
        </Box>
      )}
      {error && (
        <Box sx={{ color: "error.main", marginBottom: "8px" }}>{error}</Box>
      )}
    </form>
  );
};
