import { useEffect, useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import { EphemeralAdvisor, FormErrors } from "./types";
import { useUpdateAdvisor } from "services/advisor";
import AdvisorForm from "./AdvisorForm";
import { validateForm } from "./utils";
import { Advisor } from "protogen/common_pb";
import { updates } from "./types";

const _fromAdvisor = (advisor: Advisor): EphemeralAdvisor => {
  return {
    ref: advisor.ref,
    firstName: advisor.firstName,
    lastName: advisor.lastName,
    username: advisor.username,
    personalEmail: advisor.personalEmail,
    personalPhone: advisor.personalPhone,
    advisorPhone: advisor.advisorPhone,
    timezone: advisor.timezone,
    status: advisor.status,
    address: advisor.address,
  };
};

const hasUpdates = (advisor: Advisor, data: EphemeralAdvisor): boolean => {
  const changes = updates(advisor, data);
  return (
    changes.updatedFirstName !== "" ||
    changes.updatedLastName !== "" ||
    changes.updatedPersonalEmail !== "" ||
    changes.updatedPersonalPhone !== "" ||
    changes.updatedTimezone !== "" ||
    changes.updatedStatus !== ""
  );
};
interface Props {
  advisor: Advisor;
  open: boolean;
  onClose: (c?: boolean) => void;
}

export default ({ advisor, open, onClose }: Props) => {
  const [errors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralAdvisor>(
    _fromAdvisor(advisor),
  );
  const { request, loading } = useUpdateAdvisor(() => {
    onClose(true);
  });

  useEffect(() => {
    setFormData(_fromAdvisor(advisor));
  }, [open]);

  const handleCreate = () => {
    if (validateForm(formData)) {
      request(updates(advisor, formData));
    }
  };

  return (
    <ReactiveDialog
      fullWidthSize="sm"
      open={open}
      onClose={() => onClose(false)}
      title={`Update Advisor`}
    >
      <AdvisorForm
        formData={formData}
        updateFormData={(updates) =>
          setFormData((pv) => ({ ...pv, ...updates }))
        }
        errors={errors}
        onSubmit={handleCreate}
        loading={loading}
        actionName="Save Changes"
        editMode={true}
        disabled={!hasUpdates(advisor, formData)}
      />
    </ReactiveDialog>
  );
};
