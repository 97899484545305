import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";

interface TimeRange {
  value: string | null;
  label: string;
}

export const TIME_RANGES: TimeRange[] = [
  { value: null, label: "Any time" },
  { value: "days(-1)", label: "Today" },
  // { value: 'days(-1, -2)', label: 'Yesterday' },
  { value: "days(-7)", label: "Last 7 days" },
  { value: "days(-30)", label: "Last 30 days" },
  { value: "months(-3)", label: "Last 3 months" },
];

interface Props {
  selectedRange: string | null;
  onChange: (range: string | null) => void;
  error?: string | null;
  disabled?: boolean;
}

export default ({ selectedRange, onChange, error, disabled }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    onChange(selectedValue === "" ? null : selectedValue);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      {/*<InputLabel>Priority</InputLabel>*/}
      <Select
        disabled={disabled}
        error={!!error}
        // label="Priority"
        // input={<OutlinedInput label="Priority" />}
        value={selectedRange || ""}
        onChange={handleChange}
      >
        {TIME_RANGES.map((range) => (
          <MenuItem key={range.value} value={range.value || ""}>
            <ListItem component="div" disablePadding>
              {range.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
