import { ButtonProps } from "@mui/material";
import { LucideIcon } from "lucide-react";
import { ReactNode } from "react";

interface EditIconProps extends ButtonProps {
  IconComponent?: LucideIcon;
  children?: ReactNode;
  isActive?: boolean;
}
export default ({
  IconComponent,
  isActive,
  children,
  ...otherProps
}: EditIconProps) => {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "none",
        padding: "6px",
        height: "32px",
        width: "32px",
        color: "#8E9598",
        // Needs a "pressed" state
        ...(isActive ? { backgroundColor: "#e8e8e8" } : { background: "none" }),
      }}
      {...otherProps}
    >
      {IconComponent && <IconComponent size={20} />}
      {children}
    </button>
  );
};
