import {
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import { ChevronDown } from "lucide-react";
import { DatePicker } from "@mui/x-date-pickers";
import { Task, TaskBlock, TaskState } from "protogen/tasks_pb";
import {
  useUpdateTask,
  useCreateTaskBlock,
  useCreateMemberTaskBlock,
  useUpdateMemberTask,
  useCreateTask,
  useCreateMemberTask,
} from "services/tasks";
import {
  EphemeralTask,
  TaskFormErrors,
  updates,
  validateTask,
} from "../../types/tasks";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";
import TimeTrackingBlock from "./blocks/TimeTrackingBlock";
import DescriptionBlock, { Handle } from "./blocks/DescriptionBlock";
import TaskRequirementsBlock from "./blocks/TaskRequirementsBlock";
import ConversationBookmarkBlock from "./blocks/ConversationBookmarkBlock";
import DocumentBlock from "./blocks/DocumentBlock";
import { useIsNewTask } from "./utils";
import TimeCompletion from "./TimeCompletion";
import { celebrate, getElementCenter } from "../../common/celebrations";
import useIsMobile from "../hooks/useIsMobile";
import PrioritySelect from "../creation/PrioritySelect";
import TaskStateSelector from "./TaskStateSelector";
import { getStateLabel } from "./constants";
import { BlockHandle } from "./blocks/utils";
import { ActiveSparkles } from "./TaskSuggestions";
import WebResearchBlock from "./blocks/WebResearchBlock";
import SimpleDocument from "./SimpleDocument";
import { RichContent } from "../editor/utils";
import CurrentUserAvatar from "../common/CurrentUserAvatar";
import {
  getPriorityLabel,
  getLabelColor,
  getLabelBackgroundColor,
  getLabelBorder,
} from "../creation/PrioritySelect";
import { dateStringToDayAndDate } from "./utils";
import { Family } from "protogen/advisors_service_pb";
import { getFormattedLocation } from "../family/utils";
import { CreateTaskRequest } from "protogen/tasks_service_pb";
import { StatusContext } from "components/context/StatusContextProvider";
import RoundedDropDown, { Option } from "../common/RoundedDropDown";
import DocumentEditor from "components/editor/DocumentEditor";
import { stripImgSrc } from "../../common/utils";

const validDueDate = (
  dueDate: string | null | undefined,
  ignorePast: boolean = false,
): null | Date => {
  if (!dueDate) {
    return null;
  }
  const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  // Check the format
  if (!regex.test(dueDate)) {
    return null;
  }
  const date = new Date(dueDate);
  if (!ignorePast && date < new Date()) {
    return null;
  }
  return date;
};

const BlockSection = ({
  task,
  detailsRef,
  doToast,
  accountType = "advisor",
  family,
  updateTask,
  onDeleteTaskBlock,
}: {
  task: Task;
  detailsRef: RefObject<Handle>;
  doToast: (key: bigint, content?: string) => void;
  accountType: "advisor" | "member";
  family?: Family;
  updateTask: (t: Task) => void;
  onDeleteTaskBlock: { (ref: string): void };
}) => {
  const [latestRefs, setLatestRefs] = useState<BlockHandle[]>([]);
  const blockRefs = useRef<BlockHandle[]>([]);
  const [blocksLoading, setBlocksLoading] = useState(true);

  const addBlockRef = (ref: BlockHandle | null, idx: number) => {
    if (ref) {
      blockRefs.current[idx] = ref;
    }
  };
  const moveToFront = (arr: TaskBlock[], type: string) => {
    // Filter out the items that match the value
    const matchingItems = arr.filter((item) => item.type === type);
    // Filter out the items that don't match the value
    const nonMatchingItems = arr.filter((item) => item.type !== type);
    // Concatenate the matching items at the front, followed by the non-matching items
    return [...matchingItems, ...nonMatchingItems];
  };

  useEffect(() => {
    setLatestRefs(blockRefs.current);
  }, [blockRefs.current]);
  // Some historical tasks have misordered descriptions, lets fix that now.
  let reoderedBlocks = moveToFront(task.blocks, "description");
  reoderedBlocks = moveToFront(task.blocks, "time-tracking");
  const blockComponents = reoderedBlocks.flatMap((block, i) => {
    if (block.type === "time-tracking") {
      return (
        <TimeTrackingBlock
          ref={(ref: BlockHandle | null) => addBlockRef(ref, i)}
          key={block.ref}
          block={block}
          task={task}
          onUpdate={(n) => {
            doToast(n);
          }}
          updateTask={updateTask}
        />
      );
    } else if (block.type === "description") {
      return [
        <DescriptionBlock
          ref={detailsRef}
          key={block.ref}
          block={block}
          task={task}
          onUpdate={(n) => {
            doToast(n);
          }}
        />,
      ];
    } else if (block.type === "conversation-bookmark") {
      return (
        <ConversationBookmarkBlock
          key={block.ref}
          block={block}
          task={task}
          accountType={accountType}
          ref={(ref: BlockHandle | null) => addBlockRef(ref, i)}
        />
      );
    } else if (block.type === "requirement-list") {
      return (
        <TaskRequirementsBlock
          ref={(ref: BlockHandle | null) => addBlockRef(ref, i)}
          key={block.ref}
          block={block}
          task={task}
          accountType={accountType}
          onUpdate={(n) => {
            doToast(n);
          }}
        />
      );
    } else if (block.type === "internet-research") {
      const mappedAddresses: [string, string][] = [];
      if (family) {
        mappedAddresses.push([
          family.name,
          getFormattedLocation(family.address),
        ]);
      }
      const details = reoderedBlocks.find((b) => b.type === "requirement-list");
      if (details && details.content) {
        const parsed = JSON.parse(details.content);
        const labelRegex =
          /\b(home|location|address|office|place|residence|site|spot)\b/i;
        for (const d of parsed?.content[0]?.attrs?.details || []) {
          if (d?.value && d?.description.match(labelRegex)) {
            mappedAddresses.push([d.description, d.value]);
          }
        }
      }
      return (
        <WebResearchBlock
          key={block.ref}
          block={block}
          task={task}
          ref={(ref: BlockHandle | null) => addBlockRef(ref, i)}
          mappedAddresses={mappedAddresses}
        />
      );
    } else if (block.type === "document") {
      return (
        <DocumentBlock
          ref={(ref: BlockHandle | null) => addBlockRef(ref, i)}
          key={block.ref}
          block={block}
          task={task}
          onUpdate={(n, c) => {
            doToast(n, c);
          }}
          accountType={accountType}
          onDeleteTaskBlock={onDeleteTaskBlock}
        />
      );
    }
  });

  // Locally poll for changes to the subcomponent loading states.
  useEffect(() => {
    if (blocksLoading) {
      const interval = setInterval(() => {
        if (!(latestRefs || []).some((r) => r.isPolling?.())) {
          setBlocksLoading(false);
        }
      }, 200);

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [blocksLoading, latestRefs]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {blockComponents}
      {blocksLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            marginBottom: "24px",
          }}
        >
          <ActiveSparkles />
          <Typography variant="bodySmall">Loading suggestions...</Typography>
        </Box>
      )}
    </Box>
  );
};

interface TaskPropertiesProps {
  formData: EphemeralTask;
  updateFormData: (f: EphemeralTask) => void | Promise<void>;
  errors: TaskFormErrors | null;
  onNewlyCompleted: () => void;
  accountType: "advisor" | "member";
}

const TaskProperties = ({
  errors,
  formData,
  updateFormData,
  onNewlyCompleted,
  accountType,
}: TaskPropertiesProps) => {
  const isMobile = useIsMobile();
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <Box>
      {accountType === "advisor" ? (
        <Box
          sx={{
            display: "flex",
            gap: isMobile ? "8px" : "17px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <Box sx={{ width: isMobile ? "50%" : "160px" }}>
              <PrioritySelect
                disabled={false}
                error={errors?.priority}
                selectedPriority={formData.priority || null}
                onChange={(p) => updateFormData({ priority: p || undefined })}
              />
            </Box>
            <Box sx={{ width: isMobile ? "50%" : "160px" }}>
              <DatePicker
                disabled={false}
                sx={(theme) => ({
                  ".MuiOutlinedInput-input": {
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "20px",
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                    fontWeight: "500",
                    marginBottom: "15px",
                  },
                  ".MuiInputAdornment-root": {
                    padding: "2px 16px 0px 0px !important",
                    ".MuiButtonBase-root": {
                      padding: "0px",
                    },
                  },
                  svg: {
                    margin: "0px",
                    marginBottom: "18px",
                  },
                  width: "100%",
                  height: "44px",
                  minHeight: "44px",
                  div: {
                    padding: "8px",
                    fieldset: {
                      border: "2px solid #EAEBEC",
                      borderRadius: "100px",
                      height: "44px",
                      minHeight: "44px",
                      color: "#8E9598",
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                  },
                })}
                slots={{
                  openPickerIcon: () => (
                    <ChevronDown size={20} strokeWidth="2px" color="#8E9598" />
                  ),
                }}
                onChange={(v: Date | null) =>
                  updateFormData({
                    dueDate: v ? v.toLocaleDateString() : undefined,
                  })
                }
                open={dateOpen}
                value={validDueDate(formData.dueDate, true) || null}
                onClose={() => setDateOpen(false)}
                slotProps={{
                  textField: {
                    color: "primary",
                    error: !!errors?.dueDate,
                    helperText: errors?.dueDate,
                    onClick: () => setDateOpen(true),
                    placeholder: "Due date",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ width: isMobile ? "100%" : "160px" }}>
            <TaskStateSelector
              disabled={false}
              selected={formData.state || TaskState.OPEN}
              onChange={(s) => {
                if (s === TaskState.COMPLETED) {
                  updateFormData({
                    state: s,
                    completionDateSec: BigInt(Math.floor(Date.now() / 1000)),
                  });
                  onNewlyCompleted();
                  return;
                }
                updateFormData({ state: s });
              }}
              error={errors?.state}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Chip
            label={getPriorityLabel(formData.priority || "")}
            sx={{
              color: getLabelColor(formData.priority || ""),
              background: getLabelBackgroundColor(formData.priority || ""),
              border: getLabelBorder(formData.priority || ""),
              height: isMobile ? "28px" : "35px",
              width: isMobile ? "64px" : "95px",
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: "600",
            }}
          />
          <Chip
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {getStateLabel(formData.state!)}
              </Box>
            }
            sx={{
              color: "#3D3D3D",
              background: "#FFF",
              height: isMobile ? "28px" : "35px",
              border: "2px solid #D4D4D4",
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: "600",
            }}
          />
          {formData.dueDate && (
            <Chip
              label={
                "Due: " +
                dateStringToDayAndDate(formData.dueDate || "", false)[1]
              }
              sx={{
                color: "#3D3D3D",
                fontWeight: "600",
                background: "#FFF",
                height: isMobile ? "28px" : "35px",
                fontSize: isMobile ? "14px" : "16px",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

interface FooterProps {
  formData: EphemeralTask;
  updateFormData: (f: EphemeralTask) => void | Promise<void>;
  doneButtonRef: RefObject<HTMLButtonElement>;
  onNewlyCompleted: () => void;
  onCreateDocument: () => void;
}

const Footer = ({
  formData,
  updateFormData,
  doneButtonRef,
  onNewlyCompleted,
  onCreateDocument,
}: FooterProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "21px",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "12px",
            flexGrow: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Button variant="outlined" onClick={onCreateDocument}>
              Create a document
            </Button>
          </Box>
        </Box>
        <Box>
          <Button
            ref={doneButtonRef}
            variant="outlined"
            onClick={() => {
              if (formData.state !== TaskState.COMPLETED) {
                onNewlyCompleted();
                celebrate(
                  doneButtonRef.current
                    ? getElementCenter(doneButtonRef.current)
                    : undefined,
                );
                updateFormData({
                  state: TaskState.COMPLETED,
                  completionDateSec: BigInt(Math.floor(Date.now() / 1000)),
                });
              } else {
                updateFormData({
                  state: TaskState.OPEN,
                });
              }
            }}
          >
            {formData.state !== TaskState.COMPLETED
              ? "Mark as done"
              : "Reopen task"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

type Props = {
  task: Task;
  updateTask: (t: Task) => void;
  updateBufferMsec?: number;
  accountType?: "member" | "advisor";
  family?: Family;
  isUnsavedTask?: boolean;
};
export default ({
  task,
  updateTask,
  updateBufferMsec = 2500,
  accountType = "advisor",
  family,
  isUnsavedTask = false,
}: Props) => {
  const navigate = useNavigate();
  const { families } = useContext(StatusContext);
  const isFresh = useIsNewTask();
  const firstRender = useRef(true);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [formErrors, setFormErrors] = useState<TaskFormErrors | null>(null);
  const [newlyCompleted, setNewlyCompleted] = useState(false);
  const [_isUnsavedTask, _setIsUnsavedTask] = useState(isUnsavedTask);
  const [selectedFamilyRef, setSelectedFamilyRef] = useState<string>("");
  const doneButtonRef = useRef<HTMLButtonElement>(null);
  const [content, setContent] = useState<RichContent | null>(null);
  const [formData, setFormData] = useState<EphemeralTask>({
    ref: task.ref,
    state: task.state,
    title: task.title,
    dueDate: task.dueDate,
    priority: task.priority,
    assigneeRef: task?.assignee?.ref,
    hidden: task.hidden,
    hoursReported: task.timing?.hoursReported || undefined,
    minutesReported: task.timing?.minutesReported || undefined,
    completionDateSec: task.completionDateSec || undefined,
    sourceEntityType: task.sourceEntityType,
    sourceEntityRef: task.sourceEntityRef,
  });
  const notesRef = useRef<Handle>(null);
  const useUpdateTaskFn =
    accountType === "advisor" ? useUpdateTask : useUpdateMemberTask;
  const { request } = useUpdateTaskFn(() => {});
  const createTaskBlockRequestFn =
    accountType === "advisor" ? useCreateTaskBlock : useCreateMemberTaskBlock;
  const { request: createTaskBlockRequest } = createTaskBlockRequestFn((r) => {
    if (!r?.block) return;
    const updatedTask = task.clone();
    updatedTask.blocks = [...updatedTask.blocks, r.block];
    updateTask(updatedTask);
  });

  const createTaskRequestFn =
    accountType === "advisor" ? useCreateTask : useCreateMemberTask;

  const { request: createTaskRequest } = createTaskRequestFn((r) => {
    if (!r.task?.ref) return;
    updateTask(r.task);
    _setIsUnsavedTask(false);
    navigate(`/tasks/${encodeURIComponent(r.task?.ref)}`, { replace: true });
  });

  const _validate = (payload: EphemeralTask) => {
    const errors = validateTask(payload);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onCreateTaskBlock = async (
    type: string,
    isShareable: boolean,
    content?: RichContent,
    isRichDocument?: boolean,
  ) => {
    const metadata = { isShared: isShareable, isRichDocument: isRichDocument };
    await createTaskBlockRequest({
      taskRef: task.ref,
      type: type,
      content: stripImgSrc(content?.html || ""),
      textContent: content?.text || "",
      attachments: content?.attachments || [],
      contentType: "html",
      metadata: JSON.stringify(metadata),
    });
  };

  const onDeleteTaskBlock = async (ref: string) => {
    const updatedTask = task.clone();
    updatedTask.blocks = updatedTask.blocks.filter((b) => b.ref !== ref);
    updateTask(updatedTask);
  };
  // Use the debounce function to save data with a delay
  const handleUpdateTask = async (payload: EphemeralTask) => {
    if (_validate(payload)) {
      const result = await request(updates(task, payload));
      if (result) {
        updateTask(result.task);
        doToast(result.task?.lastUpdatedSec || BigInt(0));
      }
    }
  };

  const doToast = (key: bigint, content?: string) => {
    setSnackPack((p) => [
      ...p,
      {
        message: content || "Changes saved!",
        saveNotification: true,
        key: `${key}`,
      },
    ]);
  };
  const debouncedUpdateTask = useMemo(() => {
    return debounce(
      async (p) => {
        await handleUpdateTask(p);
      },
      updateBufferMsec,
      { trailing: true },
    );
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (_isUnsavedTask) return;
    debouncedUpdateTask(formData);
  }, [formData]);

  useEffect(() => {
    const taskAgeSecs = new Date().getTime() / 1000 - Number(task.createdSec);
    // If the page is refreshed, lets not show this.
    if (isFresh && taskAgeSecs < 30) {
      setSnackPack((p) => [
        ...p,
        {
          message: "Task created!",
          saveNotification: true,
          key: `new-task`,
        },
      ]);
    }
    if (accountType !== "advisor") {
      return;
    }
  }, [task.ref]);

  const updateFormData = (t: EphemeralTask) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...t,
    }));
  };

  const createNewTask = async () => {
    _validate(formData);
    const selectedFamily = family
      ? family
      : families.find((f) => f.ref === selectedFamilyRef);

    await createTaskRequest(
      new CreateTaskRequest({
        familyRef: selectedFamily?.ref,
        title: formData.title,
        assigneeRef: selectedFamily?.advisorRef,
        priority: "medium",
        sourceEntityType: formData.sourceEntityType,
        sourceEntityRef: formData.sourceEntityRef,
        details: {
          payload: content?.html || "",
          textContent: content?.text || "",
          contentType: "html",
          attachments: content?.attachments || [],
        },
      }),
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "850px",
        }}
      >
        {_isUnsavedTask && !family && (
          <Box sx={{ marginBottom: "8px" }}>
            <RoundedDropDown
              initial={selectedFamilyRef}
              onChange={(s) => s?.value && setSelectedFamilyRef(s.value)}
              options={
                families.map((f) => ({
                  label: f.name,
                  value: f.ref,
                })) as Option[]
              }
              displayValue={
                families.find((f) => f.ref === selectedFamilyRef)?.name ||
                "Family (Required)"
              }
              sx={{
                fieldset: {
                  borderRadius: "16px",
                  border: "2px solid #EAEBEC !important",
                },
                height: "52px",
              }}
            />
          </Box>
        )}
        <TextField
          variant="standard"
          fullWidth
          multiline
          error={!!formErrors?.title}
          value={formData.title || ""}
          onChange={(e) => updateFormData({ title: e.target.value })}
          margin="none"
          placeholder="Task name"
          InputProps={{
            disableUnderline: true,
            sx: (theme) => ({
              ...theme.typography.title,
              fontFamily: "AlbertSans",
              paddingBottom: _isUnsavedTask ? "0px" : "20px", // To add clickable area
              border: formErrors?.title
                ? `2px solid ${theme.palette.error.main}`
                : _isUnsavedTask
                  ? `1px solid ${theme.palette.border}`
                  : "none",
              borderRadius: "16px",
              padding: "12px 0",
              ".MuiInputBase-input": {
                paddingLeft: _isUnsavedTask ? "18px" : undefined,
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "26px",
                lineHeight: "35px",
                fontWeight: "500",
              },
            }),
          }}
        />
        {_isUnsavedTask && accountType === "advisor" && (
          <DocumentEditor
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "18px 16px 18px 20px",
              border: "1px solid #D4D4D4",
              borderRadius: "16px",
            }}
            setContent={setContent}
            passiveEditor={true}
            placeholder={"Write details about this task"}
            attachmentsEnabled={true}
            editorMinHeight={"74px"}
          />
        )}
        {_isUnsavedTask && (
          <Box
            sx={{
              marginTop: "17px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              {accountType === "advisor" && (
                <TaskProperties
                  errors={formErrors}
                  formData={formData}
                  updateFormData={updateFormData}
                  onNewlyCompleted={() => setNewlyCompleted(true)}
                  key={"task-properties"}
                  accountType={accountType}
                />
              )}
            </Box>
            <Button sx={{ alignSelf: "flex-end  " }} onClick={createNewTask}>
              Save
            </Button>
          </Box>
        )}

        {/* All the components after we have a task saved in the backend - not a "new task" */}
        {!_isUnsavedTask && (
          <Box>
            <Box sx={{ marginBottom: "24px" }}>
              <TaskProperties
                errors={formErrors}
                formData={formData}
                updateFormData={updateFormData}
                onNewlyCompleted={() => setNewlyCompleted(true)}
                key={"task-properties"}
                accountType={accountType}
              />
            </Box>
            <BlockSection
              family={family}
              task={task}
              detailsRef={notesRef}
              doToast={doToast}
              accountType={accountType}
              updateTask={updateTask}
              onDeleteTaskBlock={onDeleteTaskBlock}
            />
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "8px",
                marginBototm: "24px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <CurrentUserAvatar size={34} autoscale={false} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <SimpleDocument
                  onSaveDocument={async (content, isShareable) => {
                    if (accountType === "member") {
                      isShareable = true;
                    }
                    await onCreateTaskBlock("document", isShareable, content);
                  }}
                  isCreate={true}
                  accountType={accountType}
                  familyMembers={family?.familyMembers || []}
                />
              </Box>
            </Box>
            {accountType === "advisor" && (
              <Box sx={{ marginTop: "16px" }}>
                {formData.state === TaskState.COMPLETED && (
                  <TimeCompletion newlyCompleted={newlyCompleted} task={task} />
                )}
                <Divider sx={{ margin: "40px 0px 24px 0px" }} />
                <Footer
                  formData={formData}
                  updateFormData={updateFormData}
                  doneButtonRef={doneButtonRef}
                  onNewlyCompleted={() => setNewlyCompleted(true)}
                  onCreateDocument={() => {
                    onCreateTaskBlock("document", false, undefined, true);
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <SnackPack
        autoHideDuration={2000}
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Box>
  );
};
