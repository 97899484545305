import { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/system";
import useIsMobile from "../hooks/useIsMobile";

export type ConfirmationState = {
  openDialog: (
    confirmCallback: () => void,
    cancelCallback?: () => void,
  ) => void;
  closeDialog: () => void;
  dialogProps: {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  };
};

export const useConfirmationDialog = (): ConfirmationState => {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);
  const [onCancel, setOnCancel] = useState<(() => void) | null>(null);

  const openDialog = useCallback(
    (confirmCallback: () => void, cancelCallback?: () => void) => {
      setIsOpen(true);
      setOnConfirm(() => confirmCallback);
      setOnCancel(() => cancelCallback);
    },
    [],
  );

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  const confirm = useCallback(() => {
    if (onConfirm) onConfirm();
    closeDialog();
  }, [onConfirm, closeDialog]);

  const cancel = useCallback(() => {
    if (onCancel) onCancel();
    closeDialog();
  }, [onCancel, closeDialog]);

  return {
    openDialog,
    closeDialog,
    dialogProps: {
      isOpen,
      onConfirm: confirm,
      onCancel: cancel,
    },
  };
};

type Props = {
  isOpen: boolean;
  fullScreen?: boolean;
  // Passed in by state.
  title?: string;
  content?: string;
  onConfirm: () => void;
  onCancel: () => void;
  noIcon?: boolean;
};

export default ({
  title = "Confirmation",
  content = "Are you sure you want to proceed",
  isOpen,
  onConfirm,
  onCancel,
  fullScreen,
  noIcon = false,
}: Props) => {
  const isMobile = useIsMobile();
  // Defer to an explicit fullScreen prop if it's provided
  const isFullScreen = fullScreen === undefined ? isMobile : fullScreen;
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="sm"
      fullScreen={isFullScreen}
      PaperComponent={Paper}
      PaperProps={{
        style: {
          ...(isFullScreen
            ? {
                boxShadow: "none",
              }
            : {
                borderRadius: "12px",
                backgroundColor: "var(--base-white, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                border: "1px solid #EAECF0",
              }),
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" alignItems="center" gap="12px">
          {!noIcon && (
            <WarningAmberIcon
              sx={{
                height: "36px",
                width: "36px",
              }}
            />
          )}
          <DialogContentText id="confirmation-dialog-description">
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <Button variant="outlined" fullWidth={isFullScreen} onClick={onCancel}>
          No
        </Button>
        <Button fullWidth={isFullScreen} onClick={onConfirm} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
