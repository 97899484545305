import { FormControlLabel, IconButton, MenuItem, Switch } from "@mui/material";
import { Ellipsis } from "lucide-react";
import { Task } from "protogen/tasks_pb";
import { useContext, useEffect, useRef, useState } from "react";
import { taskDeletion } from "./utils";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdvisor } from "../../common/userUtils";
import { CurrentUser } from "protogen/auth_pb";
import { useUpdateTask } from "services/tasks";
import { EditTaskRequest } from "protogen/tasks_service_pb";
import PopperMenu from "../common/PopperMenu";

const DeleteTask = ({
  task,
  currentUser,
}: {
  task: Task;
  currentUser: CurrentUser;
}) => {
  const { confirmState, deleteTask } = taskDeletion(
    !isUserAdvisor(currentUser),
  );
  return (
    <>
      <MenuItem
        sx={{
          color: "#C14743",
          padding: "8px 0",
          minHeight: "inherit",
        }}
        onClick={() => deleteTask(task.ref)}
      >
        Delete
      </MenuItem>
      <ConfirmationDialog
        title="Delete task"
        noIcon={true}
        content="Are you sure you want to delete this task?"
        {...confirmState.dialogProps}
      />
    </>
  );
};

const HideTask = ({ task, refresh }: { task: Task; refresh: () => void }) => {
  const { request, loading } = useUpdateTask();
  const [latestTask, setLatestTask] = useState<Task>(task);
  const hideTask = async (taskRef: string, hidden: boolean) => {
    const resp = await request(
      new EditTaskRequest({
        taskRef,
        shouldUpdateHidden: true,
        updatedHidden: hidden,
      }),
    );
    if (resp) {
      setLatestTask(resp.task);
    }
    refresh();
  };
  useEffect(() => {
    setLatestTask(task);
  }, [task]);
  return (
    <FormControlLabel
      control={
        <Switch
          edge="end"
          disabled={loading}
          onChange={() => hideTask(task.ref, !latestTask.hidden)}
          checked={latestTask.hidden}
        />
      }
      label={"Hide from client"}
    />
  );
};

export default ({ task, refresh }: { task: Task; refresh: () => void }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const currentUser = useContext(CurrentUserContext);
  const canDelete =
    task.createdBy.ref === currentUser.ref || isUserAdvisor(currentUser);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const options = [];
  if (isUserAdvisor(currentUser)) {
    options.push(<HideTask key={"hide-task"} task={task} refresh={refresh} />);
  }
  if (canDelete) {
    options.push(
      <DeleteTask task={task} currentUser={currentUser} key="delete-task" />,
    );
  }
  if (options.length === 0) return null;
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        sx={{
          borderRadius: "50px",
          border: "2px solid #E2E3E4",
          padding: "4px",
        }}
      >
        <Ellipsis size={20} />
      </IconButton>
      <PopperMenu open={open} anchorRef={anchorRef} handleClose={handleClose}>
        {options}
      </PopperMenu>
    </>
  );
};
