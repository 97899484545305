import FormStep from "./FormStep";
import { validateDetails } from "../utils";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { EphemeralAdvisor, FormErrors } from "../types";
import { hasErrors } from "./utils";
import { Timezones } from "../../common/Timezones";
import { AdvisorStatus } from "protogen/common_pb";
import AddressAutocomplete from "components/common/AddressAutocomplete";

const AdvisorStatusSelect = ({
  status = AdvisorStatus.ACTIVE,
  setStatus,
}: {
  status?: AdvisorStatus;
  setStatus: (s: AdvisorStatus) => void;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">Status</InputLabel>
      <Select
        fullWidth
        labelId="select-label"
        id="select-label"
        value={status}
        label="Status"
        onChange={(e) => setStatus(e.target.value as AdvisorStatus)}
      >
        <MenuItem value={AdvisorStatus.ACTIVE}>Active</MenuItem>
        <MenuItem value={AdvisorStatus.DEACTIVATED}>Deactivated</MenuItem>
        <MenuItem value={AdvisorStatus.TEST}>Test</MenuItem>
        <MenuItem value={AdvisorStatus.STAFF}>Staff</MenuItem>
        <MenuItem value={AdvisorStatus.PENDING}>Pending</MenuItem>
      </Select>
    </FormControl>
  );
};

type Props = {
  formData: EphemeralAdvisor;
  updateFormData: (f: EphemeralAdvisor) => void;
  errors: FormErrors | null;
  handleBack: () => void;
  handleNext: () => void;
};
export default ({
  formData,
  updateFormData,
  errors,
  handleBack,
  handleNext,
  ...otherProps
}: Props) => {
  return (
    <FormStep
      title="Personal Details"
      disabled={hasErrors(validateDetails(formData))}
      handleNext={handleNext}
      handleBack={handleBack}
      {...otherProps}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={!!errors?.firstName}
            helperText={errors?.firstName}
            label="First Name"
            fullWidth
            size="small"
            autoComplete="off"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={formData?.firstName || ""}
            onChange={(e) => updateFormData({ firstName: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={!!errors?.lastName}
            helperText={errors?.lastName}
            label="Last Name"
            fullWidth
            autoComplete="off"
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            value={formData?.lastName || ""}
            onChange={(e) => updateFormData({ lastName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressAutocomplete
            label={"Address"}
            error={errors?.location}
            initialValue={
              formData.address
                ? JSON.parse(formData?.address || "")?.formattedAddress
                : ""
            }
            setValue={(location) => {
              updateFormData({
                address: JSON.stringify(location),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvisorStatusSelect
            status={formData.status}
            setStatus={(s) => updateFormData({ status: s })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Timezone</InputLabel>
            <Select
              fullWidth
              displayEmpty
              value={formData?.timezone || ""}
              onChange={(e) => {
                updateFormData({ timezone: e.target.value });
              }}
            >
              {Timezones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FormStep>
  );
};
