import { Avatar, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import FeedEntryBox from "./FeedEntryBox";

type Address = {
  postOfficeBox: string;
  extendedAddress: string;
  streetAddress: string;
  locality: string; // City
  region: string; // State/Province
  postalCode: string;
  country: string;
};

type ContactInfo = {
  fullName?: string;
  tel?: string;
  email?: string;
  url?: string;
  photo?: string;
  photoFormat?: string;
  prefCellSet?: boolean;
  address?: Address;
};

const parseVcfAddress = (vcfLine: string): Address | null => {
  if (vcfLine.startsWith("ADR")) {
    const components = vcfLine.split(":")[1].split(";");
    return {
      postOfficeBox: components[0],
      extendedAddress: components[1],
      streetAddress: components[2],
      locality: components[3],
      region: components[4],
      postalCode: components[5],
      country: components[6],
    };
  } else {
    return null;
  }
};

const useExtractedContactInfo = (vcfUrl: string) => {
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
  const _extractNameFromVCF = (vcfContent: string): ContactInfo => {
    let tmpContactInfo: ContactInfo = {};
    // Split the VCF content into lines
    const lines = vcfContent.split("\n");
    let photoStart = false;
    // Loop through the lines to find the name fields
    for (const line of lines) {
      const key = line.substring(0, line.indexOf(":")).trim();
      const value = line.substring(line.indexOf(":") + 1).trim();
      if (photoStart && !key) {
        tmpContactInfo.photo += value;
        continue;
      }
      photoStart = false;
      if (key === "FN") {
        tmpContactInfo.fullName = value;
      } else if (key === "N" && !tmpContactInfo.fullName) {
        tmpContactInfo.fullName = value;
      } else if (key.indexOf("CELL") >= 0 && !tmpContactInfo.tel) {
        tmpContactInfo.tel = value;
      } else if (key.indexOf("TEL") >= 0 && !tmpContactInfo.tel) {
        tmpContactInfo.tel = value;
      } else if (key.indexOf("EMAIL") >= 0) {
        tmpContactInfo.email = value;
      } else if (key === "URL") {
        tmpContactInfo.url = value;
      } else if (key.indexOf("ADR") >= 0) {
        const address = parseVcfAddress(line);
        if (address) {
          tmpContactInfo.address = address;
        }
      } else if (key.indexOf("PHOTO") >= 0) {
        tmpContactInfo.photoFormat =
          key.toLowerCase().indexOf("png") >= 0 ? "png" : "jpeg";
        tmpContactInfo.photo = value;
        photoStart = true;
      }
    }
    return tmpContactInfo;
  };
  useEffect(() => {
    fetch(vcfUrl)
      .then((response) => response.text())
      .then((vcfContent) => {
        setContactInfo(_extractNameFromVCF(vcfContent));
      });
  }, [vcfUrl]);

  return contactInfo;
};

export const ContactCard = ({
  fullName,
  imgSrc,
  tel,
  email,
  address,
  onClick,
}: {
  fullName: string;
  imgSrc?: string;
  tel?: string;
  email?: string;
  address?: Address;
  onClick?: () => void;
}) => {
  const nameToInitials = (name: string) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };
  return (
    <FeedEntryBox
      onclick={onClick}
      icon={
        <Avatar sx={{ borderRadius: "5%" }} alt={fullName} src={imgSrc}>
          {nameToInitials(fullName || "?")}
        </Avatar>
      }
      header={fullName || "Unknown"}
      subheader={
        <div
          style={{
            wordBreak: "break-word",
            whiteSpace: "pre-line", // Lets messages use new lines.
          }}
        >
          {tel && <div>{tel}</div>}
          {email && <div>{email}</div>}
          {address && (
            <div>
              <div>{address?.streetAddress}</div>
              <div>
                {address?.locality} {address?.region} {address?.postalCode}{" "}
                {address?.country}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export const LoadingContactCard = ({ onClick }: { onClick?: () => void }) => {
  return (
    <FeedEntryBox
      icon={<Avatar alt={"?"} />}
      header={<Skeleton width="80%" />}
      subheader={<Skeleton width="80%" />}
      onclick={onClick}
    />
  );
};

type Props = {
  vcfUrl: string;
  onClick?: () => void;
};

export default ({ vcfUrl, onClick }: Props) => {
  const contactInfo = useExtractedContactInfo(vcfUrl);
  if (!contactInfo) {
    return <LoadingContactCard onClick={onClick} />;
  }
  const src = contactInfo.photo
    ? `data:image/${contactInfo?.photoFormat};base64,${contactInfo?.photo}`
    : undefined;
  return (
    <ContactCard
      onClick={onClick}
      fullName={contactInfo.fullName || "Unknown"}
      imgSrc={src}
      tel={contactInfo.tel}
      email={contactInfo.email}
      address={contactInfo.address}
    />
  );
};
