import LinkRouter from "../navigation/LinkRouter";
import { useContext } from "react";
import {
  Family,
  FetchAdvisorNotificationStatusesResponse_FamilyStatus as FamilyStatus,
} from "protogen/advisors_service_pb";
import { useLocation } from "react-router-dom";
import { StatusContext } from "../context/StatusContextProvider";
import {
  ExpendableEntryContainer,
  ExpendableEntryItem,
} from "./ExpandableEntry";
import { ReactComponent as HeartTopIcon } from "../../icons/Menu/HeartTop.svg";

const FamilyEntry = ({
  family,
  familyStatus,
}: {
  family: Family;
  familyStatus: FamilyStatus;
}) => {
  const location = useLocation();
  const regex = new RegExp(`/families/${encodeURIComponent(family.ref)}/?.*$`);
  const isSelected = regex.test(location.pathname);
  const hasUnread =
    familyStatus.unreadMessages +
      familyStatus.unreadEmails +
      familyStatus.missedCalls >
    0;
  return (
    <ExpendableEntryItem
      selected={isSelected}
      unread={hasUnread}
      content={family.name}
    />
  );
};

type Props = {
  selected: boolean;
  open: boolean;
  onClick: () => void;
};

export default ({ selected, open, onClick }: Props) => {
  const { families, loaded, familyStatus } = useContext(StatusContext);
  const myFamilies = (families || []).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <ExpendableEntryContainer
      icon={<HeartTopIcon height="20px" width="20px" />}
      selected={selected}
      open={open}
      onClick={onClick}
      content="Families"
      url="/families"
      loading={!loaded}
      stateName="faye.settings.families-expanded"
    >
      {myFamilies.map((family) => (
        <LinkRouter
          key={family.ref}
          to={`/families/${encodeURIComponent(family.ref)}`}
          onClick={() => onClick()}
        >
          <FamilyEntry
            family={family}
            familyStatus={familyStatus.get(family.ref)!}
          />
        </LinkRouter>
      ))}
    </ExpendableEntryContainer>
  );
};
