import FormStep from "./FormStep";
import { validateContact } from "../utils";
import { Grid, TextField } from "@mui/material";
import PhoneInput from "../../creation/PhoneInput";
import { EphemeralAdvisor, FormErrors } from "../types";
import { hasErrors } from "./utils";
import { cleanPhone } from "../../../common/utils";

type Props = {
  formData: EphemeralAdvisor;
  updateFormData: (f: EphemeralAdvisor) => void;
  errors: FormErrors | null;
  handleNext: () => void;
};
export default ({
  formData,
  updateFormData,
  errors,
  handleNext,
  ...otherProps
}: Props) => {
  return (
    <FormStep
      title="Contact Information"
      disabled={hasErrors(validateContact(formData))}
      handleNext={handleNext}
      {...otherProps}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={!!errors?.email}
            helperText={errors?.email}
            label="Personal Email"
            fullWidth
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            type="email"
            size="small"
            autoComplete="off"
            value={formData?.personalEmail || ""}
            onChange={(e) => updateFormData({ personalEmail: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            value={formData?.personalPhone || ""}
            onChange={(phone) =>
              updateFormData({
                personalPhone: cleanPhone(
                  typeof phone === "string" ? phone : phone.target.value,
                ),
              })
            }
            error={!!errors?.personalPhone}
            helperText={errors?.personalPhone}
            label="Personal Phone"
            fullWidth
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </FormStep>
  );
};
