// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/tasks_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ContentUpdates } from "./notes_pb.ts";
import { DefaultTaskDetail, Task, TaskBlock, TaskBlockStatus, TaskInspiration, TaskInspirationCategory, TaskState } from "./tasks_pb.ts";
import { Family } from "./advisors_service_pb.ts";
import { FamilyStub, UploadAttachment } from "./common_pb.ts";
import { EmailMessage } from "./conversation_pb.ts";

/**
 * @generated from message protogen.CreateTaskRequest
 */
export class CreateTaskRequest extends Message<CreateTaskRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string priority = 4;
   */
  priority = "";

  /**
   * @generated from field: string dueDate = 5;
   */
  dueDate = "";

  /**
   * @generated from field: string assigneeRef = 6;
   */
  assigneeRef = "";

  /**
   * @generated from field: bool hidden = 7;
   */
  hidden = false;

  /**
   * @generated from field: protogen.ContentUpdates details = 8;
   */
  details?: ContentUpdates;

  /**
   * @generated from field: string sourceEntityType = 9;
   */
  sourceEntityType = "";

  /**
   * @generated from field: string sourceEntityRef = 10;
   */
  sourceEntityRef = "";

  /**
   * @generated from field: int32 sourceEntityIndex = 11;
   */
  sourceEntityIndex = 0;

  constructor(data?: PartialMessage<CreateTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "dueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "assigneeRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "details", kind: "message", T: ContentUpdates },
    { no: 9, name: "sourceEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "sourceEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "sourceEntityIndex", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined, b: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskResponse
 */
export class CreateTaskResponse extends Message<CreateTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<CreateTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined, b: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskRequest
 */
export class GetTaskRequest extends Message<GetTaskRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  /**
   * @generated from field: bool markTaskNotificationsRead = 2;
   */
  markTaskNotificationsRead = false;

  constructor(data?: PartialMessage<GetTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markTaskNotificationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined, b: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined): boolean {
    return proto3.util.equals(GetTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskResponse
 */
export class GetTaskResponse extends Message<GetTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  /**
   * @generated from field: protogen.Family family = 2;
   */
  family?: Family;

  constructor(data?: PartialMessage<GetTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
    { no: 2, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined, b: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined): boolean {
    return proto3.util.equals(GetTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetMemberTaskRequest
 */
export class GetMemberTaskRequest extends Message<GetMemberTaskRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  constructor(data?: PartialMessage<GetMemberTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberTaskRequest {
    return new GetMemberTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberTaskRequest {
    return new GetMemberTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberTaskRequest {
    return new GetMemberTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberTaskRequest | PlainMessage<GetMemberTaskRequest> | undefined, b: GetMemberTaskRequest | PlainMessage<GetMemberTaskRequest> | undefined): boolean {
    return proto3.util.equals(GetMemberTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetMemberTaskResponse
 */
export class GetMemberTaskResponse extends Message<GetMemberTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<GetMemberTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberTaskResponse {
    return new GetMemberTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberTaskResponse {
    return new GetMemberTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberTaskResponse {
    return new GetMemberTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberTaskResponse | PlainMessage<GetMemberTaskResponse> | undefined, b: GetMemberTaskResponse | PlainMessage<GetMemberTaskResponse> | undefined): boolean {
    return proto3.util.equals(GetMemberTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskRequest
 */
export class EditTaskRequest extends Message<EditTaskRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  /**
   * @generated from field: string updatedTitle = 2;
   */
  updatedTitle = "";

  /**
   * @generated from field: string updatedPriority = 4;
   */
  updatedPriority = "";

  /**
   * @generated from field: string updatedDueDate = 5;
   */
  updatedDueDate = "";

  /**
   * @generated from field: string updatedAssigneeRef = 6;
   */
  updatedAssigneeRef = "";

  /**
   * @generated from field: protogen.TaskState updatedState = 7;
   */
  updatedState = TaskState.UNSPECIFIED;

  /**
   * @generated from field: bool shouldUpdateHidden = 10;
   */
  shouldUpdateHidden = false;

  /**
   * @generated from field: bool updatedHidden = 11;
   */
  updatedHidden = false;

  constructor(data?: PartialMessage<EditTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedPriority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedDueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updatedAssigneeRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updatedState", kind: "enum", T: proto3.getEnumType(TaskState) },
    { no: 10, name: "shouldUpdateHidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "updatedHidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskRequest | PlainMessage<EditTaskRequest> | undefined, b: EditTaskRequest | PlainMessage<EditTaskRequest> | undefined): boolean {
    return proto3.util.equals(EditTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskResponse
 */
export class EditTaskResponse extends Message<EditTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<EditTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskResponse | PlainMessage<EditTaskResponse> | undefined, b: EditTaskResponse | PlainMessage<EditTaskResponse> | undefined): boolean {
    return proto3.util.equals(EditTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTasksBookmarksRequest
 */
export class UpdateTasksBookmarksRequest extends Message<UpdateTasksBookmarksRequest> {
  /**
   * @generated from field: repeated protogen.UpdateTasksBookmarksRequest.TaskBookmark bookmarks = 1;
   */
  bookmarks: UpdateTasksBookmarksRequest_TaskBookmark[] = [];

  constructor(data?: PartialMessage<UpdateTasksBookmarksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTasksBookmarksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookmarks", kind: "message", T: UpdateTasksBookmarksRequest_TaskBookmark, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTasksBookmarksRequest {
    return new UpdateTasksBookmarksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksRequest {
    return new UpdateTasksBookmarksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksRequest {
    return new UpdateTasksBookmarksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTasksBookmarksRequest | PlainMessage<UpdateTasksBookmarksRequest> | undefined, b: UpdateTasksBookmarksRequest | PlainMessage<UpdateTasksBookmarksRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTasksBookmarksRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTasksBookmarksRequest.TaskBookmark
 */
export class UpdateTasksBookmarksRequest_TaskBookmark extends Message<UpdateTasksBookmarksRequest_TaskBookmark> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  /**
   * @generated from field: string entityType = 2;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 3;
   */
  entityRef = "";

  /**
   * @generated from field: bool add = 4;
   */
  add = false;

  /**
   * @generated from field: bool remove = 5;
   */
  remove = false;

  constructor(data?: PartialMessage<UpdateTasksBookmarksRequest_TaskBookmark>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTasksBookmarksRequest.TaskBookmark";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "add", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "remove", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTasksBookmarksRequest_TaskBookmark {
    return new UpdateTasksBookmarksRequest_TaskBookmark().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksRequest_TaskBookmark {
    return new UpdateTasksBookmarksRequest_TaskBookmark().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksRequest_TaskBookmark {
    return new UpdateTasksBookmarksRequest_TaskBookmark().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTasksBookmarksRequest_TaskBookmark | PlainMessage<UpdateTasksBookmarksRequest_TaskBookmark> | undefined, b: UpdateTasksBookmarksRequest_TaskBookmark | PlainMessage<UpdateTasksBookmarksRequest_TaskBookmark> | undefined): boolean {
    return proto3.util.equals(UpdateTasksBookmarksRequest_TaskBookmark, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTasksBookmarksResponse
 */
export class UpdateTasksBookmarksResponse extends Message<UpdateTasksBookmarksResponse> {
  constructor(data?: PartialMessage<UpdateTasksBookmarksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTasksBookmarksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTasksBookmarksResponse {
    return new UpdateTasksBookmarksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksResponse {
    return new UpdateTasksBookmarksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTasksBookmarksResponse {
    return new UpdateTasksBookmarksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTasksBookmarksResponse | PlainMessage<UpdateTasksBookmarksResponse> | undefined, b: UpdateTasksBookmarksResponse | PlainMessage<UpdateTasksBookmarksResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTasksBookmarksResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBlockContentRequest
 */
export class UpdateBlockContentRequest extends Message<UpdateBlockContentRequest> {
  /**
   * @generated from field: string taskBlockRef = 1;
   */
  taskBlockRef = "";

  /**
   * @generated from field: string content = 2;
   */
  content = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: string contentType = 4;
   */
  contentType = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 5;
   */
  attachments: UploadAttachment[] = [];

  constructor(data?: PartialMessage<UpdateBlockContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBlockContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskBlockRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBlockContentRequest {
    return new UpdateBlockContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBlockContentRequest {
    return new UpdateBlockContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBlockContentRequest {
    return new UpdateBlockContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBlockContentRequest | PlainMessage<UpdateBlockContentRequest> | undefined, b: UpdateBlockContentRequest | PlainMessage<UpdateBlockContentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBlockContentRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBlockContentResponse
 */
export class UpdateBlockContentResponse extends Message<UpdateBlockContentResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  constructor(data?: PartialMessage<UpdateBlockContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBlockContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBlockContentResponse {
    return new UpdateBlockContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBlockContentResponse {
    return new UpdateBlockContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBlockContentResponse {
    return new UpdateBlockContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBlockContentResponse | PlainMessage<UpdateBlockContentResponse> | undefined, b: UpdateBlockContentResponse | PlainMessage<UpdateBlockContentResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBlockContentResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBlockMetadataRequest
 */
export class UpdateBlockMetadataRequest extends Message<UpdateBlockMetadataRequest> {
  /**
   * @generated from field: string taskBlockRef = 1;
   */
  taskBlockRef = "";

  /**
   * @generated from field: string key = 2;
   */
  key = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  constructor(data?: PartialMessage<UpdateBlockMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBlockMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskBlockRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBlockMetadataRequest {
    return new UpdateBlockMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBlockMetadataRequest {
    return new UpdateBlockMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBlockMetadataRequest {
    return new UpdateBlockMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBlockMetadataRequest | PlainMessage<UpdateBlockMetadataRequest> | undefined, b: UpdateBlockMetadataRequest | PlainMessage<UpdateBlockMetadataRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBlockMetadataRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateBlockMetadataResponse
 */
export class UpdateBlockMetadataResponse extends Message<UpdateBlockMetadataResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  constructor(data?: PartialMessage<UpdateBlockMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateBlockMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBlockMetadataResponse {
    return new UpdateBlockMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBlockMetadataResponse {
    return new UpdateBlockMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBlockMetadataResponse {
    return new UpdateBlockMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBlockMetadataResponse | PlainMessage<UpdateBlockMetadataResponse> | undefined, b: UpdateBlockMetadataResponse | PlainMessage<UpdateBlockMetadataResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBlockMetadataResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForFamilyRequest
 */
export class ListTasksForFamilyRequest extends Message<ListTasksForFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: repeated protogen.TaskState taskStates = 2;
   */
  taskStates: TaskState[] = [];

  constructor(data?: PartialMessage<ListTasksForFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "taskStates", kind: "enum", T: proto3.getEnumType(TaskState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForFamilyRequest | PlainMessage<ListTasksForFamilyRequest> | undefined, b: ListTasksForFamilyRequest | PlainMessage<ListTasksForFamilyRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForFamilyResponse
 */
export class ListTasksForFamilyResponse extends Message<ListTasksForFamilyResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  constructor(data?: PartialMessage<ListTasksForFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForFamilyResponse | PlainMessage<ListTasksForFamilyResponse> | undefined, b: ListTasksForFamilyResponse | PlainMessage<ListTasksForFamilyResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForAdvisorRequest
 */
export class ListTasksForAdvisorRequest extends Message<ListTasksForAdvisorRequest> {
  /**
   * @generated from field: repeated protogen.TaskState taskStates = 1;
   */
  taskStates: TaskState[] = [];

  /**
   * @generated from field: repeated string familyRefs = 2;
   */
  familyRefs: string[] = [];

  /**
   * @generated from field: repeated string categories = 3;
   */
  categories: string[] = [];

  /**
   * @generated from field: repeated string priorities = 4;
   */
  priorities: string[] = [];

  constructor(data?: PartialMessage<ListTasksForAdvisorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForAdvisorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskStates", kind: "enum", T: proto3.getEnumType(TaskState), repeated: true },
    { no: 2, name: "familyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "priorities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForAdvisorRequest | PlainMessage<ListTasksForAdvisorRequest> | undefined, b: ListTasksForAdvisorRequest | PlainMessage<ListTasksForAdvisorRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForAdvisorRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForAdvisorResponse
 */
export class ListTasksForAdvisorResponse extends Message<ListTasksForAdvisorResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.FamilyStub families = 2;
   */
  families: FamilyStub[] = [];

  constructor(data?: PartialMessage<ListTasksForAdvisorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForAdvisorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 2, name: "families", kind: "message", T: FamilyStub, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForAdvisorResponse | PlainMessage<ListTasksForAdvisorResponse> | undefined, b: ListTasksForAdvisorResponse | PlainMessage<ListTasksForAdvisorResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForAdvisorResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForMemberRequest
 */
export class ListTasksForMemberRequest extends Message<ListTasksForMemberRequest> {
  constructor(data?: PartialMessage<ListTasksForMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForMemberRequest | PlainMessage<ListTasksForMemberRequest> | undefined, b: ListTasksForMemberRequest | PlainMessage<ListTasksForMemberRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForMemberRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForMemberResponse
 */
export class ListTasksForMemberResponse extends Message<ListTasksForMemberResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  constructor(data?: PartialMessage<ListTasksForMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForMemberResponse | PlainMessage<ListTasksForMemberResponse> | undefined, b: ListTasksForMemberResponse | PlainMessage<ListTasksForMemberResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForMemberResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskBlockRequest
 */
export class GetTaskBlockRequest extends Message<GetTaskBlockRequest> {
  /**
   * @generated from field: string taskBlockRef = 1;
   */
  taskBlockRef = "";

  constructor(data?: PartialMessage<GetTaskBlockRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskBlockRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskBlockRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskBlockRequest {
    return new GetTaskBlockRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskBlockRequest {
    return new GetTaskBlockRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskBlockRequest {
    return new GetTaskBlockRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskBlockRequest | PlainMessage<GetTaskBlockRequest> | undefined, b: GetTaskBlockRequest | PlainMessage<GetTaskBlockRequest> | undefined): boolean {
    return proto3.util.equals(GetTaskBlockRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskBlockResponse
 */
export class GetTaskBlockResponse extends Message<GetTaskBlockResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  constructor(data?: PartialMessage<GetTaskBlockResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskBlockResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskBlockResponse {
    return new GetTaskBlockResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskBlockResponse {
    return new GetTaskBlockResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskBlockResponse {
    return new GetTaskBlockResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskBlockResponse | PlainMessage<GetTaskBlockResponse> | undefined, b: GetTaskBlockResponse | PlainMessage<GetTaskBlockResponse> | undefined): boolean {
    return proto3.util.equals(GetTaskBlockResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskBlockRequest
 */
export class CreateTaskBlockRequest extends Message<CreateTaskBlockRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * @generated from field: string textContent = 4;
   */
  textContent = "";

  /**
   * @generated from field: string contentType = 5;
   */
  contentType = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 6;
   */
  attachments: UploadAttachment[] = [];

  /**
   * @generated from field: string metadata = 7;
   */
  metadata = "";

  constructor(data?: PartialMessage<CreateTaskBlockRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskBlockRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
    { no: 7, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskBlockRequest {
    return new CreateTaskBlockRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskBlockRequest {
    return new CreateTaskBlockRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskBlockRequest {
    return new CreateTaskBlockRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskBlockRequest | PlainMessage<CreateTaskBlockRequest> | undefined, b: CreateTaskBlockRequest | PlainMessage<CreateTaskBlockRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskBlockRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskBlockResponse
 */
export class CreateTaskBlockResponse extends Message<CreateTaskBlockResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  constructor(data?: PartialMessage<CreateTaskBlockResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskBlockResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskBlockResponse {
    return new CreateTaskBlockResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskBlockResponse {
    return new CreateTaskBlockResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskBlockResponse {
    return new CreateTaskBlockResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskBlockResponse | PlainMessage<CreateTaskBlockResponse> | undefined, b: CreateTaskBlockResponse | PlainMessage<CreateTaskBlockResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskBlockResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateEmailDraftFromDocumentBlockRequest
 */
export class CreateEmailDraftFromDocumentBlockRequest extends Message<CreateEmailDraftFromDocumentBlockRequest> {
  /**
   * @generated from field: string taskBlockRef = 1;
   */
  taskBlockRef = "";

  constructor(data?: PartialMessage<CreateEmailDraftFromDocumentBlockRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateEmailDraftFromDocumentBlockRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskBlockRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEmailDraftFromDocumentBlockRequest {
    return new CreateEmailDraftFromDocumentBlockRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEmailDraftFromDocumentBlockRequest {
    return new CreateEmailDraftFromDocumentBlockRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEmailDraftFromDocumentBlockRequest {
    return new CreateEmailDraftFromDocumentBlockRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEmailDraftFromDocumentBlockRequest | PlainMessage<CreateEmailDraftFromDocumentBlockRequest> | undefined, b: CreateEmailDraftFromDocumentBlockRequest | PlainMessage<CreateEmailDraftFromDocumentBlockRequest> | undefined): boolean {
    return proto3.util.equals(CreateEmailDraftFromDocumentBlockRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateEmailDraftFromDocumentBlockResponse
 */
export class CreateEmailDraftFromDocumentBlockResponse extends Message<CreateEmailDraftFromDocumentBlockResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  /**
   * @generated from field: string draftRef = 2;
   */
  draftRef = "";

  /**
   * @generated from field: protogen.EmailMessage email = 3;
   */
  email?: EmailMessage;

  constructor(data?: PartialMessage<CreateEmailDraftFromDocumentBlockResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateEmailDraftFromDocumentBlockResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
    { no: 2, name: "draftRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "message", T: EmailMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEmailDraftFromDocumentBlockResponse {
    return new CreateEmailDraftFromDocumentBlockResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEmailDraftFromDocumentBlockResponse {
    return new CreateEmailDraftFromDocumentBlockResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEmailDraftFromDocumentBlockResponse {
    return new CreateEmailDraftFromDocumentBlockResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEmailDraftFromDocumentBlockResponse | PlainMessage<CreateEmailDraftFromDocumentBlockResponse> | undefined, b: CreateEmailDraftFromDocumentBlockResponse | PlainMessage<CreateEmailDraftFromDocumentBlockResponse> | undefined): boolean {
    return proto3.util.equals(CreateEmailDraftFromDocumentBlockResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListAllTaskInspirationRequest
 */
export class ListAllTaskInspirationRequest extends Message<ListAllTaskInspirationRequest> {
  constructor(data?: PartialMessage<ListAllTaskInspirationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllTaskInspirationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllTaskInspirationRequest {
    return new ListAllTaskInspirationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllTaskInspirationRequest {
    return new ListAllTaskInspirationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllTaskInspirationRequest {
    return new ListAllTaskInspirationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllTaskInspirationRequest | PlainMessage<ListAllTaskInspirationRequest> | undefined, b: ListAllTaskInspirationRequest | PlainMessage<ListAllTaskInspirationRequest> | undefined): boolean {
    return proto3.util.equals(ListAllTaskInspirationRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAllTaskInspirationResponse
 */
export class ListAllTaskInspirationResponse extends Message<ListAllTaskInspirationResponse> {
  /**
   * @generated from field: repeated protogen.TaskInspiration taskInspirations = 1;
   */
  taskInspirations: TaskInspiration[] = [];

  constructor(data?: PartialMessage<ListAllTaskInspirationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllTaskInspirationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspirations", kind: "message", T: TaskInspiration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllTaskInspirationResponse {
    return new ListAllTaskInspirationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllTaskInspirationResponse {
    return new ListAllTaskInspirationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllTaskInspirationResponse {
    return new ListAllTaskInspirationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllTaskInspirationResponse | PlainMessage<ListAllTaskInspirationResponse> | undefined, b: ListAllTaskInspirationResponse | PlainMessage<ListAllTaskInspirationResponse> | undefined): boolean {
    return proto3.util.equals(ListAllTaskInspirationResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListAllTaskInspirationCategoriesRequest
 */
export class ListAllTaskInspirationCategoriesRequest extends Message<ListAllTaskInspirationCategoriesRequest> {
  constructor(data?: PartialMessage<ListAllTaskInspirationCategoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllTaskInspirationCategoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllTaskInspirationCategoriesRequest {
    return new ListAllTaskInspirationCategoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllTaskInspirationCategoriesRequest {
    return new ListAllTaskInspirationCategoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllTaskInspirationCategoriesRequest {
    return new ListAllTaskInspirationCategoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllTaskInspirationCategoriesRequest | PlainMessage<ListAllTaskInspirationCategoriesRequest> | undefined, b: ListAllTaskInspirationCategoriesRequest | PlainMessage<ListAllTaskInspirationCategoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListAllTaskInspirationCategoriesRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAllTaskInspirationCategoriesResponse
 */
export class ListAllTaskInspirationCategoriesResponse extends Message<ListAllTaskInspirationCategoriesResponse> {
  /**
   * @generated from field: repeated protogen.TaskInspirationCategory taskInspirationCategories = 1;
   */
  taskInspirationCategories: TaskInspirationCategory[] = [];

  constructor(data?: PartialMessage<ListAllTaskInspirationCategoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllTaskInspirationCategoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspirationCategories", kind: "message", T: TaskInspirationCategory, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllTaskInspirationCategoriesResponse {
    return new ListAllTaskInspirationCategoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllTaskInspirationCategoriesResponse {
    return new ListAllTaskInspirationCategoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllTaskInspirationCategoriesResponse {
    return new ListAllTaskInspirationCategoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllTaskInspirationCategoriesResponse | PlainMessage<ListAllTaskInspirationCategoriesResponse> | undefined, b: ListAllTaskInspirationCategoriesResponse | PlainMessage<ListAllTaskInspirationCategoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListAllTaskInspirationCategoriesResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskInspirationRequest
 */
export class CreateTaskInspirationRequest extends Message<CreateTaskInspirationRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string categoryRef = 2;
   */
  categoryRef = "";

  /**
   * @generated from field: string additionalContext = 3;
   */
  additionalContext = "";

  /**
   * @generated from field: repeated protogen.DefaultTaskDetail defaultDetails = 4;
   */
  defaultDetails: DefaultTaskDetail[] = [];

  constructor(data?: PartialMessage<CreateTaskInspirationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskInspirationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "categoryRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "additionalContext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "defaultDetails", kind: "message", T: DefaultTaskDetail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskInspirationRequest {
    return new CreateTaskInspirationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskInspirationRequest {
    return new CreateTaskInspirationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskInspirationRequest {
    return new CreateTaskInspirationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskInspirationRequest | PlainMessage<CreateTaskInspirationRequest> | undefined, b: CreateTaskInspirationRequest | PlainMessage<CreateTaskInspirationRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskInspirationRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskInspirationResponse
 */
export class CreateTaskInspirationResponse extends Message<CreateTaskInspirationResponse> {
  /**
   * @generated from field: protogen.TaskInspiration taskInspiration = 1;
   */
  taskInspiration?: TaskInspiration;

  constructor(data?: PartialMessage<CreateTaskInspirationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskInspirationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspiration", kind: "message", T: TaskInspiration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskInspirationResponse {
    return new CreateTaskInspirationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskInspirationResponse {
    return new CreateTaskInspirationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskInspirationResponse {
    return new CreateTaskInspirationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskInspirationResponse | PlainMessage<CreateTaskInspirationResponse> | undefined, b: CreateTaskInspirationResponse | PlainMessage<CreateTaskInspirationResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskInspirationResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTaskInspirationRequest
 */
export class UpdateTaskInspirationRequest extends Message<UpdateTaskInspirationRequest> {
  /**
   * @generated from field: string taskInspirationRef = 1;
   */
  taskInspirationRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string additionalContext = 3;
   */
  additionalContext = "";

  /**
   * @generated from field: string status = 4;
   */
  status = "";

  /**
   * @generated from field: repeated protogen.DefaultTaskDetail defaultDetails = 5;
   */
  defaultDetails: DefaultTaskDetail[] = [];

  constructor(data?: PartialMessage<UpdateTaskInspirationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTaskInspirationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspirationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "additionalContext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "defaultDetails", kind: "message", T: DefaultTaskDetail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskInspirationRequest {
    return new UpdateTaskInspirationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskInspirationRequest {
    return new UpdateTaskInspirationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskInspirationRequest {
    return new UpdateTaskInspirationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskInspirationRequest | PlainMessage<UpdateTaskInspirationRequest> | undefined, b: UpdateTaskInspirationRequest | PlainMessage<UpdateTaskInspirationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTaskInspirationRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTaskInspirationResponse
 */
export class UpdateTaskInspirationResponse extends Message<UpdateTaskInspirationResponse> {
  /**
   * @generated from field: protogen.TaskInspiration taskInspiration = 1;
   */
  taskInspiration?: TaskInspiration;

  constructor(data?: PartialMessage<UpdateTaskInspirationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTaskInspirationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspiration", kind: "message", T: TaskInspiration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskInspirationResponse {
    return new UpdateTaskInspirationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskInspirationResponse {
    return new UpdateTaskInspirationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskInspirationResponse {
    return new UpdateTaskInspirationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskInspirationResponse | PlainMessage<UpdateTaskInspirationResponse> | undefined, b: UpdateTaskInspirationResponse | PlainMessage<UpdateTaskInspirationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTaskInspirationResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTaskBlockStatusRequest
 */
export class UpdateTaskBlockStatusRequest extends Message<UpdateTaskBlockStatusRequest> {
  /**
   * @generated from field: string taskBlockRef = 1;
   */
  taskBlockRef = "";

  /**
   * @generated from field: protogen.TaskBlockStatus status = 2;
   */
  status = TaskBlockStatus.UNSET;

  constructor(data?: PartialMessage<UpdateTaskBlockStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTaskBlockStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskBlockRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(TaskBlockStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskBlockStatusRequest {
    return new UpdateTaskBlockStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskBlockStatusRequest {
    return new UpdateTaskBlockStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskBlockStatusRequest {
    return new UpdateTaskBlockStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskBlockStatusRequest | PlainMessage<UpdateTaskBlockStatusRequest> | undefined, b: UpdateTaskBlockStatusRequest | PlainMessage<UpdateTaskBlockStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTaskBlockStatusRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateTaskBlockStatusResponse
 */
export class UpdateTaskBlockStatusResponse extends Message<UpdateTaskBlockStatusResponse> {
  /**
   * @generated from field: protogen.TaskBlock block = 1;
   */
  block?: TaskBlock;

  constructor(data?: PartialMessage<UpdateTaskBlockStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateTaskBlockStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "message", T: TaskBlock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskBlockStatusResponse {
    return new UpdateTaskBlockStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskBlockStatusResponse {
    return new UpdateTaskBlockStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskBlockStatusResponse {
    return new UpdateTaskBlockStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskBlockStatusResponse | PlainMessage<UpdateTaskBlockStatusResponse> | undefined, b: UpdateTaskBlockStatusResponse | PlainMessage<UpdateTaskBlockStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTaskBlockStatusResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskInspirationCategoryRequest
 */
export class CreateTaskInspirationCategoryRequest extends Message<CreateTaskInspirationCategoryRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string iconName = 3;
   */
  iconName = "";

  constructor(data?: PartialMessage<CreateTaskInspirationCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskInspirationCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "iconName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskInspirationCategoryRequest {
    return new CreateTaskInspirationCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskInspirationCategoryRequest {
    return new CreateTaskInspirationCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskInspirationCategoryRequest {
    return new CreateTaskInspirationCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskInspirationCategoryRequest | PlainMessage<CreateTaskInspirationCategoryRequest> | undefined, b: CreateTaskInspirationCategoryRequest | PlainMessage<CreateTaskInspirationCategoryRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskInspirationCategoryRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskInspirationCategoryResponse
 */
export class CreateTaskInspirationCategoryResponse extends Message<CreateTaskInspirationCategoryResponse> {
  /**
   * @generated from field: protogen.TaskInspirationCategory taskInspirationCategory = 1;
   */
  taskInspirationCategory?: TaskInspirationCategory;

  constructor(data?: PartialMessage<CreateTaskInspirationCategoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskInspirationCategoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspirationCategory", kind: "message", T: TaskInspirationCategory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskInspirationCategoryResponse {
    return new CreateTaskInspirationCategoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskInspirationCategoryResponse {
    return new CreateTaskInspirationCategoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskInspirationCategoryResponse {
    return new CreateTaskInspirationCategoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskInspirationCategoryResponse | PlainMessage<CreateTaskInspirationCategoryResponse> | undefined, b: CreateTaskInspirationCategoryResponse | PlainMessage<CreateTaskInspirationCategoryResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskInspirationCategoryResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskInspirationCategoryRequest
 */
export class EditTaskInspirationCategoryRequest extends Message<EditTaskInspirationCategoryRequest> {
  /**
   * @generated from field: string categoryRef = 1;
   */
  categoryRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string iconName = 4;
   */
  iconName = "";

  constructor(data?: PartialMessage<EditTaskInspirationCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskInspirationCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categoryRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "iconName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskInspirationCategoryRequest {
    return new EditTaskInspirationCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskInspirationCategoryRequest {
    return new EditTaskInspirationCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskInspirationCategoryRequest {
    return new EditTaskInspirationCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskInspirationCategoryRequest | PlainMessage<EditTaskInspirationCategoryRequest> | undefined, b: EditTaskInspirationCategoryRequest | PlainMessage<EditTaskInspirationCategoryRequest> | undefined): boolean {
    return proto3.util.equals(EditTaskInspirationCategoryRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskInspirationCategoryResponse
 */
export class EditTaskInspirationCategoryResponse extends Message<EditTaskInspirationCategoryResponse> {
  /**
   * @generated from field: protogen.TaskInspirationCategory taskInspirationCategory = 1;
   */
  taskInspirationCategory?: TaskInspirationCategory;

  constructor(data?: PartialMessage<EditTaskInspirationCategoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskInspirationCategoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskInspirationCategory", kind: "message", T: TaskInspirationCategory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskInspirationCategoryResponse {
    return new EditTaskInspirationCategoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskInspirationCategoryResponse {
    return new EditTaskInspirationCategoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskInspirationCategoryResponse {
    return new EditTaskInspirationCategoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskInspirationCategoryResponse | PlainMessage<EditTaskInspirationCategoryResponse> | undefined, b: EditTaskInspirationCategoryResponse | PlainMessage<EditTaskInspirationCategoryResponse> | undefined): boolean {
    return proto3.util.equals(EditTaskInspirationCategoryResponse, a, b);
  }
}

