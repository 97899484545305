// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/files_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Attachment } from "./common_pb.ts";

/**
 * @generated from message protogen.SignedUrlHeader
 */
export class SignedUrlHeader extends Message<SignedUrlHeader> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<SignedUrlHeader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignedUrlHeader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlHeader {
    return new SignedUrlHeader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlHeader {
    return new SignedUrlHeader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlHeader {
    return new SignedUrlHeader().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlHeader | PlainMessage<SignedUrlHeader> | undefined, b: SignedUrlHeader | PlainMessage<SignedUrlHeader> | undefined): boolean {
    return proto3.util.equals(SignedUrlHeader, a, b);
  }
}

/**
 * @generated from message protogen.SignedUrl
 */
export class SignedUrl extends Message<SignedUrl> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string key = 2;
   */
  key = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: repeated protogen.SignedUrlHeader headers = 4;
   */
  headers: SignedUrlHeader[] = [];

  constructor(data?: PartialMessage<SignedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "headers", kind: "message", T: SignedUrlHeader, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrl {
    return new SignedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrl | PlainMessage<SignedUrl> | undefined, b: SignedUrl | PlainMessage<SignedUrl> | undefined): boolean {
    return proto3.util.equals(SignedUrl, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsRequest
 */
export class GetSignedUploadsUrlsRequest extends Message<GetSignedUploadsUrlsRequest> {
  /**
   * @generated from field: repeated protogen.GetSignedUploadsUrlsRequest.Upload uploads = 1;
   */
  uploads: GetSignedUploadsUrlsRequest_Upload[] = [];

  /**
   * @generated from field: string sseRef = 2;
   */
  sseRef = "";

  constructor(data?: PartialMessage<GetSignedUploadsUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uploads", kind: "message", T: GetSignedUploadsUrlsRequest_Upload, repeated: true },
    { no: 2, name: "sseRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsRequest | PlainMessage<GetSignedUploadsUrlsRequest> | undefined, b: GetSignedUploadsUrlsRequest | PlainMessage<GetSignedUploadsUrlsRequest> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsRequest.Upload
 */
export class GetSignedUploadsUrlsRequest_Upload extends Message<GetSignedUploadsUrlsRequest_Upload> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string contentType = 2;
   */
  contentType = "";

  /**
   * @generated from field: int32 size = 3;
   */
  size = 0;

  constructor(data?: PartialMessage<GetSignedUploadsUrlsRequest_Upload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsRequest.Upload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsRequest_Upload | PlainMessage<GetSignedUploadsUrlsRequest_Upload> | undefined, b: GetSignedUploadsUrlsRequest_Upload | PlainMessage<GetSignedUploadsUrlsRequest_Upload> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsRequest_Upload, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsResponse
 */
export class GetSignedUploadsUrlsResponse extends Message<GetSignedUploadsUrlsResponse> {
  /**
   * @generated from field: repeated protogen.SignedUrl signedUrls = 1;
   */
  signedUrls: SignedUrl[] = [];

  /**
   * @generated from field: string validationKey = 2;
   */
  validationKey = "";

  constructor(data?: PartialMessage<GetSignedUploadsUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signedUrls", kind: "message", T: SignedUrl, repeated: true },
    { no: 2, name: "validationKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsResponse | PlainMessage<GetSignedUploadsUrlsResponse> | undefined, b: GetSignedUploadsUrlsResponse | PlainMessage<GetSignedUploadsUrlsResponse> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetUrlContentRequest
 */
export class GetUrlContentRequest extends Message<GetUrlContentRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<GetUrlContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetUrlContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUrlContentRequest {
    return new GetUrlContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUrlContentRequest {
    return new GetUrlContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUrlContentRequest {
    return new GetUrlContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUrlContentRequest | PlainMessage<GetUrlContentRequest> | undefined, b: GetUrlContentRequest | PlainMessage<GetUrlContentRequest> | undefined): boolean {
    return proto3.util.equals(GetUrlContentRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetUrlContentResponse
 */
export class GetUrlContentResponse extends Message<GetUrlContentResponse> {
  /**
   * @generated from field: protogen.Attachment attachment = 1;
   */
  attachment?: Attachment;

  /**
   * @generated from field: string validationKey = 2;
   */
  validationKey = "";

  /**
   * @generated from field: string s3Key = 3;
   */
  s3Key = "";

  constructor(data?: PartialMessage<GetUrlContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetUrlContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "attachment", kind: "message", T: Attachment },
    { no: 2, name: "validationKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "s3Key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUrlContentResponse {
    return new GetUrlContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUrlContentResponse {
    return new GetUrlContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUrlContentResponse {
    return new GetUrlContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUrlContentResponse | PlainMessage<GetUrlContentResponse> | undefined, b: GetUrlContentResponse | PlainMessage<GetUrlContentResponse> | undefined): boolean {
    return proto3.util.equals(GetUrlContentResponse, a, b);
  }
}

