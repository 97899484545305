// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/notes_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ContentUpdates, EntityType, Note } from "./notes_pb.ts";

/**
 * @generated from message protogen.EditNoteRequest
 */
export class EditNoteRequest extends Message<EditNoteRequest> {
  /**
   * @generated from field: protogen.ContentUpdates content = 1;
   */
  content?: ContentUpdates;

  /**
   * @generated from field: protogen.EntityType entityType = 2;
   */
  entityType = EntityType.TYPE_UNSPECIFIED;

  /**
   * @generated from field: string entityRef = 3;
   */
  entityRef = "";

  /**
   * @generated from field: string prevHash = 4;
   */
  prevHash = "";

  constructor(data?: PartialMessage<EditNoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditNoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: ContentUpdates },
    { no: 2, name: "entityType", kind: "enum", T: proto3.getEnumType(EntityType) },
    { no: 3, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "prevHash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditNoteRequest {
    return new EditNoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditNoteRequest {
    return new EditNoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditNoteRequest {
    return new EditNoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditNoteRequest | PlainMessage<EditNoteRequest> | undefined, b: EditNoteRequest | PlainMessage<EditNoteRequest> | undefined): boolean {
    return proto3.util.equals(EditNoteRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditNoteResponse
 */
export class EditNoteResponse extends Message<EditNoteResponse> {
  /**
   * @generated from field: protogen.Note note = 1;
   */
  note?: Note;

  constructor(data?: PartialMessage<EditNoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditNoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "note", kind: "message", T: Note },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditNoteResponse {
    return new EditNoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditNoteResponse {
    return new EditNoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditNoteResponse {
    return new EditNoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditNoteResponse | PlainMessage<EditNoteResponse> | undefined, b: EditNoteResponse | PlainMessage<EditNoteResponse> | undefined): boolean {
    return proto3.util.equals(EditNoteResponse, a, b);
  }
}

