import { useRef } from "react";

const partitionOnce = (total: number, part: number) => {
  if (total >= part) return [part, total - part];
  else return [total, 0];
};

const interpolateColor = (colors: string[]) => {
  const length = colors.length;
  return (value: number) => {
    const index = Math.floor(value * (length - 1));
    return colors[index];
  };
};

const cumulate = (
  values: number[],
): { value: number; from: number; to: number }[] => {
  let from = 0;
  return values.map((value) => ({ value, from, to: (from += value) }));
};

const computeGradientRotation = (angle: number) => {
  return {
    x1: Math.cos(angle) * 0.5 + 0.5,
    y1: Math.sin(angle) * 0.5 + 0.5,
    x2: Math.cos(angle - Math.PI) * 0.5 + 0.5,
    y2: Math.sin(angle - Math.PI) * 0.5 + 0.5,
  };
};

export default ({
  height,
  width,
  innerRadius,
  ringSpacing,
  strokeWidth,
  text,
  colors,
  unfilledColor,
  numerator,
  numeratorIncrement,
  denominator,
  phase: initialPhase = -Math.PI / 2,
}: {
  height: number;
  width: number;
  innerRadius: number;
  ringSpacing: number;
  strokeWidth: number;
  numerator: number;
  denominator: number;
  unfilledColor: string;
  colors: string[];
  numeratorIncrement?: number;
  text?: string;
  phase?: number;
}) => {
  const cx = width / 2;
  const cy = height / 2;
  const angle = (numerator / denominator) * Math.PI * 2;
  const radius = innerRadius + (strokeWidth + ringSpacing) * 2;
  const partitionStep = Math.PI;

  const singleLapAngle = Math.min(angle, Math.PI * 2);
  const ringRotationAngle = angle - singleLapAngle;
  const phase = initialPhase + ringRotationAngle;

  const partitionAngles = partitionOnce(singleLapAngle, partitionStep);
  const [segmentStart, segmentEnd] = cumulate(partitionAngles);

  const colorInterp = interpolateColor(colors.slice(0, 3));

  const arcX = (angle: number) => Math.cos(angle + phase) * radius + cx;
  const arcY = (angle: number) => Math.sin(angle + phase) * radius + cy;

  const path = (segment: { value: number; from: number; to: number }) => `
    M ${arcX(segment.from)} ${arcY(segment.from)}
    A
      ${radius} ${radius}
      0 ${segment.value > Math.PI ? 1 : 0} 1
      ${arcX(segment.to)} ${arcY(segment.to)}`;

  // If there are mutliple rings per page, we need unique refs
  const randomSuffix = Math.random().toString(36).substring(2, 15);

  const gradientEndId = useRef(`gradEndId${randomSuffix}`).current;
  const gradientStartId = useRef(`gradStartId${randomSuffix}`).current;
  const maskEndId = useRef(`maskEndId${randomSuffix}`).current;
  const maskStartId = useRef(`maskStartId${randomSuffix}`).current;
  const gradientShadowId = useRef(`gradShadowId${randomSuffix}`).current;

  const gradientAngle = Math.max(0, angle - Math.PI * 2) + initialPhase;
  const gradientRotation = computeGradientRotation(gradientAngle);
  const gradientSide = radius * 2 + strokeWidth;

  const totalAngle =
    ((numerator + (numeratorIncrement || 0)) / denominator) * Math.PI * 2;
  const singleLapTotalAngle = Math.min(totalAngle, Math.PI * 2);
  const ringRotationTotalAngle = totalAngle - singleLapTotalAngle;
  const totalPhase = ringRotationTotalAngle;

  const arcXTotal = (angle: number) =>
    Math.cos(angle + totalPhase) * radius + cx;
  const arcYTotal = (angle: number) =>
    Math.sin(angle + totalPhase) * radius + cy;

  const pathTotal = () => `
    M ${arcXTotal(initialPhase)} ${arcYTotal(initialPhase)}
      A ${radius} ${radius}
        0 ${totalAngle > Math.PI ? 1 : 0} 1
        ${arcXTotal(initialPhase + totalAngle)} ${arcYTotal(
          initialPhase + totalAngle,
        )}`;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g>
        <defs>
          <linearGradient id={gradientStartId} {...gradientRotation}>
            <stop offset="15%" stopColor={colorInterp(0)} />
            <stop offset="85%" stopColor={colorInterp(0.5)} />
          </linearGradient>

          <linearGradient id={gradientEndId} {...gradientRotation}>
            <stop offset="15%" stopColor={colorInterp(1)} />
            <stop offset="85%" stopColor={colorInterp(0.5)} />
          </linearGradient>

          <radialGradient id={gradientShadowId}>
            <stop offset="40%" stopColor={colorInterp(1)} />
            <stop offset="100%" stopColor={colorInterp(1)} stopOpacity={0} />
          </radialGradient>
        </defs>

        <mask id={maskStartId}>
          <path
            d={path(segmentStart)}
            strokeWidth={strokeWidth}
            stroke="white"
            fill="none"
            strokeLinecap="round"
          />
        </mask>

        <mask id={maskEndId}>
          <path
            d={path(segmentEnd)}
            strokeWidth={strokeWidth}
            stroke="white"
            fill="none"
            strokeLinecap="round"
          />
        </mask>

        {/* The background ring */}
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill="none"
          stroke={unfilledColor}
          opacity={0.25}
          strokeWidth={strokeWidth}
        />
        {numerator + (numeratorIncrement || 0) <= denominator && (
          <path
            d={pathTotal()}
            stroke="#D1D1D1"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        )}

        <rect
          x={cx - gradientSide / 2}
          y={cy - gradientSide / 2}
          width={gradientSide}
          height={gradientSide}
          fill={`url(#${gradientStartId})`}
          mask={`url(#${maskStartId})`}
        />

        {/* The filled progress ring */}
        <circle
          r={strokeWidth / 2 + 6}
          cx={arcX(segmentEnd.to)}
          cy={arcY(segmentEnd.to)}
          fill={`url(#${gradientShadowId})`}
          mask={`url(#${maskStartId})`}
          opacity={angle > Math.PI * 1.5 ? 1 : 0}
        />
        <text
          x={cx}
          y={cy - height * 0.02}
          textAnchor="middle"
          dominantBaseline="top"
          style={{ fontSize: "13px", fontFamily: "AlbertSans" }}
        >
          <tspan style={{ fontSize: "20px", fontWeight: 600 }} fill={colors[1]}>
            {numerator}
          </tspan>
          <tspan fill="#6B6E7B"> / </tspan>
          <tspan fill="#6B6E7B">{denominator}</tspan>
          <tspan x={cx} dy="1.2em" fill="#3D3D3D" style={{ fontWeight: "500" }}>
            {text}
          </tspan>
        </text>
        {angle >= Math.PI && (
          <rect
            x={cx - gradientSide / 2}
            y={cy - gradientSide / 2}
            width={gradientSide}
            height={gradientSide}
            fill={`url(#${gradientEndId})`}
            mask={`url(#${maskEndId})`}
          />
        )}
      </g>
    </svg>
  );
};
