import { CSSProperties, ReactNode } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import useIsMobile from "../hooks/useIsMobile";

export default ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: CSSProperties;
}) => {
  const isMobile = useIsMobile();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            padding: isMobile ? "32px 10px" : "32px",
            display: "flex",
            flexDirection: "column",
            ...sx,
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
