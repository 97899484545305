import { Family } from "protogen/advisors_service_pb";
import { Box, Button, Typography } from "@mui/material";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { SectionProps } from "../details-page/Section";
import { useState } from "react";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";
import AddEventDialog from "../calendar/AddEventDialog";
import ViewEventDialog from "../calendar/ViewEventDialog";
import EditEventDialog from "../calendar/EditEventDialog";
import { EventNotice } from "protogen/calendar_pb";
import LinkRouter from "../navigation/LinkRouter";
import EventsList from "../calendar/EventsList";

const _mergeEvents = (preferred: EventNotice[], secondary: EventNotice[]) => {
  // Merge the two lists of events, preferring the preferred list
  const merged = [...preferred];
  for (const event of secondary) {
    if (!preferred.find((e) => e.eventRef === event.eventRef)) {
      merged.push(event);
    }
  }
  return merged;
};

interface Props extends SectionProps {
  family: Family;
  events: EventNotice[];
}
export default ({ family, events }: Props) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [localEvents, setLocalEvents] = useState<EventNotice[]>([]);
  const [deleteEvents, setDeletedEvents] = useState<string[]>([]);

  const onEnableEdit = (eventRef: string) => {
    setSelectedEvent(eventRef);
    setViewModalOpen(false);
    setEditModalOpen(true);
  };
  const selectEvent = (eventRef: string) => {
    setViewModalOpen(true);
    setSelectedEvent(eventRef);
  };
  const onEdited = (e: EventNotice) => {
    setLocalEvents(_mergeEvents([e], localEvents));
    setSelectedEvent("");
    setEditModalOpen(false);
  };
  const onCreated = (eventNotice: EventNotice) => {
    setAddModalOpen(false);
    setLocalEvents([...localEvents, eventNotice]);
    setSnackPack((prev) => [
      ...prev,
      {
        key: eventNotice.eventRef,
        message: `"${eventNotice.title}" added!`,
      },
    ]);
  };

  const activeEvents = _mergeEvents(localEvents, events).filter(
    (e) => !deleteEvents.includes(e.eventRef),
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" onClick={() => setAddModalOpen(true)}>
            New Event
          </Button>
          {activeEvents.length > 0 && (
            <LinkRouter
              to={`/datebook?familyRefs=${encodeURIComponent(family.ref)}`}
            >
              <Button
                variant="text"
                endIcon={
                  <ArrowRightIcon
                    height="20px"
                    width="20px"
                    strokeWidth="2.5px"
                    color="#198282"
                  />
                }
              >
                See all events
              </Button>
            </LinkRouter>
          )}
        </Box>
        {activeEvents.length === 0 && (
          <Typography
            variant="body"
            sx={{
              color: "text.secondary",
            }}
          >
            No events to show
          </Typography>
        )}
        {activeEvents.length > 0 && (
          <>
            <EventsList
              events={activeEvents}
              selectEvent={selectEvent}
              familyPageView={true}
            />
          </>
        )}
      </Box>
      <AddEventDialog
        familyRef={family.ref}
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onCreated={onCreated}
      />
      <ViewEventDialog
        open={viewModalOpen}
        onClose={() => {
          setSelectedEvent("");
          setViewModalOpen(false);
        }}
        onDelete={() => {
          if (selectedEvent) {
            setDeletedEvents([...deleteEvents, selectedEvent]);
          }
        }}
        primaryAction={onEnableEdit}
        eventRef={selectedEvent}
      />
      <EditEventDialog
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onEdited={onEdited}
        eventRef={selectedEvent}
      />
      <SnackPack snackPack={snackPack} />
    </>
  );
};
