import {
  Family,
  FeedEntry,
  Medium,
  Member,
} from "protogen/advisors_service_pb";
import { Box, FormHelperText, Menu, MenuItem, Typography } from "@mui/material";
import TextMessageCompose from "./TextMessageCompose";
import EmailComposeDialog from "../email/EmailComposeDialog";
import PhoneCallInstantiation from "./PhoneCallInstantiation";
import { FeedFocusState } from "../../types/feed";
import { Advisor, UserPermission } from "protogen/common_pb";
import { useContext, useRef, useState } from "react";
import { CurrentUserContext } from "../context/RequireAuth";
import { ChevronDown } from "lucide-react";
import { commaSeparatedEnglishList } from "../../common/utils";

const familyRecipients = (family: Family): Member[] => {
  return family.familyMembers.filter((m) => m.primaryPhone);
};

const ContactSelection = ({
  recipient,
  feedFocusState,
  setFeedFocusState,
  family,
}: {
  recipient: Member;
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
  family: Family;
}) => {
  const anchorEl = useRef<HTMLElement>();
  const [open, setOpen] = useState(false);
  const groupSmsMembers = familyRecipients(family);
  return (
    <>
      <Box
        ref={anchorEl}
        display="flex"
        flexDirection="row"
        padding="0px 16px"
        alignItems="center"
        gap="4px"
        sx={{
          cursor: "pointer",
          marginTop: "12px",
          marginBottom: "-8px",
        }}
        onClick={() => setOpen((o) => !o)}
      >
        <Typography variant="bodySmall" sx={{ fontWeight: 600 }}>
          To{" "}
          {feedFocusState.groupText
            ? groupSmsMembers.map((g) => g.firstName).join(" & ")
            : recipient.firstName}
        </Typography>
        <ChevronDown size={18} />
      </Box>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: "-40px", // Move the menu up
          ml: "16px", // Move the menu left
        }}
      >
        {family.familyMembers.map((m) => (
          <MenuItem
            key={m.ref}
            value={m.ref}
            disabled={!m.primaryPhone}
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                isDirty: true,
                selectedContact: m,
                replyToEmail: null,
                editEmailDraft: null,
                groupText: false,
              });
              setOpen(false);
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <div>{m.displayName}</div>
              {!m.primaryPhone && (
                <FormHelperText sx={{ mt: 0 }}>
                  Missing phone number
                </FormHelperText>
              )}
            </Box>
          </MenuItem>
        ))}
        {groupSmsMembers.length == 2 && (
          <MenuItem
            key={groupSmsMembers.map((g) => g.ref).join("|")}
            value={"group-sms"}
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                groupText: true,
                isDirty: true,
              });
              setOpen(false);
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <div>{groupSmsMembers.map((g) => g.firstName).join(" & ")}</div>
              <FormHelperText sx={{ mt: 0 }}>Beta feature</FormHelperText>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

type MessageComposeProps = {
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
  family: Family;
  addFeedEntry: (e: FeedEntry) => void;
  removeFeedEntry: (ref: string, m: Medium) => void;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
};

export default ({
  feedFocusState,
  setFeedFocusState,
  family,
  addFeedEntry,
  removeFeedEntry,
  primaryAdvisor,
  advisorPermissions,
}: MessageComposeProps) => {
  const currentUser = useContext(CurrentUserContext);
  const isPhoneMedium = feedFocusState.selectedMedium === Medium.PHONE;
  const isEmailMedium = feedFocusState.selectedMedium === Medium.EMAIL;
  const recipient = feedFocusState.selectedContact;

  const isAdvisor = primaryAdvisor.ref === currentUser.ref;
  const delegate = advisorPermissions.find(
    (p) => p.account?.ref === currentUser.ref && p.active,
  );
  const communicationEnabled = isAdvisor || !!delegate?.actions?.communicate;
  const onClose = () => {
    setFeedFocusState({
      ...feedFocusState,
      replyToEmail: null,
      editEmailDraft: null,
      selectedMedium: Medium.SMS,
    });
  };
  const initialCC = family.familyMembers
    .filter((m) => m.ref !== recipient.ref && m.primaryEmail)
    .map((m) => m.primaryEmail);
  if (delegate && communicationEnabled) {
    initialCC.push(primaryAdvisor.username);
  }
  let placeholder = `Text ${recipient.firstName}`;
  if (feedFocusState.groupText) {
    const groupSmsMembers = familyRecipients(family).map((m) => m.firstName);
    placeholder = `Text ${commaSeparatedEnglishList(groupSmsMembers)}`;
  }

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      {/* Contact selection */}
      <ContactSelection
        feedFocusState={feedFocusState}
        recipient={recipient}
        setFeedFocusState={setFeedFocusState}
        family={family}
      />
      <TextMessageCompose
        forceDisabled={!communicationEnabled}
        familyRef={family.ref}
        recipientRefs={(feedFocusState.groupText
          ? familyRecipients(family)
          : [recipient]
        ).map((r) => r.ref)}
        contactEnabled={!!recipient.primaryPhone}
        placeholder={placeholder}
        onSent={addFeedEntry}
      />
      <EmailComposeDialog
        closed={!isEmailMedium || !communicationEnabled}
        onClose={onClose}
        onSent={addFeedEntry}
        onRemove={removeFeedEntry}
        inReplyTo={feedFocusState.replyToEmail}
        initialDraft={feedFocusState.editEmailDraft}
        initialTo={[recipient.primaryEmail]}
        initialCC={initialCC}
      />
      <PhoneCallInstantiation
        closed={!isPhoneMedium || !communicationEnabled}
        onClose={onClose}
        family={family}
        recipientRef={recipient.ref}
        onStarted={addFeedEntry}
      />
    </Box>
  );
};
