import * as React from "react";
import { createContext, useEffect, useState } from "react";
import checkIfSubscribed from "../../common/checkIfSubscribed";

interface SubscriptionState {
  loaded: boolean;
  serviceWorker: ServiceWorkerRegistration | null;
  subscription: PushSubscription | null;
}

export const SWRegistrationContext = createContext<SubscriptionState>(
  {} as SubscriptionState,
);

export default ({ children }: { children: React.ReactNode }) => {
  const [loaded, setLoaded] = useState(false);
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);
  const [existingSub, setExistingSub] = useState<PushSubscription | null>(null);
  const setup = async () => {
    // A service worker must be registered in order to send notifications on iOS
    // https://devtails.xyz/@adam/how-to-setup-web-push-notifications-in-ios-safari
    if ("serviceWorker" in navigator) {
      try {
        const reg =
          await navigator.serviceWorker.register("/service-worker.js");
        setRegistration(reg);
        // console.log('Service worker registered with scope: ', reg.scope);
      } catch (error) {
        console.error("Service worker registration failed: ", error);
      }
    }
    setExistingSub(await checkIfSubscribed());
    setLoaded(true);
  };
  useEffect(() => {
    setup().catch(console.error);
  }, []);

  return (
    <SWRegistrationContext.Provider
      value={{
        loaded: loaded,
        serviceWorker: registration,
        subscription: existingSub,
      }}
    >
      {children}
    </SWRegistrationContext.Provider>
  );
};
