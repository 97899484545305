import { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import ReactiveDialog from "../common/ReactiveDialog";
import { useCreateAdvisorNux } from "services/nux";
import { FormErrors } from "components/advisor/types";
import { checkEmail } from "../../common/userUtils";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default ({ open, onClose }: Props) => {
  const [email, setEmail] = useState("");
  const { request } = useCreateAdvisorNux();
  const [errors, setFormErrors] = useState<FormErrors>({});
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onClick = () => {
    const errors: FormErrors = {};
    if (!email) {
      errors["email"] = "Required";
      setFormErrors(errors);
      return;
    }
    if (!checkEmail(email || "")) {
      errors["email"] = "Invalid email address";
      setFormErrors(errors);
      return;
    }
    request({ email });
    onClose();
  };

  return (
    <ReactiveDialog
      fullWidthSize="sm"
      open={open}
      onClose={() => onClose()}
      title={`Invite Advisor`}
    >
      <Typography variant="body">
        Invite an advisor by email. They will receive a link to create their
        account, accept terms of service and other onboarding steps.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
        <TextField
          sx={{ flexGrow: 1 }}
          margin="dense"
          label="email"
          type="text"
          variant="outlined"
          autoComplete="off"
          error={!!errors?.email}
          helperText={errors?.email}
          value={email}
          onChange={handleEmailChange}
        ></TextField>
        <Button onClick={onClick}>Invite</Button>
      </Box>
    </ReactiveDialog>
  );
};
