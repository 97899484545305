import LinkRouter from "../navigation/LinkRouter";
import {
  ExpendableEntryContainer,
  ExpendableEntryItem,
} from "./ExpandableEntry";
import { LayoutGrid } from "lucide-react";
import { ReactNode, useContext } from "react";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdmin } from "../../common/userUtils";

type Props = {
  selected: boolean;
  open: boolean;
  onClick: () => void;
};

export default ({ selected, open, onClick }: Props) => {
  const currentUser = useContext(CurrentUserContext);
  let entries: ReactNode[] = [];
  if (isUserAdmin(currentUser)) {
    entries = [
      <LinkRouter
        onClick={onClick}
        to={`/tools/advisor-administration`}
        key={`/tools/advisor-administration`}
      >
        <ExpendableEntryItem
          dense={true}
          content="Advisors"
          selected={new RegExp(`/tools/advisor-administration?.*$`).test(
            location.pathname,
          )}
        />
      </LinkRouter>,
      <LinkRouter
        onClick={onClick}
        to={`/tools/family-directory`}
        key={`/tools/family-directory`}
      >
        <ExpendableEntryItem
          dense={true}
          content="Families"
          selected={new RegExp(`/tools/family-directory?.*$`).test(
            location.pathname,
          )}
        />
      </LinkRouter>,
    ];
  }

  return (
    <ExpendableEntryContainer
      icon={<LayoutGrid height="20px" width="20px" stroke="#6B6E7B" />}
      selected={selected}
      open={open}
      onClick={onClick}
      content="Tools"
      url="/tools"
      stateName="faye.settings.tools-expanded"
    >
      {entries}
    </ExpendableEntryContainer>
  );
};
