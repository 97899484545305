import { Dialog, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AdvancedMarkerRef,
  APIProvider,
  InfoWindow,
  Map as GMap,
} from "@vis.gl/react-google-maps";
import { Box } from "@mui/system";
import useGeocoder from "./useGeocoder";
import FMarker, { Treatment } from "./FMarker";
import { US_CENTER, US_ZOOM } from "./utils";
import { CircleX, ExternalLink } from "lucide-react";
import Loading from "../Loading";
import LinkRouter from "../../navigation/LinkRouter";

export type MapLocation = {
  address: string;
  title: string;
  link?: string;
  content?: string;
  lat?: number;
  lng?: number;
  treatment?: Treatment;
  color?: string;
  bounding?: boolean;
};
type Props = {
  open: boolean;
  onClose: () => void;
  locations: MapLocation[];
};

export default ({ open, onClose, locations }: Props) => {
  const isMobile = window.innerWidth < 600;
  const { results, geocode } = useGeocoder({});
  const [mapCenter, setMapCenter] = useState(US_CENTER);
  const [mapZoom, setMapZoom] = useState(US_ZOOM);
  const [focusedMarker, setFocusedMarker] = useState<null | {
    index: number;
    marker: AdvancedMarkerRef | null;
    location: MapLocation;
  }>(null);
  useEffect(() => {
    const run = async () => {
      const resp = await geocode(
        locations.map((location) => ({
          address: location.address,
          lat: location.lat,
          lng: location.lng,
          bounding: location.bounding,
        })),
      );
      setMapCenter(resp.center);
      setMapZoom(resp.zoom);
    };
    if (open) {
      run();
    }
  }, [open, locations]);

  const resultByAddress = new Map(
    (results?.locations || []).map((location) => [location.address, location]),
  );

  // Zip results and locations together
  const resultsWithLocations: MapLocation[] = locations
    .map((location) => ({
      ...location,
      ...resultByAddress.get(location.address),
    }))
    .filter((location) => location.lat && location.lng);

  // https://visgl.github.io/react-google-maps/
  const renderMap = () => (
    <APIProvider
      apiKey={process.env.GOOGLE_MAPS_PUBLISHABLE_KEY!}
      libraries={["marker"]}
    >
      <GMap
        mapId={"bf51a910020fa25a"}
        // defaultZoom={results?.zoom || US_ZOOM}
        // defaultCenter={results?.center || US_CENTER}
        // zoom={results?.zoom || US_ZOOM}
        // center={results?.center || US_CENTER}
        zoom={mapZoom}
        center={mapCenter}
        onZoomChanged={(m) => setMapZoom(m.detail.zoom)}
        onCenterChanged={(m) => setMapCenter(m.detail.center)}
        gestureHandling={"greedy"}
        onClick={() => setFocusedMarker(null)}
        disableDefaultUI
      >
        {resultsWithLocations.map((location, index) => (
          <FMarker
            key={index}
            active={focusedMarker?.index === index}
            title={location.title}
            lng={location.lng!}
            lat={location.lat!}
            color={location.color}
            treatment={location.treatment}
            onClick={(marker) =>
              setFocusedMarker(
                focusedMarker?.index === index
                  ? null
                  : { index, marker, location },
              )
            }
          />
        ))}
        {focusedMarker && (
          <InfoWindow
            anchor={focusedMarker.marker}
            maxWidth={200}
            onCloseClick={() => setFocusedMarker(null)}
            headerDisabled
            pixelOffset={[0, -5]}
          >
            <Box>
              {focusedMarker.location.link ? (
                <LinkRouter
                  to={focusedMarker.location.link}
                  targetNew={!isMobile}
                >
                  <Typography variant="bodyHeavy">
                    {focusedMarker.location.title} <ExternalLink size={16} />
                  </Typography>
                </LinkRouter>
              ) : (
                <Typography variant="bodyHeavy">
                  {focusedMarker.location.title}
                </Typography>
              )}
              <Typography variant="bodySmall" sx={{ padding: "5px 0px" }}>
                {focusedMarker.location.content}
              </Typography>
            </Box>
          </InfoWindow>
        )}
      </GMap>
    </APIProvider>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: "8px",
        },
      }}
    >
      <Box sx={{ height: isMobile ? "90vh" : "60vh" }} display={"flex"}>
        {results?.locations.length ? (
          renderMap()
        ) : (
          <Box sx={{ width: "100%", alignSelf: "center" }}>
            <Loading />
          </Box>
        )}
      </Box>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: "5px",
          backgroundColor: "rgb(255,255,255, 0.2)",
          top: "5px",
        }}
      >
        <CircleX size={30} />
      </IconButton>
    </Dialog>
  );
};
