import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { isStandaloneWebapp, urlBase64ToUint8Array } from "../../common/utils";
import { VAPID_PUBLIC_KEY } from "../../constants";
import { useNotificationRegistration } from "services/advisor";
import { SWRegistrationContext } from "../context/AddServiceWorker";
import { CurrentUserContext } from "../context/RequireAuth";
import useIsMobile from "../hooks/useIsMobile";

export default () => {
  const { request } = useNotificationRegistration();
  const [closed, setClosed] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const isMobile = useIsMobile();

  const {
    loaded: regContextLoaded,
    serviceWorker,
    subscription,
  } = useContext(SWRegistrationContext);
  const notificationsEnabled = "Notification" in window;
  useEffect(() => {
    if (
      regContextLoaded &&
      notificationsEnabled &&
      !closed &&
      serviceWorker &&
      !currentUser.isTemporarySession &&
      Notification.permission === "granted"
    ) {
      if (!closed) {
        setClosed(true);
      }
      (async () => {
        await getOrCreateAndSaveSubscription();
      })();
    }
  }, [
    regContextLoaded,
    notificationsEnabled,
    closed,
    serviceWorker,
    currentUser.isTemporarySession,
    subscription,
  ]);

  // On desktop, let's just rely on the default permission prompt.
  useEffect(() => {
    const requestPermission = async () => {
      await window.Notification.requestPermission();
    };
    if (
      !isMobile &&
      regContextLoaded &&
      !subscription &&
      notificationsEnabled &&
      !closed &&
      serviceWorker &&
      !currentUser.isTemporarySession
    ) {
      requestPermission();
    }
  });

  const getOrCreateAndSaveSubscription = async () => {
    if (!serviceWorker) return;
    // We likely need to wrap this in a confirmation modal.
    const _subscription =
      subscription === null
        ? await serviceWorker.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
            userVisibleOnly: true,
          })
        : subscription;
    if (!_subscription || !_subscription?.endpoint) return;
    await request({
      platform: isStandaloneWebapp() ? "ios-webapp" : "desktop-webapp", // Not strictly true!
      subscription: JSON.stringify(_subscription.toJSON()),
    });
  };

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setClosed(true);
    // Triggers popup to request access to send notifications
    const result = await window.Notification.requestPermission();
    // If the user rejects the permission result will be "denied"
    if (result === "granted") {
      await getOrCreateAndSaveSubscription();
      // You must use the service worker notification to show the notification
      // Using new Notification("Hello World", { body: "My first notification on iOS"}) does not work on iOS
      // despite working on other platforms
      // await swRegistration.showNotification("Granted!", {
      //   body: "We did it boys.",
      // });
    }
    // console.log(result);
  };

  if (
    !regContextLoaded ||
    subscription ||
    !notificationsEnabled ||
    closed ||
    !serviceWorker ||
    currentUser.isTemporarySession ||
    // Don't prompt on desktop.
    !isMobile
  ) {
    return null;
  }

  return (
    <Dialog
      open={!closed}
      PaperComponent={Paper}
      PaperProps={{ style: { borderRadius: "12px", padding: "15px 15px" } }}
      disableEscapeKeyDown={true}
      onClose={(_, reason) => {
        if (reason && reason === "backdropClick") return;
        setClosed(true);
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <NotificationsIcon />
        <span>Turn on notifications</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Click yes to get updates and messages from Faye and your clients.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
