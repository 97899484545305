// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/nux_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { NuxDetails } from "./nux_state_pb.ts";
import { UploadAttachment } from "./common_pb.ts";
import { BillingCoupon, BillingProduct } from "./billing_pb.ts";

/**
 * @generated from message protogen.CreateAdvisorNuxRequest
 */
export class CreateAdvisorNuxRequest extends Message<CreateAdvisorNuxRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<CreateAdvisorNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateAdvisorNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAdvisorNuxRequest {
    return new CreateAdvisorNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAdvisorNuxRequest {
    return new CreateAdvisorNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAdvisorNuxRequest {
    return new CreateAdvisorNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAdvisorNuxRequest | PlainMessage<CreateAdvisorNuxRequest> | undefined, b: CreateAdvisorNuxRequest | PlainMessage<CreateAdvisorNuxRequest> | undefined): boolean {
    return proto3.util.equals(CreateAdvisorNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateAdvisorNuxResponse
 */
export class CreateAdvisorNuxResponse extends Message<CreateAdvisorNuxResponse> {
  constructor(data?: PartialMessage<CreateAdvisorNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateAdvisorNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAdvisorNuxResponse {
    return new CreateAdvisorNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAdvisorNuxResponse {
    return new CreateAdvisorNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAdvisorNuxResponse {
    return new CreateAdvisorNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAdvisorNuxResponse | PlainMessage<CreateAdvisorNuxResponse> | undefined, b: CreateAdvisorNuxResponse | PlainMessage<CreateAdvisorNuxResponse> | undefined): boolean {
    return proto3.util.equals(CreateAdvisorNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchNuxDetailsRequest
 */
export class FetchNuxDetailsRequest extends Message<FetchNuxDetailsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<FetchNuxDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchNuxDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchNuxDetailsRequest {
    return new FetchNuxDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchNuxDetailsRequest {
    return new FetchNuxDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchNuxDetailsRequest {
    return new FetchNuxDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchNuxDetailsRequest | PlainMessage<FetchNuxDetailsRequest> | undefined, b: FetchNuxDetailsRequest | PlainMessage<FetchNuxDetailsRequest> | undefined): boolean {
    return proto3.util.equals(FetchNuxDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchNuxDetailsResponse
 */
export class FetchNuxDetailsResponse extends Message<FetchNuxDetailsResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<FetchNuxDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchNuxDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchNuxDetailsResponse {
    return new FetchNuxDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchNuxDetailsResponse {
    return new FetchNuxDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchNuxDetailsResponse {
    return new FetchNuxDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchNuxDetailsResponse | PlainMessage<FetchNuxDetailsResponse> | undefined, b: FetchNuxDetailsResponse | PlainMessage<FetchNuxDetailsResponse> | undefined): boolean {
    return proto3.util.equals(FetchNuxDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmAdvisorLandingRequest
 */
export class ConfirmAdvisorLandingRequest extends Message<ConfirmAdvisorLandingRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<ConfirmAdvisorLandingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmAdvisorLandingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmAdvisorLandingRequest {
    return new ConfirmAdvisorLandingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmAdvisorLandingRequest {
    return new ConfirmAdvisorLandingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmAdvisorLandingRequest {
    return new ConfirmAdvisorLandingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmAdvisorLandingRequest | PlainMessage<ConfirmAdvisorLandingRequest> | undefined, b: ConfirmAdvisorLandingRequest | PlainMessage<ConfirmAdvisorLandingRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmAdvisorLandingRequest, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmAdvisorLandingResponse
 */
export class ConfirmAdvisorLandingResponse extends Message<ConfirmAdvisorLandingResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<ConfirmAdvisorLandingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmAdvisorLandingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmAdvisorLandingResponse {
    return new ConfirmAdvisorLandingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmAdvisorLandingResponse {
    return new ConfirmAdvisorLandingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmAdvisorLandingResponse {
    return new ConfirmAdvisorLandingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmAdvisorLandingResponse | PlainMessage<ConfirmAdvisorLandingResponse> | undefined, b: ConfirmAdvisorLandingResponse | PlainMessage<ConfirmAdvisorLandingResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmAdvisorLandingResponse, a, b);
  }
}

/**
 * @generated from message protogen.AddPersonalDetailsAdvisorNuxRequest
 */
export class AddPersonalDetailsAdvisorNuxRequest extends Message<AddPersonalDetailsAdvisorNuxRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string personalEmail = 4;
   */
  personalEmail = "";

  /**
   * @generated from field: string personalPhone = 5;
   */
  personalPhone = "";

  /**
   * @generated from field: string password = 6;
   */
  password = "";

  /**
   * @generated from field: string timezone = 7;
   */
  timezone = "";

  /**
   * @generated from field: protogen.UploadAttachment profileImage = 8;
   */
  profileImage?: UploadAttachment;

  /**
   * @generated from field: string address = 9;
   */
  address = "";

  constructor(data?: PartialMessage<AddPersonalDetailsAdvisorNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddPersonalDetailsAdvisorNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "personalEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "personalPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "profileImage", kind: "message", T: UploadAttachment },
    { no: 9, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPersonalDetailsAdvisorNuxRequest {
    return new AddPersonalDetailsAdvisorNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPersonalDetailsAdvisorNuxRequest {
    return new AddPersonalDetailsAdvisorNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPersonalDetailsAdvisorNuxRequest {
    return new AddPersonalDetailsAdvisorNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPersonalDetailsAdvisorNuxRequest | PlainMessage<AddPersonalDetailsAdvisorNuxRequest> | undefined, b: AddPersonalDetailsAdvisorNuxRequest | PlainMessage<AddPersonalDetailsAdvisorNuxRequest> | undefined): boolean {
    return proto3.util.equals(AddPersonalDetailsAdvisorNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.AddPersonalDetailsAdvisorNuxResponse
 */
export class AddPersonalDetailsAdvisorNuxResponse extends Message<AddPersonalDetailsAdvisorNuxResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AddPersonalDetailsAdvisorNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddPersonalDetailsAdvisorNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPersonalDetailsAdvisorNuxResponse {
    return new AddPersonalDetailsAdvisorNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPersonalDetailsAdvisorNuxResponse {
    return new AddPersonalDetailsAdvisorNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPersonalDetailsAdvisorNuxResponse {
    return new AddPersonalDetailsAdvisorNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddPersonalDetailsAdvisorNuxResponse | PlainMessage<AddPersonalDetailsAdvisorNuxResponse> | undefined, b: AddPersonalDetailsAdvisorNuxResponse | PlainMessage<AddPersonalDetailsAdvisorNuxResponse> | undefined): boolean {
    return proto3.util.equals(AddPersonalDetailsAdvisorNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.AcceptAdvisorTermsRequest
 */
export class AcceptAdvisorTermsRequest extends Message<AcceptAdvisorTermsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: int32 termsOfServiceVersion = 2;
   */
  termsOfServiceVersion = 0;

  constructor(data?: PartialMessage<AcceptAdvisorTermsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptAdvisorTermsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "termsOfServiceVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptAdvisorTermsRequest {
    return new AcceptAdvisorTermsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptAdvisorTermsRequest {
    return new AcceptAdvisorTermsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptAdvisorTermsRequest {
    return new AcceptAdvisorTermsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptAdvisorTermsRequest | PlainMessage<AcceptAdvisorTermsRequest> | undefined, b: AcceptAdvisorTermsRequest | PlainMessage<AcceptAdvisorTermsRequest> | undefined): boolean {
    return proto3.util.equals(AcceptAdvisorTermsRequest, a, b);
  }
}

/**
 * @generated from message protogen.AcceptAdvisorTermsResponse
 */
export class AcceptAdvisorTermsResponse extends Message<AcceptAdvisorTermsResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AcceptAdvisorTermsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptAdvisorTermsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptAdvisorTermsResponse {
    return new AcceptAdvisorTermsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptAdvisorTermsResponse {
    return new AcceptAdvisorTermsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptAdvisorTermsResponse {
    return new AcceptAdvisorTermsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptAdvisorTermsResponse | PlainMessage<AcceptAdvisorTermsResponse> | undefined, b: AcceptAdvisorTermsResponse | PlainMessage<AcceptAdvisorTermsResponse> | undefined): boolean {
    return proto3.util.equals(AcceptAdvisorTermsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmContactInfoRequest
 */
export class ConfirmContactInfoRequest extends Message<ConfirmContactInfoRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<ConfirmContactInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmContactInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmContactInfoRequest {
    return new ConfirmContactInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmContactInfoRequest {
    return new ConfirmContactInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmContactInfoRequest {
    return new ConfirmContactInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmContactInfoRequest | PlainMessage<ConfirmContactInfoRequest> | undefined, b: ConfirmContactInfoRequest | PlainMessage<ConfirmContactInfoRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmContactInfoRequest, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmContactInfoResponse
 */
export class ConfirmContactInfoResponse extends Message<ConfirmContactInfoResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<ConfirmContactInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmContactInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmContactInfoResponse {
    return new ConfirmContactInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmContactInfoResponse {
    return new ConfirmContactInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmContactInfoResponse {
    return new ConfirmContactInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmContactInfoResponse | PlainMessage<ConfirmContactInfoResponse> | undefined, b: ConfirmContactInfoResponse | PlainMessage<ConfirmContactInfoResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmContactInfoResponse, a, b);
  }
}

/**
 * @generated from message protogen.AddPublicProfileRequest
 */
export class AddPublicProfileRequest extends Message<AddPublicProfileRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<AddPublicProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddPublicProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPublicProfileRequest {
    return new AddPublicProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPublicProfileRequest {
    return new AddPublicProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPublicProfileRequest {
    return new AddPublicProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPublicProfileRequest | PlainMessage<AddPublicProfileRequest> | undefined, b: AddPublicProfileRequest | PlainMessage<AddPublicProfileRequest> | undefined): boolean {
    return proto3.util.equals(AddPublicProfileRequest, a, b);
  }
}

/**
 * @generated from message protogen.AddPublicProfileResponse
 */
export class AddPublicProfileResponse extends Message<AddPublicProfileResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AddPublicProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddPublicProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPublicProfileResponse {
    return new AddPublicProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPublicProfileResponse {
    return new AddPublicProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPublicProfileResponse {
    return new AddPublicProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddPublicProfileResponse | PlainMessage<AddPublicProfileResponse> | undefined, b: AddPublicProfileResponse | PlainMessage<AddPublicProfileResponse> | undefined): boolean {
    return proto3.util.equals(AddPublicProfileResponse, a, b);
  }
}

/**
 * @generated from message protogen.FinishAdvisorNuxRequest
 */
export class FinishAdvisorNuxRequest extends Message<FinishAdvisorNuxRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<FinishAdvisorNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FinishAdvisorNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishAdvisorNuxRequest {
    return new FinishAdvisorNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishAdvisorNuxRequest {
    return new FinishAdvisorNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishAdvisorNuxRequest {
    return new FinishAdvisorNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinishAdvisorNuxRequest | PlainMessage<FinishAdvisorNuxRequest> | undefined, b: FinishAdvisorNuxRequest | PlainMessage<FinishAdvisorNuxRequest> | undefined): boolean {
    return proto3.util.equals(FinishAdvisorNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FinishAdvisorNuxResponse
 */
export class FinishAdvisorNuxResponse extends Message<FinishAdvisorNuxResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<FinishAdvisorNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FinishAdvisorNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishAdvisorNuxResponse {
    return new FinishAdvisorNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishAdvisorNuxResponse {
    return new FinishAdvisorNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishAdvisorNuxResponse {
    return new FinishAdvisorNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinishAdvisorNuxResponse | PlainMessage<FinishAdvisorNuxResponse> | undefined, b: FinishAdvisorNuxResponse | PlainMessage<FinishAdvisorNuxResponse> | undefined): boolean {
    return proto3.util.equals(FinishAdvisorNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.GenerateAdvisorContactNuxRequest
 */
export class GenerateAdvisorContactNuxRequest extends Message<GenerateAdvisorContactNuxRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string areaCode = 2;
   */
  areaCode = "";

  constructor(data?: PartialMessage<GenerateAdvisorContactNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateAdvisorContactNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "areaCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAdvisorContactNuxRequest {
    return new GenerateAdvisorContactNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAdvisorContactNuxRequest {
    return new GenerateAdvisorContactNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAdvisorContactNuxRequest {
    return new GenerateAdvisorContactNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAdvisorContactNuxRequest | PlainMessage<GenerateAdvisorContactNuxRequest> | undefined, b: GenerateAdvisorContactNuxRequest | PlainMessage<GenerateAdvisorContactNuxRequest> | undefined): boolean {
    return proto3.util.equals(GenerateAdvisorContactNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.GenerateAdvisorContactNuxResponse
 */
export class GenerateAdvisorContactNuxResponse extends Message<GenerateAdvisorContactNuxResponse> {
  /**
   * @generated from field: string advisorPhone = 1;
   */
  advisorPhone = "";

  constructor(data?: PartialMessage<GenerateAdvisorContactNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateAdvisorContactNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAdvisorContactNuxResponse {
    return new GenerateAdvisorContactNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAdvisorContactNuxResponse {
    return new GenerateAdvisorContactNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAdvisorContactNuxResponse {
    return new GenerateAdvisorContactNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAdvisorContactNuxResponse | PlainMessage<GenerateAdvisorContactNuxResponse> | undefined, b: GenerateAdvisorContactNuxResponse | PlainMessage<GenerateAdvisorContactNuxResponse> | undefined): boolean {
    return proto3.util.equals(GenerateAdvisorContactNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberNuxRequest
 */
export class CreateMemberNuxRequest extends Message<CreateMemberNuxRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string startDate = 2;
   */
  startDate = "";

  /**
   * @generated from field: protogen.BillingProduct product = 4;
   */
  product?: BillingProduct;

  /**
   * @generated from field: protogen.BillingCoupon coupon = 5;
   */
  coupon?: BillingCoupon;

  /**
   * @generated from field: string serviceDetails = 6;
   */
  serviceDetails = "";

  /**
   * @generated from field: string welcomeMessage = 7;
   */
  welcomeMessage = "";

  constructor(data?: PartialMessage<CreateMemberNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "product", kind: "message", T: BillingProduct },
    { no: 5, name: "coupon", kind: "message", T: BillingCoupon },
    { no: 6, name: "serviceDetails", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "welcomeMessage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberNuxRequest {
    return new CreateMemberNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberNuxRequest {
    return new CreateMemberNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberNuxRequest {
    return new CreateMemberNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberNuxRequest | PlainMessage<CreateMemberNuxRequest> | undefined, b: CreateMemberNuxRequest | PlainMessage<CreateMemberNuxRequest> | undefined): boolean {
    return proto3.util.equals(CreateMemberNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberNuxResponse
 */
export class CreateMemberNuxResponse extends Message<CreateMemberNuxResponse> {
  constructor(data?: PartialMessage<CreateMemberNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberNuxResponse {
    return new CreateMemberNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberNuxResponse {
    return new CreateMemberNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberNuxResponse {
    return new CreateMemberNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberNuxResponse | PlainMessage<CreateMemberNuxResponse> | undefined, b: CreateMemberNuxResponse | PlainMessage<CreateMemberNuxResponse> | undefined): boolean {
    return proto3.util.equals(CreateMemberNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmMemberLandingRequest
 */
export class ConfirmMemberLandingRequest extends Message<ConfirmMemberLandingRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<ConfirmMemberLandingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmMemberLandingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmMemberLandingRequest {
    return new ConfirmMemberLandingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmMemberLandingRequest {
    return new ConfirmMemberLandingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmMemberLandingRequest {
    return new ConfirmMemberLandingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmMemberLandingRequest | PlainMessage<ConfirmMemberLandingRequest> | undefined, b: ConfirmMemberLandingRequest | PlainMessage<ConfirmMemberLandingRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmMemberLandingRequest, a, b);
  }
}

/**
 * @generated from message protogen.ConfirmMemberLandingResponse
 */
export class ConfirmMemberLandingResponse extends Message<ConfirmMemberLandingResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<ConfirmMemberLandingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConfirmMemberLandingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmMemberLandingResponse {
    return new ConfirmMemberLandingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmMemberLandingResponse {
    return new ConfirmMemberLandingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmMemberLandingResponse {
    return new ConfirmMemberLandingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmMemberLandingResponse | PlainMessage<ConfirmMemberLandingResponse> | undefined, b: ConfirmMemberLandingResponse | PlainMessage<ConfirmMemberLandingResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmMemberLandingResponse, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberDetailsRequest
 */
export class AddMemberDetailsRequest extends Message<AddMemberDetailsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string prefContactMethod = 2;
   */
  prefContactMethod = "";

  /**
   * @generated from field: string prefContactTimes = 3;
   */
  prefContactTimes = "";

  constructor(data?: PartialMessage<AddMemberDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prefContactMethod", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prefContactTimes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberDetailsRequest {
    return new AddMemberDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberDetailsRequest {
    return new AddMemberDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberDetailsRequest {
    return new AddMemberDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberDetailsRequest | PlainMessage<AddMemberDetailsRequest> | undefined, b: AddMemberDetailsRequest | PlainMessage<AddMemberDetailsRequest> | undefined): boolean {
    return proto3.util.equals(AddMemberDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberDetailsResponse
 */
export class AddMemberDetailsResponse extends Message<AddMemberDetailsResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AddMemberDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberDetailsResponse {
    return new AddMemberDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberDetailsResponse {
    return new AddMemberDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberDetailsResponse {
    return new AddMemberDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberDetailsResponse | PlainMessage<AddMemberDetailsResponse> | undefined, b: AddMemberDetailsResponse | PlainMessage<AddMemberDetailsResponse> | undefined): boolean {
    return proto3.util.equals(AddMemberDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.AcceptMemberPlanRequest
 */
export class AcceptMemberPlanRequest extends Message<AcceptMemberPlanRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: int32 termsOfServiceVersion = 2;
   */
  termsOfServiceVersion = 0;

  constructor(data?: PartialMessage<AcceptMemberPlanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptMemberPlanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "termsOfServiceVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptMemberPlanRequest {
    return new AcceptMemberPlanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptMemberPlanRequest {
    return new AcceptMemberPlanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptMemberPlanRequest {
    return new AcceptMemberPlanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptMemberPlanRequest | PlainMessage<AcceptMemberPlanRequest> | undefined, b: AcceptMemberPlanRequest | PlainMessage<AcceptMemberPlanRequest> | undefined): boolean {
    return proto3.util.equals(AcceptMemberPlanRequest, a, b);
  }
}

/**
 * @generated from message protogen.AcceptMemberPlanResponse
 */
export class AcceptMemberPlanResponse extends Message<AcceptMemberPlanResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AcceptMemberPlanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptMemberPlanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptMemberPlanResponse {
    return new AcceptMemberPlanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptMemberPlanResponse {
    return new AcceptMemberPlanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptMemberPlanResponse {
    return new AcceptMemberPlanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptMemberPlanResponse | PlainMessage<AcceptMemberPlanResponse> | undefined, b: AcceptMemberPlanResponse | PlainMessage<AcceptMemberPlanResponse> | undefined): boolean {
    return proto3.util.equals(AcceptMemberPlanResponse, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberContactsRequest
 */
export class AddMemberContactsRequest extends Message<AddMemberContactsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<AddMemberContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberContactsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberContactsRequest {
    return new AddMemberContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberContactsRequest {
    return new AddMemberContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberContactsRequest {
    return new AddMemberContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberContactsRequest | PlainMessage<AddMemberContactsRequest> | undefined, b: AddMemberContactsRequest | PlainMessage<AddMemberContactsRequest> | undefined): boolean {
    return proto3.util.equals(AddMemberContactsRequest, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberContactsResponse
 */
export class AddMemberContactsResponse extends Message<AddMemberContactsResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AddMemberContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberContactsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberContactsResponse {
    return new AddMemberContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberContactsResponse {
    return new AddMemberContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberContactsResponse {
    return new AddMemberContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberContactsResponse | PlainMessage<AddMemberContactsResponse> | undefined, b: AddMemberContactsResponse | PlainMessage<AddMemberContactsResponse> | undefined): boolean {
    return proto3.util.equals(AddMemberContactsResponse, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberTasksRequest
 */
export class AddMemberTasksRequest extends Message<AddMemberTasksRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string taskInquiry = 2;
   */
  taskInquiry = "";

  constructor(data?: PartialMessage<AddMemberTasksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberTasksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "taskInquiry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberTasksRequest {
    return new AddMemberTasksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberTasksRequest {
    return new AddMemberTasksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberTasksRequest {
    return new AddMemberTasksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberTasksRequest | PlainMessage<AddMemberTasksRequest> | undefined, b: AddMemberTasksRequest | PlainMessage<AddMemberTasksRequest> | undefined): boolean {
    return proto3.util.equals(AddMemberTasksRequest, a, b);
  }
}

/**
 * @generated from message protogen.AddMemberTasksResponse
 */
export class AddMemberTasksResponse extends Message<AddMemberTasksResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<AddMemberTasksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AddMemberTasksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberTasksResponse {
    return new AddMemberTasksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberTasksResponse {
    return new AddMemberTasksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberTasksResponse {
    return new AddMemberTasksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddMemberTasksResponse | PlainMessage<AddMemberTasksResponse> | undefined, b: AddMemberTasksResponse | PlainMessage<AddMemberTasksResponse> | undefined): boolean {
    return proto3.util.equals(AddMemberTasksResponse, a, b);
  }
}

/**
 * @generated from message protogen.FinishMemberNuxRequest
 */
export class FinishMemberNuxRequest extends Message<FinishMemberNuxRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<FinishMemberNuxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FinishMemberNuxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishMemberNuxRequest {
    return new FinishMemberNuxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishMemberNuxRequest {
    return new FinishMemberNuxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishMemberNuxRequest {
    return new FinishMemberNuxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinishMemberNuxRequest | PlainMessage<FinishMemberNuxRequest> | undefined, b: FinishMemberNuxRequest | PlainMessage<FinishMemberNuxRequest> | undefined): boolean {
    return proto3.util.equals(FinishMemberNuxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FinishMemberNuxResponse
 */
export class FinishMemberNuxResponse extends Message<FinishMemberNuxResponse> {
  /**
   * @generated from field: protogen.NuxDetails nuxDetails = 1;
   */
  nuxDetails?: NuxDetails;

  constructor(data?: PartialMessage<FinishMemberNuxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FinishMemberNuxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nuxDetails", kind: "message", T: NuxDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinishMemberNuxResponse {
    return new FinishMemberNuxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinishMemberNuxResponse {
    return new FinishMemberNuxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinishMemberNuxResponse {
    return new FinishMemberNuxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinishMemberNuxResponse | PlainMessage<FinishMemberNuxResponse> | undefined, b: FinishMemberNuxResponse | PlainMessage<FinishMemberNuxResponse> | undefined): boolean {
    return proto3.util.equals(FinishMemberNuxResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateNuxChangeEventRequest
 */
export class CreateNuxChangeEventRequest extends Message<CreateNuxChangeEventRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  constructor(data?: PartialMessage<CreateNuxChangeEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateNuxChangeEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNuxChangeEventRequest {
    return new CreateNuxChangeEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNuxChangeEventRequest {
    return new CreateNuxChangeEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNuxChangeEventRequest {
    return new CreateNuxChangeEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNuxChangeEventRequest | PlainMessage<CreateNuxChangeEventRequest> | undefined, b: CreateNuxChangeEventRequest | PlainMessage<CreateNuxChangeEventRequest> | undefined): boolean {
    return proto3.util.equals(CreateNuxChangeEventRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateNuxChangeEventResponse
 */
export class CreateNuxChangeEventResponse extends Message<CreateNuxChangeEventResponse> {
  constructor(data?: PartialMessage<CreateNuxChangeEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateNuxChangeEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNuxChangeEventResponse {
    return new CreateNuxChangeEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNuxChangeEventResponse {
    return new CreateNuxChangeEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNuxChangeEventResponse {
    return new CreateNuxChangeEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNuxChangeEventResponse | PlainMessage<CreateNuxChangeEventResponse> | undefined, b: CreateNuxChangeEventResponse | PlainMessage<CreateNuxChangeEventResponse> | undefined): boolean {
    return proto3.util.equals(CreateNuxChangeEventResponse, a, b);
  }
}

