import { EphemeralTask } from "../../types/tasks";
import { useContext } from "react";
import { CurrentUserContext } from "../context/RequireAuth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateTask,
  useCreateMemberTask,
  useUpdateTask,
  useUpdateMemberTask,
} from "services/tasks";
import { CreateTaskRequest, EditTaskRequest } from "protogen/tasks_service_pb";
import { TaskState } from "protogen/tasks_pb";
import {
  ConfirmationState,
  useConfirmationDialog,
} from "../common/ConfirmationDialog";

type CreateTaskHelperReturn = {
  createTask: (task: EphemeralTask) => Promise<void>;
  loading: boolean;
};
const createTaskHelper = ({
  referral = "1",
  creatorType = "advisor",
  assigneeRef,
}: {
  referral?: string;
  creatorType?: "advisor" | "member";
  assigneeRef?: string;
}): CreateTaskHelperReturn => {
  const currentUser = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const requestFn =
    creatorType === "advisor" ? useCreateTask : useCreateMemberTask;
  const { request, loading } = requestFn((r) => {
    if (creatorType === "advisor") {
      navigate(
        `/tasks/${encodeURIComponent(r?.task?.ref)}?newTaskFrom=${referral}`,
      );
    } else if (creatorType === "member") {
      navigate(`/tasks/${encodeURIComponent(r?.task?.ref)}`);
    }
  });

  const createTask = async (task: EphemeralTask) => {
    if (task.familyRef && task.title) {
      await request(
        new CreateTaskRequest({
          assigneeRef: assigneeRef ? assigneeRef : currentUser.ref,
          priority: "medium",
          ...task,
        }),
      );
    }
  };
  return { createTask, loading };
};

const useIsNewTask = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isFresh = !!queryParams.get("newTaskFrom");
  return isFresh;
};

const taskDeletion = (
  isMember: boolean = false,
): {
  deleteTask: (taskRef: string) => Promise<void>;
  confirmState: ConfirmationState;
} => {
  const isFresh = useIsNewTask();

  const navigate = useNavigate();
  // check if new.
  const confirmState = useConfirmationDialog();
  const useUpdateTaskFn = isMember ? useUpdateMemberTask : useUpdateTask;
  const { request } = useUpdateTaskFn();
  const deleteTask = async (taskRef: string) => {
    const resp = await request(
      new EditTaskRequest({
        taskRef,
        updatedState: TaskState.DELETED,
      }),
    );
    if (resp) {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate(`/tasks`);
      }
    }
  };
  const confirmDeleteTask = async (taskRef: string) => {
    confirmState.openDialog(async () => deleteTask(taskRef));
  };
  return {
    confirmState,
    deleteTask: isFresh ? deleteTask : confirmDeleteTask,
  };
};

const dateStringToDayAndDate = (dateString: string, shortDates: boolean) => {
  // "3/28/2024"
  let parts = dateString.split("/");
  const monthPart = parseInt(parts[0]) - 1; // months are 0-based in JavaScript
  const dayPart = parseInt(parts[1]);
  const yearPart = parseInt(parts[2]);

  // Passing in the parts makes the day explicit.  If not JS date will try to convert to users local tz
  const date = new Date(yearPart, monthPart, dayPart);
  const day = date.toLocaleDateString("en-US", {
    weekday: shortDates ? "short" : "long",
  });
  const dateStr = date.toLocaleDateString("en-US", {
    day: "numeric",
    ...(shortDates ? {} : { month: "short" }),
  });
  return [day, dateStr];
};

const minutesToDisplay = (minutes: number): string => {
  return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
};

export {
  createTaskHelper,
  useIsNewTask,
  taskDeletion,
  dateStringToDayAndDate,
  minutesToDisplay,
};
