import { useParams } from "react-router-dom";
import {
  Box,
  Skeleton,
  Button,
  Link,
  Typography,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import DocumentViewer from "components/editor/DocumentViewer";
import GridPage from "components/layout/GridPage";
import { useFetchPublicAdvisorProfile } from "services/public";
import { UserAvatar, DefaultAvatar } from "components/common/CurrentUserAvatar";
import AdvisorDetails from "components/advisor/AdvisorDetails";
import useIsMobile from "components/hooks/useIsMobile";
import MemberInquiry from "components/advisor/MemberInquiry";
import { ReactComponent as FayeIcon } from "../icons/FayeLight.svg";
import AdvisorSpecialties from "components/advisor/AdvisorSpecialties";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const AdvisorStaticContent = ({ className }: { className: string }) => {
  const header = "What can a Faye Family Advisor do?";
  const description = (
    <>
      Your local Family Advisor will help with the strategic tasks that take up
      too much time and brainspace.
      <br />
      <br />
      Here are some quick ideas to get you started:
    </>
  );
  const listItems = [
    "Register the kids for activities",
    "Meal plan and order grocery delivery",
    "Plan your next family vacation",
    "Source home contractors and schedule visits",
    "Send flowers for your bestie's birthday",
  ];
  return (
    <Box
      sx={{ padding: "24px 32px", backgroundColor: "#FDF9F7" }}
      className={className}
    >
      <Box sx={{ marginBottom: "16px" }}>
        <Typography variant="h3Serif">{header}</Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
        }}
      >
        {description}
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        {listItems.map((item) => (
          <ListItem
            sx={{ display: "list-item", marginLeft: 4, padding: "0px" }}
            key={item}
          >
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const Sidebar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "108px 70px 0px 0px",
        height: "100%",
      }}
    >
      <Box sx={{ paddingLeft: "35px" }}>
        <AdvisorStaticContent className={""} />
      </Box>
    </Box>
  );
};

const FooterAdvisorStaticContent = styled(AdvisorStaticContent)({
  padding: "35px 0px",
  maxWidth: "750px",
});

export default () => {
  let params = useParams();
  const isMobile = useIsMobile();
  useState<boolean>(false);
  const { request, data, loading, error } = useFetchPublicAdvisorProfile();

  useEffect(() => {
    const profilePath = params.profilePath!;
    request({ profilePath });
  }, [params.profilePath]);

  const advisor = data?.advisor;
  return (
    <GridPage sx={{ padding: "0px" }}>
      <Grid
        container
        sx={{
          height: "72px",
          backgroundColor: "#FDFAF7",
          borderBottom: "1px solid #E8E8E8",
        }}
      >
        <Grid
          item
          xs={isMobile ? 6 : 8}
          sx={{ paddingLeft: isMobile ? "35px" : "70px" }}
        >
          <CenteredBox
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                width: "100%",
                maxWidth: "750px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "& svg path": {
                  fill: "#198282",
                },
              }}
            >
              <Link
                href="https://findfaye.com"
                target="_blank"
                underline="none"
              >
                <FayeIcon />
              </Link>
            </Box>
          </CenteredBox>
        </Grid>
        <Grid
          item
          xs={isMobile ? 6 : 4}
          sx={{ paddingRight: isMobile ? "14px" : "20px" }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Button variant={"outlined"} aria-label="Learn more">
              <Link
                href="https://findfaye.com"
                target="_blank"
                underline="none"
              >
                Learn more
              </Link>
            </Button>
          </Box>
        </Grid>
      </Grid>
      {error && <div>Not Found</div>}
      {!error && (
        <Grid container>
          {/* It should span the full width on mobile (12).  The other grid panel is removed. */}
          <Grid item xs={isMobile ? 12 : 8}>
            <CenteredBox
              sx={{
                padding: isMobile ? "24px" : "108px 70px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "750px",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  ...(isMobile
                    ? {
                        marginBottom: "10px",
                      }
                    : {
                        marginBottom: "44px",
                      }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    ...(isMobile
                      ? {
                          marginBottom: "10px",
                          gap: "20px",
                          flexDirection: "column",
                          alignItems: "center",
                        }
                      : {
                          marginBottom: "35px",
                          gap: "50px",
                          flexDirection: "row",
                        }),
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      maxWidth: isMobile ? "100px" : "158px",
                      width: "100%",
                    }}
                  >
                    {advisor ? (
                      <UserAvatar
                        clickable={false}
                        user={advisor}
                        size={isMobile ? 100 : 158}
                      />
                    ) : (
                      <DefaultAvatar size={isMobile ? 100 : 158} />
                    )}
                  </Box>
                  <AdvisorDetails
                    advisor={advisor}
                    loading={loading}
                    isPublic={true}
                  />
                </Box>
                <AdvisorSpecialties advisor={advisor} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography variant="h2Serif">About me</Typography>
                  {loading ? (
                    <Skeleton width="80%" />
                  ) : (
                    <DocumentViewer
                      sx={{ marginBottom: "44px" }}
                      content={advisor?.blurbContent || ""}
                    />
                  )}
                </Box>
                <MemberInquiry />
              </Box>
              {isMobile && <FooterAdvisorStaticContent className={""} />}
            </CenteredBox>
          </Grid>
          {!isMobile && (
            <Grid item xs={4}>
              <Sidebar />
            </Grid>
          )}
        </Grid>
      )}
    </GridPage>
  );
};
