import React, { useState } from "react";

interface PositionOffset {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  offsetX?: number;
  offsetY?: number;
}

export default (position: string, isMobile: boolean) => {
  const getInitialPosition = (): PositionOffset => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    // Positions relative to window width for mobile
    if (isMobile && position.startsWith("top"))
      return { top: screenHeight * 0.05, left: screenWidth * 0.05 };
    if (isMobile && position.startsWith("bottom"))
      return { bottom: screenHeight * 0.05, left: screenWidth * 0.05 };
    switch (position) {
      case "bottom-right":
        return { bottom: 20, right: 20 };
      case "bottom-left":
        return { bottom: 20, left: 20 };
      case "top-right":
        return { top: 20, right: 20 };
      case "top-left":
        return { top: 20, left: 20 };
      default:
        return { bottom: 20, right: 20 };
    }
  };
  const [positionOffset, setPositionOffset] =
    useState<PositionOffset>(getInitialPosition());
  const [dragging, setDragging] = useState(false);

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    if ((e.target as HTMLElement).tagName.toLowerCase() === "button") return;
    setDragging(true);
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    if (e.type === "mousedown") {
      const mouseEvent = e as React.MouseEvent<HTMLDivElement>;
      setPositionOffset((prev) => ({
        ...prev,
        offsetX: mouseEvent.clientX - rect.left,
        offsetY: mouseEvent.clientY - rect.top,
      }));
    } else if (e.type === "touchstart") {
      const touchEvent = e as React.TouchEvent<HTMLDivElement>;
      setPositionOffset((prev) => ({
        ...prev,
        offsetX: touchEvent.touches[0].clientX - rect.left,
        offsetY: touchEvent.touches[0].clientY - rect.top,
      }));
    }
  };

  const handleMouseMove = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    if (!dragging) return;
    if (e.type === "mousemove") {
      const mouseEvent = e as React.MouseEvent<HTMLDivElement>;
      setPositionOffset((prev) => ({
        ...prev,
        left: mouseEvent.clientX - (prev.offsetX ?? 0),
        top: mouseEvent.clientY - (prev.offsetY ?? 0),
      }));
    } else if (e.type === "touchmove") {
      const touchEvent = e as React.TouchEvent<HTMLDivElement>;
      setPositionOffset((prev) => ({
        ...prev,
        left: touchEvent.touches[0].clientX - (prev.offsetX ?? 0),
        top: touchEvent.touches[0].clientY - (prev.offsetY ?? 0),
      }));
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return { positionOffset, handleMouseDown, handleMouseMove, handleMouseUp };
};
