import React from "react";
import DefaultForm from "./forms/DefaultForm";
import { FactFormHandle, FactFormProps } from "./types";
import ContactForm, { renderTextContact } from "./forms/ContactForm";
import OccupationForm, { renderTextOccupation } from "./forms/OccupationForm";
import SchoolForm, { renderTextSchool } from "./forms/SchoolForm";

interface FactType {
  value: string;
  label: string;
  view: React.ForwardRefExoticComponent<
    FactFormProps & React.RefAttributes<FactFormHandle>
  >;
  json: boolean;
  renderText?: (s: string) => string;
}

export const DEFAULT_FACT_TYPE: FactType = {
  value: "generic",
  label: "Observation",
  view: DefaultForm,
  json: false,
};

export const FACT_TYPES: FactType[] = [
  DEFAULT_FACT_TYPE,
  {
    value: "document",
    label: "Document",
    view: DefaultForm,
    json: false,
  },
  {
    value: "contact",
    label: "Contact",
    view: ContactForm,
    json: true,
    renderText: renderTextContact,
  },
  {
    value: "occupation",
    label: "Occupation",
    view: OccupationForm,
    json: true,
    renderText: renderTextOccupation,
  },
  {
    value: "school",
    label: "School",
    view: SchoolForm,
    json: true,
    renderText: renderTextSchool,
  },
];
