import { PlainMessage } from "@bufbuild/protobuf";
import { Attachment, UploadAttachment } from "protogen/common_pb";
import { FactStub, FactSectionStub } from "protogen/facts_pb";
import { attachmentsToUploads } from "../creation/FileUploader";

export type EphemeralFact = {
  ref?: string;
  name: string;
  value: string;
  valueType: string;
  attachments: Attachment[];
  uploads: PlainMessage<UploadAttachment>[];
  // Used for associating a task with where it was made.
  sourceEntityType?: string;
  sourceEntityRef?: string;
  sourceEntityIndex?: number;
  sortOrder?: number;
  sectionRef?: string;
};

export type EphemeralFactSection = {
  ref?: string;
  name: string;
  sortOrder?: number;
};

export type FormErrors = {
  [key: string]: string;
};

export type FactFormProps = {
  fact: EphemeralFact;
  sections: FactSectionStub[];
  updateFormData: (f: EphemeralFact) => void;
  errors: FormErrors | null;
};

export type FactSectionFormProps = {
  factSection: EphemeralFactSection;
  updateFormData: (f: EphemeralFactSection) => void;
  errors: FormErrors | null;
};

export type FactFormHandle = {
  isValid: () => boolean;
};

export const buildEphemeralFact = (fact: FactStub): EphemeralFact => {
  return {
    ref: fact.ref,
    name: fact.name,
    value: fact.value,
    valueType: fact.valueType,
    attachments: fact.attachments || [],
    uploads: attachmentsToUploads(fact.attachments || []),
    sectionRef: fact.sectionRef,
  };
};

export const buildEphemeralFactSection = (
  factSection: FactSectionStub,
): EphemeralFactSection => {
  return {
    ref: factSection.ref,
    name: factSection.name,
    sortOrder: factSection.sortOrder,
  };
};
