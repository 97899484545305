import { useParams } from "react-router-dom";
import { Box, Skeleton, Typography, Alert, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DocumentViewer from "components/editor/DocumentViewer";
import { UserAvatar, DefaultAvatar } from "components/common/CurrentUserAvatar";
import AdvisorDetails from "components/advisor/AdvisorDetails";
import useIsMobile from "components/hooks/useIsMobile";
import AdvisorSpecialties from "components/advisor/AdvisorSpecialties";
import FlexPage from "components/layout/FlexPage";
import { useGetAdvisorProfile } from "services/member";
import { CurrentUserContext } from "components/context/RequireAuth";
import { Copy } from "lucide-react";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";

const ReferralSection = ({ path }: { path: string }) => {
  const currentUser = useContext(CurrentUserContext);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const copyCode = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${path}?code=${encodeURIComponent(
        currentUser.ref,
      )}`,
    );
    setSnackPack((prev) => [
      ...prev,
      {
        key: "copied",
        message: `Link copied to clipboard`,
      },
    ]);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="h2Serif">Referrals are my Faye-vorite</Typography>
      <Typography variant="body">
        If you're happy with your experience, share my business with friends
        using your unique referral link.
      </Typography>
      <Button
        variant={"text"}
        endIcon={<Copy size={"20px"} />}
        onClick={copyCode}
        sx={{ alignSelf: "start", height: "unset" }}
      >
        Copy link
      </Button>
      <SnackPack
        snackPack={snackPack}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};

export default () => {
  let params = useParams();
  const isMobile = useIsMobile();
  useState<boolean>(false);
  const { request, data, loading, error } = useGetAdvisorProfile();

  useEffect(() => {
    const profileRef = params.profileRef!;
    request({ advisorRef: profileRef });
  }, [params.profileRef]);

  const advisor = data?.profile;
  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box>
        {error && <Alert severity="warning">Not Found</Alert>}
        {!error && (
          <Box
            sx={{
              maxWidth: "750px",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              ...(isMobile
                ? {
                    marginBottom: "10px",
                  }
                : {
                    marginBottom: "44px",
                  }),
            }}
          >
            <Box
              sx={{
                display: "flex",
                ...(isMobile
                  ? {
                      marginBottom: "10px",
                      gap: "20px",
                      flexDirection: "column",
                      alignItems: "center",
                    }
                  : {
                      marginBottom: "35px",
                      gap: "50px",
                      flexDirection: "row",
                    }),
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  maxWidth: isMobile ? "100px" : "158px",
                  width: "100%",
                }}
              >
                {advisor ? (
                  <UserAvatar
                    clickable={false}
                    user={advisor}
                    size={isMobile ? 100 : 158}
                  />
                ) : (
                  <DefaultAvatar size={isMobile ? 100 : 158} />
                )}
              </Box>
              <AdvisorDetails
                advisor={advisor}
                loading={loading}
                isPublic={false}
              />
            </Box>
            <AdvisorSpecialties advisor={advisor} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="h2Serif">About me</Typography>
              {loading ? (
                <Skeleton width="80%" />
              ) : (
                <DocumentViewer
                  sx={{ marginBottom: "44px" }}
                  content={advisor?.blurbContent || ""}
                />
              )}
            </Box>
            <ReferralSection path={advisor?.profilePath} />
          </Box>
        )}
      </Box>
    </FlexPage>
  );
};
