export const VAPID_PUBLIC_KEY =
  "BAtxT2-SArSl4dxCkB3EshKVrEJUdIHV0atq85h4dPG-Dhz06-zxSKydtqOY6M829bIqqD7ay-lDELIH8ZXGmY0";

const FORCE_PROD_APIS = process.env.REACT_APP_API_ENVIRONMENT === "production";

export const getApiDomain = () => {
  let basePath = "https://api.findfaye.com/api";
  const localhostMatch = window.location.host.match(/localhost/);
  const stagingMatch = window.location.host.match(/staging\d+/);
  if (FORCE_PROD_APIS) {
    console.warn("Using production APIs: ", basePath);
  } else if (localhostMatch) {
    basePath = "https://localhost:3001/api";
  } else if (stagingMatch) {
    // TODO(Kip): How do we want to handle this.
    basePath = `???`;
  }
  return basePath;
};

export const getInferenceDomain = () => {
  let basePath = "https://inference.findfaye.com/api";
  const localhostMatch = window.location.host.match(/localhost/);
  const stagingMatch = window.location.host.match(/staging\d+/);
  if (FORCE_PROD_APIS) {
    console.warn("Using production APIs: ", basePath);
  } else if (localhostMatch) {
    basePath = "https://localhost:3001/api";
  } else if (stagingMatch) {
    // TODO(Kip): How do we want to handle this.
    basePath = `???`;
  }
  return basePath;
};
