import { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Typography, Popper } from "@mui/material";
import { SmilePlus } from "lucide-react";
import { useUpdateMessageReaction } from "services/messaging";
import { DirectMessage, MessageReaction } from "protogen/messaging_service_pb";
import { CurrentUser } from "protogen/auth_pb";
import LikerTooltip, { Liker } from "../forum/LikerTooltip";

// Note - changing this might require tweaking the positioning of the selectors.
const REACTION_EMOJIS = ["👍", "👎", "❤️", "😂", "🎉"];

const ReactionBubble = ({
  onClick,
  children,
  hidden = false,
  isActive = false,
}: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  children: ReactNode;
  hidden?: boolean;
  isActive?: boolean;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="8px"
      sx={{
        visibility: hidden ? "hidden" : "visible",
        padding: "6px 12px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: "32px",
        borderRadius: "100px",
        ...(isActive
          ? {
              border: "2px solid #b4b5b6",
              backgroundColor: "#f0f1f1",
            }
          : {
              border: "1px solid #E2E3E4",
              background: "#FFF",
            }),
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

const ReactionAction = ({
  active,
  message,
  currentUser,
  refreshFeed,
}: {
  active: boolean;
  message: DirectMessage;
  currentUser: CurrentUser;
  refreshFeed?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <div style={{ position: "relative" }}>
      <ReactionBubble
        onClick={(event) => {
          setAnchorEl((prevAnchorEl) =>
            prevAnchorEl ? null : event.currentTarget,
          );
        }}
        hidden={!(active || anchorEl)}
      >
        <SmilePlus size={20} />
      </ReactionBubble>
      {anchorEl && (
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={"top"}>
          <ReactionSelector
            currentUser={currentUser}
            message={message}
            onClose={() => setAnchorEl(null)}
            refreshFeed={refreshFeed}
          />
        </Popper>
      )}
    </div>
  );
};

const ReactionRow = ({
  alignLeft,
  message,
  currentUser,
  refreshFeed,
}: {
  alignLeft: boolean;
  message: DirectMessage;
  currentUser: CurrentUser;
  refreshFeed?: () => void;
}) => {
  const [reactions, setReactions] = useState<MessageReaction[]>(
    message.reactions,
  );
  const { request, loading } = useUpdateMessageReaction();
  const hasLiked = new Set(
    reactions
      .filter((r) => r.reactor?.ref === currentUser.ref)
      .map((r) => r.reaction),
  );
  useEffect(() => {
    setReactions(message.reactions);
  }, [message]);
  // Group reactions by char and count.
  const countMap = reactions.reduce(
    (acc, obj) => {
      if (acc[obj.reaction]) {
        acc[obj.reaction].push({
          userRef: obj.reactor?.ref || "",
          displayName: obj.reactor?.displayName || "",
        });
      } else {
        acc[obj.reaction] = [
          {
            userRef: obj.reactor?.ref || "",
            displayName: obj.reactor?.displayName || "",
          },
        ];
      }
      return acc;
    },
    {} as { [key: string]: Liker[] },
  );
  const handleClick = async (reaction: string) => {
    if (loading) return;
    const resp = await request({
      messageRef: message.ref,
      reaction,
      isRemoval: hasLiked.has(reaction),
    });
    if (resp) {
      setReactions(resp.reactions);
      refreshFeed?.();
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="7px"
      alignItems={alignLeft ? "flex-start" : "flex-end"}
      mt="8px"
    >
      {Object.keys(countMap).map((name) => (
        <LikerTooltip
          key={name}
          likers={countMap[name]}
          total={countMap[name].length}
        >
          <ReactionBubble
            isActive={hasLiked.has(name)}
            onClick={() => handleClick(name)}
          >
            <Typography variant="body">
              {countMap[name].length > 1 && <>{countMap[name].length} </>}
              {name}
            </Typography>
          </ReactionBubble>
        </LikerTooltip>
      ))}
    </Box>
  );
};

const ReactionSelector = ({
  message,
  onClose,
  currentUser,
  refreshFeed,
}: {
  message: DirectMessage;
  onClose: () => void;
  currentUser: CurrentUser;
  refreshFeed?: () => void;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [activeChar, setActiveChar] = useState<string | null>(null);
  const { request, loading } = useUpdateMessageReaction();
  const hasLiked = new Set(
    message.reactions
      .filter((r) => r.reactor?.ref === currentUser.ref)
      .map((r) => r.reaction),
  );
  const handleClick = async (reaction: string) => {
    if (loading) return;
    setActiveChar(reaction);
    await request({
      messageRef: message.ref,
      reaction,
      isRemoval: hasLiked.has(reaction),
    });
    setActiveChar(null);
    refreshFeed?.();
    onClose();
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the divRef element
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Box
      ref={divRef}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
        background: "#FFF",
        border: "1px solid #E2E3E4",
        borderRadius: "100px",
      }}
    >
      {REACTION_EMOJIS.map((r, i) => (
        <Box
          key={i}
          onClick={() => handleClick(r)}
          sx={{
            cursor: "pointer",
            padding: "4px 10px",
            borderRadius: "100px",
            fontSize: "20px",
            backgroundColor:
              hasLiked.has(r) || activeChar === r ? "#E2E3E4" : undefined,
          }}
        >
          {r}
        </Box>
      ))}
    </Box>
  );
};

export { ReactionRow, ReactionAction, ReactionSelector };
