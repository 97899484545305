import { BillingCoupon, BillingProduct } from "protogen/billing_pb";

export const defaultPlanDetails = (): PlanDetailsForm => {
  return {
    startDate: undefined,
    product: undefined,
    coupon: undefined,
  };
};

export const validateFamilyInvitation = (
  planDetails: PlanDetailsForm,
  serviceDetails: string,
): Errors => {
  const errors: Errors = {};
  if (!planDetails.startDate) {
    errors["startDate"] = "State date is required";
  }
  if (!planDetails?.product?.id) {
    errors["product"] = "A service tier is required";
  }
  if (!serviceDetails) {
    errors["serviceDetails"] = "Service details are required";
  }
  return errors;
};

export type PlanDetailsForm = {
  startDate: Date | undefined;
  product: BillingProduct | undefined;
  coupon: BillingCoupon | undefined;
};

export type Errors = {
  [key: string]: string;
};

export const couponDescription = (coupon: BillingCoupon): string => {
  if (coupon.percentOff > 0) {
    return `${coupon.percentOff}% off${
      coupon.duration ? " " + coupon.duration : ""
    }`;
  } else {
    return `$${(coupon.amountOffCents / 100).toFixed(2)} off${
      coupon.duration ? " " + coupon.duration : ""
    }`;
  }
};
