import FormStep from "./FormStep";
import { validateForm } from "../utils";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { EphemeralAdvisor, FormErrors } from "../types";
import { hasErrors } from "./utils";
import { ContactCard } from "../../common/ContactCardEntry";

type Props = {
  formData: EphemeralAdvisor;
  updateFormData: (f: EphemeralAdvisor) => void;
  errors: FormErrors | null;
  handleBack: () => void;
  onSubmit: () => void;
  loading: boolean;
  editMode: boolean;
  actionName: string;
  disabled: boolean;
};
export default ({
  formData,
  updateFormData,
  actionName,
  handleBack,
  onSubmit,
  editMode,
  disabled,
  loading,
  ...otherProps
}: Props) => {
  return (
    <FormStep title={actionName} noActions={true} {...otherProps}>
      <Box display="flex" flexDirection="column">
        {!editMode && (
          <>
            <Typography>
              All steps completed - we are now ready to create a new advisor
              account.
            </Typography>
            <Typography>
              The intro email will include a link to setup their password. If
              you do not send this email they will need to use the "Forgot
              Password" flow to access their account.
            </Typography>
            <FormControlLabel
              label="Send Intro Email"
              control={
                <Checkbox
                  checked={formData?.sendIntroEmail}
                  onChange={(e) =>
                    updateFormData({ sendIntroEmail: e.target.checked })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </>
        )}
        {editMode && (
          <ContactCard
            fullName={`${formData.firstName} ${formData.lastName}`}
            tel={formData.advisorPhone || ""}
            email={formData.username || ""}
          />
        )}
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
          <Button
            disabled={loading || hasErrors(validateForm(formData)) || disabled}
            onClick={onSubmit}
            sx={{ mt: 1, mr: 1 }}
          >
            {actionName}
          </Button>
        </Box>
      </Box>
    </FormStep>
  );
};
