import { Box, Button } from "@mui/material";
import { SecretStub } from "protogen/secrets_pb";
import SecretList from "../secrets/SecretListView";

interface Props {
  secrets: SecretStub[];
  handleSecretClick: (secretRef: string) => void;
}
export default ({ secrets, handleSecretClick }: Props) => {
  return (
    <Box>
      <SecretList secrets={secrets} handleSecretClick={handleSecretClick} />
      <Box sx={{ marginTop: "16px" }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleSecretClick("");
          }}
        >
          New secret
        </Button>
      </Box>
    </Box>
  );
};
