import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import AddServiceWorker from "./components/context/AddServiceWorker";

import axios from "axios";
import { getApiDomain, getInferenceDomain } from "./constants";
import AdvisorApp from "./AdvisorApp";
import MemberApp from "./MemberApp";
import StatMeApp from "./StatMeApp";
import PushContextProvider from "./components/context/PushContextProvider";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b66261dcf26c1c09edca35955814f27b@o4507744123879424.ingest.us.sentry.io/4507744126107648",
  enabled: process.env.NODE_ENV !== "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

const _warmupApi = async (domain: string) => {
  axios.post(`${domain}/health?warmup=true`);
};

const _checkDevSetup = () => {
  const overrideFavicon = (url: string): void => {
    // Find and remove any existing favicon elements
    const existingIcons = document.querySelectorAll("link[rel~='icon']");
    existingIcons.forEach((icon) => icon.parentNode?.removeChild(icon));

    // Create a new link element for the favicon
    const newIcon = document.createElement("link");
    newIcon.rel = "icon";
    newIcon.href = url;

    // Append the new favicon to the head
    document.head.appendChild(newIcon);
  };

  if (process.env.NODE_ENV !== "development") {
    return;
  }
  console.log("Development mode.");
  // Horribly inefficient size for a favicon, but it's just for local development.
  overrideFavicon("/favicons/dev-favicon.png");
};

const startupAdvisor = async () => {
  // Poke the inference server since it can take some time to warmup.
  await _warmupApi(getInferenceDomain());
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <AddServiceWorker>
          <PushContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AdvisorApp />
            </LocalizationProvider>
          </PushContextProvider>
        </AddServiceWorker>
      </BrowserRouter>
    </ThemeProvider>,
  );
  _checkDevSetup();
};

const startupMember = async () => {
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MemberApp />
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>,
  );
  _checkDevSetup();
};

const startupStatMethat = async () => {
  // Poke the inference server since it can take some time to warmup.
  await _warmupApi(getApiDomain());
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <StatMeApp />
      </BrowserRouter>
    </ThemeProvider>,
  );
  _checkDevSetup();
};

if (process.env.REACT_APP_BUILD_TARGET === "statmethat") {
  startupStatMethat();
} else if (process.env.REACT_APP_BUILD_TARGET === "member") {
  startupMember();
} else {
  startupAdvisor();
}
