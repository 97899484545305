import useIsMobile from "../hooks/useIsMobile";
import { ReactElement } from "react";
import { Fade } from "@mui/material";

export default ({
  visible,
  children,
}: {
  visible: boolean;
  children: ReactElement;
}) => {
  const isMobile = useIsMobile();
  if (!isMobile) {
    return children;
  }
  return (
    <Fade in={visible} mountOnEnter unmountOnExit>
      {children}
    </Fade>
  );
};
