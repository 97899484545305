import useDeviceManager, {
  DeviceManagerReturnType,
} from "../phone/useDeviceManager";
import { createContext, ReactNode, useEffect, useState } from "react";
import { PhoneState } from "../../types/phone";
import { PhoneCall } from "protogen/conversation_pb";

type contextType = {
  device: DeviceManagerReturnType;
  acceptNextCall: boolean;
  setAcceptNextCall: () => void;
  activeCall: PhoneCall | null;
  setActiveCall: (s: PhoneCall | null) => void;
};
export const CallContext = createContext<contextType>({
  device: {
    phoneState: PhoneState.NOT_CONFIGURED,
    isMuted: false,
  },
  acceptNextCall: false,
  setAcceptNextCall: () => {},
  activeCall: null,
  setActiveCall: () => {},
});

export const AddCallContext = ({ children }: { children: ReactNode }) => {
  const device = useDeviceManager();
  const [acceptNextCall, setAcceptNextCall] = useState(false);
  const [activeCall, setActiveCall] = useState<PhoneCall | null>(null);

  useEffect(() => {
    const { acceptCall, phoneState } = device;
    if (phoneState === PhoneState.RINGING && acceptNextCall) {
      acceptCall && acceptCall();
      setAcceptNextCall(false);
    }
  }, [device, acceptNextCall]);
  return (
    <CallContext.Provider
      value={{
        device: device,
        acceptNextCall,
        setAcceptNextCall: () => setAcceptNextCall(true),
        activeCall: activeCall,
        setActiveCall,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
