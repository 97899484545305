import { ReactNode, useMemo, useState } from "react";
import { RichContent } from "./utils";
import { debounce, isEqual } from "lodash";
import SavingIndicator from "./SavingIndicator";

type Output = {
  savingInProgress: boolean;
  editorContent: RichContent | null;
  setEditorContent: (c: RichContent | null) => void;
  lastEditSec: number | null;
  savingIndicator: ReactNode;
};

export default (
  initialContent: string | null,
  initialLastEditSec: number | null,
  doSave: (c: RichContent) => Promise<void>,
  hasError: boolean = false,
  updateBufferSec: number = 2500,
  contentType: string | null = "json",
): Output => {
  const [isSaving, setIsSaving] = useState(false);
  const [content, setContent] = useState<RichContent | null>(null);
  const [lastEditSec, setLastEditSec] = useState<number | null>(
    initialLastEditSec,
  );

  const update = async (c: RichContent | null) => {
    setIsSaving(true);
    if (c) {
      await doSave(c);
    }
    setLastEditSec(Math.floor(Date.now() / 1000));
    setIsSaving(false);
  };

  // Use the debounce function to save data with a delay
  const debouncedUpdate = useMemo(
    () => debounce(update, updateBufferSec, { trailing: true }), // Adjust the delay as needed (e.g., 1000ms = 1 second)
    [],
  );

  const setEditorContent = (c: RichContent | null) => {
    const _contentType =
      contentType === "json" || contentType === null ? "json" : "html";
    let _initialContent =
      initialContent && _contentType === "json"
        ? JSON.parse(initialContent)
        : contentType === "html" && initialContent
          ? initialContent
          : null;

    let _newContent =
      _contentType === "json" && c?.json
        ? JSON.parse(c.json)
        : c?.html
          ? c?.html
          : null;
    if (isEqual(_initialContent, _newContent)) {
      return;
    }
    setIsSaving(true);
    setContent(c);
    debouncedUpdate(c);
  };
  return {
    savingInProgress: isSaving,
    editorContent: content,
    setEditorContent,
    lastEditSec,
    savingIndicator: (
      <SavingIndicator
        saving={isSaving}
        lastUpdatedSec={lastEditSec}
        hasError={hasError}
      />
    ),
  };
};
