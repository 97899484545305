import { AuthData } from "../types/auth";
import { AuthToken, CurrentUser } from "protogen/auth_pb";
import { PlainMessage } from "@bufbuild/protobuf";

const AUTH_DATA_KEY = "auth";

const getLocalRefreshToken = () => {
  const authData = getAuthData();
  return authData?.refreshToken;
};

const getLocalCurrentUser = () => {
  const authData = getAuthData();
  return authData?.currentUser;
};

const getLocalAccessToken = () => {
  const authData = getAuthData();
  return authData?.accessToken;
};

const updateAuthData = (
  token: PlainMessage<AuthToken>,
  currentUser: CurrentUser,
) => {
  let authData = getAuthData();
  if (!authData) {
    throw new Error("No auth data found");
  }
  authData.accessToken = token;
  authData.currentUser = currentUser;
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
};

const getAuthData = (): AuthData | null => {
  if (localStorage.getItem(AUTH_DATA_KEY)) {
    return JSON.parse(localStorage.getItem(AUTH_DATA_KEY) || "");
  } else {
    return null;
  }
};

const setAuthData = (authData: AuthData) => {
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
};

const clearAuthData = () => {
  localStorage.removeItem(AUTH_DATA_KEY);
};

export default {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalCurrentUser,
  updateAuthData,
  setAuthData,
  clearAuthData,
};
