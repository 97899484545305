import * as React from "react";

import { createContext, useEffect, useState } from "react";
import { Family } from "protogen/advisors_service_pb";
import { useLocation } from "react-router-dom";
import { PhoneCall } from "protogen/conversation_pb";
import usePrevious from "../hooks/usePrevious";

type searchContentType = {
  family: Family | null;
  setFamily: (f: Family | null) => void;
  phoneCall: PhoneCall | null;
  setPhoneCall: (p: PhoneCall | null) => void;
  tasksOnly: boolean;
  setTasksOnly: (b: boolean) => void;
  forumOnly: boolean;
  setForumOnly: (b: boolean) => void;
  calendarOnly: boolean;
  setCalendarOnly: (b: boolean) => void;
};
export const SearchContext = createContext<searchContentType>({
  family: null,
  setFamily: () => {},
  phoneCall: null,
  setPhoneCall: () => {},
  tasksOnly: false,
  setTasksOnly: () => {},
  forumOnly: false,
  setForumOnly: () => {},
  calendarOnly: false,
  setCalendarOnly: () => {},
});

export default ({ children }: { children: React.ReactNode }) => {
  const [family, setFamily] = useState<Family | null>(null);
  const [phoneCall, setPhoneCall] = useState<PhoneCall | null>(null);
  const [tasksOnly, setTasksOnly] = useState(false);
  const [forumOnly, setForumOnly] = useState(false);
  const [calendarOnly, setCalendarOnly] = useState(false);
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (previousLocation && previousLocation.pathname !== location.pathname) {
      // Clear context on page change.
      setFamily(null);
      setPhoneCall(null);
      setTasksOnly(false);
      setForumOnly(false);
      setCalendarOnly(false);
    }
  }, [location, previousLocation]);

  return (
    <SearchContext.Provider
      value={{
        family: family,
        setFamily: setFamily,
        phoneCall: phoneCall,
        setPhoneCall: setPhoneCall,
        tasksOnly: tasksOnly,
        setTasksOnly: setTasksOnly,
        forumOnly: forumOnly,
        setForumOnly: setForumOnly,
        calendarOnly: calendarOnly,
        setCalendarOnly: setCalendarOnly,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
