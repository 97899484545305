import {
  USER_NUX_TYPE,
  ADVISOR_STEP_NAME_PATH_MAPPING,
  ADVISOR_STEP_NAME_INDEX_MAPPING,
  MEMBER_STEP_NAME_INDEX_MAPPING,
  MEMBER_STEP_NAME_PATH_MAPPING,
} from "./constants";

const getStepperIndex = (stepName: string, userType: USER_NUX_TYPE): number => {
  const mapping =
    userType === USER_NUX_TYPE.ADVISOR
      ? ADVISOR_STEP_NAME_INDEX_MAPPING
      : MEMBER_STEP_NAME_INDEX_MAPPING;
  return mapping[stepName];
};

const getStepPath = (stepName: string, userType: USER_NUX_TYPE): string => {
  const mapping =
    userType === USER_NUX_TYPE.ADVISOR
      ? ADVISOR_STEP_NAME_PATH_MAPPING
      : MEMBER_STEP_NAME_PATH_MAPPING;
  return mapping[stepName];
};

export { getStepperIndex, getStepPath };
