import { useRef, useState } from "react";
import api from "./api";
import axios from "axios";
import { Message, PlainMessage } from "@bufbuild/protobuf";
import { useError } from "components/context/HttpErrorProvider";
import { isMobileiOS } from "../common/utils";
import * as Sentry from "@sentry/react";

type UseApiReturnType<T1 extends Message<T1>, T2 extends Message<T2>> = {
  request: (payload?: T1 | PlainMessage<T1>) => Promise<T2 | null>;
  cancel: () => void;
  reset: () => void;
  data: T2 | null;
  loading: boolean;
  error: string | null;
  errorCode: number | null;
};

type UseApiCallOptions<T2> = {
  baseUrl?: string;
  protoUrlPath?: string;
  callback?: (p: T2) => void;
};

// Wow this is complicated! Essentially we want to use protobuf definitions to type our API calls.
// For convenience, inputs may be plain/conforming objects or protobuf messages (which we case). While
// all outputs are cast as protobuf messages.
export default <T1 extends Message<T1>, T2 extends Message<T2>>(
  protoMethod: string,
  responseConstructor: { new (data: any): T2 },
  options?: UseApiCallOptions<T2>,
): UseApiReturnType<T1, T2> => {
  const { addError } = useError();
  const [data, setData] = useState<T2 | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  const request = async (
    payload?: T1 | PlainMessage<T1>,
  ): Promise<T2 | null> => {
    setLoading(true);
    setError(null);
    setErrorCode(null);
    // Refresh case!
    // setData(null);

    // Three cases to handle here
    // 1. Payload is null/undefined/etc and we just want to send an empty object {}
    //      - Our API expect an empty object if no payload is provided.
    // 2. Payload is a protobuf message and we want to call toJson() on it
    // 3. Payload is already a plain object and we can just send it as is
    const payloadData = !payload
      ? {}
      : "toJson" in payload
        ? payload.toJson()
        : payload;
    try {
      const response = await api.request({
        data: {
          method: protoMethod,
          payload: payloadData,
        },
        signal: controllerRef.current.signal,
        method: "post",
        url: `${options?.protoUrlPath || "/proto"}?method=${protoMethod}`,
        baseURL: options?.baseUrl,
      });
      const responseMessage = new responseConstructor(response.data);
      setData(responseMessage);
      options?.callback && options?.callback(responseMessage);
      return responseMessage;
    } catch (error) {
      Sentry.captureException(error);
      // Typing hijinks
      setError(
        axios.isAxiosError(error)
          ? error?.response?.data || error.message
          : "Unexpected Error!",
      );
      setErrorCode(
        (axios.isAxiosError(error) && error.response?.status) || 500,
      );
      if (axios.isAxiosError(error)) {
        if (!error.response && error.message === "Network Error") {
          // Swallow the error on mobile iOS where it is often triggered by backgrounding.
          // Note(Kip): This is not the most elegant solution, but this is a super annoying experience
          // for users and we don't have a better solution at the moment.
          if (!isMobileiOS()) {
            addError("Network Error", true);
          }
        } else if (error.response?.status === 500) {
          addError("Server Error");
        } else {
          console.error(error);
        }
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    cancel,
    data,
    error,
    errorCode,
    loading,
    reset: () => {
      setData(null);
      setError(null);
      setErrorCode(null);
      setLoading(false);
    },
  };
};
