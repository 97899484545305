import { Box, Button, IconButton } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { addOneMonth, subtractOneMonth } from "./utils";

type Props = {
  updateTime: (dte: Date | null) => void;
  disabled?: boolean;
};

export default ({ updateTime, disabled = false }: Props) => {
  const [cursorDate, setCursorDate] = useState<Date | null>(null);
  const resetTime = () => {
    setCursorDate(null);
    updateTime(null);
  };
  const onBack = () => {
    let date = cursorDate || new Date();
    if (cursorDate) {
      // move back one
      date = subtractOneMonth(date);
    }
    setCursorDate(date);
    updateTime(date);
  };
  const onForward = () => {
    // move back one
    const date = addOneMonth(cursorDate || new Date());
    setCursorDate(date);
    updateTime(date);
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
    >
      <Button
        variant="text"
        onClick={resetTime}
        sx={{
          padding: "0px",
          margin: "0px",
        }}
      >
        Today
      </Button>
      <IconButton disabled={disabled} onClick={onBack}>
        <ArrowLeftIcon
          height="20px"
          width="20px"
          strokeWidth="2.5px"
          color="#198282"
        />
      </IconButton>
      <IconButton
        disabled={disabled}
        onClick={onForward}
        sx={{ marginLeft: "8px" }}
      >
        <ArrowRightIcon
          height="20px"
          width="20px"
          strokeWidth="2.5px"
          color="#198282"
        />
      </IconButton>
    </Box>
  );
};
