import { CSSProperties, useState } from "react";
import { Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Attachment as AttachmentProto } from "protogen/common_pb";
import MessageBubble from "./MessageBubble";
import AttachmentComp from "../common/AttachmentComp";
import CustomLinkify from "../common/CustomLinkify";

interface TextMessageProps {
  loading?: boolean;
  error?: string | undefined;
  content: string | null;
  attachments: AttachmentProto[];
  alignLeft: boolean;
  openAttachment: (a: AttachmentProto) => void;
  sx?: CSSProperties;
  handleRetry?: () => void;
}

export default ({
  loading,
  error,
  content,
  attachments,
  alignLeft,
  openAttachment,
  sx,
  handleRetry,
}: TextMessageProps) => {
  const [retryDisabled, setRetryDisabled] = useState(false);
  return (
    <Box>
      {content && (
        <MessageBubble
          loading={loading}
          alignLeft={alignLeft}
          sx={{
            ...sx,
            ...(error
              ? { border: "2px solid red", boxShadow: "0 0 3px red" }
              : {}),
            a: {
              textDecoration: "none",
            },
          }}
        >
          <CustomLinkify>{content}</CustomLinkify>
        </MessageBubble>
      )}
      {attachments.length > 0 && (
        <div
          style={{
            gap: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          {attachments.map((attachment, i) => (
            <AttachmentComp
              key={i}
              attachment={attachment}
              openAttachment={openAttachment}
              loading={loading}
              error={error}
            />
          ))}
        </div>
      )}
      {error && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "4px",
            flexDirection: "row",
            marginTop: "4px",
            justifyContent: alignLeft ? "flex-end" : "flex-start",
          }}
        >
          <WarningIcon sx={{ color: "red", fontSize: "11px" }} />
          <span style={{ color: "red", fontSize: "11px", marginLeft: "3px" }}>
            {error}
            {handleRetry && (
              <>
                {"  "}
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    if (retryDisabled) return;
                    setRetryDisabled(true);
                    handleRetry();
                  }}
                >
                  Try again?
                </span>{" "}
              </>
            )}
          </span>
        </div>
      )}
    </Box>
  );
};
