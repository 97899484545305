import TableCell from "@tiptap/extension-table-cell";
export const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      styles: {
        default: null,
        renderHTML: () => {
          return {
            style: `min-width: 100px; background-color: #FFFFFF`,
          };
        },
      },
    };
  },
});
