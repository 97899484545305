import { usePlacesWidget } from "react-google-autocomplete";
import { TextField } from "@mui/material";

import "./AddressAutocomplete.css";

export type Location = {
  latitude: number;
  longitude: number;
  address: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  formattedAddress: string;
};

const getAddressComponent = (
  components: { long_name: string; short_name: string; types: string[] }[],
  type: string,
) => {
  const component = components.find((c) => c.types.includes(type));
  return component ? component.long_name : "";
};

type Props = {
  label: string;
  error?: string;
  initialValue?: string;
  setValue: (location: Location) => void;
};

export default ({ label, error, initialValue, setValue }: Props) => {
  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      if (
        !place.geometry ||
        !place.address_components ||
        !place.formatted_address ||
        !place.geometry.location
      )
        return;
      // Extract lat and lng
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      // Extract individual address components
      const address =
        getAddressComponent(place.address_components, "street_number") +
        " " +
        getAddressComponent(place.address_components, "route");
      const unit = getAddressComponent(place.address_components, "subpremise");
      const city =
        getAddressComponent(place.address_components, "locality") ||
        getAddressComponent(place.address_components, "sublocality");
      const state = getAddressComponent(
        place.address_components,
        "administrative_area_level_1",
      );
      const zip = getAddressComponent(place.address_components, "postal_code");

      // Form the final location object
      const location: Location = {
        latitude,
        longitude,
        address,
        unit,
        city,
        state,
        zip,
        formattedAddress: place.formatted_address,
      };
      setValue(location);
    },
    language: "en",
    inputAutocompleteValue: "address",
    options: {
      types: ["address"], // Restrict to address searches only
      componentRestrictions: { country: ["us", "ca"] }, // Restrict search to a specific country (US in this case)
    },
  });
  return (
    <TextField
      error={!!error}
      helperText={error}
      label={label}
      autoComplete="off"
      fullWidth
      color="secondary"
      variant="outlined"
      inputRef={materialRef}
      margin="normal"
      size="small"
      sx={{ marginTop: 0, marginBottom: 0 }}
      defaultValue={
        initialValue
      } /* Note: This will not be updated on re-render.*/
      inputProps={{
        autoComplete: "nope", // Additional unconventional value to ensure browsers don't enable autocomplete
      }}
    />
  );
};
