import useProtoMethod from "./useProtoMethod";
import { SearchRequest, SearchResponse } from "protogen/search_service_pb";
import { getInferenceDomain } from "../constants";

const useSearch = (callback?: (r: SearchResponse) => void) => {
  return useProtoMethod<SearchRequest, SearchResponse>(
    "Search",
    SearchResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

export { useSearch };
