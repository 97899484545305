import { styled } from "@mui/material/styles";

export default styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // !important because the mixin toolbar is more specificity.  We want to override
  minHeight: "50px !important",
  Height: "50px !important",
  justifyContent: "left",
  paddingLeft: "18px",
  borderBottom: "1px solid #E8E8E8",
}));
