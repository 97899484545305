import useIsVisible from "../hooks/useIsVisible";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import WithDividers from "../helpers/WithDividers";
import Loading from "../common/Loading";
import DateDisplay from "../common/DateDisplay";
import useIsMobile from "../hooks/useIsMobile";
import {
  useFetchMessageInbox,
  useGetOrCreateMessageConversation,
} from "services/messaging";
import StartMessageThread from "../creation/StartMessageThread";
import { InboxEntry } from "protogen/messaging_service_pb";
import { InboxHandle } from "./utils";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";
import { UserAvatar } from "../common/CurrentUserAvatar";
import { UserIcon, UsersIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";

const EntryTS = ({ t }: { t: bigint }) => (
  <DateDisplay
    date={new Date(Number(t) * 1000)}
    sx={{
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "right",
    }}
  />
);

interface EntryItemProps {
  entry: InboxEntry;
  onClick: (entryRef: string) => void;
  selected?: boolean;
}

const messageHeadline = (entry: InboxEntry) => {
  switch (entry.otherParticipants.length) {
    case 1:
      return entry.otherParticipants[0].displayName;
    case 2:
      return `${entry.otherParticipants[0].firstName} & ${entry.otherParticipants[1].firstName}`;
    default:
      const start = entry.otherParticipants.slice(
        0,
        entry.otherParticipants.length - 1,
      );
      const last = entry.otherParticipants[entry.otherParticipants.length - 1];
      return `${start.map((u) => u.firstName).join(", ")} & ${last.firstName}`;
  }
};

const EntryItem = ({ entry, onClick, selected = false }: EntryItemProps) => {
  const isMobile = useIsMobile();
  const title = messageHeadline(entry);
  const [markedRead, setMarkedRead] = useState(false);
  const unread = entry.unreadCount > 0 && !markedRead;
  return (
    <ListItem
      onClick={() => {
        setMarkedRead(true);
        onClick && onClick(entry.ref);
      }}
      key={entry.ref}
      sx={{
        cursor: "pointer",
        backgroundColor: selected ? "#FAF9FA" : "white",
        gap: "12px",
        padding: !isMobile ? "16px 28px" : "16px 18px",
      }}
    >
      <ListItemAvatar sx={{ minWidth: "unset" }}>
        <Avatar
          sx={{
            backgroundColor: "#8E9598",
          }}
        >
          {entry.otherParticipants.length === 1 ? (
            entry.otherParticipants[0].avatarUrl ? (
              <UserAvatar
                user={entry.otherParticipants[0]}
                size={28}
                autoscale={true}
              />
            ) : (
              <UserIcon height={28} width={28} />
            )
          ) : (
            <UsersIcon height={28} width={28} />
          )}
        </Avatar>
      </ListItemAvatar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          width: "100%",
        }}
      >
        {/* Top row: Name, date */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="body"
            color="text.primary"
            fontWeight={unread ? "600" : "500"}
            sx={{ lineHeight: "130%" }}
          >
            {unread && (
              <EllipseIcon
                height={8}
                width={8}
                style={{ marginRight: "5px" }}
              />
            )}
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              flexDirection: "row",
              alignItems: "center",
              minWidth: "100px",
              justifyContent: "end",
            }}
          >
            <EntryTS t={entry.timestampSec} />
          </Box>
        </Box>

        {/* Message content */}
        <Typography
          variant="bodySmall"
          color={unread ? "text.primary" : "text.secondary"}
          fontWeight={unread ? "600" : "500"}
          sx={{
            wordWrap: "break-word",
            overflow: "hidden",
            maxHeight: "40px",
          }}
        >
          {entry.content || <br />}
        </Typography>
      </Box>
    </ListItem>
  );
};

interface MessagesInboxProps {
  selectedRef?: string;
  onEntrySelect: (entryRef: string) => void;
}

export default forwardRef<InboxHandle, MessagesInboxProps>(
  ({ selectedRef, onEntrySelect }: MessagesInboxProps, ref) => {
    const navigate = useNavigate();
    const { isVisible } = useIsVisible({});
    const [searchParams] = useSearchParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [entries, setEntries] = useState<InboxEntry[]>([]);
    const isMobile = useIsMobile();
    const { request } = useFetchMessageInbox((r) => {
      setLoaded(true);
      setEntries(r.entries);
    });
    const { request: requestConversation } =
      useGetOrCreateMessageConversation();
    useImperativeHandle(ref, () => ({
      triggerCompose: () => {
        if (isMobile) {
          setModalOpen(true);
        }
      },
      triggerRefresh: () => request(),
    }));

    useEffect(() => {
      request();
      const intervalId = setInterval(async () => {
        if (isVisible) {
          request();
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }, []);

    const startConversation = async (recipientRefs: string[]) => {
      const resp = await requestConversation({
        userRefs: recipientRefs,
      });
      if (resp?.conversationRef) {
        navigate(`/inbox/messages/${encodeURIComponent(resp.conversationRef)}`);
        return true;
      }
      return false;
    };

    useEffect(() => {
      const recipientRefs = (searchParams.get("recipientRefs") || "")
        .split(",")
        .filter(Boolean);
      if (!recipientRefs || recipientRefs.length === 0) return;

      // Not required but nice to remove the query parameters to avoid popping up the fact if
      // user manually refreshes the page.
      searchParams.delete("recipientRefs");
      navigate("." + searchParams.toString(), { replace: true });
      startConversation(recipientRefs);
    }, [searchParams]);

    if (!loaded) return <Loading />;
    return (
      <Box
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <List sx={{ paddingTop: 0 }}>
          <WithDividers>
            {entries.map((entry) => (
              <EntryItem
                key={entry.ref}
                entry={entry}
                onClick={onEntrySelect}
                selected={selectedRef === entry.ref}
              />
            ))}
          </WithDividers>
        </List>
        <StartMessageThread
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </Box>
    );
  },
);
