import { ReactNode } from "react";
import { Box } from "@mui/material";
import FlexPage from "../layout/FlexPage";
import useIsMobile from "../hooks/useIsMobile";

export default ({ children }: { children: ReactNode }) => {
  const isMobile = useIsMobile();
  return (
    <FlexPage
      fullHeight
      headerPadding={false}
      sx={{
        overflowY: "scroll",
        backgroundColor: isMobile ? "#FFF" : "#FDFAF7",
        maxWidth: "100%",
        boxShadow: isMobile ? "None" : "0px 1px 7px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Box sx={{ width: "100%", display: "flex" }}>
        {isMobile ? (
          <Box sx={{ width: "100%" }}>{children}</Box>
        ) : (
          <Box
            sx={{
              maxWidth: "900px",
              width: "100%",
              padding: "48px 64px",
              border: "1px solid #EAEBEC",
              borderRadius: "24px",
              backgroundColor: "#FFFFFF",
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </FlexPage>
  );
};
