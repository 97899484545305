import { createContext, ReactNode, useContext, useEffect } from "react";

type Handler = (message: MessageEvent) => void;
const ServiceWorkerContext = createContext({
  registerHandler: (_h: Handler) => {},
  unregisterHandler: (_h: Handler) => {},
});

export const usePushContext = () => {
  return useContext(ServiceWorkerContext);
};

export default ({ children }: { children: ReactNode }) => {
  const handlers: Set<Handler> = new Set();

  useEffect(() => {
    // Check if service workers are supported
    if ("serviceWorker" in navigator) {
      const handleMessage = (event: MessageEvent) => {
        handlers.forEach((handler) => handler(event));
      };

      navigator.serviceWorker.addEventListener("message", handleMessage);

      return () => {
        navigator.serviceWorker.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  const registerHandler = (handler: Handler) => {
    handlers.add(handler);
  };

  const unregisterHandler = (handler: Handler) => {
    handlers.delete(handler);
  };

  return (
    <ServiceWorkerContext.Provider
      value={{ registerHandler, unregisterHandler }}
    >
      {children}
    </ServiceWorkerContext.Provider>
  );
};
