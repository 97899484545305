import { useContext, useEffect, useState } from "react";
import { useCreateFamily } from "services/advisor";
import { useNavigate } from "react-router-dom";
import ReactiveDialog from "../common/ReactiveDialog";
import { Family, FormErrors, Member } from "./types";
import useIsMobile from "../hooks/useIsMobile";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  cleanPhone,
  defaultFamily,
  validateFamily,
  validateFamilyMembers,
} from "./utils";
import Form from "./Form";
import { CurrentUser } from "protogen/auth_pb";
import { CurrentUserContext } from "../context/RequireAuth";
import { PlatformFeeSchedule } from "protogen/advisors_service_pb";

interface Props {
  open: boolean;
  onClose: () => void;
  selectAdvisor?: boolean;
}

export default ({ open, onClose, selectAdvisor }: Props) => {
  const currentUser = useContext<CurrentUser>(CurrentUserContext);
  const isMobile = useIsMobile();
  const [familyErrors, setFamilyErrors] = useState<FormErrors | null>(null);
  const [memberErrors, setMemberErrors] = useState<FormErrors[] | null>(null);
  const [familyData, setFamilyData] = useState<Family>(
    defaultFamily(currentUser.ref),
  );
  const [members, setMembers] = useState<Member[]>([]);
  const navigate = useNavigate();
  const { request, loading, error } = useCreateFamily((r) => {
    navigate(`/families/${encodeURIComponent(r?.family?.ref || "")}`);
  });

  useEffect(() => {
    setFamilyData(defaultFamily(currentUser.ref));
    setMembers([]);
  }, [open]);

  const _validate = () => {
    const famErrors = validateFamily(familyData);
    setFamilyErrors(famErrors);
    let hasErrors = Object.keys(famErrors).length !== 0;
    const [hasMemberErrors, memberErrors] = validateFamilyMembers(members);
    setMemberErrors(memberErrors);
    return !(hasErrors || hasMemberErrors);
  };
  const handleCreateFamily = () => {
    if (_validate()) {
      request({
        familyName: familyData.name,
        advisorRef: familyData.advisorRef,
        status: familyData.status,
        startDate: familyData.startDate || "",
        product: familyData.product,
        introCoupon: familyData.introCoupon,
        platformFeeSchedule:
          familyData.platformFeeSchedule ||
          PlatformFeeSchedule.SCHEDULE_NOT_SET,
        members: members.map((member) => ({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          phone: cleanPhone(member.phone),
          altEmails: member.altEmails,
          timezone: member.timezone,
        })),
      });
    }
  };

  return (
    <ReactiveDialog
      primaryAction={handleCreateFamily}
      primaryActionName={
        isMobile ? (
          <PlusIcon height="24px" width="24px" stroke="#FFF"></PlusIcon>
        ) : (
          "Create Family"
        )
      }
      fullWidthSize="sm"
      primaryActionEnabled={!loading}
      open={open}
      onClose={onClose}
      title={`New Family`}
    >
      <Form
        familyData={familyData}
        members={members}
        setFamilyData={setFamilyData}
        setMembers={setMembers}
        familyErrors={familyErrors}
        memberErrors={memberErrors}
        handleSubmit={handleCreateFamily}
        selectAdvisor={selectAdvisor}
        error={error}
      />
    </ReactiveDialog>
  );
};
