import { GridPagination, useGridApiContext } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

function getFormattedDate(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yy = String(today.getFullYear()).slice(-2);
  return `${mm}/${dd}/${yy}`;
}

interface Props {}

export default ({ ...otherProps }: Props) => {
  const apiRef = useGridApiContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Button
          size="small"
          variant="text"
          sx={{ marginLeft: 16 }}
          onClick={() =>
            apiRef.current.exportDataAsCsv({
              fileName: `Tasks ${getFormattedDate()}`,
              allColumns: true,
            })
          }
        >
          Download CSV
        </Button>
      </Box>
      <GridPagination {...otherProps} />
    </Box>
  );
};
