import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { FactSuggestion } from "protogen/suggestions_pb";
import { EphemeralFact } from "../facts/types";
import { getTypeViewByCategory } from "../facts/FactView";
import React from "react";
import SuggestionItemContainer from "./SuggestionItemContainer";

export default ({
  entityRef,
  entityType,
  suggestion,
  suggestFact,
  index,
  isOpen,
  disabled = false,
}: {
  entityRef: string;
  entityType: string;
  suggestion: FactSuggestion;
  suggestFact: (f: EphemeralFact) => void;
  index: number;
  isOpen: boolean;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const factCreated = suggestion.factRef;
  const factType = getTypeViewByCategory(suggestion.valueType);
  return (
    <SuggestionItemContainer
      open={isOpen}
      childHeight={300}
      onClick={() => {
        if (factCreated) {
          // Note...will only work on the family page for now.
          navigate(`?factRef=${encodeURIComponent(suggestion.factRef)}`);
        } else {
          suggestFact({
            name: suggestion.name,
            value: suggestion.value,
            valueType: factType.value,
            attachments: [],
            uploads: [],
            sourceEntityType: entityType,
            sourceEntityRef: entityRef,
            sourceEntityIndex: index,
          });
        }
      }}
    >
      <>
        <Typography sx={{ marginBottom: "10px" }} variant="bodySmallHeavy">
          {suggestion.name}
        </Typography>
        <Typography sx={{ marginBottom: "4px" }} variant="bodySmall">
          {factType.renderText
            ? factType.renderText(suggestion.value)
            : suggestion.value}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="text"
            sx={{
              height: "unset",
              fontSize: "14px",
            }}
            disabled={disabled}
          >
            {factCreated
              ? `View ${factType.label.toLowerCase()}`
              : `Save ${factType.label.toLowerCase()}`}
          </Button>
        </Box>
      </>
    </SuggestionItemContainer>
  );
};
