import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FayeIcon } from "../icons/FayeBrand.svg";
import AuthService from "services/auth";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";
import { FayeAppBar } from "components/layout/NavigationLayout";

const cleanPhone = (phone: string): string => {
  const clean = phone.replace(/[^\d+]/g, "");
  if (clean.length === 11 && clean[0] === "1") {
    return `+${clean}`;
  } else if (clean.length === 10) {
    return `+1${clean}`;
  } else {
    return clean;
  }
};

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidPhone = (phone: string) => {
  return /^\+1\d{10}$/.test(cleanPhone(phone));
};

export default () => {
  const navigate = useNavigate();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const intendedPath = queryParams.get("destination");
  // Default to the value in the local state, then the intended path, then null.
  const redirectedFrom = location.state?.from?.pathname || intendedPath || null;
  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    if (email) {
      setValue(email);
    }
  }, []);

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      navigate(intendedPath || "/");
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sent) return;
    if (!(isValidEmail(value) || isValidPhone(value))) {
      setError("");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    AuthService.initiateMemberLogin(
      isValidEmail(value) ? value : "",
      isValidPhone(value) ? cleanPhone(value) : "",
      redirectedFrom,
    ).then(
      () => {
        setSent(true);
        setLoading(false);
        setError(null);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(resMessage);
      },
    );
  };

  useEffect(() => {
    if (location.state?.calendarEvent?.action === "confirm") {
      setSnackPack((prev) => [
        ...prev,
        {
          message: `Your event RSVP has been recorded`,
          key: new Date().getTime(),
        },
      ]);
    }
  }, []);

  return (
    <>
      <FayeAppBar />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            padding: "0px 28px",
            alignSelf: "stretch",
          }}
        >
          <div>
            <FayeIcon
              style={{
                width: "112.762px",
                height: "64px",
                flexShrink: "0",
                marginBottom: "29px",
              }}
            />
          </div>
          <Typography
            variant="h2Serif"
            sx={{
              marginBottom: "8px",
            }}
          >
            Sign in to Faye
          </Typography>
          <Typography
            variant="body"
            sx={{
              marginBottom: "32px",
            }}
          >
            Your Family Advisor awaits.
          </Typography>
          {sent && (
            <Alert
              severity="success"
              sx={{
                marginBottom: "12px",
                borderRadius: "12px",
                border: "2px solid #BEDDBB",
                background: "#FBFDFB",
                fontWeight: "500",
                color: "#3D7C37",
                alignSelf: "stretch",
                "& .MuiSvgIcon-root": {
                  color: "#468E3E",
                },
              }}
            >
              A link to sign in has been sent if there is a matching account.
              See you soon!
            </Alert>
          )}
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {error !== null && (
              <Alert
                icon={false}
                severity="error"
                sx={{
                  alignSelf: "stretch",
                  marginBottom: "12px",
                  borderRadius: "12px",
                  border: "2px solid #FAD3D0",
                  background: "#FFFBFA",
                }}
              >
                {error || "Please enter a valid email or phone number"}
              </Alert>
            )}
            <FormControl
              fullWidth
              style={{ marginTop: 16 }}
              disabled={loading || sent}
            >
              <TextField
                sx={{ flexGrow: 1 }}
                disabled={loading || sent}
                autoFocus
                label="Email or phone number"
                type="text"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={value}
                onChange={(e) => setValue(e.target.value?.trim() || "")}
              />
            </FormControl>
            <Button
              disabled={loading || sent}
              type="submit"
              fullWidth
              style={{ marginTop: "12px" }}
            >
              Sign in
            </Button>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Button
                variant="text"
                sx={{ mt: 1, mr: 1 }}
                onClick={() => {
                  window.location.href = `https://advisor.findfaye.com/login${
                    isValidEmail(value)
                      ? `?username=${encodeURIComponent(value)}`
                      : ""
                  }`;
                }}
              >
                Are you a Family Advisor? Login here
              </Button>
            </Box>
          </form>
        </Box>
        <SnackPack
          snackPack={snackPack}
          alertSeverity="success"
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        />
      </Box>
    </>
  );
};
