import NavigationLayout from "./NavigationLayout";
import AdvisorDrawerContent, {
  NotificationBadgedButton,
} from "./AdvisorDrawerContent";
import { AccountType } from "protogen/auth_service_pb";
import { AddCallContext } from "../context/AddCallContext";
import SearchContextProvider from "../context/SearchContextProvider";
import StatusContextProvider from "../context/StatusContextProvider";

export default () => {
  return (
    <NavigationLayout
      drawerContent={AdvisorDrawerContent}
      MobileBadgerComponent={NotificationBadgedButton}
      loginRedirectPath="/login"
      tosAccountType={AccountType.ADVISOR}
      authedContexts={[
        StatusContextProvider,
        AddCallContext,
        SearchContextProvider,
      ]}
    />
  );
};
