import { useCallback, useEffect, useRef, useState } from "react";
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  PointerActivationConstraint,
  Active,
  Over,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import { DroppableContainer, DroppableItem } from "./components/Container";
import { SortableItem } from "./components/Item";

type Items = Record<UniqueIdentifier, Container>;

interface Container {
  id: UniqueIdentifier;
  headerName: string;
  headerElement: JSX.Element;
  items: DroppableItem[];
}

interface Props {
  items: Items;
  strategy?: SortingStrategy;
  vertical?: boolean;
  activationConstraint?: PointerActivationConstraint;
  containerStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  onDragEndCallback?: (args: { active: Active; over: Over | null }) => void;
}

export const MultiContainer = ({
  items: initialItems,
  strategy = verticalListSortingStrategy,
  vertical = false,
  activationConstraint,
  containerStyle,
  headerStyle,
  onDragEndCallback,
}: Props) => {
  const [items, setItems] = useState<Items>({});
  const [containers, setContainers] = useState<Container[]>(
    Object.values(initialItems),
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
    setContainers(Object.values(initialItems));
    setItems(initialItems);
  }, [initialItems]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId].items;

          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.some((item) => item.id === container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {}),
  );

  const findContainer = (
    id: UniqueIdentifier,
  ): UniqueIdentifier | undefined => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].items.some((item) => item.id === id),
    );
  };

  const getIndex = (id: UniqueIdentifier): number => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].items.findIndex((item) => item.id === id);

    return index;
  };

  const onDragStart = ({ active }: { active: Active }) => {
    setActiveId(active.id);
  };

  const onDragCancel = () => {
    setActiveId(null);
  };

  const onDragOver = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    const activeId = active.id;
    const overId = over?.id;

    if (overId == null || activeId in items) {
      return;
    }

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(activeId);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setItems((items) => {
        const activeItems = items[activeContainer].items;
        const overItems = items[overContainer].items;
        const overIndex = overItems.findIndex((item) => item.id === overId);
        const activeIndex = activeItems.findIndex(
          (item) => item.id === activeId,
        );

        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        recentlyMovedToNewContainer.current = true;

        return {
          ...items,
          [activeContainer]: {
            ...items[activeContainer],
            items: items[activeContainer].items.filter(
              (item) => item.id !== activeId,
            ),
          },
          [overContainer]: {
            ...items[overContainer],
            items: [
              ...items[overContainer].items.slice(0, newIndex),
              items[activeContainer].items[activeIndex],
              ...items[overContainer].items.slice(
                newIndex,
                items[overContainer].items.length,
              ),
            ],
          },
        };
      });
    }
  };

  const onDragEnd = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    const activeId = active.id;
    const overId = over?.id;

    const activeContainer1 = containers.find(
      (container) => container.id === activeId,
    );
    const overContainer1 = containers.find(
      (container) => container.id === overId,
    );

    if (activeContainer1 && overContainer1) {
      setContainers((containers) => {
        const activeIndex = containers.indexOf(activeContainer1);
        const overIndex = containers.indexOf(overContainer1);

        return arrayMove(containers, activeIndex, overIndex);
      });
    }

    const activeContainer = findContainer(activeId);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    if (overId == null) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId);

    if (overContainer) {
      const activeIndex = items[overContainer].items.findIndex(
        (item) => item.id === activeId,
      );
      const overIndex = items[overContainer].items.findIndex(
        (item) => item.id === overId,
      );

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: {
            ...items[overContainer],
            items: arrayMove(
              items[overContainer].items,
              activeIndex,
              overIndex,
            ),
          },
        }));
      }
    }

    setActiveId(null);
    onDragEndCallback && onDragEndCallback({ active, over });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: vertical ? "column" : "row",
          gap: "48px",
        }}
      >
        <SortableContext
          items={[...containers]}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {containers.map((c) => (
            <DroppableContainer
              headerStyle={headerStyle}
              headerElement={(handleProps) => (
                <div {...handleProps}>{c.headerElement}</div>
              )}
              key={c.id}
              id={c.id}
              label={`${c.id}`}
              items={items[c.id].items}
            >
              <div style={{ ...containerStyle }}>
                <SortableContext
                  items={items[c.id].items}
                  strategy={strategy}
                  id={c.id.toString()}
                >
                  {items[c.id].items.map((value, index) => {
                    return (
                      <SortableItem
                        key={value.id}
                        id={value.id}
                        value={value.element}
                        index={index}
                        containerId={c.id}
                        getIndex={getIndex}
                        disabled={!!value.isDisabled}
                      />
                    );
                  })}
                </SortableContext>
              </div>
            </DroppableContainer>
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};
