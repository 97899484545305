import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Link } from "@mui/material";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { GetMemberDetailsResponse } from "protogen/member_service_pb";
import FlexPage from "components/layout/FlexPage";
import AuthService from "services/auth";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useGetMemberDetails, useGetBillingPortalUrl } from "services/member";
import Loading from "components/common/Loading";

const formatDate = (dateString: string) => {
  if (!dateString) return;
  const date = new Date(dateString);
  return format(date, "MMMM do", { locale: enUS });
};

const formatBillingAmount = (amountCents: number | undefined): string => {
  if (amountCents === undefined) return "??/mo";
  return `$${(amountCents / 100).toString()}/mo`;
};

const PlanDetails = ({
  memberDetails,
  loading,
  onBillingRedirect,
}: {
  memberDetails: GetMemberDetailsResponse | null;
  loading: boolean;
  onBillingRedirect: () => void;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Box>
            <Typography variant="h3">Plan details</Typography>
          </Box>
          <Box>
            <Typography variant="bodyHeavy">
              {memberDetails?.family?.billingInfo?.product?.name} -{" "}
              {formatBillingAmount(
                memberDetails?.family?.billingInfo?.product?.amountCents,
              )}
            </Typography>
            <Typography variant="body">
              {memberDetails?.family?.billingInfo?.product?.description || ""}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body">
              Renews{" "}
              {formatDate(memberDetails?.planUtilization?.periodEndDate || "")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="bodySmall">
              To update your billing information, please{" "}
              <Link
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  onBillingRedirect();
                }}
              >
                click here
              </Link>
              .
            </Typography>
            <Typography variant="bodySmall">
              To make changes to your subscription, please{" "}
              <Link href="mailto:hi@findfaye.com">contact support</Link>.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const { request, data, loading, error } = useGetMemberDetails();
  const { request: billingUrlRequest } = useGetBillingPortalUrl();

  const handleLogout = async () => {
    await AuthService.logout();
    navigate("/");
  };

  const handleBillingRedirect = async () => {
    const result = await billingUrlRequest();
    const url = result?.url;
    if (!url) return;
    window.open(url, "_blank");
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Breadcrumbs
          breadcrumbs={[{ name: "Home", link: "/" }, { name: "Settings" }]}
        />
        <Box sx={{ paddingBottom: "24px" }}>
          <Typography variant="display">Settings</Typography>
        </Box>
        <PlanDetails
          memberDetails={data}
          loading={loading || !!error}
          onBillingRedirect={handleBillingRedirect}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleLogout}
          sx={{ alignSelf: "flex-start" }}
        >
          Log out
        </Button>
        <Link
          href="https://www.findfaye.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ alignSelf: "flex-start" }}
        >
          Privacy Policy
        </Link>
        <Link
          href="https://www.findfaye.com/client-terms"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ alignSelf: "flex-start" }}
        >
          Client Terms of Service
        </Link>
      </Box>
    </FlexPage>
  );
};
