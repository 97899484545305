import useIsVisible from "../hooks/useIsVisible";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { Box, Chip, List, ListItem, Typography } from "@mui/material";
import WithDividers from "../helpers/WithDividers";
import Loading from "../common/Loading";
import { useFetchPhoneInbox } from "services/advisor";
import DateDisplay from "../common/DateDisplay";
import {
  FetchPhoneInboxResponse_InboxEntry,
  FetchPhoneInboxResponse_InboxEntryType,
  FetchPhoneInboxResponse_InboxTreatment,
} from "protogen/advisors_service_pb";
import { CurrentUserContext } from "../context/RequireAuth";
import { CurrentUser } from "protogen/auth_pb";
import StartExternalConversation from "../creation/StartExternalConversation";
import {
  Phone as PhoneIcon,
  MessageSquare as MessageSquareIcon,
} from "lucide-react";
import LinkRouter from "../navigation/LinkRouter";
import useIsMobile from "../hooks/useIsMobile";
import { InboxHandle } from "./utils";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";

const toMe = (
  entry: FetchPhoneInboxResponse_InboxEntry,
  currentUser: CurrentUser,
) => entry.recipient?.ref === currentUser.ref;
const fromMe = (
  entry: FetchPhoneInboxResponse_InboxEntry,
  currentUser: CurrentUser,
) => entry.sender?.ref === currentUser.ref;
const withMe = (
  entry: FetchPhoneInboxResponse_InboxEntry,
  currentUser: CurrentUser,
) => toMe(entry, currentUser) || fromMe(entry, currentUser);

const EntryTS = ({ t }: { t: bigint }) => (
  <DateDisplay
    date={new Date(Number(t) * 1000)}
    sx={{
      color: "rgba(0, 0, 0, 0.5)",
    }}
  />
);

interface EntryItemProps {
  entry: FetchPhoneInboxResponse_InboxEntry;
  currentUser: CurrentUser;
  onClick: (entryRef: string) => void;
  selected?: boolean;
}

const callTitle = (
  entry: FetchPhoneInboxResponse_InboxEntry,
  currentUser: CurrentUser,
) => {
  if (toMe(entry, currentUser)) {
    return `Call from ${entry.sender?.displayName}`;
  } else if (fromMe(entry, currentUser)) {
    return `Call to ${entry.recipient?.displayName}`;
  } else {
    return `Call between ${entry.sender?.displayName} and ${entry.recipient?.displayName}`;
  }
};

const messageTitle = (
  entry: FetchPhoneInboxResponse_InboxEntry,
  currentUser: CurrentUser,
) => {
  if (withMe(entry, currentUser)) {
    const them =
      entry.sender?.ref === currentUser.ref ? entry.recipient : entry.sender;
    if (entry.isGroupConversation) {
      return `${them?.firstName} and others`;
    } else {
      return `${them?.displayName}`;
    }
  } else {
    return `${entry.sender?.displayName}, ${entry.recipient?.displayName}`;
  }
};

const EntryItem = ({
  currentUser,
  entry,
  onClick,
  selected = false,
}: EntryItemProps) => {
  const isMobile = useIsMobile();
  const isCall = entry.type === FetchPhoneInboxResponse_InboxEntryType.CALL;
  const title = isCall
    ? callTitle(entry, currentUser)
    : messageTitle(entry, currentUser);
  const url =
    entry.family && !entry.isGroupConversation
      ? `/families/${encodeURIComponent(entry.family.ref)}`
      : undefined;

  const [markedRead] = useState(false);
  const unread =
    entry.treatment === FetchPhoneInboxResponse_InboxTreatment.UNREAD &&
    !markedRead;
  const missedCall =
    entry.treatment === FetchPhoneInboxResponse_InboxTreatment.MISSED;
  return (
    <LinkRouter
      to={url}
      sx={{
        padding: "0",
      }}
      onClick={() => {
        if (url) return;
        onClick && onClick(entry.ref);
      }}
    >
      <ListItem
        key={entry.ref}
        sx={{
          cursor: "pointer",
          display: "flex",
          gap: "16px",
          width: "100%",
          padding: !isMobile ? "12px 28px" : "12px 18px",
          justifyContent: "space-between",
          alignItems: "start",
          backgroundColor: selected ? "#FAF9FA" : "white",
          ...(missedCall
            ? {
                border: "1px solid #EAECF0",
                backgroundColor: "#F2F4F7",
              }
            : {}),
        }}
      >
        <Box sx={{ color: "#198282", marginTop: "2px" }}>
          {isCall ? (
            <PhoneIcon width="20px" />
          ) : (
            <MessageSquareIcon width="20px" />
          )}
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1} gap="4px">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body"
              color="text.primary"
              sx={{ fontWeight: "600", lineHight: "130%" }}
            >
              {title}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <EntryTS t={entry.timestampSec} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "start",
                  textAlign: "end",
                  gap: "4px",
                  ...(isMobile
                    ? {
                        flexDirection: "column-reverse",
                      }
                    : {}),
                }}
              >
                {unread && <EllipseIcon height={8} width={8} />}
              </Box>
            </Box>
          </Box>
          <Typography
            variant="bodySmall"
            color={unread ? "text.primary" : "text.secondary"}
            fontWeight={unread ? "600" : "500"}
            sx={{
              margin: "0px 0px",
              wordWrap: "break-word",
              wordBreak: "break-word",
              overflow: "hidden",
              maxHeight: "40px",
            }}
          >
            {entry.title}
          </Typography>

          {/* Family chip */}
          {entry.family && (
            <Box>
              <Chip
                label={entry.family?.name}
                color="default"
                size="small"
                sx={{ background: "#F2F2F2", marginTop: "4px" }}
              />
            </Box>
          )}
        </Box>
      </ListItem>
    </LinkRouter>
  );
};

interface PhoneInboxProps {
  selectedRef?: string;
  onEntrySelect(entryRef: string): void;
}

export default forwardRef<InboxHandle, PhoneInboxProps>(
  ({ selectedRef, onEntrySelect }: PhoneInboxProps, ref) => {
    const currentUser = useContext(CurrentUserContext);
    const isMobile = useIsMobile();
    const { isVisible } = useIsVisible({});
    const [modalOpen, setModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [entries, setEntries] = useState<
      FetchPhoneInboxResponse_InboxEntry[]
    >([]);
    const { request } = useFetchPhoneInbox((r) => {
      setLoaded(true);
      setEntries(r.entries);
    });
    useImperativeHandle(ref, () => ({
      triggerCompose: () => {
        if (isMobile) {
          setModalOpen(true);
        }
      },
      triggerRefresh: () => request(),
    }));

    useEffect(() => {
      request();
      const intervalId = setInterval(async () => {
        if (isVisible) {
          request();
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }, []);

    if (!loaded) return <Loading />;
    return (
      <Box
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <List sx={{ paddingTop: 0 }}>
          <WithDividers>
            {entries.map((entry) => (
              <EntryItem
                key={entry.ref}
                currentUser={currentUser}
                entry={entry}
                onClick={() => onEntrySelect(entry.ref)}
                selected={selectedRef === entry.ref}
              />
            ))}
          </WithDividers>
        </List>
        <StartExternalConversation
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </Box>
    );
  },
);
