import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, Divider, InputBase } from "@mui/material";
import { debounce } from "lodash";

import { FeedEntry, Medium } from "protogen/advisors_service_pb";
import {
  useDeleteEmailDraft,
  useSendEmailDraft,
  useUpdateEmailDraft,
} from "services/email";
import { EmailMessage } from "protogen/conversation_pb";
import DocumentEditor, { Handle } from "../editor/DocumentEditor";
import useIsMobile from "../hooks/useIsMobile";
import { CurrentUserContext } from "../context/RequireAuth";
import {
  initialDraftFromDraft,
  initialLocalDraft,
  isDraftSavable,
  isDraftSendable,
} from "./utils";
import AddressFields from "./AddressFields";
import { LocalDraft } from "./types";
import { Trash2 } from "lucide-react";
import useGetEmailAutocompleteEntries from "./useGetEmailAutocompleteEntries";
import { stripImgSrc } from "../../common/utils";

export interface EmailComposeProps {
  onSent: (e: FeedEntry) => void;
  onClose: () => void;
  onRemove: (ref: string, m: Medium) => void;
  inReplyTo?: EmailMessage | null;
  initialDraft?: EmailMessage | null;
  isForward?: boolean;
  initialTo?: string[];
  initialCC?: string[];
  setSubject?: (t: string) => void;
  setSendDisabled?: (d: boolean) => void;
  setHovering?: (h: boolean) => void;
  minHeight?: string;
  maxHeight?: string;
  inlineView?: boolean;
}

const randstr = (prefix: string) => {
  return Math.random()
    .toString(36)
    .replace("0.", prefix || "");
};

export type ComposeHandle = {
  reset: () => void;
  handleSend: () => void;
};

export default forwardRef<ComposeHandle, EmailComposeProps>(
  (
    {
      onSent,
      onClose,
      onRemove,
      inReplyTo = null,
      initialDraft = null,
      isForward = false,
      initialTo,
      initialCC,
      setSubject,
      setSendDisabled,
      setHovering,
      minHeight = "55vh",
      maxHeight = "55vh",
      inlineView = false,
    }: EmailComposeProps,
    ref,
  ) => {
    const isMobile = useIsMobile();
    const hideActions = isMobile && !inlineView;
    const isSavingFirstDraft = useRef(false);
    const currentUser = useContext(CurrentUserContext);
    const [autosaving, setAutosaving] = useState<Promise<any> | null>(null);
    const [key, setKey] = useState(randstr("body-"));
    const documentRef = useRef<Handle | null>(null);
    const [draft, setDraft] = useState<LocalDraft>(
      initialLocalDraft(
        initialDraft,
        inReplyTo,
        currentUser,
        isForward,
        initialTo,
        initialCC,
      ),
    );
    const draftRef = useRef<LocalDraft>(draft);
    useEffect(() => {
      draftRef.current = draft; // <-- cache current value
    }, [draft]);
    const { autocompleteEntries } = useGetEmailAutocompleteEntries({
      // make a prop
      familyRef: undefined,
    });

    const reset = () => {
      const localDraft = initialLocalDraft(
        initialDraft,
        inReplyTo,
        currentUser,
        isForward,
        initialTo,
        initialCC,
      );
      // withAttachments(localDraft.attachments || []);
      setDraft(localDraft);
      setKey(randstr("body-"));
    };
    useEffect(() => {
      // TODO: Get email draft from server and then update local draft
      reset();
    }, [initialDraft, inReplyTo, currentUser, isForward]);
    useEffect(() => {
      setDraft((draft) => ({
        ...draft,
        ...(!draft.ref && {
          to: initialTo || draft.to,
          cc: initialCC || draft.cc,
        }),
      }));
    }, [initialTo, initialCC]);

    const { loading: deleteLoading, request: deleteRequest } =
      useDeleteEmailDraft(() => {
        // withAttachments([]);
        onRemove(draft.ref!, Medium.EMAIL);
        reset();
        onClose();
      });
    const [sendLoading, setSendLoading] = useState(false);
    const { request: sendRequest } = useSendEmailDraft((r) => {
      // withAttachments([]);
      setDraft(
        initialLocalDraft(null, null, currentUser, false, initialTo, initialCC),
      );
      onSent(
        new FeedEntry({
          ref: r.email.ref,
          senderRef: r.email.senderRef,
          timestampSec: r.email.timestampSec,
          medium: Medium.EMAIL,
          emailMessage: r.email,
          recipientRefs: [],
        }),
      );
      reset();
      onClose();
    });
    const { loading: updateLoading, request: updateDraftRequest } =
      useUpdateEmailDraft((r) => {
        if (documentRef.current) {
          documentRef.current?.updateAttachments(r.email?.attachments || []);
        }
        setDraft((d) => ({
          ...initialDraftFromDraft(r.email),
          // Let's not override these local changes.
          to: d.to,
          cc: d.cc,
          bcc: d.bcc,
          subject: d.subject,
          textContent: d.textContent,
          htmlContent: d.htmlContent,
        }));
      });

    const loading = deleteLoading || sendLoading || updateLoading;
    const handleSave = async (currentDraft: LocalDraft, isAuto: boolean) => {
      // Note! It's important that this function not rely on any state which is likely stale
      // since it is debounced.
      if (currentDraft.ref) {
        isSavingFirstDraft.current = false;
      } else if (!isSavingFirstDraft.current) {
        isSavingFirstDraft.current = true;
      } else {
        return;
      }
      const resultPromise = updateDraftRequest({
        draftRef: currentDraft?.ref || "",
        draft: {
          replyToRef: inReplyTo?.ref || "",
          subject: currentDraft?.subject || "",
          textContent: currentDraft?.textContent || "",
          htmlContent: stripImgSrc(currentDraft?.htmlContent) || "",
          attachments: currentDraft.uploadAttachments,
          toAddresses: currentDraft?.to || [],
          ccAddresses: currentDraft?.cc || [],
          bccAddresses: currentDraft?.bcc || [],
          isForwarded: currentDraft?.isForwarded || false,
        },
      });
      if (isAuto) setAutosaving(resultPromise);
      const result = await resultPromise;
      if (isAuto) setAutosaving(null);
      return result;
    };

    const handleSendEmail = async () => {
      if (deleteLoading || sendLoading) return;
      if (!isDraftSendable(draft)) return;
      setSendLoading(true);
      if (autosaving) {
        await autosaving;
      }
      const finalDraft = await handleSave(draft, false);
      await sendRequest({ draftRef: finalDraft!.email!.ref });
      setSendLoading(false);
    };

    // Use the debounce function to save data with a delay
    const debouncedSave = useMemo(
      () =>
        debounce(handleSave, 500, {
          trailing: true,
        }), // Adjust the delay as needed (e.g., 1000ms = 1 second)
      [inReplyTo, initialTo, initialCC],
    );
    const updateDraft = (d: LocalDraft) => {
      setSubject?.(d.ref ? d.subject : "");
      setDraft(d);
      if (isDraftSavable(d)) {
        debouncedSave(d, true);
      }
    };

    // It's annoying to visually change the enabled state here.
    // const isSendDisabled = loading || autosaving || !isDraftSendable(draft);
    const isSendDisabled =
      deleteLoading || sendLoading || !isDraftSendable(draft);

    useEffect(() => {
      setSendDisabled?.(isSendDisabled);
    }, [isSendDisabled]);
    const deleteAction = (
      <Trash2
        onClick={() => {
          if (!loading) {
            if (draft.ref) {
              deleteRequest({ draftRef: draft.ref });
            } else {
              onClose();
            }
          }
        }}
        style={{
          color: sendLoading || deleteLoading ? "rgba(0, 0, 0, 0.26)" : "unset",
          cursor: !(sendLoading || deleteLoading) ? "pointer" : undefined,
        }}
      />
    );

    useImperativeHandle(ref, () => ({
      reset: () => reset(),
      handleSend: () => handleSendEmail(),
    }));
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <>
          <Box sx={{ marginBottom: "15px" }}>
            <AddressFields
              autofocusTo={draft.to.length === 0}
              recipients={{
                to: draft.to,
                cc: draft.cc,
                bcc: draft.bcc,
              }}
              setRecipients={(r) =>
                updateDraft({ ...draft, to: r.to, cc: r.cc, bcc: r.bcc })
              }
              autocompleteEntries={autocompleteEntries}
            />
          </Box>
          <InputBase
            key={`subject-${key}`}
            disabled={sendLoading || deleteLoading}
            autoComplete="off"
            value={draft.subject}
            onChange={(e) => {
              updateDraft({ ...draft, subject: e.target.value || "" });
            }}
            placeholder="Subject"
            sx={{ fontFamily: "sans-serif" }}
            fullWidth
            autoFocus={draft.to.length > 0 && !draft.subject}
          />
          <Divider />
          <DocumentEditor
            autofocus={draft.to.length > 0 && !!draft.subject}
            ref={documentRef}
            key={key}
            editorMinHeight={minHeight}
            editorMaxHeight={maxHeight}
            placeholder="Type your message..."
            disabled={sendLoading || deleteLoading}
            setContent={(c) => {
              if (
                c.html === draft.htmlContent &&
                c.text === draft.textContent &&
                // Only "new" uploads have a validation key set, any if there are any new uploads
                // we should trigger a save.
                c.attachments.every((a) => !a.validationKey)
              )
                return;
              updateDraft({
                ...draft,
                textContent: c.text,
                htmlContent: c.html,
                uploadAttachments: c.attachments,
              });
            }}
            initialContent={draft.htmlContent || draft.textContent || undefined}
            initialAttachments={draft.attachments || undefined}
            leftPrimaryAction={
              !hideActions && (
                <>
                  <Button
                    onClick={() => handleSendEmail()}
                    disabled={isSendDisabled}
                    variant="contained"
                  >
                    Send
                  </Button>
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "unset",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                </>
              )
            }
            secondaryAction={!isMobile ? deleteAction : undefined}
            editActions={isMobile ? [deleteAction] : undefined}
            attachmentsEnabled={true}
            setDragState={setHovering}
            // Override the font family so we see what the user will see.
            sx={{
              fontFamily: "sans-serif",
              height: "100%",
              ".editor-actions": {
                marginBottom: "20px",
              },
            }}
            scrollable={true}
          />
        </>
      </Box>
    );
  },
);
