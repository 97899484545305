import { useState, useEffect } from "react";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import { EllipsisVertical, Plus, Clipboard, Pencil } from "lucide-react";
import { createTaskHelper } from "./utils";
import { TaskInspiration, Task } from "protogen/tasks_pb";
import {
  useListAllMemberTaskInspirations,
  useListAllMemberTaskInspirationCategories,
} from "services/tasks";

export const InspiredTaskList = ({
  title,
  tasks,
  onTaskClick,
  numVisibileTasks = 3,
  showStatus = false,
  iconType = "Add",
  titleSx,
  onCategoryClick,
  onCopySuccess,
}: {
  title: string;
  tasks: TaskInspiration[];
  onTaskClick: (task: TaskInspiration) => void;
  numVisibileTasks?: number;
  showStatus?: boolean;
  iconType?: "Add" | "More";
  titleSx?: React.CSSProperties;
  onCategoryClick?: (categoryRef: string) => void;
  onCopySuccess?: (task: TaskInspiration) => void;
}) => {
  const [visibleTasks, setVisibleTasks] = useState<TaskInspiration[]>(
    tasks.slice(0, numVisibileTasks),
  );

  const onCopy = (task: TaskInspiration) => {
    const prodOrigin = "https://app.findfaye.com";
    const currentOrigin = window.location.origin;
    const isLocal = currentOrigin.includes("localhost");
    const origin = isLocal ? currentOrigin : prodOrigin;
    const url = `${origin}/tasks/add/inspired/${encodeURIComponent(task.ref)}`;
    navigator.clipboard.writeText(url);
    onCopySuccess && onCopySuccess(task);
  };

  useEffect(() => {
    setVisibleTasks(tasks.slice(0, numVisibileTasks));
  }, [tasks]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Typography variant="bodyHeavy" color="#3D3D3D" sx={{ ...titleSx }}>
          {title}
        </Typography>
        {onCategoryClick && (
          <Button
            sx={{ height: "16px" }}
            size="small"
            variant="text"
            onClick={() => {
              onCategoryClick && onCategoryClick(tasks[0].categoryRef);
            }}
          >
            <Pencil size={16}></Pencil>
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {visibleTasks.map((task, i) => {
          return (
            <Box
              key={i}
              sx={(theme) => ({
                padding: "20px",
                paddingRight: "16px",
                border: `1px solid ${theme.palette.border}`,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              })}
            >
              <Box>
                <Typography variant="body" color="#3D3D3D">
                  {task.title}
                </Typography>
                {showStatus && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <Typography
                      variant="body"
                      color={
                        task.status === "active"
                          ? "primary.main"
                          : "secondary.dark"
                      }
                    >
                      {task.status}
                    </Typography>
                    {task.status === "active" && (
                      <Tooltip
                        title="Copy quick add link to clipboard"
                        placement="top"
                      >
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            onCopy(task);
                          }}
                          sx={{
                            height: "16px",
                            minWidth: "16px",
                            cursor: "pointer",
                          }}
                        >
                          <Clipboard size={16} color="#198282" />
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Box>

              <Box
                onClick={() => {
                  onTaskClick(task);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {iconType === "Add" ? (
                  <Plus size={24} color="#198282" />
                ) : (
                  <EllipsisVertical size={24} color="#198282" />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {tasks.length > numVisibileTasks &&
        tasks.length !== visibleTasks.length && (
          <Button
            onClick={() => {
              setVisibleTasks(tasks);
            }}
            sx={{ height: "20px" }}
            variant="text"
          >
            See more
          </Button>
        )}
    </Box>
  );
};
export default ({
  familyRef,
  assigneeRef,
  activeTasks,
  layout = "vertical",
}: {
  familyRef: string;
  assigneeRef: string;
  activeTasks: Task[];
  layout?: "vertical" | "horizontal";
}) => {
  const inspiredTasksRefs = activeTasks
    .filter((task) => {
      return task.sourceEntityType === "task_inspiration";
    })
    .map((task) => task.sourceEntityRef);
  const { data, request: listAllTaskInspirationRequest } =
    useListAllMemberTaskInspirations(() => {});
  const {
    data: inspirationCategories,
    request: listAllTaskInspirationCategoriesRequest,
  } = useListAllMemberTaskInspirationCategories(() => {});
  useEffect(() => {
    listAllTaskInspirationCategoriesRequest();
    listAllTaskInspirationRequest();
  }, []);

  const commonTasks = data?.taskInspirations
    .filter((task) => {
      const category = inspirationCategories?.taskInspirationCategories.find(
        (cat) => cat.ref === task.categoryRef,
      );
      return (
        category && category.title === "Popular" && task.status === "active"
      );
    })
    .sort((a, b) => {
      const aIncluded = inspiredTasksRefs.includes(a.ref);
      const bIncluded = inspiredTasksRefs.includes(b.ref);
      if (aIncluded && !bIncluded) return 1;
      if (!aIncluded && bIncluded) return -1;
      return 0;
    });

  const trendingTasks = data?.taskInspirations
    .filter((task) => {
      const category = inspirationCategories?.taskInspirationCategories.find(
        (cat) => cat.ref === task.categoryRef,
      );
      return (
        category && category.title === "Seasonal" && task.status === "active"
      );
    })
    .sort((a, b) => {
      const aIncluded = inspiredTasksRefs.includes(a.ref);
      const bIncluded = inspiredTasksRefs.includes(b.ref);
      if (aIncluded && !bIncluded) return 1;
      if (!aIncluded && bIncluded) return -1;
      return 0;
    });

  const { createTask } = createTaskHelper({
    referral: "task-inspiration",
    creatorType: "member",
    assigneeRef,
  });
  const onTaskClick = (task: TaskInspiration) => {
    createTask({
      familyRef,
      title: task.title,
      sourceEntityType: "task_inspiration",
      sourceEntityRef: task.ref,
    });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography variant="h3Serif">Task inspiration ✨</Typography>
        <Typography variant="body" color="#3D3D3D">
          Get ahead by requesting these common tasks from your Advisor.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: layout === "vertical" ? "column" : "row",
          gap: layout === "vertical" ? "16px" : "48px",
        }}
      >
        <InspiredTaskList
          title="Seasonal tasks"
          tasks={trendingTasks || []}
          onTaskClick={onTaskClick}
        />
        <InspiredTaskList
          title="Popular tasks"
          tasks={commonTasks || []}
          onTaskClick={onTaskClick}
        />
      </Box>
    </Box>
  );
};
