import { Box, Card, CardContent, Typography } from "@mui/material";
import { FactStub } from "protogen/facts_pb";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { getTypeViewByCategory } from "../facts/FactView";
import { ChevronRight, Settings } from "lucide-react";

const defaultFactRenderer = (value: string): string => {
  return value.replace(/(?:\r\n|\r|\n)/g, " / ");
};

export default ({
  fact,
  isMobile,
  onClick,
}: {
  fact: FactStub;
  isMobile: boolean;
  onClick: (fact: FactStub) => void;
  isDisabled?: boolean;
}) => {
  const factType = getTypeViewByCategory(fact.valueType);

  return (
    <Card
      key={fact.ref}
      sx={(theme) => ({
        width: "100%",
        cursor: "pointer",
        padding: "16px 24px",
        boxShadow: "none",
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "10px",
      })}
      onClick={() => {
        onClick(fact);
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          padding: "0px",
          paddingBottom: "0px !important",
          alignItems: "center",
          gap: isMobile ? "8px" : "25px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: isMobile ? "2px" : "12px",
            alignItems: isMobile ? "flex-start" : "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              gap: "25px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", width: isMobile ? "100%" : "178px" }}>
              {fact.attachments.length > 0 && (
                <DocumentTextIcon
                  style={{ marginRight: "8px" }}
                  height="24px"
                  width="24px"
                />
              )}
              <Typography
                sx={{
                  textWrap: "wrap",
                }}
                variant="bodySmallHeavy"
              >
                {fact.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              fontWeight: "500",
              fontSize: "14px",
              color: "#616161" /* text.body */,
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "100%",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {factType.renderText
              ? factType.renderText(fact.value)
              : defaultFactRenderer(fact.value)}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {fact.ref === "FamilyDetails" ? (
            <Settings size={24} color="#8E9598" />
          ) : (
            <ChevronRight size={24} color="#8E9598" />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
