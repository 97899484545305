import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetchNuxDetails } from "services/nux";
import { getStepPath } from "components/nux/utils";
import auth from "components/nux/auth";
import { USER_NUX_TYPE } from "../nux/constants";

type NuxDetails = {
  path: string;
  stepName: string;
  state: any;
};

const defaultNuxContext = () => ({
  path: "",
  stepName: "",
  state: "",
});
export const NuxDetailsContext = createContext<NuxDetails>(defaultNuxContext());

export default ({
  userType,
  children,
}: {
  userType: USER_NUX_TYPE;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { request } = useFetchNuxDetails((r) => {
    const stepPath = getStepPath(r?.nuxDetails?.stepName, userType);
    if (stepPath !== currentPath) {
      navigate(stepPath);
      return;
    }
    setContext({
      path: r?.nuxDetails?.path || "",
      stepName: r?.nuxDetails?.stepName || "",
      state: r?.nuxDetails?.state || "",
    });
  });
  const [context, setContext] = useState<NuxDetails>(defaultNuxContext());
  useEffect(() => {
    const token = auth.getAuthData();
    if (!token) {
      navigate("/login");
      return;
    }
    request({ token });
  }, [location]);

  return (
    <NuxDetailsContext.Provider value={context}>
      {children}
    </NuxDetailsContext.Provider>
  );
};
