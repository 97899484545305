import useProtoMethod from "./useProtoMethod";
import {
  GenerateReportRequest,
  GenerateReportResponse,
  ListReportsRequest,
  ListReportsResponse,
} from "protogen/reports_service_pb";

const useListReports = (callback?: (r: ListReportsResponse) => void) => {
  return useProtoMethod<ListReportsRequest, ListReportsResponse>(
    "ListReports",
    ListReportsResponse,
    { callback },
  );
};

const useGenerateReport = (callback?: (r: GenerateReportResponse) => void) => {
  return useProtoMethod<GenerateReportRequest, GenerateReportResponse>(
    "GenerateReport",
    GenerateReportResponse,
    { callback },
  );
};

export { useListReports, useGenerateReport };
