import { useState, useEffect } from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthService from "services/auth";

export default () => {
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenVal = queryParams.get("token") || token || "";
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // If this device is already subscribed to notifications, we should send that along.
    AuthService.tokenLogin(tokenVal).then(
      () => {
        navigate("/", { replace: true });
        setError(null);
      },
      (error) => {
        const resMessage =
          error?.response?.data || error.message || error.toString();
        setError(resMessage);
      },
    );
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ padding: "2rem", maxWidth: "500px" }}>
            <Typography variant="display">Logging In...</Typography>
            <form>
              {error && (
                <Alert severity="error" style={{ marginTop: 16 }}>
                  {error}
                </Alert>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
