import React from "react";
import { Box, Button, Step, StepContent, StepLabel } from "@mui/material";

type FormStepProps = {
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  handleNext?: () => void;
  handleBack?: () => void;
  noActions?: boolean;
};

export default ({
  title,
  children,
  disabled,
  handleNext,
  handleBack,
  noActions = false,
  ...otherProps
}: FormStepProps) => {
  return (
    <Step {...otherProps}>
      <StepLabel>{title}</StepLabel>
      <StepContent>
        {children}
        {!noActions && (
          <Box sx={{ mb: 2 }}>
            <div>
              {handleBack && (
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              )}
              {handleNext && (
                <Button
                  disabled={disabled}
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
              )}
            </div>
          </Box>
        )}
      </StepContent>
    </Step>
  );
};
