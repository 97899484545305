import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Chip, InputBase, LinearProgress } from "@mui/material";

import { FeedEntry, Medium } from "protogen/advisors_service_pb";
import { useSendTextMessage } from "services/textMessage";
import { SendTextMessageResponse } from "protogen/text_message_service_pb";
import { useUploader } from "../creation/FileUploader";
import { PaperAirplaneIcon as SendIcon } from "@heroicons/react/24/outline";
import { ReactComponent as AttachmentIcon } from "../../icons/AttachmentIcon.svg";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  isMobileiOS,
  isStandaloneWebapp,
  isTouchDevice,
} from "../../common/utils";
import MessageMenu, { UploadSpeedDialAction } from "./MessageMenu";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { UploadAttachment } from "protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import DragNDrop from "../common/DragNDrop";
import SimpleEditor, { Handle } from "../editor/SimpleEditor";
import { RichContent } from "../editor/utils";
import useCached from "../hooks/useCached";

interface SMSInputProps {
  onSent: (e: FeedEntry) => void;
  familyRef: string | null;
  recipientRefs: string[];
  placeholder: string;
  contactEnabled?: boolean;
  forceDisabled?: boolean;
}

const SendButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) => {
  // if (disabled) return null;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="text"
      sx={{
        minWidth: "24px",
        borderRadius: "8px",
        border: "none",
        padding: "0px",
        height: "44px",
        width: "24px",
        overflow: "hidden",
        opacity: 1,
        transition: "all 0.3s ease",
        marginLeft: 0,
        flexShrink: 0,
        "&.Mui-disabled": {
          minWidth: "0px",
          opacity: 0,
          width: 0,
          padding: 0,
          marginLeft: "-12px", // negative to offset parent's gap.
        },
        ".MuiButton-startIcon": {
          margin: 0,
        },
      }}
      startIcon={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "44px",
          }}
        >
          <SendIcon width="24px" />
        </span>
      }
    />
  );
};

interface CompositionSectionProps {
  placeholder: string;
  contactEnabled?: boolean;
  loading: boolean;
  thumbsUp: boolean;
  onSend: (
    inputValue: string,
    attachments: PlainMessage<UploadAttachment>[],
  ) => Promise<boolean>;
  handleSendCannedMessage?: (
    message: string | null,
    includeContactCard?: boolean,
  ) => void;
  unpad?: boolean;
  autoFocus?: boolean;
  maxUploadBytes?: number;
  forceDisabled?: boolean;
  cacheKey?: string | null;
}
export const CompositionSection = ({
  onSend,
  loading,
  placeholder,
  handleSendCannedMessage,
  thumbsUp = false,
  contactEnabled = true,
  unpad = false,
  autoFocus = false,
  maxUploadBytes = 4900000,
  forceDisabled = false,
  cacheKey = null,
}: CompositionSectionProps) => {
  const isTouch = isTouchDevice();
  const editorRef = useRef<Handle>(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [content, setContent] = useCached<RichContent | null>(cacheKey, null);
  const [hover, setHover] = useState(false);

  const resetInput = () => {
    setTriggerReset(true);
    setContent(null);
  };

  const {
    onUpload,
    fileUploads,
    getCompleteAttachments,
    uploadPercentage,
    clearUploads,
    removeUpload,
    uploadsInProgress,
  } = useUploader({
    maxUploadBytes: maxUploadBytes,
  });

  const handleSend = async () => {
    const attachments = [
      ...(content?.attachments || []),
      ...getCompleteAttachments(),
    ];
    if (loading || uploadsInProgress) return;
    if (!(content?.text || attachments.length)) return;
    const success = await onSend(content?.text || "", attachments);
    if (success) {
      clearUploads();
      resetInput();
    }
  };

  const onCannedMessage = (
    message: string | null,
    includeContactCard?: boolean,
  ) => {
    // Lets not confused things by posting non-current content text if it's present, but if it's a contact
    // that seems fine.
    if (includeContactCard || (!content?.text && !fileUploads.length)) {
      handleSendCannedMessage &&
        handleSendCannedMessage(message, includeContactCard);
    }
  };

  useEffect(() => {
    if (!loading && autoFocus) {
      editorRef.current?.focus();
    }
  }, [loading, editorRef.current]);

  let initialContent = undefined;
  if (content && content.json) {
    try {
      initialContent = JSON.parse(content.json);
    } catch (e) {
      console.error(`Error parsing initialContent: ${e}`);
    }
  }
  const disabled =
    loading || (!content?.text && !fileUploads.length) || uploadsInProgress;
  return (
    <DragNDrop
      enabled={!(uploadsInProgress || loading || forceDisabled)}
      setHover={setHover}
      onUpload={(files) => onUpload(files)}
    >
      <Box
        display="flex"
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "end",
          gap: "12px",
          ...(unpad
            ? {}
            : {
                // Need extra padding on iosWebapps since there's a little bar at the bottom.
                padding:
                  isStandaloneWebapp() && isMobileiOS()
                    ? "16px 16px 30px 0px"
                    : "16px 16px 16px 0px",
              }),
          ...(hover ? { backgroundColor: "#e8f4f8" } : {}),
        }}
      >
        <div style={{ position: "relative", width: "40px", height: "100%" }}>
          <MessageMenu disabled={!contactEnabled || forceDisabled}>
            {thumbsUp && (
              <SpeedDialAction
                icon={"👍"}
                tooltipPlacement="right"
                tooltipTitle={"Send 👍"}
                tooltipOpen={isTouch}
                onClick={() => onCannedMessage("👍", false)}
              />
            )}
            <UploadSpeedDialAction
              disabled={uploadsInProgress}
              tooltipOpen={isTouch}
              onChange={(e) => onUpload(e.target.files)}
              tooltipPlacement="right"
              icon={<AttachmentIcon />}
              tooltipTitle={"Attachments"}
            />
            <SpeedDialAction
              icon={<ContactPhoneIcon />}
              tooltipPlacement="right"
              tooltipTitle={"Share Contact"}
              tooltipOpen={isTouch}
              onClick={() => onCannedMessage(null, true)}
            />
          </MessageMenu>
          {uploadPercentage > 0 && (
            <LinearProgress
              variant="determinate"
              value={uploadPercentage}
              sx={{
                position: "absolute",
                bottom: "0px",
                width: "28px",
                left: "14px",
                boxShadow: "none",
              }}
            />
          )}
        </div>
        <div
          style={{
            position: "relative",
            flexGrow: 1,
            width: "10px",
          }}
        >
          <SimpleEditor
            ref={editorRef}
            disabled={loading || !contactEnabled || forceDisabled}
            placeholder={placeholder}
            initialContent={initialContent}
            setContent={setContent}
            attachmentsEnabled={true}
            onKeyDown={(_, e: KeyboardEvent) => {
              if (e.key === "Enter" && !e.shiftKey && !isTouch) {
                handleSend();
                return true;
              }
            }}
            inlineAttachmentsEnabled={false}
            attachmentActionEnabled={false}
            primaryAction={
              <SendButton
                onClick={handleSend}
                disabled={disabled || !contactEnabled || forceDisabled}
              />
            }
            triggerReset={triggerReset}
            onReset={() => {
              setTriggerReset(false);
            }}
            urlShortenerEnabled={true}
            isRichTextEnabled={false}
            externalAttachmentHandler={onUpload}
          />
          {/* FileUploads */}
          {fileUploads.length > 0 && (
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                gap: "6px",
                flexWrap: "wrap",
                marginTop: "6px",
              }}
            >
              {fileUploads.map((fileUpload, i) => (
                <Chip
                  key={i}
                  label={fileUpload.filename}
                  size="small"
                  sx={{ maxWidth: "120px" }}
                  icon={<DescriptionIcon />}
                  onDelete={() => removeUpload(fileUpload.filename)}
                />
              ))}
            </Box>
          )}
        </div>
      </Box>
    </DragNDrop>
  );
};

export const ShellCompositionSection = ({
  onFocus,
  placeholder,
  disabled = false,
  unpad = false,
}: {
  onFocus: () => void;
  placeholder: string;
  disabled?: boolean;
  unpad?: boolean;
}) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "center",
        alignItems: "end",
        gap: "12px",
        ...(unpad ? {} : { padding: "16px 16px 16px 0px" }),
      }}
    >
      <div style={{ position: "relative", width: "40px", height: "100%" }}>
        <MessageMenu disabled={true}>{""}</MessageMenu>
      </div>
      <div style={{ position: "relative", flexGrow: 1 }}>
        <div
          style={{
            padding: "9px 30px 9px 14px",
            borderRadius: "8px",
            border: "1px solid #D0D5DD",
            background: "var(--base-white, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <InputBase
            inputRef={inputRef}
            autoComplete="off"
            fullWidth
            placeholder={placeholder}
            disabled={disabled}
            multiline
            maxRows={6}
            sx={{
              padding: 0,
            }}
            onFocus={() => {
              onFocus();
            }}
          />
        </div>
      </div>
      <SendButton onClick={() => {}} disabled={true} />
    </Box>
  );
};

export default ({
  familyRef,
  onSent,
  recipientRefs,
  placeholder,
  contactEnabled = true,
  forceDisabled = false,
}: SMSInputProps) => {
  const callback = (resp: SendTextMessageResponse) => {
    const message = resp.textMessage;
    onSent(
      new FeedEntry({
        ref: message.ref,
        senderRef: message.senderRef,
        timestampSec: message.timestampSec,
        medium: Medium.SMS,
        textMessage: message,
        recipientRefs: recipientRefs,
      }),
    );
  };
  const { loading, request } = useSendTextMessage();
  const handleSend = async (
    inputValue: string,
    attachments: PlainMessage<UploadAttachment>[],
  ) => {
    if (loading) return false;
    const resp = await request({
      familyRef: familyRef || "",
      memberRefs: recipientRefs,
      content: inputValue,
      includeContactCard: false,
      attachments: attachments,
    });
    if (resp) {
      callback(resp);
      return true;
    }
    return false;
  };
  const handleSendCannedMessage = async (
    message: string | null,
    includeContactCard?: boolean,
  ) => {
    const resp = await request({
      familyRef: familyRef || "",
      memberRefs: recipientRefs,
      content: message || "",
      includeContactCard: includeContactCard || false,
      attachments: [],
    });
    if (resp) {
      callback(resp);
      return true;
    }
    return false;
  };

  return (
    <CompositionSection
      contactEnabled={contactEnabled}
      onSend={handleSend}
      placeholder={placeholder}
      handleSendCannedMessage={handleSendCannedMessage}
      loading={loading}
      thumbsUp={true}
      forceDisabled={forceDisabled}
      cacheKey={
        familyRef
          ? `sms-compose-family:${familyRef}`
          : `sms-compose-contacts:${recipientRefs.join("|")}`
      }
    />
  );
};
