import { Box, Button, TextField, FormControl } from "@mui/material";
import {
  EphemeralTaskInspirationCategory,
  TaskFormErrors,
} from "../../types/tasks";

interface TaskInspirationFormProps {
  formData: EphemeralTaskInspirationCategory;
  updateFormData: (f: EphemeralTaskInspirationCategory) => void | Promise<void>;
  errors: TaskFormErrors | null;
  onSave?: () => void;
}
export default ({
  formData,
  updateFormData,
  errors,
  onSave,
}: TaskInspirationFormProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxWidth: "850px",
      }}
    >
      <FormControl fullWidth>
        <TextField
          error={!!errors?.title}
          helperText={errors?.title}
          label="Title"
          fullWidth
          value={formData.title || ""}
          onChange={(e) =>
            updateFormData({ ...formData, title: e.target.value })
          }
          margin="none"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Category description"
          fullWidth
          value={formData.description || ""}
          onChange={(e) =>
            updateFormData({ ...formData, description: e.target.value })
          }
          margin="none"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Category icon name"
          fullWidth
          value={formData.iconName || ""}
          onChange={(e) =>
            updateFormData({ ...formData, iconName: e.target.value })
          }
          margin="none"
        />
      </FormControl>

      <Box display="flex" flexDirection="row" justifyContent="end">
        <Button
          onClick={() => {
            onSave && onSave();
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
