// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/public_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { PublicAdvisor } from "./common_pb.ts";

/**
 * @generated from message protogen.FetchAdvisorPublicProfileRequest
 */
export class FetchAdvisorPublicProfileRequest extends Message<FetchAdvisorPublicProfileRequest> {
  /**
   * @generated from field: string profilePath = 1;
   */
  profilePath = "";

  constructor(data?: PartialMessage<FetchAdvisorPublicProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorPublicProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorPublicProfileRequest {
    return new FetchAdvisorPublicProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorPublicProfileRequest {
    return new FetchAdvisorPublicProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorPublicProfileRequest {
    return new FetchAdvisorPublicProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorPublicProfileRequest | PlainMessage<FetchAdvisorPublicProfileRequest> | undefined, b: FetchAdvisorPublicProfileRequest | PlainMessage<FetchAdvisorPublicProfileRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorPublicProfileRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorPublicProfileResponse
 */
export class FetchAdvisorPublicProfileResponse extends Message<FetchAdvisorPublicProfileResponse> {
  /**
   * @generated from field: protogen.PublicAdvisor advisor = 1;
   */
  advisor?: PublicAdvisor;

  constructor(data?: PartialMessage<FetchAdvisorPublicProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorPublicProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: PublicAdvisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorPublicProfileResponse {
    return new FetchAdvisorPublicProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorPublicProfileResponse {
    return new FetchAdvisorPublicProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorPublicProfileResponse {
    return new FetchAdvisorPublicProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorPublicProfileResponse | PlainMessage<FetchAdvisorPublicProfileResponse> | undefined, b: FetchAdvisorPublicProfileResponse | PlainMessage<FetchAdvisorPublicProfileResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorPublicProfileResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberInquiryRequest
 */
export class CreateMemberInquiryRequest extends Message<CreateMemberInquiryRequest> {
  /**
   * @generated from field: protogen.CreateMemberInquiryRequest.Member member = 1;
   */
  member?: CreateMemberInquiryRequest_Member;

  /**
   * @generated from field: string advisorProfilePath = 2;
   */
  advisorProfilePath = "";

  /**
   * @generated from field: string helpBlurb = 3;
   */
  helpBlurb = "";

  constructor(data?: PartialMessage<CreateMemberInquiryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberInquiryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: CreateMemberInquiryRequest_Member },
    { no: 2, name: "advisorProfilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "helpBlurb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberInquiryRequest {
    return new CreateMemberInquiryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberInquiryRequest {
    return new CreateMemberInquiryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberInquiryRequest {
    return new CreateMemberInquiryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberInquiryRequest | PlainMessage<CreateMemberInquiryRequest> | undefined, b: CreateMemberInquiryRequest | PlainMessage<CreateMemberInquiryRequest> | undefined): boolean {
    return proto3.util.equals(CreateMemberInquiryRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberInquiryRequest.Member
 */
export class CreateMemberInquiryRequest_Member extends Message<CreateMemberInquiryRequest_Member> {
  /**
   * @generated from field: string firstName = 1;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  constructor(data?: PartialMessage<CreateMemberInquiryRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberInquiryRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberInquiryRequest_Member {
    return new CreateMemberInquiryRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberInquiryRequest_Member {
    return new CreateMemberInquiryRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberInquiryRequest_Member {
    return new CreateMemberInquiryRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberInquiryRequest_Member | PlainMessage<CreateMemberInquiryRequest_Member> | undefined, b: CreateMemberInquiryRequest_Member | PlainMessage<CreateMemberInquiryRequest_Member> | undefined): boolean {
    return proto3.util.equals(CreateMemberInquiryRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberInquiryResponse
 */
export class CreateMemberInquiryResponse extends Message<CreateMemberInquiryResponse> {
  constructor(data?: PartialMessage<CreateMemberInquiryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberInquiryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberInquiryResponse {
    return new CreateMemberInquiryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberInquiryResponse {
    return new CreateMemberInquiryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberInquiryResponse {
    return new CreateMemberInquiryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberInquiryResponse | PlainMessage<CreateMemberInquiryResponse> | undefined, b: CreateMemberInquiryResponse | PlainMessage<CreateMemberInquiryResponse> | undefined): boolean {
    return proto3.util.equals(CreateMemberInquiryResponse, a, b);
  }
}

