import React, { useState } from "react";
import { Box, Button, FormControlLabel, Typography } from "@mui/material";
import { RichContent } from "../editor/utils";
import Checkbox from "../common/Checkbox";
import { Attachment } from "protogen/common_pb";
import { Member } from "protogen/advisors_service_pb";
import { commaSeparatedEnglishList } from "../../common/utils";
import useIsMobile from "../hooks/useIsMobile";
import SimpleRichEditor from "../editor/SimpleRichEditor";
import Collapse from "@mui/material/Collapse";

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default ({
  onSaveDocument,
  isCreate,
  initialContent,
  initialAttachments,
  isShared,
  accountType = "advisor",
  familyMembers = [],
  isEditMode = false,
  onCancel = () => {},
}: {
  onSaveDocument: (content: RichContent, isShareable: boolean) => void;
  isCreate: boolean;
  initialContent?: string;
  initialAttachments?: Attachment[];
  isShared?: boolean;
  accountType?: "advisor" | "member";
  familyMembers?: Member[];
  isEditMode?: boolean;
  onCancel?: () => void;
}) => {
  const isMobile = useIsMobile();
  const placeholder = "Write a comment";
  const [isPrivate, setIsPrivate] = useState(
    isCreate === true ? false : !isShared,
  );
  const [content, setContent] = useState<RichContent | null>(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [uploadsInProgress, setUploadsInProgress] = useState(false);

  const handleSave = (isShareable: boolean) => {
    if (!content) {
      onCancel();
      return;
    }
    onSaveDocument(content, isShareable);
    if (isCreate) {
      setTriggerReset(true);
    }
  };

  const familyMemebersFirstNames = familyMembers.map((member) =>
    capitalizeFirstLetter(member.firstName),
  );
  return (
    <Box sx={{ display: "flex", gap: "20px" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <SimpleRichEditor
          placeholder={placeholder}
          setContent={setContent}
          setUploadsInProgress={setUploadsInProgress}
          triggerReset={triggerReset}
          onReset={() => {
            setContent(null);
            setTriggerReset(false);
          }}
          inputSx={{ borderRadius: "16px" }}
          initialContent={initialContent}
          initialAttachments={initialAttachments}
          primaryAction={
            <Button
              size="small"
              sx={{
                height: "unset",
                padding: "8px 18px",
              }}
              onClick={() => handleSave(!isPrivate)}
              disabled={!content || uploadsInProgress}
            >
              {isCreate ? "Post" : "Save"}
            </Button>
          }
        />
        <Collapse in={isEditMode || (!!content && isCreate)}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "22px",
              justifyContent: isCreate ? "space-between" : "flex-start",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                flexGrow: 1,
                justifyContent: isEditMode ? "flex-end" : "space-between",
                width: isMobile ? "100%" : undefined,
              }}
            >
              {accountType === "advisor" && isCreate && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: isMobile ? "flex-start" : "center",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography variant={"body"}>Make private</Typography>
                    }
                    control={
                      <Checkbox
                        checked={isPrivate}
                        onChange={(e: { target: { checked: boolean } }) => {
                          const isChecked = e.target.checked;
                          setIsPrivate(isChecked);
                        }}
                      />
                    }
                  />
                  <Box>
                    <Typography variant="body" color="#8E9598">
                      {!isPrivate
                        ? `${commaSeparatedEnglishList(
                            familyMemebersFirstNames,
                          )} will be notified of this comment.`
                        : "Only you will see this comment."}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
