import { Box, FormHelperText, Grow, SxProps } from "@mui/material";
import Divider from "@mui/material/Divider";

import { JSONContent, HTMLContent, useEditor } from "@tiptap/react";
import { EditorView } from "@tiptap/pm/view";
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
} from "react";

import useIsRefFocused from "../hooks/useIsRefFocused";
import { Attachment, UploadAttachment } from "protogen/common_pb";
import StyledEditorContent from "./StyledEditorContent";
import EditActions from "./EditActions";
import {
  addImagePlaceholder,
  extractImageReferences,
  generateRandomName,
  getExtensions,
  PLACEHOLDER_PATH,
  renderInitialContent,
  convertHEICImages,
} from "./utils";
import DragNDrop from "../common/DragNDrop";
import {
  FileUpload,
  useUploader,
  fromAttachment,
} from "../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { RichContent } from "./utils";
import { Transaction } from "@tiptap/pm/state";
import { Editor } from "@tiptap/core/dist/packages/core/src";
import { Editor as ReactEditor } from "@tiptap/react";
import AttachmentList from "./AttachmentList";
import UploadAttachmentIcon from "./UploadAttachmentIcon";
import { InsertableSuggestion, insertSuggestion } from "./extensions/utils";
import useIsMobile from "../hooks/useIsMobile";
import { useGetUrlContent } from "services/files";
import { isUserAdvisor } from "../../common/userUtils";
import { CurrentUserContext } from "../context/RequireAuth";

type Props = {
  setContent: (content: RichContent) => void;
  initialContent?: string | JSONContent | HTMLContent;
  initialAttachments?: Attachment[];
  placeholder?: string;
  passiveEditor?: boolean;
  leftPrimaryAction?: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  editActions?: ReactNode[];
  disabled?: boolean;
  editorMinHeight?: string;
  editorMaxHeight?: string;
  editorDefaultFontSize?: string;
  scrollable?: boolean;
  // If present, attachments will be enabled.
  attachmentsEnabled?: boolean;
  setDragState?: (dragging: boolean) => void;
  sx?: SxProps;
  formError?: boolean;
  formHelperText?: string;
  placement?: "top" | "bottom";
  autofocus?: boolean;
};
export type Handle = {
  updateAttachments: (attachments: Attachment[]) => void;
  insertSuggestion: (s: InsertableSuggestion) => void;
  getContent: () => RichContent | null;
};

const EditorActions = ({
  editor,
  showEditor,
  leftPrimaryAction,
  primaryAction,
  secondaryAction,
  extraActions,
  placement = "bottom",
}: {
  editor: ReactEditor;
  showEditor: boolean;
  extraActions: ReactNode[];
  leftPrimaryAction?: ReactNode;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  placement?: "top" | "bottom";
}) => {
  const isMobile = useIsMobile();
  return (
    <Grow in={showEditor}>
      <Box
        className={"editor-actions"}
        sx={{
          display: showEditor ? "flex" : "none",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          rowGap: "16px",
          marginTop: placement === "bottom" ? "20px" : "0px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              width: isMobile ? "100%" : "10px",
              overflowX: "auto",
              display: "flex",
              alignItems: "start",
              flexDirection: isMobile ? "column-reverse" : "row",
              gap: isMobile ? "8px" : "0px",
            }}
          >
            {leftPrimaryAction}
            <Box
              sx={{
                flexGrow: 1,
                width: isMobile ? "100%" : "10px",
                overflowX: "auto",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <EditActions editor={editor} extraActions={extraActions} />
            </Box>
          </Box>
        </Box>
        {(secondaryAction || primaryAction) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              justifyContent: "flex-end",
              alignItems: "center",
              minWidth: "fit-content",
              ...(isMobile ? { width: "100%" } : {}),
            }}
          >
            {secondaryAction}
            {primaryAction}
          </Box>
        )}
      </Box>
    </Grow>
  );
};

export default forwardRef<Handle, Props>(
  (
    {
      setContent,
      initialContent,
      placeholder,
      passiveEditor = false,
      leftPrimaryAction,
      primaryAction,
      secondaryAction,
      editActions,
      disabled,
      editorMinHeight,
      editorMaxHeight,
      editorDefaultFontSize,
      scrollable,
      setDragState,
      initialAttachments = [],
      attachmentsEnabled = false,
      sx,
      formError = false,
      formHelperText,
      placement = "bottom",
      autofocus = false,
    }: Props,
    ref,
  ) => {
    const notesRef = React.useRef<HTMLDivElement>(null);
    const currentUser = useContext(CurrentUserContext);
    const { isFocused } = useIsRefFocused(notesRef);
    const {
      onUpload,
      fileUploads,
      fileOnlyUploads,
      uploadPercentage,
      removeUpload,
      getCompleteAttachments,
      withAttachments,
      uploadsInProgress,
      updateFileUpload,
    } = useUploader({
      initialAttachments: initialAttachments,
    });

    const { request: getUrlContentRequest } = useGetUrlContent();

    const handlePaste = (_: EditorView, event: ClipboardEvent) => {
      // Handle pasted images
      const files = Array.from(event.clipboardData?.files || []);
      // Handle copy and pasted html with images with our signed urls
      // as the source.  Since they expire, they cannot be copied over.

      const html = event.clipboardData?.getData("text/html");
      const plainText = event.clipboardData?.getData("text/plain");
      const isMSWord =
        html && html.includes("urn:schemas-microsoft-com:office:word");

      // MSWord will always include an image of the selected content. When there is content, this
      // is distracting since you have the text and an image. When there is no content, then this could
      // be a single image copied from MS Word.
      if (files.length > 0 && (!isMSWord || plainText === " ")) {
        // Editor paste handler is synchronous
        (async (editor: Editor | null) => {
          const convertedFiles = await convertHEICImages(files);
          const references: (string | null)[] = [];
          for (const file of convertedFiles) {
            if (file.type.startsWith("image/")) {
              const name = generateRandomName("pasted-");
              addImagePlaceholder(editor, name, file, true);
              references.push(name);
            } else {
              references.push(null);
            }
            onUpload(convertedFiles, references, setAttachments);
          }
        })(editor);
        return true;
      }

      if (html && !isMSWord) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const imgs = doc.querySelectorAll("img");
        const fetchPromises: Promise<FileUpload | undefined>[] = [];
        if (imgs.length) {
          // We use this pattern to avoid typescript warnings when looping over a NodeList
          Array.from(imgs).forEach((img) => {
            const title = generateRandomName("pasted-");
            img.title = title;
            // tiptap will set the height.  We want to keep the aspect ratio while allowing
            // our resizable extension to work (it changes width and maintains aspect ratio)
            img.setAttribute("height", "auto");
            const fetchPromise = getUrlContentRequest({
              name: title,
              url: img.src,
            }).then((response) => {
              const attachment = response?.attachment;
              if (attachment) {
                const upload = fromAttachment(attachment);
                upload.validationKey = response.validationKey;
                upload.s3Key = response.s3Key;
                upload.inlineReference = title;
                return upload;
              }
            });
            fetchPromises.push(fetchPromise);
          });

          Promise.all(fetchPromises).then((uploads) => {
            const validUploads = uploads.filter(
              (u): u is FileUpload => u !== undefined,
            );
            updateFileUpload([...validUploads]);

            const updatedHtml = doc.body.innerHTML;
            editor?.commands.insertContent(updatedHtml);
            return true;
          });
          return true;
        }
      }

      return false;
    };

    const updateAttachments = (attachments: Attachment[]) => {
      withAttachments(attachments);
      if (!editor) return;
      const attachmentMap = attachments.reduce(
        (mapping, a) => {
          mapping[a.inlineReference] = a;
          return mapping;
        },
        {} as { [inlineReference: string]: Attachment },
      );
      editor.state.doc.descendants((a, startPos) => {
        if (
          a.type.name === "image" &&
          attachmentMap[a.attrs.title] &&
          (a.attrs.src === PLACEHOLDER_PATH ||
            // Don't replace if it's a data URL, would disrupt the user experience.
            // || a.attrs.src.startsWith('data:')
            a.attrs.src === "")
        ) {
          const n = attachmentMap[a.attrs.title];
          const newNode = editor.schema.nodes.image.create({
            title: n.inlineReference,
            src: n.url,
          });
          const transaction = editor.state.tr.replaceWith(
            startPos,
            startPos + a.nodeSize,
            newNode,
          );
          return editor.view.dispatch(transaction);
        }
      });
    };
    useImperativeHandle(ref, () => ({
      updateAttachments: updateAttachments,
      insertSuggestion: (s: InsertableSuggestion) =>
        editor && insertSuggestion(editor, s),
      getContent: () => {
        if (!editor) return null;
        const references = extractImageReferences(editor.getJSON());
        const attachments = getCompleteAttachments();
        const filteredAttachments = attachments.filter(
          (a) => !a.inlineReference || references.has(a.inlineReference),
        );
        return {
          html: editor.getHTML() || "",
          text: editor.getText({ blockSeparator: "\n" }) || "",
          json: JSON.stringify(editor.getJSON()),
          attachments: filteredAttachments,
        };
      },
    }));

    const onUpdate = (props: { editor: Editor; transaction: Transaction }) => {
      if (props.transaction.steps.length === 1) {
        // @ts-ignore
        const slice = props.transaction?.steps[0]?.slice;
        if (
          slice?.content?.content?.length === 1 &&
          slice.content.content[0].type.name === "image" &&
          slice.content.content[0].attrs.src.startsWith("https://s3")
        ) {
          // This is a placeholder image, don't update content
          return;
        }
      }
      const references = extractImageReferences(props.editor.getJSON());
      const attachments = getCompleteAttachments();
      const filteredAttachments = attachments.filter(
        (a) => !a.inlineReference || references.has(a.inlineReference),
      );
      setContent({
        html: props.editor.getHTML(),
        text: props.editor.getText({ blockSeparator: "\n" }),
        json: JSON.stringify(props.editor.getJSON()),
        attachments: filteredAttachments,
      });
    };

    const editor = useEditor({
      editable: !disabled,
      extensions: getExtensions({
        placeholder,
        handleCommunications: currentUser && isUserAdvisor(currentUser),
      }),
      content: renderInitialContent(initialContent, initialAttachments),
      onUpdate: onUpdate,
      editorProps: {
        handlePaste: handlePaste,
      },
      parseOptions: {
        preserveWhitespace: "full",
      },
      autofocus: autofocus,
    });

    useEffect(() => {
      if (editor) {
        editor.setOptions({
          onUpdate: onUpdate,
          editorProps: {
            handlePaste: handlePaste,
          },
        });
      }
    }, [editor, fileUploads, handlePaste, onUpdate]);

    useEffect(() => {
      if (initialContent && editor) {
        setContent({
          html: editor.getHTML() || "",
          text: editor.getText({ blockSeparator: "\n" }) || "",
          json: JSON.stringify(editor.getJSON()),
          attachments: getCompleteAttachments(),
        });
      }
    }, [editor]);

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        if (editor) {
          setContent({
            html: editor.getHTML(),
            text: editor.getText({ blockSeparator: "\n" }),
            json: JSON.stringify(editor.getJSON()),
            attachments: attachments,
          });
        }
      },
      [editor, fileUploads],
    );

    if (!editor) return null;
    const onUploadInlineFiles = (files: FileList | null) => {
      if (!files) return;
      const inlineReferences = [];
      (async () => {
        const convertedFiles = await convertHEICImages(Array.from(files));
        for (let i = 0; i < convertedFiles.length; i++) {
          if (convertedFiles[i].type.startsWith("image/")) {
            // maybe configure this by product? notes/etc.
            const name = generateRandomName("file-");
            addImagePlaceholder(editor, name, convertedFiles[i], false);
            inlineReferences.push(name);
          } else {
            inlineReferences.push(null);
          }
        }
        onUpload(convertedFiles, inlineReferences, setAttachments);
      })();
    };

    const extraActions = [
      ...(attachmentsEnabled
        ? [
            <UploadAttachmentIcon
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={(files) => {
                (async () => {
                  const convertedFiles = await convertHEICImages(
                    Array.from(files || []),
                  );
                  onUpload(convertedFiles, undefined, setAttachments);
                })();
              }}
            />,
            <UploadAttachmentIcon
              imageIcon
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={onUploadInlineFiles}
            />,
          ]
        : []),
      ...(editActions || []),
    ];

    const showEditor = !passiveEditor || isFocused;
    return (
      <DragNDrop
        enabled={attachmentsEnabled}
        setHover={setDragState}
        onUpload={onUploadInlineFiles}
      >
        <Box
          ref={notesRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            fontWeight: 500,
            ...sx,
          }}
        >
          {placement === "top" && (
            <EditorActions
              editor={editor}
              showEditor={showEditor}
              leftPrimaryAction={leftPrimaryAction}
              primaryAction={primaryAction}
              secondaryAction={secondaryAction}
              extraActions={extraActions}
              placement={placement}
            />
          )}
          {showEditor && placement === "top" && (
            <Divider sx={{ marginBottom: "20px" }} />
          )}
          <Box
            sx={{
              display: "flex",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <StyledEditorContent
              minHeight={editorMinHeight}
              maxHeight={editorMaxHeight}
              fontSize={editorDefaultFontSize}
              scrollable={scrollable}
              editor={editor}
            />
          </Box>
          <AttachmentList
            attachments={fileOnlyUploads}
            onDelete={(filename) => removeUpload(filename, setAttachments)}
          />
          {formHelperText && (
            <FormHelperText error={formError}>{formHelperText}</FormHelperText>
          )}
          {showEditor && (!placement || placement === "bottom") && <Divider />}
          {!placement ||
            (placement === "bottom" && (
              <EditorActions
                editor={editor}
                showEditor={showEditor}
                leftPrimaryAction={leftPrimaryAction}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                extraActions={extraActions}
                placement={placement}
              />
            ))}
        </Box>
      </DragNDrop>
    );
  },
);
