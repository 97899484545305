import React, {
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { Button } from "@mui/material";
import { Plus } from "lucide-react";
import { createTaskHelper } from "../tasks/utils";
import { createPortal } from "react-dom";

const getScrollParent = (element: HTMLElement | null) => {
  if (!element) {
    return null;
  }

  const isScrollable = (node: HTMLElement) => {
    const style = window.getComputedStyle(node);
    const overflowY = style.getPropertyValue("overflow-y");
    const overflowX = style.getPropertyValue("overflow-x");
    const isScrollableY = ["auto", "scroll", "overlay"].includes(overflowY);
    const isScrollableX = ["auto", "scroll", "overlay"].includes(overflowX);
    return (
      (isScrollableY && node.scrollHeight > node.clientHeight) ||
      (isScrollableX && node.scrollWidth > node.clientWidth)
    );
  };

  let parent = element.parentElement;
  while (parent) {
    if (isScrollable(parent)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return window; // Fallback to window if no scrollable parent is found
};

export default ({
  familyRef,
  children,
  entityRef,
  entityType,
}: {
  familyRef?: string;
  entityRef?: string;
  entityType?: string;
  children: ReactNode | ReactNode[];
}) => {
  const { createTask } = createTaskHelper({
    referral: "highlight",
  });
  const [loading, setLoading] = useState(false);
  const [highlight, setHighlight] = useState<{
    text: string;
    position: null | {
      top: number;
      left: number;
    };
  }>({ text: "", position: null });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const highlightBoxRef = useRef<HTMLDivElement | null>(null);

  const handleCreate = async () => {
    if (!(highlight.text && familyRef)) return;
    setLoading(true);
    await createTask({
      title: highlight.text,
      familyRef,
      sourceEntityType: entityType,
      sourceEntityRef: entityRef,
    });
    setLoading(false);
    setHighlight({ text: "", position: null });
  };

  const handleHighlight = useCallback(() => {
    const selection = window.getSelection();
    const text = selection?.toString().trim();
    const qualifies = (t: string) => t.length > 7 && t.split(" ").length > 1;

    if (selection && text && qualifies(text)) {
      const range = selection.getRangeAt(0);

      const rect = range.getBoundingClientRect();
      setHighlight({
        text,
        position: {
          // top: rect.bottom + 10 + window.scrollY,
          top: rect.bottom + 10,
          left: rect.left + window.scrollX,
        },
      });
    } else {
      setHighlight({ text: "", position: null });
    }
  }, []);

  useEffect(() => {
    const clearHighlight = () => {
      setHighlight({ text: "", position: null });
    };

    if (highlight.position) {
      // Find the scrollable parent
      const scrollableElement = getScrollParent(containerRef.current) || window;

      scrollableElement.addEventListener("scroll", clearHighlight);
      window.addEventListener("resize", clearHighlight);

      return () => {
        scrollableElement.removeEventListener("scroll", clearHighlight);
        window.removeEventListener("resize", clearHighlight);
      };
    }
  }, [highlight.position]);

  // Add event listener to detect clicks outside the floating box
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        highlightBoxRef.current &&
        !highlightBoxRef.current.contains(event.target as Node)
      ) {
        setHighlight({ text: "", position: null });
      }
    };

    if (highlight.position) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [highlight.position]);

  if (!familyRef) return <>{children}</>;
  return (
    <div
      ref={containerRef}
      onMouseUp={() => {
        setTimeout(handleHighlight, 100);
      }}
      onTouchEnd={() => {
        setTimeout(handleHighlight, 100);
      }}
    >
      {children}
      {highlight.position &&
        createPortal(
          <div
            ref={highlightBoxRef}
            style={{
              position: "fixed",
              top: highlight.position.top,
              left: highlight.position.left,
              padding: "8px 13px",
              borderRadius: "8px",
              background: "white",
              userSelect: "none", // Prevent text selection inside the popup
              zIndex: 1000,
              pointerEvents: "auto",
              transition:
                "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), transform 133ms cubic-bezier(0.4, 0, 0.2, 1)",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
            }}
          >
            <Button
              variant="text"
              sx={{ height: "unset", userSelect: "none" }} // Prevent text selection on the button
              startIcon={<Plus />}
              onClick={handleCreate}
              disabled={loading}
            >
              Create task
            </Button>
          </div>,
          document.body, // Render the popup into the body
        )}
    </div>
  );
};
