import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";
import { isStandaloneWebapp } from "../../common/utils";

// When the mobile app is closed and goes offline, it creates a network error.
// This allows a grace period when the app is re-opened.  After this window, network errors will be shown.
const ONLINE_GRACE_WINDOW = 6000;

interface ErrorContextType {
  addError: (message: string, isNetwork?: boolean) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export default ({ children }: { children: ReactNode }) => {
  const isApp = isStandaloneWebapp();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [lastOnline, setLastOnline] = useState<number>(Date.now());
  const [lastForeground, setLastForeground] = useState<number>(Date.now());

  useEffect(() => {
    const updateLastOnline = () => {
      setLastOnline(Date.now());
    };

    window.addEventListener("online", updateLastOnline);

    return () => {
      window.removeEventListener("online", updateLastOnline);
    };
  }, []);

  useEffect(() => {
    const updateLastForeground = () => {
      if (document.visibilityState === "visible") {
        setLastForeground(Date.now());
      }
    };
    window.addEventListener("visibilitychange", updateLastForeground);
    return () => {
      window.removeEventListener("visibilitychange", updateLastForeground);
    };
  }, []);

  const addError = useCallback(
    (message: string, isNetwork: boolean = false) => {
      if (isNetwork && Date.now() - lastOnline <= ONLINE_GRACE_WINDOW) {
        return;
      }
      if (
        isApp &&
        isNetwork &&
        Date.now() - lastForeground <= ONLINE_GRACE_WINDOW
      ) {
        // For the app we also need to check if the app was in the background recently which
        // may have killed some network requests.
        return;
      }
      setSnackPack((prev) => [
        ...prev,
        {
          message,
          key: new Date().getTime(),
          alertSeverity: isNetwork ? "warning" : undefined,
        },
      ]);
    },
    [lastOnline, lastForeground, setSnackPack],
  );

  return (
    <ErrorContext.Provider value={{ addError }}>
      <SnackPack
        snackPack={snackPack}
        alertSeverity="error"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an HttpErrorProvider");
  }
  return context;
};
