import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Typography, Divider } from "@mui/material";
import { Active, Over } from "@dnd-kit/core";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { FactSectionStub, FactStub } from "protogen/facts_pb";
import FactDialog from "../facts/FactDialog";
import FactSectionDialog from "../facts/FactSectionDialog";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import {
  EphemeralFact,
  buildEphemeralFact,
  EphemeralFactSection,
  buildEphemeralFactSection,
} from "../facts/types";
import useIsMobile from "../hooks/useIsMobile";
import { Family } from "protogen/advisors_service_pb";
import EditFamilyDialog from "./EditFamilyDialog";
import FactCard from "../facts/FactCard";
import FactHeader from "../facts/FactHeader";
import { createMemberFactStubs } from "../facts/FamilyMembers";
import { MultiContainer } from "../common/dnd/MultiContainer";
import { useEditFact, useEditFactSection } from "services/facts";
import { attachmentsToUploads } from "../creation/FileUploader";
import { Advisor, UserPermission } from "protogen/common_pb";
import { Note } from "protogen/notes_pb";
import NotesEditor from "../details-page/NotesEditor";
import { getFormattedLocation } from "./utils";
import { prettyFamilyStatus } from "./types";

// FactMap has the mapping between sections and facts.  We reference this object
// on drag and drop to determine new section and fact sort order.
// FactMapDroppable contains the "FactCard" element cretaed from the FactMap
// It's used to render the drag-and-drop.

const UNASSIGNED_FACT_SECTION_NAME = "Other facts";

interface FactMap {
  [key: string]: FactStub[];
}

interface Container {
  id: string;
  headerName: string;
  headerElement: JSX.Element;
  items: DroppableItem[];
}

interface FactMapDroppable {
  [key: string]: Container;
}
interface DroppableItem {
  id: string;
  element: JSX.Element;
  isDisabled: boolean;
}

const createFamilyDetailsFactStub = (family: Family): FactStub => {
  const values = [
    `Location: ${getFormattedLocation(family.address)}`,
    `Status: ${prettyFamilyStatus(family.status)}`,
    `Members: ${family.familyMembers.length}`,
  ];
  return new FactStub({
    ref: "FamilyDetails",
    name: `${family.name} Family`,
    valueType: "family",
    value: values.join("\n"),
    attachments: [],
  });
};

const createFactMap = (
  facts: FactStub[],
  family: Family,
  sections: FactSectionStub[],
): FactMap => {
  const familyStub = createFamilyDetailsFactStub(family);
  const memberStubs = createMemberFactStubs(family);
  const result: FactMap = {};
  // Initialize result with sections
  sections.forEach((section) => {
    result[section.name] = [];

    // populate "fake facts" - family details
    if (section.entityType === "family") {
      result[section.name].push(familyStub);
    }
    if (section.entityType === "member") {
      const memberFact = memberStubs.find(
        (stub) => stub.ref === section.entityRef,
      );
      if (!memberFact) return;
      result[section.name].push(memberFact);
    }
  });

  // Add facts to each appropriate section.
  facts.forEach((fact) => {
    const sectionName =
      sections.find((section) => section.ref === fact.sectionRef)?.name ??
      UNASSIGNED_FACT_SECTION_NAME;
    if (!result[sectionName]) {
      // If the section is not found, add it to the last section
      result[sections[sections.length - 1].name].push(fact);
    } else {
      result[sectionName].push(fact);
    }
  });

  return result;
};

const createDroppableItem = (
  fact: FactStub,
  onClick: (fact: FactStub) => void,
  isMobile: boolean,
  isDisabled: boolean,
  factType: string,
): DroppableItem => {
  const item: DroppableItem = {
    element: (
      <FactCard
        fact={fact}
        isMobile={isMobile}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    ),
    id: `${factType}-${fact.ref}`, // prefix with type since it can be a ref for a fact-id or member (user id)
    isDisabled,
  };
  return item;
};

const createFactMapDroppable = (
  factMap: FactMap,
  sections: FactSectionStub[],
  isMobile: boolean,
  onFactClick: (fact: FactStub) => void,
  onFamilyDetailClick: (fact: FactStub) => void,
  onSectionClick: (factSection: FactSectionStub) => void,
): FactMapDroppable => {
  const result: FactMapDroppable = {};

  // Iterate over the factMap and convert each fact to a droppable item
  Object.entries(factMap).forEach(([sectionName, facts]) => {
    const section = sections.find((s) => s.name === sectionName);
    const container: Container = {
      id: sectionName,
      headerName: sectionName,
      headerElement: (
        <FactHeader factSection={section!} onClick={onSectionClick} />
      ),
      items: facts.map((fact) =>
        createDroppableItem(
          fact,
          fact.valueType === "family" || fact.valueType === "member"
            ? onFamilyDetailClick
            : onFactClick,
          isMobile,
          fact.valueType === "family" || fact.valueType === "member",
          fact.valueType,
        ),
      ),
    };
    result[sectionName] = container;
  });

  return result;
};

interface FamilyFactsSectionProps {
  title: string;
  note: Note;
  facts: FactStub[];
  sections: FactSectionStub[];
  familyRef: string;
  family: Family;
  refresh: () => void;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
}

export default ({
  note,
  facts,
  sections,
  familyRef,
  family,
  refresh,
  primaryAdvisor,
  advisorPermissions,
}: FamilyFactsSectionProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
  const confirmState = useConfirmationDialog();
  const [localFacts, setLocalFacts] = useState<FactStub[]>(facts);
  const [selectedFact, setSelectedFact] = useState<EphemeralFact | null>(null);
  const [selectedFactSection, setSelectedFactSection] =
    useState<EphemeralFactSection | null>(null);
  const [editFamilyDialogSection, setEditFamilyDialogSection] = useState<
    string | null
  >(null);
  const { request: editRequest } = useEditFact(() => {});
  const { request: editSectionRequest } = useEditFactSection(() => {});

  const handleClose = () => {
    setSelectedFact(null);
    setSelectedFactSection(null);
    setDialogOpen(false);
  };

  const handleSectionClose = () => {
    setSelectedFactSection(null);
    setSectionDialogOpen(false);
  };

  const onFactSelectionSelect = (factSection: FactSectionStub) => {
    setSelectedFactSection(buildEphemeralFactSection(factSection));
    setSectionDialogOpen(true);
  };

  const onFactSelect = (fact: FactStub) => {
    setSelectedFact(buildEphemeralFact(fact));
    setDialogOpen(true);
  };

  const onFamilyDetailClick = (fact: FactStub) => {
    setEditFamilyDialogSection(
      fact.valueType === "family" ? "family" : fact.ref,
    );
  };

  const [factMap, setFactMap] = useState<FactMap>({});
  const [factMapDroppable, setFactMapDroppable] = useState<FactMapDroppable>(
    {},
  );

  const calculateSortOrder = (
    index: number,
    container: FactStub[] | FactSectionStub[],
    hasContainerChange: boolean,
    originalIndex: number,
  ): number => {
    const containerLength = container.length;
    const DEFAULT_SORT_ORDER = 1000;
    let leftIndex = 0;
    let rightIndex = 0;

    if (index <= 0) {
      rightIndex =
        containerLength > 0 ? container[0].sortOrder : DEFAULT_SORT_ORDER;
    } else if (index >= containerLength - 1) {
      leftIndex = container[container.length - 1].sortOrder;
      rightIndex = leftIndex + DEFAULT_SORT_ORDER;
    } else {
      if (hasContainerChange || index < originalIndex) {
        leftIndex = container[index - 1].sortOrder;
        rightIndex = container[index].sortOrder;
      } else {
        leftIndex = container[index].sortOrder;
        rightIndex = container[index + 1].sortOrder;
      }
    }

    return (leftIndex + rightIndex) / 2;
  };

  const findSectionInfo = (fact: FactStub, containerId: string) => {
    const sectionName =
      sections.find((s) => s.ref === fact.sectionRef)?.name ?? "";
    const hasSectionedChanged = sectionName !== containerId;
    const newSectionRef =
      sections.find((s) => s.name === containerId)?.ref ?? "";

    return { hasSectionedChanged, newSectionRef };
  };

  const handleContainerMove = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    const containerId = active.id;
    const section = sections.find((s) => s.name === containerId);
    const originalIndex = active?.data.current?.sortable.index;
    const index = over?.data.current?.sortable.index;
    if (!section) return;

    const hasContainerChange = section.name !== containerId;

    const sortOrder = calculateSortOrder(
      index,
      sections,
      hasContainerChange,
      originalIndex,
    );

    editSectionRequest({
      factSectionRef: section.ref,
      name: section.name,
      sortOrder: sortOrder,
    });
  };

  const handleItemMove = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    const factRef = active.id.toString().split("-")[1];
    const containerId = active?.data.current?.sortable.containerId;

    if (containerId === undefined) return;

    const container = factMap[containerId];
    const fact = facts.find((f) => f.ref === factRef);
    if (!fact) return;

    const { hasSectionedChanged, newSectionRef } = findSectionInfo(
      fact,
      containerId,
    );

    const index = hasSectionedChanged
      ? active?.data.current?.sortable.index
      : over?.data.current?.sortable.index;

    if (index === undefined) return;

    const originalIndex = container.findIndex((f) => f.ref === factRef);

    const sortOrder = calculateSortOrder(
      index,
      container,
      hasSectionedChanged,
      originalIndex,
    );

    editRequest({
      factRef: fact.ref,
      name: fact.name,
      value: fact.value,
      attachments: attachmentsToUploads(fact.attachments),
      sectionRef: hasSectionedChanged ? newSectionRef : fact.sectionRef,
      sortOrder: sortOrder,
    });
  };

  const onContainerUpdate = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    if (active.data.current?.type === "container") {
      handleContainerMove({ active, over });
    } else {
      handleItemMove({ active, over });
    }
  };

  useEffect(() => {
    const factRef = searchParams.get("factRef");
    if (!factRef) return;

    const fact = facts.find((f) => f.ref === factRef);
    if (!fact) return;

    setSelectedFact(buildEphemeralFact(fact));
    setDialogOpen(true);

    // Not required but nice to remove the query parameters to avoid popping up the fact if
    // user manually refreshes the page.
    searchParams.delete("factRef");
    navigate("." + searchParams.toString(), { replace: true });
  }, [searchParams]);

  useEffect(() => {
    setLocalFacts(facts);
  }, [facts]);

  useEffect(() => {
    const factMap = createFactMap(localFacts, family, sections);
    setFactMap(factMap);
    setFactMapDroppable(
      createFactMapDroppable(
        factMap,
        sections,
        isMobile,
        onFactSelect,
        onFamilyDetailClick,
        onFactSelectionSelect,
      ),
    );
  }, [localFacts, family]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
        marginBottom: "150px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          alignSelf: "stretch",
        }}
      >
        <NotesEditor
          note={note}
          style={{ border: "none", padding: "0", boxShadow: "none" }}
        />
      </Box>

      <Divider sx={{ width: "100%", margin: "24px 0", color: "#EAEAEA" }} />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography
          variant="h2Serif"
          sx={{ lineHeight: "normal", fontWeight: "700" }}
        >
          Facts
        </Typography>
        <Button
          variant="outlined"
          sx={{
            height: "40px",
            padding: "8px 24px",
            fontSize: "14px",
            fontWeight: "600",
          }}
          onClick={() => setDialogOpen(true)}
        >
          New fact
        </Button>
      </Box>
      <MultiContainer
        items={factMapDroppable}
        strategy={rectSortingStrategy}
        vertical
        activationConstraint={{
          delay: 250,
          tolerance: 5,
        }}
        containerStyle={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: "12px",
        }}
        onDragEndCallback={onContainerUpdate}
      />
      <Typography variant="bodySmall" sx={{ color: "#6B6E7B" }}>
        Pro tip: You can drag and drop any fact card into another section.
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setSectionDialogOpen(true);
          }}
          sx={{
            height: "40px",
            padding: "8px 24px",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          New section
        </Button>
      </Box>
      {facts.length === 0 && "No facts have been added yet."}
      <FactDialog
        open={dialogOpen}
        familyRef={familyRef}
        onClose={handleClose}
        onCreate={(s) => {
          if (s) {
            setLocalFacts((prevState) => [s, ...prevState]);
          }
          handleClose();
        }}
        onDelete={(ref) => {
          if (ref) {
            setLocalFacts((prevState) => [
              ...prevState.filter((fact) => fact.ref !== ref),
            ]);
          }
          handleClose();
        }}
        onEdit={(s) => {
          if (s) {
            setLocalFacts((prevState) => [
              ...prevState.map((fact) => (fact.ref === s.ref ? s : fact)),
            ]);
          }
          handleClose();
        }}
        isEdit={selectedFact !== null}
        fact={selectedFact}
        factSectionRef={selectedFactSection?.ref ?? ""}
      />

      <FactSectionDialog
        open={sectionDialogOpen}
        familyRef={familyRef}
        onClose={handleSectionClose}
        onCreate={() => {
          refresh();
          handleSectionClose();
        }}
        onDelete={() => {
          refresh();
          handleSectionClose();
        }}
        onEdit={() => {
          refresh();
          handleSectionClose();
        }}
        isEdit={selectedFactSection !== null}
        factSection={selectedFactSection}
      />

      <ConfirmationDialog {...confirmState.dialogProps} />
      <EditFamilyDialog
        primaryAdvisor={primaryAdvisor}
        advisorPermissions={advisorPermissions}
        open={editFamilyDialogSection !== null}
        familyOnly={editFamilyDialogSection === "family"}
        contactOnly={
          editFamilyDialogSection && editFamilyDialogSection !== "family"
            ? editFamilyDialogSection
            : undefined
        }
        onClose={(updated) => {
          if (updated) {
            refresh();
          }
          setEditFamilyDialogSection(null);
        }}
        family={family}
      />
    </Box>
  );
};
