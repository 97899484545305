/*
 * This file is used to cache signed AWS images in local storage by re-using the last signed URL
 * and updating it when key is no longer valid and returns an error, though if the browser
 * cache is working correctly, this should not be very common.
 */

import {
  ImgHTMLAttributes,
  useState,
  VideoHTMLAttributes,
  useEffect,
} from "react";
import { LRUCache } from "./SerializableLRU";

class ImageCache {
  private KEY = "img-bucket-";
  private buckets: number;
  private bucketCapacity: number;

  constructor(buckets: number, bucketCapacity: number) {
    this.buckets = buckets;
    this.bucketCapacity = bucketCapacity;
  }

  public getOrSetItem(
    key: string,
    newValue?: string | null,
  ): string | undefined {
    const itemKey = this.getBaseUrl(key);
    const bucketKey = this.bucketKey(itemKey);
    const bucket = localStorage.getItem(bucketKey);
    const cache = new LRUCache<string>(this.bucketCapacity);
    if (bucket) {
      cache.deserialize(bucket);
    }

    const value = cache.get(itemKey);
    if (value) {
      // Need to update LRU stats.
      localStorage.setItem(bucketKey, cache.serialize());
      return value;
    } else if (newValue) {
      cache.put(itemKey, newValue);
      localStorage.setItem(bucketKey, cache.serialize());
      return undefined;
    } else {
      return undefined;
    }
  }

  public setItem(key: string, value: string) {
    const itemKey = this.getBaseUrl(key);
    const bucketKey = this.bucketKey(itemKey);
    const bucket = localStorage.getItem(bucketKey);
    const cache = new LRUCache<string>(this.bucketCapacity);
    if (bucket) {
      cache.deserialize(bucket);
    }
    cache.put(itemKey, value);
    localStorage.setItem(bucketKey, cache.serialize());
  }

  private partitionKey(key: string): number {
    let hash = 0;
    for (let i = 0; i < key.length; i++) {
      hash = (hash << 5) - hash + key.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash % this.buckets);
  }

  private getBaseUrl(url: string): string {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;
  }

  private bucketKey(baseUrl: string) {
    const bucket = this.partitionKey(baseUrl);
    return `${this.KEY}${bucket}`;
  }
}

const isSignedAWSUrl = (url: string) => {
  const s3UrlRegex = /^https:\/\/s3\.[a-z]{2}-[a-z]+-[1-9]\.amazonaws\.com\/*/;
  return s3UrlRegex.test(url) && url.indexOf("X-Amz-Signature") > 0;
};

type Props = {
  url: string;
};

export const withAssetProps = ({
  url,
}: Props): { src: string; onError?: () => void } => {
  const uncacheable = !url || !isSignedAWSUrl(url);
  const imageCache = new ImageCache(100, 100);
  const cachedUrl = !uncacheable && imageCache.getOrSetItem(url, url);
  const [imageUrl, setImageUrl] = useState<string>(cachedUrl || url);

  useEffect(() => {
    if (url) {
      const newUncacheable = !isSignedAWSUrl(url);
      const newCachedUrl = !newUncacheable && imageCache.getOrSetItem(url, url);
      setImageUrl(newCachedUrl || url);
    }
  }, [url]);

  if (uncacheable) {
    return {
      src: url,
    };
  }

  const handleImageError = () => {
    if (url && url !== cachedUrl) {
      imageCache.setItem(url, url);
      setImageUrl(url);
    }
  };

  return {
    src: imageUrl,
    onError: handleImageError,
  };
};

export const withImageProps = ({
  url,
}: Props): ImgHTMLAttributes<HTMLImageElement> => {
  return {
    ...withAssetProps({ url }),
  };
};

export const withVideoProps = ({
  url,
}: Props): VideoHTMLAttributes<HTMLVideoElement> => {
  return {
    ...withAssetProps({ url }),
  };
};
