import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import useIsMobile from "../hooks/useIsMobile";
import {
  generateSearchQueryUrlWithFilters,
  useDetermineFilters,
} from "./utils";
import { useNavigate } from "react-router";

const IOS_KEYBOARD_OPEN_TIMEOUT = 500;

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export default ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [query, setQuery] = useState<string>("");
  const defaultFilters = useDetermineFilters();
  const handleQuery = () => {
    if (!query) return;
    setQuery("");
    onClose();
    navigate(generateSearchQueryUrlWithFilters(query, defaultFilters));
  };
  useEffect(() => {
    if (open && textFieldRef.current) {
      textFieldRef.current.value = "";
      setQuery("");
      textFieldRef.current.focus();
      if (isMobile) {
        // When focusing on text input Safari iOS keyboard will open
        // and push the content up, possibly moving the input out of viewport.
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, IOS_KEYBOARD_OPEN_TIMEOUT);
      }
    }
  }, [open]);
  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
      PaperComponent={Paper}
      PaperProps={{
        style: isMobile
          ? {
              boxShadow: "none",
            }
          : {
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "var(--base-white, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px", marginRight: "10px" }}
              aria-label="search"
              onClick={handleQuery}
            >
              <SearchIcon />
            </IconButton>
            {!isMobile &&
              defaultFilters
                .filter((f) => f.presentationPrefix && f.presentationValue)
                .map((filter, index) => (
                  <div key={index} style={{}}>
                    <Chip label={filter.presentationValue} size="small" />
                  </div>
                ))}
            <InputBase
              inputRef={textFieldRef}
              fullWidth
              defaultValue={query}
              onChange={(event) => setQuery(event.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "Search" }}
              onKeyDown={(event) => {
                // Allow shift for newlines.
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleQuery();
                }
              }}
            />
            {!isMobile && (
              <>
                <Typography
                  sx={{
                    padding: "8px",
                    color: "rgb(158, 158, 158)",
                    fontWeight: 600,
                  }}
                >
                  ⌘K
                </Typography>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ margin: "0px 5px" }}
                />
              </>
            )}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
        {isMobile && <Divider sx={{ marginTop: "10px" }} />}
      </DialogTitle>
    </Dialog>
  );
};
