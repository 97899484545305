// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/billing_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub } from "./common_pb.ts";
import { Family } from "./advisors_service_pb.ts";
import { BillingCoupon, BillingProduct } from "./billing_pb.ts";

/**
 * Due to recursive imports, we need to import this here instead of billing.proto
 *
 * @generated from message protogen.Invoice
 */
export class Invoice extends Message<Invoice> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.AccountStub advisor = 2;
   */
  advisor?: AccountStub;

  /**
   * @generated from field: protogen.Family family = 3;
   */
  family?: Family;

  /**
   * @generated from field: string status = 4;
   */
  status = "";

  /**
   * @generated from field: int64 createdSec = 5;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: string stripeId = 6;
   */
  stripeId = "";

  /**
   * @generated from field: int64 periodStartSec = 7;
   */
  periodStartSec = protoInt64.zero;

  /**
   * @generated from field: int64 periodEndSec = 8;
   */
  periodEndSec = protoInt64.zero;

  /**
   * @generated from field: int32 invoiceAmountCents = 9;
   */
  invoiceAmountCents = 0;

  /**
   * @generated from field: string invoiceCurrency = 10;
   */
  invoiceCurrency = "";

  /**
   * @generated from field: int32 serviceMonth = 11;
   */
  serviceMonth = 0;

  /**
   * @generated from field: float feePercent = 12;
   */
  feePercent = 0;

  /**
   * @generated from field: int32 paymentCents = 13;
   */
  paymentCents = 0;

  /**
   * @generated from field: repeated protogen.Invoice.InvoiceChangeEvents changeEvents = 14;
   */
  changeEvents: Invoice_InvoiceChangeEvents[] = [];

  constructor(data?: PartialMessage<Invoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Invoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "advisor", kind: "message", T: AccountStub },
    { no: 3, name: "family", kind: "message", T: Family },
    { no: 4, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "stripeId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "periodStartSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "periodEndSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "invoiceAmountCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "invoiceCurrency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "serviceMonth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "feePercent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 13, name: "paymentCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "changeEvents", kind: "message", T: Invoice_InvoiceChangeEvents, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice {
    return new Invoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice | PlainMessage<Invoice> | undefined, b: Invoice | PlainMessage<Invoice> | undefined): boolean {
    return proto3.util.equals(Invoice, a, b);
  }
}

/**
 * @generated from message protogen.Invoice.InvoiceChangeEvents
 */
export class Invoice_InvoiceChangeEvents extends Message<Invoice_InvoiceChangeEvents> {
  /**
   * @generated from field: string eventJson = 1;
   */
  eventJson = "";

  constructor(data?: PartialMessage<Invoice_InvoiceChangeEvents>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Invoice.InvoiceChangeEvents";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventJson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice_InvoiceChangeEvents {
    return new Invoice_InvoiceChangeEvents().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice_InvoiceChangeEvents {
    return new Invoice_InvoiceChangeEvents().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice_InvoiceChangeEvents {
    return new Invoice_InvoiceChangeEvents().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice_InvoiceChangeEvents | PlainMessage<Invoice_InvoiceChangeEvents> | undefined, b: Invoice_InvoiceChangeEvents | PlainMessage<Invoice_InvoiceChangeEvents> | undefined): boolean {
    return proto3.util.equals(Invoice_InvoiceChangeEvents, a, b);
  }
}

/**
 * @generated from message protogen.CustomerAccount
 */
export class CustomerAccount extends Message<CustomerAccount> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 createdSec = 2;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: map<string, string> metadata = 6;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CustomerAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CustomerAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomerAccount {
    return new CustomerAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomerAccount {
    return new CustomerAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomerAccount {
    return new CustomerAccount().fromJsonString(jsonString, options);
  }

  static equals(a: CustomerAccount | PlainMessage<CustomerAccount> | undefined, b: CustomerAccount | PlainMessage<CustomerAccount> | undefined): boolean {
    return proto3.util.equals(CustomerAccount, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyBillingRequest
 */
export class CreateFamilyBillingRequest extends Message<CreateFamilyBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string paymentMethodId = 2;
   */
  paymentMethodId = "";

  constructor(data?: PartialMessage<CreateFamilyBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paymentMethodId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyBillingRequest | PlainMessage<CreateFamilyBillingRequest> | undefined, b: CreateFamilyBillingRequest | PlainMessage<CreateFamilyBillingRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilyBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyBillingResponse
 */
export class CreateFamilyBillingResponse extends Message<CreateFamilyBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<CreateFamilyBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyBillingResponse | PlainMessage<CreateFamilyBillingResponse> | undefined, b: CreateFamilyBillingResponse | PlainMessage<CreateFamilyBillingResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilyBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberBillingRequest
 */
export class CreateMemberBillingRequest extends Message<CreateMemberBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string paymentMethodId = 2;
   */
  paymentMethodId = "";

  constructor(data?: PartialMessage<CreateMemberBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paymentMethodId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberBillingRequest | PlainMessage<CreateMemberBillingRequest> | undefined, b: CreateMemberBillingRequest | PlainMessage<CreateMemberBillingRequest> | undefined): boolean {
    return proto3.util.equals(CreateMemberBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberBillingResponse
 */
export class CreateMemberBillingResponse extends Message<CreateMemberBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<CreateMemberBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberBillingResponse | PlainMessage<CreateMemberBillingResponse> | undefined, b: CreateMemberBillingResponse | PlainMessage<CreateMemberBillingResponse> | undefined): boolean {
    return proto3.util.equals(CreateMemberBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyBillingRequest
 */
export class UpdateFamilyBillingRequest extends Message<UpdateFamilyBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string customerId = 2;
   */
  customerId = "";

  /**
   * @generated from field: bool unlinkCustomer = 3;
   */
  unlinkCustomer = false;

  constructor(data?: PartialMessage<UpdateFamilyBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unlinkCustomer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyBillingRequest | PlainMessage<UpdateFamilyBillingRequest> | undefined, b: UpdateFamilyBillingRequest | PlainMessage<UpdateFamilyBillingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyBillingResponse
 */
export class UpdateFamilyBillingResponse extends Message<UpdateFamilyBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<UpdateFamilyBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyBillingResponse | PlainMessage<UpdateFamilyBillingResponse> | undefined, b: UpdateFamilyBillingResponse | PlainMessage<UpdateFamilyBillingResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingProductsRequest
 */
export class ListBillingProductsRequest extends Message<ListBillingProductsRequest> {
  constructor(data?: PartialMessage<ListBillingProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingProductsRequest | PlainMessage<ListBillingProductsRequest> | undefined, b: ListBillingProductsRequest | PlainMessage<ListBillingProductsRequest> | undefined): boolean {
    return proto3.util.equals(ListBillingProductsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingProductsResponse
 */
export class ListBillingProductsResponse extends Message<ListBillingProductsResponse> {
  /**
   * @generated from field: repeated protogen.BillingProduct products = 1;
   */
  products: BillingProduct[] = [];

  constructor(data?: PartialMessage<ListBillingProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: BillingProduct, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingProductsResponse | PlainMessage<ListBillingProductsResponse> | undefined, b: ListBillingProductsResponse | PlainMessage<ListBillingProductsResponse> | undefined): boolean {
    return proto3.util.equals(ListBillingProductsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingCouponsRequest
 */
export class ListBillingCouponsRequest extends Message<ListBillingCouponsRequest> {
  constructor(data?: PartialMessage<ListBillingCouponsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingCouponsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingCouponsRequest | PlainMessage<ListBillingCouponsRequest> | undefined, b: ListBillingCouponsRequest | PlainMessage<ListBillingCouponsRequest> | undefined): boolean {
    return proto3.util.equals(ListBillingCouponsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingCouponsResponse
 */
export class ListBillingCouponsResponse extends Message<ListBillingCouponsResponse> {
  /**
   * @generated from field: repeated protogen.BillingCoupon coupons = 1;
   */
  coupons: BillingCoupon[] = [];

  constructor(data?: PartialMessage<ListBillingCouponsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingCouponsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coupons", kind: "message", T: BillingCoupon, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingCouponsResponse | PlainMessage<ListBillingCouponsResponse> | undefined, b: ListBillingCouponsResponse | PlainMessage<ListBillingCouponsResponse> | undefined): boolean {
    return proto3.util.equals(ListBillingCouponsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListInvoicesRequest
 */
export class ListInvoicesRequest extends Message<ListInvoicesRequest> {
  /**
   * @generated from field: repeated string statuses = 1;
   */
  statuses: string[] = [];

  /**
   * @generated from field: repeated string advisorRefs = 2;
   */
  advisorRefs: string[] = [];

  /**
   * @generated from field: repeated string familyRefs = 3;
   */
  familyRefs: string[] = [];

  /**
   * @generated from field: int64 createdSecStart = 4;
   */
  createdSecStart = protoInt64.zero;

  /**
   * @generated from field: string advisorRef = 5;
   */
  advisorRef = "";

  /**
   * @generated from field: string familyRef = 6;
   */
  familyRef = "";

  constructor(data?: PartialMessage<ListInvoicesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListInvoicesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "statuses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "advisorRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "familyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "createdSecStart", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListInvoicesRequest | PlainMessage<ListInvoicesRequest> | undefined, b: ListInvoicesRequest | PlainMessage<ListInvoicesRequest> | undefined): boolean {
    return proto3.util.equals(ListInvoicesRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListInvoicesResponse
 */
export class ListInvoicesResponse extends Message<ListInvoicesResponse> {
  /**
   * @generated from field: repeated protogen.Invoice invoices = 1;
   */
  invoices: Invoice[] = [];

  constructor(data?: PartialMessage<ListInvoicesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListInvoicesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoices", kind: "message", T: Invoice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListInvoicesResponse | PlainMessage<ListInvoicesResponse> | undefined, b: ListInvoicesResponse | PlainMessage<ListInvoicesResponse> | undefined): boolean {
    return proto3.util.equals(ListInvoicesResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateInvoiceRequest
 */
export class UpdateInvoiceRequest extends Message<UpdateInvoiceRequest> {
  /**
   * @generated from field: string invoiceRef = 1;
   */
  invoiceRef = "";

  /**
   * @generated from field: string status = 2;
   */
  status = "";

  /**
   * @generated from field: string advisorRef = 3;
   */
  advisorRef = "";

  /**
   * @generated from field: float feePercent = 4;
   */
  feePercent = 0;

  /**
   * @generated from field: int32 paymentCents = 5;
   */
  paymentCents = 0;

  /**
   * @generated from field: string changeNotes = 6;
   */
  changeNotes = "";

  constructor(data?: PartialMessage<UpdateInvoiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateInvoiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoiceRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "feePercent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "paymentCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "changeNotes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateInvoiceRequest {
    return new UpdateInvoiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateInvoiceRequest {
    return new UpdateInvoiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateInvoiceRequest {
    return new UpdateInvoiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateInvoiceRequest | PlainMessage<UpdateInvoiceRequest> | undefined, b: UpdateInvoiceRequest | PlainMessage<UpdateInvoiceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateInvoiceRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateInvoiceResponse
 */
export class UpdateInvoiceResponse extends Message<UpdateInvoiceResponse> {
  /**
   * @generated from field: protogen.Invoice invoice = 1;
   */
  invoice?: Invoice;

  constructor(data?: PartialMessage<UpdateInvoiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateInvoiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoice", kind: "message", T: Invoice },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateInvoiceResponse {
    return new UpdateInvoiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateInvoiceResponse {
    return new UpdateInvoiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateInvoiceResponse {
    return new UpdateInvoiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateInvoiceResponse | PlainMessage<UpdateInvoiceResponse> | undefined, b: UpdateInvoiceResponse | PlainMessage<UpdateInvoiceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateInvoiceResponse, a, b);
  }
}

