import * as React from "react";
import AuthService from "services/auth";
import { Navigate, useLocation } from "react-router-dom";

import { createContext } from "react";
import { CurrentUser } from "protogen/auth_pb";
export const CurrentUserContext = createContext<CurrentUser>(new CurrentUser());

const RequireAuth = ({
  children,
  loginRedirectPath,
}: {
  children: React.ReactNode;
  loginRedirectPath: string;
}) => {
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // Note that we will also add a redirect path to the url. This way if the user changes browsers
    // (common on mobile) they will still be redirected to the correct page.
    const hasDestination =
      !(
        location.pathname === "" ||
        location.pathname === "/" ||
        location.pathname === "/home"
      ) || location.search !== "";
    const redirectTo = hasDestination
      ? `${loginRedirectPath}?destination=${encodeURIComponent(
          location.pathname + location.search,
        )}`
      : loginRedirectPath;
    return (
      <Navigate
        to={redirectTo}
        state={{ from: location, ...location.state }}
        replace
      />
    );
  }
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default RequireAuth;
