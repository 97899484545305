export default async () => {
  // Check if the browser supports the required APIs
  if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
    console.log("This browser does not support push notifications.");
    return null;
  }

  try {
    // Register a service worker if not already registered
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;

    // Get the existing subscription, if any
    const existingSubscription =
      await serviceWorkerRegistration.pushManager.getSubscription();

    if (existingSubscription) {
      // console.log('User is already subscribed to push notifications: ', existingSubscription);
      return existingSubscription;
    } else {
      // console.log('User is not subscribed to push notifications.');
      return null;
    }
  } catch (error) {
    console.error(
      "An error occurred while checking subscription status:",
      error,
    );
    return null;
  }
};
