// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/messaging_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub } from "./common_pb.ts";
import { ContentUpdates, NoteContent } from "./notes_pb.ts";

/**
 * @generated from message protogen.MessageReaction
 */
export class MessageReaction extends Message<MessageReaction> {
  /**
   * @generated from field: string reaction = 1;
   */
  reaction = "";

  /**
   * @generated from field: protogen.AccountStub reactor = 2;
   */
  reactor?: AccountStub;

  constructor(data?: PartialMessage<MessageReaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MessageReaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reactor", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MessageReaction {
    return new MessageReaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MessageReaction {
    return new MessageReaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MessageReaction {
    return new MessageReaction().fromJsonString(jsonString, options);
  }

  static equals(a: MessageReaction | PlainMessage<MessageReaction> | undefined, b: MessageReaction | PlainMessage<MessageReaction> | undefined): boolean {
    return proto3.util.equals(MessageReaction, a, b);
  }
}

/**
 * @generated from message protogen.InboxEntry
 */
export class InboxEntry extends Message<InboxEntry> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.AccountStub sender = 2;
   */
  sender?: AccountStub;

  /**
   * @generated from field: repeated protogen.AccountStub otherParticipants = 3;
   */
  otherParticipants: AccountStub[] = [];

  /**
   * @generated from field: int64 timestampSec = 4;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: int32 unreadCount = 5;
   */
  unreadCount = 0;

  /**
   * @generated from field: string content = 6;
   */
  content = "";

  constructor(data?: PartialMessage<InboxEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InboxEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sender", kind: "message", T: AccountStub },
    { no: 3, name: "otherParticipants", kind: "message", T: AccountStub, repeated: true },
    { no: 4, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "unreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InboxEntry {
    return new InboxEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InboxEntry {
    return new InboxEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InboxEntry {
    return new InboxEntry().fromJsonString(jsonString, options);
  }

  static equals(a: InboxEntry | PlainMessage<InboxEntry> | undefined, b: InboxEntry | PlainMessage<InboxEntry> | undefined): boolean {
    return proto3.util.equals(InboxEntry, a, b);
  }
}

/**
 * @generated from message protogen.DirectMessage
 */
export class DirectMessage extends Message<DirectMessage> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string senderRef = 2;
   */
  senderRef = "";

  /**
   * @generated from field: int64 timestampSec = 3;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.NoteContent content = 4;
   */
  content?: NoteContent;

  /**
   * @generated from field: repeated protogen.MessageReaction reactions = 5;
   */
  reactions: MessageReaction[] = [];

  constructor(data?: PartialMessage<DirectMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DirectMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "senderRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "content", kind: "message", T: NoteContent },
    { no: 5, name: "reactions", kind: "message", T: MessageReaction, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DirectMessage {
    return new DirectMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DirectMessage {
    return new DirectMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DirectMessage {
    return new DirectMessage().fromJsonString(jsonString, options);
  }

  static equals(a: DirectMessage | PlainMessage<DirectMessage> | undefined, b: DirectMessage | PlainMessage<DirectMessage> | undefined): boolean {
    return proto3.util.equals(DirectMessage, a, b);
  }
}

/**
 * @generated from message protogen.FetchMessageInboxRequest
 */
export class FetchMessageInboxRequest extends Message<FetchMessageInboxRequest> {
  /**
   * @generated from field: string cursor = 1;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchMessageInboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchMessageInboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchMessageInboxRequest {
    return new FetchMessageInboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchMessageInboxRequest {
    return new FetchMessageInboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchMessageInboxRequest {
    return new FetchMessageInboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchMessageInboxRequest | PlainMessage<FetchMessageInboxRequest> | undefined, b: FetchMessageInboxRequest | PlainMessage<FetchMessageInboxRequest> | undefined): boolean {
    return proto3.util.equals(FetchMessageInboxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchMessageInboxResponse
 */
export class FetchMessageInboxResponse extends Message<FetchMessageInboxResponse> {
  /**
   * @generated from field: repeated protogen.InboxEntry entries = 1;
   */
  entries: InboxEntry[] = [];

  /**
   * @generated from field: string nextCursor = 2;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchMessageInboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchMessageInboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: InboxEntry, repeated: true },
    { no: 2, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchMessageInboxResponse {
    return new FetchMessageInboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchMessageInboxResponse {
    return new FetchMessageInboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchMessageInboxResponse {
    return new FetchMessageInboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchMessageInboxResponse | PlainMessage<FetchMessageInboxResponse> | undefined, b: FetchMessageInboxResponse | PlainMessage<FetchMessageInboxResponse> | undefined): boolean {
    return proto3.util.equals(FetchMessageInboxResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchMessageThreadRequest
 */
export class FetchMessageThreadRequest extends Message<FetchMessageThreadRequest> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * @generated from field: bool markConversationsRead = 2;
   */
  markConversationsRead = false;

  /**
   * @generated from field: string cursor = 3;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchMessageThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchMessageThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markConversationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchMessageThreadRequest {
    return new FetchMessageThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchMessageThreadRequest {
    return new FetchMessageThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchMessageThreadRequest {
    return new FetchMessageThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchMessageThreadRequest | PlainMessage<FetchMessageThreadRequest> | undefined, b: FetchMessageThreadRequest | PlainMessage<FetchMessageThreadRequest> | undefined): boolean {
    return proto3.util.equals(FetchMessageThreadRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchMessageThreadResponse
 */
export class FetchMessageThreadResponse extends Message<FetchMessageThreadResponse> {
  /**
   * @generated from field: repeated protogen.DirectMessage messages = 1;
   */
  messages: DirectMessage[] = [];

  /**
   * @generated from field: repeated protogen.AccountStub participants = 2;
   */
  participants: AccountStub[] = [];

  /**
   * @generated from field: string nextCursor = 3;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchMessageThreadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchMessageThreadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messages", kind: "message", T: DirectMessage, repeated: true },
    { no: 2, name: "participants", kind: "message", T: AccountStub, repeated: true },
    { no: 3, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchMessageThreadResponse {
    return new FetchMessageThreadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchMessageThreadResponse {
    return new FetchMessageThreadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchMessageThreadResponse {
    return new FetchMessageThreadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchMessageThreadResponse | PlainMessage<FetchMessageThreadResponse> | undefined, b: FetchMessageThreadResponse | PlainMessage<FetchMessageThreadResponse> | undefined): boolean {
    return proto3.util.equals(FetchMessageThreadResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetOrCreateMessageConversationRequest
 */
export class GetOrCreateMessageConversationRequest extends Message<GetOrCreateMessageConversationRequest> {
  /**
   * @generated from field: repeated string userRefs = 1;
   */
  userRefs: string[] = [];

  constructor(data?: PartialMessage<GetOrCreateMessageConversationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetOrCreateMessageConversationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "userRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrCreateMessageConversationRequest {
    return new GetOrCreateMessageConversationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrCreateMessageConversationRequest {
    return new GetOrCreateMessageConversationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrCreateMessageConversationRequest {
    return new GetOrCreateMessageConversationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrCreateMessageConversationRequest | PlainMessage<GetOrCreateMessageConversationRequest> | undefined, b: GetOrCreateMessageConversationRequest | PlainMessage<GetOrCreateMessageConversationRequest> | undefined): boolean {
    return proto3.util.equals(GetOrCreateMessageConversationRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetOrCreateMessageConversationResponse
 */
export class GetOrCreateMessageConversationResponse extends Message<GetOrCreateMessageConversationResponse> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  constructor(data?: PartialMessage<GetOrCreateMessageConversationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetOrCreateMessageConversationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrCreateMessageConversationResponse {
    return new GetOrCreateMessageConversationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrCreateMessageConversationResponse {
    return new GetOrCreateMessageConversationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrCreateMessageConversationResponse {
    return new GetOrCreateMessageConversationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrCreateMessageConversationResponse | PlainMessage<GetOrCreateMessageConversationResponse> | undefined, b: GetOrCreateMessageConversationResponse | PlainMessage<GetOrCreateMessageConversationResponse> | undefined): boolean {
    return proto3.util.equals(GetOrCreateMessageConversationResponse, a, b);
  }
}

/**
 * @generated from message protogen.SendMessageRequest
 */
export class SendMessageRequest extends Message<SendMessageRequest> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * @generated from field: protogen.ContentUpdates content = 2;
   */
  content?: ContentUpdates;

  constructor(data?: PartialMessage<SendMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendMessageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendMessageRequest {
    return new SendMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendMessageRequest {
    return new SendMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendMessageRequest {
    return new SendMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendMessageRequest | PlainMessage<SendMessageRequest> | undefined, b: SendMessageRequest | PlainMessage<SendMessageRequest> | undefined): boolean {
    return proto3.util.equals(SendMessageRequest, a, b);
  }
}

/**
 * @generated from message protogen.SendMessageResponse
 */
export class SendMessageResponse extends Message<SendMessageResponse> {
  /**
   * @generated from field: protogen.DirectMessage message = 1;
   */
  message?: DirectMessage;

  constructor(data?: PartialMessage<SendMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SendMessageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "message", T: DirectMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendMessageResponse {
    return new SendMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendMessageResponse {
    return new SendMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendMessageResponse {
    return new SendMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendMessageResponse | PlainMessage<SendMessageResponse> | undefined, b: SendMessageResponse | PlainMessage<SendMessageResponse> | undefined): boolean {
    return proto3.util.equals(SendMessageResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMessageReactionRequest
 */
export class UpdateMessageReactionRequest extends Message<UpdateMessageReactionRequest> {
  /**
   * @generated from field: string messageRef = 1;
   */
  messageRef = "";

  /**
   * @generated from field: string reaction = 2;
   */
  reaction = "";

  /**
   * @generated from field: bool isRemoval = 3;
   */
  isRemoval = false;

  constructor(data?: PartialMessage<UpdateMessageReactionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMessageReactionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messageRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reaction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isRemoval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMessageReactionRequest {
    return new UpdateMessageReactionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMessageReactionRequest {
    return new UpdateMessageReactionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMessageReactionRequest {
    return new UpdateMessageReactionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMessageReactionRequest | PlainMessage<UpdateMessageReactionRequest> | undefined, b: UpdateMessageReactionRequest | PlainMessage<UpdateMessageReactionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMessageReactionRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMessageReactionResponse
 */
export class UpdateMessageReactionResponse extends Message<UpdateMessageReactionResponse> {
  /**
   * @generated from field: repeated protogen.MessageReaction reactions = 1;
   */
  reactions: MessageReaction[] = [];

  constructor(data?: PartialMessage<UpdateMessageReactionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMessageReactionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reactions", kind: "message", T: MessageReaction, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMessageReactionResponse {
    return new UpdateMessageReactionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMessageReactionResponse {
    return new UpdateMessageReactionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMessageReactionResponse {
    return new UpdateMessageReactionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMessageReactionResponse | PlainMessage<UpdateMessageReactionResponse> | undefined, b: UpdateMessageReactionResponse | PlainMessage<UpdateMessageReactionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMessageReactionResponse, a, b);
  }
}

