import useProtoMethod from "./useProtoMethod";
import {
  CreateTaskRequest,
  CreateTaskResponse,
  EditTaskRequest,
  EditTaskResponse,
  GetTaskRequest,
  GetTaskResponse,
  ListTasksForAdvisorRequest,
  ListTasksForAdvisorResponse,
  ListTasksForMemberRequest,
  ListTasksForMemberResponse,
  ListTasksForFamilyRequest,
  ListTasksForFamilyResponse,
  UpdateBlockContentResponse,
  UpdateBlockContentRequest,
  UpdateBlockMetadataRequest,
  UpdateBlockMetadataResponse,
  CreateEmailDraftFromDocumentBlockRequest,
  CreateEmailDraftFromDocumentBlockResponse,
  GetTaskBlockRequest,
  GetTaskBlockResponse,
  CreateTaskBlockRequest,
  CreateTaskBlockResponse,
  UpdateTasksBookmarksResponse,
  UpdateTasksBookmarksRequest,
  ListAllTaskInspirationRequest,
  ListAllTaskInspirationResponse,
  CreateTaskInspirationRequest,
  CreateTaskInspirationResponse,
  UpdateTaskInspirationRequest,
  UpdateTaskInspirationResponse,
  GetMemberTaskResponse,
  GetMemberTaskRequest,
  UpdateTaskBlockStatusRequest,
  UpdateTaskBlockStatusResponse,
  ListAllTaskInspirationCategoriesRequest,
  ListAllTaskInspirationCategoriesResponse,
  CreateTaskInspirationCategoryRequest,
  CreateTaskInspirationCategoryResponse,
  EditTaskInspirationCategoryRequest,
  EditTaskInspirationCategoryResponse,
} from "protogen/tasks_service_pb";

const useCreateTask = (callback?: (r: CreateTaskResponse) => void) => {
  return useProtoMethod<CreateTaskRequest, CreateTaskResponse>(
    "CreateTask",
    CreateTaskResponse,
    { callback },
  );
};

const useCreateMemberTask = (callback?: (r: CreateTaskResponse) => void) => {
  return useProtoMethod<CreateTaskRequest, CreateTaskResponse>(
    "CreateMemberTask",
    CreateTaskResponse,
    { callback },
  );
};

const useUpdateTask = (callback?: (r: EditTaskResponse) => void) => {
  return useProtoMethod<EditTaskRequest, EditTaskResponse>(
    "EditTask",
    EditTaskResponse,
    { callback },
  );
};

const useUpdateMemberTask = (callback?: (r: EditTaskResponse) => void) => {
  return useProtoMethod<EditTaskRequest, EditTaskResponse>(
    "EditMemberTask",
    EditTaskResponse,
    { callback },
  );
};

const useUpdateBlockContent = (
  callback?: (r: UpdateBlockContentResponse) => void,
) => {
  return useProtoMethod<UpdateBlockContentRequest, UpdateBlockContentResponse>(
    "UpdateBlockContent",
    UpdateBlockContentResponse,
    { callback },
  );
};

const useUpdateMemberBlockContent = (
  callback?: (r: UpdateBlockContentResponse) => void,
) => {
  return useProtoMethod<UpdateBlockContentRequest, UpdateBlockContentResponse>(
    "UpdateMemberBlockContent",
    UpdateBlockContentResponse,
    { callback },
  );
};

const useUpdateBlockMetadata = (
  callback?: (r: UpdateBlockMetadataResponse) => void,
) => {
  return useProtoMethod<
    UpdateBlockMetadataRequest,
    UpdateBlockMetadataResponse
  >("UpdateBlockMetadata", UpdateBlockMetadataResponse, { callback });
};

const useCreateEmailDraftFromDocumentBlock = (
  callback?: (r: CreateEmailDraftFromDocumentBlockResponse) => void,
) => {
  return useProtoMethod<
    CreateEmailDraftFromDocumentBlockRequest,
    CreateEmailDraftFromDocumentBlockResponse
  >(
    "CreateEmailDraftFromDocumentBlock",
    CreateEmailDraftFromDocumentBlockResponse,
    { callback },
  );
};

const useGetTask = (callback?: (r: GetTaskResponse) => void) => {
  return useProtoMethod<GetTaskRequest, GetTaskResponse>(
    "GetTask",
    GetTaskResponse,
    { callback },
  );
};

const useGetMemberTask = (callback?: (r: GetMemberTaskResponse) => void) => {
  return useProtoMethod<GetMemberTaskRequest, GetMemberTaskResponse>(
    "GetMemberTask",
    GetMemberTaskResponse,
    { callback },
  );
};

const useGetTaskBlock = (callback?: (r: GetTaskBlockResponse) => void) => {
  return useProtoMethod<GetTaskBlockRequest, GetTaskBlockResponse>(
    "GetTaskBlock",
    GetTaskBlockResponse,
    { callback },
  );
};

const useGetMemberTaskBlock = (
  callback?: (r: GetTaskBlockResponse) => void,
) => {
  return useProtoMethod<GetTaskBlockRequest, GetTaskBlockResponse>(
    "GetMemberTaskBlock",
    GetTaskBlockResponse,
    { callback },
  );
};

const useCreateTaskBlock = (
  callback?: (r: CreateTaskBlockResponse) => void,
) => {
  return useProtoMethod<CreateTaskBlockRequest, CreateTaskBlockResponse>(
    "CreateTaskBlock",
    CreateTaskBlockResponse,
    { callback },
  );
};

const useCreateMemberTaskBlock = (
  callback?: (r: CreateTaskBlockResponse) => void,
) => {
  return useProtoMethod<CreateTaskBlockRequest, CreateTaskBlockResponse>(
    "CreateMemberTaskBlock",
    CreateTaskBlockResponse,
    { callback },
  );
};

const useListTasksForFamily = (
  callback?: (r: ListTasksForFamilyResponse) => void,
) => {
  return useProtoMethod<ListTasksForFamilyRequest, ListTasksForFamilyResponse>(
    "ListTasksForFamily",
    ListTasksForFamilyResponse,
    { callback },
  );
};

const useListTasksForAdvisor = (
  callback?: (r: ListTasksForAdvisorResponse) => void,
) => {
  return useProtoMethod<
    ListTasksForAdvisorRequest,
    ListTasksForAdvisorResponse
  >("ListTasksForAdvisor", ListTasksForAdvisorResponse, { callback });
};

const useListTasksForMember = (
  callback?: (r: ListTasksForMemberResponse) => void,
) => {
  return useProtoMethod<ListTasksForMemberRequest, ListTasksForMemberResponse>(
    "ListTasksForMember",
    ListTasksForMemberResponse,
    { callback },
  );
};

const useUpdateTasksBookmarks = (
  callback?: (r: UpdateTasksBookmarksResponse) => void,
) => {
  return useProtoMethod<
    UpdateTasksBookmarksRequest,
    UpdateTasksBookmarksResponse
  >("UpdateTasksBookmarks", UpdateTasksBookmarksResponse, { callback });
};

const useListAllTaskInspirations = (
  callback?: (r: ListAllTaskInspirationResponse) => void,
) => {
  return useProtoMethod<
    ListAllTaskInspirationRequest,
    ListAllTaskInspirationResponse
  >("ListAllTaskInspirations", ListAllTaskInspirationResponse, { callback });
};

const useListAllMemberTaskInspirationCategories = (
  callback?: (r: ListAllTaskInspirationCategoriesResponse) => void,
) => {
  return useProtoMethod<
    ListAllTaskInspirationCategoriesRequest,
    ListAllTaskInspirationCategoriesResponse
  >(
    "ListAllMemberTaskInspirationCategories",
    ListAllTaskInspirationCategoriesResponse,
    { callback },
  );
};

const useListAllMemberTaskDiscoveryInspirationCategories = (
  callback?: (r: ListAllTaskInspirationCategoriesResponse) => void,
) => {
  return useProtoMethod<
    ListAllTaskInspirationCategoriesRequest,
    ListAllTaskInspirationCategoriesResponse
  >(
    "ListAllMemberTaskDiscoveryInspirationCategories",
    ListAllTaskInspirationCategoriesResponse,
    { callback },
  );
};

const useListAllTaskInspirationCategories = (
  callback?: (r: ListAllTaskInspirationCategoriesResponse) => void,
) => {
  return useProtoMethod<
    ListAllTaskInspirationCategoriesRequest,
    ListAllTaskInspirationCategoriesResponse
  >(
    "ListAllTaskInspirationCategories",
    ListAllTaskInspirationCategoriesResponse,
    { callback },
  );
};

const useListAllMemberTaskInspirations = (
  callback?: (r: ListAllTaskInspirationResponse) => void,
) => {
  return useProtoMethod<
    ListAllTaskInspirationRequest,
    ListAllTaskInspirationResponse
  >("ListAllMemberTaskInspirations", ListAllTaskInspirationResponse, {
    callback,
  });
};

const useCreateTaskInspiration = (
  callback?: (r: CreateTaskInspirationResponse) => void,
) => {
  return useProtoMethod<
    CreateTaskInspirationRequest,
    CreateTaskInspirationResponse
  >("CreateTaskInspiration", CreateTaskInspirationResponse, { callback });
};

const useUpdateTaskInspiration = (
  callback?: (r: UpdateTaskInspirationResponse) => void,
) => {
  return useProtoMethod<
    UpdateTaskInspirationRequest,
    UpdateTaskInspirationResponse
  >("UpdateTaskInspiration", UpdateTaskInspirationResponse, { callback });
};

const useUpdateTaskBlockStatusRequest = (
  callback?: (r: UpdateTaskBlockStatusResponse) => void,
) => {
  return useProtoMethod<
    UpdateTaskBlockStatusRequest,
    UpdateTaskBlockStatusResponse
  >("UpdateTaskBlockStatus", UpdateTaskBlockStatusResponse, { callback });
};

const useUpdateMemberTaskBlockStatusRequest = (
  callback?: (r: UpdateTaskBlockStatusResponse) => void,
) => {
  return useProtoMethod<
    UpdateTaskBlockStatusRequest,
    UpdateTaskBlockStatusResponse
  >("UpdateMemberTaskBlockStatus", UpdateTaskBlockStatusResponse, { callback });
};

const useCreateTaskInspirationCategory = (
  callback?: (r: CreateTaskInspirationCategoryResponse) => void,
) => {
  return useProtoMethod<
    CreateTaskInspirationCategoryRequest,
    CreateTaskInspirationCategoryResponse
  >("CreateTaskInspirationCategory", CreateTaskInspirationCategoryResponse, {
    callback,
  });
};

const useEditTaskInspirationCategory = (
  callback?: (r: EditTaskInspirationCategoryResponse) => void,
) => {
  return useProtoMethod<
    EditTaskInspirationCategoryRequest,
    EditTaskInspirationCategoryResponse
  >("EditTaskInspirationCategory", EditTaskInspirationCategoryResponse, {
    callback,
  });
};

export {
  useCreateTask,
  useCreateMemberTask,
  useGetTask,
  useGetMemberTask,
  useUpdateTask,
  useUpdateBlockContent,
  useUpdateMemberBlockContent,
  useUpdateBlockMetadata,
  useCreateEmailDraftFromDocumentBlock,
  useListTasksForFamily,
  useListTasksForAdvisor,
  useListTasksForMember,
  useGetTaskBlock,
  useGetMemberTaskBlock,
  useCreateTaskBlock,
  useCreateMemberTaskBlock,
  useUpdateTasksBookmarks,
  useListAllTaskInspirations,
  useListAllMemberTaskInspirations,
  useCreateTaskInspiration,
  useUpdateTaskInspiration,
  useUpdateMemberTask,
  useUpdateTaskBlockStatusRequest,
  useUpdateMemberTaskBlockStatusRequest,
  useListAllTaskInspirationCategories,
  useCreateTaskInspirationCategory,
  useListAllMemberTaskInspirationCategories,
  useListAllMemberTaskDiscoveryInspirationCategories,
  useEditTaskInspirationCategory,
};
