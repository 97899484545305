import React, { useEffect, useState } from "react";
import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  getIndex(id: UniqueIdentifier): number;
  value: any;
  disabled?: boolean;
}

export const SortableItem = ({
  id,
  index,
  value,
  disabled,
}: SortableItemProps) => {
  const { setNodeRef, listeners, isDragging, transform, transition } =
    useSortable({ id, disabled: disabled || false });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);

  const mountedWhileDragging = isDragging && !isMounted;

  return (
    <Item
      ref={setNodeRef}
      value={value}
      index={index}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
    />
  );
};

export interface ItemProps {
  dragOverlay?: boolean;
  color?: string;
  handle?: boolean;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
}
export const Item = React.memo(
  React.forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        color,
        dragOverlay,
        fadeIn,
        handle,
        index,
        listeners,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      const cssTransform = transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : undefined;

      return (
        <div
          style={
            {
              width: "100%",
              transform: cssTransform,
              transformOrigin: "0 0",
              touchAction: "manipulation",
              animation: fadeIn ? "fadeIn 500ms ease" : "none",
              ...wrapperStyle,
              transition: [transition, "background-color 350ms ease"]
                .filter(Boolean)
                .join(", "),
              "--index": index,
              "--color": color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            style={{
              position: "relative",
              transformOrigin: "50% 50%",
              transform: "scale(var(--scale, 1))",
              transition:
                "box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)",

              ...style,
            }}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {value}
          </div>
        </div>
      );
    },
  ),
);
