import { Filter } from "./types";

import { Box } from "@mui/system";
import { useListFamilies } from "../creation/FamilySelect";
import { TIME_RANGES } from "../creation/TimeRangeSelect";
import React, { useContext, useEffect, useState } from "react";
import CheckedDropDown from "../common/CheckedDropDown";
import { Family } from "../family/types";
import { CurrentUserContext } from "../context/RequireAuth";
import { ReactComponent as CheckboxIcon } from "../../icons/Checkbox.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../icons/CheckboxSelected.svg";
import { Typography } from "@mui/material";
import { ROLE_ADMIN } from "../../common/userUtils";
import useIsMobile from "../hooks/useIsMobile";

const makeFamilyOptions = (families: Family[]) => {
  const options = families.map((f: Family) => ({
    label: f.name,
    value: f.ref || "",
    mutuallyExclusive: true,
  }));
  options.unshift({
    label: "All Families",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

const makeContentOptions = () => {
  return [
    {
      label: "Texts",
      value: "textmessage",
      mutuallyExclusive: true,
    },
    {
      label: "Emails",
      value: "email",
      mutuallyExclusive: true,
    },
    {
      label: "Phone calls",
      value: "phonecall",
      mutuallyExclusive: true,
    },
  ];
};

type Props = {
  filters: Filter[];
  setFilters: (f: Filter[]) => void;
  updateAdminSearch: (b: boolean) => void;
  loading: boolean;
};

export default ({ filters, setFilters, updateAdminSearch, loading }: Props) => {
  const isMobile = useIsMobile();
  const currentUser = useContext(CurrentUserContext);
  const [admin, setAdmin] = useState(false);
  const { data, request } = useListFamilies(admin);

  const domainLimited =
    filters.filter((f) => f.field === "entity_type").length > 0;

  const getFieldValue = (field: string): string | null => {
    return filters.find((f) => f.field === field)?.value || null;
  };
  useEffect(() => {
    request();
  }, [admin]);
  const setOrUpdateFilter = (
    field: string,
    value: string | null,
    operator: string,
    presentationValue?: string,
  ) => {
    const existingFilter = filters.find((f) => f.field === field);
    if (existingFilter && value !== null) {
      existingFilter.value = value;
      existingFilter.operator = operator;
      existingFilter.presentationValue = presentationValue;
      setFilters([...filters]);
    } else if (value == null) {
      setFilters(filters.filter((f) => f.field !== field));
    } else {
      filters.push({ field, value, operator, presentationValue });
      setFilters([...filters]);
    }
  };
  return (
    <Box display={"flex"} flexDirection={"row"} gap={"12px"}>
      <CheckedDropDown
        disabled={loading}
        radio={true}
        options={Object.values(TIME_RANGES).map((pt) => ({
          label: pt.label,
          value: pt.value || "",
          mutuallyExclusive: true,
        }))}
        sx={{ width: isMobile ? "50%" : "", minWidth: "120px" }}
        displayValue={
          Object.values(TIME_RANGES).find(
            (t) => t.value === getFieldValue("time_range"),
          )?.label || "Date"
        }
        onChange={(r) =>
          r.length === 1 &&
          setOrUpdateFilter("time_range", r[0].value || null, "GREATER_THAN")
        }
      />
      <CheckedDropDown
        disabled={loading || !data}
        radio={true}
        options={makeFamilyOptions(data?.families || [])}
        sx={{ width: isMobile ? "50%" : "", minWidth: "120px" }}
        maxDropdownHeight={"343px"}
        displayValue={
          (data?.families || []).find((f) => f.ref === getFieldValue("family"))
            ?.name || "Family"
        }
        onChange={(f) =>
          f.length === 1 &&
          setOrUpdateFilter("family", f[0].value || null, "EQUALS")
        }
      />
      {!domainLimited && (
        <CheckedDropDown
          disabled={loading || !data}
          noDecoration={true}
          options={makeContentOptions()}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={"Source"}
          onChange={(f) =>
            f.length === 1 &&
            setOrUpdateFilter(
              "entity_type",
              f[0].value || null,
              "CONTAINED_IN",
              f[0].label,
            )
          }
        />
      )}
      {!isMobile && currentUser.roles.includes(ROLE_ADMIN) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            padding: "8px 18px",
            borderRadius: "100px",
            border: "1px solid #D4D4D4",
            background: "#FFF",
            cursor: "pointer",
          }}
          onClick={() => {
            setAdmin((a) => !a);
            updateAdminSearch(!admin);
          }}
        >
          {admin ? (
            <CheckboxSelectedIcon height={18} width={18} />
          ) : (
            <CheckboxIcon height={18} width={18} />
          )}
          <Typography variant="body" color="#6B6E7B">
            Admin mode
          </Typography>
        </Box>
      )}
    </Box>
  );
};
