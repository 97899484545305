import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { ReactNode, useRef } from "react";
import { SpeedDialActionProps } from "@mui/material/SpeedDialAction/SpeedDialAction";
import { Backdrop } from "@mui/material";
import useIsMobile from "../hooks/useIsMobile";
import useOutsideClick from "../hooks/useOutsideClick";

interface UploadSpeedDialActionProps extends SpeedDialActionProps {
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const UploadSpeedDialAction = ({
  disabled,
  onChange,
  ...otherProps
}: UploadSpeedDialActionProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <React.Fragment>
      <input
        ref={inputRef}
        multiple
        disabled={disabled}
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
        id="icon-button-file"
      />
      <SpeedDialAction
        onClick={() => inputRef.current && inputRef.current.click()}
        {...otherProps}
      ></SpeedDialAction>
    </React.Fragment>
  );
};

export default ({
  disabled = false,
  children,
}: {
  disabled?: boolean;
  children: ReactNode;
}) => {
  const isMobile = useIsMobile();
  const ref = useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useOutsideClick(ref, () => {
    if (open) {
      handleClose();
    }
  });
  return (
    <>
      {isMobile && <Backdrop open={open && !disabled} />}
      <Box
        ref={ref}
        sx={{
          position: "absolute",
          left: "0px",
          bottom: "10px",
          transform: "translateZ(0px)",
          flexGrow: 1,
          ".MuiSpeedDialAction-staticTooltipLabel": {
            textWrap: "nowrap",
            // Specific to mobile safari
            whiteSpace: "nowrap",
          },
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          FabProps={{
            sx: {
              backgroundColor: "#bdbdbd",
              boxShadow: "none",
              minHeight: "24px",
              height: "24px",
              width: "24px",
            },
          }}
          sx={{
            ".MuiSpeedDial-actionsClosed": {
              // Note(Kip): Need to adjust the height on the options when the menu is closed otherwise it's
              // click area can extend beyond the button blocking other actions. Seems like a common problem, but
              // I didn't find a common solution and this seems safe enough.
              height: "24px",
            },
          }}
          icon={<SpeedDialIcon />}
          open={open && !disabled}
          onClick={() => {
            if (open) {
              handleClose();
            } else {
              handleOpen();
            }
          }}
        >
          {children}
        </SpeedDial>
      </Box>
    </>
  );
};
