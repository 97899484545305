import AddressCompletion, {
  AutocompleteEntry,
  Handle,
} from "./AddressCompletion";
import { useRef } from "react";
import { Box, Chip, Typography, SxProps, Tooltip } from "@mui/material";

type Props = {
  title?: string;
  recipients: string[];
  setRecipients: (r: string[]) => void;
  autocompleteEntries?: AutocompleteEntry[];
  placeholder?: string;
  sx?: SxProps;
  fullWidthInput?: boolean;
  autofocus?: boolean;
};
export default ({
  title,
  recipients,
  setRecipients,
  autocompleteEntries,
  placeholder,
  sx,
  fullWidthInput = false,
  autofocus = false,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const completionRef = useRef<Handle | null>(null);
  const emailNameMap = new Map<string, string>(
    autocompleteEntries?.map((e) => [e.email.toLowerCase(), e.name]) || [],
  );
  const parentIDRef = useRef(
    `autocomplete-entry-${title}-${Math.random().toString(36).substr(2, 9)}`,
  );
  return (
    <Box
      id={parentIDRef.current}
      ref={divRef}
      onClick={(e) => {
        if (e.target === divRef.current && completionRef.current) {
          completionRef.current?.setFocus();
        }
      }}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
        cursor: "text",
        gap: "6px",
        ...sx,
      }}
    >
      {title && (
        <Typography variant="caption" sx={{ width: "20px" }}>
          {title}
        </Typography>
      )}
      {recipients.map((e, index) => {
        return (
          <Tooltip title={e.toLowerCase()} key={index}>
            <Chip
              key={index}
              label={emailNameMap.get(e.toLowerCase()) || e}
              style={{ fontSize: "10px" }}
              onDelete={() => {
                setRecipients(recipients.filter((_, i) => i !== index));
              }}
            />
          </Tooltip>
        );
      })}
      <AddressCompletion
        ref={completionRef}
        autocompleteEntries={autocompleteEntries || []}
        addAddresses={(a) => setRecipients([...recipients, ...a])}
        usedAddresses={new Set(recipients || [])}
        placeholder={placeholder}
        anchorID={parentIDRef.current}
        fullWidthInput={fullWidthInput}
        autofocus={autofocus}
      />
    </Box>
  );
};
