import React, { MouseEvent, useState } from "react";
import {
  Popper,
  ClickAwayListener,
  Box,
  Typography,
  Fade,
} from "@mui/material";
import { Editor } from "@tiptap/react";
import { Baseline } from "lucide-react"; // Make sure you import the Editor type from TipTap
import EditIcon from "./EditIcon";

// ColorPalette component: Displays the color options
interface ColorPaletteProps {
  editor: Editor;
  handleClose: () => void;
}

const ColorPalette = ({ editor, handleClose }: ColorPaletteProps) => {
  const textColors: string[] = [
    "#000000",
    "#198282",
    "#EF7B77",
    "#996A8A",
    "#D56887",
    "#FFF",
    "#6092A8",
    "#F59F51",
    "#468E3E",
    "#C14743",
  ];
  const highlightColors: string[] = [
    "#000000",
    "#D0F1EB",
    "#FDD7C6",
    "#F0DDEA",
    "#FFC3D4",
    "#FFF",
    "#BBDBE9",
    "#FEF1C1",
    "#D7ECD4",
    "#FFB8B6",
  ];

  const selectTextColor = (e: MouseEvent, color: string) => {
    editor.chain().focus().setColor(color).run();
    e.stopPropagation();
    e.preventDefault();
    handleClose();
  };
  const selectHighlightColor = (e: MouseEvent, color: string) => {
    editor.chain().focus().toggleHighlight({ color }).run();
    e.stopPropagation();
    e.preventDefault();
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        border: "1px solid #D4D4D4",
        borderRadius: "8px",
        backgroundColor: "#FFF",
        padding: "10px",
      }}
    >
      {/* Text Color Section */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="bodyHeavy">Text</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 16px)",
            gap: "2px",
            mt: "4px",
          }}
        >
          {textColors.map((color) => (
            <Box
              key={color}
              onClick={(e) => selectTextColor(e, color)}
              sx={{
                backgroundColor: color,
                width: "16px",
                height: "16px",
                cursor: "pointer",
                border: editor.isActive("textStyle", { color })
                  ? "1.5px solid black"
                  : "none",
                ":hover": {
                  border: "1.5px solid black",
                },
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Highlight Color Section */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="bodyHeavy">Highlight</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 16px)",
            gap: "2px",
            mt: "4px",
          }}
        >
          {highlightColors.map((color) => (
            <Box
              key={color}
              onClick={(e) => selectHighlightColor(e, color)}
              sx={{
                backgroundColor: color,
                width: "16px",
                height: "16px",
                cursor: "pointer",
                border: editor.isActive("highlight", { color: color })
                  ? "1.5px solid black"
                  : "none",
                ":hover": {
                  border: "1.5px solid black",
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

interface TextColorButtonProps {
  editor: Editor;
}

export default ({ editor }: TextColorButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Anchor element for the Popper
  const [open, setOpen] = useState(false); // State to toggle Popper visibility

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const active = editor.isActive("textStyle") || editor.isActive("highlight");
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <EditIcon
          IconComponent={Baseline}
          onClick={handleClick}
          disabled={!editor.can().chain().focus().run()}
          isActive={open || active}
          className={open || active ? "is-active" : ""}
        />
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="top"
          sx={{ zIndex: 1302 }}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 10], // Move up by 10 pixels
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <ColorPalette editor={editor} handleClose={handleClose} />
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
