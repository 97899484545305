import { RetrievalResult } from "protogen/search_service_pb";
import DateDisplay from "../common/DateDisplay";
import { iconForResult, urlForResult } from "./utils";
import LinkRouter from "../navigation/LinkRouter";
import { Box } from "@mui/system";

type Props = {
  result: RetrievalResult;
  onClose: () => void;
};

export default ({ result, onClose }: Props) => {
  const entryDt = new Date(Number(result.indexedSec) * 1000);
  return (
    <LinkRouter to={urlForResult(result)} onClick={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          cursor: "pointer",
          borderWidth: "1px",
          borderRadius: "12px",
          borderColor: "#D4D4D4",
          borderStyle: "solid",
          alignItems: "flex-start",
          padding: "24px 32px",
        }}
      >
        <Box sx={{ minWidth: "unset", margin: "8px 16px 8px 8px" }}>
          {iconForResult(result)}
        </Box>
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <div style={{ fontSize: "14px", display: "flex" }}>
              <b>{result.sourceName}</b>
              <span style={{ margin: "0px 4px" }}> - </span>
              {<DateDisplay date={entryDt} sx={{ fontSize: "14px" }} />}
            </div>
          </Box>
          <Box sx={{ wordBreak: "break-word" }}>{result.text}</Box>
        </Box>
      </Box>
    </LinkRouter>
  );
};
