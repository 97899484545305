import { Chip, Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { SxProps } from "@mui/system";
import { XMarkIcon } from "@heroicons/react/24/outline";

const TOPICS_CATEGORY = [
  ["Plumbing", "Home Services"],
  ["Electrical Work", "Home Services"],
  ["Landscaping", "Home Services"],
  ["House Cleaning", "Home Services"],
  ["Pest Control", "Home Services"],
  ["Home Security", "Home Services"],
  ["HVAC Services", "Home Services"],
  ["Interior Design", "Home Services"],
  ["Roofing", "Home Services"],
  ["Handyman Services", "Home Services"],
  ["Educational Toys", "Kids"],
  ["Childcare Services", "Kids"],
  ["Children's Clothing", "Kids"],
  ["Pediatric Healthcare", "Kids"],
  ["Extracurricular Activities", "Kids"],
  ["Tutoring Services", "Kids"],
  ["Birthday Party Venues", "Kids"],
  ["Child Safety Products", "Kids"],
  ["Kid-Friendly Apps/Games", "Kids"],
  ["School Supplies", "Kids"],
  ["Flight Booking", "Travel"],
  ["Hotel Accommodation", "Travel"],
  ["Travel Insurance", "Travel"],
  ["Car Rentals", "Travel"],
  ["Tour Packages", "Travel"],
  ["Travel Gear", "Travel"],
  ["Destination Guides", "Travel"],
  ["Cruise Services", "Travel"],
  ["Travel Health Advice", "Travel"],
  ["Adventure Travel", "Travel"],
  ["Catering Services", "Parties"],
  ["Party Venues", "Parties"],
  ["Event Planning", "Parties"],
  ["Entertainment Services", "Parties"],
  ["Decorations", "Parties"],
  ["Party Favors", "Parties"],
  ["Photography Services", "Parties"],
  ["DJ/Music Services", "Parties"],
  ["Costume Rentals", "Parties"],
  ["Invitations", "Parties"],
  ["Holiday Decorations", "Seasonal"],
  ["Seasonal Clothing", "Seasonal"],
  ["Seasonal Foods & Recipes", "Seasonal"],
  ["Gift Ideas", "Seasonal"],
  ["Seasonal Sales & Promotions", "Seasonal"],
  ["Weather-Related Gear", "Seasonal"],
  ["Festive Events", "Seasonal"],
  ["Gardening & Landscaping (Seasonal)", "Seasonal"],
  ["Seasonal Home Maintenance", "Seasonal"],
  ["Holiday-specific Services", "Seasonal"],
  ["Fitness Classes", "Activities"],
  ["Hiking/Biking Trails", "Activities"],
  ["Art & Craft Workshops", "Activities"],
  ["Cooking Classes", "Activities"],
  ["Music Lessons", "Activities"],
  ["Book Clubs", "Activities"],
  ["Yoga/Meditation Retreats", "Activities"],
  ["Sports Leagues", "Activities"],
  ["Museum Exhibits", "Activities"],
  ["Theater Shows", "Activities"],
  ["Grocery Stores", "Local Services"],
  ["Local Restaurants", "Local Services"],
  ["Automotive Services", "Local Services"],
  ["Health Clinics", "Local Services"],
  ["Legal Services", "Local Services"],
  ["Laundry Services", "Local Services"],
  ["Banks & ATMs", "Local Services"],
  ["Hair Salons/Barbershops", "Local Services"],
  ["Pet Services", "Local Services"],
  ["Postal/Courier Services", "Local Services"],
];

export default ({
  setSelected,
  initialSelected,
  sx,
}: {
  initialSelected?: string[];
  setSelected: (s: string[]) => void;
  sx?: SxProps;
}) => {
  const [init] = useState(initialSelected);
  const topics = TOPICS_CATEGORY.map((t) => t[0]).sort();
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const minChars = 0; // Minimum number of characters to open dropdown

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= minChars && !open) {
      setOpen(true);
    }
    if (newInputValue.length < minChars && open) {
      setOpen(false);
    }
  };
  return (
    <Autocomplete
      sx={{ ...sx }}
      size="small"
      multiple
      // fullWidth
      disableClearable
      filterSelectedOptions
      limitTags={3}
      options={topics}
      defaultValue={init}
      freeSolo
      open={open}
      onOpen={() => {
        if (inputValue.length >= minChars) {
          setOpen(true);
        }
      }}
      onChange={(_, val) => {
        if (!val) return;
        setSelected(val);
      }}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            deleteIcon={<XMarkIcon height={20} width={20} stroke="#198282" />}
            sx={{
              borderRadius: "100px",
              background: "#E9F3F3",
              span: {
                color: "#198282",
                fontWeight: "600",
              },
            }}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => <TextField {...params} placeholder="Topics" />}
    />
  );
};
