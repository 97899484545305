import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Loading from "components/common/Loading";
import useIsMobile from "components/hooks/useIsMobile";
import {
  sortFamiliesByFields,
  ColumnHeader,
  FamilyReportRow,
} from "components/engagement/ReportTable";
import { useFetchAdvisorEngagementReports } from "services/engagement";
import AddFamilyDialog from "components/family/AddFamilyDialog";
import { ReactComponent as PlusIcon } from "../icons/Plus.svg";
import useTitle from "components/hooks/useTitle";

export default () => {
  const isMobile = useIsMobile();
  useTitle("Families");
  const [modalOpen, setModalOpen] = useState(false);
  const [sorts, setSorts] = useState<{ field: string; asc: boolean }[]>([
    // Note the order is how they will be applied, so the first entry is the primary sort.
    { field: "state", asc: true },
    { field: "familyName", asc: true },
  ]);
  const { request, data, loading } = useFetchAdvisorEngagementReports();
  useEffect(() => {
    request();
  }, []);
  const allReports = sortFamiliesByFields(data?.reports || [], sorts);
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Families</Typography>
            <Button startIcon={<PlusIcon />} onClick={() => setModalOpen(true)}>
              Add Family
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={"0 24px"}
          mr="34px"
        >
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Family"
            sortKey="familyName"
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Advisor"
            sortKey="advisorName"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Last activity"
            sortKey="lastActivity"
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Utilization"
            sortKey="utilization"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Renewal"
            sortKey="renewal"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Start date"
            sortKey="startDate"
            hideMobile
          />
        </Box>
        {loading ? (
          <Loading />
        ) : allReports.length > 0 ? (
          allReports.map((report) => (
            <FamilyReportRow key={report.family.ref} report={report} />
          ))
        ) : (
          <Box>No families added yet.</Box>
        )}
      </Box>
      <AddFamilyDialog open={modalOpen} onClose={() => setModalOpen(false)} />
    </Box>
  );
};
