import {
  Box,
  Typography,
  Divider,
  ButtonGroup,
  Button,
  IconButton,
} from "@mui/material";
import { ReactComponent as EllipseIcon } from "../icons/Menu/Ellipse.svg";
import FlexPage from "components/layout/FlexPage";
import EmailThread from "components/email/EmailThread";
import MessageThread from "components/phone/MessageThread";
import DirectMessagingThread from "components/messaging/DirectMessagingThread";
import EmailInbox from "components/inbox/EmailInbox";
import PhoneInbox from "components/inbox/PhoneInbox";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { StatusContext } from "components/context/StatusContextProvider";
import useIsMobile from "components/hooks/useIsMobile";
import MessagesInbox from "components/inbox/MessagesInbox";
import { InboxHandle } from "components/inbox/utils";
import { usePushContext } from "components/context/PushContextProvider";
import { Search, SquarePen } from "lucide-react";
import {
  emailOnlyFilter,
  generateSearchQueryUrlWithFilters,
  textsOnlyFilter,
} from "components/search/utils";
import useTitle from "components/hooks/useTitle";

export default ({ create = false }: { create?: boolean }) => {
  const { emailUnreadCount, phoneUnreadCount, messageUnreadCount } =
    useContext(StatusContext);
  const navigate = useNavigate();
  const { registerHandler, unregisterHandler } = usePushContext();
  let params = useParams();
  const [inboxType, setInboxType] = useState(params.inboxType || "messages");
  const [isNewMessageConversation, setIsNewMessageConversation] =
    useState(false);
  const messagesInboxRef = useRef<InboxHandle>(null);
  const emailInboxRef = useRef<InboxHandle>(null);
  const phoneInboxRef = useRef<InboxHandle>(null);
  const [inboxRef, setInboxRef] = useState<string | undefined>(undefined);
  const isMobile = useIsMobile();
  const onEntrySelect = (inboxType: string, inboxRef: string) => {
    navigate(`/inbox/${inboxType}/${encodeURIComponent(inboxRef)}`);
  };
  useTitle("Inbox");

  const onInboxSelect = (inboxType: string) => {
    navigate(`/inbox/${inboxType}`);
  };

  useEffect(() => {
    setInboxType(params.inboxType || "messages");
    setInboxRef(params.ref);
  }, [params]);

  useEffect(() => {
    const pushHandler = () => {
      if (inboxType === "email") emailInboxRef.current?.triggerRefresh();
      if (inboxType === "messages") messagesInboxRef.current?.triggerRefresh();
      if (inboxType === "phone") phoneInboxRef.current?.triggerRefresh();
    };
    registerHandler(pushHandler);

    return () => {
      unregisterHandler(pushHandler);
    };
  }, [inboxType, registerHandler, unregisterHandler]);

  const threadModule = () => {
    if (!inboxType) return;
    if (inboxType === "email" && inboxRef) {
      return (
        <EmailThread
          emailRef={inboxRef || ""}
          onThreadUpdates={() => emailInboxRef.current?.triggerRefresh()}
        />
      );
    } else if (inboxType === "messages") {
      return (
        <DirectMessagingThread
          key={inboxRef}
          conversationRef={inboxRef}
          onNewMessage={() => messagesInboxRef.current?.triggerRefresh()}
          isNewConversation={isNewMessageConversation}
        />
      );
    } else if (
      inboxType === "phone" &&
      (inboxRef || isNewMessageConversation || create)
    ) {
      return (
        <MessageThread
          entryRef={inboxRef}
          onUpdates={() => phoneInboxRef.current?.triggerRefresh()}
          isNewConversation={isNewMessageConversation || create}
        />
      );
    }
  };
  const compose = () => {
    if (inboxType === "email") {
      emailInboxRef.current?.triggerCompose();
    } else if (inboxType === "messages") {
      if (!isMobile) {
        setIsNewMessageConversation(true);
        onInboxSelect("messages");
      }
      messagesInboxRef.current?.triggerCompose();
    } else if (inboxType === "phone") {
      if (!isMobile) {
        setIsNewMessageConversation(true);
        onInboxSelect("phone");
      }
      phoneInboxRef.current?.triggerCompose();
    } else {
      console.error("Unknown inbox type");
    }
  };
  const search = () => {
    switch (inboxType) {
      case "email":
        navigate(generateSearchQueryUrlWithFilters("", [emailOnlyFilter()]));
        break;
      case "messages":
        // We don't have message search so this needs to break somewhere...here seems easiest for now.
        // navigate(generateSearchQueryUrlWithFilters("", [messagesOnlyFilter()]));
        navigate(generateSearchQueryUrlWithFilters("", []));
        break;
      case "phone":
        navigate(generateSearchQueryUrlWithFilters("", [textsOnlyFilter()]));
        break;
    }
  };

  return (
    <FlexPage
      sx={{
        maxWidth: undefined,
        margin: isMobile ? "" : "0px min(7%, 100px)",
        padding: "0",
        flexDirection: "row",
        height: isMobile ? "calc(100vh - 50px)" : "100vh",
      }}
    >
      {/* Left */}
      {(!isMobile || (isMobile && inboxRef === undefined)) && (
        <Box
          sx={{
            height: "100%",
            width: isMobile ? "100%" : "33%",
            minWidth: "350px",
            maxWidth: "450px",
            paddingTop: !isMobile ? "32px" : "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              marginBottom: "15px",
              marginTop: "16px",
              paddingLeft: !isMobile ? "28px" : "18px",
              paddingRight: !isMobile ? "28px" : "18px",
            }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="title" id="tableTitle">
              Inbox
            </Typography>
            <Box display="flex" flexDirection="row" gap={"8px"}>
              <IconButton
                onClick={search}
                sx={{
                  display: "flex",
                  padding: "8px",
                  alignItems: "flex-start",
                  gap: "1px",
                  borderRadius: "100px",
                  border: "2px solid #EAEBEC",
                  background: "#FFF",
                }}
              >
                <Search height={20} width={20} stroke="#198282" />
              </IconButton>
              <IconButton
                onClick={compose}
                sx={{
                  display: "flex",
                  padding: "8px",
                  alignItems: "flex-start",
                  gap: "1px",
                  borderRadius: "100px",
                  border: "2px solid #EAEBEC",
                  background: "#FFF",
                }}
              >
                <SquarePen height={20} width={20} stroke="#198282" />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "23px",
              paddingLeft: !isMobile ? "28px" : "18px",
              paddingRight: !isMobile ? "28px" : "18px",
            }}
          >
            <ButtonGroup
              style={{
                display: "flex",
                marginRight: "28px",
                width: "100%",
                height: "42px",
                gap: "4px",
              }}
            >
              <Button
                sx={{ flexGrow: "1", width: "100%" }}
                variant={
                  inboxType === "email" ? "buttonGroupSelected" : "buttonGroup"
                }
                onClick={() => onInboxSelect("email")}
              >
                Emails
                {emailUnreadCount > 0 && (
                  <Box>
                    {/* Do not remove wrapping Box/div, it prevents the svg from shrinking when screensizes are small */}
                    <EllipseIcon
                      height={8}
                      width={8}
                      style={{ marginLeft: "5px" }}
                    />
                  </Box>
                )}
              </Button>

              <Button
                sx={{ flexGrow: "1", width: "100%" }}
                variant={
                  inboxType === "phone" ? "buttonGroupSelected" : "buttonGroup"
                }
                onClick={() => onInboxSelect("phone")}
              >
                Texts
                {phoneUnreadCount > 0 && (
                  <Box>
                    {/* Do not remove wrapping Box/div, it prevents the svg from shrinking when screensizes are small */}
                    <EllipseIcon
                      height={8}
                      width={8}
                      style={{ marginLeft: "5px" }}
                    />
                  </Box>
                )}
              </Button>

              <Button
                sx={{ flexGrow: "1", width: "100%" }}
                variant={
                  inboxType === "messages"
                    ? "buttonGroupSelected"
                    : "buttonGroup"
                }
                onClick={() => onInboxSelect("messages")}
              >
                Chats
                {messageUnreadCount > 0 && (
                  <Box>
                    {/* Do not remove wrapping Box/div, it prevents the svg from shrinking when screensizes are small */}
                    <EllipseIcon
                      height={8}
                      width={8}
                      style={{ marginLeft: "5px" }}
                    />
                  </Box>
                )}
              </Button>
            </ButtonGroup>
          </Box>
          {inboxType === "messages" && (
            <MessagesInbox
              ref={messagesInboxRef}
              selectedRef={inboxRef}
              onEntrySelect={(entryRef) => {
                setIsNewMessageConversation(false);
                onEntrySelect("messages", entryRef);
              }}
            />
          )}
          {inboxType === "email" && (
            <EmailInbox
              ref={emailInboxRef}
              create={create}
              selectedRef={inboxRef}
              onEntrySelect={(entryRef) => {
                onEntrySelect("email", entryRef);
              }}
            />
          )}
          {inboxType === "phone" && (
            <PhoneInbox
              ref={phoneInboxRef}
              selectedRef={inboxRef}
              onEntrySelect={(entryRef) => {
                setIsNewMessageConversation(false);
                onEntrySelect("phone", entryRef);
              }}
            />
          )}
        </Box>
      )}
      {/* Right */}
      {(!isMobile || (isMobile && inboxRef)) && (
        <Box
          sx={{
            width: isMobile ? "100%" : "67%",
            display: "flex",
          }}
        >
          {!isMobile && (
            <Divider orientation="vertical" sx={{ height: "100vh" }} />
          )}
          <Box
            sx={{
              padding: isMobile ? "0px" : "48px 24px 0px 48px",
              width: "100%",
              height: "100%",
              paddingRight: isMobile
                ? "0px"
                : inboxType === "email"
                  ? "48px"
                  : "24px",
              overflowY: "auto",
            }}
          >
            {threadModule()}
          </Box>
        </Box>
      )}
    </FlexPage>
  );
};
