import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router";

interface Breadcrumbs {
  name: string;
  link?: string;
  // Navigate back in history
  back?: boolean;
}

export default ({ breadcrumbs }: { breadcrumbs: Breadcrumbs[] }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <Typography variant="body" sx={{ color: "#198282", fontWeight: "600" }}>
          {">"}
        </Typography>
      }
    >
      {breadcrumbs.map((breadcrumb, index) => {
        return index < breadcrumbs.length - 1 ||
          breadcrumb.link ||
          breadcrumb.back ? (
          <Link
            underline="none"
            color="inherit"
            href={breadcrumb.link || "#"}
            key={index}
            onClick={breadcrumb.back ? () => navigate(-1) : undefined}
          >
            <Typography
              sx={{ color: "#198282", fontWeight: "600" }}
              variant="body"
              key={index}
            >
              {breadcrumb.name}
            </Typography>
          </Link>
        ) : (
          <Typography
            sx={{ color: "#198282", fontWeight: "600" }}
            variant="body"
            key={index}
          >
            {breadcrumb.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
