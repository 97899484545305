import { Box, Typography } from "@mui/material";
import TimeUtilizationRing from "./time-utilization-ring";
import { PlanUtilization } from "protogen/common_pb";
import { pluralize } from "../../common/utils";

const PLANNED_TASK_HOUR_ESTIMATE = 0.5;

export const WrappedRing = ({
  planUtilization,
}: {
  planUtilization: PlanUtilization;
}) => {
  const colors =
    planUtilization.periodHoursUtilized > planUtilization.planHoursAllocated
      ? ["#F3D7D6", "#EF7B77"]
      : ["#A0CDCD", "#198282"];

  const hoursPlanned =
    planUtilization.tasksInProgress * PLANNED_TASK_HOUR_ESTIMATE;
  return (
    <TimeUtilizationRing
      height={92}
      width={92}
      innerRadius={30}
      ringSpacing={2}
      strokeWidth={5}
      numerator={parseFloat(planUtilization.periodHoursUtilized.toFixed(1))}
      numeratorIncrement={parseFloat(hoursPlanned.toFixed(1))}
      denominator={parseFloat(planUtilization.planHoursAllocated.toFixed(1))}
      unfilledColor="#D4D4D4"
      colors={colors}
      text={"hours used"}
    />
  );
};

export default ({ planUtilization }: { planUtilization?: PlanUtilization }) => {
  if (!planUtilization) {
    return null;
  }
  let renewalDate = new Date(planUtilization.periodEndDate);
  renewalDate.setDate(renewalDate.getDate() + 1);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "13px" }}>
      <WrappedRing planUtilization={planUtilization} />
      <Box gap="8px" display="flex" flexDirection="column">
        <div>
          <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
            {planUtilization.periodTasksCompleted} completed{" "}
            {pluralize("task", planUtilization.periodTasksCompleted)}{" "}
          </Typography>
          <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
            {planUtilization.tasksInProgress}{" "}
            {pluralize("task", planUtilization.tasksInProgress)} in progress
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          Renews{" "}
          {renewalDate.toLocaleDateString("en-US", {
            timeZone: "UTC",
            day: "numeric",
            month: "long",
          })}
        </Typography>
      </Box>
    </Box>
  );
};
