import { default as DMA_LIST, DMADefinition } from "./topDMAs";

// Function to calculate distance between two points using Haversine formula
const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371; // Earth's radius in kilometers

  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
};

const findNearestDMA = (lat: number, lng: number): DMADefinition => {
  let nearestDMA = DMA_LIST[0];
  let shortestDistance = Infinity;

  for (const dma of DMA_LIST) {
    const distance = haversineDistance(lat, lng, dma.latitude, dma.longitude);
    if (distance < shortestDistance) {
      shortestDistance = distance;
      nearestDMA = dma;
    }
  }

  return nearestDMA;
};

export { findNearestDMA };
