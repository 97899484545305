import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";

import {
  JSONContent,
  HTMLContent,
  useEditor,
  EditorContent,
} from "@tiptap/react";
import { EditorView } from "@tiptap/pm/view";
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Attachment, UploadAttachment } from "protogen/common_pb";
import {
  addImagePlaceholder,
  extractImageReferences,
  generateRandomName,
  getExtensions,
  PLACEHOLDER_PATH,
  renderInitialContent,
  convertHEICImages,
} from "./utils";
import DragNDrop from "../common/DragNDrop";
import { useUploader } from "../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { RichContent } from "./utils";
import { Transaction } from "@tiptap/pm/state";
import { Editor } from "@tiptap/core/dist/packages/core/src";
import DescriptionIcon from "@mui/icons-material/Description";
import { styled } from "@mui/system";
import ShortUrlBubbleMenu from "./ShortUrlBubbleMenu";
import Collapse from "@mui/material/Collapse";
import { LucideIcon, Paperclip, Type, Image, X } from "lucide-react";
import EditActions from "./EditActions";
import useIsMobile from "../hooks/useIsMobile";
import { tableStyles, taskListStyles } from "./StyledEditorContent";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdvisor } from "../../common/userUtils";

const MinibarActionIcon = ({
  icon: Icon,
  onClick,
  title,
  active = false,
  disabled = false,
  progress,
}: {
  icon: LucideIcon;
  onClick?: () => void;
  title: string;
  active?: boolean;
  disabled?: boolean;
  progress?: number;
}) => {
  return (
    <Tooltip title={title} enterDelay={1000}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        component={"div"}
        sx={{
          padding: "6px",
          "&:focus": { backgroundColor: active ? "#e8e8e8" : "none" },
          "&:hover": { backgroundColor: active ? "#e8e8e8" : "none" },
          ...(active ? { backgroundColor: "#e8e8e8" } : { background: "none" }),
        }}
      >
        {progress !== undefined ? (
          <CircularProgress
            variant="determinate"
            value={progress}
            size={18}
            thickness={5}
            sx={{ color: "#111827" }}
          />
        ) : (
          <Icon className="icon" color="#6B6E7B" size={18} />
        )}
      </IconButton>
    </Tooltip>
  );
};

const _TextFieldEditor = styled(EditorContent, {
  shouldForwardProp: (prop) => prop !== "minHeight",
})(
  ({} // minHeight='150px'
  : {
    minHeight?: string;
  }) => ({
    outline: "none",
    padding: "12px 0",
    div: {
      outline: "none",
    },
    "& .ProseMirror p": {
      margin: 0,
    },
    "& .ProseMirror image, & .ProseMirror img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "& .ProseMirror": {
      outline: "none",
      wordBreak: "break-word",
    },
    "& .ProseMirror-focused": {
      outline: "none",
    },
    ".ProseMirror p.is-editor-empty:first-of-type::before": {
      color: "#adb5bd",
      content: "attr(data-placeholder)",
      float: "left",
      height: 0,
      pointerEvents: "none",
    },
    '.ProseMirror ul[data-type="taskList"]': {
      ...taskListStyles(),
    },
    ...tableStyles(),
  }),
);

type Props = {
  setContent: (content: RichContent) => void;
  initialContent?: string | JSONContent | HTMLContent;
  initialAttachments?: Attachment[];
  placeholder?: string;
  passiveEditor?: boolean;
  secondaryAction?: ReactNode;
  primaryAction?: ReactNode;
  disabled?: boolean;
  editorMinHeight?: string;
  setDragState?: (dragging: boolean) => void;
  onKeyDown?: (_: EditorView, event: KeyboardEvent) => void;
  triggerReset?: boolean;
  onReset?: () => void;
  urlShortenerEnabled?: boolean;
  externalAttachmentHandler?: (files: File[]) => void;
  setUploadsInProgress?: (uploadsInProgress: boolean) => void;
  inputSx?: React.CSSProperties;
};

export type Handle = {
  updateAttachments: (attachments: Attachment[]) => void;
  focus: () => void;
};

export default forwardRef<Handle, Props>(
  (
    {
      setContent,
      initialContent,
      placeholder,
      primaryAction,
      secondaryAction,
      disabled,
      onKeyDown,
      onReset,
      initialAttachments = [],
      triggerReset = false,
      urlShortenerEnabled = false,
      externalAttachmentHandler,
      setUploadsInProgress,
      inputSx,
    }: Props,
    ref,
  ) => {
    const {
      onUpload,
      fileUploads,
      fileOnlyUploads,
      uploadPercentage,
      removeUpload,
      getCompleteAttachments,
      withAttachments,
      uploadsInProgress,
      clearUploads,
    } = useUploader({
      initialAttachments: initialAttachments,
    });
    const editorRef = useRef<HTMLDivElement>(null); // To track the editor element
    const currentUser = useContext(CurrentUserContext);
    const isMobile = useIsMobile();
    const [dragging, setDragging] = useState(false);
    const [isActive, setIsActive] = useState<boolean>(
      !!(initialAttachments?.length || initialContent),
    );
    const [minibarActive, setMinibarActive] = useState(false);
    const [activeAttachmentButton, setActiveAttachmentButton] =
      useState<string>("");

    const handleMouseDown = useCallback(
      (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (
          target &&
          isActive &&
          !(editorRef.current && editorRef.current.contains(target)) &&
          (editor ? editor?.isEmpty : true)
        ) {
          setIsActive(false);
        }
      },
      [isActive, setIsActive],
    );
    useEffect(() => {
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }, [handleMouseDown]);
    const handlePaste = (_: EditorView, event: ClipboardEvent) => {
      setActiveAttachmentButton("");
      const files = Array.from(event.clipboardData?.files || []);
      if (files.length > 0) {
        (async (editor: Editor | null) => {
          const convertedFiles = await convertHEICImages(files);

          if (externalAttachmentHandler) {
            externalAttachmentHandler(convertedFiles);
            return true;
          }
          const references: (string | null)[] = [];
          for (const file of convertedFiles) {
            if (file.type.startsWith("image/")) {
              const name = generateRandomName("pasted-");
              addImagePlaceholder(editor, name, file, true);
              references.push(name);
            } else {
              references.push(null);
            }
            onUpload(convertedFiles, references, setAttachments);
          }
          return true;
        })(editor);
        return true;
      }
      return false;
    };

    const updateAttachments = (attachments: Attachment[]) => {
      withAttachments(attachments);
      if (!editor) return;
      const attachmentMap = attachments.reduce(
        (mapping, a) => {
          mapping[a.inlineReference] = a;
          return mapping;
        },
        {} as { [inlineReference: string]: Attachment },
      );
      editor.state.doc.descendants((a, startPos) => {
        if (
          a.type.name === "image" &&
          attachmentMap[a.attrs.title] &&
          (a.attrs.src === PLACEHOLDER_PATH ||
            // Don't replace if it's a data URL, would disrupt the user experience.
            // || a.attrs.src.startsWith('data:')
            a.attrs.src === "")
        ) {
          const n = attachmentMap[a.attrs.title];
          const newNode = editor.schema.nodes.image.create({
            title: n.inlineReference,
            src: n.url,
          });
          const transaction = editor.state.tr.replaceWith(
            startPos,
            startPos + a.nodeSize,
            newNode,
          );
          return editor.view.dispatch(transaction);
        }
      });
    };

    const onUpdate = (props: { editor: Editor; transaction: Transaction }) => {
      if (props.transaction.steps.length === 1) {
        // @ts-ignore
        const slice = props.transaction?.steps[0]?.slice;
        if (
          slice?.content?.content?.length === 1 &&
          slice.content.content[0].type.name === "image" &&
          slice.content.content[0].attrs.src.startsWith("https://s3")
        ) {
          // This is a placeholder image, don't update content
          return;
        }
      }
      const references = extractImageReferences(props.editor.getJSON());
      const attachments = getCompleteAttachments();
      const filteredAttachments = attachments.filter(
        (a) => !a.inlineReference || references.has(a.inlineReference),
      );
      setContent({
        html: props.editor.getHTML(),
        text: props.editor.getText({ blockSeparator: "\n" }),
        json: JSON.stringify(props.editor.getJSON()),
        attachments: filteredAttachments,
      });
    };

    const editor = useEditor({
      editable: !disabled,
      extensions: getExtensions({
        placeholder,
        urlShortenerEnabled: urlShortenerEnabled,
        isRichTextEnabled: true,
        handleCommunications: currentUser && isUserAdvisor(currentUser),
      }),
      content: renderInitialContent(initialContent, initialAttachments),
      onUpdate: onUpdate,
      editorProps: {
        handlePaste: handlePaste,
      },
      onFocus: () => {
        // Set focus state to true when focused
        setIsActive(true);
      },
      // We handle this with a global click event listener
      // onBlur: () => {
      //   // Set focus state to false when blurred
      //   setIsActive(!(editor ? editor?.isEmpty : true));
      // },
    });

    useImperativeHandle(ref, () => ({
      updateAttachments: updateAttachments,
      focus: () => {
        editor?.commands.focus();
      },
    }));

    useEffect(() => {
      if (triggerReset) {
        if (!editor) return;
        editor.commands.clearContent();
        clearUploads();
        onReset && onReset();
      }
    }, [triggerReset, onReset]);

    useEffect(() => {
      if (editor) {
        editor.setOptions({
          editable: !disabled,
          onUpdate: onUpdate,
          editorProps: {
            handlePaste: handlePaste,
            handleKeyDown: onKeyDown ? onKeyDown : undefined,
            attributes: {
              style: `color: ${disabled ? "#adb5bd" : "inherit"}`,
            },
          },
        });
      }
    }, [editor, fileUploads, handlePaste, onUpdate, disabled]);

    useEffect(() => {
      if (editor) {
        const placeHolderExtension = editor.extensionManager.extensions.find(
          (extension) => extension.name === "placeholder",
        );
        if (placeHolderExtension) {
          placeHolderExtension.options["placeholder"] = placeholder;
          // Forces a re-render of the placeholder
          editor.view.dispatch(editor.state.tr);
        }
      }
    }, [placeholder]);

    useEffect(
      () => setUploadsInProgress && setUploadsInProgress(uploadsInProgress),
      [uploadsInProgress],
    );

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        if (editor) {
          setContent({
            html: editor.getHTML(),
            text: editor.getText({ blockSeparator: "\n" }),
            json: JSON.stringify(editor.getJSON()),
            attachments: attachments,
          });
        }
      },
      [editor, fileUploads],
    );

    const handleUpload = (
      filelist: FileList | null,
      defaultInline: boolean,
      activeButton?: string,
    ) => {
      setActiveAttachmentButton(activeButton || "");
      const files = Array.from(filelist || []);
      if (!files?.length) return;
      if (files.length > 0) {
        (async () => {
          const convertedFiles = await convertHEICImages(Array.from(files));
          if (!defaultInline) {
            onUpload(convertedFiles, undefined, setAttachments);
            return;
          }
          const references: (string | null)[] = [];
          for (const file of convertedFiles) {
            if (file.type.startsWith("image/")) {
              const name = generateRandomName("pasted-");
              addImagePlaceholder(editor, name, file, false);
              references.push(name);
            } else {
              references.push(null);
            }
            onUpload(convertedFiles, references, setAttachments);
          }
        })();
      }
    };

    const uploadChips = () => {
      if (!fileOnlyUploads.length) return null;
      return (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            gap: "6px",
            flexWrap: "wrap",
          }}
        >
          {fileOnlyUploads.map((fileUpload, i) => (
            <Chip
              key={i}
              label={fileUpload.filename}
              size="small"
              sx={{ maxWidth: "120px" }}
              icon={<DescriptionIcon />}
              onDelete={() => removeUpload(fileUpload.filename)}
            />
          ))}
        </Box>
      );
    };

    if (!editor) return null;
    // const showEditor = !passiveEditor || isFocused;
    return (
      <DragNDrop
        setHover={setDragging}
        onUpload={(files) => handleUpload(files, true)}
      >
        {urlShortenerEnabled && <ShortUrlBubbleMenu editor={editor} />}
        <Box
          ref={editorRef}
          display="flex"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: primaryAction || secondaryAction ? "12px" : "0px",
            width: "100%",
          }}
        >
          <div style={{ position: "relative", flexGrow: 1 }}>
            <Box
              sx={{
                padding: "0px 14px",
                borderRadius: "8px",
                border: "1px solid #D0D5DD",
                background: "var(--base-white, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                ...(dragging ? { backgroundColor: "#e8f4f8" } : {}),
                ...inputSx,
              }}
            >
              {!isMobile && (
                <Collapse in={isActive && minibarActive}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="start"
                    marginTop="1px"
                  >
                    <EditActions withDividers={true} editor={editor} />
                  </Box>
                </Collapse>
              )}
              <_TextFieldEditor editor={editor} />
              {uploadChips()}
              <Collapse in={isActive}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="end"
                  marginBottom="9px"
                >
                  {isMobile && minibarActive ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      sx={{ flexGrow: 1, width: "20px" }}
                    >
                      <MinibarActionIcon
                        active={false}
                        title="Close"
                        icon={X}
                        onClick={() => setMinibarActive(false)}
                      />
                      <Divider
                        orientation="vertical"
                        sx={{ height: "20px", margin: "6px 3px" }}
                      />
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap="2px"
                        sx={{
                          overflowX: "scroll",
                        }}
                      >
                        <EditActions withDividers={false} editor={editor} />
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" flexDirection="row" gap="2px">
                      <label>
                        <MinibarActionIcon
                          active={
                            uploadsInProgress &&
                            activeAttachmentButton === "inlineImage"
                          }
                          progress={
                            uploadsInProgress &&
                            activeAttachmentButton === "inlineImage"
                              ? uploadPercentage
                              : undefined
                          }
                          title="Add image"
                          icon={Image}
                        />
                        <input
                          multiple
                          disabled={uploadsInProgress}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleUpload(e.target.files, true, "inlineImage")
                          }
                        />
                      </label>
                      <label>
                        <MinibarActionIcon
                          active={
                            uploadsInProgress &&
                            activeAttachmentButton === "attachment"
                          }
                          progress={
                            uploadsInProgress &&
                            activeAttachmentButton === "attachment"
                              ? uploadPercentage
                              : undefined
                          }
                          disabled={uploadsInProgress}
                          title="Upload attachment"
                          icon={Paperclip}
                        />
                        <input
                          multiple
                          disabled={uploadsInProgress}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleUpload(e.target.files, false, "attachment")
                          }
                        />
                      </label>
                      <MinibarActionIcon
                        active={minibarActive}
                        title="Text styling"
                        icon={Type}
                        onClick={() => setMinibarActive((a) => !a)}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{ display: "flex", flex: 1, height: "30px" }}
                    onClick={() => {
                      if (!editor) return;

                      const endPos = editor.state.doc.content.size; // Get the end position of the document
                      editor.chain().setTextSelection(endPos).focus().run(); // Set the selection to the end and focus
                    }}
                  />
                  <Box>
                    {secondaryAction}
                    {primaryAction}
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </div>
        </Box>
      </DragNDrop>
    );
  },
);
