import { Box } from "@mui/material";
import CheckedDropDown, { Option } from "../common/CheckedDropDown";
import { Family } from "protogen/advisors_service_pb";
import { CalendarParameters, todaySec } from "../../types/calendars";
import CalendarRange from "./CalendarRange";
import { getMonthBounds } from "./utils";
import useIsMobile from "../hooks/useIsMobile";
import GroupedCheckedDropDown from "../common/GroupedCheckedDropDown";

type Props = {
  families: Family[];
  disabled: boolean;
  parameters: CalendarParameters;
  updateParameters: (f: CalendarParameters) => void;
  updateDateFilter: (d: Date | null) => void;
};

export default ({
  families,
  disabled = false,
  parameters,
  updateParameters,
  updateDateFilter,
}: Props) => {
  const isMobile = useIsMobile();
  const onTypeChange = (selected: Option[]) => {
    updateParameters({
      ...parameters,
      ignoreEvents: !selected.find((s) => s.value === "eventsEnabled"),
      ignoreTasks: !selected.find((s) => s.value === "tasksEnabled"),
    });
  };
  const onFamiliesChange = (selected: Option[]) => {
    updateParameters({
      ...parameters,
      familyRefs: selected.find((s) => s.value === "all")
        ? null
        : selected.map((s) => s.value),
      allFamiliesSelected: families.length === selected.length,
    });
  };
  const onUpdateTime = (newDate: Date | null) => {
    const [startSec, endSec] = newDate
      ? getMonthBounds(newDate)
      : [todaySec(), null];
    updateParameters({
      ...parameters,
      includeOverdueTasks: !newDate,
      startSec: startSec,
      endSec: endSec,
    });
    updateDateFilter(newDate || null);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        borderBottomColor: "border",
        borderBottomWidth: "1px",
        marginBottom: "31px",
        paddingBottom: "16px",
        borderBottomStyle: "solid",
        marginTop: !isMobile ? "21px" : undefined,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        gap="15px"
      >
        {!isMobile && (
          <>
            <CheckedDropDown
              disabled={disabled}
              options={[
                {
                  label: "Events",
                  value: "eventsEnabled",
                  defaultChecked: !parameters.ignoreEvents,
                },
                {
                  label: "Tasks",
                  value: "tasksEnabled",
                  defaultChecked: !parameters.ignoreTasks,
                },
              ]}
              displayValue={
                !parameters.ignoreTasks && !parameters.ignoreEvents
                  ? "Events and tasks"
                  : parameters.ignoreTasks && parameters.ignoreEvents
                    ? "Nothing"
                    : !parameters.ignoreTasks
                      ? "Tasks"
                      : "Events"
              }
              onChange={onTypeChange}
            />
            <CheckedDropDown
              key={families.length}
              disabled={disabled}
              options={[
                ...families.map((f) => ({
                  label: f.name,
                  value: f.ref,
                  defaultChecked:
                    (parameters.familyRefs || []).indexOf(f.ref) >= 0,
                })),
              ]}
              displayValue={
                !parameters.familyRefs?.length
                  ? "My events"
                  : parameters.familyRefs?.length === families.length
                    ? "All families"
                    : `${parameters.familyRefs?.length} ${
                        parameters.familyRefs?.length === 1
                          ? "family"
                          : "families"
                      }`
              }
              onChange={onFamiliesChange}
            />
          </>
        )}
        {isMobile && (
          <Box>
            <GroupedCheckedDropDown
              displayValue="Filters"
              disabled={disabled}
              groups={[
                {
                  label: "Type",
                  options: [
                    {
                      label: "Events",
                      value: "eventsEnabled",
                      defaultChecked: !parameters.ignoreEvents,
                    },
                    {
                      label: "Tasks",
                      value: "tasksEnabled",
                      defaultChecked: !parameters.ignoreTasks,
                    },
                  ],
                  onChange: onTypeChange,
                },
                {
                  label: "Families",
                  options: [
                    ...families.map((f) => ({
                      label: f.name,
                      value: f.ref,
                      defaultChecked:
                        (parameters.familyRefs || []).indexOf(f.ref) >= 0,
                    })),
                  ],
                  onChange: onFamiliesChange,
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <CalendarRange updateTime={onUpdateTime} />
    </Box>
  );
};
