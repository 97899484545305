import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useHandleCalendarEvent } from "services/calendar";

export default () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { request, data } = useHandleCalendarEvent(() => {
    navigate("/", { state: { calendarEvent: { action: "confirm" } } });
  });
  useEffect(() => {
    request({
      status: queryParams.get("status")!,
      token: queryParams.get("token")!,
    });
  }, []);
  if (!data) return <></>;
  return <></>;
};
