import { LikeHint } from "protogen/forum_service_pb";
import React, { ReactElement } from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";

export type Liker = {
  userRef: string;
  displayName: string;
};

export default ({
  likers,
  total,
  children,
}: {
  likers: LikeHint[] | Liker[];
  total: number;
  children: ReactElement;
}) => {
  if (!likers.length) {
    return children;
  }
  return (
    <Tooltip
      style={{ alignContent: "center", width: "fit-content" }}
      title={
        <React.Fragment>
          <Box sx={{ padding: "12px" }}>
            <Typography variant="bodySmallHeavy">Liked by</Typography>
            {likers.map((liker) => (
              <LinkRouter
                key={liker.userRef}
                to={`/advisor/${encodeURIComponent(liker.userRef)}`}
              >
                <Typography key={liker.userRef} variant="body">
                  {liker.displayName}
                </Typography>
              </LinkRouter>
            ))}
            {likers.length < total && (
              <Typography variant="body" sx={{ marginTop: "2px" }}>
                {` and ${total - likers.length} others`}
              </Typography>
            )}
          </Box>
        </React.Fragment>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
