import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { EphemeralTask } from "../../types/tasks";
import { TaskSuggestion } from "protogen/suggestions_pb";
import { PlusIcon, CheckIcon, ClockIcon } from "@heroicons/react/24/outline";
import { TaskState } from "protogen/tasks_pb";
import { ReactComponent as LoadingIcon } from "../../icons/Member/Loading.svg";
import SuggestionItemContainer from "./SuggestionItemContainer";

const TaskSuggestionItem = ({
  entityRef,
  entityType,
  suggestion,
  suggestTask,
  index,
  disabled = false,
}: {
  entityRef: string;
  entityType: string;
  suggestion: TaskSuggestion;
  suggestTask: (t: EphemeralTask) => void;
  index: number;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{}}>
      <Button
        size="small"
        variant="text"
        disabled={disabled}
        sx={{
          display: "flex",
          alignItems: "start",
          lineHeight: "normal",
          textAlign: "left",
          color:
            suggestion.taskState === TaskState.COMPLETED
              ? "#B3AFB6" // gray
              : "#198282", // teal
          strokeWidth: "2px",
          height: "auto",
        }}
        onClick={() => {
          const genContent = (content: string) => {
            return `<p>${content}</p>`;
          };
          if (!suggestion.taskState) {
            suggestTask({
              title: suggestion.title,
              details: {
                contentType: "html",
                payload: suggestion.initialDetailsText
                  ? genContent(suggestion.initialDetailsText)
                  : "",
                textContent: suggestion.initialDetailsText,
                attachments: [],
              },
              dueDate: suggestion.dueDate || undefined,
              sourceEntityType: entityType,
              sourceEntityRef: entityRef,
              sourceEntityIndex: index,
            });
          } else {
            navigate(`/tasks/${encodeURIComponent(suggestion.taskRef)}`);
          }
        }}
        startIcon={
          suggestion.taskState === TaskState.INPROGRESS ? (
            <LoadingIcon height="20px" width="20px" strokeWidth="2px" />
          ) : suggestion.taskState === TaskState.COMPLETED ? (
            <CheckIcon height="20px" width="20px" strokeWidth="2.5px" />
          ) : suggestion.taskState === TaskState.OPEN ? (
            <ClockIcon
              height="20px"
              width="20px"
              stroke="#6092A8"
              strokeWidth="2px"
            />
          ) : (
            <PlusIcon height="20px" width="20px" strokeWidth="2px" />
          )
        }
      >
        {suggestion.title}
      </Button>
    </Box>
  );
};

export default ({
  entityRef,
  entityType,
  suggestions,
  suggestTask,
  isOpen,
  disabled = false,
}: {
  entityRef: string;
  entityType: string;
  suggestions: TaskSuggestion[];
  suggestTask: (t: EphemeralTask) => void;
  isOpen: boolean;
  disabled?: boolean;
}) => {
  return (
    <SuggestionItemContainer
      open={isOpen}
      title={`Add task${suggestions.length === 1 ? "" : "s"}`}
    >
      {suggestions.map((suggestion: TaskSuggestion, i: number) => {
        return (
          <TaskSuggestionItem
            key={`suggestion-${suggestion.title}-${i}`}
            entityRef={entityRef}
            entityType={entityType}
            suggestion={suggestion}
            suggestTask={suggestTask}
            index={i}
            disabled={disabled}
          />
        );
      })}
    </SuggestionItemContainer>
  );
};
