import React, { forwardRef } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  AnimateLayoutChanges,
  useSortable,
  defaultAnimateLayoutChanges,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export interface DroppableItem {
  id: UniqueIdentifier;
  element: JSX.Element;
  headerElement?: JSX.Element;
  isDisabled?: boolean;
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export interface ContainerProps {
  children: React.ReactNode;
  headerStyle?: React.CSSProperties;
  headerElement?: (
    handleProps: React.HTMLAttributes<any>,
  ) => React.ReactElement;
  label?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  onClick?(): void;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      headerStyle,
      headerElement,
      handleProps,
      hover,
      onClick,
      label,
      ...props
    }: ContainerProps,
    ref,
  ) => {
    const Component = "div";

    return (
      <Component
        {...props}
        ref={ref}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        <div
          {...props}
          style={{
            backgroundColor: !!hover ? "rgb(235, 235, 235, 1)" : "transparent",
            borderRadius: "4px",
          }}
        >
          {headerElement && handleProps ? (
            headerElement(handleProps)
          ) : label ? (
            <div
              style={{
                cursor: "pointer",
                ...headerStyle,
              }}
              {...handleProps}
            >
              {label}
            </div>
          ) : null}
          <div>{children}</div>
        </div>
      </Component>
    );
  },
);

export const DroppableContainer = ({
  children,
  headerStyle,
  id,
  items,
  style,
  ...props
}: ContainerProps & {
  id: UniqueIdentifier;
  items: DroppableItem[];
}) => {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") ||
      items.some((item) => item.id === over.id)
    : false;

  return (
    <Container
      headerStyle={headerStyle}
      ref={setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    >
      {children}
    </Container>
  );
};
