import { ReactElement } from "react";

type Props = {
  icon: ReactElement;
  header: string | ReactElement;
  // Could be a "Duration".
  subheader: string | ReactElement;
  onclick?: () => void;
  draftTreatment?: boolean;
  unreadTreatment?: boolean;
};

export default ({
  icon,
  header,
  subheader,
  onclick,
  draftTreatment,
  unreadTreatment,
}: Props) => {
  return (
    <div
      onClick={onclick}
      style={{
        padding: "10px 14px",
        alignItems: "center",
        borderRadius: "0px 8px 8px 8px",
        border: "1px solid #EAECF0",
        background: "var(--base-white, #FFF)",
        maxWidth: "500px",
        ...(draftTreatment
          ? {
              border: "1px solid #EAECF0",
              background: "#F2F4F7",
            }
          : {}),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flexStart",
          gap: "12px",
          flex: "1 0 0",
        }}
      >
        {icon}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
            flex: "1 0 0",
            width: "180px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "stretch",
            }}
          >
            <div
              style={{
                color: "#344054",
                fontWeight: "500",
                height: "20px",
                overflow: "hidden",
                width: "100%",
                fontSize: "14px",
                textOverflow: "ellipsis",
                ...{ textWrap: "nowrap" },
                ...(unreadTreatment && { fontWeight: "700" }),
              }}
            >
              {header}
            </div>
            <div
              style={{
                color: "#475467",
                overflow: "hidden",
                width: "100%",
                fontSize: "14px",
                textOverflow: "ellipsis",
                ...{ textWrap: "nowrap" },
              }}
            >
              {subheader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
