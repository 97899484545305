import { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  TextField,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import PhoneInput from "components/creation/PhoneInput";
import { validateMemberInquiry } from "components/family/utils";
import { FormErrors, Member } from "components/family/types";
import { defaultMember } from "components/family/utils";
import { cleanPhone } from "../../common/utils";
import { useCreateMemberInquiry } from "services/public";

export default ({}: {}) => {
  let params = useParams();
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState<Member>(defaultMember());
  const [helpBlurb, setHelpBlurb] = useState<string>();
  const [formErrors, setFormErrors] = useState<FormErrors>();

  const { request, error } = useCreateMemberInquiry();

  const _validate = () => {
    const formErrors = validateMemberInquiry(formData);
    setFormErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_validate()) return;
    request({
      member: {
        firstName: formData.firstName || "",
        lastName: formData.lastName || "",
        email: formData.email || "",
        phone: formData.phone || "",
      },
      advisorProfilePath: params.profilePath!,
      helpBlurb: helpBlurb || "",
    });
    setSent(true);
  };

  return (
    <Grid container sx={{ maxWidth: "862px" }}>
      <Grid item xs={12}>
        <Typography variant="h1Serif">Get in touch</Typography>
        {sent && !error && (
          <Alert severity="success" style={{ marginTop: 16 }}>
            We've received your request. An advisor with Faye will contatct you
            shortly.
          </Alert>
        )}
        {sent && error && (
          <Alert severity="error" style={{ marginTop: 16 }}>
            {error}
          </Alert>
        )}
        {!sent && !error && (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth style={{ marginTop: 16 }}>
              <Grid
                container
                spacing={2}
                sx={{ ".MuiGrid-item": { paddingTop: 0 } }}
              >
                <Grid item xs={6}>
                  <TextField
                    error={!!formErrors?.firstName}
                    helperText={formErrors?.firstName}
                    label="First name"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    sx={{
                      marginTop: "8px",
                      marginBottom: "4px",
                    }}
                    value={formData.firstName}
                    onChange={(e) => {
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          firstName: e.target.value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formErrors?.lastName}
                    helperText={formErrors?.lastName}
                    label="Last name"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    value={formData.lastName}
                    onChange={(e) => {
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          lastName: e.target.value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!formErrors?.email}
                    helperText={formErrors?.email}
                    label="Email"
                    fullWidth
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    type="email"
                    size="small"
                    autoComplete="off"
                    value={formData?.email || ""}
                    onChange={(e) =>
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          email: e.target.value,
                        };
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    error={!!formErrors?.phone}
                    helperText={formErrors?.phone}
                    value={formData.phone}
                    onChange={(phone) =>
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          phone: cleanPhone(
                            typeof phone === "string"
                              ? phone
                              : phone.target.value,
                          ),
                        };
                      })
                    }
                    label="Phone number"
                    fullWidth
                    size="small"
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="How can I help? (optional)"
                    type="text"
                    multiline
                    rows={7}
                    fullWidth
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    size="small"
                    autoComplete="off"
                    value={helpBlurb}
                    onChange={(e) => {
                      setHelpBlurb(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <Button type="submit" style={{ marginTop: 16 }}>
              Submit
            </Button>
          </form>
        )}
      </Grid>
    </Grid>
  );
};
