import useProtoMethod from "./useProtoMethod";
import {
  GetSignedUploadsUrlsRequest,
  GetSignedUploadsUrlsResponse,
  GetUrlContentRequest,
  GetUrlContentResponse,
} from "protogen/files_service_pb";

const useGetSignedUploadUrls = () => {
  return useProtoMethod<
    GetSignedUploadsUrlsRequest,
    GetSignedUploadsUrlsResponse
  >("GetSignedUploadsUrls", GetSignedUploadsUrlsResponse);
};

const useGetUrlContent = () => {
  return useProtoMethod<GetUrlContentRequest, GetUrlContentResponse>(
    "GetUrlContent",
    GetUrlContentResponse,
  );
};

export { useGetSignedUploadUrls, useGetUrlContent };
