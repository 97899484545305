import { Checkbox, CheckboxProps } from "@mui/material";
import { ReactComponent as CheckboxIcon } from "../../icons/Checkbox.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../icons/CheckboxSelected.svg";

export default ({ size, ...otherProps }: CheckboxProps) => {
  return (
    <Checkbox
      icon={
        <CheckboxIcon
          style={size === "small" ? { height: "16px", width: "16px" } : {}}
        />
      }
      checkedIcon={
        <CheckboxSelectedIcon
          style={size === "small" ? { height: "16px", width: "16px" } : {}}
        />
      }
      {...otherProps}
    />
  );
};
