import { Extension } from "@tiptap/core";
import { Plugin, PluginKey, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Slice } from "@tiptap/pm/model";
import { SHORT_URL_REGEX } from "../utils";
export default Extension.create({
  name: "urlShortener",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("htmlPasteHandler"),
        props: {
          handlePaste: (
            view: EditorView,
            event: ClipboardEvent,
            slice: Slice,
          ) => {
            let text = "";
            slice.content.forEach((node) => {
              text += node.textContent;
            });

            // Handle html/rich text
            const html = event.clipboardData?.getData("text/html");
            if (html) {
              const match = text.match(SHORT_URL_REGEX);
              if (match) {
                const tr = view.state.tr.replaceSelection(slice);
                const end = tr.selection.from;
                const start = end - slice.size;
                const newSelection = TextSelection.create(tr.doc, start, end);
                view.dispatch(tr.setSelection(newSelection));

                return true;
              }
            }

            // Handle plain text
            const plainText = event.clipboardData?.getData("text/plain");
            if (plainText) {
              if (SHORT_URL_REGEX.test(text)) {
                view.dispatch(view.state.tr.insertText(text));
                const end = view.state.tr.selection.from;
                const start = end - slice.size;
                const newSelection = TextSelection.create(
                  view.state.tr.doc,
                  start,
                  end,
                );
                view.dispatch(view.state.tr.setSelection(newSelection));

                return true;
              }
            }
            return false;
          },
        },
      }),
    ];
  },
});
