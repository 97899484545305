import React, { ReactNode } from "react";
import Divider from "@mui/material/Divider";

interface Props {
  children: ReactNode;
}
export default ({ children }: Props) => {
  const childrenArray = React.Children.toArray(children);
  const childrenWithDividers: ReactNode[] = [];

  childrenArray.forEach((child, index) => {
    if (index !== 0) {
      childrenWithDividers.push(<Divider key={`divider-${index}`} />);
    }
    childrenWithDividers.push(child);
  });

  return <>{childrenWithDividers}</>;
};
