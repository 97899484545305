import { useRef, useState } from "react";

import ReactiveDialog from "../common/ReactiveDialog";
import { ReactComponent as SendIcon } from "../../icons/SendIcon.svg";
import EmailCompose, { ComposeHandle, EmailComposeProps } from "./EmailCompose";

interface Props extends EmailComposeProps {
  closed: boolean;
  onClose: () => void;
}

export default ({ closed, onClose, ...otherProps }: Props) => {
  const composeRef = useRef<ComposeHandle | null>(null);
  const [title, setTitle] = useState(otherProps.initialDraft?.subject || "");
  const [hovering, setHovering] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);

  const getTitle = () => {
    if (otherProps.isForward && otherProps.inReplyTo) {
      return "Forward";
    } else if (otherProps.inReplyTo) {
      return "Reply";
    } else if (title) {
      return title;
    } else {
      return "New Email";
    }
  };
  if (closed) return null;
  return (
    <ReactiveDialog
      open={!closed}
      onClose={() => {
        composeRef.current?.reset();
        onClose();
      }}
      title={getTitle()}
      primaryAction={() => composeRef.current?.handleSend()}
      primaryActionName={
        <SendIcon
          height={20}
          width={20}
          style={{
            opacity: sendDisabled ? 0.4 : 1,
          }}
        />
      }
      primaryActionEnabled={!sendDisabled}
      noActionsFullScreen={true}
      fullWidthSize="lg"
      unpad={true}
      style={hovering ? { backgroundColor: "#e8f4f8" } : {}}
    >
      <EmailCompose
        ref={composeRef}
        onClose={onClose}
        setSubject={(s) => {
          setTitle(s);
        }}
        setSendDisabled={setSendDisabled}
        setHovering={setHovering}
        {...otherProps}
      />
    </ReactiveDialog>
  );
};
