// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/admin_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Family } from "./advisors_service_pb.ts";
import { AccountStub, Advisor, AdvisorStatus, PlanUtilization, UserPermission } from "./common_pb.ts";

/**
 * @generated from message protogen.ListAllFamiliesRequest
 */
export class ListAllFamiliesRequest extends Message<ListAllFamiliesRequest> {
  constructor(data?: PartialMessage<ListAllFamiliesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllFamiliesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllFamiliesRequest {
    return new ListAllFamiliesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllFamiliesRequest {
    return new ListAllFamiliesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllFamiliesRequest {
    return new ListAllFamiliesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllFamiliesRequest | PlainMessage<ListAllFamiliesRequest> | undefined, b: ListAllFamiliesRequest | PlainMessage<ListAllFamiliesRequest> | undefined): boolean {
    return proto3.util.equals(ListAllFamiliesRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAllFamiliesResponse
 */
export class ListAllFamiliesResponse extends Message<ListAllFamiliesResponse> {
  /**
   * @generated from field: repeated protogen.Family families = 1;
   */
  families: Family[] = [];

  /**
   * @generated from field: repeated string subscribedFamilyRefs = 2;
   */
  subscribedFamilyRefs: string[] = [];

  /**
   * @generated from field: repeated protogen.ListAllFamiliesResponse.FamilyPermissions familyPermissions = 3;
   */
  familyPermissions: ListAllFamiliesResponse_FamilyPermissions[] = [];

  constructor(data?: PartialMessage<ListAllFamiliesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllFamiliesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: Family, repeated: true },
    { no: 2, name: "subscribedFamilyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "familyPermissions", kind: "message", T: ListAllFamiliesResponse_FamilyPermissions, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllFamiliesResponse {
    return new ListAllFamiliesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllFamiliesResponse {
    return new ListAllFamiliesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllFamiliesResponse {
    return new ListAllFamiliesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllFamiliesResponse | PlainMessage<ListAllFamiliesResponse> | undefined, b: ListAllFamiliesResponse | PlainMessage<ListAllFamiliesResponse> | undefined): boolean {
    return proto3.util.equals(ListAllFamiliesResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListAllFamiliesResponse.FamilyPermissions
 */
export class ListAllFamiliesResponse_FamilyPermissions extends Message<ListAllFamiliesResponse_FamilyPermissions> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: protogen.AccountStub primaryAdvisor = 2;
   */
  primaryAdvisor?: AccountStub;

  /**
   * @generated from field: repeated protogen.UserPermission advisorPermissions = 3;
   */
  advisorPermissions: UserPermission[] = [];

  constructor(data?: PartialMessage<ListAllFamiliesResponse_FamilyPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllFamiliesResponse.FamilyPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "primaryAdvisor", kind: "message", T: AccountStub },
    { no: 3, name: "advisorPermissions", kind: "message", T: UserPermission, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllFamiliesResponse_FamilyPermissions {
    return new ListAllFamiliesResponse_FamilyPermissions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllFamiliesResponse_FamilyPermissions {
    return new ListAllFamiliesResponse_FamilyPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllFamiliesResponse_FamilyPermissions {
    return new ListAllFamiliesResponse_FamilyPermissions().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllFamiliesResponse_FamilyPermissions | PlainMessage<ListAllFamiliesResponse_FamilyPermissions> | undefined, b: ListAllFamiliesResponse_FamilyPermissions | PlainMessage<ListAllFamiliesResponse_FamilyPermissions> | undefined): boolean {
    return proto3.util.equals(ListAllFamiliesResponse_FamilyPermissions, a, b);
  }
}

/**
 * @generated from message protogen.AdminListAllAdvisorsRequest
 */
export class AdminListAllAdvisorsRequest extends Message<AdminListAllAdvisorsRequest> {
  constructor(data?: PartialMessage<AdminListAllAdvisorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdminListAllAdvisorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListAllAdvisorsRequest {
    return new AdminListAllAdvisorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListAllAdvisorsRequest {
    return new AdminListAllAdvisorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListAllAdvisorsRequest {
    return new AdminListAllAdvisorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListAllAdvisorsRequest | PlainMessage<AdminListAllAdvisorsRequest> | undefined, b: AdminListAllAdvisorsRequest | PlainMessage<AdminListAllAdvisorsRequest> | undefined): boolean {
    return proto3.util.equals(AdminListAllAdvisorsRequest, a, b);
  }
}

/**
 * @generated from message protogen.AdminListAllAdvisorsResponse
 */
export class AdminListAllAdvisorsResponse extends Message<AdminListAllAdvisorsResponse> {
  /**
   * @generated from field: repeated protogen.Advisor advisors = 1;
   */
  advisors: Advisor[] = [];

  constructor(data?: PartialMessage<AdminListAllAdvisorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdminListAllAdvisorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisors", kind: "message", T: Advisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListAllAdvisorsResponse {
    return new AdminListAllAdvisorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListAllAdvisorsResponse {
    return new AdminListAllAdvisorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListAllAdvisorsResponse {
    return new AdminListAllAdvisorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListAllAdvisorsResponse | PlainMessage<AdminListAllAdvisorsResponse> | undefined, b: AdminListAllAdvisorsResponse | PlainMessage<AdminListAllAdvisorsResponse> | undefined): boolean {
    return proto3.util.equals(AdminListAllAdvisorsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateAdvisorRequest
 */
export class CreateAdvisorRequest extends Message<CreateAdvisorRequest> {
  /**
   * Details
   *
   * @generated from field: string firstName = 1;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 2;
   */
  lastName = "";

  /**
   * Contact.
   *
   * @generated from field: string personalPhone = 3;
   */
  personalPhone = "";

  /**
   * @generated from field: string personalEmail = 4;
   */
  personalEmail = "";

  /**
   * Assigned details.
   *
   * @generated from field: string username = 5;
   */
  username = "";

  /**
   * @generated from field: string advisorPhone = 6;
   */
  advisorPhone = "";

  /**
   * @generated from field: bool sendIntroEmail = 7;
   */
  sendIntroEmail = false;

  /**
   * @generated from field: string timezone = 8;
   */
  timezone = "";

  /**
   * @generated from field: protogen.AdvisorStatus status = 9;
   */
  status = AdvisorStatus.NOT_SET;

  /**
   * @generated from field: string address = 10;
   */
  address = "";

  constructor(data?: PartialMessage<CreateAdvisorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateAdvisorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "personalPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "personalEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "advisorPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sendIntroEmail", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "status", kind: "enum", T: proto3.getEnumType(AdvisorStatus) },
    { no: 10, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAdvisorRequest {
    return new CreateAdvisorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAdvisorRequest {
    return new CreateAdvisorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAdvisorRequest {
    return new CreateAdvisorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAdvisorRequest | PlainMessage<CreateAdvisorRequest> | undefined, b: CreateAdvisorRequest | PlainMessage<CreateAdvisorRequest> | undefined): boolean {
    return proto3.util.equals(CreateAdvisorRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateAdvisorResponse
 */
export class CreateAdvisorResponse extends Message<CreateAdvisorResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<CreateAdvisorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateAdvisorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAdvisorResponse {
    return new CreateAdvisorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAdvisorResponse {
    return new CreateAdvisorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAdvisorResponse {
    return new CreateAdvisorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAdvisorResponse | PlainMessage<CreateAdvisorResponse> | undefined, b: CreateAdvisorResponse | PlainMessage<CreateAdvisorResponse> | undefined): boolean {
    return proto3.util.equals(CreateAdvisorResponse, a, b);
  }
}

/**
 * @generated from message protogen.GenerateAdvisorContactsRequest
 */
export class GenerateAdvisorContactsRequest extends Message<GenerateAdvisorContactsRequest> {
  /**
   * @generated from field: string areaCode = 1;
   */
  areaCode = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  constructor(data?: PartialMessage<GenerateAdvisorContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateAdvisorContactsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "areaCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAdvisorContactsRequest {
    return new GenerateAdvisorContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAdvisorContactsRequest {
    return new GenerateAdvisorContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAdvisorContactsRequest {
    return new GenerateAdvisorContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAdvisorContactsRequest | PlainMessage<GenerateAdvisorContactsRequest> | undefined, b: GenerateAdvisorContactsRequest | PlainMessage<GenerateAdvisorContactsRequest> | undefined): boolean {
    return proto3.util.equals(GenerateAdvisorContactsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GenerateAdvisorContactsResponse
 */
export class GenerateAdvisorContactsResponse extends Message<GenerateAdvisorContactsResponse> {
  /**
   * @generated from field: string advisorEmail = 1;
   */
  advisorEmail = "";

  /**
   * @generated from field: string advisorPhone = 2;
   */
  advisorPhone = "";

  constructor(data?: PartialMessage<GenerateAdvisorContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateAdvisorContactsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "advisorPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAdvisorContactsResponse {
    return new GenerateAdvisorContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAdvisorContactsResponse {
    return new GenerateAdvisorContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAdvisorContactsResponse {
    return new GenerateAdvisorContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAdvisorContactsResponse | PlainMessage<GenerateAdvisorContactsResponse> | undefined, b: GenerateAdvisorContactsResponse | PlainMessage<GenerateAdvisorContactsResponse> | undefined): boolean {
    return proto3.util.equals(GenerateAdvisorContactsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateUserRolesRequest
 */
export class UpdateUserRolesRequest extends Message<UpdateUserRolesRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  /**
   * @generated from field: repeated string roles = 2;
   */
  roles: string[] = [];

  constructor(data?: PartialMessage<UpdateUserRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateUserRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRolesRequest {
    return new UpdateUserRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRolesRequest {
    return new UpdateUserRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRolesRequest {
    return new UpdateUserRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRolesRequest | PlainMessage<UpdateUserRolesRequest> | undefined, b: UpdateUserRolesRequest | PlainMessage<UpdateUserRolesRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRolesRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateUserRolesResponse
 */
export class UpdateUserRolesResponse extends Message<UpdateUserRolesResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<UpdateUserRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateUserRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRolesResponse {
    return new UpdateUserRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRolesResponse {
    return new UpdateUserRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRolesResponse {
    return new UpdateUserRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRolesResponse | PlainMessage<UpdateUserRolesResponse> | undefined, b: UpdateUserRolesResponse | PlainMessage<UpdateUserRolesResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUserRolesResponse, a, b);
  }
}

/**
 * @generated from message protogen.AuditRecord
 */
export class AuditRecord extends Message<AuditRecord> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string actorName = 2;
   */
  actorName = "";

  /**
   * @generated from field: string actorRef = 3;
   */
  actorRef = "";

  /**
   * @generated from field: string eventType = 4;
   */
  eventType = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: string contextJson = 6;
   */
  contextJson = "";

  /**
   * @generated from field: int64 timestampSec = 7;
   */
  timestampSec = protoInt64.zero;

  constructor(data?: PartialMessage<AuditRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AuditRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "actorName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "eventType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "contextJson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuditRecord {
    return new AuditRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuditRecord {
    return new AuditRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuditRecord {
    return new AuditRecord().fromJsonString(jsonString, options);
  }

  static equals(a: AuditRecord | PlainMessage<AuditRecord> | undefined, b: AuditRecord | PlainMessage<AuditRecord> | undefined): boolean {
    return proto3.util.equals(AuditRecord, a, b);
  }
}

/**
 * TODO: Add filters.
 *
 * @generated from message protogen.ListAuditRecordsRequest
 */
export class ListAuditRecordsRequest extends Message<ListAuditRecordsRequest> {
  constructor(data?: PartialMessage<ListAuditRecordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAuditRecordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditRecordsRequest {
    return new ListAuditRecordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditRecordsRequest {
    return new ListAuditRecordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditRecordsRequest {
    return new ListAuditRecordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditRecordsRequest | PlainMessage<ListAuditRecordsRequest> | undefined, b: ListAuditRecordsRequest | PlainMessage<ListAuditRecordsRequest> | undefined): boolean {
    return proto3.util.equals(ListAuditRecordsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAuditRecordsResponse
 */
export class ListAuditRecordsResponse extends Message<ListAuditRecordsResponse> {
  /**
   * @generated from field: repeated protogen.AuditRecord records = 1;
   */
  records: AuditRecord[] = [];

  constructor(data?: PartialMessage<ListAuditRecordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAuditRecordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "records", kind: "message", T: AuditRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditRecordsResponse {
    return new ListAuditRecordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditRecordsResponse {
    return new ListAuditRecordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditRecordsResponse {
    return new ListAuditRecordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditRecordsResponse | PlainMessage<ListAuditRecordsResponse> | undefined, b: ListAuditRecordsResponse | PlainMessage<ListAuditRecordsResponse> | undefined): boolean {
    return proto3.util.equals(ListAuditRecordsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ImpersonationRequest
 */
export class ImpersonationRequest extends Message<ImpersonationRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  constructor(data?: PartialMessage<ImpersonationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ImpersonationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonationRequest | PlainMessage<ImpersonationRequest> | undefined, b: ImpersonationRequest | PlainMessage<ImpersonationRequest> | undefined): boolean {
    return proto3.util.equals(ImpersonationRequest, a, b);
  }
}

/**
 * @generated from message protogen.ImpersonationResponse
 */
export class ImpersonationResponse extends Message<ImpersonationResponse> {
  /**
   * @generated from field: string loginUrl = 1;
   */
  loginUrl = "";

  constructor(data?: PartialMessage<ImpersonationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ImpersonationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loginUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonationResponse {
    return new ImpersonationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonationResponse {
    return new ImpersonationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonationResponse {
    return new ImpersonationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonationResponse | PlainMessage<ImpersonationResponse> | undefined, b: ImpersonationResponse | PlainMessage<ImpersonationResponse> | undefined): boolean {
    return proto3.util.equals(ImpersonationResponse, a, b);
  }
}

/**
 * @generated from message protogen.FamilyPlanUtilization
 */
export class FamilyPlanUtilization extends Message<FamilyPlanUtilization> {
  /**
   * @generated from field: string familyName = 1;
   */
  familyName = "";

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 2;
   */
  planUtilization?: PlanUtilization;

  constructor(data?: PartialMessage<FamilyPlanUtilization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FamilyPlanUtilization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "planUtilization", kind: "message", T: PlanUtilization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FamilyPlanUtilization {
    return new FamilyPlanUtilization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FamilyPlanUtilization {
    return new FamilyPlanUtilization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FamilyPlanUtilization {
    return new FamilyPlanUtilization().fromJsonString(jsonString, options);
  }

  static equals(a: FamilyPlanUtilization | PlainMessage<FamilyPlanUtilization> | undefined, b: FamilyPlanUtilization | PlainMessage<FamilyPlanUtilization> | undefined): boolean {
    return proto3.util.equals(FamilyPlanUtilization, a, b);
  }
}

/**
 * @generated from message protogen.GetAllActiveFamilyPlanUtilizationRequest
 */
export class GetAllActiveFamilyPlanUtilizationRequest extends Message<GetAllActiveFamilyPlanUtilizationRequest> {
  constructor(data?: PartialMessage<GetAllActiveFamilyPlanUtilizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetAllActiveFamilyPlanUtilizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllActiveFamilyPlanUtilizationRequest {
    return new GetAllActiveFamilyPlanUtilizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllActiveFamilyPlanUtilizationRequest {
    return new GetAllActiveFamilyPlanUtilizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllActiveFamilyPlanUtilizationRequest {
    return new GetAllActiveFamilyPlanUtilizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllActiveFamilyPlanUtilizationRequest | PlainMessage<GetAllActiveFamilyPlanUtilizationRequest> | undefined, b: GetAllActiveFamilyPlanUtilizationRequest | PlainMessage<GetAllActiveFamilyPlanUtilizationRequest> | undefined): boolean {
    return proto3.util.equals(GetAllActiveFamilyPlanUtilizationRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetAllActiveFamilyPlanUtilizationResponse
 */
export class GetAllActiveFamilyPlanUtilizationResponse extends Message<GetAllActiveFamilyPlanUtilizationResponse> {
  /**
   * @generated from field: repeated protogen.FamilyPlanUtilization familyPlanUtilization = 1;
   */
  familyPlanUtilization: FamilyPlanUtilization[] = [];

  constructor(data?: PartialMessage<GetAllActiveFamilyPlanUtilizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetAllActiveFamilyPlanUtilizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyPlanUtilization", kind: "message", T: FamilyPlanUtilization, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllActiveFamilyPlanUtilizationResponse {
    return new GetAllActiveFamilyPlanUtilizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllActiveFamilyPlanUtilizationResponse {
    return new GetAllActiveFamilyPlanUtilizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllActiveFamilyPlanUtilizationResponse {
    return new GetAllActiveFamilyPlanUtilizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllActiveFamilyPlanUtilizationResponse | PlainMessage<GetAllActiveFamilyPlanUtilizationResponse> | undefined, b: GetAllActiveFamilyPlanUtilizationResponse | PlainMessage<GetAllActiveFamilyPlanUtilizationResponse> | undefined): boolean {
    return proto3.util.equals(GetAllActiveFamilyPlanUtilizationResponse, a, b);
  }
}

